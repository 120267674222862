import React from 'react';
import {injectIntl} from 'react-intl';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import Button from '../../components/Button/Button';
import Text from '../../components/Text/Text';
import './LocalePicker.css';
import i18n from '../../i18n';
import messages from './messages';

class LocalePicker extends React.Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.state = {'locale': intl.locale};
    this.setupHandlers();
  }

  /**
   * Binds any handlers to the correct context
   *
   * @method  setUpHandlers
   */
  setupHandlers() {
    this.onChange = this.handleChange.bind(this);
    this.onSave = this.handleSave.bind(this);
  }

  /**
   * Updates the current locale within the internal component state.
   * This method is used as a callback to the radio group change event.
   *
   * @method handleChange
   * @param  {Object} option [The selected locale option object]
   */
  handleChange(name, value) {
    this.setState({'locale': value});
  }

  /**
   * Sends the current locale stored in internal state outward through a callback.
   * This method is used as a callback to the locale save button click event.
   *
   * @method handleSave
   */
  handleSave() {
    this.props.onLocaleChange(this.state.locale);
  }

  /**
   * Provides the localized string for a locale code. Used for display of
   * available locales in the radio options.
   *
   * @method localizedLocale
   * @param  {String} locale [The locale code for lookup (i.e. 'en', 'de')]
   * @return {String}        [The localized string for a given locale code]
   */
  localizedLocale(locale) {
    const {intl} = this.props;

    return intl.formatMessage(messages[`label_${locale}`]);
  }

  /**
   * Builds the radio group containing the available locales for display.
   *
   * @method _renderLocaleOptions
   */
  _renderLocaleOptions() {
    const localeOptions = i18n.availableLocales.map(locale => {
      const element = <span>{this.localizedLocale(locale)}</span>;

      return {
        'element': element,
        'value': locale
      };
    });

    return (
      <RadioButtonGroup
        className="RadioButtonGroup_col"
        filterLabels={localeOptions}
        onChange={this.onChange}
        name="locale"
        activeRadioButtonValue={this.state.locale}
      />
    );
  }

  render() {
    const {intl, closeModal} = this.props;

    return (
      <div className="LocalePicker-container">
        <Text
          className="LocalePicker-heading"
          type="lg"
          weight="light"
        >
          {intl.formatMessage(messages.locale_picker_heading)}
        </Text>
        <div className="LocalePicker-options">
          {this._renderLocaleOptions()}
        </div>
        <div className="LocalePicker-save">
          <Button
            animateOnClick
            className="btn_fixedWidthMd"
            onClick={this.onSave}
          >
            {intl.formatMessage(messages.locale_picker_save_label)}
          </Button>
        </div>
        <a
          href="javascrip:void(0)"
          className="LocalePicker-cancel"
          onClick={closeModal}
        >
          <Text
            className="mix-txt_uppercase"
            color="DefaultBlue"
            type="md"
            weight="light"
          >
            {intl.formatMessage(messages.locale_picker_cancel_label)}
          </Text>
        </a>
      </div>
    );
  }
}

export default injectIntl(LocalePicker);
