import React, {Component} from 'react';


export default function componentWithErrorBoundary(WrappedComponent, key) {
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
      this.setState({hasError: true});
    }

    render() {
      if (this.state.hasError) {
        return <p/>;
      }
      return this.props.children;
    }
  }

  if (key !== undefined) {
    return (
      <ErrorBoundary key={key}>
        {WrappedComponent}
      </ErrorBoundary>
    )
  } else {
    return (
      <ErrorBoundary>
        {WrappedComponent}
      </ErrorBoundary>
    )
  }

}