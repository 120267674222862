/* eslint-disable */
export const localeStrings = {
    fr: {
        'add_transaction.back_to_sessions_label': 'Retour aux sessions',
        'add_transaction.bike_label': 'Vélo',
        'add_transaction.commute_comfort_type': 'Loisir Confort',
        'add_transaction.commute_sport_type': 'Loisir Sportif',
        'add_transaction.complete_transaction_cta_label':
            'Ajouter la transaction',
        'add_transaction.confirm_label': 'Confirmer',
        'add_transaction.cyclocross_type': 'Cyclocross',
        'add_transaction.family_label': 'Famille',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'Semelles',
        'add_transaction.heading': 'Ajouter une transaction à {data}',
        'add_transaction.model_label': 'Modèle',
        'add_transaction.mountain_type': 'VTT',
        'add_transaction.mtb_trail_type': 'VTT (trail)',
        'add_transaction.mtb_xc_type': 'VTT (cross-country)',
        'add_transaction.product_label': 'Produit',
        'add_transaction.ratings_message':
            'Lorsque vous aurez complété cette transaction, {data} recevra un Email pour évaluer ce produit. Vous pourrez voir cette note sur la liste des sessions et sur la page de détail des sessions.',
        'add_transaction.road_type': 'Route',
        'add_transaction.saddle_label': 'Selle',
        'add_transaction.shoe_label': 'Chaussures',
        'add_transaction.size_label': 'Taille',
        'add_transaction.transaction_complete_message': 'Transaction ajouté.',
        'add_transaction.triathlon_type': 'Triathlon',
        'add_transaction.tt_tri_type': 'Contr la montre / Triathlon',
        'add_transaction.type_label': 'Type',
        'analytics.loading_message':
            'Nous compilons vos données, cela peut prendre quelques minutes.',
        'analytics_filters.analytics_by': 'ANALYSES PAR',
        'analytics_filters.filter_by': 'FILTRE PAR',
        'analytics_filters.products': 'PRODUITS',
        'analytics_filters.sessions': 'SESSIONS',
        'charts.bike_label_products': 'Vélo',
        'charts.bike_label_sessions': 'Vélo',
        'charts.commute_comfort_label': 'Loisir Confort',
        'charts.commute_sport_label': 'Loisir Sportif',
        'charts.cyclocross_label': 'Cyclocross',
        'charts.female_label': 'Femme',
        'charts.friday_label': 'Vendredi',
        'charts.high_arch_label': 'Haute',
        'charts.low_arch_label': 'Basse',
        'charts.male_label': 'Homme',
        'charts.medium_arch_label': 'Moyenne',
        'charts.monday_label': 'Lundi',
        'charts.mtb_trail_label': 'VTT-trail',
        'charts.mtb_xc_label': 'VTT-Cross-country',
        'charts.road_label': 'Route',
        'charts.saddle_label_products': 'Selle',
        'charts.saddle_label_sessions': 'Selle',
        'charts.saturday_label': 'Samedi',
        'charts.sessions_by_bike_type_label': 'Sessions par types de vélos',
        'charts.sessions_by_day_of_week_label':
            'Sessions par jour de la semaine',
        'charts.sessions_by_employee_label': 'Sessions par employés',
        'charts.sessions_by_foot_arch_label': 'Sessions par voûtes plantaires',
        'charts.sessions_by_gender_label': 'Sessions par genre',
        'charts.sessions_by_product_label': 'Sessions par produits',
        'charts.sessions_by_saddle_height_label':
            'Sessions par hauteurs de selle',
        'charts.sessions_by_saddle_size_label':
            'Sessions par tailles de selles',
        'charts.sessions_by_shirt_bike_size_label':
            'Sessions par taille de vélo (T-shirt)',
        'charts.sessions_by_shoe_size_label': 'Sessions par pointures',
        'charts.sessions_by_sitbone_width_label':
            'Sessions par écartements ischiatiques',
        'charts.sessions_by_standard_bike_size_label':
            'Sessions par taille de vélo (Standard)',
        'charts.sessions_over_time_label': 'Sessions au fil du temps',
        'charts.shoe_label_products': 'Chaussures',
        'charts.shoe_label_sessions': 'Chaussures',
        'charts.sunday_label': 'dimanche',
        'charts.thursday_label': 'Jeudi',
        'charts.top_ten_session_label': 'Top Dix Dates De Session',
        'charts.top_transactions_label': 'Dates des meilleures transactions',
        'charts.triathlon_label': 'Triathlon',
        'charts.tuesday_label': 'Mardi',
        'charts.wednesday_label': 'Mercredi',
        'client.all_sessions_link': 'Toutes les sessions',
        'client.bike_progress': 'Vélo',
        'client.client_progress_heading': 'Progression du client',
        'client.client_sessions_heading': 'Dernières sessions du client',
        'client.email': 'Email du client',
        'client.name': 'Nom du client',
        'client.not_applicable': 'N/A',
        'client.notes_label': 'Remarques',
        'client.progress_types_complete': 'Complété',
        'client.saddle_progress': 'Selle',
        'client.save_cta': 'Enregistrer',
        'client.shoe_progress': 'Chaussures',
        'commute-comfort': 'Loisir Confort',
        'commute-sport': 'Loisir Sportif',
        'country.ad': 'Andorre',
        'country.ae': 'Emirats Arabes Unis',
        'country.af': 'Afghanistan',
        'country.ag': 'Antigua et Barbuda',
        'country.ai': 'Anguilla',
        'country.al': 'Albanie',
        'country.am': 'Arménie',
        'country.ao': 'Angola',
        'country.aq': 'Antarctique',
        'country.ar': 'Argentine',
        'country.as': 'Samoa américaines',
        'country.at': 'Autriche',
        'country.au': 'Australie',
        'country.aw': 'Aruba',
        'country.ax': 'Åland (Îles)',
        'country.az': 'Azerbaïdjan',
        'country.ba': 'Bosnie-Herzégovine',
        'country.bb': 'Barbade',
        'country.bd': 'Bangladesh',
        'country.be': 'Belgique',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bulgarie',
        'country.bh': 'Bahreïn',
        'country.bi': 'Burundi',
        'country.bj': 'Bénin',
        'country.bl': 'Saint Barthélemy',
        'country.bm': 'Bermudes',
        'country.bn': 'Brunei',
        'country.bo': 'Bolivie',
        'country.bq': 'Bonaire, Saint-Eustache et Saba',
        'country.br': 'Brésil',
        'country.bs': 'Bahamas',
        'country.bt': 'Bhoutan',
        'country.bv': 'Île Bouvet',
        'country.bw': 'Botswana',
        'country.by': 'Biélorussie',
        'country.bz': 'Belize',
        'country.ca': 'Canada',
        'country.cc': 'Cocos (Keeling) (Îles)',
        'country.cd': 'Congo (République démocratique du)',
        'country.cf': 'République centrafricaine',
        'country.cg': 'Congo',
        'country.ch': 'Suisse',
        'country.ci': "Côte d'Ivoire",
        'country.ck': 'Cook (Îles Cook)',
        'country.cl': 'Chili',
        'country.cm': 'Cameroun',
        'country.cn': 'Chine',
        'country.co': 'Colombie',
        'country.cr': 'Costa Rica',
        'country.cu': 'Cuba',
        'country.cv': 'Cabo Verde',
        'country.cw': 'Curaçao',
        'country.cx': 'Île Christmas',
        'country.cy': 'Chypre',
        'country.cz': 'Tchéquie',
        'country.de': 'Allemagne',
        'country.dj': 'Djibouti',
        'country.dk': 'Danemark',
        'country.dm': 'Dominique',
        'country.do': 'République Dominicaine',
        'country.dz': 'Algérie',
        'country.ec': 'Équateur',
        'country.ee': 'Estonie',
        'country.eg': 'Égypte',
        'country.eh': 'Sahara occidental',
        'country.er': 'Érythrée',
        'country.es': 'Espagne',
        'country.et': 'Éthiopie',
        'country.fi': 'Finlande',
        'country.fj': 'Fidji',
        'country.fk': 'Îles Falkland [Malvinas]',
        'country.fm': 'Micronésie (États fédérés de)',
        'country.fo': 'Îles Féroé',
        'country.fr': 'France',
        'country.ga': 'Gabon',
        'country.gb': 'Royaume-Uni',
        'country.gd': 'Grenade',
        'country.ge': 'Géorgie',
        'country.gf': 'Guyane française',
        'country.gg': 'Guernesey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibraltar',
        'country.gl': 'Groenland',
        'country.gm': 'Gambie',
        'country.gn': 'Guinée',
        'country.gp': 'Guadeloupe',
        'country.gq': 'Guinée Équatoriale',
        'country.gr': 'Grèce',
        'country.gs': 'Géorgie du Sud et îles Sandwich du Sud',
        'country.gt': 'Guatemala',
        'country.gu': 'Guam',
        'country.gw': 'Guinée-Bissau',
        'country.gy': 'Guyane',
        'country.hk': 'Hong Kong',
        'country.hm': 'Île Heard et îles McDonald',
        'country.hn': 'Honduras',
        'country.hr': 'Croatie',
        'country.ht': 'Haïti',
        'country.hu': 'Hongrie',
        'country.id': 'Indonésie',
        'country.ie': 'Irlande',
        'country.il': 'Israël',
        'country.im': 'Île de Man',
        'country.in': 'Inde',
        'country.io': "Territoire britannique de l'océan Indien",
        'country.iq': 'Irak',
        'country.ir': 'Iran',
        'country.is': 'Islande',
        'country.it': 'Italie',
        'country.je': 'Jersey',
        'country.jm': 'Jamaïque',
        'country.jo': 'Jordanie',
        'country.jp': 'Japon',
        'country.ke': 'Kenya',
        'country.kg': 'Kirghizistan',
        'country.kh': 'Cambodge',
        'country.ki': 'Kiribati',
        'country.km': 'Comores',
        'country.kn': 'Saint-Kitts-et-Nevis',
        'country.kp': 'Corée du Nord',
        'country.kr': 'Corée du Sud',
        'country.kw': 'Koweït',
        'country.ky': 'Îles Caïmans',
        'country.kz': 'Kazakhstan',
        'country.la': 'Laos',
        'country.lb': 'Liban',
        'country.lc': 'Sainte-Lucie',
        'country.li': 'Liechtenstein',
        'country.lk': 'Sri Lanka',
        'country.lr': 'Libéria',
        'country.ls': 'Lesotho',
        'country.lt': 'Lituanie',
        'country.lu': 'Luxembourg',
        'country.lv': 'Lettonie',
        'country.ly': 'Libye',
        'country.ma': 'Maroc',
        'country.mc': 'Monaco',
        'country.md': 'Moldavie',
        'country.me': 'Monténégro',
        'country.mf': 'Saint-Martin (partie Français)',
        'country.mg': 'Madagascar',
        'country.mh': 'Îles Marshall',
        'country.mk': 'Macédoine',
        'country.ml': 'Mali',
        'country.mm': 'Myanmar',
        'country.mn': 'Mongolie',
        'country.mo': 'Macao',
        'country.mp': 'Îles Mariannes du Nord',
        'country.mq': 'Martinique',
        'country.mr': 'Mauritanie',
        'country.ms': 'Montserrat',
        'country.mt': 'Malte',
        'country.mu': 'Île Maurice',
        'country.mv': 'Maldives',
        'country.mw': 'Malawi',
        'country.mx': 'Mexique',
        'country.my': 'Malaisie',
        'country.mz': 'Mozambique',
        'country.na': 'Namibie',
        'country.nc': 'Nouvelle Calédonie',
        'country.ne': 'Niger',
        'country.nf': 'Île Norfolk',
        'country.ng': 'Nigéria',
        'country.ni': 'Nicaragua',
        'country.nl': 'Pays-Bas',
        'country.no': 'Norvège',
        'country.np': 'Népal',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'Nouvelle-Zélande',
        'country.om': 'Oman',
        'country.pa': 'Panama',
        'country.pe': 'Pérou',
        'country.pf': 'Polynésie française',
        'country.pg': 'Papouasie Nouvelle Guinée',
        'country.ph': 'Philippines',
        'country.pk': 'Pakistan',
        'country.pl': 'Pologne',
        'country.pm': 'Saint Pierre et Miquelon',
        'country.pn': 'Pitcairn',
        'country.pr': 'Porto Rico',
        'country.ps': 'Palestine, État de',
        'country.pt': 'Portugal',
        'country.pw': 'Palau',
        'country.py': 'Paraguay',
        'country.qa': 'Qatar',
        'country.re': 'Réunion',
        'country.ro': 'Roumanie',
        'country.rs': 'Serbie',
        'country.ru': 'Russie',
        'country.rw': 'Rwanda',
        'country.sa': 'Arabie Saoudite',
        'country.sb': 'Îles Salomon',
        'country.sc': 'Seychelles',
        'country.sd': 'Soudan',
        'country.se': 'Suède',
        'country.sg': 'Singapour',
        'country.sh': 'Sainte Hélène, Ascension et Tristan da Cunha',
        'country.si': 'Slovénie',
        'country.sj': 'Svalbard et Jan Mayen',
        'country.sk': 'Slovaquie',
        'country.sl': 'Sierra Leone',
        'country.sm': 'Saint Marin',
        'country.sn': 'Sénégal',
        'country.so': 'Somalie',
        'country.sr': 'Suriname',
        'country.ss': 'Sud-Soudan',
        'country.st': 'Sao Tomé et Principe',
        'country.sv': 'Le Salvador',
        'country.sx': 'Sint Maarten (partie néerlandaise)',
        'country.sy': 'Syrie',
        'country.sz': 'Swaziland',
        'country.tc': 'Îles Turques et Caïques',
        'country.td': 'Tchad',
        'country.tf': 'Territoires français du Sud',
        'country.tg': 'Togo',
        'country.th': 'Thaïlande',
        'country.tj': 'Tadjikistan',
        'country.tk': 'Tokélaou',
        'country.tl': 'Timor-Leste',
        'country.tm': 'Turkménistan',
        'country.tn': 'Tunisie',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trinité-et-Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Taïwan',
        'country.tz': 'Tanzanie',
        'country.ua': 'Ukraine',
        'country.ug': 'Ouganda',
        'country.um': 'Îles mineures éloignées des États-Unis',
        'country.us': 'États-Unis d’Amérique',
        'country.uy': 'Uruguay',
        'country.uz': 'Ouzbekistan',
        'country.va': 'Saint-Siège',
        'country.vc': 'Saint Vincent et les Grenadines',
        'country.ve': 'Venezuela',
        'country.vg': 'Îles Vierges (britanniques)',
        'country.vi': 'Îles Vierges (États-Unis)',
        'country.vn': 'Vietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis et Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Yémen',
        'country.yt': 'Mayotte',
        'country.za': 'Afrique du Sud',
        'country.zm': 'Zambie',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Cyclocross',
        'date.all_time': 'DATE: TOUT LE TEMPS',
        'date.last_month': 'DATE : LE MOIS DERNIER',
        'date.last_week': 'DATE : LA SEMAINE DERNIÈRE',
        'date.last_year': "DATE : L'ANNÉE DERNIÈRE",
        'employees.add_cta_label': 'Ajouter un employé',
        'employees.delete_cta_label': 'Supprimer l’employé',
        'employees.employee_id_label': "Numéro d'employé",
        'employees.heading': 'Employés',
        'employees.intro': 'Une liste de tous vos dernièrs employés Retül.',
        'employees.update_cta_label': "Mettre à jour l'employé",
        'filters.all_label': 'TOUT',
        'filters.client_all': 'Tout',
        'filters.client_anonymous': 'Anonyme',
        'filters.client_identified': 'Identifiés',
        'filters.client_label': 'Type de clients',
        'filters.employees_label': 'Employés',
        'filters.filters_heading': 'Filtres',
        'filters.hide_filters': 'Afficher les filtres',
        'filters.locations_label': 'Emplacements',
        'filters.rating_all': 'TOUTES',
        'filters.rating_label': 'Note',
        'filters.rating_none': 'AUCUNE',
        'filters.session_all': 'Tout',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Type de session',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Afficher les filtres',
        'filters.status_all': 'Tout',
        'filters.status_closed': 'Fermé',
        'filters.status_label': 'Statut',
        'filters.status_open': 'Ouvert',
        'footer.privacy_link_label': 'Politique de confidentialité',
        'footer.terms_link_label': "Conditions d'utilisation",
        'form.address_1_field_error': "L'adresse est requise.",
        'form.address_1_field_label': 'Adresse',
        'form.city_field_error': 'La ville est requise.',
        'form.city_field_label': 'Ville',
        'form.country_field_error': 'Le pays est requis.',
        'form.country_field_label': 'Pays',
        'form.delete_field_confirm_message':
            'Êtes-vous sûr de vouloir supprimer {data} ?',
        'form.delete_field_label': 'Supprimer',
        'form.edit_field_label': 'Modifier',
        'form.email_field_error':
            "L'Email est obligatoire et doit être un format de courrier électronique valide.",
        'form.email_field_label': 'Email',
        'form.name_field_error': 'Le nom est obligatoire.',
        'form.name_field_label': 'Nom',
        'form.shop_field_label': 'magasin',
        'form.shops_field_label': 'Magasins',
        'form.state_field_error': 'Le département est obligatoire.',
        'form.state_field_label': 'Département',
        'form.zip_field_error': 'Le Code postal est obligatoire.',
        'form.zip_field_label': 'Code Postal',
        'gender.all': 'GENRE: TOUS',
        'gender.female': 'GENRE: FEMME',
        'gender.male': 'GENRE : HOMME',
        'landing.heading': 'Bienvenue sur le Retailer Portal',
        'landing.intro':
            'Accédez aux données, sessions et informations clients capturées par la technologie Retül.',
        'landing.sign_in_or_create_account_label':
            'Se Connecter / Créer Un Compte',
        'locale.label_cs': '',
        'locale.label_de': '',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': '',
        'locale.label_fr': 'Français',
        'locale.label_it': '',
        'locale.label_ja': '',
        'locale.label_ko': '',
        'locale.label_nl': '',
        'locale.label_pl': '',
        'locale.label_pt': '',
        'locale.label_pt-br': '',
        'locale.label_zh-cn': '',
        'locale.label_zh-tw': '',
        'locale.locale_picker_cancel_label': 'Annuler',
        'locale.locale_picker_heading': 'Choisissez votre langue.',
        'locale.locale_picker_save_label': 'Enregister',
        'locations.add_cta_label': 'Ajouter un emplacement',
        'locations.delete_cta_label': "Supprimer l'emplacement",
        'locations.empty':
            "Aucun emplacement n'a été ajouté, pour utiliser les applications Retül, veuillez ajouter un emplacement.",
        'locations.empty_cta_label': 'Gérer les emplacements',
        'locations.heading': 'Gérer les emplacements',
        'locations.intro':
            'Ajoutez, modifiez et supprimez des emplacements qui utilisent la technologie Retül.',
        'locations.location_id_label': "Numéro d'emplacement",
        'locations.update_cta_label': "Mettre à jour l'emplacement",
        'metrics.closed_session_tooltip':
            "Les sessions fermées indiquent les sessions pour lesquelles une transaction a été enregistrée. Si un client a effectué ou n'a pas effectué d’achat, {closingSessions} vous aide à suivre les ventes réalisées avec la technologie Retül.",
        'metrics.closed_sessions': 'Sessions fermées',
        'metrics.closing_session_link': 'Fermé une session',
        'metrics.total_sessions': 'Nombre total de Sessions',
        'metrics.unique_client_emails': 'Nombres Emails client',
        'metrics.unique_emails_tooltip':
            "Ce nombre représente les sessions qui ont mené a l'envoie d'un email aux clients. Les clients sont déterminés par leur adresse électronique.",
        'mtb-trail': 'VTT (trail)',
        'mtb-xc': 'VTT (cross-country)',
        'nav.analytics': 'Analyses',
        'nav.greeting_label': 'Bonjour, {data}',
        'nav.manage_employees_link_label': 'Gérer les employés',
        'nav.manage_locations_link_label': 'Gérer les emplacements',
        'nav.sessions': 'Sessions',
        'nav.sign_out_link_label': 'Se déconnecter',
        'pagination.next': 'Suivant',
        'pagination.page': 'Page',
        'pagination.previous': 'Précédent',
        road: 'Route',
        'session_details.add_transaction_cta_label': 'Ajouter une transaction',
        'session_details.all_sessions_link': 'Toutes les sessions',
        'session_details.bike_product': 'Vélo',
        'session_details.client': 'Client',
        'session_details.client_review_label': 'Notes client',
        'session_details.complete_session_cta_label': 'Marquer comme fermé',
        'session_details.complete_session_message':
            'Fermez cette session en ajoutant toutes les transactions effectuées par le client, ou',
        'session_details.complete_status': 'Fermé',
        'session_details.created_on': 'Créé le: {data}',
        'session_details.delete_link': 'Supprimer',
        'session_details.employee': 'Employé: {data}',
        'session_details.family_label': 'Famille',
        'session_details.incomplete_status': 'Ouvert',
        'session_details.model_label': 'Modèle',
        'session_details.no_transactions_message':
            "Aucune transaction n'a été ajoutée.",
        'session_details.product_label': 'Produit',
        'session_details.saddle_product': 'Selle',
        'session_details.shoe_product': 'Chaussures',
        'session_details.shop': 'Magasin : {data}',
        'session_details.size_label': 'Taille',
        'session_details.status_label': 'Statut',
        'session_details.transactions_heading':
            'Transactions liés à la session',
        'session_products.arch_height_label':
            'Hauteur de voûteplantaire : {data}',
        'session_products.bike_size_label': 'Taille de vélo: {data}',
        'session_products.saddle_height_label': 'Hauteur de selle: {data} mm',
        'session_products.saddle_width_label': 'Largeur de selle: {data} mm',
        'session_products.session_product_label': 'Données de session',
        'session_products.shoe_size_label': 'Taille de chaussure: {data}',
        'sessions.add_transaction_cta_label': 'Ajouter une transaction',
        'sessions.client_anonymous': 'Anonyme',
        'sessions.client_label': 'Client',
        'sessions.closed_status': 'FERMÉ',
        'sessions.csv_downloading_message':
            'Nous rassemblons toutes vos sessions pour votre CSV, cela peut prendre quelques minutes.',
        'sessions.date_label': 'Date',
        'sessions.employee_label': 'Employé',
        'sessions.export_csv_link': 'Exporter la liste (. CSV)',
        'sessions.filter_empty':
            'Désolé, aucune session ne correspond à vos fitres. Veuillez réinitialiser les filtres.',
        'sessions.filter_empty_cta_label': 'Réinitialiser les filtres',
        'sessions.heading': 'Sessions',
        'sessions.intro': 'Une liste de toutes vos dernières sessions Retül.',
        'sessions.location_label': 'Emplacement',
        'sessions.no_rating': 'Pas de note',
        'sessions.open_status': 'OUVERT',
        'sessions.rating_label': 'Note',
        'sessions.session_id_label': 'Numéro de session',
        'sessions.session_loading_message':
            'Nous rassemblons toutes vos sessions, cela peut prendre un moment.',
        'sessions.status_label': 'Statut',
        'sign_in.heading': 'Se connecter',
        'sign_in.intro':
            'Entrez votre email et votre mot de passe pour commencer, ou choisissez "Créer un compte" pour en créer un nouveau.',
        'status.all': 'STATUS: TOUS',
        'status.complete': 'STATUS : FERMÉ',
        'status.incomplete': 'STATUS: OUVERT',
        triathlon: 'Triathlon',
        'POWER MIMIC DESCRIPTION':
            "Cette selle fait disparaître l'engourdissement et la douleur grâce à une conception qui imite la réponse du corps aux différents types de pression afin de créer un équilibre dans les tissus mous.",
        'date_picker.all_time': 'Toutes les sessions',
        'date_picker.date_range': 'Plage de dates',
        'date_picker.last_month': 'Le mois dernier',
        'date_picker.last_week': 'La semaine dernière',
        'date_picker.last_year': "L'année dernière",
        'date_picker.this_month': 'Ce mois-ci',
        'date_picker.this_week': 'Cette semaine',
        'date_picker.this_year': 'Cette année',
        'session_details.client_notes': 'Notes client',
        'session_details.footbed_product': 'Semelle',
        'session_products.it_width_label': 'Ecartement Ischiatique',
        'bike_size.large': 'L',
        'bike_size.medium': 'M',
        'bike_size.small': 'S',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match est un système d’évaluation en boutique qui mesure avec précision la longueur de vos jambes afin de vous proposer la taille de vélo optimale ainsi que les produits qui vous correspondent le mieux.',
        'body_data.about_saddle':
            'Retül Match est une expérience immersive en boutique qui utilise le Digital Sitbone Device de Retül pour saisir votre largeur d’ischion grâce à une technologie numérique de cartographie par pression, afin de vous proposer une taille de selle précise ainsi que les selles qui vous correspondent le mieux.',
        'body_data.about_shoe':
            'Retül Match est une expérience immersive en boutique qui saisit les données de vos pieds afin de vous proposer un support d’arche précis ainsi que la taille et les modèles de chaussures qui vous correspondent le mieux.',
        'body_data.arch_height_label': 'hauteur d’arche',
        'body_data.bike_label': 'Vélo',
        'body_data.bike_size_label': 'taille vélo',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'circonférence',
        'body_data.find_retul_technology': 'Trouver la technologie Retül',
        'body_data.heading': 'Données corporelles',
        'body_data.intro':
            'Vos données corporelles et vos préférences en matière de cyclisme sont saisies grâce à la technologie Retül Vantage afin de fournir des informations de taille précises ainsi que les produits qui vous correspondent le mieux.',
        'body_data.length_label': 'longueur',
        'body_data.load_error':
            'Nous sommes dans l’incapacité de récupérer vos données corporelles pour le moment, veuillez réessayer ultérieurement.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'non collecté',
        'body_data.recommended_label': 'Recommandé',
        'body_data.saddle_height_label': 'hauteur de selle',
        'body_data.saddle_label': 'Selle',
        'body_data.saddle_size_label': 'taille de selle',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'tibia',
        'body_data.shoe_label': 'Chaussure',
        'body_data.shoe_size_label': 'taille chaussure',
        'body_data.sit_bone_label': 'ischions',
        'body_data.starting_label': 'Démarrage',
        'body_data.thigh_label': 'cuisse',
        'body_data.view_bike_matches_label': 'Voir les vélos correspondants',
        'body_data.view_saddle_matches_label':
            'Voir les selles correspondantes',
        'body_data.view_shoe_matches_label':
            'Voir les chaussures correspondantes',
        'body_data.your_progress_completion_label': '{numComplete}/3 terminé',
        'body_data.your_progress_label': 'Vos progrès',
        cancel_label: 'Annuler',
        'create_account.heading': 'Créer un compte',
        'create_account.intro':
            'Veuillez saisir un e-mail et un mot de passe pour créer un compte Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Annuler',
        'edit_form.edit_form_save_label': 'Sauvegarder',
        email_format_error: 'Format d’e-mail non valide.',
        email_label: 'E-mail',
        'forgot_password.heading': 'Mot de passe oublié',
        'forgot_password.intro':
            'Vous avez oublié votre mot de passe ? Saisissez votre e-mail et nous vous enverrons un lien pour le réinitialiser.',
        'forgot_password.reset_email_sent':
            'Un e-mail de réinitialisation de mot de passe a été envoyé à {email}',
        'landing.create_account_label': 'Créer un compte',
        'landing.sign_in_label': 'Se connecter',
        'nav.account_profiles_link_label': 'Compte et profils de cycliste',
        'nav.home_page_link_label': 'Page d’accueil',
        'nav.logout_link_label': 'Déconnexion',
        'not_found.go_home_label': 'Retour à l’accueil',
        'not_found.heading': '404',
        'not_found.intro':
            'La page que vous recherchez semble avoir été déplacée, supprimée ou n’existe pas.',
        password_invalid_error: 'Mot de passe non valide.',
        password_label: 'Mot de passe',
        'product_matches.bike_size_label': 'taille vélo',
        'product_matches.category_filter_commute_comfort_label':
            'Trajet confortable',
        'product_matches.category_filter_commute_sport_label': 'Trajet sportif',
        'product_matches.category_filter_cyclocross_label': 'Cyclo-cross',
        'product_matches.category_filter_label': 'Catégorie',
        'product_matches.category_filter_label_road': 'Route',
        'product_matches.category_filter_mountain_trail_label':
            'Montagne (piste)',
        'product_matches.category_filter_mountain_xc_label':
            'Montagne (cross-country)',
        'product_matches.category_filter_triathlon_label': 'Triathlon',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Nous ne disposons pas de données corporelles nous permettant de vous proposer des résultats Match pour ce type de produit. Utilisez le filtre ci-dessus pour voir d’autres types de produits, ou utilisez la page Emplacements pour trouver un lieu où terminer ce module Match.',
        'product_matches.find_retul_technology_label':
            'Trouver la technologie Retul',
        'product_matches.gender_filter_all_label': 'Montrer tous les produits',
        'product_matches.gender_filter_female_label':
            'Montrer les produits pour femme',
        'product_matches.gender_filter_male_label':
            'Montrer les produits pour homme',
        'product_matches.heading': 'Produits correspondants',
        'product_matches.intro':
            'La technologie Retül utilise vos données corporelles et vos préférences en matière de cyclisme pour vous proposer des produits Specialized correspondant à vos besoins.',
        'product_matches.last_session_match_label': 'Dernière séance Match',
        'product_matches.load_error':
            'Nous sommes dans l’incapacité de récupérer vos correspondances produits pour le moment, veuillez réessayer ultérieurement.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'À partir des données biométriques capturées lors de votre séance Retül Match, nous vous recommandons la taille de vélo ci-dessus. Bien que ce soit un bon point de départ, nous vous conseillons de consulter votre revendeur local pour des recommandations basées sur votre niveau d’expérience et votre type de corps.',
        'product_matches.overlay_bike_size_label': 'taille vélo',
        'product_matches.overlay_close_label': 'Fermer',
        'product_matches.overlay_indicator_label': 'Vous',
        'product_matches.overlay_saddle_height_details':
            'À partir des données biométriques capturées lors de votre séance Retül Match, nous vous recommandons la hauteur de selle ci-dessus. Bien que ce soit un bon point de départ, nous vous conseillons de consulter votre revendeur local pour des recommandations basées sur votre niveau d’expérience et votre type de corps.',
        'product_matches.overlay_saddle_height_label': 'hauteur de selle',
        'product_matches.overlay_saddle_size_details':
            'À partir des données biométriques capturées lors de votre séance Retül Match, nous vous recommandons la taille de selle ci-dessus. Bien que ce soit un bon point de départ, nous vous conseillons de consulter votre revendeur local pour des recommandations basées sur vos spécificités corporelles.',
        'product_matches.overlay_saddle_size_label': 'taille de selle',
        'product_matches.overlay_shoe_size_details':
            'À partir des données biométriques capturées lors de votre séance Retül Match, nous vous recommandons la taille de chaussure ci-dessus. Bien que ce soit un bon point de départ, nous vous conseillons de consulter votre revendeur local pour des recommandations basées sur votre niveau d’expérience.',
        'product_matches.overlay_shoe_size_label': 'taille chaussure',
        'product_matches.padding_level_label': 'Niveau de rembourrage',
        'product_matches.product_type_filter_bikes_label': 'Vélos',
        'product_matches.product_type_filter_label': 'Type de produit',
        'product_matches.product_type_filter_saddles_label': 'Selles',
        'product_matches.product_type_filter_shoes_label': 'Chaussures',
        'product_matches.recommended_label': 'Recommandé',
        'product_matches.saddle_detail_size_label': 'Taille de selle :',
        'product_matches.saddle_height_label': 'hauteur de selle',
        'product_matches.saddle_models_label': 'Modèles',
        'product_matches.saddle_size_label': 'taille de selle',
        'product_matches.shoe_size_label': 'taille chaussure',
        'product_matches.starting_label': 'Démarrage',
        'product_matches.view_on_specialized_label': 'Voir sur Specialized',
        'profile.account_heading': 'Compte',
        'profile.bike_type_city_label': 'Ville',
        'profile.bike_type_label': 'Type de vélo',
        'profile.bike_type_mountain_label': 'Montagne',
        'profile.bike_type_road_label': 'Route',
        'profile.email_error': 'Veuillez saisir une adresse e-mail valide',
        'profile.email_label': 'E-mail',
        'profile.gender_female_label': 'femme',
        'profile.gender_label': 'Sexe',
        'profile.gender_male_label': 'homme',
        'profile.heading': 'Compte et profils de cycliste',
        'profile.intro':
            'Prochainement... modifiez les informations de votre compte et vos profils de cycliste.',
        'profile.name_error': 'Veuillez saisir un nom',
        'profile.name_label': 'Nom',
        'profile.password_error': 'Veuillez saisir un mot de passe',
        'profile.password_label': 'Mot de passe',
        'profile.position_high_label': 'Haute',
        'profile.position_label': 'Position',
        'profile.position_low_label': 'Basse',
        'profile.position_medium_label': 'Medium',
        'profile.ride_duration_extended_label': 'Plus de 90 minutes',
        'profile.ride_duration_label': 'Durée',
        'profile.ride_duration_long_label': '60 à 90 minutes',
        'profile.ride_duration_medium_label': '30 à 60 minutes',
        'profile.ride_duration_short_label': '0 à 30 minutes',
        'profile.ride_frequency_label': 'Fréquence',
        'profile.ride_frequency_once_month_label': 'Une fois par mois',
        'profile.ride_frequency_once_week_label': 'Une fois par semaine',
        'profile.ride_frequency_six_week_label': '6 fois par semaine',
        'profile.ride_frequency_three_week_label': '3 fois par semaine',
        'routes.account_profile_route_label': 'Compte et profils de cycliste',
        'routes.body_data_route_label': 'Données corporelles',
        'routes.create_account_route_label': 'Créer un compte',
        'routes.forgot_password_route_label': 'Mot de passe oublié',
        'routes.locations_route_label': 'Emplacements',
        'routes.not_found_route_label': 'Non trouvé',
        'routes.privacy_policy_route_label': 'Politique de confidentialité',
        'routes.product_matches_route_label': 'Produits correspondants',
        'routes.reset_password_route_label': 'Réinitialiser le mot de passe',
        'routes.set_password_route_label': 'Réinitialiser le mot de passe',
        'routes.sessions_route_label': 'Sessions',
        'routes.sign_in_route_label': 'Se connecter',
        'routes.terms_of_service_route_label': 'Conditions de service',
        'sessions.download_pdf_cta_label': 'Télécharger le PDF',
        'sessions.empty': 'Vous n’avez eu aucune séance.',
        'sessions.empty_cta_label': 'Trouver les emplacements',
        'sessions.loading_error':
            'Nous sommes dans l’incapacité de récupérer vos sessions pour le moment, veuillez réessayer ultérieurement.',
        'sessions.loading_error_cta_label': 'Essayer à nouveau',
        'sessions.session_type_filter_label': 'Type de session',
        'session_type.dfd_session_name': 'Digital Foot Device de Retül',
        'session_type.dsd_session_name': 'Digital Sitbone Device de Retül',
        'session_type.foot_session_name': 'Retül pour les pieds',
        'session_type.legs_session_name': 'Retül pour les jambes',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül pour les selles',
        'session_type.unknown_session_name': 'Session Retül',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': 'Se connecter',
        'sign_in.contact_label': 'Contacter Retül',
        'sign_in.forgot_password_label': 'Mot de passe oublié ?',
        'sign_in.sign_in_error':
            'Nous sommes dans l’incapacité de récupérer vos données utilisateur. Veuillez contacter le service client Retül pour obtenir de l’aide.',
        submit_label: 'Envoyer',
    },
};
