import React, {Component} from 'react';
import {Power1, TweenMax} from 'gsap';
import classNames from 'classnames';
import './Loader.css';

export default class Loader extends Component {
  componentDidMount() {
    if (this.props.isLoading) {
      this.createTweens();
      this.loopTweens();
    }
  }

  componentWillReceiveProps(nextProps) {
    const starting = nextProps.isLoading && !this.props.isLoading;
    const stopping = !nextProps.isLoading && this.props.isLoading;

    if (starting) {
      if (this.loadTween) {
        this.loadTween.restart(0);
        clearInterval(this.tweenLoop);
        this.loopTweens();
      } else {
        this.createTweens();
        this.loopTweens();
      }
    } else if (stopping) {
      clearInterval(this.tweenLoop);
    }
  }

  componentWillUnmount() {
    this.killAnimation();
  }

  createTweens() {
    const stageTwo = () => {
      TweenMax.to(this.loader, 0.5, {
        'delay': 0.1,
        'x': window.innerWidth * 2,
        'ease': Power1.easeIn,
        'onComplete': () => {
          if (this.loader) {
            this.loader.setAttribute('style', '');
          }
        }
      });
    };

    this.loadTween = TweenMax.to(this.loader, 0.5, {
      'x': window.innerWidth,
      'ease': Power1.easeOut,
      'onComplete': stageTwo
    });
  }

  loopTweens() {
    this.tweenLoop = setInterval(() => {
      if (this.props.isLoading) {
        this.loadTween.restart(0);
      }
    }, 1150);
  }

  killAnimation() {
    if (this.loadTween) {
      this.loadTween.kill();
    }

    if (this.tweenLoop) {
      clearInterval(this.tweenLoop);
    }
  }

  render() {
    return (
      <div className={this.loaderClasses} ref={el => {
        this.loader = el;
      }}>
      </div>
    )
  }

  get loaderClasses() {
    return classNames(
      'loader',
      {
        'loader_fixed': this.props.fixedLoader
      }
    );
  }
}
