import * as actionTypes from '../types/actions/sessions'
import {apiDelete, apiGet, apiPut} from '../api/apiClient';
import {JSON_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';
import {
  bikeTransactionsUrl,
  deleteBikeTransactionsUrl,
  deleteFootbedTransactionsUrl,
  deleteSaddleTransactionsUrl,
  deleteShoeTransactionsUrl,
  fetchSessionUrl,
  footbedTransactionsUrl,
  saddleTransactionsUrl,
  shoeTransactionsUrl
} from '../utils/urlHelper';

export function fetchSession(sessionId) {
  return dispatch => {
    dispatch(requestSession());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchSessionUrl(sessionId),
          receiveCallback: receiveSession,
        }
      )
    )
  }
}

export function fetchBikeTransactions(sessionId) {
  return dispatch => {
    dispatch(requestBikeTransactions());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveBikeTransactions,
          url: bikeTransactionsUrl(sessionId),
        }
      )
    )
  }
}

export function fetchSaddleTransactions(sessionId) {
  return dispatch => {
    dispatch(requestSaddleTransactions());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveSaddleTransactions,
          url: saddleTransactionsUrl(sessionId),
        }
      )
    )
  }
}

export function fetchShoeTransactions(sessionId) {
  return dispatch => {
    dispatch(requestShoeTransactions());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveShoeTransactions,
          url: shoeTransactionsUrl(sessionId),
        }
      )
    )
  }
}

export function fetchFootbedTransactions() {
  return dispatch => {
    dispatch(requestShoeTransactions());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveFootbedTransactions,
          url: footbedTransactionsUrl(),
        }
      )
    )
  }
}

export function updateSession(sessionId, data, receiveCallback) {
  return dispatch => {
    dispatch(requestSession());

    return apiPut(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback,
          url: fetchSessionUrl(sessionId),
          body: JSON.stringify(data)
        }
      )
    )
  }
}

export function deleteTransaction(id, type, receiveCallback) {
  const typeFunctions = getTypeFunctions(type);
  const {url, dispatchFunc} = typeFunctions;

  return dispatch => {
    dispatch(dispatchFunc());

    return apiDelete(
      generateRequestConfigs(
        {
          dispatch,
          receiveCallback,
          url: url(id),
        }
      )
    )
  }
}

export function receiveSession(data) {
  return {
    type: actionTypes.RECEIVE_SESSION_DATA,
    data
  };
}

export function requestSession() {
  return {
    type: actionTypes.REQUEST_SESSION_DATA
  }
}

export function requestBikeTransactions() {
  return {
    type: actionTypes.REQUEST_BIKE_TRANSACTIONS
  }
}

export function receiveBikeTransactions(data) {
  return {
    type: actionTypes.RECEIVE_BIKE_TRANSACTIONS,
    data,
  }
}

export function requestSaddleTransactions() {
  return {
    type: actionTypes.REQUEST_SADDLE_TRANSACTIONS
  }
}

export function receiveSaddleTransactions(data) {
  return {
    type: actionTypes.RECEIVE_SADDLE_TRANSACTIONS,
    data,
  }
}

export function requestShoeTransactions() {
  return {
    type: actionTypes.REQUEST_SHOE_TRANSACTIONS
  }
}

export function receiveShoeTransactions(data) {
  return {
    type: actionTypes.RECEIVE_SHOE_TRANSACTIONS,
    data,
  }
}

export function requestFootbedTransactions() {
  return {
    type: actionTypes.REQUEST_FOOTBED_TRANSACTIONS
  }
}

export function receiveFootbedTransactions(data) {
  return {
    type: actionTypes.RECEIVE_FOOTBED_TRANSACTIONS,
    data,
  }
}

// TODO: replace receiveCallbacks with new ones
function getTypeFunctions(type) {
  switch (type.toLowerCase()) {
    case 'bike':
      return {
        'url': deleteBikeTransactionsUrl,
        'dispatchFunc': requestBikeTransactions,
      };
    case 'saddle':
      return {
        'url': deleteSaddleTransactionsUrl,
        'dispatchFunc': requestSaddleTransactions,
      };
    case 'shoe':
      return {
        'url': deleteShoeTransactionsUrl,
        'dispatchFunc': requestShoeTransactions,
      };
    case 'footbed':
      return {
        'url': deleteFootbedTransactionsUrl,
        'dispatchFunc': requestFootbedTransactions,
      };
    default:
      return null;
  }
}
