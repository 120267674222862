export function isAccepted(response) {
  return response.status === 202;
}

export function isValidStatus(response) {
  return isSuccessfulStatus(response) || isNotModifiedStatus(response)
}

export function isSuccessfulStatus(response) {
  return response.status >= 200 && response.status < 300
}

export function isNotModifiedStatus(response) {
  return response.status === 304
}

export function isUnauthorizedStatus(response) {
  return response.status === 401
}

export function isBadStatus(response) {
  return response.status === 400
}
