import React, { Component } from 'react';
import './CreateAccountForm.css';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import makeHash from '../../utils/makeHash';
import Text from '../../components/Text/Text';
import messages from '../../utils/messages';
import { validateEmail, validatePassword } from '../../utils/validators';
import Button from '../../components/Button/Button';

class CreateAccountForm extends Component {
    constructor(props) {
        super(props);

        const { intl } = props;

        this.state = {
            serverError: false,
            serverMessages: [],
            loaderActive: false,
            loading: false,
            email: '',
            emailError: '',
            locale: intl.locale,
            firstName: '',
            lastName: '',
            password: '',
            passwordError: '',
            acceptTAC: false,
            acceptTOU: false,
            marketingOptin: false,
            submitEnabled: false,
            formMessages: [],
            showForm: true,
        };

        this.showGigyaScreenset = this.showGigyaScreenset.bind(this);

        this.setUpHandlers();
    }

    componentDidMount() {
        if (process.env.REACT_APP_AUTH_BACKEND === 'gigya') {
            this.createScrollScript();
            this.createGigyaScript(
                `${process.env.REACT_APP_GIGYA_KEY}`,
                document.body
            );
        }
    }

    setUpHandlers() {
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    handleSubmit(event) {
        // TODO : check validation
        event.preventDefault();
        // const isValid = !this.formHasErrors(); // TODO fix this

        if (event) event.preventDefault();

        this.createAccount();

        // // TODO: remove
        // if (isValid) {
        //     this.createAccount();
        // }
    }
    createAccount() {
        this.props.createAccount({
            client: 'retailer',
            email: this.state.email,
            password: this.state.password,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            locale: this.state.locale,
            tac: this.state.acceptTAC,
            tou: this.state.acceptTOU,
            retul_marketing_opt_in: this.state.marketingOptin,
        });
    }

    formHasErrors() {
        let errors = this.fields.filter((field) => {
            const hasError =
                field.state.isError || field.input.value.trim().length === 0;

            if (hasError) {
                field.setState({ isError: true });
            }

            return hasError;
        });

        return errors.length > 0;
    }

    createScrollScript() {
        return new Promise(function (resolve, reject) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = `function scrollIt(element) {
        window.scrollTo({
          'behavior': 'smooth',
          'left': 0,
          'top': element.offsetTop
        });
        element.focus();
      }
      
      function enableScrollTo() {
        if (window.scrollToSet !== true) {
          for (let i = 0; i < document.querySelectorAll('input').length; i++) {
            document.querySelectorAll('input')[i].addEventListener('click', () => scrollIt(document.querySelectorAll('input')[i]));
            window.scrollToSet = true;
          }
        }
      }`;

            document.body.appendChild(s);
            resolve();
        });
    }

    createGigyaScript(gigyaApi, element) {
        const scriptTag = document.createElement('script');
        scriptTag.src = `https://cdns.gigya.com/js/gigya.js?apikey=${gigyaApi}&lang=${this.locale}`;

        scriptTag.onload = this.showGigyaScreenset;
        scriptTag.onreadystatechange = this.showGigyaScreenset;

        element.appendChild(scriptTag);
    }

    handleError(error) {
        this.setState({
            loading: false,
        });

        // TODO: IMPLEMENT!
        error.response.json().then((errorBody) => {
            // Try to translate first error
            // EMAIL_NOT_VERIFIED, or USER_NOT_ACCEPTED_TERMS or INVALID_USERNAME_OR_PASSWORD
            if (errorBody.errors) {
                const firstError = errorBody.errors[0];
                const message = messages[firstError.message];

                let missingField = '';
                if (firstError.message === 'REQUIRED_FIELD') {
                    missingField = firstError.field;
                }

                // errors may look like  so handle if they are present in our messages
                if (message) {
                    return this.setState({
                        serverError: true,
                        formMessages: [
                            this.props.intl.formatMessage(message, {
                                field: missingField,
                            }),
                        ],
                    });
                }
            }

            // Use reason from the server if were unable to find error enum in our locales
            if (errorBody.reason) {
                return this.setState({
                    serverError: true,
                    formMessages: [errorBody.reason],
                });
            }

            // Use untranslated "non_field_errors" if that is present
            if (errorBody.non_field_errors) {
                return this.setState({
                    serverError: true,
                    formMessages: errorBody.non_field_errors,
                });
            }

            // Use a final catch-all error if none of the above applied
            this.setState({
                serverError: true,
                formMessages: ['Uknown error. Please try again.'],
            });
        });
    }

    showGigyaScreenset() {
        // console.log(test);
        const params = {
            screenSet: 'Passport-RegistrationLogin',
            startScreen: 'gigya-register-screen',
            containerID: 'userScreensDiv',
            onAfterSubmit: (evt) => {
                // console.log(evt.form);
                if (
                    (evt.form === 'gigya-login-form' ||
                        evt.form === 'gigya-profile-form') &&
                    evt.response.errorCode === 0
                ) {
                    window.gigya.accounts.getJWT({
                        fields: 'firstName,lastName,email',
                        expiration: 604800,
                        callback: (resp) => {
                            this.submitForm(
                                evt.response.profile.email,
                                resp.id_token
                            );
                        },
                    });
                }
            },
        };
        window.gigya.accounts.showScreenSet(params);
        window.scrollToSet = false;

        setInterval(function () {
            window.enableScrollTo();
        }, 1000);
    }

    submitForm(email, password) {
        this.props.handleSubmit({
            client: 'retailer',
            email,
            password,
        });
    }

    _renderFormMessages() {
        const color = this.props.serverErrors ? 'DefaultOrange' : 'DefaultBlue';

        let errorMessage = null;
        const hasErrors =
            this.props.serverErrors &&
            this.props.serverErrors.errors &&
            this.props.serverErrors.errors.length > 0;

        const ulClasses = classNames('form-messages', {
            'form-messages_hasMessage': hasErrors,
        });

        if (hasErrors) {
            const firstError = this.props.serverErrors.errors[0];
            const message = messages[firstError.message];

            let missingField = '';
            if (firstError.message === 'REQUIRED_FIELD') {
                missingField = firstError.field;
            }
            errorMessage = this.props.intl.formatMessage(message, {
                field: missingField,
            });
        }

        return (
            <ul className={ulClasses}>
                {this.state.formMessages.map((msg, i) => {
                    if (typeof msg === 'string') {
                        return (
                            <li key={makeHash(`${msg}-${i}`)}>
                                <Text color={color} weight="light">
                                    {msg.endsWith('.') ? msg : msg + '.'}
                                </Text>
                            </li>
                        );
                    } else {
                        return <li key={makeHash(`${i}`)}>{msg}</li>;
                    }
                })}
                {hasErrors && (
                    <li>
                        <Text color={color} weight="light">
                            {errorMessage}
                        </Text>
                    </li>
                )}
            </ul>
        );
    }

    updatePassword(event) {
        // Check that confirm password matches only when it has had entry
        event.persist();
        this.setState({ password: event.target.value }, () => {
            if (this.state.password.length > 0) {
                this.checkPasswordComplexity(event);
            }
        });
    }
    checkPasswordComplexity(event) {
        if (!validatePassword(this.state.password)) {
            this.setState({
                passwordError:
                    'Passwords must be at least 8 characters long, include upper- and lower-case letters, and include a number',
            });
        } else {
            this.setState({ passwordError: '' });
        }
    }

    render() {
        const { intl } = this.props;
        const formMessages = this._renderFormMessages();
        const showForm = this.state.showForm ? 'set' : 'hidden';
        const emailError = !validateEmail(this.state.email);
        const passwordFieldClass = classNames('field field_floatingLabel', {
            field_hasError: this.state.passwordError,
        });
        const firstNameError = this.state.firstName.length === 0;
        const lastNameError = this.state.lastName.length === 0;
        const submitEnabled =
            this.state.passwordError.length === 0 &&
            this.state.password.length > 0 &&
            this.state.acceptTAC &&
            this.state.acceptTOU &&
            this.state.firstName.length > 0 &&
            this.state.lastName.length > 0 &&
            this.state.email.length > 0;
        // TODO: remove userScreensDiv along with gigya when the switch happens
        return (
            <div className="SignInForm form-container">
                <div className="gigya-screen-set" id="userScreensDiv"></div>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div className="mix-set_alignCenter">{formMessages}</div>
                    <div className={showForm}>
                        <div className="set-hd">
                            <label className="field-label" htmlFor="email">
                                {intl.formatMessage(messages.email_label)}
                            </label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                className="field-input"
                                label={intl.formatMessage(messages.email_label)}
                                onChange={(e) => {
                                    this.setState({
                                        email: e.target.value,
                                    });
                                }}
                            />
                            {emailError && (
                                <span>
                                    {intl.formatMessage(
                                        messages.email_required_label
                                    )}
                                </span>
                            )}
                        </div>
                        <div className="set-bd set-bd_super">
                            <div className={passwordFieldClass}>
                                <label
                                    className="field-label"
                                    htmlFor="password"
                                >
                                    {intl.formatMessage(
                                        messages.password_label
                                    )}
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="field-input"
                                    value={this.state.password}
                                    onChange={this.updatePassword}
                                />
                                <span
                                    className={
                                        passwordFieldClass && 'field-error'
                                    }
                                >
                                    {this.state.passwordError}
                                </span>
                            </div>
                        </div>
                        <div className="set-bd set-bd_super">
                            <div className={passwordFieldClass}>
                                <label
                                    className="field-label"
                                    htmlFor="first-name"
                                >
                                    {intl.formatMessage(
                                        messages.first_name_label
                                    )}
                                </label>
                                <input
                                    id="first-name"
                                    name="first name"
                                    className="field-input"
                                    value={this.state.firstName}
                                    onChange={(e) => {
                                        this.setState({
                                            firstName: e.target.value,
                                        });
                                    }}
                                />
                                {firstNameError && (
                                    <span>
                                        {intl.formatMessage(
                                            messages.first_name_required_label
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="set-bd set-bd_super">
                            <div className={passwordFieldClass}>
                                <label
                                    className="field-label"
                                    htmlFor="last-name"
                                >
                                    {intl.formatMessage(
                                        messages.last_name_label
                                    )}
                                </label>
                                <input
                                    id="last-name"
                                    name="last name"
                                    className="field-input"
                                    value={this.state.lastName}
                                    onChange={(e) => {
                                        this.setState({
                                            lastName: e.target.value,
                                        });
                                    }}
                                />
                                {lastNameError && (
                                    <span>
                                        {intl.formatMessage(
                                            messages.last_name_required_label
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="set-bd set-bd_super">
                            <div className={passwordFieldClass}>
                                <input
                                    id="accept-tac"
                                    name="accept terms and conditions"
                                    type="checkbox"
                                    value={this.state.acceptTAC}
                                    onChange={(e) => {
                                        this.setState({
                                            acceptTAC: !this.state.acceptTAC,
                                        });
                                    }}
                                />
                                <label htmlFor="accept-tac">
                                    {intl.formatMessage(
                                        messages.accept_tac_label
                                    )}
                                </label>
                                {!this.state.acceptTAC && (
                                    <span>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="txt txt_reg mix-txt_colorDefaultBlue"
                                            href="https://www.specialized.com/terms-and-conditions"
                                        >
                                            &nbsp;&nbsp;(required)
                                        </a>
                                    </span>
                                )}
                            </div>
                            <div className={passwordFieldClass}>
                                <input
                                    id="accept-tou"
                                    name="accept terms of use"
                                    type="checkbox"
                                    value={this.state.acceptTOU}
                                    onChange={(e) => {
                                        this.setState({
                                            acceptTOU: !this.state.acceptTOU,
                                        });
                                    }}
                                />
                                <label htmlFor="accept-tou">
                                    {intl.formatMessage(
                                        messages.accept_tou_label
                                    )}
                                </label>
                                {!this.state.acceptTOU && (
                                    <span>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="txt txt_reg mix-txt_colorDefaultBlue"
                                            href="https://www.specialized.com/terms-of-use"
                                        >
                                            &nbsp;&nbsp;(required)
                                        </a>
                                    </span>
                                )}
                            </div>

                            <div className={passwordFieldClass}>
                                <input
                                    id="marketing-optin"
                                    name="accept marketing optin"
                                    type="checkbox"
                                    value={this.state.marketingOptin}
                                    onChange={(e) => {
                                        this.setState({
                                            marketingOptin: !this.state
                                                .marketingOptin,
                                        });
                                    }}
                                />
                                <label htmlFor="marketing-optin">
                                    {intl.formatMessage(
                                        messages.marketing_optin_label
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="set-bd set-bd_super">
                            <div className="set mix-set_alignCenter">
                                <div className="set-hd">
                                    <Button
                                        animateOnClick
                                        onClick={this.handleSubmit}
                                        type="submit"
                                        className="btn_fixedWidth"
                                        disabled={!submitEnabled}
                                    >
                                        {intl.formatMessage(
                                            messages.submit_label
                                        )}
                                    </Button>
                                </div>
                                <div className="set-bd set-bd_separated">
                                    <a
                                        className="txt txt_reg mix-txt_colorDefaultBlue"
                                        href="/sign-in"
                                    >
                                        {intl.formatMessage(
                                            messages.sign_in_label
                                        )}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default injectIntl(CreateAccountForm);
