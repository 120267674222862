import {
    RECEIVE_NEW_ACCOUNT,
    RECEIVE_NEW_ACCOUNT_FAILURE,
    REQUEST_NEW_ACCOUNT,
} from '../types/actions';
import * as statusTypes from '../types/statuses';

export const initialState = {
    fetchingStatus: null,
    userCreated: false,
};

function requestNewAccount(state) {
    return {
        ...state,
        fetchingStatus: statusTypes.REQUESTING,
    };
}

function receiveNewAccount(state) {
    return {
        ...state,
        // fetchingStatus: statusTypes.REQUESTING,
        fetchingStatus: statusTypes.RECEIVED,
        userCreated: true,
    };
}

function receiveNewAccountFailure(state, action) {
    return {
        ...state,
        fetchingStatus: statusTypes.FAILED,
        error: action.error,
    };
}

export default function currentUser(state = initialState, action) {
    switch (action.type) {
        case REQUEST_NEW_ACCOUNT:
            return requestNewAccount(state);
        case RECEIVE_NEW_ACCOUNT:
            return receiveNewAccount(state);
        case RECEIVE_NEW_ACCOUNT_FAILURE:
            return receiveNewAccountFailure(state, action);
        default:
            return state;
    }
}
