import React, {Component} from 'react';
import classNames from 'classnames';
import {validateEmail, validatePresence} from '../../utils/validators';
import './FormInput.css';

export default class FormInput extends Component {
  constructor(props) {
    super(props);
    const {defaultValue} = props;

    this.state = {
      value: defaultValue || '',
      isValid: true,
      isDirty: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyup = this.handleKeyup.bind(this);
  }

  handleChange(event) {
    const {handleChange} = this.props;

    this.setState({value: event.target.value});

    if (handleChange) {
      handleChange(event)
    }
  }

  handleBlur(event) {
    if (!this.state.isDirty) {
      this.setState({isDirty: true});
    }
    this.updateField(event.target.value);
  }

  handleKeyup(event) {
    if (!this.state.isDirty) {
      return;
    }
    this.updateField(event.target.value);
  }

  updateField(val) {
    const isValid = this.validateField(val);

    this.setState({isValid});
  }

  validateField(val) {
    const {required, inputType} = this.props;

    if (required && !validatePresence(val)) {
      return false;
    }

    if (inputType === 'email') {
      return validateEmail(val);
    }

    return true;
  }

  renderError() {
    const {errorMessage} = this.props;

    return this.state.isValid ? '' : (
      <div className="form-control">
        <p className="error">{errorMessage}</p>
      </div>
    );
  }

  renderInput() {
    const {inputType, name, required} = this.props;

    return inputType === 'textarea' ? (
      <textarea
        className={this.inputClasses}
        name={name}
        value={this.state.value}
        onChange={this.handleChange}
        onKeyUp={this.handleKeyup}
        onBlur={this.handleBlur}
        required={required}
      />
    ) : (
      <input
        className={this.inputClasses}
        type={inputType}
        name={name}
        value={this.state.value}
        onChange={this.handleChange}
        onKeyUp={this.handleKeyup}
        onBlur={this.handleBlur}
        required={required}
      />
    )
  }

  render() {
    const {name, label} = this.props;
    const formLabel = label ? label : name;

    return (
      <div className="form-row">
        <div className={this.formControlClasses}>
          <label className={this.labelClasses}>
            {formLabel}
          </label>
        </div>

        <div className={this.formControlClasses}>
          {this.renderInput()}
        </div>

        {this.renderError()}

        {this.props.children}
      </div>
    )
  }

  get labelClasses() {
    return classNames(
      'label',
      {
        'label_error': !this.state.isValid,
      }
    )
  }

  get inputClasses() {
    return classNames(
      'input',
      {
        'input_error': !this.state.isValid,
        'input_area': this.props.inputType === 'textarea',
      }
    )
  }

  get formControlClasses() {
    return classNames(
      'form-control',
      {
        'form-control_light': this.props.light
      }
    )
  }
}
