/* eslint-disable */
export const localeStrings = {
    'pt-br': {
        'add_transaction.back_to_sessions_label': 'Volta às sessões',
        'add_transaction.bike_label': 'Bicicleta',
        'add_transaction.commute_comfort_type': 'Comutar-conforto',
        'add_transaction.commute_sport_type': 'Comutar-esporte',
        'add_transaction.complete_transaction_cta_label':
            'Concluir a transação',
        'add_transaction.confirm_label': 'Confirme',
        'add_transaction.cyclocross_type': 'Ciclocross',
        'add_transaction.family_label': 'Family',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'Palmilha',
        'add_transaction.heading': 'Adicionar transação a {data}',
        'add_transaction.model_label': 'Modelo',
        'add_transaction.mountain_type': 'Montanha',
        'add_transaction.mtb_trail_type': 'Montanha (trilha)',
        'add_transaction.mtb_xc_type': 'Montanha (cross-country)',
        'add_transaction.product_label': 'Produto',
        'add_transaction.ratings_message':
            'Quando concluir esta transação, {data} receberá um email para avaliar este produto. Você poderá ver essa classificação na lista de sessões e na página de detalhes da sessão.',
        'add_transaction.road_type': 'Estrada',
        'add_transaction.saddle_label': 'Selim',
        'add_transaction.shoe_label': 'Sapato',
        'add_transaction.size_label': 'Tamanho',
        'add_transaction.transaction_complete_message': 'Transação Concluída.',
        'add_transaction.triathlon_type': 'Triatlo',
        'add_transaction.tt_tri_type': 'TT/Tri',
        'add_transaction.type_label': 'tipo',
        'analytics.loading_message':
            'Estamos compilando os dados, isso pode levar alguns minutos',
        'analytics_filters.analytics_by': 'ANÁLISE POR',
        'analytics_filters.filter_by': 'FILTRAR POR',
        'analytics_filters.products': 'PRODUTOS',
        'analytics_filters.sessions': 'SESSÕES',
        'charts.bike_label_products': 'Bicicleta',
        'charts.bike_label_sessions': 'Bicicleta',
        'charts.commute_comfort_label': 'Comutar-conforto',
        'charts.commute_sport_label': 'Comutar-esporte',
        'charts.cyclocross_label': 'Ciclocross',
        'charts.female_label': 'Feminino',
        'charts.friday_label': 'Sexta-feira',
        'charts.high_arch_label': 'alto',
        'charts.low_arch_label': 'baixo',
        'charts.male_label': 'Masculino',
        'charts.medium_arch_label': 'Médio',
        'charts.monday_label': 'Segunda-feira',
        'charts.mtb_trail_label': 'trilha mtb',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'Estrada',
        'charts.saddle_label_products': 'Selim',
        'charts.saddle_label_sessions': 'Selim',
        'charts.saturday_label': 'Sábado',
        'charts.sessions_by_bike_type_label': 'Sessões por tipo de bicicleta',
        'charts.sessions_by_day_of_week_label': 'Sessões por dia da semana',
        'charts.sessions_by_employee_label': 'Sessões por empregado',
        'charts.sessions_by_foot_arch_label': 'Sessões por arco do pé',
        'charts.sessions_by_gender_label': 'Sessões por sexo',
        'charts.sessions_by_product_label': 'Sessões Por Produto',
        'charts.sessions_by_saddle_height_label': 'Sessões por altura do selim',
        'charts.sessions_by_saddle_size_label': 'Sessões por tamanho de sela',
        'charts.sessions_by_shirt_bike_size_label':
            'Sessões de bicicleta tamanho (t-shirt)',
        'charts.sessions_by_shoe_size_label': 'Sessões por tamanho de sapato',
        'charts.sessions_by_sitbone_width_label':
            'Sessões por Largura do Sitbone',
        'charts.sessions_by_standard_bike_size_label':
            'Sessões de bicicleta tamanho (padrão)',
        'charts.sessions_over_time_label': 'Sessões ao longo do tempo',
        'charts.shoe_label_products': 'Sapato',
        'charts.shoe_label_sessions': 'Sapato',
        'charts.sunday_label': 'Domingo',
        'charts.thursday_label': 'Quinta-feira',
        'charts.top_ten_session_label': 'Dez melhores datas de sessão',
        'charts.top_transactions_label': 'Datas de transações superior',
        'charts.triathlon_label': 'Triatlo',
        'charts.tuesday_label': 'Terça-feira',
        'charts.wednesday_label': 'Quarta-feira',
        'client.all_sessions_link': 'Todas as Sessões',
        'client.bike_progress': 'Bicicleta',
        'client.client_progress_heading': 'Progresso do Cliente',
        'client.client_sessions_heading': 'Última sessão de cliente',
        'client.email': 'Email do cliente',
        'client.name': 'Nome do cliente',
        'client.not_applicable': '',
        'client.notes_label': 'Notas',
        'client.progress_types_complete': 'Completo',
        'client.saddle_progress': 'Selim',
        'client.save_cta': 'Salvar',
        'client.shoe_progress': 'Sapato',
        'commute-comfort': 'Comutar-conforto',
        'commute-sport': 'Comutar-esporte',
        'country.ad': '',
        'country.ae': '',
        'country.af': '',
        'country.ag': '',
        'country.ai': '',
        'country.al': '',
        'country.am': '',
        'country.ao': '',
        'country.aq': '',
        'country.ar': '',
        'country.as': '',
        'country.at': '',
        'country.au': '',
        'country.aw': '',
        'country.ax': '',
        'country.az': '',
        'country.ba': '',
        'country.bb': '',
        'country.bd': '',
        'country.be': '',
        'country.bf': '',
        'country.bg': '',
        'country.bh': '',
        'country.bi': '',
        'country.bj': '',
        'country.bl': '',
        'country.bm': '',
        'country.bn': '',
        'country.bo': '',
        'country.bq': '',
        'country.br': '',
        'country.bs': '',
        'country.bt': '',
        'country.bv': '',
        'country.bw': '',
        'country.by': '',
        'country.bz': '',
        'country.ca': '',
        'country.cc': '',
        'country.cd': '',
        'country.cf': '',
        'country.cg': '',
        'country.ch': '',
        'country.ci': '',
        'country.ck': '',
        'country.cl': '',
        'country.cm': '',
        'country.cn': '',
        'country.co': '',
        'country.cr': '',
        'country.cu': '',
        'country.cv': '',
        'country.cw': '',
        'country.cx': '',
        'country.cy': '',
        'country.cz': '',
        'country.de': '',
        'country.dj': '',
        'country.dk': '',
        'country.dm': '',
        'country.do': '',
        'country.dz': '',
        'country.ec': '',
        'country.ee': '',
        'country.eg': '',
        'country.eh': '',
        'country.er': '',
        'country.es': '',
        'country.et': '',
        'country.fi': '',
        'country.fj': '',
        'country.fk': '',
        'country.fm': '',
        'country.fo': '',
        'country.fr': '',
        'country.ga': '',
        'country.gb': '',
        'country.gd': '',
        'country.ge': '',
        'country.gf': '',
        'country.gg': '',
        'country.gh': '',
        'country.gi': '',
        'country.gl': '',
        'country.gm': '',
        'country.gn': '',
        'country.gp': '',
        'country.gq': '',
        'country.gr': '',
        'country.gs': '',
        'country.gt': '',
        'country.gu': '',
        'country.gw': '',
        'country.gy': '',
        'country.hk': '',
        'country.hm': '',
        'country.hn': '',
        'country.hr': '',
        'country.ht': '',
        'country.hu': '',
        'country.id': '',
        'country.ie': '',
        'country.il': '',
        'country.im': '',
        'country.in': '',
        'country.io': '',
        'country.iq': '',
        'country.ir': '',
        'country.is': '',
        'country.it': '',
        'country.je': '',
        'country.jm': '',
        'country.jo': '',
        'country.jp': '',
        'country.ke': '',
        'country.kg': '',
        'country.kh': '',
        'country.ki': '',
        'country.km': '',
        'country.kn': '',
        'country.kp': '',
        'country.kr': '',
        'country.kw': '',
        'country.ky': '',
        'country.kz': '',
        'country.la': '',
        'country.lb': '',
        'country.lc': '',
        'country.li': '',
        'country.lk': '',
        'country.lr': '',
        'country.ls': '',
        'country.lt': '',
        'country.lu': '',
        'country.lv': '',
        'country.ly': '',
        'country.ma': '',
        'country.mc': '',
        'country.md': '',
        'country.me': '',
        'country.mf': '',
        'country.mg': '',
        'country.mh': '',
        'country.mk': '',
        'country.ml': '',
        'country.mm': '',
        'country.mn': '',
        'country.mo': '',
        'country.mp': '',
        'country.mq': '',
        'country.mr': '',
        'country.ms': '',
        'country.mt': '',
        'country.mu': '',
        'country.mv': '',
        'country.mw': '',
        'country.mx': '',
        'country.my': '',
        'country.mz': '',
        'country.na': '',
        'country.nc': '',
        'country.ne': '',
        'country.nf': '',
        'country.ng': '',
        'country.ni': '',
        'country.nl': '',
        'country.no': '',
        'country.np': '',
        'country.nr': '',
        'country.nu': '',
        'country.nz': '',
        'country.om': '',
        'country.pa': '',
        'country.pe': '',
        'country.pf': '',
        'country.pg': '',
        'country.ph': '',
        'country.pk': '',
        'country.pl': '',
        'country.pm': '',
        'country.pn': '',
        'country.pr': '',
        'country.ps': '',
        'country.pt': '',
        'country.pw': '',
        'country.py': '',
        'country.qa': '',
        'country.re': '',
        'country.ro': '',
        'country.rs': '',
        'country.ru': '',
        'country.rw': '',
        'country.sa': '',
        'country.sb': '',
        'country.sc': '',
        'country.sd': '',
        'country.se': '',
        'country.sg': '',
        'country.sh': '',
        'country.si': '',
        'country.sj': '',
        'country.sk': '',
        'country.sl': '',
        'country.sm': '',
        'country.sn': '',
        'country.so': '',
        'country.sr': '',
        'country.ss': '',
        'country.st': '',
        'country.sv': '',
        'country.sx': '',
        'country.sy': '',
        'country.sz': '',
        'country.tc': '',
        'country.td': '',
        'country.tf': '',
        'country.tg': '',
        'country.th': '',
        'country.tj': '',
        'country.tk': '',
        'country.tl': '',
        'country.tm': '',
        'country.tn': '',
        'country.to': '',
        'country.tr': '',
        'country.tt': '',
        'country.tv': '',
        'country.tw': '',
        'country.tz': '',
        'country.ua': '',
        'country.ug': '',
        'country.um': '',
        'country.us': '',
        'country.uy': '',
        'country.uz': '',
        'country.va': '',
        'country.vc': '',
        'country.ve': '',
        'country.vg': '',
        'country.vi': '',
        'country.vn': '',
        'country.vu': '',
        'country.wf': '',
        'country.ws': '',
        'country.ye': '',
        'country.yt': '',
        'country.za': '',
        'country.zm': '',
        'country.zw': '',
        cyclocross: 'Ciclocross',
        'date.all_time': 'DATA: TODOS OS TEMPOS',
        'date.last_month': 'DATA: MÊS PASSADO',
        'date.last_week': 'DATA: SEMANA PASSADO',
        'date.last_year': 'DATA: NO ANO PASSADO',
        'employees.add_cta_label': 'Adicionar o empregado',
        'employees.delete_cta_label': 'Excluir o empregado',
        'employees.employee_id_label': 'ID do Empregado',
        'employees.heading': 'Empregados',
        'employees.intro':
            'Um cronograma de todos os seus funcionários mais recentes com tecnologia Retül.',
        'employees.update_cta_label': 'Empregado de atualização',
        'filters.all_label': 'TODOS',
        'filters.client_all': 'Todos',
        'filters.client_anonymous': 'Anônimo',
        'filters.client_identified': 'Identificado',
        'filters.client_label': 'Tipo de cliente',
        'filters.employees_label': 'Empregados',
        'filters.filters_heading': 'Filtros',
        'filters.hide_filters': 'Mostrar filtros',
        'filters.locations_label': 'Localizações',
        'filters.rating_all': 'TODOS',
        'filters.rating_label': 'Avaliação',
        'filters.rating_none': 'NENHUM',
        'filters.session_all': 'Todos',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Tipo de sessão',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Mostrar filtros',
        'filters.status_all': 'Todos',
        'filters.status_closed': 'Fechado',
        'filters.status_label': 'Status',
        'filters.status_open': 'Aberto',
        'footer.privacy_link_label': 'Política de Privacidade',
        'footer.terms_link_label': 'Termos de uso',
        'form.address_1_field_error': 'Endereço é necessário.',
        'form.address_1_field_label': 'Endereço',
        'form.city_field_error': 'Cidade é necessária.',
        'form.city_field_label': 'Cidade',
        'form.country_field_error': 'País é necessária.',
        'form.country_field_label': 'País',
        'form.delete_field_confirm_message':
            'Tem certeza de que deseja excluir {data} ?',
        'form.delete_field_label': 'Deletar',
        'form.edit_field_label': 'Editar',
        'form.email_field_error':
            'E-mail é necessária e deve ser um formato de e-mail válido.',
        'form.email_field_label': 'E-mail',
        'form.name_field_error': 'Nome é necessário.',
        'form.name_field_label': 'Nome',
        'form.shop_field_label': 'Loja',
        'form.shops_field_label': 'Lojas',
        'form.state_field_error': 'Estado é necessário.',
        'form.state_field_label': 'Estado',
        'form.zip_field_error': 'Zip é necessário.',
        'form.zip_field_label': '',
        'gender.all': 'GÊNERO: TODOS',
        'gender.female': 'GÊNERO: FEMININO',
        'gender.male': 'GÉNERO: MASCULINO',
        'landing.heading': 'Bem-vindo ao Portal do Retalhista',
        'landing.intro':
            'Acesso dados, sessões e cliente informações capturadas pela tecnologia Retül.',
        'landing.sign_in_or_create_account_label': 'Entrar / Criar conta',
        'locale.label_cs': '',
        'locale.label_de': '',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': '',
        'locale.label_fr': '',
        'locale.label_it': '',
        'locale.label_ja': '',
        'locale.label_ko': '',
        'locale.label_nl': '',
        'locale.label_pl': '',
        'locale.label_pt': '',
        'locale.label_pt-br': '',
        'locale.label_zh-cn': '',
        'locale.label_zh-tw': '',
        'locale.locale_picker_cancel_label': 'Cancelar',
        'locale.locale_picker_heading': 'Escolha o seu idioma.',
        'locale.locale_picker_save_label': 'Salvar',
        'locations.add_cta_label': 'Adicionar localização',
        'locations.delete_cta_label': 'Localização de excluir',
        'locations.empty':
            'Não há locais foram adicionados, para usar Apps Retül por favor adicionar um local.',
        'locations.empty_cta_label': 'Gerir localizações',
        'locations.heading': 'Gerir localizações',
        'locations.intro':
            'Adicionar, editar e remover locais usando a tecnologia Retül.',
        'locations.location_id_label': 'ID de localização',
        'locations.update_cta_label': 'Atualizar localização',
        'metrics.closed_session_tooltip':
            'Sessões fechadas indicam sessões que possuem uma transação registrada. Se um cliente fez ou não uma compra, {closingSessions} ajuda você a acompanhar as vendas da tecnologia Retül.',
        'metrics.closed_sessions': 'Sessões fechadas',
        'metrics.closing_session_link': 'sessão de encerramento',
        'metrics.total_sessions': 'Sessões Totais',
        'metrics.unique_client_emails': 'E-mails de clientes exclusivos',
        'metrics.unique_emails_tooltip':
            'Este número representa as sessões que têm sido realizadas com clientes exclusivos. Clientes exclusivos são determinados pelos seus endereços de e-mail.',
        'mtb-trail': 'Montanha (trilha)',
        'mtb-xc': 'Montanha (cross-country)',
        'nav.analytics': 'Analytics',
        'nav.greeting_label': 'Olá, {data}',
        'nav.manage_employees_link_label': 'Gerenciar funcionários',
        'nav.manage_locations_link_label': 'Gerir localizações',
        'nav.sessions': 'Sessões',
        'nav.sign_out_link_label': 'Sair',
        'pagination.next': 'Próximo',
        'pagination.page': 'Página',
        'pagination.previous': 'Anterior',
        road: 'Estrada',
        'session_details.add_transaction_cta_label': 'Adicionar transação',
        'session_details.all_sessions_link': 'Todas as Sessões',
        'session_details.bike_product': 'Bicicleta',
        'session_details.client': 'Cliente',
        'session_details.client_review_label': 'Revisão do cliente',
        'session_details.complete_session_cta_label': 'Marcar como fechado',
        'session_details.complete_session_message':
            'Fechar esta sessão adicionando quaisquer transacções feitas pelo cliente, ou',
        'session_details.complete_status': 'Fechado',
        'session_details.created_on': 'Criado em: {data}',
        'session_details.delete_link': 'Deletar',
        'session_details.employee': 'Empregado: {data}',
        'session_details.family_label': 'Modelo',
        'session_details.incomplete_status': 'Aberto',
        'session_details.model_label': 'Model',
        'session_details.no_transactions_message':
            'Nenhuma transação foi adicionada.',
        'session_details.product_label': 'Produto',
        'session_details.saddle_product': 'Selim',
        'session_details.shoe_product': 'Sapato',
        'session_details.shop': 'Loja: {data}',
        'session_details.size_label': 'Tamanho',
        'session_details.status_label': 'Status',
        'session_details.transactions_heading': 'Transações de Sessão',
        'session_products.arch_height_label': 'Altura do arco: {data}',
        'session_products.bike_size_label': 'Tamanho da bicicleta: {data}',
        'session_products.saddle_height_label': 'Altura da sela: {data} mm',
        'session_products.saddle_width_label': 'Largura da sela: {data} mm',
        'session_products.session_product_label': 'Dados da Sessão',
        'session_products.shoe_size_label': 'Tamanho da sapata: {data}',
        'sessions.add_transaction_cta_label': 'Adicionar transação',
        'sessions.client_anonymous': 'Anônimo',
        'sessions.client_label': 'Cliente',
        'sessions.closed_status': 'FECHADO',
        'sessions.csv_downloading_message':
            'Estamos reunindo todas as suas sessões para o seu CSV. Isso pode levar alguns minutos.',
        'sessions.date_label': 'Data',
        'sessions.employee_label': 'Empregado',
        'sessions.export_csv_link': 'Exportar lista (.CSV)',
        'sessions.filter_empty':
            'Desculpa, não há nenhum sessões que correspondem aos seus critérios de filtro. Por favor, redefina a seleção de filtro.',
        'sessions.filter_empty_cta_label': 'Redefinir Filtros',
        'sessions.heading': 'Sessões',
        'sessions.intro':
            'Um cronograma de todas as suas sessões mais recentes com tecnologia Retül.',
        'sessions.location_label': 'Localização',
        'sessions.no_rating': 'Sem avaliação',
        'sessions.open_status': 'ABERTO',
        'sessions.rating_label': 'Avaliação',
        'sessions.session_id_label': 'Identificação de sessão',
        'sessions.session_loading_message':
            'Estamos aqui reunidos todas as sessões, isso pode levar um momento.',
        'sessions.status_label': 'Status',
        'sign_in.heading': 'Assinar em',
        'sign_in.intro':
            'Digite seu e-mail e senha para começar, ou escolha "Registar conta" para fazer um novo.',
        'status.all': 'ESTADO: TODOS',
        'status.complete': 'ESTADO: FECHADO',
        'status.incomplete': 'ESTADO: ABERTO',
        triathlon: 'Triathlon',
        'POWER MIMIC DESCRIPTION':
            'Esta sela faz com que a dormência e a dor desapareçam com um design que imita a resposta do corpo a diferentes tipos de pressão para criar equilíbrio dentro do tecido mole.',
        'date_picker.all_time': 'Todas as Sessões',
        'date_picker.date_range': 'Intervalo de datas',
        'date_picker.last_month': 'Mês passado',
        'date_picker.last_week': 'Semana passada',
        'date_picker.last_year': 'Ano passado',
        'date_picker.this_month': 'Este mês',
        'date_picker.this_week': 'Esta semana',
        'date_picker.this_year': 'Este ano',
        'session_details.client_notes': 'Notas do cliente',
        'session_details.footbed_product': 'Palmilha',
        'session_products.it_width_label': 'Largura IT: {data}mm',
        'bike_size.large': 'G',
        'bike_size.medium': 'M',
        'bike_size.small': 'P',
        'bike_size.xlarge': 'EG',
        'bike_size.xsmall': 'EP',
        'bike_size.xxlarge': 'EEG',
        'body_data.about_bike':
            'Retül Match é uma experiência de avaliação em loja que mede com precisão o comprimento da sua perna para oferecer o tamanho de bicicleta perfeito e os produtos que melhor combinam com você.',
        'body_data.about_saddle':
            'Retül Match é uma envolvente experiência em loja que tira o máximo proveito do Digital Sitbone Device da Retül para capturar a largura dos seus ísquios com tecnologia de mapa de pressão digital a oferecer os selins que melhor combinam com você, no tamanho mais preciso.',
        'body_data.about_shoe':
            'Retül Match é uma envolvente experiência em loja que captura dados dos pés para fornecer as necessidades de suporte em arco e medidas de calçados precisas, e os calçados que melhor combinam com você.',
        'body_data.arch_height_label': 'altura do arco',
        'body_data.bike_label': 'Bicicleta',
        'body_data.bike_size_label': 'tamanho de bicicleta',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'circunferência',
        'body_data.find_retul_technology': 'Encontre tecnologia Retül',
        'body_data.heading': 'Dados corporais',
        'body_data.intro':
            'Os dados corporais e as preferências pedalada são capturados usando a tecnologia Retül Vantage para apresentar informações de tamanho precisas e produtos que melhor combinam com você.',
        'body_data.length_label': 'comprimento',
        'body_data.load_error':
            'Não foi possível recuperar seus dados corporais no momento. Tente novamente mais tarde.',
        'body_data.millimeter_symbol': 'milímetros',
        'body_data.not_collected_label': 'não coletado',
        'body_data.recommended_label': 'Recomendado',
        'body_data.saddle_height_label': 'altura do selim',
        'body_data.saddle_label': 'Selim',
        'body_data.saddle_size_label': 'tamanho do Selim',
        'body_data.session_type': 'Retül{sessionType}',
        'body_data.shin_label': 'canela',
        'body_data.shoe_label': 'calçado',
        'body_data.shoe_size_label': 'tamanho do calçado',
        'body_data.sit_bone_label': 'tuberosidade isquiática',
        'body_data.starting_label': 'Começando',
        'body_data.thigh_label': 'coxa',
        'body_data.view_bike_matches_label': 'Ver bicicletas encontradas',
        'body_data.view_saddle_matches_label': 'Ver selins encontrados',
        'body_data.view_shoe_matches_label': 'Ver calçados encontrados',
        'body_data.your_progress_completion_label': '{numComplete}/3 completo',
        'body_data.your_progress_label': 'Seu progresso',
        cancel_label: 'Cancelar',
        'create_account.heading': 'Criar Conta',
        'create_account.intro':
            'Entre um e-mail e senha para criar uma conta Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Cancelar',
        'edit_form.edit_form_save_label': 'Salvar',
        email_format_error: 'Formato de e-mail inválido.',
        email_label: 'E-mail',
        'forgot_password.heading': 'Esqueci a Senha',
        'forgot_password.intro':
            'Esqueceu sua senha? Entre seu e-mail e nós enviaremos um link para redefinir.',
        'forgot_password.reset_email_sent':
            'O e-mail de redefinição de senha foi enviado para {email}',
        'landing.create_account_label': 'Criar uma conta',
        'landing.sign_in_label': 'Acessar',
        'nav.account_profiles_link_label': 'Conta e Perfil do ciclista',
        'nav.home_page_link_label': 'Página inicial',
        'nav.logout_link_label': 'Sair',
        'not_found.go_home_label': 'Ir para a página inicial',
        'not_found.heading': '404',
        'not_found.intro':
            'Parece que a página que você está procurando mudou, foi apagada ou não existe.',
        password_invalid_error: 'Senha inválida.',
        password_label: 'Senha',
        'product_matches.bike_size_label': 'tamanho de bicicleta',
        'product_matches.category_filter_commute_comfort_label':
            'Conforto no Trajeto',
        'product_matches.category_filter_commute_sport_label':
            'Esporte no Trajeto',
        'product_matches.category_filter_cyclocross_label': 'Cyclocross',
        'product_matches.category_filter_label': 'Categoria',
        'product_matches.category_filter_label_road': 'Estrada',
        'product_matches.category_filter_mountain_trail_label':
            'Montanha (trilha)',
        'product_matches.category_filter_mountain_xc_label':
            'Montanha (cross-country)',
        'product_matches.category_filter_triathlon_label': 'Triatlo',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Não temos dados corporais para apresentar resultados correspondentes para este tipo de produto. Use o filtro acima para ver outros tipos de produtos, ou use a página de locais para encontrar um local para completar este módulo Match.',
        'product_matches.find_retul_technology_label':
            'Encontre tecnologia Retül',
        'product_matches.gender_filter_all_label': 'Mostrar todos os produtos',
        'product_matches.gender_filter_female_label':
            'Mostrar produtos femininos',
        'product_matches.gender_filter_male_label':
            'Mostrar produtos masculinos',
        'product_matches.heading': 'Produtos correspondentes',
        'product_matches.intro':
            'A tecnologia Retül tira o máximo proveito dos seus dados corporais e preferências de pedalar para encontrar os produtos especializados que melhor combinam com você.',
        'product_matches.last_session_match_label': 'Última sessão Match',
        'product_matches.load_error':
            'Não foi possível recuperar seus Produtos Correspondentes no momento. Tente novamente mais tarde.',
        'product_matches.millimeter_symbol': 'milímetros',
        'product_matches.overlay_bike_size_details':
            'A partir da biometria capturada durante a sua sessão Retül Match, recomendamos o tamanho da bicicleta acima. Apesar de ser um bom ponto de partida, ainda recomendamos que você consulte seu revendedor local para obter recomendações com base em seu nível de experiência e tipo de corpo.',
        'product_matches.overlay_bike_size_label': 'tamanho de bicicleta',
        'product_matches.overlay_close_label': 'Fechar',
        'product_matches.overlay_indicator_label': 'Você',
        'product_matches.overlay_saddle_height_details':
            'A partir da biometria capturada durante a sua sessão Retül Match, recomendamos a altura de selim acima. Apesar de ser um bom ponto de partida, ainda recomendamos que você consulte seu revendedor local para obter recomendações com base em seu nível de experiência e tipo de corpo.',
        'product_matches.overlay_saddle_height_label': 'altura do selim',
        'product_matches.overlay_saddle_size_details':
            'A partir da biometria capturada durante sua sessão do Retül Match, recomendamos o tamanho de selim acima. Apesar de ser um bom ponto de partida, ainda recomendamos que você consulte seu revendedor local para obter recomendações com base no seu tipo de corpo.',
        'product_matches.overlay_saddle_size_label': 'tamanho do selim',
        'product_matches.overlay_shoe_size_details':
            'A partir da biometria capturada durante sua sessão do Retül Match, recomendamos o tamanho de calçado acima. Apesar de ser um bom ponto de partida, ainda recomendamos que você consulte seu revendedor local para obter recomendações com base em seu nível de experiência.',
        'product_matches.overlay_shoe_size_label': 'tamanho do calçado',
        'product_matches.padding_level_label': 'Nível de Estofamento',
        'product_matches.product_type_filter_bikes_label': 'Bicicletas',
        'product_matches.product_type_filter_label': 'Tipo de produto',
        'product_matches.product_type_filter_saddles_label': 'Selins',
        'product_matches.product_type_filter_shoes_label': 'Calçados',
        'product_matches.recommended_label': 'Recomendado',
        'product_matches.saddle_detail_size_label': 'Tamanho do Selim:',
        'product_matches.saddle_height_label': 'altura do selim',
        'product_matches.saddle_models_label': 'Modelos',
        'product_matches.saddle_size_label': 'tamanho do selim',
        'product_matches.shoe_size_label': 'tamanho do calçado',
        'product_matches.starting_label': 'Começando',
        'product_matches.view_on_specialized_label': 'Ver em Especiais',
        'profile.account_heading': 'Conta',
        'profile.bike_type_city_label': 'Cidade',
        'profile.bike_type_label': 'Tipo de bicicleta',
        'profile.bike_type_mountain_label': 'Mountain',
        'profile.bike_type_road_label': 'Estrada',
        'profile.email_error': 'Entre com um e-mail válido',
        'profile.email_label': 'E-mail',
        'profile.gender_female_label': 'feminino',
        'profile.gender_label': 'Sexo',
        'profile.gender_male_label': 'masculino',
        'profile.heading': 'Conta e Perfil do ciclista',
        'profile.intro':
            'Em breve... edite as informações de sua conta e o perfil do ciclista.',
        'profile.name_error': 'Insira um nome',
        'profile.name_label': 'Nome',
        'profile.password_error': 'Insira uma senha',
        'profile.password_label': 'Senha',
        'profile.position_high_label': 'Elevada',
        'profile.position_label': 'Posição',
        'profile.position_low_label': 'Baixo',
        'profile.position_medium_label': 'Médio',
        'profile.ride_duration_extended_label': '+90 minutos',
        'profile.ride_duration_label': 'Duração',
        'profile.ride_duration_long_label': '60 a 90 minutos',
        'profile.ride_duration_medium_label': '30 a 60 minutos',
        'profile.ride_duration_short_label': '0 a 30 minutos',
        'profile.ride_frequency_label': 'Frequência',
        'profile.ride_frequency_once_month_label': 'Uma vez por mês',
        'profile.ride_frequency_once_week_label': 'Uma vez por semana',
        'profile.ride_frequency_six_week_label': '6 vezes por semana',
        'profile.ride_frequency_three_week_label': '3 vezes por semana',
        'routes.account_profile_route_label': 'Conta e Perfil do ciclista',
        'routes.body_data_route_label': 'Dados corporais',
        'routes.create_account_route_label': 'Criar Conta',
        'routes.forgot_password_route_label': 'Esqueci a Senha',
        'routes.locations_route_label': 'Localidades',
        'routes.not_found_route_label': 'Não encontrado',
        'routes.privacy_policy_route_label': 'Política de privacidade',
        'routes.product_matches_route_label': 'Produtos correspondentes',
        'routes.reset_password_route_label': 'Redefinir senha',
        'routes.set_password_route_label': 'Redefinir senha',
        'routes.sessions_route_label': 'Sessões',
        'routes.sign_in_route_label': 'Acessar',
        'routes.terms_of_service_route_label': 'Termos de serviço',
        'sessions.download_pdf_cta_label': 'Baixar PDF',
        'sessions.empty': 'Você não tem nenhuma sessão.',
        'sessions.empty_cta_label': 'Encontrar local',
        'sessions.loading_error':
            'Não foi possível recuperar suas Sessões no momento. Tente novamente mais tarde.',
        'sessions.loading_error_cta_label': 'Tente novamente',
        'sessions.session_type_filter_label': 'Tipo da sessão',
        'session_type.dfd_session_name': 'Digital Foot Device Retül',
        'session_type.dsd_session_name': 'Digital Sitbone Device Retül',
        'session_type.foot_session_name': 'Retül Foot',
        'session_type.legs_session_name': 'Retül Legs',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül Saddle',
        'session_type.unknown_session_name': 'Retül Session',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': 'Acessar',
        'sign_in.contact_label': 'Entre em contato com a Retül',
        'sign_in.forgot_password_label': 'Esqueceu sua senha?',
        'sign_in.sign_in_error':
            'Não foi possível recuperar seus dados. Entre em contato com o Retül Support para ajuda.',
        submit_label: 'Enviar',
    },
};
