import * as actionTypes from '../types/actions';
import { JSON_PARSER } from '../types/constants';
import { createTokensUrl } from '../utils/urlHelper';
import { apiCreate } from '../api/apiClient';
import { generateRequestConfigs } from '../utils/fetchHelper';
import { clearLocalStorage, setTokens } from '../api/tokens';

export function fetchJWT(data) {
    return async (dispatch) => {
        dispatch(requestJWT());

        return apiCreate(
            generateRequestConfigs({
                body: JSON.stringify(data),
                dispatch,
                receiveCallback: receiveJWTSucess,
                failCallback: receiveAuthFailure,
                parser: JSON_PARSER,
                url: createTokensUrl(),
            })
        );
    };
}

export function requestJWT() {
    return {
        type: actionTypes.REQUEST_JWT,
    };
}

export function receiveJWTSucess(data) {
    setTokens(data);

    return {
        type: actionTypes.RECEIVE_JWT_SUCCESS,
    };
}

export function receiveAuthFailure(error) {
    return {
        type: actionTypes.RECEIVE_JWT_ERROR,
        error,
    };
}

export function signOut() {
    clearLocalStorage();

    return {
        type: actionTypes.SIGN_OUT,
    };
}
