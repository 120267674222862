import React, { Component } from 'react';
import Main from '../../components/Main/Main';
import ForgotPasswordForm from '../../components/ForgotPassword/ForgotPasswordForm';
import Intro from '../../components/Intro/Intro';
import Loader from '../../components/Loader/Loader';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';

export default class ForgotPassword extends Component {
    renderForm() {
        const { handleSubmit, data } = this.props;

        return componentWithErrorBoundary(
            <ForgotPasswordForm handleSubmit={handleSubmit} data={data} />
        );
    }

    render() {
        return (
            <Main>
                <Loader
                    isLoading={this.props.fetchingStatus === 'REQUESTING'}
                />
                <section className="top top_medium">
                    <div className="container">
                        <Intro
                            header="Forgot Password"
                            summary="Forgot your password? Enter your email and we’ll send you a link to reset."
                        />
                    </div>
                </section>
                <section className="bottom bottom_extraPad bottom_dark">
                    <div className="container">{this.renderForm()}</div>
                </section>
            </Main>
        );
    }
}
