import React from 'react';
import Button from '../../components/Button/Button';
import Intro from '../../components/Intro/Intro';
import Main from '../../components/Main/Main';
import './Landing.css';
import {injectIntl} from 'react-intl';
import messages from './messages';

export default injectIntl(function (props) {
  const {intl} = props;

  return (
    <Main className="Landing">
      <section className="top top_alignTop top_fillScreen">
        <div className="container">
          <Intro
            header={intl.formatMessage(messages.heading)}
            summary={intl.formatMessage(messages.intro)}
          />
          <div className="cta-column">
            <Button
              to="/sign-in">
              {intl.formatMessage(messages.sign_in_or_create_account_label)}
            </Button>
          </div>
        </div>
      </section>
    </Main>
  );
});
