/* eslint-disable */
export const localeStrings = {
    ja: {
        'add_transaction.back_to_sessions_label': 'セッションに戻る',
        'add_transaction.bike_label': 'バイク',
        'add_transaction.commute_comfort_type': '通勤-コンフォート',
        'add_transaction.commute_sport_type': '通勤-スポーツ',
        'add_transaction.complete_transaction_cta_label': '接客の完了',
        'add_transaction.confirm_label': '確定',
        'add_transaction.cyclocross_type': 'シクロクロス',
        'add_transaction.family_label': '製品ファミリー',
        'add_transaction.fitness_type': 'フィットネス',
        'add_transaction.footbed_label': 'フットベッド',
        'add_transaction.heading': '{data}に接客を追加',
        'add_transaction.model_label': 'モデル',
        'add_transaction.mountain_type': 'マウンテン',
        'add_transaction.mtb_trail_type': 'マウンテン（トレイル）',
        'add_transaction.mtb_xc_type': 'マウンテン（クロスカントリー）',
        'add_transaction.product_label': '製品',
        'add_transaction.ratings_message':
            'この接客が完了すると、 {data}へこの製品を評価するためのメールが届きます。セッションのリストと詳細ページでこの評価を見ることができます。',
        'add_transaction.road_type': 'ロード',
        'add_transaction.saddle_label': 'サドル',
        'add_transaction.shoe_label': 'シューズ',
        'add_transaction.size_label': 'サイズ',
        'add_transaction.transaction_complete_message': '接客が完了しました。',
        'add_transaction.triathlon_type': 'トライアスロン',
        'add_transaction.tt_tri_type': 'タイムトライアル/トライアスロン',
        'add_transaction.type_label': '種類',
        'analytics.loading_message':
            'データを編集中です。しばらくお待ちください。',
        'analytics_filters.analytics_by': 'で分析',
        'analytics_filters.filter_by': 'でフィルター',
        'analytics_filters.products': '製品',
        'analytics_filters.sessions': 'セッション',
        'charts.bike_label_products': 'バイク',
        'charts.bike_label_sessions': 'バイク',
        'charts.commute_comfort_label': '通勤-コンフォート',
        'charts.commute_sport_label': '通勤-スポーツ',
        'charts.cyclocross_label': 'シクロクロス',
        'charts.female_label': '女性',
        'charts.friday_label': '金曜日',
        'charts.high_arch_label': '高',
        'charts.low_arch_label': '低',
        'charts.male_label': '男性',
        'charts.medium_arch_label': '中',
        'charts.monday_label': '月曜日',
        'charts.mtb_trail_label': 'mtb-トレイル',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'ロード',
        'charts.saddle_label_products': 'サドル',
        'charts.saddle_label_sessions': 'サドル',
        'charts.saturday_label': '土曜日',
        'charts.sessions_by_bike_type_label': 'バイク種類ごとのセッション',
        'charts.sessions_by_day_of_week_label': '曜日ごとのセッション',
        'charts.sessions_by_employee_label': 'スタッフごとのセッション',
        'charts.sessions_by_foot_arch_label': '足のアーチごとのセッション',
        'charts.sessions_by_gender_label': '性別ごとのセッション',
        'charts.sessions_by_product_label': '製品ごとのセッション',
        'charts.sessions_by_saddle_height_label': 'サドル高ごとのセッション',
        'charts.sessions_by_saddle_size_label': 'サドルサイズごとのセッション',
        'charts.sessions_by_shirt_bike_size_label':
            'バイクサイズごとのセッション（Tシャツ）',
        'charts.sessions_by_shoe_size_label': 'シューズサイズごとのセッション',
        'charts.sessions_by_sitbone_width_label': '坐骨結節幅ごとのセッション',
        'charts.sessions_by_standard_bike_size_label':
            'バイクサイズごとのセッション（標準）',
        'charts.sessions_over_time_label': 'これまでのセッション',
        'charts.shoe_label_products': 'シューズ',
        'charts.shoe_label_sessions': 'シューズ',
        'charts.sunday_label': '日曜日',
        'charts.thursday_label': '木曜日',
        'charts.top_ten_session_label': 'トップ10セッションの実施日',
        'charts.top_transactions_label': '接客数が多い実施日',
        'charts.triathlon_label': 'トライアスロン',
        'charts.tuesday_label': '火曜日',
        'charts.wednesday_label': '水曜日',
        'client.all_sessions_link': 'すべてのセッション',
        'client.bike_progress': 'バイク',
        'client.client_progress_heading': '進捗具合',
        'client.client_sessions_heading': '最近のセッション',
        'client.email': 'メールアドレス',
        'client.name': '氏名',
        'client.not_applicable': '該当なし',
        'client.notes_label': 'メモ',
        'client.progress_types_complete': '完了',
        'client.saddle_progress': 'サドル',
        'client.save_cta': '保存',
        'client.shoe_progress': 'シューズ',
        'commute-comfort': '通勤-コンフォート',
        'commute-sport': '通勤-スポーツ',
        'country.ad': 'アンドラ',
        'country.ae': 'アラブ首長国連邦',
        'country.af': 'アフガニスタン',
        'country.ag': 'アンティグアバーブーダ',
        'country.ai': 'アンギラ',
        'country.al': 'アルバニア',
        'country.am': 'アルメニア',
        'country.ao': 'アンゴラ',
        'country.aq': '南極大陸',
        'country.ar': 'アルゼンチン',
        'country.as': 'アメリカ領サモア',
        'country.at': 'オーストリア',
        'country.au': 'オーストラリア',
        'country.aw': 'アルバ',
        'country.ax': 'オーランド諸島',
        'country.az': 'アゼルバイジャン',
        'country.ba': 'ボスニア・ヘルツェゴビナ',
        'country.bb': 'バルバドス',
        'country.bd': 'バングラデシュ',
        'country.be': 'ベルギー',
        'country.bf': 'ブルキナファソ',
        'country.bg': 'ブルガリア',
        'country.bh': 'バーレーン',
        'country.bi': 'ブルンジ',
        'country.bj': 'ベナン',
        'country.bl': 'サンバルテレミー',
        'country.bm': 'バミューダ',
        'country.bn': 'ブルネイ',
        'country.bo': 'ボリビア',
        'country.bq': 'ボネール島、シント・ユースタティウス島、サバ島',
        'country.br': 'ブラジル',
        'country.bs': 'バハマ',
        'country.bt': 'ブータン',
        'country.bv': 'ブーベ島',
        'country.bw': 'ボツワナ',
        'country.by': 'ベラルーシ',
        'country.bz': 'ベリーズ',
        'country.ca': 'カナダ',
        'country.cc': 'ココス (キーリング) 諸島',
        'country.cd': 'コンゴ民主共和国',
        'country.cf': '中央アフリカ共和国',
        'country.cg': 'コンゴ',
        'country.ch': 'スイス',
        'country.ci': 'コートジボワール',
        'country.ck': 'クック諸島',
        'country.cl': 'チリ',
        'country.cm': 'カメルーン',
        'country.cn': '中国',
        'country.co': 'コロンビア',
        'country.cr': 'コスタリカ',
        'country.cu': 'キューバ',
        'country.cv': 'カボヴェルデ',
        'country.cw': 'キュラソー',
        'country.cx': 'クリスマス島',
        'country.cy': 'キプロス',
        'country.cz': 'チェコ',
        'country.de': 'ドイツ',
        'country.dj': 'ジブチ',
        'country.dk': 'デンマーク',
        'country.dm': 'ドミニカ',
        'country.do': 'ドミニカ共和国',
        'country.dz': 'アルジェリア',
        'country.ec': 'エクアドル',
        'country.ee': 'エストニア',
        'country.eg': 'エジプト',
        'country.eh': '西サハラ',
        'country.er': 'エリトリア',
        'country.es': 'スペイン',
        'country.et': 'エチオピア',
        'country.fi': 'フィンランド',
        'country.fj': 'フィジー',
        'country.fk': 'フォークランド諸島[マルビナス]',
        'country.fm': 'ミクロネシア（連邦）',
        'country.fo': 'フェロー諸島',
        'country.fr': 'フランス',
        'country.ga': 'ガボン',
        'country.gb': 'イギリス',
        'country.gd': 'グレナダ',
        'country.ge': 'ジョージア',
        'country.gf': 'フランス領ギアナ',
        'country.gg': 'ガーンジー',
        'country.gh': 'ガーナ',
        'country.gi': 'ジブラルタル',
        'country.gl': 'グリーンランド',
        'country.gm': 'ガンビア',
        'country.gn': 'ギニア',
        'country.gp': 'グアドループ',
        'country.gq': '赤道ギニア',
        'country.gr': 'ギリシャ',
        'country.gs': 'サウスジョージアおよびサウスサンドウィッチ諸島',
        'country.gt': 'グアテマラ',
        'country.gu': 'グアム',
        'country.gw': 'ギニアビサウ',
        'country.gy': 'ガイアナ',
        'country.hk': '香港',
        'country.hm': 'ハード島およびマクドナルド諸島',
        'country.hn': 'ホンジュラス',
        'country.hr': 'クロアチア',
        'country.ht': 'ハイチ',
        'country.hu': 'ハンガリー',
        'country.id': 'インドネシア',
        'country.ie': 'アイルランド',
        'country.il': 'イスラエル',
        'country.im': 'マン島',
        'country.in': 'インド',
        'country.io': 'イギリス領インド洋地域',
        'country.iq': 'イラク',
        'country.ir': 'イラン',
        'country.is': 'アイスランド',
        'country.it': 'イタリア',
        'country.je': 'ジャージー',
        'country.jm': 'ジャマイカ',
        'country.jo': 'ヨルダン',
        'country.jp': '日本',
        'country.ke': 'ケニア',
        'country.kg': 'キルギスタン',
        'country.kh': 'カンボジア',
        'country.ki': 'キリバス',
        'country.km': 'コモロ',
        'country.kn': 'セントクリストファーネイビス',
        'country.kp': '北朝鮮',
        'country.kr': '韓国',
        'country.kw': 'クウェート',
        'country.ky': 'ケイマン諸島',
        'country.kz': 'カザフスタン',
        'country.la': 'ラオス',
        'country.lb': 'レバノン',
        'country.lc': 'セントルシア',
        'country.li': 'リヒテンシュタイン',
        'country.lk': 'スリランカ',
        'country.lr': 'リベリア',
        'country.ls': 'レソト',
        'country.lt': 'リトアニア',
        'country.lu': 'ルクセンブルク',
        'country.lv': 'ラトビア',
        'country.ly': 'リビア',
        'country.ma': 'モロッコ',
        'country.mc': 'モナコ',
        'country.md': 'モルドバ',
        'country.me': 'モンテネグロ',
        'country.mf': 'セントマーチン（フランス領）',
        'country.mg': 'マダガスカル',
        'country.mh': 'マーシャル諸島',
        'country.mk': 'マケドニア',
        'country.ml': 'マリ',
        'country.mm': 'ミャンマー',
        'country.mn': 'モンゴル',
        'country.mo': 'マカオ',
        'country.mp': '北マリアナ諸島',
        'country.mq': 'マルティニーク',
        'country.mr': 'モーリタニア',
        'country.ms': 'モントセラト',
        'country.mt': 'マルタ',
        'country.mu': 'モーリシャス',
        'country.mv': 'モルディブ',
        'country.mw': 'マラウイ',
        'country.mx': 'メキシコ',
        'country.my': 'マレーシア',
        'country.mz': 'モザンビーク',
        'country.na': 'ナミビア',
        'country.nc': 'ニューカレドニア',
        'country.ne': 'ニジェール',
        'country.nf': 'ノーフォーク島',
        'country.ng': 'ナイジェリア',
        'country.ni': 'ニカラグア',
        'country.nl': 'オランダ',
        'country.no': 'ノルウェー',
        'country.np': 'ネパール',
        'country.nr': 'ナウル',
        'country.nu': 'ニウエ',
        'country.nz': 'ニュージーランド',
        'country.om': 'オマーン',
        'country.pa': 'パナマ',
        'country.pe': 'ペルー',
        'country.pf': 'フランス領ポリネシア',
        'country.pg': 'パプアニューギニア',
        'country.ph': 'フィリピン',
        'country.pk': 'パキスタン',
        'country.pl': 'ポーランド',
        'country.pm': 'サンピエール島およびミクロン島',
        'country.pn': 'ピトケアン',
        'country.pr': 'プエルトリコ',
        'country.ps': 'パレスチナ',
        'country.pt': 'ポルトガル',
        'country.pw': 'パラオ',
        'country.py': 'パラグアイ',
        'country.qa': 'カタール',
        'country.re': 'レユニオン',
        'country.ro': 'ルーマニア',
        'country.rs': 'セルビア',
        'country.ru': 'ロシア',
        'country.rw': 'ルワンダ',
        'country.sa': 'サウジアラビア',
        'country.sb': 'ソロモン諸島',
        'country.sc': 'セイシェル',
        'country.sd': 'スーダン',
        'country.se': 'スウェーデン',
        'country.sg': 'シンガポール',
        'country.sh': 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
        'country.si': 'スロベニア',
        'country.sj': 'スヴァールバル諸島およびヤンマイエン島',
        'country.sk': 'スロバキア',
        'country.sl': 'シエラレオネ',
        'country.sm': 'サンマリノ',
        'country.sn': 'セネガル',
        'country.so': 'ソマリア',
        'country.sr': 'スリナム',
        'country.ss': '南スーダン',
        'country.st': 'サントメ・プリンシペ',
        'country.sv': 'エルサルバドル',
        'country.sx': 'シントマールテン島（オランダ領）',
        'country.sy': 'シリア',
        'country.sz': 'スワジランド',
        'country.tc': 'タークス・カイコス諸島',
        'country.td': 'チャド',
        'country.tf': 'フランス領南方・南極地域',
        'country.tg': 'トーゴ',
        'country.th': 'タイ',
        'country.tj': 'タジキスタン',
        'country.tk': 'トケラウ諸島',
        'country.tl': '東ティモール',
        'country.tm': 'トルクメニスタン',
        'country.tn': 'チュニジア',
        'country.to': 'トンガ',
        'country.tr': 'ツバル',
        'country.tt': 'トリニダード・トバゴ',
        'country.tv': 'ツバル',
        'country.tw': '台湾',
        'country.tz': 'タンザニア',
        'country.ua': 'ウクライナ',
        'country.ug': 'ウガンダ',
        'country.um': 'アメリカ合衆国領有小離島',
        'country.us': 'アメリカ合衆国',
        'country.uy': 'ウルグアイ',
        'country.uz': 'ウズベキスタン',
        'country.va': 'バチカン市国',
        'country.vc': 'セントビンセントおよびグレナディーン諸島',
        'country.ve': 'ベネズエラ',
        'country.vg': 'バージン諸島（イギリス）',
        'country.vi': 'バージン諸島（アメリカ）',
        'country.vn': 'ベトナム',
        'country.vu': 'バヌアツ',
        'country.wf': 'ウォリス・フツナ',
        'country.ws': 'サモア',
        'country.ye': 'イエメン',
        'country.yt': 'マヨット島',
        'country.za': '南アフリカ',
        'country.zm': 'ザンビア',
        'country.zw': 'ジンバブエ',
        cyclocross: 'シクロクロス',
        'date.all_time': '日付: 全期間',
        'date.last_month': '日付: 先月',
        'date.last_week': '日付: 先週',
        'date.last_year': '日付: 昨年',
        'employees.add_cta_label': 'スタッフの追加',
        'employees.delete_cta_label': 'スタッフの削除',
        'employees.employee_id_label': 'スタッフID',
        'employees.heading': 'スタッフ',
        'employees.intro': '直近の全スタッフの履歴。',
        'employees.update_cta_label': 'スタッフの更新',
        'filters.all_label': 'すべて',
        'filters.client_all': 'すべて',
        'filters.client_anonymous': '匿名',
        'filters.client_identified': '確認済み',
        'filters.client_label': 'クライアントの種類',
        'filters.employees_label': 'スタッフ',
        'filters.filters_heading': 'フィルター',
        'filters.hide_filters': 'フィルターの表示',
        'filters.locations_label': '位置',
        'filters.rating_all': 'すべて',
        'filters.rating_label': '評価',
        'filters.rating_none': 'なし',
        'filters.session_all': 'すべて',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'セッションの種類',
        'filters.session_match': 'Match',
        'filters.show_filters': 'フィルターの表示',
        'filters.status_all': 'すべて',
        'filters.status_closed': 'クローズ',
        'filters.status_label': 'ステータス',
        'filters.status_open': 'オープン',
        'footer.privacy_link_label': '個人情報保護方針',
        'footer.terms_link_label': '利用規約',
        'form.address_1_field_error': '住所は必須項目です。',
        'form.address_1_field_label': '住所',
        'form.city_field_error': '市町村は必須項目です。',
        'form.city_field_label': '市町村',
        'form.country_field_error': '国は必須項目です。',
        'form.country_field_label': '国',
        'form.delete_field_confirm_message':
            '{data}を削除してもよろしいですか？',
        'form.delete_field_label': '削除',
        'form.edit_field_label': '編集',
        'form.email_field_error':
            'メールは必須であり、有効なメールアドレスでなければなりません。',
        'form.email_field_label': 'メールアドレス',
        'form.name_field_error': '氏名は必須項目です。',
        'form.name_field_label': '氏名',
        'form.shop_field_label': 'リテーラー',
        'form.shops_field_label': 'リテーラー',
        'form.state_field_error': '都道府県は必須項目です。',
        'form.state_field_label': '都道府県',
        'form.zip_field_error': '郵便番号は必須項目です。',
        'form.zip_field_label': '郵便番号',
        'gender.all': '性別: すべて',
        'gender.female': '性別: 女性',
        'gender.male': '性別: 男性',
        'landing.heading': 'リテーラーポータルへようこそ',
        'landing.intro':
            'データ、セッション、およびクライアント情報にアクセスします。',
        'landing.sign_in_or_create_account_label': 'サインイン/アカウント作成',
        'locale.label_cs': 'Česky',
        'locale.label_de': 'Deutsch',
        'locale.label_en': 'English',
        'locale.label_en-us': 'English (US)',
        'locale.label_es': 'Espanol',
        'locale.label_fr': 'Français',
        'locale.label_it': 'Italiano',
        'locale.label_ja': '日本語',
        'locale.label_ko': '한국어',
        'locale.label_nl': 'Nederlands',
        'locale.label_pl': 'Polskie',
        'locale.label_pt': 'Português',
        'locale.label_pt-br': 'Português do Brasil',
        'locale.label_zh-cn': '简体中文',
        'locale.label_zh-tw': '繁體中文',
        'locale.locale_picker_cancel_label': 'キャンセル',
        'locale.locale_picker_heading': '言語を選択してください。',
        'locale.locale_picker_save_label': '保存する',
        'locations.add_cta_label': '位置の追加',
        'locations.delete_cta_label': '位置の削除',
        'locations.empty':
            '位置が追加されていません。Retülアプリを使用するには、位置を追加してください。',
        'locations.empty_cta_label': '位置の管理',
        'locations.heading': '位置の管理',
        'locations.intro': '場所を追加、編集、削除します。',
        'locations.location_id_label': '位置ID',
        'locations.update_cta_label': '位置の更新',
        'metrics.closed_session_tooltip':
            'セッションをクローズすると、クライアントの購入有無にかかわらず、{closingSessions}で売り上げを追跡できます。',
        'metrics.closed_sessions': 'クローズしたセッション',
        'metrics.closing_session_link': 'クロージング中のセッション',
        'metrics.total_sessions': '総セッション数',
        'metrics.unique_client_emails': 'クライアントのEメール',
        'metrics.unique_emails_tooltip':
            'この数字はクライアント間のセッション回数を表します。登録メールアドレスによって特定できます。',
        'mtb-trail': 'マウンテン（トレイル）',
        'mtb-xc': 'マウンテン（クロスカントリー）',
        'nav.analytics': '分析',
        'nav.greeting_label': 'こんにちは、 {data}',
        'nav.manage_employees_link_label': 'スタッフの管理',
        'nav.manage_locations_link_label': '位置の管理',
        'nav.sessions': 'セッション',
        'nav.sign_out_link_label': 'サインアウト',
        'pagination.next': '次',
        'pagination.page': 'ページ',
        'pagination.previous': '前',
        road: 'ロード',
        'session_details.add_transaction_cta_label': '接客の追加',
        'session_details.all_sessions_link': 'すべてのセッション',
        'session_details.bike_product': 'バイク',
        'session_details.client': 'クライアント',
        'session_details.client_review_label': 'クライアントのレビュー',
        'session_details.complete_session_cta_label': 'クローズとしてマーク',
        'session_details.complete_session_message':
            '接客を追加してこのセッションをクローズさせるか、もしくは',
        'session_details.complete_status': 'クローズ',
        'session_details.created_on': '作成日: {data}',
        'session_details.delete_link': '削除',
        'session_details.employee': 'スタッフ: {data}',
        'session_details.family_label': '製品ファミリー',
        'session_details.incomplete_status': 'オープン',
        'session_details.model_label': 'モデル',
        'session_details.no_transactions_message': '接客は追加されていません。',
        'session_details.product_label': '製品',
        'session_details.saddle_product': 'サドル',
        'session_details.shoe_product': 'シューズ',
        'session_details.shop': 'ショップ: {data}',
        'session_details.size_label': 'サイズ',
        'session_details.status_label': 'ステータス',
        'session_details.transactions_heading': 'セッションからの接客',
        'session_products.arch_height_label': 'アーチ高: {data}',
        'session_products.bike_size_label': 'バイクサイズ: {data}',
        'session_products.saddle_height_label': 'サドル高: {data}mm',
        'session_products.saddle_width_label': 'サドル幅: {data}mm',
        'session_products.session_product_label': 'セッションのデータ',
        'session_products.shoe_size_label': 'シューズサイズ: {data}',
        'sessions.add_transaction_cta_label': '取引の追加',
        'sessions.client_anonymous': '匿名',
        'sessions.client_label': 'クライアント',
        'sessions.closed_status': 'クローズド',
        'sessions.csv_downloading_message':
            'CSVファイルに出力するため、すべてのセッションを収集中です。しばらくお待ちください。',
        'sessions.date_label': '日付',
        'sessions.employee_label': 'スタッフ',
        'sessions.export_csv_link': 'リストの書き出し（.CSV）',
        'sessions.filter_empty':
            'フィルターの条件と一致するセッションがありません。フィルターを選び直してください。',
        'sessions.filter_empty_cta_label': 'フィルターのリセット',
        'sessions.heading': 'セッション',
        'sessions.intro':
            'Retülテクノロジーを利用した直近の全セッションのタイムライン。',
        'sessions.location_label': '位置',
        'sessions.no_rating': '評価なし',
        'sessions.open_status': 'オープン',
        'sessions.rating_label': '評価',
        'sessions.session_id_label': 'セッションID',
        'sessions.session_loading_message':
            'すべてのセッションを収集中です。しばらくお待ちください。',
        'sessions.status_label': 'ステータス',
        'sign_in.heading': 'サインイン',
        'sign_in.intro':
            'メールアドレスとパスワードを入力して開始するか、[アカウントを登録]を選択して新しいアカウントを作成してください。',
        'status.all': 'ステータス: すべて',
        'status.complete': 'ステータス: クローズド',
        'status.incomplete': 'ステータス: オープン',
        triathlon: 'トライアスロン',
        'POWER MIMIC DESCRIPTION':
            'このサドルはさまざまな圧迫に体が反応する作用を真似し、軟組織にかかる圧迫を均等にすることで、しびれや痛みを軽減します。',
        'date_picker.all_time': 'すべてのセッション',
        'date_picker.date_range': '期間',
        'date_picker.last_month': '先月',
        'date_picker.last_week': '先週',
        'date_picker.last_year': '昨年',
        'date_picker.this_month': '今月',
        'date_picker.this_week': '今週',
        'date_picker.this_year': '今年',
        'session_details.client_notes': 'クライアントのメモ',
        'session_details.footbed_product': 'フットベッド',
        'session_products.it_width_label': '坐骨結節幅: {data}mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Matchは、お客様の脚の長さを正確に計測して最適なバイクサイズや製品をお勧めする店内用評価ツールです。',
        'body_data.about_saddle':
            'Retül Digital Sitbone Deviceを搭載した没入型店舗体験を提供するRetül Matchは、デジタル圧力マップテクノロジーを利用してお客様の坐骨幅を計測し、正確なサドルサイズを提供してそれにマッチするサドル製品を推奨します。',
        'body_data.about_shoe':
            'Retül Matchは没入型店舗体験を通して足のデータを取得し、正確なアーチサポートのニーズや靴のサイズ、お客様にぴったりの靴を提供します。',
        'body_data.arch_height_label': 'アーチの高さ',
        'body_data.bike_label': 'バイク',
        'body_data.bike_size_label': 'バイクサイズ',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': '周囲',
        'body_data.find_retul_technology': 'Retülテクノロジーを探す',
        'body_data.heading': '身体データ',
        'body_data.intro':
            'Retül Vantageテクノロジーを利用して身体データとライド設定を取得し、お客様に正確なサイズ情報と最適な推奨製品を提供します。',
        'body_data.length_label': '長さ',
        'body_data.load_error':
            '現在お客様の身体データを取得することができません。しばらくしてからもう一度お試しください。',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': '未収集',
        'body_data.recommended_label': '推奨製品',
        'body_data.saddle_height_label': 'サドルの高さ',
        'body_data.saddle_label': 'サドル',
        'body_data.saddle_size_label': 'サドルのサイズ',
        'body_data.session_type': 'Retül{sessionType}',
        'body_data.shin_label': 'すね',
        'body_data.shoe_label': '靴',
        'body_data.shoe_size_label': '靴のサイズ',
        'body_data.sit_bone_label': '坐骨',
        'body_data.starting_label': '開始しています',
        'body_data.thigh_label': '大腿部',
        'body_data.view_bike_matches_label': 'マッチしたバイクを表示',
        'body_data.view_saddle_matches_label': 'マッチしたサドルを表示',
        'body_data.view_shoe_matches_label': 'マッチした靴を表示',
        'body_data.your_progress_completion_label': '{numComplete}/3 完了',
        'body_data.your_progress_label': '進行状況',
        cancel_label: 'キャンセル',
        'create_account.heading': 'アカウントを作成',
        'create_account.intro':
            'メールアドレスとパスワードを入力して、Retül Passportアカウントを作成してください。',
        'edit_form.edit_form_cancel_label': 'キャンセル',
        'edit_form.edit_form_save_label': '保存',
        email_format_error: 'メールアドレスの形式が無効です。',
        email_label: 'メールアドレス',
        'forgot_password.heading': 'パスワードをお忘れの場合',
        'forgot_password.intro':
            'パスワードをお忘れの場合 メールアドレスを入力すると、パスワードリセットのリンクが送信されます。',
        'forgot_password.reset_email_sent':
            'パスワードリセットのメールが{email}宛に送信されました。',
        'landing.create_account_label': 'アカウントを作成',
        'landing.sign_in_label': 'サインイン',
        'nav.account_profiles_link_label': 'アカウント & ライダープロフィール',
        'nav.home_page_link_label': 'ホームページ',
        'nav.logout_link_label': 'ログアウト',
        'not_found.go_home_label': 'ホームへ戻る',
        'not_found.heading': '404',
        'not_found.intro':
            'お探しのページは移動または削除されたか、存在しません。',
        password_invalid_error: 'パスワードが無効です。',
        password_label: 'パスワード',
        'product_matches.bike_size_label': 'バイクサイズ',
        'product_matches.category_filter_commute_comfort_label':
            'コミュート・コンフォート',
        'product_matches.category_filter_commute_sport_label':
            'コミュート・スポーツ',
        'product_matches.category_filter_cyclocross_label': 'シクロクロス',
        'product_matches.category_filter_label': 'カテゴリ',
        'product_matches.category_filter_label_road': 'ロード',
        'product_matches.category_filter_mountain_trail_label':
            'マウンテン（トレイル）',
        'product_matches.category_filter_mountain_xc_label':
            'マウンテン（クロスカントリー）',
        'product_matches.category_filter_triathlon_label': 'トライアスロン',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'この製品タイプに一致する結果を提供するための身体データがありません。上部のフィルターを使用して他の製品タイプを見るか、ロケーションページでこのマッチモジュールを完了できるロケーションを検索してください。',
        'product_matches.find_retul_technology_label':
            'Retülテクノロジーを探す',
        'product_matches.gender_filter_all_label': 'すべての製品を表示',
        'product_matches.gender_filter_female_label': 'レディース製品を表示',
        'product_matches.gender_filter_male_label': 'メンズ製品を表示',
        'product_matches.heading': 'マッチした製品',
        'product_matches.intro':
            'Retülテクノロジーはお客様の身体データとライド設定を利用して、お客様の身体にもっとも合った最適な製品を推奨します。',
        'product_matches.last_session_match_label': '最後のマッチセッション',
        'product_matches.load_error':
            '現在お客様にマッチする製品を取得することができません。しばらくしてからもう一度お試しください。',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'Retül Matchセッションで計測されたバイオメトリクスから、上記のバイクサイズをお勧めします。ここから開始されても良いのですが、お客様の経験レベルと身体タイプに基づいた推奨については、お近くの販売店に相談されることをお勧めします。',
        'product_matches.overlay_bike_size_label': 'バイクサイズ',
        'product_matches.overlay_close_label': '閉じる',
        'product_matches.overlay_indicator_label': 'お客様',
        'product_matches.overlay_saddle_height_details':
            'Retül Matchセッションで計測されたバイオメトリクスから、上記のサドル高さをお勧めします。ここから開始されても良いのですが、お客様の経験レベルと身体タイプに基づいた推奨については、お近くの販売店に相談されることをお勧めします。',
        'product_matches.overlay_saddle_height_label': 'サドルの高さ',
        'product_matches.overlay_saddle_size_details':
            'Retül Matchセッションで計測されたバイオメトリクスから、上記のサドルサイズを推奨します。ここから開始されても良いのですが、お客様の身体データに基づいた推奨については、お近くの販売店に相談されることをお勧めします。',
        'product_matches.overlay_saddle_size_label': 'サドルのサイズ',
        'product_matches.overlay_shoe_size_details':
            'Retül Matchセッションで計測されたバイオメトリクスから、上記の靴サイズを推奨します。ここから開始されても良いのですが、お客様の経験レベルに基づいた推奨については、お近くの販売店に相談されることをお勧めします。',
        'product_matches.overlay_shoe_size_label': '靴のサイズ',
        'product_matches.padding_level_label': 'パッディング・レベル',
        'product_matches.product_type_filter_bikes_label': 'バイク',
        'product_matches.product_type_filter_label': '製品タイプ',
        'product_matches.product_type_filter_saddles_label': 'サドル',
        'product_matches.product_type_filter_shoes_label': '靴',
        'product_matches.recommended_label': '推奨製品',
        'product_matches.saddle_detail_size_label': 'サドルのサイズ：',
        'product_matches.saddle_height_label': 'サドルの高さ',
        'product_matches.saddle_models_label': 'モデル',
        'product_matches.saddle_size_label': 'サドルのサイズ',
        'product_matches.shoe_size_label': '靴のサイズ',
        'product_matches.starting_label': '開始しています',
        'product_matches.view_on_specialized_label':
            'Specializedのページで見る',
        'profile.account_heading': 'アカウント',
        'profile.bike_type_city_label': 'シティ',
        'profile.bike_type_label': 'バイクの種類',
        'profile.bike_type_mountain_label': 'マウンテン',
        'profile.bike_type_road_label': 'ロード',
        'profile.email_error': '有効なメールアドレスを入力してください',
        'profile.email_label': 'メールアドレス',
        'profile.gender_female_label': '女性',
        'profile.gender_label': '性別',
        'profile.gender_male_label': '男性',
        'profile.heading': 'アカウント & ライダープロフィール',
        'profile.intro':
            'アカウント情報とライダープロフィールを編集できます。（近日導入予定）',
        'profile.name_error': '名前を入力してください',
        'profile.name_label': '名前',
        'profile.password_error': 'パスワードを入力してください',
        'profile.password_label': 'パスワード',
        'profile.position_high_label': '高',
        'profile.position_label': 'ポジション',
        'profile.position_low_label': '低',
        'profile.position_medium_label': '中',
        'profile.ride_duration_extended_label': '90分以上',
        'profile.ride_duration_label': 'ライド時間',
        'profile.ride_duration_long_label': '60～90分',
        'profile.ride_duration_medium_label': '30～60分',
        'profile.ride_duration_short_label': '0～30分',
        'profile.ride_frequency_label': '頻度',
        'profile.ride_frequency_once_month_label': '月に1回',
        'profile.ride_frequency_once_week_label': '週に1回',
        'profile.ride_frequency_six_week_label': '週に6回',
        'profile.ride_frequency_three_week_label': '週に3回',
        'routes.account_profile_route_label':
            'アカウント & ライダープロフィール',
        'routes.body_data_route_label': '身体データ',
        'routes.create_account_route_label': 'アカウントを作成',
        'routes.forgot_password_route_label': 'パスワードをお忘れの場合',
        'routes.locations_route_label': 'ロケーション',
        'routes.not_found_route_label': '見つかりません',
        'routes.privacy_policy_route_label': 'プライバシーポリシー',
        'routes.product_matches_route_label': 'マッチした製品',
        'routes.reset_password_route_label': 'パスワードをリセット',
        'routes.set_password_route_label': 'パスワードをリセット',
        'routes.sessions_route_label': 'セッション',
        'routes.sign_in_route_label': 'サインイン',
        'routes.terms_of_service_route_label': 'サービス規約',
        'sessions.download_pdf_cta_label': 'PDFをダウンロード',
        'sessions.empty': 'まだセッションを実行していません。',
        'sessions.empty_cta_label': 'ロケーションを検索',
        'sessions.loading_error':
            '現在お客様のセッションを取得することができません。しばらくしてからもう一度お試しください。',
        'sessions.loading_error_cta_label': 'もう一度お試しください',
        'sessions.session_type_filter_label': 'セッションタイプ',
        'session_type.dfd_session_name': 'Retül Digital Foot Device',
        'session_type.dsd_session_name': 'Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül Foot',
        'session_type.legs_session_name': 'Retül Legs',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül Saddle',
        'session_type.unknown_session_name': 'Retül Session',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': 'サインイン',
        'sign_in.contact_label': 'Retülへのお問い合わせ',
        'sign_in.forgot_password_label': 'パスワードをお忘れの場合',
        'sign_in.sign_in_error':
            'お客様のユーザーデータを取得できません。Retülのサポートまでご連絡ください。',
        submit_label: '送信',
    },
};
