// PARSER TYPES
export const JSON_PARSER = 'JSON_PARSER';
export const TEXT_PARSER = 'TEXT_PARSER';

// HEADERS
export const ACCEPT = 'Accept';
export const APPLICATION_JSON = 'application/json';
export const AUTHORIZATION = 'Authorization';
export const CONTENT_TYPE = 'Content-Type';
export const APPLICATION_TEXT = 'application/text';

//JWT
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH TOKEN';
export const TOKEN_REFRESH_SUCCESSFUL = 'REFRESH SUCCESSFUL';
export const TOKEN_REFRESH_THRESHOLD = 60;

//ERRORS
export const REFRESH_FAILED_ERROR = 'REFRESH FAILED';
export const RETRY_FAILED_ERROR = 'RETRY_FAILED';
export const UNAUTHORIZED_STATUS = '401';
export const BAD_STATUS = '400';

//RESPONSE TYPES
export const ACCEPTED = 'ACCEPTED';

//REST
export const POST = 'POST';
export const GET = 'GET';
export const DELETE = 'DELETE';
export const OPTIONS = 'OPTIONS';
export const PUT = 'PUT';

// LOCAL STORAGE
export const CURRENT_USER_NAME = 'CURRENT_USER_NAME';
export const CURRENT_USER_EMAIL = 'CURRENT_USER_EMAIL';
export const LOCAL_STORAGE_ITEMS = [
  ACCESS_TOKEN,
  CURRENT_USER_EMAIL,
  CURRENT_USER_NAME,
  REFRESH_TOKEN
];
