import { COUNTRY_CODES } from '../types/countryCodes';

// Analytics
export const SESSIONS = 'SESSIONS';
export const PRODUCTS = 'PRODUCTS';
export const ANALYTICS_DATA_TYPES = [ SESSIONS, PRODUCTS ];

// Form Fields
export const LOCATION_FORM_FIELDS = [
  {
    fieldName: 'name',
    fieldLabel: 'form.name_field_label',
    fieldType: 'text',
    required: true,
    errorMessage: 'form.name_field_error',
  },
  {
    fieldName: 'address_1',
    fieldLabel: 'form.address_1_field_label',
    fieldType: 'text',
    required: true,
    errorMessage: 'form.address_1_field_error',
  },
  {
    fieldName: 'city',
    fieldLabel: 'form.city_field_label',
    fieldType: 'text',
    required: true,
    errorMessage: 'form.city_field_error',
  },
  {
    fieldName: 'state',
    fieldLabel: 'form.state_field_label',
    fieldType: 'text',
    required: true,
    errorMessage: 'form.state_field_error',
  },
  {
    fieldName: 'zip_code',
    fieldLabel: 'form.zip_field_label',
    fieldType: 'text',
    required: true,
    errorMessage: 'form.zip_field_error',
  },
  {
    fieldName: 'country',
    fieldLabel: 'form.country_field_label',
    fieldType: 'select',
    listItems: COUNTRY_CODES,
    required: true,
    errorMessage: 'form.country_field_error'
  }
];

export const EMPLOYEE_FORM_FIELDS = [
  {
    fieldName: 'name',
    fieldLabel: 'form.name_field_label',
    fieldType: 'text',
    errorMessage: 'form.name_field_error',
    required: true,
    editable: false,
  },
  {
    fieldName: 'email',
    fieldLabel: 'form.email_field_label',
    fieldType: 'email',
    errorMessage: 'form.email_field_error',
    required: true,
    editable: false,
  }
];

//Transaction Arrays
export const PROGRESS_BAR_LABELS = [
  { transactionStep: 1, label: 'add_transaction.product_label', value: 'product', translateStep: true },
  { transactionStep: 2, label: 'add_transaction.type_label', value: 'type', translateStep: true },
  { transactionStep: 3, label: 'add_transaction.family_label', value: 'family' },
  { transactionStep: 4, label: 'add_transaction.model_label', value: 'model' },
  { transactionStep: 5, label: 'add_transaction.size_label', value: 'size' },
  { transactionStep: 6, label: 'add_transaction.confirm_label', value: 'confirm', translateStep: true },
];

export const PROGRESS_BAR_LABELS_BIKES = [
  { transactionStep: 1, label: 'add_transaction.product_label', value: 'product', translateStep: true },
  { transactionStep: 2, label: 'add_transaction.type_label', value: 'type', translateStep: true },
  { transactionStep: 3, label: 'add_transaction.family_label', value: 'family' },
  { transactionStep: 4, label: 'add_transaction.model_label', value: 'model' },
  { transactionStep: 5, label: 'add_transaction.size_label', value: 'size' },
  { transactionStep: 6, label: 'add_transaction.confirm_label', value: 'confirm', translateStep: true },
];

export const PROGRESS_BAR_LABELS_FOOTBEDS = [
  { transactionStep: 1, label: 'add_transaction.product_label', value: 'product', translateStep: true },
  { transactionStep: 2, label: 'add_transaction.model_label', value: 'model' },
  { transactionStep: 3, label: 'add_transaction.size_label', value: 'size' },
  { transactionStep: 4, label: 'add_transaction.confirm_label', value: 'confirm', translateStep: true },
];

export const TRANSACTIONPRODUCTS = [
  'bike',
  'shoe',
  'saddle',
  'footbed'
];

export const JSON_PARSER = 'JSON_PARSER';
export const TEXT_PARSER = 'TEXT_PARSER';

// CRUD
export const UPDATE_LOCATION = 'Update Location';
export const DELETE_LOCATION = 'Delete Location';

export const ADD_EMPLOYEE = 'Add Employee';
export const UPDATE_EMPLOYEE = 'Update Employee';
export const DELETE_EMPLOYEE = 'Delete Employee';

// Headers
export const ACCEPT = 'Accept';
export const APPLICATION_JSON = 'application/json';
export const AUTHORIZATION = 'Authorization';
export const CONTENT_TYPE = 'Content-Type';
export const APPLICATION_TEXT = 'application/text';

//JWT
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH TOKEN';


//Products
export const SADDLE = 'saddle';
export const BIKE = 'bike';
export const SHOE = 'shoe';

//REST
export const POST = 'POST';
export const GET = 'GET';
export const DELETE = 'DELETE';
export const PUT = 'PUT';

// Search Filters
export const GENDER = 'GENDER';
export const GENDER_SEARCH_FILTERS = [
  {
    'value': 'MALE',
    'label': 'gender.male'
  },
  {
    'value': 'FEMALE',
    'label': 'gender.female'
  },
  {
    'value': 'ALL',
    'label': 'gender.all'
  }
];
export const STATUS = 'STATUS';
export const DATE = 'DATE';
export const ALL = 'ALL';
export const STATUS_SEARCH_FILTERS = [
  {
    'value': 'CLOSED',
    'label': 'status.complete'
  },
  {
    'value': 'OPEN',
    'label': 'status.incomplete'
  },
  {
    'value': 'ALL',
    'label': 'status.all'
  }
];
export const DATE_RANGE = 'DATE RANGE';
export const LAST_WEEK = 'LAST WEEK';
export const LAST_MONTH = 'LAST MONTH';
export const LAST_YEAR = 'LAST YEAR';
export const ALL_TIME = 'ALL TIME';
export const DATE_RANGE_SEARCH_FILTERS = [
  {
    'value': LAST_WEEK,
    'label': 'date.last_week'
  },
  {
    'value': LAST_MONTH,
    'label': 'date.last_month'
  },
  {
    'value': LAST_YEAR,
    'label': 'date.last_year'
  },
  {
    'value': ALL_TIME,
    'label': 'date.all_time'
  },
];

// User
export const CURRENT_USER_NAME = 'CURRENT_USER_NAME';
export const CURRENT_USER_EMAIL = 'CURRENT_USER_EMAIL';

export const RETUL = 'Ret\u00fcl';
