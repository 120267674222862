import React, {Component} from 'react';
import './SessionsSearchFiltersGroup.css';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import * as filtersHelpers from '../../utils/sessionFiltersHelpers';
import debounce from '../../utils/debounce';
import * as animation from './animation.js';
import {injectIntl} from 'react-intl';
import messages from './messages';

class SessionsSearchFiltersGroup extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'filterHeading': intl.formatMessage(messages.filter_heading),
      'showFilters': `${intl.formatMessage(messages.show_filters)} +`,
      'hideFilters': `${intl.formatMessage(messages.hide_filters)} -`,
      'statusFilter': intl.formatMessage(messages.status_filter_label),
      'sessionFilter': intl.formatMessage(messages.session_filter_label),
      'clientFilter': intl.formatMessage(messages.client_filter_label),
      'ratingFilter': intl.formatMessage(messages.rating_filter_label),
      'employeesFilter': intl.formatMessage(messages.employees_filter_label),
      'locationsFilter': intl.formatMessage(messages.locations_filter_label)
    };

    this.state = {
      'filtersOpen': false
    };

    this.toggleControls = this.toggleControls.bind(this);
  }

  componentDidMount() {
    this.resizeHandler = debounce(animation.onWindowResize.bind(this, this.refs.filters));

    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filtersOpen) {
      animation.close(this.refs.filters);
    }
    else {
      animation.open(this.refs.filters);
    }
  }

  toggleControls() {
    this.setState({
      'filtersOpen': !this.state.filtersOpen
    });
  }

  renderCheckboxGroup(heading, name, filters) {
    const {searchParams, updateSearchParams} = this.props;

    return (
      <div className="filter-group-container">
        <CheckboxGroup
          activeCheckboxValues={searchParams[name]}
          heading={heading}
          name={name}
          checkboxLabels={filters}
          onChange={updateSearchParams}
        />
      </div>
    );
  }

  renderRadioButtonGroup(heading, name, filters) {
    const {searchParams, updateSearchParams} = this.props;

    return (
      <div className="filter-group-container">
        <RadioButtonGroup
          activeRadioButtonValue={searchParams[name]}
          filterLabels={filters}
          heading={heading}
          name={name}
          onChange={updateSearchParams}
        />
      </div>
    );
  }

  renderFilterControls() {
    const displayText = this.state.filtersOpen ? this.page.hideFilters : this.page.showFilters;

    return (
      <div className="heading">
        <button className="filter-controls" onClick={this.toggleControls}>
          {displayText}
        </button>
        <p className="filter-heading">
          {this.page.filterHeading}:
        </p>
      </div>
    );
  }

  render() {
    const {sessionsFilters, intl} = this.props;

    return (
      <React.Fragment>
        {this.renderFilterControls()}
        <div className="SessionsSearchFiltersGroup" ref="filters">
          {this.renderRadioButtonGroup(this.page.statusFilter, 'status', filtersHelpers.generateStatusFilters(intl))}
          {this.renderRadioButtonGroup(this.page.sessionFilter, 'sessionType', filtersHelpers.generateSessionFilters(intl))}
          {this.renderRadioButtonGroup(this.page.clientFilter, 'clientType', filtersHelpers.generateClientFilters(intl))}
          {this.renderCheckboxGroup(this.page.ratingFilter, 'ratings', filtersHelpers.generateRatingFilters(intl))}
          {this.renderCheckboxGroup(this.page.employeesFilter, 'employees', filtersHelpers.generateEmployeeFilters(sessionsFilters, intl))}
          {this.renderCheckboxGroup(this.page.locationsFilter, 'locations', filtersHelpers.generateLocationFilters(sessionsFilters, intl))}
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SessionsSearchFiltersGroup);
