import React, {Component} from 'react';
import Button from '../../components/Button/Button';
import Intro from '../../components/Intro/Intro';
import Main from '../../components/Main/Main';

export default class NotFound extends Component {
  render() {
    return (
      <Main className="Landing">
        <section className="top top_alignTop top_fillScreen">
          <div className="container">
            <Intro
              header="404"
              summary="The page you were looking for appears to have been moved, deleted or does not exist."
            />
            <div className="cta-column">
              <Button
                to="/">
                Go Home
              </Button>
            </div>
          </div>
        </section>
      </Main>
    )
  }
}
