import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './text-field.css';

// import {
//     validateText,
//     validateEmail,
//     validateZip,
// } from 'helpers/utilities/validators';
import { validateText, validateEmail, validateZip } from '../../utils/validators';

export default class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.input = null;

        this.state = {
            isError: false,
        };

        this.validate =
            {
                text: validateText,
                email: validateEmail,
                zip: validateZip,
            }[props.type] || validateText;

        this.setUpHandlers();
    }

    setUpHandlers() {
        this.onBlur = this.handleBlur.bind(this);
    }

    handleBlur() {
        const isValid = this.isValid();

        this.setState({ isError: !isValid });
    }

    isValid() {
        const valid = this.validate(this.input.value);
        return valid;
    }

    render() {
        const {
            className,
            disabled,
            id,
            name,
            label,
            type,
            errorMsg,
        } = this.props;

        const textFieldClasses = classNames(
            'field field_floatingLabel',
            { field_hasError: this.state.isError },
            className
        );

        return (
            <div className={textFieldClasses}>
                <label className="field-label" htmlFor={id}>
                    {label}
                </label>
                <input
                    className="field-input"
                    type={type}
                    id={id}
                    name={name}
                    onBlur={this.onBlur}
                    disabled={disabled}
                    ref={(input) => {
                        this.input = input;
                    }}
                />
                <span className="field-error">{errorMsg}</span>
            </div>
        );
    }
}

TextField.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    errorMsg: PropTypes.string,
};
