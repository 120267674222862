import React, {Component} from 'react';
import Metric from '../../components/Metric/Metric';
import './MetricsGroup.css';
import {FormattedMessage, injectIntl} from 'react-intl';
import messages from './messages';


class MetricsGroup extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'uniqueEmailsTooltip': intl.formatMessage(messages.unique_emails_tooltip),
      'closingSessionLink': intl.formatMessage(messages.closing_session)
    };
  }

  closedSessionTooltip() {
    const closingSessions = <a href="/sessions">{this.page.closingSessionLink}</a>;

    return (
      <FormattedMessage {...messages.closed_session_tooltip} values={{'closingSessions': closingSessions}}/>
    );
  }

  uniqueEmailsTooltip() {
    return (
      <p>
        {this.page.uniqueEmailsTooltip}
      </p>
    );
  }

  renderTotalSessionsMetric() {
    const {data} = this.props;
    const metricData = data[0];
    return (
      <Metric
        data={metricData}
      />
    )
  }

  renderClosedSessionMetric() {
    const {data} = this.props;
    const metricData = data[1];
    return (
      <Metric
        data={metricData}
        tooltip={this.closedSessionTooltip()}
      />
    )
  }

  renderUniqueClientEmails() {
    const {data} = this.props;
    const metricData = data[2];
    return (
      <Metric
        data={metricData}
        tooltip={this.uniqueEmailsTooltip()}
      />
    )
  }

  renderStyledBorder() {
    return (
      <div className="border-tip-container">
        <div className="border-tip border-tip-top"/>
        <div className="border-tip border-tip-bottom"/>
      </div>
    )
  }

  render() {
    return (
      <div className="MetricsGroup">
        <div className="metric-container">
          {this.renderTotalSessionsMetric()}
        </div>
        {this.renderStyledBorder()}
        <div className="metric-container">
          {this.renderClosedSessionMetric()}
        </div>
        {this.renderStyledBorder()}
        <div className="metric-container">
          {this.renderUniqueClientEmails()}
        </div>
      </div>
    )

  }
}

export default injectIntl(MetricsGroup);
