import { apiUrl, passportUrl } from '../utils/urlHelper';

export const LOCATIONS_ENDPOINT = apiUrl('shops/');
export const EMPLOYEES_ENDPOINT = apiUrl('employees/');

export const BIKE_TYPES_ENDPOINT = apiUrl('bike-types/');
export const BIKE_FAMILIES_ENDPOINT = apiUrl('bike-families/');
export const BIKE_MODELS_ENDPOINT = apiUrl('bike-models/');
export const BIKE_SIZES_ENDPOINT = apiUrl('bike-sizes/');
export const BIKE_TRANSACTIONS_ENDPOINT = apiUrl('bike-transactions/');

export const SADDLE_TYPES_ENDPOINT = apiUrl('saddle-types/');
export const SADDLE_FAMILIES_ENDPOINT = apiUrl('saddle-families/');
export const SADDLE_MODELS_ENDPOINT = apiUrl('saddle-models/');
export const SADDLE_SIZES_ENDPOINT = apiUrl('saddle-sizes/');
export const SADDLE_TRANSACTIONS_ENDPOINT = apiUrl('saddle-transactions/');

export const SHOE_TYPES_ENDPOINT = apiUrl('shoe-types/');
export const SHOE_FAMILIES_ENDPOINT = apiUrl('shoe-families/');
export const SHOE_MODELS_ENDPOINT = apiUrl('shoe-models/');
export const SHOE_SIZES_ENDPOINT = apiUrl('shoe-sizes/');
export const SHOE_TRANSACTIONS_ENDPOINT = apiUrl('shoe-transactions/');

export const FOOTBED_MODELS_ENDPOINT = apiUrl('footbed-options/');
export const FOOTBED_TRANSACTIONS_ENDPOINT = apiUrl('footbed-transactions/');

export const REQUEST_SESSIONS_ANALYTICS_ENDPOINT = apiUrl(
    'sessions/session-analytics/'
);
export const REQUEST_PRODUCTS_ANALYTICS_ENDPOINT = apiUrl(
    'sessions/product-analytics/'
);
export const REQUEST_SESSIONS_ENDPOINT = apiUrl('sessions/');
export const REQUEST_SESSION_PRODUCT_ENDPOINT = apiUrl(
    'session-latest-product/'
);
export const REQUEST_CLIENT_PRODUCT_ENDPOINT = apiUrl('client-latest-product/');
export const REQUEST_SESSIONS_EXPORT_CSV_ENDPOINT = apiUrl(
    'sessions/export-csv/'
);

export const REQUEST_JWT_ENDPOINT = passportUrl('tokens/pair/');

export const REFRESH_JWT_ENDPOINT = passportUrl('tokens/refresh/');
export const REQUEST_CURRENT_USER_ENDPOINT = passportUrl(
    'users/current/?date_start=2017-10-24&date_end=2017-10-28'
);
export const REQUEST_NEW_ACCOUNT_ENDPOINT = apiUrl('users/');
export const REQUEST_CLIENT_ENDPOINT = apiUrl('client/');
export const CREATE_PASSWORD_RESET_ENDPOINT = passportUrl('reset-password/');

export const REGISTER_USER = passportUrl('register-user/');
export const VERIFY_USER = passportUrl('verify-email/');
export const CONFIRM_PASSWORD_RESET = passportUrl('confirm-password/');

export const NAVIGATION_DROPDOWN_ITEMS = [
    {
        path: '/manage-employees',
        displayedText: 'nav.manage_employees_link_label',
    },
    {
        path: '/manage-locations',
        displayedText: 'nav.manage_locations_link_label',
    },
    { path: '/', displayedText: 'nav.sign_out_link_label', isSignOut: true },
];
