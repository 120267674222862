import React from 'react';

export default function (props) {
  const {label, value} = props;

  return (
    <div className="form-row">
      <div className="form-control form-control_light">
        <span className="label">{label}</span>
      </div>
      <div className="form-control">
        <span>{value}</span>
      </div>
    </div>
  )
}
