import moment from 'moment';
import jwt_decode from 'jwt-decode';
import {
  ACCESS_TOKEN,
  APPLICATION_JSON,
  CONTENT_TYPE,
  CURRENT_USER_EMAIL,
  CURRENT_USER_NAME,
  LOCAL_STORAGE_ITEMS,
  POST,
  REFRESH_FAILED_ERROR,
  REFRESH_TOKEN,
  RETRY_FAILED_ERROR,
  TOKEN_REFRESH_SUCCESSFUL,
  TOKEN_REFRESH_THRESHOLD,
  UNAUTHORIZED_STATUS
} from '../types/apiClient'

import {REFRESH_JWT_ENDPOINT} from '../types/urls';
import {isUnauthorizedStatus, isValidStatus} from '../api/responses';
import {apiFetchConfig} from '../api/apiClient';

export function refreshTokenConfig() {
  const customConfig = {
    customHeaders: {
      [CONTENT_TYPE]: APPLICATION_JSON
    },
    body: JSON.stringify({"refresh": localStorage.getItem(REFRESH_TOKEN)}),
  };
  return apiFetchConfig({customConfig, method: POST});
}

export function setTokens(data) {
  const {access, refresh} = data;
  LOCAL_STORAGE_ITEMS.forEach((key => localStorage.setItem(key, '')));
  localStorage.setItem(ACCESS_TOKEN, access);
  localStorage.setItem(REFRESH_TOKEN, refresh);
  window.location.reload()
}

export function isTokenExpired() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const {exp} = jwt_decode(accessToken);
  const timeElapsed = exp - moment().utc().unix();

  return timeElapsed <= TOKEN_REFRESH_THRESHOLD
}

export async function handleFetchResponse(response) {
  try {
    if (isValidStatus(response)) {
      return await Promise.resolve(response);
    }
    else if (isUnauthorizedStatus(response)) {
      return UNAUTHORIZED_STATUS
    }
  }
  catch (error) {
    return error;
  }
}

export async function fetchTokenRefresh() {
  try {
    const response = await fetch(REFRESH_JWT_ENDPOINT, refreshTokenConfig());
    const result = await handleFetchResponse(response);

    if (result.status === 200) {
      try {
        const data = await result.json();
        setTokens(data);
        return TOKEN_REFRESH_SUCCESSFUL;
      } catch (err) {
        console.error('Unable to parse token data')
      }
    }

    if (result === UNAUTHORIZED_STATUS) {
      console.error(REFRESH_FAILED_ERROR);

      clearLocalStorage();
      window.location.reload()

    }

  } catch (err) {
    console.error(RETRY_FAILED_ERROR, err);
    return RETRY_FAILED_ERROR
  }
}

export function isAuthenticated() {
  return !!localStorage.getItem(ACCESS_TOKEN)
}

export function isCurrentUserNameSet() {
  return !!localStorage.getItem(CURRENT_USER_NAME)
}

export function getCurrentUserName() {
  return localStorage.getItem(CURRENT_USER_NAME);
}

export function setCurrentUser(data) {
  const {email, name} = data;

  localStorage.setItem(CURRENT_USER_EMAIL, email);
  localStorage.setItem(CURRENT_USER_NAME, name);
}

export function clearLocalStorage() {
  LOCAL_STORAGE_ITEMS.forEach((key => localStorage.setItem(key, '')))
}
