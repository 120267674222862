import React, { Component } from 'react';
import makeHash from '../../utils/makeHash';
import Button from '../../components/Button/Button';
import Confirm from './Confirm';
import TransactionLabel from './TransactionLabel';
import './AddTransactionForm.css';
import {
    PROGRESS_BAR_LABELS,
    PROGRESS_BAR_LABELS_BIKES,
    PROGRESS_BAR_LABELS_FOOTBEDS,
    TRANSACTIONPRODUCTS,
} from '../../types/constants';
import { injectIntl } from 'react-intl';
import messages from './messages';

class AddTransactionForm extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;

        this.state = {
            active: false,
            buttonSelected: '',
            familyId: [],
            isConfirmed: false,
            modelId: [],
            name: 'product',
            productName: '',
            step: 1,
            steps: [],
            subtitles: [],
            typeSelected: [],
        };
        this.handleFormStepButtonClick = this.handleFormStepButtonClick.bind(
            this
        );
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchTypes();
    }

    fetchTypes(type) {
        const {
            fetchBikeTypes,
            fetchSaddleTypes,
            fetchShoeTypes,
            fetchFootbedModels,
        } = this.props;

        fetchShoeTypes();
        fetchBikeTypes();
        fetchSaddleTypes();
        fetchFootbedModels();
    }

    renderProgessBarStepLabels() {
        const { name, step, buttonSelected, subtitles } = this.state;

        let progressBar = PROGRESS_BAR_LABELS;
        if (subtitles[0] === 'bike') {
            progressBar = PROGRESS_BAR_LABELS_BIKES;
        } else if (subtitles[0] === 'footbed') {
            progressBar = PROGRESS_BAR_LABELS_FOOTBEDS;
        }

        return progressBar.map((labelObject, index) => {
            const {
                transactionStep,
                label,
                value,
                translateStep,
            } = labelObject;
            const title = subtitles.find((subtitle, i) => i === index);

            return (
                <TransactionLabel
                    intl={this.intl}
                    key={makeHash(label)}
                    title={title}
                    name={name}
                    step={step}
                    translateStep={translateStep}
                    buttonSelected={buttonSelected}
                    index={index}
                    transactionStep={transactionStep}
                    label={this.intl.formatMessage(messages[label])}
                    handleChange={() =>
                        this.handleChange(value, transactionStep, title)
                    }
                />
            );
        });
    }

    progressBarLabelsConfirm() {
        if (this.state.productName === 'footbed') {
            return PROGRESS_BAR_LABELS_FOOTBEDS.map((label) => label.label);
        }
        return PROGRESS_BAR_LABELS.map((label) => label.label);
    }

    handleChange(value, transactionStep, title) {
        this.setState(
            {
                name: value,
                step: transactionStep,
                modelId: [],
                typeSelected: [],
                familyId: [],
                sizeId: [],
            },
            () => this.handleUpdatedStepCount()
        );
    }

    handleUpdatedStepCount() {
        console.log('handleUpdatedStepCount');
        const { step, steps, subtitles } = this.state;

        const updatedStepNumber = step - 1;

        this.setState(
            {
                steps: steps.slice(0, updatedStepNumber),
                subtitles: subtitles.slice(0, updatedStepNumber),
            },
            () => this.renderFormStep()
        );
    }

    renderFormStep() {
        const { name } = this.state;
        switch (name) {
            case 'product':
                return this.renderProductStep();
            case 'type':
                return this.renderTypeStep();
            case 'family':
                return this.renderFamilyStep();
            case 'model':
                return this.renderModelStep();
            case 'size':
                return this.renderSizeStep();
            case 'confirm':
                return this.renderConfirmStep();
            default:
                return this.renderProductStep();
        }
    }

    renderProductStep() {
        return TRANSACTIONPRODUCTS.map((product, index) =>
            this.makeTranslatedButton(product, index)
        );
    }

    renderTypeStep() {
        if (this.state.productName === 'bike') {
            this.renderProgessBarStepLabels();
        }
        return this.selectTypeStep().map((type, index) =>
            this.makeTranslatedButton(type.name, index)
        );
    }

    renderFamilyStep() {
        return this.selectFamilyStep().map((family, index) =>
            this.makeButton(family.name, index)
        );
    }

    renderModelStep() {
        return this.selectModelStep().map((model, index) =>
            this.makeButton(model.name, index)
        );
    }

    renderSizeStep() {
        return this.selectSizeStep().map((size, index) =>
            this.makeButton(size.size, index)
        );
    }

    renderConfirmStep() {
        const { subtitles, isConfirmed, productName } = this.state;
        const { id, client } = this.props.data;

        return (
            <Confirm
                intl={this.intl}
                labels={this.progressBarLabelsConfirm()}
                selections={subtitles}
                isConfirmed={isConfirmed}
                handleConfirmation={() => this.handleConfirmation()}
                sessionId={id}
                client={client}
                product={productName}
            />
        );
    }

    handleConfirmation() {
        this.setState({ isConfirmed: true });

        if (this.state.productName === 'bike') this.bikeTransaction();
        if (this.state.productName === 'saddle') this.saddleTransaction();
        if (this.state.productName === 'shoe') this.shoeTransaction();
        if (this.state.productName === 'footbed') this.footbedTransaction();
    }

    bikeTransaction() {
        let selectedBikeId;
        for (let i = 0; i < this.props.productInfo.bikeSize.length; i++) {
            if (
                this.props.productInfo.bikeSize[i].name ===
                this.state.buttonSelected
            ) {
                selectedBikeId = this.props.productInfo.bikeSize[i].id;
            }
        }
        const data = {
            session: this.props.data.id,
            bike: selectedBikeId,
            mpl_id: this.state.modelId[0].id,
            is_other_brand: false,
        };
        console.log(data);
        this.props.createTransaction(data);
    }

    saddleTransaction(radix) {
        let sizeId = null;
        for (let i = 0; i < this.props.productInfo.saddleSizes.length; i++) {
            if (
                this.state.buttonSelected ===
                this.props.productInfo.saddleSizes[i]['size']
            ) {
                sizeId = this.props.productInfo.saddleSizes[i]['id'];
                break;
            }
        }

        const data = {
            session: this.props.data.id,
            size: sizeId,
            is_other_brand: false,
        };
        this.props.createSaddleTransaction(data);
    }

    shoeTransaction(radix) {
        let sizeId = null;
        for (let i = 0; i < this.props.productInfo.shoeSizes.length; i++) {
            if (
                this.state.buttonSelected ===
                this.props.productInfo.shoeSizes[i]['size']
            ) {
                sizeId = this.props.productInfo.shoeSizes[i]['id'];
                break;
            }
        }

        const data = {
            session: this.props.data.id,
            family: this.state.familyId[0].id,
            size: sizeId,
            is_other_brand: false,
        };
        this.props.createShoeTransaction(data);
    }

    footbedTransaction(radix) {
        const { footbedModels } = this.props.productInfo;
        let footBedId;
        for (let i = 0; i < footbedModels.length; i++) {
            if (this.state.subtitles[1] === footbedModels[i].name) {
                for (let j = 0; j < footbedModels[i].footbeds.length; j++) {
                    if (
                        this.state.subtitles[2] ===
                        footbedModels[i].footbeds[j].size
                    ) {
                        footBedId = footbedModels[i].footbeds[j].id;
                        break;
                    }
                }
            }
        }
        const data = {
            session: this.props.data.id,
            footbed: footBedId,
            is_other_brand: false,
        };

        this.props.createFootbedTransaction(data);
    }

    selectTypeStep() {
        const {
            bikeType,
            saddleType,
            shoeType,
            footbedModels,
        } = this.props.productInfo;

        // console.log("selectTypeStep", this.state);

        const buttonSelected = this.state.subtitles[0];

        let product;
        // console.log(bikeType);

        if (buttonSelected === 'bike') product = bikeType;
        if (buttonSelected === 'saddle') product = saddleType;
        if (buttonSelected === 'shoe') product = shoeType;
        if (buttonSelected === 'footbed') product = footbedModels;

        return product || [];
    }

    selectFamilyStep() {
        const {
            bikeFamily,
            saddleFamily,
            shoeFamily,
            footbedModels,
        } = this.props.productInfo;

        const { productName } = this.state;

        let family;

        if (productName === 'bike') family = bikeFamily;
        if (productName === 'saddle') family = saddleFamily;
        if (productName === 'shoe') family = shoeFamily;
        if (productName === 'footbed') family = footbedModels;

        return family || [];
    }

    selectModelStep() {
        const {
            saddleModel,
            shoeModel,
            bikeModels,
            footbedModels,
        } = this.props.productInfo;

        const { name, buttonSelected, productName } = this.state;

        let prodToComp = productName;
        if (name === 'model' && buttonSelected === 'footbed') {
            prodToComp = buttonSelected;
        }

        let model;

        if (prodToComp === 'bike') {
            let cleaned_bike_models = [];
            for (let year in bikeModels) {
                if (bikeModels.hasOwnProperty(year)) {
                    for (let i = 0; i < bikeModels[year].length; i++) {
                        cleaned_bike_models.push({
                            id: bikeModels[year][i]['mpl_id'],
                            name: `${year} ${bikeModels[year][i]['name']}`,
                        });
                    }
                }
            }

            model = cleaned_bike_models;
        }
        if (prodToComp === 'saddle') model = saddleModel;
        if (prodToComp === 'shoe') model = shoeModel;
        if (prodToComp === 'footbed') model = footbedModels;

        return model || [];
    }

    selectSizeStep() {
        const {
            saddleSizes,
            shoeSizes,
            bikeSize,
            footbedModels,
        } = this.props.productInfo;

        // console.log("selectSizeStep props", this.props);
        // console.log("selectSizeStep state", this.state);
        const { productName } = this.state;

        let bikeSizeModded = bikeSize;
        if (productName === 'bike') {
            if (typeof bikeSizeModded !== 'undefined') {
                for (let i = 0; i < bikeSizeModded.length; i++) {
                    bikeSizeModded[i].size = bikeSizeModded[i].name;
                }
            }
        }
        let footBedSizes;
        if (productName === 'footbed') {
            for (let i = 0; i < footbedModels.length; i++) {
                if (this.state.subtitles[1] === footbedModels[i].name) {
                    footBedSizes = footbedModels[i].footbeds;
                }
            }
        }

        let sizes;
        if (productName === 'bike') sizes = bikeSize;
        if (productName === 'saddle') sizes = saddleSizes;
        if (productName === 'shoe') sizes = shoeSizes;
        if (productName === 'footbed') sizes = footBedSizes;

        return sizes || [];
    }

    makeTranslatedButton(value, index) {
        return (
            <Button
                key={makeHash(`${value}-${index}`)}
                onClick={() => this.handleFormStepButtonClick(value)}
            >
                {this.intl.formatMessage(messages[value])}
            </Button>
        );
    }

    makeButton(value, index) {
        return (
            <Button
                key={makeHash(`${value}-${index}`)}
                onClick={() => this.handleFormStepButtonClick(value)}
            >
                {value}
            </Button>
        );
    }

    handleFormStepButtonClick(value) {
        this.updateSelectionState(value);
    }

    updateSelectionState(value) {
        this.updateCurrentState(value);
        this.handleSelection(value);
    }

    updateCurrentState(value) {
        const { step, subtitles, steps, name, productName } = this.state;

        let prodNameToCompare = productName;
        if (name === 'product') {
            prodNameToCompare = value;
        }
        let nextStep = PROGRESS_BAR_LABELS.find(
            (label) => label.transactionStep === step + 1
        );

        if (prodNameToCompare === 'bike') {
            nextStep = PROGRESS_BAR_LABELS_BIKES.find(
                (label) => label.transactionStep === step + 1
            );
        } else if (prodNameToCompare === 'footbed') {
            nextStep = PROGRESS_BAR_LABELS_FOOTBEDS.find(
                (label) => label.transactionStep === step + 1
            );
        }

        this.setState(
            {
                name: nextStep.value,
                step: nextStep.transactionStep,
                active: true,
                buttonSelected: value,
                subtitles: subtitles.concat(value),
                steps: steps.concat(name),
            },
            () => {
                this.setProductName();
            }
        );
    }

    setProductName() {
        const { subtitles } = this.state;

        this.setState({ productName: subtitles[0] });
    }

    handleSelection(value) {
        console.log('handleSelection state:', this.state);
        console.log('handleSelection value:', value);

        const { name } = this.state;

        let stateParams;

        if (name === 'family') {
            stateParams = { familyId: this.getIdName(value) };
        }
        if (name === 'model') {
            stateParams = { modelId: this.getIdName(value) };
        }
        if (name === 'type') {
            stateParams = { typeSelected: this.getTypeName(value) };
        }

        this.setState(stateParams, () => this.fetch());
    }

    getTypeName(value) {
        return this.selectTypeStep().filter(
            (product) => product.name === value
        );
    }

    getIdName(value) {
        const stepName =
            this.state.name === 'family'
                ? this.selectFamilyStep()
                : this.selectModelStep();
        console.log(stepName.filter((item) => item.name === value));

        return stepName.filter((item) => item.name === value);
    }

    getUrlType() {
        return this.state.typeSelected.map((value) => value.name);
    }

    getUrlFamily() {
        return this.state.familyId.map((id) => id.id);
    }

    getUrlModel() {
        console.log('getUrlModel', this.state);
        if (this.state.productName === 'bike') {
            return this.state.familyId.map((id) => id.id);
        }
        return this.state.modelId.map((id) => id.id);
    }

    fetch() {
        const { typeSelected, familyId, modelId } = this.state;

        if (typeSelected.length > 0) this.fetchFamilies();
        if (familyId.length > 0) this.fetchModels();
        if (modelId.length > 0) this.fetchSizes();
    }

    fetchFamilies() {
        const {
            fetchSaddleFamilies,
            fetchBikeFamilies,
            fetchShoeFamilies,
        } = this.props;

        const product = this.state.subtitles[0];

        const type = `?type=${this.getUrlType()}`;

        if (product === 'saddle') fetchSaddleFamilies(type);
        if (product === 'bike') fetchBikeFamilies(type);
        if (product === 'shoe') fetchShoeFamilies(type);
    }

    fetchModels() {
        const {
            fetchSaddleModels,
            fetchShoeModels,
            fetchBikeModels,
        } = this.props;

        const product = this.state.subtitles[0];

        const family = `?family=${this.getUrlFamily()}`;

        if (product === 'saddle') fetchSaddleModels(family);
        if (product === 'bike') fetchBikeModels(this.getUrlFamily());
        if (product === 'shoe') fetchShoeModels(family);
    }

    fetchSizes() {
        const { fetchSaddleSizes, fetchShoeSizes, fetchBikeSizes } = this.props;

        const product = this.state.subtitles[0];

        let model = this.getUrlModel();
        if (product === 'bike') {
            model = `?family=${model}`;
        } else {
            model = `?model=${model}`;
        }

        if (product === 'saddle') fetchSaddleSizes(model);
        if (product === 'bike') fetchBikeSizes(model);
        if (product === 'shoe') fetchShoeSizes(model);
    }

    render() {
        const { data } = this.props;
        const { sessionId } = data;

        return (
            <div className="AddTransactionForm-container">
                <div className="form-heading">
                    {this.intl.formatMessage(messages.heading, {
                        data: sessionId,
                    })}
                </div>
                <div className="ProgressBar">
                    {this.renderProgessBarStepLabels()}
                </div>
                <div className="AddTransactionForm">
                    <div className="form-step">{this.renderFormStep()}</div>
                </div>
            </div>
        );
    }
}

export default injectIntl(AddTransactionForm);
