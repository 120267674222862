import {defineMessages} from 'react-intl';

export default defineMessages({
  'date.last_week': {
    'id': 'date.last_week',
    'defaultMessage': 'DATE: LAST WEEK',
    'description': 'Last week\'s date range for filtering'
  },
  'date.last_month': {
    'id': 'date.last_month',
    'defaultMessage': 'DATE: LAST MONTH',
    'description': 'Last month\'s date range for filtering'
  },
  'date.last_year': {
    'id': 'date.last_year',
    'defaultMessage': 'DATE: LAST YEAR',
    'description': 'Last year\'s date range for filtering'
  },
  'date.all_time': {
    'id': 'date.all_time',
    'defaultMessage': 'DATE: ALL TIME',
    'description': 'All time date range for filtering'
  },
  'date_picker.date_range': {
    'id': 'date_picker.date_range',
    'defaultMessage': 'Date Range',
    'description': 'Label to show date range.'
  },
  'date_picker.this_week': {
    'id': 'date_picker.this_week',
    'defaultMessage': 'This Week',
    'description': 'Specification for this week.'
  },
  'date_picker.last_week': {
    'id': 'date_picker.last_week',
    'defaultMessage': 'Last Week',
    'description': 'Specification for last week.'
  },
  'date_picker.this_month': {
    'id': 'date_picker.this_month',
    'defaultMessage': 'This Month',
    'description': 'Specification for this month.'
  },
  'date_picker.last_month': {
    'id': 'date_picker.last_month',
    'defaultMessage': 'Last Month',
    'description': 'Specification for last month.'
  },
  'date_picker.this_year': {
    'id': 'date_picker.this_year',
    'defaultMessage': 'This Year',
    'description': 'Specification for this year.'
  },
  'date_picker.last_year': {
    'id': 'date_picker.last_year',
    'defaultMessage': 'Last Year',
    'description': 'Specification for last year.'
  },
  'date_picker.all_time': {
    'id': 'date_picker.all_time',
    'defaultMessage': 'All Sessions',
    'description': 'Specification to obtain all sessions.'
  },
  'status.complete': {
    'id': 'status.complete',
    'defaultMessage': 'STATUS: CLOSED',
    'description': 'Closed status for a session, used for filtering'
  },
  'status.incomplete': {
    'id': 'status.incomplete',
    'defaultMessage': 'STATUS: OPEN',
    'description': 'Open status for a session, used for filtering'
  },
  'status.all': {
    'id': 'status.all',
    'defaultMessage': 'STATUS: ALL',
    'description': 'All statuses for a session, used for filtering'
  },
  'gender.male': {
    'id': 'gender.male',
    'defaultMessage': 'GENDER: MALE',
    'description': 'The male gender, used for filtering'
  },
  'gender.female': {
    'id': 'gender.female',
    'defaultMessage': 'GENDER: FEMALE',
    'description': 'The female gender, used for filtering'
  },
  'gender.all': {
    'id': 'gender.all',
    'defaultMessage': 'GENDER: ALL',
    'description': 'All genders, used for filtering'
  },
  'form.name_field_error': {
    'id': 'form.name_field_error',
    'defaultMessage': 'Name is required.',
    'description': 'Error message in a form when field has an error'
  },
  'form.name_field_label': {
    'id': 'form.name_field_label',
    'defaultMessage': 'Name',
    'description': 'Field label for name field'
  },
  'form.address_1_field_error': {
    'id': 'form.address_1_field_error',
    'defaultMessage': 'Address is required.',
    'description': 'Error message in a form when field has an error'
  },
  'form.address_1_field_label': {
    'id': 'form.address_1_field_label',
    'defaultMessage': 'Address',
    'description': 'Field label for address 1 field'
  },
  'form.city_field_error': {
    'id': 'form.city_field_error',
    'defaultMessage': 'City is required.',
    'description': 'Error message in a form when field has an error'
  },
  'form.city_field_label': {
    'id': 'form.city_field_label',
    'defaultMessage': 'City',
    'description': 'Field label for city field'
  },
  'form.state_field_error': {
    'id': 'form.state_field_error',
    'defaultMessage': 'State is required.',
    'description': 'Error message in a form when field has an error'
  },
  'form.state_field_label': {
    'id': 'form.state_field_label',
    'defaultMessage': 'State',
    'description': 'Field label for state field'
  },
  'form.zip_field_error': {
    'id': 'form.zip_field_error',
    'defaultMessage': 'Zip is required.',
    'description': 'Error message in a form when field has an error'
  },
  'form.zip_field_label': {
    'id': 'form.zip_field_label',
    'defaultMessage': 'Zip',
    'description': 'Field label for zip field'
  },
  'form.country_field_error': {
    'id': 'form.country_field_error',
    'defaultMessage': 'Country is required.',
    'description': 'Error message in a form when field has an error'
  },
  'form.country_field_label': {
    'id': 'form.country_field_label',
    'defaultMessage': 'Country',
    'description': 'Field label for country field'
  },
  'form.email_field_label': {
    'id': 'form.email_field_label',
    'defaultMessage': 'email',
    'description': 'Field label for email field'
  },
  'form.email_field_error': {
    'id': 'form.email_field_error',
    'defaultMessage': 'Email is required and must be a valid email format.',
    'description': 'Error message in a form when email field has an error'
  },
  'form.shop_field_label': {
    'id': 'form.shop_field_label',
    'defaultMessage': 'shop',
    'description': 'Field label for shop field'
  },
  'form_edit_field_label': {
    'id': 'form.edit_field_label',
    'defaultMessage': 'Edit',
    'description': 'Field label for generic edit'
  },
  'form_delete_field_label': {
    'id': 'form.delete_field_label',
    'defaultMessage': 'Delete',
    'description': 'Field label for generic delete'
  },
  'form_delete_field_confirm_message': {
    'id': 'form.delete_field_confirm_message',
    'defaultMessage': 'Are you sure you want to delete {data}?',
    'description': 'Confirmation message for generic delete'
  },
  "country.ad": {
    "id": "country.ad",
    "defaultMessage": "Andorra",
    "description": "Country Name for Andorra"
  },
  "country.ae": {
    "id": "country.ae",
    "defaultMessage": "United Arab Emirates",
    "description": "Country Name for United Arab Emirates"
  },
  "country.af": {
    "id": "country.af",
    "defaultMessage": "Afghanistan",
    "description": "Country Name for Afghanistan"
  },
  "country.ag": {
    "id": "country.ag",
    "defaultMessage": "Antigua and Barbuda",
    "description": "Country Name for Antigua and Barbuda"
  },
  "country.ai": {
    "id": "country.ai",
    "defaultMessage": "Anguilla",
    "description": "Country Name for Anguilla"
  },
  "country.al": {
    "id": "country.al",
    "defaultMessage": "Albania",
    "description": "Country Name for Albania"
  },
  "country.am": {
    "id": "country.am",
    "defaultMessage": "Armenia",
    "description": "Country Name for Armenia"
  },
  "country.ao": {
    "id": "country.ao",
    "defaultMessage": "Angola",
    "description": "Country Name for Angola"
  },
  "country.aq": {
    "id": "country.aq",
    "defaultMessage": "Antarctica",
    "description": "Country Name for Antarctica"
  },
  "country.ar": {
    "id": "country.ar",
    "defaultMessage": "Argentina",
    "description": "Country Name for Argentina"
  },
  "country.as": {
    "id": "country.as",
    "defaultMessage": "American Samoa",
    "description": "Country Name for American Samoa"
  },
  "country.at": {
    "id": "country.at",
    "defaultMessage": "Austria",
    "description": "Country Name for Austria"
  },
  "country.au": {
    "id": "country.au",
    "defaultMessage": "Australia",
    "description": "Country Name for Australia"
  },
  "country.aw": {
    "id": "country.aw",
    "defaultMessage": "Aruba",
    "description": "Country Name for Aruba"
  },
  "country.ax": {
    "id": "country.ax",
    "defaultMessage": "Åland Islands",
    "description": "Country Name for Åland Islands"
  },
  "country.az": {
    "id": "country.az",
    "defaultMessage": "Azerbaijan",
    "description": "Country Name for Azerbaijan"
  },
  "country.ba": {
    "id": "country.ba",
    "defaultMessage": "Bosnia and Herzegovina",
    "description": "Country Name for Bosnia and Herzegovina"
  },
  "country.bb": {
    "id": "country.bb",
    "defaultMessage": "Barbados",
    "description": "Country Name for Barbados"
  },
  "country.bd": {
    "id": "country.bd",
    "defaultMessage": "Bangladesh",
    "description": "Country Name for Bangladesh"
  },
  "country.be": {
    "id": "country.be",
    "defaultMessage": "Belgium",
    "description": "Country Name for Belgium"
  },
  "country.bf": {
    "id": "country.bf",
    "defaultMessage": "Burkina Faso",
    "description": "Country Name for Burkina Faso"
  },
  "country.bg": {
    "id": "country.bg",
    "defaultMessage": "Bulgaria",
    "description": "Country Name for Bulgaria"
  },
  "country.bh": {
    "id": "country.bh",
    "defaultMessage": "Bahrain",
    "description": "Country Name for Bahrain"
  },
  "country.bi": {
    "id": "country.bi",
    "defaultMessage": "Burundi",
    "description": "Country Name for Burundi"
  },
  "country.bj": {
    "id": "country.bj",
    "defaultMessage": "Benin",
    "description": "Country Name for Benin"
  },
  "country.bl": {
    "id": "country.bl",
    "defaultMessage": "Saint Barthélemy",
    "description": "Country Name for Saint Barthélemy"
  },
  "country.bm": {
    "id": "country.bm",
    "defaultMessage": "Bermuda",
    "description": "Country Name for Bermuda"
  },
  "country.bn": {
    "id": "country.bn",
    "defaultMessage": "Brunei",
    "description": "Country Name for Brunei"
  },
  "country.bo": {
    "id": "country.bo",
    "defaultMessage": "Bolivia",
    "description": "Country Name for Bolivia"
  },
  "country.bq": {
    "id": "country.bq",
    "defaultMessage": "Bonaire, Sint Eustatius and Saba",
    "description": "Country Name for Bonaire, Sint Eustatius and Saba"
  },
  "country.br": {
    "id": "country.br",
    "defaultMessage": "Brazil",
    "description": "Country Name for Brazil"
  },
  "country.bs": {
    "id": "country.bs",
    "defaultMessage": "Bahamas",
    "description": "Country Name for Bahamas"
  },
  "country.bt": {
    "id": "country.bt",
    "defaultMessage": "Bhutan",
    "description": "Country Name for Bhutan"
  },
  "country.bv": {
    "id": "country.bv",
    "defaultMessage": "Bouvet Island",
    "description": "Country Name for Bouvet Island"
  },
  "country.bw": {
    "id": "country.bw",
    "defaultMessage": "Botswana",
    "description": "Country Name for Botswana"
  },
  "country.by": {
    "id": "country.by",
    "defaultMessage": "Belarus",
    "description": "Country Name for Belarus"
  },
  "country.bz": {
    "id": "country.bz",
    "defaultMessage": "Belize",
    "description": "Country Name for Belize"
  },
  "country.ca": {
    "id": "country.ca",
    "defaultMessage": "Canada",
    "description": "Country Name for Canada"
  },
  "country.cc": {
    "id": "country.cc",
    "defaultMessage": "Cocos (Keeling) Islands",
    "description": "Country Name for Cocos (Keeling) Islands"
  },
  "country.cd": {
    "id": "country.cd",
    "defaultMessage": "Congo (the Democratic Republic of the)",
    "description": "Country Name for Congo (the Democratic Republic of the)"
  },
  "country.cf": {
    "id": "country.cf",
    "defaultMessage": "Central African Republic",
    "description": "Country Name for Central African Republic"
  },
  "country.cg": {
    "id": "country.cg",
    "defaultMessage": "Congo",
    "description": "Country Name for Congo"
  },
  "country.ch": {
    "id": "country.ch",
    "defaultMessage": "Switzerland",
    "description": "Country Name for Switzerland"
  },
  "country.ci": {
    "id": "country.ci",
    "defaultMessage": "Côte d'Ivoire",
    "description": "Country Name for Côte d'Ivoire"
  },
  "country.ck": {
    "id": "country.ck",
    "defaultMessage": "Cook Islands",
    "description": "Country Name for Cook Islands"
  },
  "country.cl": {
    "id": "country.cl",
    "defaultMessage": "Chile",
    "description": "Country Name for Chile"
  },
  "country.cm": {
    "id": "country.cm",
    "defaultMessage": "Cameroon",
    "description": "Country Name for Cameroon"
  },
  "country.cn": {
    "id": "country.cn",
    "defaultMessage": "China",
    "description": "Country Name for China"
  },
  "country.co": {
    "id": "country.co",
    "defaultMessage": "Colombia",
    "description": "Country Name for Colombia"
  },
  "country.cr": {
    "id": "country.cr",
    "defaultMessage": "Costa Rica",
    "description": "Country Name for Costa Rica"
  },
  "country.cu": {
    "id": "country.cu",
    "defaultMessage": "Cuba",
    "description": "Country Name for Cuba"
  },
  "country.cv": {
    "id": "country.cv",
    "defaultMessage": "Cabo Verde",
    "description": "Country Name for Cabo Verde"
  },
  "country.cw": {
    "id": "country.cw",
    "defaultMessage": "Curaçao",
    "description": "Country Name for Curaçao"
  },
  "country.cx": {
    "id": "country.cx",
    "defaultMessage": "Christmas Island",
    "description": "Country Name for Christmas Island"
  },
  "country.cy": {
    "id": "country.cy",
    "defaultMessage": "Cyprus",
    "description": "Country Name for Cyprus"
  },
  "country.cz": {
    "id": "country.cz",
    "defaultMessage": "Czechia",
    "description": "Country Name for Czechia"
  },
  "country.de": {
    "id": "country.de",
    "defaultMessage": "Germany",
    "description": "Country Name for Germany"
  },
  "country.dj": {
    "id": "country.dj",
    "defaultMessage": "Djibouti",
    "description": "Country Name for Djibouti"
  },
  "country.dk": {
    "id": "country.dk",
    "defaultMessage": "Denmark",
    "description": "Country Name for Denmark"
  },
  "country.dm": {
    "id": "country.dm",
    "defaultMessage": "Dominica",
    "description": "Country Name for Dominica"
  },
  "country.do": {
    "id": "country.do",
    "defaultMessage": "Dominican Republic",
    "description": "Country Name for Dominican Republic"
  },
  "country.dz": {
    "id": "country.dz",
    "defaultMessage": "Algeria",
    "description": "Country Name for Algeria"
  },
  "country.ec": {
    "id": "country.ec",
    "defaultMessage": "Ecuador",
    "description": "Country Name for Ecuador"
  },
  "country.ee": {
    "id": "country.ee",
    "defaultMessage": "Estonia",
    "description": "Country Name for Estonia"
  },
  "country.eg": {
    "id": "country.eg",
    "defaultMessage": "Egypt",
    "description": "Country Name for Egypt"
  },
  "country.eh": {
    "id": "country.eh",
    "defaultMessage": "Western Sahara",
    "description": "Country Name for Western Sahara"
  },
  "country.er": {
    "id": "country.er",
    "defaultMessage": "Eritrea",
    "description": "Country Name for Eritrea"
  },
  "country.es": {
    "id": "country.es",
    "defaultMessage": "Spain",
    "description": "Country Name for Spain"
  },
  "country.et": {
    "id": "country.et",
    "defaultMessage": "Ethiopia",
    "description": "Country Name for Ethiopia"
  },
  "country.fi": {
    "id": "country.fi",
    "defaultMessage": "Finland",
    "description": "Country Name for Finland"
  },
  "country.fj": {
    "id": "country.fj",
    "defaultMessage": "Fiji",
    "description": "Country Name for Fiji"
  },
  "country.fk": {
    "id": "country.fk",
    "defaultMessage": "Falkland Islands  [Malvinas]",
    "description": "Country Name for Falkland Islands  [Malvinas]"
  },
  "country.fm": {
    "id": "country.fm",
    "defaultMessage": "Micronesia (Federated States of)",
    "description": "Country Name for Micronesia (Federated States of)"
  },
  "country.fo": {
    "id": "country.fo",
    "defaultMessage": "Faroe Islands",
    "description": "Country Name for Faroe Islands"
  },
  "country.fr": {
    "id": "country.fr",
    "defaultMessage": "France",
    "description": "Country Name for France"
  },
  "country.ga": {
    "id": "country.ga",
    "defaultMessage": "Gabon",
    "description": "Country Name for Gabon"
  },
  "country.gb": {
    "id": "country.gb",
    "defaultMessage": "United Kingdom",
    "description": "Country Name for United Kingdom"
  },
  "country.gd": {
    "id": "country.gd",
    "defaultMessage": "Grenada",
    "description": "Country Name for Grenada"
  },
  "country.ge": {
    "id": "country.ge",
    "defaultMessage": "Georgia",
    "description": "Country Name for Georgia"
  },
  "country.gf": {
    "id": "country.gf",
    "defaultMessage": "French Guiana",
    "description": "Country Name for French Guiana"
  },
  "country.gg": {
    "id": "country.gg",
    "defaultMessage": "Guernsey",
    "description": "Country Name for Guernsey"
  },
  "country.gh": {
    "id": "country.gh",
    "defaultMessage": "Ghana",
    "description": "Country Name for Ghana"
  },
  "country.gi": {
    "id": "country.gi",
    "defaultMessage": "Gibraltar",
    "description": "Country Name for Gibraltar"
  },
  "country.gl": {
    "id": "country.gl",
    "defaultMessage": "Greenland",
    "description": "Country Name for Greenland"
  },
  "country.gm": {
    "id": "country.gm",
    "defaultMessage": "Gambia",
    "description": "Country Name for Gambia"
  },
  "country.gn": {
    "id": "country.gn",
    "defaultMessage": "Guinea",
    "description": "Country Name for Guinea"
  },
  "country.gp": {
    "id": "country.gp",
    "defaultMessage": "Guadeloupe",
    "description": "Country Name for Guadeloupe"
  },
  "country.gq": {
    "id": "country.gq",
    "defaultMessage": "Equatorial Guinea",
    "description": "Country Name for Equatorial Guinea"
  },
  "country.gr": {
    "id": "country.gr",
    "defaultMessage": "Greece",
    "description": "Country Name for Greece"
  },
  "country.gs": {
    "id": "country.gs",
    "defaultMessage": "South Georgia and the South Sandwich Islands",
    "description": "Country Name for South Georgia and the South Sandwich Islands"
  },
  "country.gt": {
    "id": "country.gt",
    "defaultMessage": "Guatemala",
    "description": "Country Name for Guatemala"
  },
  "country.gu": {
    "id": "country.gu",
    "defaultMessage": "Guam",
    "description": "Country Name for Guam"
  },
  "country.gw": {
    "id": "country.gw",
    "defaultMessage": "Guinea-Bissau",
    "description": "Country Name for Guinea-Bissau"
  },
  "country.gy": {
    "id": "country.gy",
    "defaultMessage": "Guyana",
    "description": "Country Name for Guyana"
  },
  "country.hk": {
    "id": "country.hk",
    "defaultMessage": "Hong Kong",
    "description": "Country Name for Hong Kong"
  },
  "country.hm": {
    "id": "country.hm",
    "defaultMessage": "Heard Island and McDonald Islands",
    "description": "Country Name for Heard Island and McDonald Islands"
  },
  "country.hn": {
    "id": "country.hn",
    "defaultMessage": "Honduras",
    "description": "Country Name for Honduras"
  },
  "country.hr": {
    "id": "country.hr",
    "defaultMessage": "Croatia",
    "description": "Country Name for Croatia"
  },
  "country.ht": {
    "id": "country.ht",
    "defaultMessage": "Haiti",
    "description": "Country Name for Haiti"
  },
  "country.hu": {
    "id": "country.hu",
    "defaultMessage": "Hungary",
    "description": "Country Name for Hungary"
  },
  "country.id": {
    "id": "country.id",
    "defaultMessage": "Indonesia",
    "description": "Country Name for Indonesia"
  },
  "country.ie": {
    "id": "country.ie",
    "defaultMessage": "Ireland",
    "description": "Country Name for Ireland"
  },
  "country.il": {
    "id": "country.il",
    "defaultMessage": "Israel",
    "description": "Country Name for Israel"
  },
  "country.im": {
    "id": "country.im",
    "defaultMessage": "Isle of Man",
    "description": "Country Name for Isle of Man"
  },
  "country.in": {
    "id": "country.in",
    "defaultMessage": "India",
    "description": "Country Name for India"
  },
  "country.io": {
    "id": "country.io",
    "defaultMessage": "British Indian Ocean Territory",
    "description": "Country Name for British Indian Ocean Territory"
  },
  "country.iq": {
    "id": "country.iq",
    "defaultMessage": "Iraq",
    "description": "Country Name for Iraq"
  },
  "country.ir": {
    "id": "country.ir",
    "defaultMessage": "Iran",
    "description": "Country Name for Iran"
  },
  "country.is": {
    "id": "country.is",
    "defaultMessage": "Iceland",
    "description": "Country Name for Iceland"
  },
  "country.it": {
    "id": "country.it",
    "defaultMessage": "Italy",
    "description": "Country Name for Italy"
  },
  "country.je": {
    "id": "country.je",
    "defaultMessage": "Jersey",
    "description": "Country Name for Jersey"
  },
  "country.jm": {
    "id": "country.jm",
    "defaultMessage": "Jamaica",
    "description": "Country Name for Jamaica"
  },
  "country.jo": {
    "id": "country.jo",
    "defaultMessage": "Jordan",
    "description": "Country Name for Jordan"
  },
  "country.jp": {
    "id": "country.jp",
    "defaultMessage": "Japan",
    "description": "Country Name for Japan"
  },
  "country.ke": {
    "id": "country.ke",
    "defaultMessage": "Kenya",
    "description": "Country Name for Kenya"
  },
  "country.kg": {
    "id": "country.kg",
    "defaultMessage": "Kyrgyzstan",
    "description": "Country Name for Kyrgyzstan"
  },
  "country.kh": {
    "id": "country.kh",
    "defaultMessage": "Cambodia",
    "description": "Country Name for Cambodia"
  },
  "country.ki": {
    "id": "country.ki",
    "defaultMessage": "Kiribati",
    "description": "Country Name for Kiribati"
  },
  "country.km": {
    "id": "country.km",
    "defaultMessage": "Comoros",
    "description": "Country Name for Comoros"
  },
  "country.kn": {
    "id": "country.kn",
    "defaultMessage": "Saint Kitts and Nevis",
    "description": "Country Name for Saint Kitts and Nevis"
  },
  "country.kp": {
    "id": "country.kp",
    "defaultMessage": "North Korea",
    "description": "Country Name for North Korea"
  },
  "country.kr": {
    "id": "country.kr",
    "defaultMessage": "South Korea",
    "description": "Country Name for South Korea"
  },
  "country.kw": {
    "id": "country.kw",
    "defaultMessage": "Kuwait",
    "description": "Country Name for Kuwait"
  },
  "country.ky": {
    "id": "country.ky",
    "defaultMessage": "Cayman Islands",
    "description": "Country Name for Cayman Islands"
  },
  "country.kz": {
    "id": "country.kz",
    "defaultMessage": "Kazakhstan",
    "description": "Country Name for Kazakhstan"
  },
  "country.la": {
    "id": "country.la",
    "defaultMessage": "Laos",
    "description": "Country Name for Laos"
  },
  "country.lb": {
    "id": "country.lb",
    "defaultMessage": "Lebanon",
    "description": "Country Name for Lebanon"
  },
  "country.lc": {
    "id": "country.lc",
    "defaultMessage": "Saint Lucia",
    "description": "Country Name for Saint Lucia"
  },
  "country.li": {
    "id": "country.li",
    "defaultMessage": "Liechtenstein",
    "description": "Country Name for Liechtenstein"
  },
  "country.lk": {
    "id": "country.lk",
    "defaultMessage": "Sri Lanka",
    "description": "Country Name for Sri Lanka"
  },
  "country.lr": {
    "id": "country.lr",
    "defaultMessage": "Liberia",
    "description": "Country Name for Liberia"
  },
  "country.ls": {
    "id": "country.ls",
    "defaultMessage": "Lesotho",
    "description": "Country Name for Lesotho"
  },
  "country.lt": {
    "id": "country.lt",
    "defaultMessage": "Lithuania",
    "description": "Country Name for Lithuania"
  },
  "country.lu": {
    "id": "country.lu",
    "defaultMessage": "Luxembourg",
    "description": "Country Name for Luxembourg"
  },
  "country.lv": {
    "id": "country.lv",
    "defaultMessage": "Latvia",
    "description": "Country Name for Latvia"
  },
  "country.ly": {
    "id": "country.ly",
    "defaultMessage": "Libya",
    "description": "Country Name for Libya"
  },
  "country.ma": {
    "id": "country.ma",
    "defaultMessage": "Morocco",
    "description": "Country Name for Morocco"
  },
  "country.mc": {
    "id": "country.mc",
    "defaultMessage": "Monaco",
    "description": "Country Name for Monaco"
  },
  "country.md": {
    "id": "country.md",
    "defaultMessage": "Moldova",
    "description": "Country Name for Moldova"
  },
  "country.me": {
    "id": "country.me",
    "defaultMessage": "Montenegro",
    "description": "Country Name for Montenegro"
  },
  "country.mf": {
    "id": "country.mf",
    "defaultMessage": "Saint Martin (French part)",
    "description": "Country Name for Saint Martin (French part)"
  },
  "country.mg": {
    "id": "country.mg",
    "defaultMessage": "Madagascar",
    "description": "Country Name for Madagascar"
  },
  "country.mh": {
    "id": "country.mh",
    "defaultMessage": "Marshall Islands",
    "description": "Country Name for Marshall Islands"
  },
  "country.mk": {
    "id": "country.mk",
    "defaultMessage": "Macedonia",
    "description": "Country Name for Macedonia"
  },
  "country.ml": {
    "id": "country.ml",
    "defaultMessage": "Mali",
    "description": "Country Name for Mali"
  },
  "country.mm": {
    "id": "country.mm",
    "defaultMessage": "Myanmar",
    "description": "Country Name for Myanmar"
  },
  "country.mn": {
    "id": "country.mn",
    "defaultMessage": "Mongolia",
    "description": "Country Name for Mongolia"
  },
  "country.mo": {
    "id": "country.mo",
    "defaultMessage": "Macao",
    "description": "Country Name for Macao"
  },
  "country.mp": {
    "id": "country.mp",
    "defaultMessage": "Northern Mariana Islands",
    "description": "Country Name for Northern Mariana Islands"
  },
  "country.mq": {
    "id": "country.mq",
    "defaultMessage": "Martinique",
    "description": "Country Name for Martinique"
  },
  "country.mr": {
    "id": "country.mr",
    "defaultMessage": "Mauritania",
    "description": "Country Name for Mauritania"
  },
  "country.ms": {
    "id": "country.ms",
    "defaultMessage": "Montserrat",
    "description": "Country Name for Montserrat"
  },
  "country.mt": {
    "id": "country.mt",
    "defaultMessage": "Malta",
    "description": "Country Name for Malta"
  },
  "country.mu": {
    "id": "country.mu",
    "defaultMessage": "Mauritius",
    "description": "Country Name for Mauritius"
  },
  "country.mv": {
    "id": "country.mv",
    "defaultMessage": "Maldives",
    "description": "Country Name for Maldives"
  },
  "country.mw": {
    "id": "country.mw",
    "defaultMessage": "Malawi",
    "description": "Country Name for Malawi"
  },
  "country.mx": {
    "id": "country.mx",
    "defaultMessage": "Mexico",
    "description": "Country Name for Mexico"
  },
  "country.my": {
    "id": "country.my",
    "defaultMessage": "Malaysia",
    "description": "Country Name for Malaysia"
  },
  "country.mz": {
    "id": "country.mz",
    "defaultMessage": "Mozambique",
    "description": "Country Name for Mozambique"
  },
  "country.na": {
    "id": "country.na",
    "defaultMessage": "Namibia",
    "description": "Country Name for Namibia"
  },
  "country.nc": {
    "id": "country.nc",
    "defaultMessage": "New Caledonia",
    "description": "Country Name for New Caledonia"
  },
  "country.ne": {
    "id": "country.ne",
    "defaultMessage": "Niger",
    "description": "Country Name for Niger"
  },
  "country.nf": {
    "id": "country.nf",
    "defaultMessage": "Norfolk Island",
    "description": "Country Name for Norfolk Island"
  },
  "country.ng": {
    "id": "country.ng",
    "defaultMessage": "Nigeria",
    "description": "Country Name for Nigeria"
  },
  "country.ni": {
    "id": "country.ni",
    "defaultMessage": "Nicaragua",
    "description": "Country Name for Nicaragua"
  },
  "country.nl": {
    "id": "country.nl",
    "defaultMessage": "Netherlands",
    "description": "Country Name for Netherlands"
  },
  "country.no": {
    "id": "country.no",
    "defaultMessage": "Norway",
    "description": "Country Name for Norway"
  },
  "country.np": {
    "id": "country.np",
    "defaultMessage": "Nepal",
    "description": "Country Name for Nepal"
  },
  "country.nr": {
    "id": "country.nr",
    "defaultMessage": "Nauru",
    "description": "Country Name for Nauru"
  },
  "country.nu": {
    "id": "country.nu",
    "defaultMessage": "Niue",
    "description": "Country Name for Niue"
  },
  "country.nz": {
    "id": "country.nz",
    "defaultMessage": "New Zealand",
    "description": "Country Name for New Zealand"
  },
  "country.om": {
    "id": "country.om",
    "defaultMessage": "Oman",
    "description": "Country Name for Oman"
  },
  "country.pa": {
    "id": "country.pa",
    "defaultMessage": "Panama",
    "description": "Country Name for Panama"
  },
  "country.pe": {
    "id": "country.pe",
    "defaultMessage": "Peru",
    "description": "Country Name for Peru"
  },
  "country.pf": {
    "id": "country.pf",
    "defaultMessage": "French Polynesia",
    "description": "Country Name for French Polynesia"
  },
  "country.pg": {
    "id": "country.pg",
    "defaultMessage": "Papua New Guinea",
    "description": "Country Name for Papua New Guinea"
  },
  "country.ph": {
    "id": "country.ph",
    "defaultMessage": "Philippines",
    "description": "Country Name for Philippines"
  },
  "country.pk": {
    "id": "country.pk",
    "defaultMessage": "Pakistan",
    "description": "Country Name for Pakistan"
  },
  "country.pl": {
    "id": "country.pl",
    "defaultMessage": "Poland",
    "description": "Country Name for Poland"
  },
  "country.pm": {
    "id": "country.pm",
    "defaultMessage": "Saint Pierre and Miquelon",
    "description": "Country Name for Saint Pierre and Miquelon"
  },
  "country.pn": {
    "id": "country.pn",
    "defaultMessage": "Pitcairn",
    "description": "Country Name for Pitcairn"
  },
  "country.pr": {
    "id": "country.pr",
    "defaultMessage": "Puerto Rico",
    "description": "Country Name for Puerto Rico"
  },
  "country.ps": {
    "id": "country.ps",
    "defaultMessage": "Palestine, State of",
    "description": "Country Name for Palestine, State of"
  },
  "country.pt": {
    "id": "country.pt",
    "defaultMessage": "Portugal",
    "description": "Country Name for Portugal"
  },
  "country.pw": {
    "id": "country.pw",
    "defaultMessage": "Palau",
    "description": "Country Name for Palau"
  },
  "country.py": {
    "id": "country.py",
    "defaultMessage": "Paraguay",
    "description": "Country Name for Paraguay"
  },
  "country.qa": {
    "id": "country.qa",
    "defaultMessage": "Qatar",
    "description": "Country Name for Qatar"
  },
  "country.re": {
    "id": "country.re",
    "defaultMessage": "Réunion",
    "description": "Country Name for Réunion"
  },
  "country.ro": {
    "id": "country.ro",
    "defaultMessage": "Romania",
    "description": "Country Name for Romania"
  },
  "country.rs": {
    "id": "country.rs",
    "defaultMessage": "Serbia",
    "description": "Country Name for Serbia"
  },
  "country.ru": {
    "id": "country.ru",
    "defaultMessage": "Russia",
    "description": "Country Name for Russia"
  },
  "country.rw": {
    "id": "country.rw",
    "defaultMessage": "Rwanda",
    "description": "Country Name for Rwanda"
  },
  "country.sa": {
    "id": "country.sa",
    "defaultMessage": "Saudi Arabia",
    "description": "Country Name for Saudi Arabia"
  },
  "country.sb": {
    "id": "country.sb",
    "defaultMessage": "Solomon Islands",
    "description": "Country Name for Solomon Islands"
  },
  "country.sc": {
    "id": "country.sc",
    "defaultMessage": "Seychelles",
    "description": "Country Name for Seychelles"
  },
  "country.sd": {
    "id": "country.sd",
    "defaultMessage": "Sudan",
    "description": "Country Name for Sudan"
  },
  "country.se": {
    "id": "country.se",
    "defaultMessage": "Sweden",
    "description": "Country Name for Sweden"
  },
  "country.sg": {
    "id": "country.sg",
    "defaultMessage": "Singapore",
    "description": "Country Name for Singapore"
  },
  "country.sh": {
    "id": "country.sh",
    "defaultMessage": "Saint Helena, Ascension and Tristan da Cunha",
    "description": "Country Name for Saint Helena, Ascension and Tristan da Cunha"
  },
  "country.si": {
    "id": "country.si",
    "defaultMessage": "Slovenia",
    "description": "Country Name for Slovenia"
  },
  "country.sj": {
    "id": "country.sj",
    "defaultMessage": "Svalbard and Jan Mayen",
    "description": "Country Name for Svalbard and Jan Mayen"
  },
  "country.sk": {
    "id": "country.sk",
    "defaultMessage": "Slovakia",
    "description": "Country Name for Slovakia"
  },
  "country.sl": {
    "id": "country.sl",
    "defaultMessage": "Sierra Leone",
    "description": "Country Name for Sierra Leone"
  },
  "country.sm": {
    "id": "country.sm",
    "defaultMessage": "San Marino",
    "description": "Country Name for San Marino"
  },
  "country.sn": {
    "id": "country.sn",
    "defaultMessage": "Senegal",
    "description": "Country Name for Senegal"
  },
  "country.so": {
    "id": "country.so",
    "defaultMessage": "Somalia",
    "description": "Country Name for Somalia"
  },
  "country.sr": {
    "id": "country.sr",
    "defaultMessage": "Suriname",
    "description": "Country Name for Suriname"
  },
  "country.ss": {
    "id": "country.ss",
    "defaultMessage": "South Sudan",
    "description": "Country Name for South Sudan"
  },
  "country.st": {
    "id": "country.st",
    "defaultMessage": "Sao Tome and Principe",
    "description": "Country Name for Sao Tome and Principe"
  },
  "country.sv": {
    "id": "country.sv",
    "defaultMessage": "El Salvador",
    "description": "Country Name for El Salvador"
  },
  "country.sx": {
    "id": "country.sx",
    "defaultMessage": "Sint Maarten (Dutch part)",
    "description": "Country Name for Sint Maarten (Dutch part)"
  },
  "country.sy": {
    "id": "country.sy",
    "defaultMessage": "Syria",
    "description": "Country Name for Syria"
  },
  "country.sz": {
    "id": "country.sz",
    "defaultMessage": "Swaziland",
    "description": "Country Name for Swaziland"
  },
  "country.tc": {
    "id": "country.tc",
    "defaultMessage": "Turks and Caicos Islands",
    "description": "Country Name for Turks and Caicos Islands"
  },
  "country.td": {
    "id": "country.td",
    "defaultMessage": "Chad",
    "description": "Country Name for Chad"
  },
  "country.tf": {
    "id": "country.tf",
    "defaultMessage": "French Southern Territories",
    "description": "Country Name for French Southern Territories"
  },
  "country.tg": {
    "id": "country.tg",
    "defaultMessage": "Togo",
    "description": "Country Name for Togo"
  },
  "country.th": {
    "id": "country.th",
    "defaultMessage": "Thailand",
    "description": "Country Name for Thailand"
  },
  "country.tj": {
    "id": "country.tj",
    "defaultMessage": "Tajikistan",
    "description": "Country Name for Tajikistan"
  },
  "country.tk": {
    "id": "country.tk",
    "defaultMessage": "Tokelau",
    "description": "Country Name for Tokelau"
  },
  "country.tl": {
    "id": "country.tl",
    "defaultMessage": "Timor-Leste",
    "description": "Country Name for Timor-Leste"
  },
  "country.tm": {
    "id": "country.tm",
    "defaultMessage": "Turkmenistan",
    "description": "Country Name for Turkmenistan"
  },
  "country.tn": {
    "id": "country.tn",
    "defaultMessage": "Tunisia",
    "description": "Country Name for Tunisia"
  },
  "country.to": {
    "id": "country.to",
    "defaultMessage": "Tonga",
    "description": "Country Name for Tonga"
  },
  "country.tr": {
    "id": "country.tr",
    "defaultMessage": "Turvalue",
    "description": "Country Name for Turvalue"
  },
  "country.tt": {
    "id": "country.tt",
    "defaultMessage": "Trinidad and Tobago",
    "description": "Country Name for Trinidad and Tobago"
  },
  "country.tv": {
    "id": "country.tv",
    "defaultMessage": "Tuvalu",
    "description": "Country Name for Tuvalu"
  },
  "country.tw": {
    "id": "country.tw",
    "defaultMessage": "Taiwan",
    "description": "Country Name for Taiwan"
  },
  "country.tz": {
    "id": "country.tz",
    "defaultMessage": "Tanzania",
    "description": "Country Name for Tanzania"
  },
  "country.ua": {
    "id": "country.ua",
    "defaultMessage": "Ukraine",
    "description": "Country Name for Ukraine"
  },
  "country.ug": {
    "id": "country.ug",
    "defaultMessage": "Uganda",
    "description": "Country Name for Uganda"
  },
  "country.um": {
    "id": "country.um",
    "defaultMessage": "United States Minor Outlying Islands",
    "description": "Country Name for United States Minor Outlying Islands"
  },
  "country.us": {
    "id": "country.us",
    "defaultMessage": "United States of America",
    "description": "Country Name for United States of America"
  },
  "country.uy": {
    "id": "country.uy",
    "defaultMessage": "Uruguay",
    "description": "Country Name for Uruguay"
  },
  "country.uz": {
    "id": "country.uz",
    "defaultMessage": "Uzbekistan",
    "description": "Country Name for Uzbekistan"
  },
  "country.va": {
    "id": "country.va",
    "defaultMessage": "Holy See",
    "description": "Country Name for Holy See"
  },
  "country.vc": {
    "id": "country.vc",
    "defaultMessage": "Saint Vincent and the Grenadines",
    "description": "Country Name for Saint Vincent and the Grenadines"
  },
  "country.ve": {
    "id": "country.ve",
    "defaultMessage": "Venezuela",
    "description": "Country Name for Venezuela"
  },
  "country.vg": {
    "id": "country.vg",
    "defaultMessage": "Virgin Islands (British)",
    "description": "Country Name for Virgin Islands (British)"
  },
  "country.vi": {
    "id": "country.vi",
    "defaultMessage": "Virgin Islands (U.S.)",
    "description": "Country Name for Virgin Islands (U.S.)"
  },
  "country.vn": {
    "id": "country.vn",
    "defaultMessage": "Vietnam",
    "description": "Country Name for Vietnam"
  },
  "country.vu": {
    "id": "country.vu",
    "defaultMessage": "Vanuatu",
    "description": "Country Name for Vanuatu"
  },
  "country.wf": {
    "id": "country.wf",
    "defaultMessage": "Wallis and Futuna",
    "description": "Country Name for Wallis and Futuna"
  },
  "country.ws": {
    "id": "country.ws",
    "defaultMessage": "Samoa",
    "description": "Country Name for Samoa"
  },
  "country.ye": {
    "id": "country.ye",
    "defaultMessage": "Yemen",
    "description": "Country Name for Yemen"
  },
  "country.yt": {
    "id": "country.yt",
    "defaultMessage": "Mayotte",
    "description": "Country Name for Mayotte"
  },
  "country.za": {
    "id": "country.za",
    "defaultMessage": "South Africa",
    "description": "Country Name for South Africa"
  },
  "country.zm": {
    "id": "country.zm",
    "defaultMessage": "Zambia",
    "description": "Country Name for Zambia"
  },
  "country.zw": {
    "id": "country.zw",
    "defaultMessage": "Zimbabwe",
    "description": "Country Name for Zimbabwe"
  },
  "commute-comfort": {
    "id": "commute-comfort",
    "defaultMessage": "Commute-comfort",
    "description": ""
  },
  "commute-sport": {
    "id": "commute-sport",
    "defaultMessage": "Commute-sport",
    "description": ""
  },
  "cyclocross": {
    "id": "cyclocross",
    "defaultMessage": "Cyclocross",
    "description": ""
  },
  "mtb-trail": {
    "id": "mtb-trail",
    "defaultMessage": "Mountain (trail)",
    "description": ""
  },
  "mtb-xc": {
    "id": "mtb-xc",
    "defaultMessage": "Mountain (cross-country)",
    "description": ""
  },
  "road": {
    "id": "road",
    "defaultMessage": "Road",
    "description": ""
  },
  "triathlon": {
    "id": "triathlon",
    "defaultMessage": "Triathlon",
    "description": ""
  },
  "fitness": {
    "id": "fitness",
    "defaultMessage": "Fitness",
    "description": ""
  }
});
