import * as actionTypes from '../types/actions'
import { JSON_PARSER } from '../types/constants';
import { generateRequestConfigs } from '../utils/fetchHelper';
import { apiGet } from '../api/apiClient';
import { setCurrentUser } from '../api/tokens';
import { fetchCurrentUserUrl } from '../utils/urlHelper'


export function fetchCurrentUser() {
  return dispatch => {
    dispatch(requestCurrentUser());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchCurrentUserUrl(),
          receiveCallback: receiveCurrentUser,
        }
      )
    )
  }
}

export function requestCurrentUser() {
  return {
    type: actionTypes.REQUEST_CURRENT_USER
  }
}

export function receiveCurrentUser(data) {
  setCurrentUser(data);
  return {
    type: actionTypes.RECEIVE_CURRENT_USER,
  }
}

export function receiveCurrentUserFailure() {
  return {
    type: actionTypes.RECEIVE_CURRENT_USER_FAILURE
  }
}
