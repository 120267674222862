import * as statusTypes from '../types/statuses';
import * as actionTypes from '../types/actions/employees';

export const initialState = {
  employeesList: {
    data: null,
    fetchingStatus: null
  },
  employee: {
    data: null,
    fetchingStatus: null,
    createStatus: null,
    deleteStatus: null,
  }
};


function setCreateStatus(state, status) {
  const employee = {...state.employee, createStatus: status};
  return {...state, employee}
}

function setDeleteStatus(state, status) {
  const employee = {...state.employee, deleteStatus: status};

  return {...state, employee};
}

function setEmployeeListFetchingStatus(state, status) {
  const employeesList = {...state.employeesList, fetchingStatus: status};

  return {...state, employeesList}
}

function setEmployeeFetchingStatus(state, status) {
  const employee = {...state.employee, fetchingStatus: status};

  return {...state, employee}
}

function receiveEmployeeData(state, action) {
  const employeesList = {...state.employeesList, fetchingStatus: statusTypes.RECEIVED, data: action.data};

  return {...state, employeesList}
}

function receiveEmployee(state, action) {
  const employee = {...state.employee, fetchingStatus: statusTypes.RECEIVED, data: action.data};

  return {...state, employee}
}

function setUpdateStatus(state, status) {
  const employee = {...state.employee, updateStatus: status};
  return {...state, employee}
}

export default function sessions(state = initialState, action) {
  switch (action.type) {
    case(actionTypes.CREATE_EMPLOYEE_REQUESTED):
      return setCreateStatus(state, statusTypes.REQUESTING);
    case(actionTypes.CREATE_EMPLOYEE_COMPLETE):
      return setCreateStatus(state, statusTypes.COMPLETED);
    case(actionTypes.CREATE_EMPLOYEE_FAILED):
      return setCreateStatus(state, statusTypes.FAILED);
    case(actionTypes.DELETE_EMPLOYEE_COMPLETE):
      return setDeleteStatus(state, statusTypes.COMPLETED);
    case(actionTypes.DELETE_EMPLOYEE_FAILED):
      return setDeleteStatus(state, statusTypes.FAILED);
    case(actionTypes.DELETE_EMPLOYEE_REQUESTED):
      return setDeleteStatus(state, statusTypes.REQUESTING);
    case(actionTypes.RECEIVE_EMPLOYEES_DATA):
      return receiveEmployeeData(state, action);
    case(actionTypes.REQUEST_EMPLOYEES_DATA):
      return setEmployeeListFetchingStatus(state, statusTypes.REQUESTING);
    case(actionTypes.REQUEST_EMPLOYEE):
      return setEmployeeFetchingStatus(state, statusTypes.REQUESTING);
    case(actionTypes.RECEIVE_EMPLOYEE):
      return receiveEmployee(state, action);
    case(actionTypes.UPDATE_EMPLOYEE_REQUESTED):
      return setUpdateStatus(state, statusTypes.REQUESTING);
    case(actionTypes.UPDATE_EMPLOYEE_COMPLETE):
      return setUpdateStatus(state, statusTypes.COMPLETED);
    case(actionTypes.UPDATE_EMPLOYEE_FAILED):
      return setUpdateStatus(state, statusTypes.FAILED);
    default:
      return state
  }
}