import React from 'react';
import {Redirect, Route} from 'react-router-dom'
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import {isAuthenticated} from '../api/tokens';

export function renderComponent(Component) {
  return componentWithErrorBoundary(Component)
}

export function renderRoute(path, Component) {
  return (
    <Route exact path={`${path}`} render={() => renderComponent(<Component/>)}/>
  )
}

export function renderDynamicSecureRoute(path, Component, redirectPath = "/") {
  return (
    <Route
      path={`${path}`}
      render={({match}) =>
        isAuthenticated()
          ?
          renderComponent(<Component data={match.params.locationId}/>)
          :
          <Redirect to={`${redirectPath}`}/>
      }
    />
  )
}

export function renderSecureRoute(path, Component, redirectPath = "/") {
  return (
    <Route
      exact path={`${path}`}
      render={(props) =>
        isAuthenticated()
          ?
          renderComponent(<Component {...props} />)
          :
          <Redirect to={`${redirectPath}`}/>
      }
    />
  )
}

export function renderSecureRedirect(path, authenticatedPath, insecurePath) {
  return (
    <Route
      exact path={`${path}`}
      render={
        () => isAuthenticated()
          ?
          (<Redirect to={`${authenticatedPath}`}/>)
          :
          (<Redirect to={`${insecurePath}`}/>)
      }
    />
  )
}

export function renderRouteUnlessAuthenticated(path, redirectRoute, Component) {
  return (
    <Route
      exact path={`${path}`}
      render={
        (props) => isAuthenticated() ?
          (<Redirect to={`${redirectRoute}`}/>) : (renderComponent(<Component {...props} />))}
    />
  )
}