import {defineMessages} from 'react-intl';

export default defineMessages({
  'location_id': {
    'id': 'locations.location_id_label',
    'defaultMessage': 'Location ID',
    'description': 'Lable for Location ID on a table'
  },
  'update_location': {
    'id': 'locations.update_cta_label',
    'defaultMessage': 'Update Location',
    'description': 'Lable for CTA to update a Location'
  },
  'delete_location': {
    'id': 'locations.delete_cta_label',
    'defaultMessage': 'Delete Location',
    'description': 'Lable for CTA to delete a location'
  },
})
