import React, {Component} from 'react';
import Modal from 'react-modal';
import AddTransactionFormContainer from '../../containers/AddTransactionFormContainer';
import ModalClose from '../../components/Modal/ModalClose';

export default class AddTransactionModal extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openModal && !this.state.modalIsOpen) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  renderResourceForm() {
    const {data} = this.props;

    return (
      <AddTransactionFormContainer
        data={data}
      />
    )
  }

  render() {
    return (
      <Modal
        className={{
          base: 'modal',
          afterOpen: '',
          beforeClose: ''
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: '',
          beforeClose: ''
        }}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel={`Add Transaction Modal`}
      >
        <ModalClose closeModal={this.closeModal}/>
        <div className="modal-content modal-content_wide">
          {this.renderResourceForm()}
        </div>
      </Modal>
    )
  }
}
