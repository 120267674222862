import React from 'react';
import PageLink from '../../components/PageLink/PageLink';
import NavigationLinkIcon from '../../components/NavigationLinkIcon/NavigationLinkIcon';
import ClientProfileForm from './ClientProfileForm';
import ClientProgress from './ClientProgress';
import ClientSessions from './ClientSessions';
import {injectIntl} from 'react-intl';
import messages from './messages';

const ClientProfile = function (props) {
  const {intl} = props;
  const {email, name, sessions} = props.data;

  return (
    <section className="Main session-details">
      <div className="container">
        <div className="box">
          <NavigationLinkIcon to="/sessions"/>
          <PageLink to="/sessions" heavy>{intl.formatMessage(messages.all_sessions)}</PageLink>
        </div>
        <div className="box box_top5">
          <div className="grid grid_columnReverse grid_rowMd">
            <div className="column column_8Md">
              <h2 className="txt mix-txt_uppercase">{intl.formatMessage(messages.client_name)}</h2>
              <div className="box box_top2">
                <span className="txt txt_md">{name || intl.formatMessage(messages.not_applicable)}</span>
              </div>
              <div className="box box_top5">
                <h2 className="txt mix-txt_uppercase">{intl.formatMessage(messages.client_email)}</h2>
                <div className="box box_top2">
                  <a className="txt txt_md txt_link" href={`mailto:${email}`}>{email}</a>
                </div>
              </div>
              <div className="box box_top10">
                <ClientProfileForm
                  data={props.data}
                  serverErrors={props.serverErrors}
                  onClientUpdateSubmit={props.onClientUpdateSubmit}
                />
              </div>
            </div>
            <div className="column column_4Md">
              <ClientProgress data={props.data}/>
              <ClientSessions
                sessions={sessions}
                clientId={props.clientId}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default injectIntl(ClientProfile);
