import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import makeHash from '../../utils/makeHash';
import Loader from '../../components/Loader/Loader';
import Text from '../../components/Text/Text';
import messages from '../../utils/messages';
import { injectIntl } from 'react-intl';
import { apiCreate } from '../../api/apiClient';
import { JSON_PARSER } from '../../types/constants';
import { generateRequestConfigs } from '../../utils/fetchHelper';
import { confirmPasswordResetUrl } from '../../utils/urlHelper';
import { validatePassword } from '../../utils/validators';

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverError: false,
            showLoginLink: false,
            serverMessages: [],
            isLoading: false,
            email: null,
            token: null,
            password: '',
            confirmPassword: '',
            passwordError: '',
            confirmPasswordError: '',
            submitEnabled: false,
            formMessages: [],
        };

        this.showGigyaScreenset = this.showGigyaScreenset.bind(this);
        this.setUpHandlers();
    }

    setUpHandlers() {
        this.handleReset = this.handleReset.bind(this);
        this.checkPasswordComplexity = this.checkPasswordComplexity.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateConfirmPassword = this.updateConfirmPassword.bind(this);
    }

    componentDidMount() {
        if (process.env.REACT_APP_AUTH_BACKEND === 'gigya') {
            this.createGigyaScript(
                `https://cdns.gigya.com/js/gigya.js?apikey=${process.env.REACT_APP_GIGYA_KEY}`,
                document.body
            );
        } else {
            const params = new URLSearchParams(window.location.search);
            const email = params.get('email');
            const token = params.get('token');

            const errorMessages = [];
            if (!email) errorMessages.push('Email is missing');
            if (!token) errorMessages.push('Token is missing');

            if (errorMessages.length > 0) {
                this.setState({
                    isLoading: false,
                    serverError: true,
                    formMessages: [
                        ...errorMessages,
                        <Link to="/reset/">
                            {this.props.intl.formatMessage(
                                messages.reset_password_request
                            )}
                        </Link>,
                    ],
                });
            } else {
                this.setState({
                    email: params.get('email'),
                    token: params.get('token'),
                });
            }
        }
    }

    updatePassword(event) {
        // Check that confirm password matches only when it has had entry
        event.persist();
        this.setState({ password: event.target.value }, () => {
            if (this.state.password.length > 0) {
                this.checkPasswordComplexity(event);
            }
        });
    }

    updateConfirmPassword(event) {
        // Check that the confirm password matches
        const confirmPassword = event.target.value;
        event.persist();
        this.setState({ confirmPassword }, () => {
            this.checkPasswordComplexity(event);
        });
    }

    checkPasswordComplexity(event) {
        if (!validatePassword(this.state.password)) {
            this.setState({
                passwordError:
                    'Passwords must be at least 8 characters long, include upper- and lower-case letters, and include a number',
            });
        } else {
            this.setState({ passwordError: '' });
        }

        if (this.state.confirmPassword !== this.state.password) {
            this.setState({ confirmPasswordError: 'Passwords must match' });
        } else {
            this.setState({ confirmPasswordError: '' });
        }
    }

    checkPasswordsMatch(event) {
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({
                confirmPasswordError: 'Passwords must match',
            });
        }
    }

    createGigyaScript() {
        const scriptTag = document.createElement('script');
        scriptTag.src = `https://cdns.gigya.com/js/gigya.js?apikey=${process.env.REACT_APP_GIGYA_KEY}&lang=${this.locale}`;

        scriptTag.onload = this.showGigyaScreenset;
        scriptTag.onreadystatechange = this.showGigyaScreenset;

        document.body.appendChild(scriptTag);
    }

    showGigyaScreenset() {
        window.gigya.accounts.showScreenSet({
            screenSet: 'Passport-RegistrationLogin',
            startScreen: 'gigya-reset-password-screen',
            containerID: 'passwordScreensDiv',
            onAfterSubmit: (evt) => {
                // console.log(evt.form);
                if (
                    (evt.form === 'gigya-login-form' ||
                        evt.form === 'gigya-profile-form') &&
                    evt.response.errorCode === 0
                ) {
                    window.gigya.accounts.getJWT({
                        fields: 'firstName,lastName,email',
                        expiration: 604800,
                        callback: (resp) => {
                            this.submitGigyaForm(
                                evt.response.profile.email,
                                resp.id_token
                            );
                        },
                    });
                }
            },
        });
    }

    submitGigyaForm(email, password) {
        this.props.handleSubmit({
            email,
            password,
        });
    }

    handleReset(event) {
        event.preventDefault();
        this.setState(
            {
                isLoading: true,
            },
            () => {
                apiCreate(
                    generateRequestConfigs({
                        body: JSON.stringify({
                            client: 'retailer',
                            email: this.state.email,
                            token: this.state.token,
                            password: this.state.password,
                        }),
                        dispatch: () => {},
                        parser: JSON_PARSER,
                        url: confirmPasswordResetUrl(),
                        failCallback: (error) => {
                            try {
                                error.response.json().then((resp) => {
                                    this.setState({
                                        isLoading: false,
                                        serverError: true,
                                        showLoginLink: false,
                                        formMessages: [resp.reason],
                                    });
                                });
                            } catch (e) {
                                this.setState({
                                    isLoading: false,
                                    serverError: true,
                                    showLoginLink: false,
                                    formMessages: [
                                        this.props.intl.formatMessage(
                                            messages.reset_password_request_failed
                                        ),
                                    ],
                                });
                            }
                        },
                        receiveCallback: (data) => {
                            this.setState({
                                isLoading: false,
                                serverError: false,
                                showLoginLink: true,
                                formMessages: [
                                    this.props.intl.formatMessage(
                                        messages.reset_password_request_success
                                    ),
                                ],
                            });
                        },
                        auth: 'Basic',
                    })
                );
            }
        );
    }

    _renderFormMessages() {
        const ulClasses = classNames('form-messages', {
            'form-messages_hasMessage': this.state.formMessages.length > 0,
        });
        const color = this.state.serverError ? 'DefaultOrange' : 'DefaultBlue';

        return (
            <ul className={ulClasses}>
                {this.state.formMessages.map((msg, i) => {
                    if (typeof msg === 'string') {
                        return (
                            <li key={makeHash(`${msg}-${i}`)}>
                                <Text color={color} weight="light">
                                    {msg.endsWith('.') ? msg : msg + '.'}
                                </Text>
                            </li>
                        );
                    } else {
                        return <li key={makeHash(`${i}`)}>{msg}</li>;
                    }
                })}
            </ul>
        );
    }

    render() {
        const passwordFieldClass = classNames('field field_floatingLabel', {
            field_hasError: this.state.passwordError,
        });
        const confirmPasswordFieldClass = classNames(
            'field field_floatingLabel',
            { field_hasError: this.state.confirmPasswordError }
        );
        const submitEnabled =
            this.state.passwordError.length === 0 &&
            this.state.confirmPasswordError.length === 0 &&
            this.state.password.length > 0;
        const formMessages = this._renderFormMessages();
        const className =
            process.env.REACT_APP_AUTH_BACKEND === 'gigya' ? 'hidden' : '';
        const showLogin = (
            <div className="set mix-set_alignCenter">
                {formMessages}
                <button
                    className="btn btn_fixedWidth"
                    onClick={() => {
                        window.location.href = '/sign-in';
                    }}
                >
                    {this.props.intl.formatMessage(
                        messages.sign_in_button_label
                    )}
                </button>
            </div>
        );

        const resetPasswordForm = (
            <div>
                <Loader isLoading={this.state.isLoading} />
                <div className="form-container container container_constrainedMd">
                    <div className="gigya-screen-set" id="passwordScreensDiv" />
                    <form
                        onSubmit={this.handleReset}
                        autoComplete="off"
                        id="reset-password-stuff"
                        noValidate
                        className={className}
                        disabled={this.submitEnabled}
                    >
                        {formMessages}
                        <div>
                            <div className="set-hd">
                                <div className={passwordFieldClass}>
                                    <label
                                        className="field-label"
                                        htmlFor="password"
                                    >
                                        New Password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        label="New Password"
                                        onChange={this.updatePassword}
                                        value={this.state.password}
                                        onBlur={this.checkPasswordComplexity}
                                        className="field-input"
                                    />
                                    <span
                                        className={
                                            passwordFieldClass && 'field-error'
                                        }
                                    >
                                        {this.state.passwordError}
                                    </span>
                                </div>
                            </div>

                            <div className="set-bd set-bd_super">
                                <div className={confirmPasswordFieldClass}>
                                    <label
                                        className="field-label"
                                        htmlFor="confirm-password"
                                    >
                                        Confirm New Password
                                    </label>
                                    <input
                                        id="confirm-password"
                                        name="confirm-password"
                                        type="password"
                                        label="New Password"
                                        onChange={this.updateConfirmPassword}
                                        value={this.state.confirmPassword}
                                        onBlur={this.checkPasswordComplexity}
                                        className="field-input"
                                    />
                                    <span
                                        className={
                                            confirmPasswordFieldClass &&
                                            'field-error'
                                        }
                                    >
                                        {this.state.confirmPasswordError}
                                    </span>
                                </div>
                            </div>

                            <div className="set-bd set-bd_super">
                                <div className="set mix-set_alignCenter">
                                    <div className="set-hd">
                                        <button
                                            disabled={!submitEnabled}
                                            className="btn btn_fixedWidth"
                                            type="submit"
                                        >
                                            {this.props.intl.formatMessage(
                                                messages.submit_label
                                            )}
                                        </button>
                                    </div>
                                    <div className="set-bd set-bd_separated">
                                        <a
                                            className="txt txt_reg mix-txt_colorDefaultBlue"
                                            href="/"
                                        >
                                            {this.props.intl.formatMessage(
                                                messages.cancel_label
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );

        return this.state.showLoginLink ? showLogin : resetPasswordForm;
    }
}
export default injectIntl(ResetPasswordForm);
