export const ALL = 'ALL';
export const CLIENT_TYPE = 'CLIENT TYPE';
export const CLOSED = 'Closed';
export const COMPLETE = 'complete';
export const DESCENDING = 'descending';
export const EMPLOYEES = 'EMPLOYEES';
export const EMPLOYEE = 'employee';
export const INCOMPLETE = 'incomplete';
export const LOCATIONS = 'LOCATIONS';
export const NONE = 'NONE';
export const OPEN = 'Open';
export const ORDER = 'order';
export const RATING = 'RATING';
export const SESSION_TYPE = 'SESSION TYPE';
export const SHOP = 'shop';
export const STATUS = 'STATUS';

export const CLIENT_FILTERS = [
  {
    'value': 'ALL',
    'label': 'filters.client_all'
  },
  {
    'value': 'Identified',
    'label': 'filters.client_identified'
  },
  {
    'value': 'Anonymous',
    'label': 'filters.client_anonymous'
  },
];

export const RATING_FILTERS = {
  textLabels: [
    {
      'value': 'ALL',
      'label': 'filters.rating_all'
    },
    {
      'value': 'NONE',
      'label': 'filters.rating_none'
    },
  ],
  scores: [ 1, 2, 3, 4, 5 ]
};

export const SESSION_FILTERS = [
  {
    'value': 'ALL',
    'label': 'filters.session_all'
  },
  {
    'value': 'match',
    'label': 'filters.session_match'
  },
  {
    'value': 'DSD',
    'label': 'filters.session_dsd'
  },
];

export const STATUS_FILTERS = [
  {
    'value': 'ALL',
    'label': 'filters.status_all'
  },
  {
    'value': 'open',
    'label': 'filters.status_open'
  },
  {
    'value': 'closed',
    'label': 'filters.status_closed'
  },
];

export const FILTER_VALUES_MAP = [
  {
    key: 'status',
    valueItems: [
      { value: OPEN, searchParamValue: 'open' },
      { value: CLOSED, searchParamValue: 'closed' },
    ]
  }
];

export const SEARCH_PARAM_KEY_MAP = [
  { key: 'clientType', searchParamKey: 'client_type' },
  { key: 'employees', searchParamKey: 'employee' },
  { key: 'locations', searchParamKey: 'shop' },
  { key: 'ratings', searchParamKey: 'rating' },
  { key: 'sessionType', searchParamKey: 'app_type' }
];
