import React, {Component} from 'react';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';
import Loader from '../../components/Loader/Loader';
import './Main.css';

export default class Main extends Component {
  renderSection(sectionContent) {
    if (Array.isArray(sectionContent)) {
      return this.renderSectionItems(sectionContent)
    } else {
      return componentWithErrorBoundary(sectionContent)
    }
  }

  renderSectionItems(sectionItems) {
    return sectionItems.map((sectionItem, i) => {
      return componentWithErrorBoundary(sectionItem, i)
    })
  }

  renderTopSection() {
    const {top} = this.props;

    if (top) {
      return (
        <section className="top top_slim">
          <div className="container">
            {this.renderSection(top)}
          </div>
        </section>
      )
    }
  }

  renderBottomSection() {
    const {bottom} = this.props;
    if (bottom) {
      return (
        <section className="bottom">
          <div className="container">
            {this.renderSection(bottom)}
          </div>
        </section>
      )
    }
  }

  renderLoader() {
    return this.props.isLoading === undefined ? '' : (
      <Loader
        isLoading={this.props.isLoading}
        fixedLoader={this.props.fixedLoader}
      />
    )
  }

  render() {
    const extraClasses = this.props.className ? this.props.className : '';

    return (
      <main className={`Main ${extraClasses}`}>
        {this.renderLoader()}
        {this.renderTopSection()}
        {this.renderBottomSection()}
        {this.props.children}
      </main>
    )
  }
}
