import React from 'react';
import {render} from 'react-dom';
import LocaleWrapper from './localeWrapper';
import 'core-js/fn/array/find';
import 'core-js/fn/array/fill';
import 'core-js/fn/object/entries';
import 'url-search-params-polyfill';

const rootElement = document.getElementById('app');

if (rootElement) {
  render(
    <LocaleWrapper/>,
    rootElement)
}
