import {defineMessages} from 'react-intl';

export default defineMessages({
  'sessions_heading': {
    'id': 'sessions.heading',
    'defaultMessage': 'Sessions',
    'description': 'Sessions Page title welcome message'
  },
  'sessions_intro': {
    'id': 'sessions.intro',
    'defaultMessage': 'A timeline of all your latest sessions with Retül Technology.',
    'description': 'Sessions Page description message'
  },
  'locations_heading': {
    'id': 'locations.heading',
    'defaultMessage': 'Manage Locations',
    'description': 'Manage Locations Page title welcome message'
  },
  'locations_intro': {
    'id': 'locations.intro',
    'defaultMessage': 'Add, edit and remove locations using Retül Technology.',
    'description': 'Manage Locations Page description message'
  },
  'employees_heading': {
    'id': 'employees.heading',
    'defaultMessage': 'Employees',
    'description': 'Manage Employee Page title welcome message'
  },
  'employees_intro': {
    'id': 'employees.intro',
    'defaultMessage': 'A timeline of all your latest employees with Retül Technology.',
    'description': 'Employee Page description message'
  },
  'total_sessions': {
    'id': 'metrics.total_sessions',
    'defaultMessage': 'Total Sessions',
    'description': 'Metric heading for total sessions'
  },
  'closed_sessions': {
    'id': 'metrics.closed_sessions',
    'defaultMessage': 'Closed Sessions',
    'description': 'Metric heading for closed sessions'
  },
  'unique_client_emails': {
    'id': 'metrics.unique_client_emails',
    'defaultMessage': 'Unique Client Emails',
    'description': 'Metric heading for unique client emails'
  },
  'analytics_loading_message': {
    'id': 'analytics.loading_message',
    'defaultMessage': 'We are compiling your data, this may take a couple minutes',
    'description': 'Loading message displayed while Analytics data is being fetched'
  },
  'csv_downloading_message': {
    'id': 'sessions.csv_downloading_message',
    'defaultMessage': 'We are gathering all of your sessions for your CSV, this may take a couple minutes.',
    'description': 'Loading message displayed while Session csv data is being fetched'
  },
  'session_loading_message': {
    'id': 'sessions.session_loading_message',
    'defaultMessage': 'We are gathering all of your sessions, this may take a moment.',
    'description': 'Loading message displayed while Session csv data is being fetched'
  },
});
