import React, { Component } from 'react';
import * as d3Axis from 'd3-axis';
import { select as d3Select } from 'd3-selection';
import { timeFormat } from 'd3-time-format';
import './Axis.css';

export default class Axis extends Component {
  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  setTickQuantity(axis) {
    const { ticks } = this.props;
    if (ticks) {
      return axis.ticks(ticks)
    } else {
      return axis;
    }
  }

  setTickSize(axis) {
    const { tickSize } = this.props;
    if (tickSize) {
      return axis.tickSize(tickSize);
    } else {
      return axis;
    }
  }

  setTickValues(axis) {
    const { tickValues } = this.props;
    if (tickValues) {
      return axis.tickValues(tickValues)
    } else {
      return axis;
    }
  }

  setTickFormat(axis) {
    const { timeFormatType } = this.props;
    if (timeFormatType) {
      return axis.tickFormat(timeFormat(timeFormatType))
    } else {
      return axis;
    }
  }


  formatAxis(axis) {
    axis = this.setTickQuantity(axis);
    axis = this.setTickSize(axis);
    axis = this.setTickValues(axis);
    return this.setTickFormat(axis);
  }

  renderXAxis(){
    const { orientation, scale } = this.props;
    let axis = d3Axis[ `axis${orientation}` ]()
      .scale(scale);
    axis = this.formatAxis(axis);

    d3Select(this.axisElement)
      .call(axis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");
  }

  renderYAxis(){
    const { orientation, scale } = this.props;
    let axis = d3Axis[ `axis${orientation}` ]()
      .scale(scale);

    axis = this.formatAxis(axis);

    d3Select(this.axisElement)
      .call(axis)
  }


  renderAxis() {
    const {orientation} = this.props;
    orientation === 'Bottom' ? this.renderXAxis() : this.renderYAxis()
  }


  className() {
    const { orientation } = this.props;
    return `Vertical-Bar-Chart Axis Axis-${orientation}`
  }

  render() {
    const { translate } = this.props;
    return (
      <g
        className={ this.className() }
        ref={ (el) => {
          this.axisElement = el
        } }
        transform={ translate }
      />
    )
  }
}



