import {RECEIVED, REQUESTING} from '../types/statuses';
import {
  RECEIVE_SESSIONS_CSV_EXPORT,
  RECEIVE_SESSIONS_DATA,
  RECEIVE_SESSIONS_FILTERS,
  REQUEST_SESSIONS_CSV_EXPORT,
  REQUEST_SESSIONS_DATA,
  REQUEST_SESSIONS_FILTERS,
  UPDATE_SESSIONS_SEARCH_PARAMS
} from '../types/actions/sessions';
import {ALL} from '../types/searchFilters'
import {getSession} from '../selectors/sessions';

export const initialState = {
  searchFilters: {
    data: null,
    fetchingStatus: null
  },
  searchParams: {
    data: {
      clientType: ALL,
      employees: [ALL],
      locations: [ALL],
      page: 1,
      order: {
        attribute: 'created_at',
        sort: 'descending'
      },
      ratings: [ALL],
      sessionType: ALL,
      status: ALL
    }
  },
  sessionsData: {
    data: null,
    fetchingStatus: null,
    pagination: null
  },
  sessionsCSVData: {
    data: null,
    fetchingStatus: null
  }
};

function getPageNumber(url) {
  if (!url) return;

  const parsedNumber = /page=(.*)/.exec(url);

  // URL for 1st page does NOT have a page=1 param, default to 1 here
  return parsedNumber ? parseInt(parsedNumber[1], 10) : 1;
}

function getCurrentPage(nextPage, prevPage) {
  return prevPage ? prevPage + 1 : nextPage - 1;
}

function getPreviousPage(previousPage, currentPage) {
  if (currentPage === 2) {
    return 1;
  }
}

function getPagination(data) {
  const {count, previous, next} = data;
  const [nextPage, previousPage] = [next, previous].map(url => getPageNumber(url));
  const currentPage = getCurrentPage(nextPage, previousPage);

  return {
    count,
    currentPage,
    nextPage,
    previousPage: previousPage || getPreviousPage(previousPage, currentPage)
  };
}

function receiveSessionsCSVExport(state, action) {
  const sessionsCSVData = {...state.sessionsCSVData, fetchingStatus: RECEIVED, data: action.data};

  return {...state, sessionsCSVData}
}

function receiveSessionsData(state, action) {
  const pagination = getPagination(action.data);
  const data = action.data.results.map((d) => getSession(d));
  const sessionsData = {...state.sessionsData, fetchingStatus: RECEIVED, data, pagination};
  return {...state, sessionsData}
}

function receiveSessionsFilters(state, action) {
  const data = {...action.data.filters};
  const sessionsFilters = {...state.sessionsFilters, fetchingStatus: RECEIVED, data};
  return {...state, sessionsFilters}
}

function requestSessionsCSVExport(state) {
  const sessionsCSVData = {...state.sessionsCSVData, fetchingStatus: REQUESTING};

  return {...state, sessionsCSVData}
}

function requestSessionsData(state) {
  const sessionsData = {...state.sessionsData, fetchingStatus: REQUESTING};

  return {...state, sessionsData}
}

function requestSessionsFILTERS(state) {
  const sessionsFilters = {...state.sessionsFilters, fetchingStatus: REQUESTING};
  return {...state, sessionsFilters}
}

function updateSearchParams(state, action) {
  const data = {...state.searchParams.data, ...action.data};

  // // If pagination was not changed, reset to 1st page
  if (!action.data.page) {
    data.page = 1;
  }

  const searchParams = {...state.searchParams, data};
  return {...state, searchParams};
}

export default function sessions(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SESSIONS_CSV_EXPORT:
      return receiveSessionsCSVExport(state, action);
    case RECEIVE_SESSIONS_DATA:
      return receiveSessionsData(state, action);
    case RECEIVE_SESSIONS_FILTERS:
      return receiveSessionsFilters(state, action);
    case REQUEST_SESSIONS_CSV_EXPORT:
      return requestSessionsCSVExport(state);
    case REQUEST_SESSIONS_DATA:
      return requestSessionsData(state);
    case REQUEST_SESSIONS_FILTERS:
      return requestSessionsFILTERS(state);
    case UPDATE_SESSIONS_SEARCH_PARAMS:
      return updateSearchParams(state, action);
    default:
      return state
  }
}
