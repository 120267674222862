import React from 'react';
import Button from '../../components/Button/Button';
import DropDown from '../../components/DropDown/DropDown';
import ResourceCheckboxGroup from '../../components/ResourceCheckboxGroup/ResourceCheckboxGroup';
import FormInput from '../../components/FormInput/FormInput';
import FormStaticValue from '../../components/FormStaticValue/FormStaticValue';
import Form from '../../components/Form/Form';
import {
  getCheckboxStateData,
  getFieldStateData,
  initializeFieldStateData,
  updateCheckboxValue,
  updateFieldValue
} from '../../utils/formHelper';
import '../../assets/stylesheets/base/ControlGroups.css';
import {injectIntl} from 'react-intl';
import messages from '../../types/constantsMessages';

class ResourceForm extends Form {
  constructor(props) {
    super(props);
    const {fields, checkboxGroups, intl} = props;
    this.intl = intl;
    this.state = initializeFieldStateData({fields, checkboxGroups});
    this.handleCheck = this.handleCheck.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleSelectChange(fieldName, value) {
    this.setState(prevState => {
      return updateFieldValue({state: prevState, fieldName, value});
    });
  }

  handleChange(fieldName, event) {
    const value = event.target.value;

    this.setState(prevState => {
      return updateFieldValue({state: prevState, fieldName, value});
    });
  }

  handleCheck(checkboxGroupName, value) {
    this.setState(prevState => {
      return updateCheckboxValue({state: prevState, checkboxGroupName, value});
    });
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (this.hasErrors()) {
      return;
    }
    const {submitFunction, id} = this.props;
    const fieldStateData = getFieldStateData(this.state);
    const checkboxStateData = getCheckboxStateData(this.state);
    const formStateData = {
      id,
      ...fieldStateData,
      ...checkboxStateData
    };

    submitFunction(formStateData);
  }

  renderFields(fields) {
    return fields.map((field, index) => {
      switch (field.fieldType) {
        case 'select':
          return this.renderSelectField(field, index);
        case 'text':
        case 'email':
          return this.renderTextField(field, index);
        default:
          // console.error(`fieldType not provided for ${field.fieldName} field`);
          return undefined;
      }
    });
  }

  renderSelectField(field, index) {
    const {listItems, fieldName, fieldLabel, value} = field;
    const defaultItem = listItems.find(x => x.value === value);

    return (
      <div
        className="form-row"
        key={`${index}-${fieldName}`}
      >
        <div className="form-control form-control_light">
          <label className="label">
            {this.intl.formatMessage(messages[fieldLabel])}
          </label>
        </div>
        <div className="form-control">
          <DropDown
            fieldName={fieldName}
            listItems={listItems}
            displayedValue={defaultItem.label}
            selectedValue={defaultItem.value}
            handleItemClick={this.handleSelectChange.bind(this, fieldName)}
          />
        </div>
      </div>
    );
  }

  renderCheckoxGroups() {
    const {checkboxGroups} = this.state;

    return checkboxGroups && checkboxGroups.map((checkboxGroup, index) => {
      return (
        <ResourceCheckboxGroup
          key={index}
          {...checkboxGroup}
          handleCheck={this.handleCheck}
        />)
    })
  }

  renderTextField(field, index) {
    const {fieldName, fieldLabel, fieldType, value, errorMessage, required, editable} = field;

    if (editable === false && value !== '') {
      return (
        <FormStaticValue
          key={`${index}-${fieldName}`}
          value={value}
          label={this.intl.formatMessage(messages[fieldLabel])}
        />
      )
    }

    return (
      <FormInput
        light
        required={required}
        inputType={fieldType}
        key={`${index}-${fieldName}`}
        name={fieldName}
        label={this.intl.formatMessage(messages[fieldLabel])}
        handleChange={(e) => this.handleChange(fieldName, e)}
        defaultValue={value || ''}
        errorMessage={this.intl.formatMessage(messages[errorMessage])}
        ref={field => {
          this.fields[fieldName] = field;
        }}
      />
    )
  }

  renderSubmitButton() {
    const {submitText} = this.props;
    return (
      <div className="form-row">
        <div className="submit-button-container">
          <Button
            onClick={this.handleOnSubmit}
            type={'submit'}
            light
          >
            {submitText}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const {fields} = this.props;

    return (
      <form className="form-container" autoComplete="off" noValidate>
        {this.renderFields(fields)}
        {this.renderCheckoxGroups()}
        {this.renderSubmitButton()}
      </form>
    )
  }
}

export default injectIntl(ResourceForm);
