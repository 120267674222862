/* eslint-disable */
export const localeStrings = {
    'zh-tw': {
        'add_transaction.back_to_sessions_label': '回到階段',
        'add_transaction.bike_label': '自行車',
        'add_transaction.commute_comfort_type': '通勤-舒適',
        'add_transaction.commute_sport_type': '通勤-運動',
        'add_transaction.complete_transaction_cta_label': '完成交易',
        'add_transaction.confirm_label': '確認',
        'add_transaction.cyclocross_type': '公路越野',
        'add_transaction.family_label': '車款',
        'add_transaction.fitness_type': '健身',
        'add_transaction.footbed_label': '鞋墊',
        'add_transaction.heading': '新增交易紀錄至{data}',
        'add_transaction.model_label': '型號',
        'add_transaction.mountain_type': '登山車',
        'add_transaction.mtb_trail_type': '登山車 (林道)',
        'add_transaction.mtb_xc_type': '登山車 (XC)',
        'add_transaction.product_label': '產品',
        'add_transaction.ratings_message':
            '完成此交易後， {data}將收到一封電子郵件，對此產品進行評分。您將能夠在階段列表和階段詳細資訊頁面上看到此評分。',
        'add_transaction.road_type': '公路',
        'add_transaction.saddle_label': '座墊',
        'add_transaction.shoe_label': '車鞋',
        'add_transaction.size_label': '尺寸',
        'add_transaction.transaction_complete_message': '交易完成。',
        'add_transaction.triathlon_type': '鐵人三項',
        'add_transaction.tt_tri_type': 'TT／鐵人三項',
        'add_transaction.type_label': '類型',
        'analytics.loading_message':
            '我們正在彙整您的數據，這可能需要幾分鐘時間',
        'analytics_filters.analytics_by': '分析依據',
        'analytics_filters.filter_by': '篩選根據',
        'analytics_filters.products': '產品',
        'analytics_filters.sessions': '階段',
        'charts.bike_label_products': '自行車',
        'charts.bike_label_sessions': '自行車',
        'charts.commute_comfort_label': '通勤-舒適',
        'charts.commute_sport_label': '通勤-運動',
        'charts.cyclocross_label': '公路越野',
        'charts.female_label': '女性',
        'charts.friday_label': '星期五',
        'charts.high_arch_label': '高',
        'charts.low_arch_label': '低',
        'charts.male_label': '男性',
        'charts.medium_arch_label': '中',
        'charts.monday_label': '星期一',
        'charts.mtb_trail_label': '登山車-林道',
        'charts.mtb_xc_label': '登山車-XC',
        'charts.road_label': '公路',
        'charts.saddle_label_products': '座墊',
        'charts.saddle_label_sessions': '座墊',
        'charts.saturday_label': '星期六',
        'charts.sessions_by_bike_type_label': '根據自行車類型的階段',
        'charts.sessions_by_day_of_week_label': '根據一週當中某日的階段',
        'charts.sessions_by_employee_label': '根據職員的階段',
        'charts.sessions_by_foot_arch_label': '根據足弓高度的階段',
        'charts.sessions_by_gender_label': '根據性別的階段',
        'charts.sessions_by_product_label': '根據產品的階段',
        'charts.sessions_by_saddle_height_label': '根據座墊高度的階段',
        'charts.sessions_by_saddle_size_label': '根據座墊尺寸的階段',
        'charts.sessions_by_shirt_bike_size_label':
            '根據自行車尺寸的階段（以T-恤尺寸）',
        'charts.sessions_by_shoe_size_label': '根據自行車鞋尺寸的階段',
        'charts.sessions_by_sitbone_width_label': '根據坐骨寬的階段',
        'charts.sessions_by_standard_bike_size_label': '根據自行車尺寸的階段',
        'charts.sessions_over_time_label': '階段超時',
        'charts.shoe_label_products': '車鞋',
        'charts.shoe_label_sessions': '車鞋',
        'charts.sunday_label': '星期日',
        'charts.thursday_label': '星期四',
        'charts.top_ten_session_label': '前十次階段日期',
        'charts.top_transactions_label': '前十次交易日期',
        'charts.triathlon_label': '鐵人三項',
        'charts.tuesday_label': '星期二',
        'charts.wednesday_label': '星期三',
        'client.all_sessions_link': '所有階段',
        'client.bike_progress': '自行車',
        'client.client_progress_heading': '客戶進度',
        'client.client_sessions_heading': '最新的客戶階段',
        'client.email': '客戶電子郵件',
        'client.name': '客戶姓名',
        'client.not_applicable': '無',
        'client.notes_label': '備註',
        'client.progress_types_complete': '完成',
        'client.saddle_progress': '座墊',
        'client.save_cta': '儲存',
        'client.shoe_progress': '車鞋',
        'commute-comfort': '通勤-舒適',
        'commute-sport': '通勤-運動',
        'country.ad': '安道爾',
        'country.ae': '阿拉伯聯合酋長國',
        'country.af': '阿富汗',
        'country.ag': '安地卡及巴布達',
        'country.ai': '安圭拉',
        'country.al': '阿爾巴尼亞',
        'country.am': '亞美尼亞',
        'country.ao': '安哥拉',
        'country.aq': '南極洲',
        'country.ar': '阿根廷',
        'country.as': '美屬薩摩亞',
        'country.at': '奧地利',
        'country.au': '澳大利亞',
        'country.aw': '阿魯巴',
        'country.ax': '歐蘭群島',
        'country.az': '亞塞拜然',
        'country.ba': '波士尼亞與赫塞哥維納',
        'country.bb': '巴貝多',
        'country.bd': '孟加拉',
        'country.be': '比利時',
        'country.bf': '布吉納法索',
        'country.bg': '保加利亞',
        'country.bh': '巴林',
        'country.bi': '蒲隆地',
        'country.bj': '貝南',
        'country.bl': '聖巴瑟米',
        'country.bm': '百慕達',
        'country.bn': '汶萊',
        'country.bo': '玻利維亞',
        'country.bq': '波奈、聖佑達修斯及沙巴',
        'country.br': '巴西',
        'country.bs': '巴哈馬',
        'country.bt': '不丹',
        'country.bv': '布威島',
        'country.bw': '波札那',
        'country.by': '白俄羅斯',
        'country.bz': '貝里斯',
        'country.ca': '加拿大',
        'country.cc': '可可斯群島',
        'country.cd': '剛果民主共和國',
        'country.cf': '中非共和國',
        'country.cg': '剛果共和國',
        'country.ch': '瑞士',
        'country.ci': '象牙海岸',
        'country.ck': '科克群島',
        'country.cl': '智利',
        'country.cm': '喀麥隆',
        'country.cn': '中國',
        'country.co': '哥倫比亞',
        'country.cr': '哥斯大黎加',
        'country.cu': '古巴',
        'country.cv': '維德角',
        'country.cw': '古拉索',
        'country.cx': '聖誕島',
        'country.cy': '塞普勒斯',
        'country.cz': '捷克',
        'country.de': '德國',
        'country.dj': '吉布地',
        'country.dk': '丹麥',
        'country.dm': '多米尼克',
        'country.do': '多明尼加共和國',
        'country.dz': '阿爾及利亞',
        'country.ec': '厄瓜多',
        'country.ee': '愛沙尼亞',
        'country.eg': '埃及',
        'country.eh': '西撒哈拉',
        'country.er': '厄利垂亞',
        'country.es': '西班牙',
        'country.et': '衣索比亞',
        'country.fi': '芬蘭',
        'country.fj': '斐濟',
        'country.fk': '福克蘭群島',
        'country.fm': '密克羅尼西亞',
        'country.fo': '法羅群島',
        'country.fr': '法國',
        'country.ga': '加彭',
        'country.gb': '英國',
        'country.gd': '格瑞那達',
        'country.ge': '喬治亞',
        'country.gf': '法屬圭亞那',
        'country.gg': '格恩西島',
        'country.gh': '迦納',
        'country.gi': '直布羅陀',
        'country.gl': '格陵蘭',
        'country.gm': '甘比亞',
        'country.gn': '幾內亞',
        'country.gp': '瓜德魯普島',
        'country.gq': '赤道幾內亞',
        'country.gr': '希臘',
        'country.gs': '南喬治亞及南三明治群島',
        'country.gt': '瓜地馬拉',
        'country.gu': '關島',
        'country.gw': '幾內亞比索',
        'country.gy': '蓋亞那',
        'country.hk': '香港',
        'country.hm': '赫德及麥當勞群島',
        'country.hn': '洪都拉斯',
        'country.hr': '克羅埃西亞',
        'country.ht': '海地',
        'country.hu': '匈牙利',
        'country.id': '印尼',
        'country.ie': '愛爾蘭',
        'country.il': '以色列',
        'country.im': '曼島',
        'country.in': '印度',
        'country.io': '英屬印度洋地區',
        'country.iq': '伊拉克',
        'country.ir': '伊朗',
        'country.is': '冰島',
        'country.it': '義大利',
        'country.je': '澤西島',
        'country.jm': '牙買加',
        'country.jo': '約旦',
        'country.jp': '日本',
        'country.ke': '肯亞',
        'country.kg': '吉爾吉斯',
        'country.kh': '柬埔寨',
        'country.ki': '吉里巴斯',
        'country.km': '葛摩',
        'country.kn': '聖克里斯多福',
        'country.kp': '北朝鮮',
        'country.kr': '韓國',
        'country.kw': '科威特',
        'country.ky': '開曼群島',
        'country.kz': '哈薩克',
        'country.la': '寮國',
        'country.lb': '黎巴嫩',
        'country.lc': '聖露西亞',
        'country.li': '列支敦斯登',
        'country.lk': '斯里蘭卡',
        'country.lr': '賴比瑞亞',
        'country.ls': '賴索托',
        'country.lt': '立陶宛',
        'country.lu': '盧森堡',
        'country.lv': '拉脫維亞',
        'country.ly': '利比亞',
        'country.ma': '摩洛哥',
        'country.mc': '摩納哥',
        'country.md': '摩爾多瓦',
        'country.me': '蒙特尼哥羅',
        'country.mf': '聖馬丁(法屬)',
        'country.mg': '馬達加斯加',
        'country.mh': '馬紹爾群島',
        'country.mk': '馬其頓',
        'country.ml': '馬利',
        'country.mm': '緬甸',
        'country.mn': '蒙古',
        'country.mo': '澳門',
        'country.mp': '北馬里亞納群島',
        'country.mq': '法屬馬丁尼克',
        'country.mr': '茅利塔尼亞',
        'country.ms': '蒙瑟拉特島',
        'country.mt': '馬爾他',
        'country.mu': '模里西斯',
        'country.mv': '馬爾地夫',
        'country.mw': '馬拉威',
        'country.mx': '墨西哥',
        'country.my': '馬來西亞',
        'country.mz': '莫三比克',
        'country.na': '納米比亞',
        'country.nc': '新喀里多尼亞',
        'country.ne': '尼日',
        'country.nf': '諾福克群島',
        'country.ng': '奈及利亞',
        'country.ni': '尼加拉瓜',
        'country.nl': '荷蘭',
        'country.no': '挪威',
        'country.np': '尼泊爾',
        'country.nr': '諾魯',
        'country.nu': '紐威島',
        'country.nz': '紐西蘭',
        'country.om': '阿曼',
        'country.pa': '巴拿馬',
        'country.pe': '秘魯',
        'country.pf': '法屬玻里尼西亞',
        'country.pg': '巴布亞新幾內亞',
        'country.ph': '菲律賓',
        'country.pk': '巴基斯坦',
        'country.pl': '波蘭',
        'country.pm': '聖匹及密啟倫群島',
        'country.pn': '皮特康島',
        'country.pr': '波多黎各',
        'country.ps': '巴勒斯坦',
        'country.pt': '葡萄牙',
        'country.pw': '帛琉',
        'country.py': '巴拉圭',
        'country.qa': '卡達',
        'country.re': '留尼旺',
        'country.ro': '羅馬尼亞',
        'country.rs': '塞爾維亞',
        'country.ru': '俄羅斯',
        'country.rw': '盧安達',
        'country.sa': '沙烏地阿拉伯',
        'country.sb': '索羅門群島',
        'country.sc': '塞席爾',
        'country.sd': '蘇丹',
        'country.se': '瑞典',
        'country.sg': '新加坡',
        'country.sh': '聖赫勒拿島',
        'country.si': '斯洛凡尼亞',
        'country.sj': '斯瓦巴及尖棉島',
        'country.sk': '斯洛伐克',
        'country.sl': '獅子山',
        'country.sm': '聖馬利諾',
        'country.sn': '塞內加爾',
        'country.so': '索馬利亞',
        'country.sr': '蘇利南',
        'country.ss': '南蘇丹',
        'country.st': '聖多美普林西比',
        'country.sv': '薩爾瓦多',
        'country.sx': '聖馬丁(荷屬)',
        'country.sy': '敘利亞',
        'country.sz': '史瓦濟蘭',
        'country.tc': '土克斯及開科斯群島',
        'country.td': '查德',
        'country.tf': '法屬南部屬地',
        'country.tg': '多哥',
        'country.th': '泰國',
        'country.tj': '塔吉克',
        'country.tk': '托克勞群島',
        'country.tl': '東帝汶',
        'country.tm': '土庫曼',
        'country.tn': '突尼西亞',
        'country.to': '東加',
        'country.tr': '土耳其',
        'country.tt': '千里達與多巴哥',
        'country.tv': '吐瓦魯',
        'country.tw': '台灣',
        'country.tz': '坦尚尼亞',
        'country.ua': '烏克蘭',
        'country.ug': '烏干達',
        'country.um': '美國本土外小島嶼',
        'country.us': '美國',
        'country.uy': '烏拉圭',
        'country.uz': '烏茲別克斯坦',
        'country.va': '教廷',
        'country.vc': '聖文森特與格林納丁斯',
        'country.ve': '委內瑞拉',
        'country.vg': '維京群島（英屬）',
        'country.vi': '維京群島（美國）',
        'country.vn': '越南',
        'country.vu': '萬那杜',
        'country.wf': '瓦利斯與富圖納群島',
        'country.ws': '薩摩亞',
        'country.ye': '葉門',
        'country.yt': '馬約特',
        'country.za': '南非',
        'country.zm': '贊比亞',
        'country.zw': '辛巴威',
        cyclocross: '公路越野',
        'date.all_time': '日期：所有時間',
        'date.last_month': '日期：上個月',
        'date.last_week': '日期：上週',
        'date.last_year': '日期：去年',
        'employees.add_cta_label': '新增職員',
        'employees.delete_cta_label': '刪除職員',
        'employees.employee_id_label': '職員ID',
        'employees.heading': '職員',
        'employees.intro': '您所有最近使用Retül科技的職員。',
        'employees.update_cta_label': '更新職員',
        'filters.all_label': '全部',
        'filters.client_all': '全部',
        'filters.client_anonymous': '匿名',
        'filters.client_identified': '已辨識',
        'filters.client_label': '客戶類型',
        'filters.employees_label': '職員',
        'filters.filters_heading': '篩選條件',
        'filters.hide_filters': '顯示篩選條件',
        'filters.locations_label': '地點',
        'filters.rating_all': '全部',
        'filters.rating_label': '評分',
        'filters.rating_none': '無',
        'filters.session_all': '全部',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': '階段類型',
        'filters.session_match': 'Match',
        'filters.show_filters': '顯示篩選條件',
        'filters.status_all': '全部',
        'filters.status_closed': '結案',
        'filters.status_label': '狀態',
        'filters.status_open': '待處理',
        'footer.privacy_link_label': '隱私政策',
        'footer.terms_link_label': '使用條款',
        'form.address_1_field_error': '此欄位必填',
        'form.address_1_field_label': '地址',
        'form.city_field_error': '此欄位必填',
        'form.city_field_label': '城市',
        'form.country_field_error': '此欄位必填',
        'form.country_field_label': '國家',
        'form.delete_field_confirm_message': '您是否確認刪除 {data}？',
        'form.delete_field_label': '刪除',
        'form.edit_field_label': '編輯',
        'form.email_field_error': '此欄位必填，且必須為有效的電子郵件格式。',
        'form.email_field_label': '電子郵件',
        'form.name_field_error': '此欄位必填',
        'form.name_field_label': '店名',
        'form.shop_field_label': '商店',
        'form.shops_field_label': '商店',
        'form.state_field_error': '此欄位必填',
        'form.state_field_label': '州',
        'form.zip_field_error': '此欄位必填',
        'form.zip_field_label': '郵遞區號',
        'gender.all': '性別：全部',
        'gender.female': '性别：女',
        'gender.male': '性別：男',
        'landing.heading': '歡迎來到Retailer Portal',
        'landing.intro': '讀取Retül科技擷取的數據、階段與客戶訊息。',
        'landing.sign_in_or_create_account_label': '登入／建立帳號',
        'locale.label_cs': 'Česky',
        'locale.label_de': 'Deutsch',
        'locale.label_en': 'English',
        'locale.label_en-us': 'English (US)',
        'locale.label_es': 'Espanol',
        'locale.label_fr': 'Français',
        'locale.label_it': 'Italiano',
        'locale.label_ja': '日本語',
        'locale.label_ko': '한국어',
        'locale.label_nl': 'Nederlands',
        'locale.label_pl': 'Polski',
        'locale.label_pt': 'Português',
        'locale.label_pt-br': 'Português do Brasil',
        'locale.label_zh-cn': '简体中文',
        'locale.label_zh-tw': '繁體中文',
        'locale.locale_picker_cancel_label': '取消',
        'locale.locale_picker_heading': '選擇您的語言。',
        'locale.locale_picker_save_label': '儲存',
        'locations.add_cta_label': '新增地點',
        'locations.delete_cta_label': '刪除地點',
        'locations.empty': '無新增地點，欲使用Retül App請新增地點。',
        'locations.empty_cta_label': '管理地點',
        'locations.heading': '管理地點',
        'locations.intro': '新增、編輯與移除使用Retül科技的地點。',
        'locations.location_id_label': '地點ID',
        'locations.update_cta_label': '更新地點',
        'metrics.closed_session_tooltip':
            '已結案階段表示一筆交易已紀錄。無論客戶是否已購買， {closingSessions}可幫助您追蹤Retül科技的銷售情況。',
        'metrics.closed_sessions': '已結案階段',
        'metrics.closing_session_link': '階段結案中',
        'metrics.total_sessions': '總計階段',
        'metrics.unique_client_emails': '唯一客戶電子郵件',
        'metrics.unique_emails_tooltip':
            '此數字表示唯一客戶所進行的階段。唯一客戶由其電子郵件地址來進行辨識。',
        'mtb-trail': '山林越野 (林道)',
        'mtb-xc': '山林越野 (XC)',
        'nav.analytics': '分析',
        'nav.greeting_label': '哈囉，{data}',
        'nav.manage_employees_link_label': '管理職員',
        'nav.manage_locations_link_label': '管理地點',
        'nav.sessions': '階段',
        'nav.sign_out_link_label': '登出',
        'pagination.next': '下一頁',
        'pagination.page': '頁',
        'pagination.previous': '上一頁',
        road: '公路',
        'session_details.add_transaction_cta_label': '新增交易紀錄',
        'session_details.all_sessions_link': '所有階段',
        'session_details.bike_product': '自行車',
        'session_details.client': '客戶',
        'session_details.client_review_label': '客戶評論',
        'session_details.complete_session_cta_label': '標記為結案',
        'session_details.complete_session_message':
            '藉由新增任一筆客戶進行的交易來關閉此階段，或',
        'session_details.complete_status': '結案',
        'session_details.created_on': '建立日期： {data}',
        'session_details.delete_link': '刪除',
        'session_details.employee': '職員： {data}',
        'session_details.family_label': '車款',
        'session_details.incomplete_status': '待處理',
        'session_details.model_label': '型號',
        'session_details.no_transactions_message': '未加入任何交易。',
        'session_details.product_label': '產品',
        'session_details.saddle_product': '座墊',
        'session_details.shoe_product': '車鞋',
        'session_details.shop': '商店： {data}',
        'session_details.size_label': '尺寸',
        'session_details.status_label': '狀態',
        'session_details.transactions_heading': '階段交易',
        'session_products.arch_height_label': '足弓高度： {data}',
        'session_products.bike_size_label': '自行車尺寸： {data}',
        'session_products.saddle_height_label': '座墊高度： {data} mm',
        'session_products.saddle_width_label': '座墊寬度： {data} mm',
        'session_products.session_product_label': '階段數據',
        'session_products.shoe_size_label': '鞋碼： {data}',
        'sessions.add_transaction_cta_label': '新增交易紀錄',
        'sessions.client_anonymous': '匿名',
        'sessions.client_label': '客戶',
        'sessions.closed_status': '結案',
        'sessions.csv_downloading_message':
            '我們正在為您將匯出的CSV檔案收集所有階段，這可能需要幾分鐘時間。',
        'sessions.date_label': '日期',
        'sessions.employee_label': '職員',
        'sessions.export_csv_link': '匯出清單（.CSV）',
        'sessions.filter_empty':
            '抱歉，沒有符合您的篩選條件的階段。請重置篩選條件。',
        'sessions.filter_empty_cta_label': '重置篩選',
        'sessions.heading': '階段',
        'sessions.intro': '您所有最近使用Retül科技的時間表。',
        'sessions.location_label': '地點',
        'sessions.no_rating': '無評分',
        'sessions.open_status': '待處理',
        'sessions.rating_label': '評分',
        'sessions.session_id_label': '階段ID',
        'sessions.session_loading_message':
            '我們正在收集您所有的階段，這可能需要一些時間。',
        'sessions.status_label': '狀態',
        'sign_in.heading': '登入',
        'sign_in.intro':
            '輸入您的電子郵件與密碼來開始使用，或選擇“註冊帳號”以建立新帳號。',
        'status.all': '狀態：全部',
        'status.complete': '狀態：結案',
        'status.incomplete': '狀態：待處理',
        triathlon: '鐵人三項',
        'POWER MIMIC DESCRIPTION':
            '此座墊能使麻木與疼痛消失，其設計模仿人體針對不同類型壓力的反應，於軟組織內形成平衡。',
        'date_picker.all_time': '所有階段',
        'date_picker.date_range': '日期範圍',
        'date_picker.last_month': '上個月',
        'date_picker.last_week': '上週',
        'date_picker.last_year': '去年',
        'date_picker.this_month': '本月',
        'date_picker.this_week': '本周',
        'date_picker.this_year': '今年',
        'session_details.client_notes': '客戶備註',
        'session_details.footbed_product': '鞋墊',
        'session_products.it_width_label': '坐骨寬度： {data} mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match 是一項店內測量體驗，能夠精準測量您的腿長，以提供最理想的自行車大小，以及最適合您的產品。',
        'body_data.about_saddle':
            'Retül Match 是店內的三維虛擬實境體驗，利用了 Retül Digital Sitbone Device，以數位壓力圖像科技來取得您坐骨寬度，讓我們知道符合您的正確坐墊尺寸，以及最適合您的坐墊。',
        'body_data.about_shoe':
            'Retül Match 是店內的三維虛擬實境體驗，能夠取得您足部的資料，以提供正確的足弓墊需求、鞋碼定制以及最適合您的鞋子。',
        'body_data.arch_height_label': '足弓高度',
        'body_data.bike_label': '自行車',
        'body_data.bike_size_label': '自行車大小',
        'body_data.centimeter_symbol': '公分',
        'body_data.circumference_label': '圓周長',
        'body_data.find_retul_technology': '尋找 Retül 科技',
        'body_data.heading': '人體資料',
        'body_data.intro':
            '透過 Retül Vantage 科技取得人體資料及騎乘偏好，以提供正確的尺寸資訊及最適合您的產品。',
        'body_data.length_label': '長度',
        'body_data.load_error': '我們無法目前取得您的人體資訊，請稍後重試。',
        'body_data.millimeter_symbol': '公釐',
        'body_data.not_collected_label': '未收集',
        'body_data.recommended_label': '建議',
        'body_data.saddle_height_label': '坐墊高度',
        'body_data.saddle_label': '坐墊',
        'body_data.saddle_size_label': '坐墊尺寸',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': '脛骨',
        'body_data.shoe_label': '鞋子',
        'body_data.shoe_size_label': '鞋碼',
        'body_data.sit_bone_label': '坐骨',
        'body_data.starting_label': '起始',
        'body_data.thigh_label': '大腿',
        'body_data.view_bike_matches_label': '檢視相配的自行車',
        'body_data.view_saddle_matches_label': '檢視相配的坐墊',
        'body_data.view_shoe_matches_label': '檢視相配的鞋子',
        'body_data.your_progress_completion_label': '完成 {numComplete}/3',
        'body_data.your_progress_label': '您的進度',
        cancel_label: '取消',
        'create_account.heading': '建立帳戶',
        'create_account.intro':
            '請輸入電子郵件及密碼以建立 Retül Passport 帳戶。',
        'edit_form.edit_form_cancel_label': '取消',
        'edit_form.edit_form_save_label': '儲存',
        email_format_error: '無效的電子郵件格式。',
        email_label: '電子郵件',
        'forgot_password.heading': '忘記密碼',
        'forgot_password.intro':
            '忘記密碼？ 請輸入您的電子郵件，我們將會寄送重設的連結給您。',
        'forgot_password.reset_email_sent': '密碼重設的郵件已經寄送至 {email}',
        'landing.create_account_label': '建立帳戶',
        'landing.sign_in_label': '登入',
        'nav.account_profiles_link_label': '帳戶及騎手檔案',
        'nav.home_page_link_label': '首頁',
        'nav.logout_link_label': '登出',
        'not_found.go_home_label': '前往首頁',
        'not_found.heading': '404',
        'not_found.intro': '您想找的頁面似乎已被移動、刪除或不存在。',
        password_invalid_error: '密碼無效。',
        password_label: '密碼',
        'product_matches.bike_size_label': '自行車大小',
        'product_matches.category_filter_commute_comfort_label': '通勤兼休閒性',
        'product_matches.category_filter_commute_sport_label': '通勤兼運動性',
        'product_matches.category_filter_cyclocross_label': '越野賽',
        'product_matches.category_filter_label': '類別',
        'product_matches.category_filter_label_road': '道路',
        'product_matches.category_filter_mountain_trail_label': '登山（車徑）',
        'product_matches.category_filter_mountain_xc_label': '登山（跨國）',
        'product_matches.category_filter_triathlon_label': '鐵人三項運動',
        'product_matches.centimeter_symbol': '公分',
        'product_matches.empty_session':
            '我們沒有人體資料，所以無法提供您此產品類型的 Match 結果。請使用上面的篩選條件以查看其他產品類型，或使用地點頁面以搜尋地點以完成此 Match 模組。',
        'product_matches.find_retul_technology_label': '尋找 Retül 科技',
        'product_matches.gender_filter_all_label': '顯示所有產品',
        'product_matches.gender_filter_female_label': '顯示女性產品',
        'product_matches.gender_filter_male_label': '顯示男性產品',
        'product_matches.heading': '產品配對',
        'product_matches.intro':
            'Retül 科技利用您的人體資料及騎乘偏好，為您提供最合適的 Specialized 產品。',
        'product_matches.last_session_match_label': '上一個 Match 工作階段期間',
        'product_matches.load_error':
            '我們無法目前取得您的產品配對，請稍後重試。',
        'product_matches.millimeter_symbol': '公釐',
        'product_matches.overlay_bike_size_details':
            '根據在您的 Retül Match 工作階段期間獲得的生物測量資料，我們推薦以上大小的自行車。雖然這是個很好的起始點，我們仍建議您諮詢您的當地零售商，根據您的體驗水平和體型為您提供建議。',
        'product_matches.overlay_bike_size_label': '自行車大小',
        'product_matches.overlay_close_label': '關閉',
        'product_matches.overlay_indicator_label': '您',
        'product_matches.overlay_saddle_height_details':
            '根據在您的 Retül Match 工作階段期間獲得的生物測量資料，我們推薦以上坐墊高度。雖然這是個很好的起始點，我們仍建議您諮詢您的當地零售商，根據您的體驗水平和體型為您提供建議。',
        'product_matches.overlay_saddle_height_label': '坐墊高度',
        'product_matches.overlay_saddle_size_details':
            '根據在您的 Retül Match 工作階段期間獲得的生物測量資料，我們推薦以上坐墊尺寸。雖然這是個很好的起始點，我們仍建議您諮詢您的當地零售商，根據您的人體資料為您提供建議。',
        'product_matches.overlay_saddle_size_label': '坐墊尺寸',
        'product_matches.overlay_shoe_size_details':
            '根據在您的 Retül Match 工作階段期間獲得的生物測量資料，我們推薦以上鞋碼。雖然這是個很好的起始點，我們仍建議您諮詢您的當地零售商，根據您的體驗水平為您提供建議。',
        'product_matches.overlay_shoe_size_label': '鞋碼',
        'product_matches.padding_level_label': '坐墊等級',
        'product_matches.product_type_filter_bikes_label': '自行車',
        'product_matches.product_type_filter_label': '產品種類',
        'product_matches.product_type_filter_saddles_label': '坐墊',
        'product_matches.product_type_filter_shoes_label': '鞋子',
        'product_matches.recommended_label': '建議',
        'product_matches.saddle_detail_size_label': '坐墊尺寸：',
        'product_matches.saddle_height_label': '坐墊高度',
        'product_matches.saddle_models_label': '型號',
        'product_matches.saddle_size_label': '坐墊尺寸',
        'product_matches.shoe_size_label': '鞋碼',
        'product_matches.starting_label': '起始',
        'product_matches.view_on_specialized_label': '檢視 Specialized',
        'profile.account_heading': '帳戶',
        'profile.bike_type_city_label': '城市',
        'profile.bike_type_label': '自行車類型',
        'profile.bike_type_mountain_label': '登山',
        'profile.bike_type_road_label': '道路',
        'profile.email_error': '請輸入有效的電子郵件',
        'profile.email_label': '電子郵件',
        'profile.gender_female_label': '女',
        'profile.gender_label': '性別',
        'profile.gender_male_label': '男',
        'profile.heading': '帳戶及騎手檔案',
        'profile.intro': '即將推出... 請編輯您的帳戶資訊及騎手檔案。',
        'profile.name_error': '請輸入名字',
        'profile.name_label': '名字',
        'profile.password_error': '請輸入密碼',
        'profile.password_label': '密碼',
        'profile.position_high_label': '高',
        'profile.position_label': '位置',
        'profile.position_low_label': '低',
        'profile.position_medium_label': '中',
        'profile.ride_duration_extended_label': '90 分鐘以上',
        'profile.ride_duration_label': '持續時間',
        'profile.ride_duration_long_label': '60 至 90 分鐘',
        'profile.ride_duration_medium_label': '30 至 60 分鐘',
        'profile.ride_duration_short_label': '0 至 30 分鐘',
        'profile.ride_frequency_label': '頻率',
        'profile.ride_frequency_once_month_label': '每個月一次',
        'profile.ride_frequency_once_week_label': '每個星期一次',
        'profile.ride_frequency_six_week_label': '每個星期 6 次',
        'profile.ride_frequency_three_week_label': '每個星期 3 次',
        'routes.account_profile_route_label': '帳戶及騎手檔案',
        'routes.body_data_route_label': '人體資料',
        'routes.create_account_route_label': '建立帳戶',
        'routes.forgot_password_route_label': '忘記密碼',
        'routes.locations_route_label': '地點',
        'routes.not_found_route_label': '未找到',
        'routes.privacy_policy_route_label': '隱私權政策',
        'routes.product_matches_route_label': '產品配對',
        'routes.reset_password_route_label': '重設密碼',
        'routes.set_password_route_label': '重設密碼',
        'routes.sessions_route_label': '工作階段',
        'routes.sign_in_route_label': '登入',
        'routes.terms_of_service_route_label': '服務條款',
        'sessions.download_pdf_cta_label': '下載 PDF',
        'sessions.empty': '您未有任何工作階段。',
        'sessions.empty_cta_label': '尋找位置',
        'sessions.loading_error': '我們無法目前取得您的工作階段，請稍後重試。',
        'sessions.loading_error_cta_label': '請再試一次',
        'sessions.session_type_filter_label': '工作階段類型',
        'session_type.dfd_session_name': 'Retül Digital Foot Device',
        'session_type.dsd_session_name': 'Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül Foot',
        'session_type.legs_session_name': 'Retül Legs',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül Saddle',
        'session_type.unknown_session_name': 'Retül Session',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': '登入',
        'sign_in.contact_label': '與 Retül 聯絡',
        'sign_in.forgot_password_label': '忘記密碼？',
        'sign_in.sign_in_error':
            '我們無法取得您的使用者資料。請與 Retül 支援聯絡以取得協助。',
        submit_label: '提交',
    },
};
