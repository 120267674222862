import React, {Component} from 'react';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import ModalClose from '../../components/Modal/ModalClose';

export default class ConfirmationModal extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openModal && !this.state.modalIsOpen) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false}, this.props.modalClosed);
  }

  handleOnSubmit(e) {
    const {id, confirmFunction} = this.props;

    e.preventDefault();
    confirmFunction(id);
    this.setState({modalIsOpen: false});
  }

  renderConfirmation() {
    const {confirmMessage, submitText} = this.props;
    return (
      <form className="modal-content">
        <div className="modal-body">
          <p>
            {confirmMessage}
          </p>
        </div>
        <div className="modal-footer">
          <Button
            onClick={this.handleOnSubmit}
            type="submit"
          >
            {submitText}
          </Button>
        </div>
      </form>
    );
  }

  render() {
    const {displayedText} = this.props;
    return (
      <Modal
        className={{
          base: 'modal',
          afterOpen: '',
          beforeClose: ''
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: '',
          beforeClose: ''
        }}

        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        contentLabel={`${displayedText} Modal`}
      >
        <ModalClose closeModal={this.closeModal}/>
        {this.renderConfirmation()}
      </Modal>
    )
  }
}
