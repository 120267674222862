import React from 'react';


export default function (props) {
  return (
    <div className="icon icon_information">
      <span>i</span>
    </div>
  )
}
