export function getDimensions(state) {
  return state.dimensions;
}

export function getMargin(state) {
  return getDimensions(state).margin;
}

export function parentContainerWidth(component) {
  return component
    .chartContainer
    .getBoundingClientRect()
    .width;
}

export function parentContainerHeight(component) {
  return component
    .chartContainer
    .getBoundingClientRect()
    .height;
}
