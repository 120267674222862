import { defineMessages } from 'react-intl';

export default defineMessages({
  'unique_emails_tooltip': {
    'id': 'metrics.unique_emails_tooltip',
    'defaultMessage': 'This number represents sessions that have been conducted with unique clients. Unique clients are determined by their email address.',
    'description': 'Tooltip displayed on the unique emails metric'
  },
  'closed_session_tooltip': {
    'id': 'metrics.closed_session_tooltip',
    'defaultMessage': 'Closed sessions indicate sessions that have a transaction recorded. If a client made or did not make a purchase, {closingSessions} helps you track sales from Retül technology.',
    'description': 'Tooltip displayed on the closed sessions metric. {closingSessions} is a link to the sessions page with the text "closing session""'
  },
  'closing_session': {
    'id': 'metrics.closing_session_link',
    'defaultMessage': 'closing session',
    'description': 'link displayed inline the closed session tooltip'
  }
});
