/**
 * Javascript implementation of Java’s String.hashCode method
 *
 * @method MakeHash
 * @param {String} str
 * @return {Int} 32 bit integer unique to the string
 */
export default function (str) {
  let hash = 0, i, chr, len;

  str = str + '';

  if (str.length === 0) return hash;
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
