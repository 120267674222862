import { defineMessages } from 'react-intl';

export default defineMessages({
  'terms_link_label': {
    'id': 'footer.terms_link_label',
    'defaultMessage': 'Terms of Use',
    'description': 'Footer terms of use link text'
  },
  'privacy_link_label': {
    'id': 'footer.privacy_link_label',
    'defaultMessage': 'Privacy Policy',
    'description': 'Footer data privacy declaration link text'
  }
});
