import React, { Component } from 'react';
import * as d3Axis from 'd3-axis';
import { select as d3Select } from 'd3-selection';
import './Axis.css';

export default class Axis extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  setTickQuantity(axis) {
    const { ticks } = this.props;
    if (ticks) {
      return axis.ticks(ticks)
    } else {
      return axis;
    }
  }

  setTickSize(axis) {
    const { tickSize } = this.props;
    if (tickSize) {
      return axis.tickSize(tickSize);
    } else {
      return axis;
    }
  }

  setTickValues(axis) {
    const { tickValues } = this.props;
    if (tickValues) {
      return axis.tickValues(tickValues)
    } else {
      return axis;
    }
  }

  formatXAxis(axis) {

    return this.formatAxis(axis)
  }

  formatYAxis(axis) {
    return this.formatAxis(axis)
  }

  formatAxis(axis) {
    axis = this.setTickQuantity(axis);
    axis = this.setTickSize(axis);
    return this.setTickValues(axis);
  }

  renderAxis() {
    const { orientation, scale } = this.props;
    let axis = d3Axis[ `axis${orientation}` ]()
      .scale(scale)

    if (orientation === 'Bottom') {
      axis = this.formatXAxis(axis)
      d3Select(this.axisElement)
        .call(axis)
    } else {
      axis = this.formatYAxis(axis)
      d3Select(this.axisElement)
        .call(axis);

    }
  }

  className() {
    const { orientation } = this.props;
    return `Horizontal-Bar-Chart Axis Axis-${orientation}`;
  }

  render() {
    const { translate } = this.props;
    return (
      <g
        className={ this.className() }
        ref={ (el) => {
          this.axisElement = el
        } }
        transform={ translate }
      />
    )
  }
}



