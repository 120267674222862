import React, {Component} from 'react';
import {setFill} from "../../../../utils/chartHelper";
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'

export default class Arc extends Component {

  render() {

    const {arc, data, colorRange} = this.props;
    const {item} = data.data;
    return (
      <Tippy content={data.value.toString()} followCursor={true}>
        <path
          d={arc(data)}
          style={setFill(colorRange, item)}
        >
        </path>
      </Tippy>
    )
  }
}