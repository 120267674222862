export const COUNTRY_CODES = [
  {
    "value":"AD",
    "label":"country.ad"
  },
  {
    "value":"AE",
    "label":"country.ae"
  },
  {
    "value":"AF",
    "label":"country.af"
  },
  {
    "value":"AG",
    "label":"country.ag"
  },
  {
    "value":"AI",
    "label":"country.ai"
  },
  {
    "value":"AL",
    "label":"country.al"
  },
  {
    "value":"AM",
    "label":"country.am"
  },
  {
    "value":"AO",
    "label":"country.ao"
  },
  {
    "value":"AQ",
    "label":"country.aq"
  },
  {
    "value":"AR",
    "label":"country.ar"
  },
  {
    "value":"AS",
    "label":"country.as"
  },
  {
    "value":"AT",
    "label":"country.at"
  },
  {
    "value":"AU",
    "label":"country.au"
  },
  {
    "value":"AW",
    "label":"country.aw"
  },
  {
    "value":"AX",
    "label":"country.ax"
  },
  {
    "value":"AZ",
    "label":"country.az"
  },
  {
    "value":"BA",
    "label":"country.ba"
  },
  {
    "value":"BB",
    "label":"country.bb"
  },
  {
    "value":"BD",
    "label":"country.bd"
  },
  {
    "value":"BE",
    "label":"country.be"
  },
  {
    "value":"BF",
    "label":"country.bf"
  },
  {
    "value":"BG",
    "label":"country.bg"
  },
  {
    "value":"BH",
    "label":"country.bh"
  },
  {
    "value":"BI",
    "label":"country.bi"
  },
  {
    "value":"BJ",
    "label":"country.bj"
  },
  {
    "value":"BL",
    "label":"country.bl"
  },
  {
    "value":"BM",
    "label":"country.bm"
  },
  {
    "value":"BN",
    "label":"country.bn"
  },
  {
    "value":"BO",
    "label":"country.bo"
  },
  {
    "value":"BQ",
    "label":"country.bq"
  },
  {
    "value":"BR",
    "label":"country.br"
  },
  {
    "value":"BS",
    "label":"country.bs"
  },
  {
    "value":"BT",
    "label":"country.bt"
  },
  {
    "value":"BV",
    "label":"country.bv"
  },
  {
    "value":"BW",
    "label":"country.bw"
  },
  {
    "value":"BY",
    "label":"country.by"
  },
  {
    "value":"BZ",
    "label":"country.bz"
  },
  {
    "value":"CA",
    "label":"country.ca"
  },
  {
    "value":"CC",
    "label":"country.cc"
  },
  {
    "value":"CD",
    "label":"country.cd"
  },
  {
    "value":"CF",
    "label":"country.cf"
  },
  {
    "value":"CG",
    "label":"country.cg"
  },
  {
    "value":"CH",
    "label":"country.ch"
  },
  {
    "value":"CI",
    "label":"country.ci"
  },
  {
    "value":"CK",
    "label":"country.ck"
  },
  {
    "value":"CL",
    "label":"country.cl"
  },
  {
    "value":"CM",
    "label":"country.cm"
  },
  {
    "value":"CN",
    "label":"country.cn"
  },
  {
    "value":"CO",
    "label":"country.co"
  },
  {
    "value":"CR",
    "label":"country.cr"
  },
  {
    "value":"CU",
    "label":"country.cu"
  },
  {
    "value":"CV",
    "label":"country.cv"
  },
  {
    "value":"CW",
    "label":"country.cw"
  },
  {
    "value":"CX",
    "label":"country.cx"
  },
  {
    "value":"CY",
    "label":"country.cy"
  },
  {
    "value":"CZ",
    "label":"country.cz"
  },
  {
    "value":"DE",
    "label":"country.de"
  },
  {
    "value":"DJ",
    "label":"country.dj"
  },
  {
    "value":"DK",
    "label":"country.dk"
  },
  {
    "value":"DM",
    "label":"country.dm"
  },
  {
    "value":"DO",
    "label":"country.do"
  },
  {
    "value":"DZ",
    "label":"country.dz"
  },
  {
    "value":"EC",
    "label":"country.ec"
  },
  {
    "value":"EE",
    "label":"country.ee"
  },
  {
    "value":"EG",
    "label":"country.eg"
  },
  {
    "value":"EH",
    "label":"country.eh"
  },
  {
    "value":"ER",
    "label":"country.er"
  },
  {
    "value":"ES",
    "label":"country.es"
  },
  {
    "value":"ET",
    "label":"country.et"
  },
  {
    "value":"FI",
    "label":"country.fi"
  },
  {
    "value":"FJ",
    "label":"country.fj"
  },
  {
    "value":"FK",
    "label":"country.fk"
  },
  {
    "value":"FM",
    "label":"country.fm"
  },
  {
    "value":"FO",
    "label":"country.fo"
  },
  {
    "value":"FR",
    "label":"country.fr"
  },
  {
    "value":"GA",
    "label":"country.ga"
  },
  {
    "value":"GB",
    "label":"country.gb"
  },
  {
    "value":"GD",
    "label":"country.gd"
  },
  {
    "value":"GE",
    "label":"country.ge"
  },
  {
    "value":"GF",
    "label":"country.gf"
  },
  {
    "value":"GG",
    "label":"country.gg"
  },
  {
    "value":"GH",
    "label":"country.gh"
  },
  {
    "value":"GI",
    "label":"country.gi"
  },
  {
    "value":"GL",
    "label":"country.gl"
  },
  {
    "value":"GM",
    "label":"country.gm"
  },
  {
    "value":"GN",
    "label":"country.gn"
  },
  {
    "value":"GP",
    "label":"country.gp"
  },
  {
    "value":"GQ",
    "label":"country.gq"
  },
  {
    "value":"GR",
    "label":"country.gr"
  },
  {
    "value":"GS",
    "label":"country.gs"
  },
  {
    "value":"GT",
    "label":"country.gt"
  },
  {
    "value":"GU",
    "label":"country.gu"
  },
  {
    "value":"GW",
    "label":"country.gw"
  },
  {
    "value":"GY",
    "label":"country.gy"
  },
  {
    "value":"HK",
    "label":"country.hk"
  },
  {
    "value":"HM",
    "label":"country.hm"
  },
  {
    "value":"HN",
    "label":"country.hn"
  },
  {
    "value":"HR",
    "label":"country.hr"
  },
  {
    "value":"HT",
    "label":"country.ht"
  },
  {
    "value":"HU",
    "label":"country.hu"
  },
  {
    "value":"ID",
    "label":"country.id"
  },
  {
    "value":"IE",
    "label":"country.ie"
  },
  {
    "value":"IL",
    "label":"country.il"
  },
  {
    "value":"IM",
    "label":"country.im"
  },
  {
    "value":"IN",
    "label":"country.in"
  },
  {
    "value":"IO",
    "label":"country.io"
  },
  {
    "value":"IQ",
    "label":"country.iq"
  },
  {
    "value":"IR",
    "label":"country.ir"
  },
  {
    "value":"IS",
    "label":"country.is"
  },
  {
    "value":"IT",
    "label":"country.it"
  },
  {
    "value":"JE",
    "label":"country.je"
  },
  {
    "value":"JM",
    "label":"country.jm"
  },
  {
    "value":"JO",
    "label":"country.jo"
  },
  {
    "value":"JP",
    "label":"country.jp"
  },
  {
    "value":"KE",
    "label":"country.ke"
  },
  {
    "value":"KG",
    "label":"country.kg"
  },
  {
    "value":"KH",
    "label":"country.kh"
  },
  {
    "value":"KI",
    "label":"country.ki"
  },
  {
    "value":"KM",
    "label":"country.km"
  },
  {
    "value":"KN",
    "label":"country.kn"
  },
  {
    "value":"KP",
    "label":"country.kp"
  },
  {
    "value":"KR",
    "label":"country.kr"
  },
  {
    "value":"KW",
    "label":"country.kw"
  },
  {
    "value":"KY",
    "label":"country.ky"
  },
  {
    "value":"KZ",
    "label":"country.kz"
  },
  {
    "value":"LA",
    "label":"country.la"
  },
  {
    "value":"LB",
    "label":"country.lb"
  },
  {
    "value":"LC",
    "label":"country.lc"
  },
  {
    "value":"LI",
    "label":"country.li"
  },
  {
    "value":"LK",
    "label":"country.lk"
  },
  {
    "value":"LR",
    "label":"country.lr"
  },
  {
    "value":"LS",
    "label":"country.ls"
  },
  {
    "value":"LT",
    "label":"country.lt"
  },
  {
    "value":"LU",
    "label":"country.lu"
  },
  {
    "value":"LV",
    "label":"country.lv"
  },
  {
    "value":"LY",
    "label":"country.ly"
  },
  {
    "value":"MA",
    "label":"country.ma"
  },
  {
    "value":"MC",
    "label":"country.mc"
  },
  {
    "value":"MD",
    "label":"country.md"
  },
  {
    "value":"ME",
    "label":"country.me"
  },
  {
    "value":"MF",
    "label":"country.mf"
  },
  {
    "value":"MG",
    "label":"country.mg"
  },
  {
    "value":"MH",
    "label":"country.mh"
  },
  {
    "value":"MK",
    "label":"country.mk"
  },
  {
    "value":"ML",
    "label":"country.ml"
  },
  {
    "value":"MM",
    "label":"country.mm"
  },
  {
    "value":"MN",
    "label":"country.mn"
  },
  {
    "value":"MO",
    "label":"country.mo"
  },
  {
    "value":"MP",
    "label":"country.mp"
  },
  {
    "value":"MQ",
    "label":"country.mq"
  },
  {
    "value":"MR",
    "label":"country.mr"
  },
  {
    "value":"MS",
    "label":"country.ms"
  },
  {
    "value":"MT",
    "label":"country.mt"
  },
  {
    "value":"MU",
    "label":"country.mu"
  },
  {
    "value":"MV",
    "label":"country.mv"
  },
  {
    "value":"MW",
    "label":"country.mw"
  },
  {
    "value":"MX",
    "label":"country.mx"
  },
  {
    "value":"MY",
    "label":"country.my"
  },
  {
    "value":"MZ",
    "label":"country.mz"
  },
  {
    "value":"NA",
    "label":"country.na"
  },
  {
    "value":"NC",
    "label":"country.nc"
  },
  {
    "value":"NE",
    "label":"country.ne"
  },
  {
    "value":"NF",
    "label":"country.nf"
  },
  {
    "value":"NG",
    "label":"country.ng"
  },
  {
    "value":"NI",
    "label":"country.ni"
  },
  {
    "value":"NL",
    "label":"country.nl"
  },
  {
    "value":"NO",
    "label":"country.no"
  },
  {
    "value":"NP",
    "label":"country.np"
  },
  {
    "value":"NR",
    "label":"country.nr"
  },
  {
    "value":"NU",
    "label":"country.nu"
  },
  {
    "value":"NZ",
    "label":"country.nz"
  },
  {
    "value":"OM",
    "label":"country.om"
  },
  {
    "value":"PA",
    "label":"country.pa"
  },
  {
    "value":"PE",
    "label":"country.pe"
  },
  {
    "value":"PF",
    "label":"country.pf"
  },
  {
    "value":"PG",
    "label":"country.pg"
  },
  {
    "value":"PH",
    "label":"country.ph"
  },
  {
    "value":"PK",
    "label":"country.pk"
  },
  {
    "value":"PL",
    "label":"country.pl"
  },
  {
    "value":"PM",
    "label":"country.pm"
  },
  {
    "value":"PN",
    "label":"country.pn"
  },
  {
    "value":"PR",
    "label":"country.pr"
  },
  {
    "value":"PS",
    "label":"country.ps"
  },
  {
    "value":"PT",
    "label":"country.pt"
  },
  {
    "value":"PW",
    "label":"country.pw"
  },
  {
    "value":"PY",
    "label":"country.py"
  },
  {
    "value":"QA",
    "label":"country.qa"
  },
  {
    "value":"RE",
    "label":"country.re"
  },
  {
    "value":"RO",
    "label":"country.ro"
  },
  {
    "value":"RS",
    "label":"country.rs"
  },
  {
    "value":"RU",
    "label":"country.ru"
  },
  {
    "value":"RW",
    "label":"country.rw"
  },
  {
    "value":"SA",
    "label":"country.sa"
  },
  {
    "value":"SB",
    "label":"country.sb"
  },
  {
    "value":"SC",
    "label":"country.sc"
  },
  {
    "value":"SD",
    "label":"country.sd"
  },
  {
    "value":"SE",
    "label":"country.se"
  },
  {
    "value":"SG",
    "label":"country.sg"
  },
  {
    "value":"SH",
    "label":"country.sh"
  },
  {
    "value":"SI",
    "label":"country.si"
  },
  {
    "value":"SJ",
    "label":"country.sj"
  },
  {
    "value":"SK",
    "label":"country.sk"
  },
  {
    "value":"SL",
    "label":"country.sl"
  },
  {
    "value":"SM",
    "label":"country.sm"
  },
  {
    "value":"SN",
    "label":"country.sn"
  },
  {
    "value":"SO",
    "label":"country.so"
  },
  {
    "value":"SR",
    "label":"country.sr"
  },
  {
    "value":"SS",
    "label":"country.ss"
  },
  {
    "value":"ST",
    "label":"country.st"
  },
  {
    "value":"SV",
    "label":"country.sv"
  },
  {
    "value":"SX",
    "label":"country.sx"
  },
  {
    "value":"SY",
    "label":"country.sy"
  },
  {
    "value":"SZ",
    "label":"country.sz"
  },
  {
    "value":"TC",
    "label":"country.tc"
  },
  {
    "value":"TD",
    "label":"country.td"
  },
  {
    "value":"TF",
    "label":"country.tf"
  },
  {
    "value":"TG",
    "label":"country.tg"
  },
  {
    "value":"TH",
    "label":"country.th"
  },
  {
    "value":"TJ",
    "label":"country.tj"
  },
  {
    "value":"TK",
    "label":"country.tk"
  },
  {
    "value":"TL",
    "label":"country.tl"
  },
  {
    "value":"TM",
    "label":"country.tm"
  },
  {
    "value":"TN",
    "label":"country.tn"
  },
  {
    "value":"TO",
    "label":"country.to"
  },
  {
    "value":"TR",
    "label":"country.tr"
  },
  {
    "value":"TT",
    "label":"country.tt"
  },
  {
    "value":"TV",
    "label":"country.tv"
  },
  {
    "value":"TW",
    "label":"country.tw"
  },
  {
    "value":"TZ",
    "label":"country.tz"
  },
  {
    "value":"UA",
    "label":"country.ua"
  },
  {
    "value":"UG",
    "label":"country.ug"
  },
  {
    "value":"UM",
    "label":"country.um"
  },
  {
    "value":"US",
    "label":"country.us"
  },
  {
    "value":"UY",
    "label":"country.uy"
  },
  {
    "value":"UZ",
    "label":"country.uz"
  },
  {
    "value":"VA",
    "label":"country.va"
  },
  {
    "value":"VC",
    "label":"country.vc"
  },
  {
    "value":"VE",
    "label":"country.ve"
  },
  {
    "value":"VG",
    "label":"country.vg"
  },
  {
    "value":"VI",
    "label":"country.vi"
  },
  {
    "value":"VN",
    "label":"country.vn"
  },
  {
    "value":"VU",
    "label":"country.vu"
  },
  {
    "value":"WF",
    "label":"country.wf"
  },
  {
    "value":"WS",
    "label":"country.ws"
  },
  {
    "value":"YE",
    "label":"country.ye"
  },
  {
    "value":"YT",
    "label":"country.yt"
  },
  {
    "value":"ZA",
    "label":"country.za"
  },
  {
    "value":"ZM",
    "label":"country.zm"
  },
  {
    "value":"ZW",
    "label":"country.zw"
  }
];
