import React, {Component} from 'react';
import './DatePicker.css';
import {injectIntl} from 'react-intl';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {createStaticRanges, DateRangePicker} from "react-date-range";
import * as rdrLocales from 'react-date-range/src/locale';
import moment from "moment";
import NavigationDropDownIcon from "../NavigationDropDownIcon/NavigationDropDownIcon";
import messages from '../../types/constantsMessages';

/* For more information on DatePicker go here: https://github.com/Adphorus/react-date-range */

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.state = {
      'listVisible': false,
      'selectedValue': props.selectedValue,
      dateRange: {
        selection: {
          startDate: moment(props.currentValues["DATE RANGE"].split(":")[0]).toDate(),
          endDate: moment(props.currentValues["DATE RANGE"].split(":")[1]).toDate(),
          key: 'selection',
        },
      },
      focusedRange: [0, 0]
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.openClass = this.openClass.bind(this);
    this.updateAnalyticsRange = this.updateAnalyticsRange.bind(this);
  }

  openClass() {
    return this.state.listVisible ? 'open' : '';
  }

  dropDownClassName() {
    const visibleStatus = this.state.listVisible ? 'show' : '';
    const halfWidth = this.props.halfWidth ? 'half-width-dropdown' : '';
    return `dropdown ${this.props.classExtensions} ${visibleStatus} ${halfWidth}`
  }

  show() {
    this.setState({listVisible: true});
    document.addEventListener("click", this.hide);
  }

  componentWillUnmount() {
    this.setState({listVisible: false});
    document.removeEventListener("click", this.hide);
  }

  hide(e) {
    console.log(e.target);
    if (e.target.classList.contains("rdrStaticRange") || e.target.classList.contains("rdrStaticRangeLabel")) {
      this.updateAnalyticsRange(this.state.dateRange.selection);
    }
    if (!e.target.classList.contains("rdrInputRangeInput") &&
      !e.target.classList.contains("rdrInputRange") &&
      !e.target.classList.contains("rdrNextPrevButton") &&
      !e.target.classList.contains("rdrMonthAndYearWrapper") &&
      !e.target.classList.contains("rdrDays") &&
      !e.target.classList.contains("rdrDay") &&
      !e.target.classList.contains("rdrDayNumber") &&
      !e.target.parentNode.classList.contains("rdrDateDisplayItem") &&
      "span" === !e.target.tagName
    ) {
      this.setState({listVisible: false});
      document.removeEventListener("click", this.hide);
    }
  }

  updateAnalyticsRange(selection) {
    let startDate = moment(selection.startDate).format("YYYY-MM-DD");
    let endDate = moment(selection.endDate).format("YYYY-MM-DD");

    this.props.handleItemClick(
      `${startDate}:${endDate}`
    );
  }

  handleRangeChange(which, payload) {
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
    if (this.state.focusedRange[1] === 1) {
      this.updateAnalyticsRange(payload.selection);
    }
  }


  renderDateRangePicker() {

    let predefinedDateRanges = createStaticRanges([
      {
        label: this.intl.formatMessage(messages["date_picker.this_week"]),  // 'This Week',
        range: () => ({
          startDate: moment().startOf('week'),
          endDate: moment().endOf('week'),
        }),
      },
      {
        label: this.intl.formatMessage(messages["date_picker.last_week"]),  // 'Last Week',
        range: () => ({
          startDate: moment().subtract(1, 'week').startOf('week'),
          endDate: moment().subtract(1, 'week').endOf('week'),
        }),
      },
      {
        label: this.intl.formatMessage(messages["date_picker.this_month"]),  // 'This Month',
        range: () => ({
          startDate: moment().startOf('month'),
          endDate: moment().endOf('month'),
        }),
      },
      {
        label: this.intl.formatMessage(messages["date_picker.last_month"]),  // 'Last Month',
        range: () => ({
          startDate: moment().subtract(1, 'month').startOf('month'),
          endDate: moment().subtract(1, 'month').endOf('month'),
        }),
      },
      {
        label: this.intl.formatMessage(messages["date_picker.this_year"]),  // 'This Year',
        range: () => ({
          startDate: moment().startOf('year'),
          endDate: moment().endOf('year'),
        }),
      },
      {
        label: this.intl.formatMessage(messages["date_picker.last_year"]),  // 'Last Year',
        range: () => ({
          startDate: moment().subtract(1, 'year').startOf('year'),
          endDate: moment().subtract(1, 'year').endOf('year'),
        }),
      },
      {
        label: this.intl.formatMessage(messages["date_picker.all_time"]),  // 'All',
        range: () => ({
          startDate: moment().subtract(100, 'yearS').startOf('year'),
          endDate: moment(),
        }),
      },
    ]);
    return (
      <DateRangePicker
        className="dropdown-list"
        locale={rdrLocales[this.intl.locale]}
        ranges={[this.state.dateRange.selection]}
        maxDate={moment().toDate()}
        shownDate={moment().toDate()}
        onChange={this.handleRangeChange.bind(this, 'dateRange')}
        showMonthAndYearPickers={false}
        dragSelectionEnabled={false}
        showPreview={false}
        staticRanges={predefinedDateRanges}
        inputRanges={[]}
        focusedRange={this.state.focusedRange}
        onRangeFocusChange={focusedRange => {
          this.setState({focusedRange});
        }}
      />
    )
  }


  render() {

    let range = this.props.currentValues["DATE RANGE"].split(":");
    return (
      <div className={this.dropDownClassName()}>

        <button
          type="button"
          className="dropdown-trigger"
          onClick={this.show}
        >
          <span className="dropdown-trigger-text">
            {this.intl.formatMessage(messages["date_picker.date_range"])}: {range[0]} &#8594; {range[1]}
          </span>
          <NavigationDropDownIcon
            openClass={this.openClass}
          />
        </button>
        {this.renderDateRangePicker()}
      </div>
    )
  }
}

export default injectIntl(DatePicker);
