import React, {Component} from 'react';
import './Pagination.css';
import {generatePaginationLinks} from '../../utils/paginationHelper';
import {REQUEST_SESSIONS_ENDPOINT} from '../../types/urls';
import {SESSIONS_PAGE_SIZE} from '../../types/sessions'
import {injectIntl} from 'react-intl';
import messages from './messages';

class Pagination extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'next': intl.formatMessage(messages.next),
      'page': intl.formatMessage(messages.page),
      'previous': intl.formatMessage(messages.previous),
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(pageNumber, e) {
    const {currentPage} = this.props.data;

    e.preventDefault();

    if (currentPage !== pageNumber) {
      const {updatePage} = this.props;
      updatePage('page', pageNumber)
    }
  }


  renderPagination() {
    const {count} = this.props.data;
    if (count && count > SESSIONS_PAGE_SIZE) {
      const {currentPage, nextPage, previousPage} = this.props.data;
      const pageNumbers = generatePaginationLinks(REQUEST_SESSIONS_ENDPOINT, count, currentPage, SESSIONS_PAGE_SIZE);

      return (
        <div className="Pagination">
          {previousPage && this.renderPaginationLink(previousPage, this.page.previous)}
          {previousPage && this.renderPaginationLink(1, `${this.page.page} 1`)}
          {pageNumbers.map((pageNumber) => {
            return this.renderPaginationLink(pageNumber)
          })}
          {nextPage && this.renderPaginationLink(nextPage, this.page.next)}
        </div>
      )
    } else {
      return <div className="Pagination"/>
    }
  }

  pageLinkClassName(pageNumber) {
    const {currentPage} = this.props.data;
    const currentClass = currentPage === pageNumber ? 'current' : '';
    return `pagination-link ${currentClass}`;
  }

  renderPaginationLink(pageNumber, displayedText = null) {
    return (
      <a
        className={this.pageLinkClassName(pageNumber)}
        onClick={(e) => this.handleClick(pageNumber, e)}
        key={`pagination-link-${pageNumber}-${displayedText}`}
      >
        {displayedText || pageNumber}
      </a>
    );
  }

  render() {
    const {isDataFetched} = this.props;

    return (
      isDataFetched && this.renderPagination()
    )
  }
}

export default injectIntl(Pagination);
