import React, {Component} from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import '../../assets/stylesheets/base/ControlGroups.css';
import {ALL} from '../../types/searchFilters';

export default class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.deselectAll = this.deselectAll.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.initializeCheckboxes = this.initializeCheckboxes.bind(this);
    this.toggleCheckedState = this.toggleCheckedState.bind(this);
    this.setAllCheckboxStates = this.setAllCheckboxStates.bind(this);
  }

  componentWillMount() {
    this.initializeCheckboxes();
  }

  isChecked(checkboxValue) {
    const {checkboxes} = this.state;

    return checkboxes.find(checkbox => {
      const {checked, value} = checkbox;

      return checkboxValue === value && checked;
    });
  }

  handleSelectAll() {
    const {name, onChange} = this.props;

    if (this.isChecked(ALL)) {
      this.deselectAll();
    } else {
      onChange(name, [ALL]);
    }
  }

  toggleCheckedState(checkboxValue) {
    const {name, onChange} = this.props;

    if (checkboxValue === ALL) {
      this.handleSelectAll();
    } else if (this.isChecked(ALL)) {
      onChange(name, [checkboxValue]);
    } else {
      const {checkboxes} = this.state;
      const values = checkboxes.reduce((checkboxValues, checkbox) => {
        const {checked, value} = checkbox;

        if (value === checkboxValue && !checked) {
          return checkboxValues.concat(value);
        } else if (value !== checkboxValue && checked) {
          return checkboxValues.concat(value);
        }
        return checkboxValues;
      }, []);

      onChange(name, values);
    }
  }

  initializeCheckboxes() {
    const {checkboxLabels} = this.props;

    const checkboxes = checkboxLabels.map(checkboxLabel => {
      const {element, value} = checkboxLabel;

      return {
        value,
        labelText: element,
        checked: this.isCheckedByProp(value)
      }
    });

    this.setState({
      checkboxes
    });
  }

  deselectAll() {
    this.setAllCheckboxStates(false)
  }

  setAllCheckboxStates(checked) {
    const {checkboxLabels} = this.props;
    const checkboxes = checkboxLabels.map(checkboxLabel => {
      const {element, value} = checkboxLabel;
      return {labelText: element, value, checked}
    });

    this.setState({
      checkboxes
    });
  }

  isCheckedByProp(value) {
    const {activeCheckboxValues} = this.props;
    const stringValues = activeCheckboxValues.map(value => {
      return value.toString();
    });

    return stringValues.includes(value.toString());
  }

  renderCheckboxes() {
    const {name} = this.props;
    const {checkboxes} = this.state;
    return checkboxes.map((checkbox, index) => {
      const {labelText, checked, value} = checkbox;
      return (
        <Checkbox
          checked={checked}
          key={index}
          labelText={labelText}
          name={name}
          onChange={this.toggleCheckedState}
          value={value}
        />
      )
    });
  }

  render() {
    const {heading} = this.props;
    return (
      <div className="CheckBoxGroup">
        <div className="control-group">
          <div className="filter-heading">
            {heading}
          </div>
          {this.renderCheckboxes()}
        </div>
      </div>
    )
  }
}
