import {
    DESCENDING,
    FILTER_VALUES_MAP,
    ORDER,
    SEARCH_PARAM_KEY_MAP,
} from '../types/searchFilters';

import { DATE_RANGE } from '../types/constants';
import { parseDateRange } from './dateHelper';
import * as urls from '../types/urls';

export function apiUrl(endpoint) {
    return `${process.env.REACT_APP_RETAILER_API_HOST}/${endpoint}`;
}

export function sizingUrl(endpoint) {
    return `${process.env.REACT_APP_SIZING_API_HOST}/${endpoint}`;
}

export function passportUrl(endpoint) {
    return `${process.env.REACT_APP_PASSPORT_HOST}/${endpoint}`;
}

export function combineUrl(currentUrl, paramObject) {
    const searchKey = Object.keys(paramObject)[0];
    const searchValue = paramObject[searchKey];
    return currentUrl + searchKey + '=' + searchValue;
}

export function buildUrl(url, searchParams) {
    if (!searchParams) {
        return url;
    }

    const formattedSearchParams = formatSearchParams(searchParams);
    const baseUrl = `${url}?`;
    const urlWithSearchParams = formattedSearchParams.reduce(
        (currentUrl, paramObject, index) => {
            if (index === 0) {
                return combineUrl(currentUrl, paramObject);
            } else {
                return combineUrl(`${currentUrl}&`, paramObject);
            }
        },
        baseUrl
    );

    return encodeURI(urlWithSearchParams);
}

export function updateFilters(searchParams) {
    return Object.keys(searchParams).reduce((searchParamsObj, key) => {
        let updatedKey = key;

        SEARCH_PARAM_KEY_MAP.forEach((keyMapping) => {
            if (keyMapping.key === key) {
                updatedKey = keyMapping.searchParamKey;
            }
        });

        searchParamsObj[updatedKey] = searchParams[key];

        return searchParamsObj;
    }, {});
}

export function updateValues(searchParams) {
    return Object.keys(searchParams).reduce((searchParamsObj, key) => {
        FILTER_VALUES_MAP.forEach((valueMapping) => {
            if (key === valueMapping.key) {
                const value = valueMapping.valueItems.find(
                    (valueItem) => valueItem.value === searchParams[key]
                );
                if (value && value.searchParamValue) {
                    searchParamsObj[key] = value.searchParamValue;
                }
            }
        });
        return searchParamsObj;
    }, {});
}

export function formatSearchParams(searchParams) {
    const unsanitizedSearchParams = updateFilters(searchParams);
    const sanitizedSearchParams = updateValues(unsanitizedSearchParams);
    const updatedSearchParams = {
        ...unsanitizedSearchParams,
        ...sanitizedSearchParams,
    };
    const searchFilterKeys = Object.keys(updatedSearchParams);

    return searchFilterKeys.reduce((paramsArray, key) => {
        const value = updatedSearchParams[key];
        const formattedValue =
            typeof value === 'string' ? value.toLowerCase() : value;

        if (key === DATE_RANGE) {
            return paramsArray.concat(parseDateRange(searchParams[DATE_RANGE]));
        } else if (key === ORDER) {
            return paramsArray.concat(filterOrder(updatedSearchParams[key]));
        } else if (formattedValue === 'all') {
            return paramsArray;
        } else if (Array.isArray(formattedValue)) {
            return formattedValue.find(
                (val) => `${val}`.toLowerCase() === 'all'
            )
                ? paramsArray
                : paramsArray.concat(
                      filterKeyWithDuplicates(updatedSearchParams, key)
                  );
        } else {
            const formattedKey = key.toLowerCase();

            return paramsArray.concat({ [formattedKey]: formattedValue });
        }
    }, []);
}

function filterOrder(order) {
    const { attribute, sort } = order;
    const formattedAttribute = attribute.toLowerCase();
    if (sort === DESCENDING) {
        return { order: `-${formattedAttribute}` };
    } else {
        return { order: `${formattedAttribute}` };
    }
}

function filterKeyWithDuplicates(updatedSearchParams, key) {
    return updatedSearchParams[key].map((value) => {
        const formattedValue =
            typeof value === 'string' ? value.toLowerCase() : value;
        return { [key]: formattedValue };
    });
}

export function bikeTypeUrl() {
    return `${urls.BIKE_TYPES_ENDPOINT}`;
}

export function bikeFamilyUrl(type) {
    return `${urls.BIKE_FAMILIES_ENDPOINT}${type}`;
}

export function bikeModelsUrl(model_id) {
    return `${urls.BIKE_MODELS_ENDPOINT}${model_id}/`;
}

export function bikeSizeUrl(family) {
    return `${urls.BIKE_SIZES_ENDPOINT}${family}`;
}

export function bikeTransactionsUrl(id) {
    console.log(
        `${urls.BIKE_TRANSACTIONS_ENDPOINT}${id ? '?session=' + id : ''}`
    );
    return `${urls.BIKE_TRANSACTIONS_ENDPOINT}${id ? '?session=' + id : ''}`;
}

export function saddleTypeUrl() {
    return `${urls.SADDLE_TYPES_ENDPOINT}`;
}

export function saddleFamilyUrl(type) {
    return `${urls.SADDLE_FAMILIES_ENDPOINT}${type}`;
}

export function saddleModelUrl(family) {
    return `${urls.SADDLE_MODELS_ENDPOINT}${family}`;
}

export function saddleSizeUrl(model) {
    return `${urls.SADDLE_SIZES_ENDPOINT}${model}`;
}

export function saddleTransactionsUrl(id) {
    return `${urls.SADDLE_TRANSACTIONS_ENDPOINT}${id ? '?session=' + id : ''}`;
}

export function shoeTypeUrl() {
    return `${urls.SHOE_TYPES_ENDPOINT}`;
}

export function shoeFamilyUrl(type) {
    return `${urls.SHOE_FAMILIES_ENDPOINT}${type}`;
}

export function shoeModelUrl(family) {
    return `${urls.SHOE_MODELS_ENDPOINT}${family}`;
}

export function shoeSizeUrl(model) {
    return `${urls.SHOE_SIZES_ENDPOINT}${model}`;
}

export function shoeTransactionsUrl(id) {
    return `${urls.SHOE_TRANSACTIONS_ENDPOINT}${id ? '?session=' + id : ''}`;
}

export function footbedModelsUrl() {
    return `${urls.FOOTBED_MODELS_ENDPOINT}`;
}

export function footbedTransactionsUrl(id) {
    return `${urls.FOOTBED_TRANSACTIONS_ENDPOINT}${id ? '?session=' + id : ''}`;
}

export function deleteBikeTransactionsUrl(id) {
    return `${urls.BIKE_TRANSACTIONS_ENDPOINT}${id ? id + '/' : ''}`;
}

export function deleteSaddleTransactionsUrl(id) {
    return `${urls.SADDLE_TRANSACTIONS_ENDPOINT}${id ? id + '/' : ''}`;
}

export function deleteShoeTransactionsUrl(id) {
    return `${urls.SHOE_TRANSACTIONS_ENDPOINT}${id ? id + '/' : ''}`;
}

export function deleteFootbedTransactionsUrl(id) {
    return `${urls.FOOTBED_TRANSACTIONS_ENDPOINT}${id ? id + '/' : ''}`;
}

export function locationUrl(locationId) {
    return `${urls.LOCATIONS_ENDPOINT}${locationId}/`;
}

export function locationsUrl() {
    return `${urls.LOCATIONS_ENDPOINT}`;
}

export function employeeUrl(employeeId) {
    return `${urls.EMPLOYEES_ENDPOINT}${employeeId}/`;
}

export function employeesUrl() {
    return `${urls.EMPLOYEES_ENDPOINT}`;
}

export function createTokensUrl() {
    return `${urls.REQUEST_JWT_ENDPOINT}`;
}

export function fetchSessionsUrl(searchParams) {
    return buildUrl(urls.REQUEST_SESSIONS_ENDPOINT, searchParams);
}

export function fetchSessionUrl(sessionId) {
    return `${urls.REQUEST_SESSIONS_ENDPOINT}${sessionId}/`;
}

export function fetchSessionProductUrl(sessionId, bikeType) {
    return `${urls.REQUEST_SESSION_PRODUCT_ENDPOINT}${sessionId}/${
        bikeType ? `?category=${bikeType}` : ''
    }`;
}

export function fetchClientProductUrl(clientID, bikeType) {
    return `${urls.REQUEST_CLIENT_PRODUCT_ENDPOINT}${clientID}/${
        bikeType ? `?category=${bikeType}` : ''
    }`;
}

export function fetchCurrentUserUrl() {
    return `${urls.REQUEST_CURRENT_USER_ENDPOINT}`;
}

export function fetchNewAccountUrl() {
    return `${urls.REQUEST_NEW_ACCOUNT_ENDPOINT}`;
}

export function createAccountUrl() {
    return `${urls.REGISTER_USER}`;
}

export function fetchSessionsAnalyticsUrl(searchParams) {
    return buildUrl(urls.REQUEST_SESSIONS_ANALYTICS_ENDPOINT, searchParams);
}

export function fetchProductsAnalyticsUrl(searchParams) {
    return buildUrl(urls.REQUEST_PRODUCTS_ANALYTICS_ENDPOINT, searchParams);
}

export function fetchSessionsCSVUrl() {
    return urls.REQUEST_SESSIONS_EXPORT_CSV_ENDPOINT;
}

export function fetchClientUrl(id) {
    return `${urls.REQUEST_CLIENT_ENDPOINT}${id}/`;
}

export function createPasswordResetUrl() {
    return `${urls.CREATE_PASSWORD_RESET_ENDPOINT}`;
}

export function createVerifyUrl() {
    return `${urls.VERIFY_USER}`;
}

export function confirmPasswordResetUrl() {
    return `${urls.CONFIRM_PASSWORD_RESET}`;
}
