export function downloadSessionsCSV(data) {
  const fileName = data.file_name;
  const href = data.csv_location;
  const link = document.createElement('a');

  link.setAttribute('href', href);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
