import React from 'react';

export default function (props) {
  const {closeModal} = props;
  return (
    <button
      className="modal-close"
      href='/'
      onClick={closeModal}
    >
      <svg className="icon" viewBox="0.604 0.604 17.812 17.812" aria-labelledby="icon-x">
        <title id="icon-x">Close</title>
        <g transform="translate(1.000000, 1.000000)">
          <path fill="none" strokeWidth="1.5" d="M0.135,16.887L16.887,0.135"></path>
          <path fill="none" strokeWidth="1.5" d="M0.135,0.135l16.752,16.752"></path>
        </g>
      </svg>
    </button>
  )
}
