import React, {Component} from 'react';
import {connect} from "react-redux";
import AnalyticsSearchFiltersGroup from '../components/AnalyticsSearchFiltersGroup/AnalyticsSearchFiltersGroup'

export class AnalyticsSearchFiltersGroupContainer extends Component {
  render() {
    const {searchFilters, updateAnalyticsFilters} = this.props;

    return (
      <AnalyticsSearchFiltersGroup
        updateSearchFilters={updateAnalyticsFilters}
        searchFilters={searchFilters}
      />
    )
  }
}

function mapStateToProps(state) {
  return state.analytics;
}

export default connect(mapStateToProps)(AnalyticsSearchFiltersGroupContainer);
