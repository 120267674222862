import React, {Component} from 'react';
import InformationToolTip from '../../components/InformationToolTip/InformationToolTip';
import './Metric.css';

export default class Metric extends Component {
  renderMetricType() {
    const {data} = this.props;
    const metricType = data.item.split(' ')[0];

    return (
      <h2 className="metric-type">
        {metricType}
      </h2>
    )
  }

  renderTooltip(name) {
    const {tooltip} = this.props;

    return !tooltip ? '' : (
      <InformationToolTip
        data={tooltip}
        name={name}
        bottomLeft
      />
    );
  }

  renderMetricName(metricType) {
    return (
      <h2 className="metric-name">
        <span>{metricType}</span>
      </h2>
    )
  }

  renderMetricQuantity() {
    const {data} = this.props;
    return (
      <span className="metric-quantity">
        {data.quantity.toLocaleString()}
      </span>
    )
  }


  render() {
    return (
      <div className="metric">
        {this.renderTooltip(this.metricType)}
        <div>
          {this.renderMetricType()}
          {this.renderMetricName(this.metricType)}
        </div>
        {this.renderMetricQuantity()}
        {this.props.children}
      </div>
    )
  }

  get metricType() {
    const {data} = this.props;
    return data.item
      .split(' ')
      .slice(1)
      .reduce((prev, next) => `${prev} ${next}`);
  }
}
