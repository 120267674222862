import { defineMessages } from 'react-intl';

export default defineMessages({
  'all_sessions': {
    'id': 'client.all_sessions_link',
    'defaultMessage': 'All Sessions',
    'description': 'Link back to the All Sessions page'
  },
  'client_name': {
    'id': 'client.name',
    'defaultMessage': 'Client Name',
    'description': 'Label to display Client Name'
  },
  'client_email': {
    'id': 'client.email',
    'defaultMessage': 'Client Email',
    'description': 'Label to display Client Email'
  },
  'not_applicable': {
    'id': 'client.not_applicable',
    'defaultMessage': 'N/A',
    'description': 'Label form Not Applicable (N/A)'
  },
  'notes_label': {
    'id': 'client.notes_label',
    'defaultMessage': 'Notes',
    'description': 'Label for Notes field'
  },
  'save_cta': {
    'id': 'client.save_cta',
    'defaultMessage': 'Save',
    'description': 'Save field CTA'
  },
  'client_sessions': {
    'id': 'client.client_sessions_heading',
    'defaultMessage': 'Latest Client Session',
    'description': 'Heading for the Client Sessions module'
  },
  'client_progress': {
    'id': 'client.client_progress_heading',
    'defaultMessage': 'Client Progress',
    'description': 'Heading for the Client Progress module'
  },
  'types_complete': {
    'id': 'client.progress_types_complete',
    'defaultMessage': 'Complete',
    'description': 'Describes how many types have been completed'
  },
  'bike': {
    'id': 'client.bike_progress',
    'defaultMessage': 'Bike',
    'description': 'The Bike step in client progress'
  },
  'saddle': {
    'id': 'client.saddle_progress',
    'defaultMessage': 'Saddle',
    'description': 'The Saddle step in client progress'
  },
  'shoe': {
    'id': 'client.shoe_progress',
    'defaultMessage': 'Shoe',
    'description': 'The Shoe step in client progress'
  },
});
