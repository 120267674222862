import {RECEIVED, REQUESTING} from '../types/statuses';
import {
  RECEIVE_BIKE_TRANSACTIONS, RECEIVE_FOOTBED_TRANSACTIONS,
  RECEIVE_SADDLE_TRANSACTIONS,
  RECEIVE_SESSION_DATA,
  RECEIVE_SHOE_TRANSACTIONS,
  REQUEST_BIKE_TRANSACTIONS, REQUEST_FOOTBED_TRANSACTIONS,
  REQUEST_SADDLE_TRANSACTIONS,
  REQUEST_SESSION_DATA,
  REQUEST_SHOE_TRANSACTIONS,
} from '../types/actions/sessions';
import {getSession} from '../selectors/sessions';

export const initialState = {
  sessionDetailsData: {
    data: null,
    fetchingStatus: null,
  },
  bikeTransactionData: {
    data: null,
    fetchingStatus: null,
  },
  saddleTransactionData: {
    data: null,
    fetchingStatus: null,
  },
  shoeTransactionData: {
    data: null,
    fetchingStatus: null,
  },
  footbedTransactionData: {
    data: null,
    fetchingStatus: null,
  },
  sessionProductData: {
    data: null,
    fetchingStatus: null,
  },
  productInfo: {
    data: null,
    fetchingStatus: null
  }
};

//SESSION DETAILS
function receiveSessionData(state, action) {
  const normalizedDate = getSession(action.data);
  const sessionDetailsData = {...state.sessionDetailsData, fetchingStatus: RECEIVED, data: normalizedDate};

  return {...state, sessionDetailsData}
}

function requestSessionData(state) {
  const sessionDetailsData = {...state.sessionDetailsData, fetchingStatus: REQUESTING};

  return {...state, sessionDetailsData}
}

//PRODUCT TRANSACTION DETAILS
function requestBikeTransactions(state) {
  const bikeTransactionData = {...state.bikeTransactionData, fetchingStatus: REQUESTING};

  return {...state, bikeTransactionData}
}

function receiveBikeTransactions(state, action) {
  const data = action.data.map(data => Object.assign({product: 'Bike', ...data}));
  const bikeTransactionData = {...state.bikeTransactionData, fetchingStatus: RECEIVED, data: data};

  return {...state, bikeTransactionData}
}

function requestSaddleTransactions(state) {
  const saddleTransactionData = {...state.saddleTransactionData, fetchingStatus: REQUESTING};

  return {...state, saddleTransactionData}
}

function receiveSaddleTransactions(state, action) {
  const data = action.data.map(data => Object.assign({product: 'Saddle', ...data}));
  const saddleTransactionData = {...state.saddleTransactionData, fetchingStatus: RECEIVED, data: data};

  return {...state, saddleTransactionData}
}

function requestShoeTransactions(state) {
  const shoeTransactionData = {...state.shoeTransactionData, fetchingStatus: REQUESTING};

  return {...state, shoeTransactionData}
}

function receiveShoeTransactions(state, action) {
  const data = action.data.map(data => Object.assign({product: 'Shoe', ...data}));
  const shoeTransactionData = {...state.shoeTransactionData, fetchingStatus: RECEIVED, data: data};

  return {...state, shoeTransactionData}
}

function requestFootbedTransactions(state) {
  const footbedTransactionData = {...state.footbedTransactionData, fetchingStatus: REQUESTING};

  return {...state, footbedTransactionData}
}

function receiveFootbedTransactions(state, action) {
  const data = action.data.map(data => Object.assign({product: 'Shoe', ...data}));
  const footbedTransactionData = {...state.footbedTransactionData, fetchingStatus: RECEIVED, data: data};

  return {...state, footbedTransactionData}
}


export default function sessionDetails(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SESSION_DATA:
      return receiveSessionData(state, action);
    case REQUEST_SESSION_DATA:
      return requestSessionData(state);
    case RECEIVE_BIKE_TRANSACTIONS:
      return receiveBikeTransactions(state, action);
    case REQUEST_BIKE_TRANSACTIONS:
      return requestBikeTransactions(state);
    case RECEIVE_SADDLE_TRANSACTIONS:
      return receiveSaddleTransactions(state, action);
    case REQUEST_SADDLE_TRANSACTIONS:
      return requestSaddleTransactions(state);
    case RECEIVE_SHOE_TRANSACTIONS:
      return receiveShoeTransactions(state, action);
    case REQUEST_SHOE_TRANSACTIONS:
      return requestShoeTransactions(state);
    case RECEIVE_FOOTBED_TRANSACTIONS:
      return receiveFootbedTransactions(state, action);
    case REQUEST_FOOTBED_TRANSACTIONS:
      return requestFootbedTransactions(state);
    default:
      return state
  }
}
