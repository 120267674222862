import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/clientProducts';
import * as clientSelectors from '../selectors/client';
import {RECEIVED} from '../types/statuses';
import ClientProducts from "../components/ClientProducts/ClientProducts";


export class ClientProductContainer extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;

    this.state = {
      'bikeType': props.bikeType
    };
  }

  componentWillReceiveProps(nextProps) {
    const {bikeType} = nextProps;

    if (this.bikeTypeChanged(bikeType)) {
      this.setState({
        'bikeType': bikeType
      }, () => {
        this.getNewProduct();
      })
    }
  }

  getNewProduct() {
    const {fetchClientProduct, bikeType} = this.props;
    fetchClientProduct(this.clientId, bikeType);
  }

  bikeTypeChanged(bikeType) {
    return bikeType !== this.props.bikeType;
  }

  componentWillMount() {
    this.fetchClientProduct();
    this.fetchBikeTypes();
  }

  fetchClientProduct() {
    const {fetchClientProduct} = this.props;
    fetchClientProduct(this.clientId, this.props.bikeType);
  }

  fetchBikeTypes() {
    const {fetchBikeTypes} = this.props;
    fetchBikeTypes();
  }

  renderClientProducts() {
    return !this.dataReceived ? null : (
      <ClientProducts
        {...this.props.clientProductData.data}
        bikeTypeData={this.props.bikeTypeData.data}
        bikeType={this.props.bikeType}
        updateBikeType={this.props.updateBikeType}
      />
    )
  }

  render() {
    return (
      this.renderClientProducts()
    )
  }

  get dataReceived() {
    return (
      clientSelectors.getClientProductFetchingStatus(this.props) === RECEIVED &&
      clientSelectors.getBikeTypeFetchingStatus(this.props) === RECEIVED
    )
  }

  get clientId() {
    return this.props.clientId;
  }
}

function mapStateToProps(state) {
  return state.clientProducts
}

export default connect(mapStateToProps, actionCreators)(ClientProductContainer);
