import { defineMessages } from 'react-intl';

export default defineMessages({
  'saddle': {
    'id': 'charts.saddle_label_products',
    'defaultMessage': 'Saddle',
    'description': 'Lable for the Saddle charts'
  },
  'saddle_size': {
    'id': 'charts.sessions_by_saddle_size_label',
    'defaultMessage': 'Sessions By Saddle Size',
    'description': 'Lable for the Sessions By Saddle Size chart'
  },
  'sitbone_width': {
    'id': 'charts.sessions_by_sitbone_width_label',
    'defaultMessage': 'Sessions By Sitbone Width',
    'description': 'Lable for the Sessions By Sitbone Width chart'
  },
  'bike_type': {
    'id': 'charts.sessions_by_bike_type_label',
    'defaultMessage': 'Sessions By Bike Type',
    'description': 'Lable for the Sessions By Bike Type chart'
  },
  'bike': {
    'id': 'charts.bike_label_products',
    'defaultMessage': 'Bike',
    'description': 'Lable for the Bike charts'
  },
  'bike_size_standard': {
    'id': 'charts.sessions_by_standard_bike_size_label',
    'defaultMessage': 'Sessions By Bike Size (Standard)',
    'description': 'Lable for Sessions By Bike Size (Standard)'
  },
  'bike_size_shirt': {
    'id': 'charts.sessions_by_shirt_bike_size_label',
    'defaultMessage': 'Sessions By Bike Size (T-Shirt)',
    'description': 'Lable for Sessions By Bike Size (T-Shirt)'
  },
  'saddle_height': {
    'id': 'charts.sessions_by_saddle_height_label',
    'defaultMessage': 'Sessions By Saddle Height',
    'description': 'Lable for Sessions By Saddle height'
  },
  'shoe': {
    'id': 'charts.shoe_label_products',
    'defaultMessage': 'Shoe',
    'description': 'Lable for the Shoe charts'
  },
  'foot_arch': {
    'id': 'charts.sessions_by_foot_arch_label',
    'defaultMessage': 'Sessions By Foot Arch',
    'description': 'Lable for Sessions By Foot Arch'
  },
  'shoe_size': {
    'id': 'charts.sessions_by_shoe_size_label',
    'defaultMessage': 'Sessions By Shoe Size',
    'description': 'Lable for Sessions By Shoe Size'
  },
  'commute-comfort': {
    'id': 'charts.commute_comfort_label',
    'defaultMessage': 'commute-comfort',
    'description': 'Lable for Commute Comfort bike type'
  },
  'commute-sport': {
    'id': 'charts.commute_sport_label',
    'defaultMessage': 'commute-sport',
    'description': 'Lable for Commute Sport bike type'
  },
  'cyclocross': {
    'id': 'charts.cyclocross_label',
    'defaultMessage': 'cyclocross',
    'description': 'Lable for Cyclocross bike type'
  },
  'mtb-trail': {
    'id': 'charts.mtb_trail_label',
    'defaultMessage': 'mtb-trail',
    'description': 'Lable for MTB Trail bike type'
  },
  'mtb-xc': {
    'id': 'charts.mtb_xc_label',
    'defaultMessage': 'mtb-xc',
    'description': 'Lable for MTB XC bike type'
  },
  'road': {
    'id': 'charts.road_label',
    'defaultMessage': 'road',
    'description': 'Lable for Road bike type'
  },
  'triathlon': {
    'id': 'charts.triathlon_label',
    'defaultMessage': 'triathlon',
    'description': 'Lable for Triathlon bike type'
  },
  'low': {
    'id': 'charts.low_arch_label',
    'defaultMessage': 'low',
    'description': 'Lable for low foot arch types'
  },
  'medium': {
    'id': 'charts.medium_arch_label',
    'defaultMessage': 'medium',
    'description': 'Lable for medium foot arch types'
  },
  'high': {
    'id': 'charts.high_arch_label',
    'defaultMessage': 'high',
    'description': 'Lable for high foot arch types'
  },
});
