import * as actionTypes from '../types/actions/sessions';
import { apiGet } from '../api/apiClient';
import { JSON_PARSER } from '../types/constants';
import { generateRequestConfigs } from '../utils/fetchHelper';
import {
    bikeTypeUrl,
    fetchClientProductUrl,
    fetchClientUrl,
} from '../utils/urlHelper';
import { receiveBikeTypes, requestBikeTypes } from './transactions';
import { receiveClient, requestClient } from './clientProfile';

export function fetchClient(id) {
    return (dispatch) => {
        dispatch(requestClient());

        return apiGet(
            generateRequestConfigs({
                dispatch,
                parser: JSON_PARSER,
                url: fetchClientUrl(id),
                receiveCallback: receiveClient,
            })
        );
    };
}

export function fetchClientProduct(ClientID, bikeType = 'road') {
    return (dispatch) => {
        return apiGet(
            generateRequestConfigs({
                dispatch,
                parser: JSON_PARSER,
                url: fetchClientProductUrl(ClientID, bikeType),
                receiveCallback: receiveClientProduct,
            })
        );
    };
}

export function fetchBikeTypes() {
    return (dispatch) => {
        dispatch(requestBikeTypes());

        return apiGet(
            generateRequestConfigs({
                dispatch,
                parser: JSON_PARSER,
                receiveCallback: receiveBikeTypes,
                url: bikeTypeUrl(),
            })
        );
    };
}

export function receiveClientProduct(data) {
    return {
        type: actionTypes.RECEIVE_CLIENT_PRODUCT_DATA,
        data,
    };
}

export function updateBikeType(data) {
    return {
        type: actionTypes.UPDATE_BIKE_TYPE,
        data: data,
    };
}
