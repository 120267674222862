import React from 'react';
import {getDimensions} from '../utils/chartDimensions';
import ResponsiveDoughnutChart from '../components/Charts/ResponsiveDoughnutChart/ResponsiveDoughnutChart'
import ResponsiveBarChart from '../components/Charts/ResponsiveBarChart/ResponsiveBarChart'
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import VerticalBarChart from '../components/Charts/VerticalBarChart/VerticalBarChart';
import LargeVerticalBarChart from '../components/Charts/LargeVerticalBarChart/Chart/LargeVerticalBarChart';
import DonutChart from '../components/Charts/DonutChart/DonutChart';
import HorizontalBarChart from '../components/Charts/HorizontalBarChart/HorizontalBarChart';
import {donutColorValues} from '../types/colors';

export function generateTickValues(items, increment) {
  const max = Math.max(...items);
  const arraySize = Math.floor(max / increment + 1);
  const values = Array(Math.floor(arraySize)).fill(0);

  return values.map((v, i) => {
    return i * increment;
  });
}

export function setFill(colorRange, key) {
  const color = Object
    .entries(colorRange)
    .map((colorObject) => colorObject[ colorObject.length - 1 ])
    .find(color => color[ key ])[ key ];
  return { fill: color }
}

export function updatedWidthState(stateObject, newWidth) {
  return {
    dimensions:
      { ...getDimensions(stateObject), width: newWidth }
  }
}

export function updatedHeightState(stateObject, newWidth) {
  return {
    dimensions:
      { ...getDimensions(stateObject), width: newWidth }
  }
}

export function updatedDimensionsState(stateObject, newWidth, newHeight) {
  return {
    dimensions:
      { ...getDimensions(stateObject), width: newWidth, height: newHeight }
  }
}

export function renderLargeVerticalBarChart(labelText, data) {
  return renderBarChart(LargeVerticalBarChart, labelText, data, 'chart chart_full');
}

export function renderVerticalBarChart(labelText, data) {
  return renderBarChart(VerticalBarChart, labelText, data)
}

export function renderHorizontalBarChart(labelText, data, chartClass) {
  return renderBarChart(HorizontalBarChart, labelText, data, chartClass);
}

export function renderBarChart(Chart, labelText, data, chartClass) {
  Chart.displayName = formatDisplayName(labelText);
  const BarChart = ResponsiveBarChart(Chart, chartClass);
  return renderChart(<BarChart data={ data } />, labelText)
}

export function renderDonutChart(labelText, data) {
  DonutChart.displayName = formatDisplayName(labelText);
  const Chart = ResponsiveDoughnutChart(DonutChart);
  return renderChart(<Chart data={ data }/>, labelText);
}

function renderChartLabel(labelText) {
  return (
    <div className="chart-label">
      { labelText }
    </div>
  )
}

export function renderChart(ChartComponent, labelText) {
  const Chart = componentWithErrorBoundary(ChartComponent);

  return (
    <div className="chart-group">
      <div className="chart-container">
        { renderChartLabel(labelText) }
        { Chart }
      </div>
    </div>
  )
}

function formatDisplayName(text) {
  return `${text.replace(/\s+/g, '')} Chart`
}

export function generateColorData(data) {
  const colorValues = getColorRange(data.length);

  return data.map((d, i) => {
    return { [ d.item ]: colorValues[ i ] }
  })
}

export function getColorRange(dataSize) {
  return donutColorValues[ dataSize ];
}

export function renderDonutOrVerticalChart(labelText, data) {
  if (data.length < 10) {
    return renderDonutChart(labelText, data)
  } else {
    return renderVerticalBarChart(labelText, data)
  }
}

export function renderVerticalOrHorizontalBarChart(labelText, data) {
  if (data.length < 15) {
    return renderVerticalBarChart(labelText, data)
  } else {
    return renderHorizontalBarChart(labelText, data)
  }
}
