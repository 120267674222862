/* eslint-disable */
export const localeStrings = {
    'zh-cn': {
        'add_transaction.back_to_sessions_label': '返回到会话',
        'add_transaction.bike_label': '自行车',
        'add_transaction.commute_comfort_type': '通勤-舒适',
        'add_transaction.commute_sport_type': '通勤运动',
        'add_transaction.complete_transaction_cta_label': '完成交易',
        'add_transaction.confirm_label': '确认',
        'add_transaction.cyclocross_type': 'CX越野',
        'add_transaction.family_label': 'Family',
        'add_transaction.fitness_type': '健身',
        'add_transaction.footbed_label': '鞋垫',
        'add_transaction.heading': '将交易添加到{data}',
        'add_transaction.model_label': '车型',
        'add_transaction.mountain_type': '山地',
        'add_transaction.mtb_trail_type': '山地（林道）',
        'add_transaction.mtb_xc_type': '山地（XC越野）',
        'add_transaction.product_label': '产品',
        'add_transaction.ratings_message':
            '完成此交易后， {data}将收到一封电子邮件，对此产品进行评分。您将能够在案例列表和案例详细信息页面上看到此评分。',
        'add_transaction.road_type': '公路',
        'add_transaction.saddle_label': '座垫',
        'add_transaction.shoe_label': '骑行鞋',
        'add_transaction.size_label': '尺寸',
        'add_transaction.transaction_complete_message': '交易完成。',
        'add_transaction.triathlon_type': '铁人三项',
        'add_transaction.tt_tri_type': 'TT/铁三',
        'add_transaction.type_label': '类型',
        'analytics.loading_message':
            '我们正在整理您的数据, 这可能需要几分钟的时间',
        'analytics_filters.analytics_by': '分析依据',
        'analytics_filters.filter_by': '筛选条件',
        'analytics_filters.products': '产品',
        'analytics_filters.sessions': '案例',
        'charts.bike_label_products': '自行车',
        'charts.bike_label_sessions': '自行车',
        'charts.commute_comfort_label': '通勤-舒适',
        'charts.commute_sport_label': '通勤-运动',
        'charts.cyclocross_label': 'CX越野',
        'charts.female_label': '女',
        'charts.friday_label': '星期五',
        'charts.high_arch_label': '高',
        'charts.low_arch_label': '低',
        'charts.male_label': '男',
        'charts.medium_arch_label': 'Medium ',
        'charts.monday_label': '星期一',
        'charts.mtb_trail_label': '山地车-TRAIL',
        'charts.mtb_xc_label': '山地车-XC',
        'charts.road_label': '公路',
        'charts.saddle_label_products': '座垫',
        'charts.saddle_label_sessions': '座垫',
        'charts.saturday_label': '星期六',
        'charts.sessions_by_bike_type_label': '车型统计分析',
        'charts.sessions_by_day_of_week_label': '星期统计分析',
        'charts.sessions_by_employee_label': '员工统计分析',
        'charts.sessions_by_foot_arch_label': '足弓统计分析',
        'charts.sessions_by_gender_label': '性别统计分析',
        'charts.sessions_by_product_label': '产品分布分析',
        'charts.sessions_by_saddle_height_label': '座垫高度统计分析',
        'charts.sessions_by_saddle_size_label': '座垫尺寸统计分析',
        'charts.sessions_by_shirt_bike_size_label': '车辆尺寸（T恤）统计分析',
        'charts.sessions_by_shoe_size_label': '骑行鞋码统计分析',
        'charts.sessions_by_sitbone_width_label': '坐骨宽度统计分析',
        'charts.sessions_by_standard_bike_size_label': '车辆尺寸分析',
        'charts.sessions_over_time_label': '会话超时',
        'charts.shoe_label_products': '骑行鞋',
        'charts.shoe_label_sessions': '骑行鞋',
        'charts.sunday_label': '星期日',
        'charts.thursday_label': '星期四',
        'charts.top_ten_session_label': '单日案例量前十',
        'charts.top_transactions_label': '最高交易日期',
        'charts.triathlon_label': '铁人三项',
        'charts.tuesday_label': '星期二',
        'charts.wednesday_label': '星期三',
        'client.all_sessions_link': '所有案例',
        'client.bike_progress': '自行车',
        'client.client_progress_heading': '客户进度',
        'client.client_sessions_heading': '最新客户案例',
        'client.email': '客户邮箱',
        'client.name': '客户姓名',
        'client.not_applicable': 'N/A',
        'client.notes_label': '备注',
        'client.progress_types_complete': '完成',
        'client.saddle_progress': '座垫',
        'client.save_cta': '保存',
        'client.shoe_progress': '骑行鞋',
        'commute-comfort': '通勤-舒适',
        'commute-sport': '通勤运动',
        'country.ad': '安道尔',
        'country.ae': '阿拉伯联合酋长国',
        'country.af': '阿富汗',
        'country.ag': '安提瓜和巴布达',
        'country.ai': '安圭拉',
        'country.al': '阿尔巴尼亚',
        'country.am': '亚美尼亚',
        'country.ao': '安哥拉',
        'country.aq': '南极洲',
        'country.ar': '阿根廷',
        'country.as': '美属萨摩亚',
        'country.at': '奥地利',
        'country.au': '澳大利亚',
        'country.aw': '阿鲁巴',
        'country.ax': '奥兰群岛',
        'country.az': '阿塞拜疆',
        'country.ba': '波斯尼亚和黑塞哥维那的',
        'country.bb': '巴巴多斯',
        'country.bd': '孟加拉国',
        'country.be': '比利时',
        'country.bf': '布基纳法索',
        'country.bg': '保加利亚',
        'country.bh': '巴林',
        'country.bi': '布隆迪',
        'country.bj': '贝宁',
        'country.bl': '圣巴泰勒米',
        'country.bm': '百慕大',
        'country.bn': '文莱',
        'country.bo': '玻利维亚',
        'country.bq': '博内尔岛，圣尤斯特歇斯岛和萨巴岛',
        'country.br': '巴西',
        'country.bs': '巴哈马',
        'country.bt': '不丹',
        'country.bv': '布维岛',
        'country.bw': '博茨瓦纳',
        'country.by': '白俄罗斯',
        'country.bz': '伯利兹',
        'country.ca': '加拿大',
        'country.cc': '科科斯（基林）群岛',
        'country.cd': '刚果(民主共和国)',
        'country.cf': '中非共和国',
        'country.cg': '刚果',
        'country.ch': '瑞士',
        'country.ci': '科特迪瓦',
        'country.ck': '库克群岛',
        'country.cl': '智利',
        'country.cm': '喀麦隆',
        'country.cn': '中国',
        'country.co': '哥伦比亚',
        'country.cr': '哥斯达黎加',
        'country.cu': '古巴',
        'country.cv': '佛得角',
        'country.cw': '库拉索',
        'country.cx': '圣诞岛',
        'country.cy': '塞浦路斯',
        'country.cz': '捷克',
        'country.de': '德国',
        'country.dj': '吉布提',
        'country.dk': '丹麦',
        'country.dm': '多米尼加',
        'country.do': '多明尼加共和国',
        'country.dz': '阿尔及利亚',
        'country.ec': '厄瓜多尔',
        'country.ee': '爱沙尼亚',
        'country.eg': '埃及',
        'country.eh': '西撒哈拉',
        'country.er': '厄立特里亚',
        'country.es': '西班牙',
        'country.et': '埃塞俄比亚',
        'country.fi': '芬兰',
        'country.fj': '斐济',
        'country.fk': '福克兰群岛[马尔维纳斯]',
        'country.fm': '密克罗尼西亚(联邦)',
        'country.fo': '法罗群岛',
        'country.fr': '法国',
        'country.ga': '加蓬',
        'country.gb': '英国',
        'country.gd': '格林纳达',
        'country.ge': '格鲁吉亚',
        'country.gf': '法属圭亚那',
        'country.gg': '根西岛',
        'country.gh': '加纳',
        'country.gi': '直布罗陀',
        'country.gl': '格陵兰',
        'country.gm': '冈比亚',
        'country.gn': '几内亚',
        'country.gp': '瓜德罗普岛',
        'country.gq': '赤道几内亚',
        'country.gr': '希腊',
        'country.gs': '南乔治亚岛和南桑威奇群岛',
        'country.gt': '危地马拉',
        'country.gu': '关岛',
        'country.gw': '几内亚比绍',
        'country.gy': '圭亚那',
        'country.hk': '香港',
        'country.hm': '赫德岛和麦当劳群岛',
        'country.hn': '洪都拉斯',
        'country.hr': '克罗地亚',
        'country.ht': '海地',
        'country.hu': '匈牙利',
        'country.id': '印度尼西亚',
        'country.ie': '爱尔兰',
        'country.il': '以色列',
        'country.im': '马恩岛',
        'country.in': '印度',
        'country.io': '英属印度洋领地',
        'country.iq': '伊拉克',
        'country.ir': '伊朗',
        'country.is': '冰岛',
        'country.it': '意大利',
        'country.je': '新泽西',
        'country.jm': '牙买加',
        'country.jo': '约旦',
        'country.jp': '日本',
        'country.ke': '肯尼亚',
        'country.kg': '吉尔吉斯斯坦',
        'country.kh': '柬埔寨',
        'country.ki': '基里巴斯',
        'country.km': '科摩罗',
        'country.kn': '圣基茨和尼维斯',
        'country.kp': '朝鲜',
        'country.kr': '韩国',
        'country.kw': '科威特',
        'country.ky': '开曼群岛',
        'country.kz': '哈萨克斯坦',
        'country.la': '老挝',
        'country.lb': '黎巴嫩',
        'country.lc': '圣卢西亚',
        'country.li': '列支敦士登',
        'country.lk': '斯里兰卡',
        'country.lr': '利比里亚',
        'country.ls': '莱索托',
        'country.lt': '立陶宛',
        'country.lu': '卢森堡',
        'country.lv': '拉脱维亚',
        'country.ly': '利比亚',
        'country.ma': '摩洛哥',
        'country.mc': '摩纳哥',
        'country.md': '摩尔多瓦',
        'country.me': '黑山',
        'country.mf': '圣马丁岛 (荷兰部分)',
        'country.mg': '马达加斯加',
        'country.mh': '特克斯和凯科斯群岛',
        'country.mk': '马其顿',
        'country.ml': '马里',
        'country.mm': '缅甸',
        'country.mn': '蒙古',
        'country.mo': '澳门',
        'country.mp': '特克斯和凯科斯群岛',
        'country.mq': '马提尼克',
        'country.mr': '毛里塔尼亚',
        'country.ms': '蒙特塞拉特',
        'country.mt': '马耳他',
        'country.mu': '毛里求斯',
        'country.mv': '马尔代夫',
        'country.mw': '马拉维',
        'country.mx': '墨西哥',
        'country.my': '马来西亚',
        'country.mz': '莫桑比克',
        'country.na': '纳米比亚',
        'country.nc': '新喀里多尼亚',
        'country.ne': '尼日尔',
        'country.nf': '诺福克岛',
        'country.ng': '尼日利亚',
        'country.ni': '尼加拉瓜',
        'country.nl': '荷兰',
        'country.no': '挪威',
        'country.np': '尼泊尔',
        'country.nr': '瑙鲁',
        'country.nu': '纽埃',
        'country.nz': '新西兰',
        'country.om': '阿曼',
        'country.pa': '巴拿马',
        'country.pe': '秘鲁',
        'country.pf': '法属圭亚那',
        'country.pg': '巴布亚新几内亚',
        'country.ph': '菲律宾',
        'country.pk': '巴基斯坦',
        'country.pl': '波兰',
        'country.pm': '圣皮埃尔和密克隆',
        'country.pn': '皮特凯恩',
        'country.pr': '波多黎各',
        'country.ps': '巴勒斯坦, 巴勒斯坦国',
        'country.pt': '葡萄牙',
        'country.pw': '帕劳',
        'country.py': '巴拉圭',
        'country.qa': '卡塔尔',
        'country.re': '团圆',
        'country.ro': '罗马尼亚',
        'country.rs': '塞尔维亚',
        'country.ru': '俄国',
        'country.rw': '卢旺达',
        'country.sa': '沙特阿拉伯',
        'country.sb': '所罗门群岛',
        'country.sc': '塞舌尔',
        'country.sd': '苏丹',
        'country.se': '瑞典',
        'country.sg': '新加坡',
        'country.sh': '圣赫勒拿，阿森松岛和特里斯坦达库尼亚',
        'country.si': '斯洛文尼亚',
        'country.sj': '斯瓦尔巴和扬马延',
        'country.sk': '斯洛伐克',
        'country.sl': '塞拉利昂',
        'country.sm': '圣马力诺',
        'country.sn': '塞内加尔',
        'country.so': '索马里',
        'country.sr': '苏里南',
        'country.ss': '南苏丹',
        'country.st': '圣多美和普林西比',
        'country.sv': '萨尔瓦多',
        'country.sx': '圣马丁岛 (荷兰部分)',
        'country.sy': '叙利亚',
        'country.sz': '斯威士兰',
        'country.tc': '特克斯和凯科斯群岛',
        'country.td': '乍得',
        'country.tf': '法属南部',
        'country.tg': '多哥',
        'country.th': '泰国',
        'country.tj': '塔吉克斯坦',
        'country.tk': '托克劳',
        'country.tl': '东帝汶',
        'country.tm': '土库曼斯坦',
        'country.tn': '突尼斯',
        'country.to': '汤加',
        'country.tr': '图尔格菲',
        'country.tt': '特立尼达和多巴哥',
        'country.tv': '图瓦卢',
        'country.tw': '台湾',
        'country.tz': '坦桑尼亚',
        'country.ua': '乌克兰',
        'country.ug': '乌干达',
        'country.um': '美国本土外小岛屿',
        'country.us': '美利坚合众国',
        'country.uy': '乌拉圭',
        'country.uz': '乌兹别克斯坦',
        'country.va': '教廷',
        'country.vc': '圣文森特和格林纳丁斯',
        'country.ve': '委内瑞拉',
        'country.vg': '维尔京群岛（英属）',
        'country.vi': '维尔京群岛（美国）',
        'country.vn': '越南',
        'country.vu': '瓦努阿图',
        'country.wf': '瓦利斯和富图纳群岛',
        'country.ws': '萨摩亚',
        'country.ye': '也门',
        'country.yt': '马约特',
        'country.za': '南非',
        'country.zm': '赞比亚',
        'country.zw': '津巴布韦',
        cyclocross: 'CX越野',
        'date.all_time': '日期：所有时间',
        'date.last_month': '日期：上个月',
        'date.last_week': '日期：上周',
        'date.last_year': '日期: 去年',
        'employees.add_cta_label': '添加员工',
        'employees.delete_cta_label': '删除员工',
        'employees.employee_id_label': '员工ID',
        'employees.heading': '员工',
        'employees.intro': 'Retül Technology为您提供所有最新员工的时间表。',
        'employees.update_cta_label': '更新员工',
        'filters.all_label': '所有',
        'filters.client_all': '所有',
        'filters.client_anonymous': '匿名',
        'filters.client_identified': '确定',
        'filters.client_label': '客户类型',
        'filters.employees_label': '员工',
        'filters.filters_heading': '筛选',
        'filters.hide_filters': '显示筛选器',
        'filters.locations_label': '位置',
        'filters.rating_all': '所有',
        'filters.rating_label': '评价',
        'filters.rating_none': '无',
        'filters.session_all': '所有',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': '案例类型',
        'filters.session_match': 'Match',
        'filters.show_filters': '显示筛选器',
        'filters.status_all': '所有',
        'filters.status_closed': '关闭',
        'filters.status_label': '状态',
        'filters.status_open': '打开',
        'footer.privacy_link_label': '隐私政策',
        'footer.terms_link_label': '使用条款',
        'form.address_1_field_error': '地址是必填项。',
        'form.address_1_field_label': '地址',
        'form.city_field_error': '城市是必填项。',
        'form.city_field_label': '城市',
        'form.country_field_error': '国家/地区是必填项。',
        'form.country_field_label': '国家',
        'form.delete_field_confirm_message': '您确定要删除{data}吗？',
        'form.delete_field_label': '删除用户？',
        'form.edit_field_label': '编辑',
        'form.email_field_error':
            '电子邮件是必需的，并且必须是一个有效的电子邮件的格式。',
        'form.email_field_label': '电子邮件：',
        'form.name_field_error': '名字是必填项。',
        'form.name_field_label': '名',
        'form.shop_field_label': '门店',
        'form.shops_field_label': '门店',
        'form.state_field_error': '国家是必填项。',
        'form.state_field_label': '国家',
        'form.zip_field_error': 'Zip是必需的。',
        'form.zip_field_label': 'Zip',
        'gender.all': '性别：所有',
        'gender.female': '性别：女',
        'gender.male': '性别：男',
        'landing.heading': '欢迎进入Retailer Portal',
        'landing.intro': '访问Retül技术捕获的数据，会话和客户信息。',
        'landing.sign_in_or_create_account_label': '登录/创建帐户',
        'locale.label_cs': '',
        'locale.label_de': '',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': '',
        'locale.label_fr': '',
        'locale.label_it': '',
        'locale.label_ja': '',
        'locale.label_ko': '',
        'locale.label_nl': '',
        'locale.label_pl': '',
        'locale.label_pt': '',
        'locale.label_pt-br': '',
        'locale.label_zh-cn': '',
        'locale.label_zh-tw': '',
        'locale.locale_picker_cancel_label': '取消',
        'locale.locale_picker_heading': '选择您的语言。',
        'locale.locale_picker_save_label': '保存',
        'locations.add_cta_label': '添加位置',
        'locations.delete_cta_label': '删除位置',
        'locations.empty':
            '没有添加任何地点，要使用Retül应用程序，请添加位置。',
        'locations.empty_cta_label': '管理位置',
        'locations.heading': '管理位置',
        'locations.intro': '使用 retül 技术添加、编辑和删除位置。',
        'locations.location_id_label': '位置ID',
        'locations.update_cta_label': '更新位置',
        'metrics.closed_session_tooltip':
            '封闭会话表示记录了交易的会话。如果客户完成或未购买， {closingSessions}可帮助您跟踪Retül技术的销售情况。',
        'metrics.closed_sessions': '关闭的会话',
        'metrics.closing_session_link': '关闭会话',
        'metrics.total_sessions': '案例总计',
        'metrics.unique_client_emails': '唯一的客户端电子邮件',
        'metrics.unique_emails_tooltip':
            '此数字表示已使用唯一客户端进行的会话。唯一客户端由其电子邮件地址决定。',
        'mtb-trail': '山地（林道）',
        'mtb-xc': '山地（XC越野）',
        'nav.analytics': '分析',
        'nav.greeting_label': '你好， {data}',
        'nav.manage_employees_link_label': '管理员工',
        'nav.manage_locations_link_label': '管理位置',
        'nav.sessions': '案例',
        'nav.sign_out_link_label': '登出',
        'pagination.next': '下一步',
        'pagination.page': '页',
        'pagination.previous': '以前的',
        road: '公路',
        'session_details.add_transaction_cta_label': '添加交易',
        'session_details.all_sessions_link': '所有案例',
        'session_details.bike_product': '自行车',
        'session_details.client': '客户',
        'session_details.client_review_label': '客户评论',
        'session_details.complete_session_cta_label': '标记为已关闭',
        'session_details.complete_session_message':
            '通过添加客户端所做的任何交易来关闭此会话, 或',
        'session_details.complete_status': '关闭',
        'session_details.created_on': '创建于： {data}',
        'session_details.delete_link': '删除',
        'session_details.employee': '员工： {data}',
        'session_details.family_label': 'Family',
        'session_details.incomplete_status': '打开',
        'session_details.model_label': '车型',
        'session_details.no_transactions_message': '没有添加任何交易。',
        'session_details.product_label': '产品',
        'session_details.saddle_product': '座垫',
        'session_details.shoe_product': '骑行鞋',
        'session_details.shop': '门店： {data}',
        'session_details.size_label': '尺寸',
        'session_details.status_label': '状态',
        'session_details.transactions_heading': '会话交易',
        'session_products.arch_height_label': '足弓高度： {data}',
        'session_products.bike_size_label': '自行车尺寸： {data}',
        'session_products.saddle_height_label': '座垫高度： {data} mm',
        'session_products.saddle_width_label': '座垫宽度： {data} mm',
        'session_products.session_product_label': '案例数据',
        'session_products.shoe_size_label': '鞋码： {data}',
        'sessions.add_transaction_cta_label': '添加交易',
        'sessions.client_anonymous': '匿名',
        'sessions.client_label': '客户',
        'sessions.closed_status': '关闭',
        'sessions.csv_downloading_message':
            '我们正在为您的CSV文件收集所有数据，这可能需要几分钟时间。',
        'sessions.date_label': '日期',
        'sessions.employee_label': '员工',
        'sessions.export_csv_link': '导出清单(.CSV)',
        'sessions.filter_empty':
            '对不起, 没有符合筛选条件的会话。请重置筛选器选择。',
        'sessions.filter_empty_cta_label': '重置筛选器',
        'sessions.heading': '案例',
        'sessions.intro': '所有最新Retül技术案例的时间表',
        'sessions.location_label': '位置',
        'sessions.no_rating': '没有评价',
        'sessions.open_status': '打开',
        'sessions.rating_label': '评分',
        'sessions.session_id_label': '案例 ID',
        'sessions.session_loading_message':
            '我们正在收集您的所有案例，这可能需要一些时间。',
        'sessions.status_label': '状态',
        'sign_in.heading': '登录',
        'sign_in.intro':
            '输入您的电子邮件和密码以开始使用，或选择“注册帐户”以创建新帐户。',
        'status.all': '状态：所有',
        'status.complete': '状态：已关闭',
        'status.incomplete': '状态：打开',
        triathlon: '铁人三项',
        'POWER MIMIC DESCRIPTION':
            '这款座垫可有效减缓麻木和疼痛，其设计模仿身体对不同类型压力的反应，在软组织内形成平衡。',
        'date_picker.all_time': '所有案例',
        'date_picker.date_range': '日期范围',
        'date_picker.last_month': '上个月',
        'date_picker.last_week': '上周',
        'date_picker.last_year': '去年',
        'date_picker.this_month': '本月',
        'date_picker.this_week': '本周',
        'date_picker.this_year': '今年',
        'session_details.client_notes': '客户须知',
        'session_details.footbed_product': '鞋垫',
        'session_products.it_width_label': '坐骨宽度： {data} mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match是一种店内评估体验，可精确地测量您的脚长，以确定最佳的自行车尺寸并提供最适合您的产品。',
        'body_data.about_saddle':
            'Retül Match是一种沉浸式店内体验，利用Retül Digital Sitbone Device通过数字压力映射技术捕捉您的坐骨宽度，以准确地确定车座尺寸并提供最适合您的车座。',
        'body_data.about_shoe':
            'Retül Match是一种沉浸式店内体验，可捕捉脚部数据，以准确地确定足弓垫需求和鞋码，并提供最适合您的鞋履。',
        'body_data.arch_height_label': '足弓高度',
        'body_data.bike_label': '自行车',
        'body_data.bike_size_label': '自行车尺寸',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': '周长',
        'body_data.find_retul_technology': '查找Retül技术',
        'body_data.heading': '身体数据',
        'body_data.intro':
            '利用Retül Vantage技术捕捉身体数据和骑行偏好，以提供准确的尺寸信息并提供最适合您的产品。',
        'body_data.length_label': '长度',
        'body_data.load_error': '此时无法检索到您的身体数据，请稍后重试。',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': '未收集',
        'body_data.recommended_label': '推荐',
        'body_data.saddle_height_label': '车座高度',
        'body_data.saddle_label': '车座',
        'body_data.saddle_size_label': '车座尺寸',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': '胫骨',
        'body_data.shoe_label': '鞋履',
        'body_data.shoe_size_label': '鞋码',
        'body_data.sit_bone_label': '坐骨',
        'body_data.starting_label': '起始',
        'body_data.thigh_label': '大腿',
        'body_data.view_bike_matches_label': '查看自行车匹配',
        'body_data.view_saddle_matches_label': '查看车座匹配',
        'body_data.view_shoe_matches_label': '查看鞋履匹配',
        'body_data.your_progress_completion_label': '已完成{numComplete}/3',
        'body_data.your_progress_label': '您的进度',
        cancel_label: '取消',
        'create_account.heading': '创建账户',
        'create_account.intro':
            '请输入电子邮箱和密码以创建Retül Passport账户。',
        'edit_form.edit_form_cancel_label': '取消',
        'edit_form.edit_form_save_label': '保存',
        email_format_error: '电子邮箱格式无效。',
        email_label: '电子邮箱',
        'forgot_password.heading': '忘记密码',
        'forgot_password.intro':
            '忘记您的密码？ 请输入您的电子邮箱，我们会向您发送密码重置链接。',
        'forgot_password.reset_email_sent': '密码重置电子邮件已发送至{email}',
        'landing.create_account_label': '创建账户',
        'landing.sign_in_label': '登录',
        'nav.account_profiles_link_label': '账户和骑手档案',
        'nav.home_page_link_label': '主页',
        'nav.logout_link_label': '注销',
        'not_found.go_home_label': '返回主页',
        'not_found.heading': '404',
        'not_found.intro': '您查找的页面似乎已被移走、删除或不存在。',
        password_invalid_error: '密码无效。',
        password_label: '密码',
        'product_matches.bike_size_label': '自行车尺寸',
        'product_matches.category_filter_commute_comfort_label': '通勤-舒适',
        'product_matches.category_filter_commute_sport_label': '通勤-运动',
        'product_matches.category_filter_cyclocross_label': '公路越野',
        'product_matches.category_filter_label': '类别',
        'product_matches.category_filter_label_road': '公路车',
        'product_matches.category_filter_mountain_trail_label': '山地（林道）',
        'product_matches.category_filter_mountain_xc_label': '山地（越野）',
        'product_matches.category_filter_triathlon_label': '三项全能',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            '我们没有这种产品类型的身体数据，因此无法为您提供匹配结果。请使用以上筛选器查看其他产品类型，或使用地点页面查找地点，以完成此Match模块。',
        'product_matches.find_retul_technology_label': '查找Retül技术',
        'product_matches.gender_filter_all_label': '显示所有产品',
        'product_matches.gender_filter_female_label': '显示女士产品',
        'product_matches.gender_filter_male_label': '显示男士产品',
        'product_matches.heading': '产品匹配',
        'product_matches.intro':
            'Retül技术利用您的身体数据和骑行偏好来匹配最适合您的Specialized产品。',
        'product_matches.last_session_match_label': '最后一个Match会话',
        'product_matches.load_error':
            '此时无法检索到您的产品匹配，请稍后重试。',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            '根据您的Retül Match会话中捕捉到的生物测定数据，我们推荐上述的自行车尺寸。尽管这是个良好的起始点，但是我们仍然建议您向当地零售商咨询，让其根据您的经验水平和体型为您提供建议。',
        'product_matches.overlay_bike_size_label': '自行车尺寸',
        'product_matches.overlay_close_label': '关闭',
        'product_matches.overlay_indicator_label': '您',
        'product_matches.overlay_saddle_height_details':
            '根据您的Retül Match会话中捕捉到的生物测定数据，我们推荐上述的车座高度。尽管这是个良好的起始点，但是我们仍然建议您向当地零售商咨询，让其根据您的经验水平和体型为您提供建议。',
        'product_matches.overlay_saddle_height_label': '车座高度',
        'product_matches.overlay_saddle_size_details':
            '根据您的Retül Match会话中捕捉到生物测定数据，我们推荐上述的车座尺寸。尽管这是个良好的起始点，但是我们仍然建议您向当地零售商咨询，让其根据您的身体特点为您提供建议。',
        'product_matches.overlay_saddle_size_label': '车座尺寸',
        'product_matches.overlay_shoe_size_details':
            '根据您的Retül Match会话中捕捉到生物测定数据，我们推荐上述的鞋码。尽管这是个良好的起始点，但是我们仍然建议您向当地零售商咨询，让其根据您的经验水平为您提供建议。',
        'product_matches.overlay_shoe_size_label': '鞋码',
        'product_matches.padding_level_label': '填料填充程度',
        'product_matches.product_type_filter_bikes_label': '自行车',
        'product_matches.product_type_filter_label': '产品类型',
        'product_matches.product_type_filter_saddles_label': '车座',
        'product_matches.product_type_filter_shoes_label': '鞋履',
        'product_matches.recommended_label': '推荐',
        'product_matches.saddle_detail_size_label': '车座尺寸：',
        'product_matches.saddle_height_label': '车座高度',
        'product_matches.saddle_models_label': '型号',
        'product_matches.saddle_size_label': '车座尺寸',
        'product_matches.shoe_size_label': '鞋码',
        'product_matches.starting_label': '起始',
        'product_matches.view_on_specialized_label': '在Specialized网站上查看',
        'profile.account_heading': '账户',
        'profile.bike_type_city_label': '城市',
        'profile.bike_type_label': '自行车类型',
        'profile.bike_type_mountain_label': '山地',
        'profile.bike_type_road_label': '公路车',
        'profile.email_error': '请输入有效的电子邮箱',
        'profile.email_label': '电子邮箱',
        'profile.gender_female_label': '女',
        'profile.gender_label': '性别',
        'profile.gender_male_label': '男',
        'profile.heading': '账户和骑手档案',
        'profile.intro': '即将推出...编辑您的账户信息和骑手档案。',
        'profile.name_error': '请输入名字',
        'profile.name_label': '名字',
        'profile.password_error': '请输入密码',
        'profile.password_label': '密码',
        'profile.position_high_label': '高位',
        'profile.position_label': '位置',
        'profile.position_low_label': '低位',
        'profile.position_medium_label': '中',
        'profile.ride_duration_extended_label': '超过 90 分钟',
        'profile.ride_duration_label': '时长',
        'profile.ride_duration_long_label': '60 至 90 分钟',
        'profile.ride_duration_medium_label': '30 至 60 分钟',
        'profile.ride_duration_short_label': '0 至 30 分钟',
        'profile.ride_frequency_label': '频率',
        'profile.ride_frequency_once_month_label': '每月 1 次',
        'profile.ride_frequency_once_week_label': '每周 1 次',
        'profile.ride_frequency_six_week_label': '每周 6 次',
        'profile.ride_frequency_three_week_label': '每周 3 次',
        'routes.account_profile_route_label': '账户和骑手档案',
        'routes.body_data_route_label': '身体数据',
        'routes.create_account_route_label': '创建账户',
        'routes.forgot_password_route_label': '忘记密码',
        'routes.locations_route_label': '地点',
        'routes.not_found_route_label': '未找到',
        'routes.privacy_policy_route_label': '隐私政策',
        'routes.product_matches_route_label': '产品匹配',
        'routes.reset_password_route_label': '重置密码',
        'routes.set_password_route_label': '重置密码',
        'routes.sessions_route_label': '会话',
        'routes.sign_in_route_label': '登录',
        'routes.terms_of_service_route_label': '服务条款',
        'sessions.download_pdf_cta_label': '下载PDF',
        'sessions.empty': '您没有任何会话。',
        'sessions.empty_cta_label': '查找地点',
        'sessions.loading_error': '此时无法检索到您的会话，请稍后重试。',
        'sessions.loading_error_cta_label': '重试',
        'sessions.session_type_filter_label': '会话类型',
        'session_type.dfd_session_name': 'Retül数字脚部设备',
        'session_type.dsd_session_name': 'Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül脚部设备',
        'session_type.legs_session_name': 'Retül腿部设备',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül车座',
        'session_type.unknown_session_name': 'Retül会话',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': '登录',
        'sign_in.contact_label': '联系Retül',
        'sign_in.forgot_password_label': '忘记密码？',
        'sign_in.sign_in_error':
            '无法检索到您的用户数据。请联系Retül支持团队寻求帮助。',
        submit_label: '提交',
    },
};
