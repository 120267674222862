export const CREATE_TRANSACTION_COMPLETE = 'CREATE_TRANSACTION_COMPLETE';
export const CREATE_TRANSACTION_FAILED = 'CREATE_TRANSACTION_FAILED';
export const CREATE_TRANSACTION_REQUESTED = 'CREATE_TRANSACTION_REQUESTED';

export const RECEIVE_BIKE_TYPES = 'RECEIVE_BIKE_TYPES';
export const REQUEST_BIKE_TYPES = 'REQUEST_BIKE_TYPES';

export const RECEIVE_BIKE_FAMILIES = 'RECEIVE_BIKE_FAMILIES';
export const REQUEST_BIKE_FAMILIES = 'REQUEST_BIKE_FAMILIES';

export const RECEIVE_BIKE_MODELS = 'RECEIVE_BIKE_MODELS';
export const REQUEST_BIKE_MODELS = 'REQUEST_BIKE_MODELS';

export const RECEIVE_BIKE_SIZES = 'RECEIVE_BIKE_SIZES';
export const REQUEST_BIKE_SIZES = 'REQUEST_BIKE_SIZES';

export const RECEIVE_SADDLE_TYPES = 'RECEIVE_SADDLE_TYPES';
export const REQUEST_SADDLE_TYPES = 'REQUEST_SADDLE_TYPES';

export const RECEIVE_SADDLE_FAMILIES = 'RECEIVE_SADDLE_FAMILIES';
export const REQUEST_SADDLE_FAMILIES = 'REQUEST_SADDLE_FAMILIES';

export const RECEIVE_SADDLE_MODELS = 'RECEIVE_SADDLE_MODELS';
export const REQUEST_SADDLE_MODELS = 'REQUEST_SADDLE_MODELS';

export const RECEIVE_SADDLE_SIZES = 'RECEIVE_SADDLE_SIZES';
export const REQUEST_SADDLE_SIZES = 'REQUEST_SADDLE_SIZES';

export const RECEIVE_SHOE_TYPES = 'RECEIVE_SHOE_TYPES';
export const REQUEST_SHOE_TYPES = 'REQUEST_SHOE_TYPES';

export const RECEIVE_SHOE_FAMILIES = 'RECEIVE_SHOE_FAMILIES';
export const REQUEST_SHOE_FAMILIES = 'REQUEST_SHOE_FAMILIES';

export const RECEIVE_SHOE_MODELS = 'RECEIVE_SHOE_MODELS';
export const REQUEST_SHOE_MODELS = 'REQUEST_SHOE_MODELS';

export const RECEIVE_SHOE_SIZES = 'RECEIVE_SHOE_SIZES';
export const REQUEST_SHOE_SIZES = 'REQUEST_SHOE_SIZES';

export const RECEIVE_FOOTBED_MODELS = 'RECEIVE_FOOTBED_MODELS';
export const REQUEST_FOOTBED_MODELS = 'REQUEST_FOOTBED_MODELS';

export const UPDATE_BIKE_TYPE = 'UPDATE_BIKE_TYPE';