import React, {Component} from 'react';
import './Bars.css';
import Tippy from "@tippy.js/react";
import 'tippy.js/dist/tippy.css'

export default class Bars extends Component {
  renderBars() {
    const {data, upperLimit} = this.props;
    const {yScale, xScale} = this.props.scales;
    const width = this.props.width || xScale.bandwidth();

    return data.map((d, i) => {
      return (
        <Tippy content={d.quantity.toString()} followCursor={true}>
          <rect
            className="Bar"
            key={i}
            x={xScale(d.item) - (width / 2.5)}
            y={yScale(d.quantity)}
            height={upperLimit - yScale(d.quantity)}
            width={width}
          />
        </Tippy>
      );
    });
  }

  render() {
    return (
      <g
        className='Vertical-Bar-Chart'>
        {this.renderBars()}
      </g>
    )
  }
}
