import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/analytics';
import * as sessionActionCreators from '../actions/sessions';
import AnalyticsFilters from '../components/AnalyticsFilters/AnalyticsFilters';
import Main from '../components/Main/Main';
import MetricsGroup from '../components/MetricsGroup/MetricsGroup';
import {RECEIVED} from '../types/statuses';
import '../assets/stylesheets/Charts.css';
import LocationsEmpty from '../components/LocationsEmpty/LocationsEmpty';
import {injectIntl} from 'react-intl';
import messages from './messages';

function WrappedAnalyticsContainer(AnalyticsComponent, analyticsType) {
  class AnalyticsDataContainer extends Component {
    constructor(props) {
      super(props);

      this.intl = props.intl;

      this.state = {
        'searchFilters': props.searchFilters
      };

      this.updateAnalyticsFilters = this.updateAnalyticsFilters.bind(this);
    }

    updateAnalyticsFilters(filterName, filterValue) {
      const {updateAnalyticsFilters} = this.props;

      updateAnalyticsFilters({[filterName]: filterValue})
    }

    componentWillReceiveProps(nextProps) {
      const { searchFilters } = nextProps;

      if (this.searchFiltersChanged(searchFilters)) {
        this.setState({
          'searchFilters': searchFilters
        }, () => {
          this.startFetchInterval();
        })
      }
    }

    componentWillMount() {
      this.startFetchInterval();
      this.fetchSessionsData();
    }

    componentWillUpdate(nextProps) {
      const { fetchingStatus } = nextProps;

      if (fetchingStatus === RECEIVED) {
        this.clearFetchInterval();
      }
    }

    componentWillUnmount() {
      this.clearFetchInterval();
    }

    searchFiltersChanged(searchFilters) {
      return JSON.stringify(searchFilters) !== JSON.stringify(this.props.searchFilters);
    }

    /**
     * The analytics API may have to crunch a lot of data
     * before returning a response, so it returns a 202 Accepted
     * until the data is ready. This interval will run until a
     * 200 is returned.
     *
     * @method startFetchInterval
     */
    startFetchInterval() {
      this.fetchInterval = setInterval(() => {
        this.getAnalytics();
      }, 2000);
    }

    fetchSessionsData() {
      this.props.fetchSessions();
    }

    getAnalytics() {
      console.log(this.props);
      const { fetchAnalytics, searchFilters } = this.props;
      fetchAnalytics(analyticsType, searchFilters);
    }

    clearFetchInterval() {
      clearInterval(this.fetchInterval);
    }

    isAnalyticsDataFetched(){
      const { fetchingStatus, displayAnalytics } = this.props;

      return fetchingStatus === RECEIVED && displayAnalytics === analyticsType;
    }

    buildMetrics(data) {
      const metrics = [];

      Object.keys(data).forEach(key => {
        metrics.push({
          'item': this.intl.formatMessage(messages[key]),
          'quantity': data[key]
        });
      });

      return metrics;
    }

    renderAnalyticsFilters(isLoading) {
      const { history } = this.props;

      return isLoading ? null : (
        <AnalyticsFilters
          activeFilter={ analyticsType }
          history={ history }
          updateAnalyticsFilters={this.updateAnalyticsFilters}
        />
      )
    }

    renderMetrics(isLoading) {
      if (isLoading) return null;

      const metrics = this.buildMetrics(this.props.data.metrics);

      if (metrics) {
        return (
          <MetricsGroup data={ metrics }/>
        )
      }
    }

    renderAnalyticsCharts(isLoading) {
      const { data } = this.props;

      return isLoading ? null : (
        <AnalyticsComponent data={ data } />
      );
    }

    renderLoadingMessage() {
      return (
        <div className="box box_alignCenter">
          <p className="txt txt_md text_loading_message">
            {this.intl.formatMessage(messages.analytics_loading_message)}
          </p>
        </div>
      );
    }

    renderAnalytics(isLoading) {
      if (isLoading) return this.renderLoadingMessage();
      const hasLocations = this.props.sessionsFilters.data.shop.length > 0;

      if (hasLocations) {
        return (
          <React.Fragment>
            { this.renderAnalyticsFilters(isLoading) }
            { this.renderAnalyticsCharts(isLoading) }
          </React.Fragment>
        );
      }
      else {
        return (
          <LocationsEmpty />
        )
      }
    }

    render() {
      const isLoading = !(this.isAnalyticsDataFetched());

      return (
        <Main
          isLoading={ isLoading }
          top={ [ this.renderMetrics(isLoading) ] }
          bottom={ [ this.renderAnalytics(isLoading) ] }
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {...state.analytics, ...state.sessions, 'searchFilters': state.analytics.searchFilters};
  }

  return connect(mapStateToProps, {...actionCreators, ...sessionActionCreators})(injectIntl(AnalyticsDataContainer));
}

export default WrappedAnalyticsContainer;
