import * as actionTypes from '../types/actions/transactions'
import {JSON_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';
import {apiCreate, apiGet} from '../api/apiClient';
import {
  bikeFamilyUrl,
  bikeModelsUrl,
  bikeSizeUrl,
  bikeTransactionsUrl,
  bikeTypeUrl,
  footbedModelsUrl,
  footbedTransactionsUrl,
  saddleFamilyUrl,
  saddleModelUrl,
  saddleSizeUrl,
  saddleTransactionsUrl,
  saddleTypeUrl,
  shoeFamilyUrl,
  shoeModelUrl,
  shoeSizeUrl,
  shoeTransactionsUrl,
  shoeTypeUrl,
} from '../utils/urlHelper'

// POST TRANSACTIONS
export function createTransactionRequested() {
  return {
    type: actionTypes.CREATE_TRANSACTION_REQUESTED
  }
}

export function createTransactionComplete() {
  return {
    type: actionTypes.CREATE_TRANSACTION_COMPLETE
  }
}

export function createTransactionFailed() {
  return {
    type: actionTypes.CREATE_TRANSACTION_FAILED
  }
}

export function createTransaction(data) {
  return dispatch => {
    dispatch(createTransactionRequested());
    return apiCreate(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          receiveCallback: createTransactionComplete,
          url: bikeTransactionsUrl()
        }
      )
    )
  }
}

export function createSaddleTransaction(data) {
  return dispatch => {
    dispatch(createTransactionRequested());
    return apiCreate(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          receiveCallback: createTransactionComplete,
          url: saddleTransactionsUrl()
        }
      )
    )
  }
}

export function createShoeTransaction(data) {
  return dispatch => {
    dispatch(createTransactionRequested());
    return apiCreate(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          receiveCallback: createTransactionComplete,
          url: shoeTransactionsUrl()
        }
      )
    )
  }
}

export function createFootbedTransaction(data) {
  return dispatch => {
    dispatch(createTransactionRequested());
    return apiCreate(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          receiveCallback: createTransactionComplete,
          url: footbedTransactionsUrl()
        }
      )
    )
  }
}

//GET PRODUCT TYPES
export function requestBikeTypes() {
  return {
    type: actionTypes.REQUEST_BIKE_TYPES
  }
}

export function receiveBikeTypes(bikeType) {
  return {
    bikeType,
    type: actionTypes.RECEIVE_BIKE_TYPES,
  }
}

//GET PRODUCT TYPES

export function requestSaddleTypes() {
  return {
    type: actionTypes.REQUEST_SADDLE_TYPES
  }
}

export function receiveSaddleTypes(saddleType) {
  return {
    saddleType,
    type: actionTypes.RECEIVE_SADDLE_TYPES,
  }
}

export function requestShoeTypes() {
  return {
    type: actionTypes.REQUEST_SHOE_TYPES
  }
}

export function receiveShoeTypes(shoeType) {
  return {
    shoeType,
    type: actionTypes.RECEIVE_SHOE_TYPES,
  }
}

export function fetchBikeTypes() {
  return dispatch => {
    dispatch(requestBikeTypes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveBikeTypes,
          url: bikeTypeUrl(),
        }
      )
    )
  }
}

export function fetchSaddleTypes() {
  return dispatch => {
    dispatch(requestSaddleTypes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveSaddleTypes,
          url: saddleTypeUrl(),
        }
      )
    )
  }
}

export function fetchShoeTypes() {
  return dispatch => {
    dispatch(requestShoeTypes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveShoeTypes,
          url: shoeTypeUrl(),
        }
      )
    )
  }
}

//GET PRODUCT FAMILIES
export function requestBikeFamilies() {
  return {
    type: actionTypes.REQUEST_BIKE_FAMILIES
  }
}

export function receiveBikeFamilies(bikeFamily) {
  return {
    bikeFamily,
    type: actionTypes.RECEIVE_BIKE_FAMILIES,
  }
}

export function requestSaddleFamilies() {
  return {
    type: actionTypes.REQUEST_SADDLE_FAMILIES
  }
}

export function receiveSaddleFamilies(saddleFamily) {
  return {
    saddleFamily,
    type: actionTypes.RECEIVE_SADDLE_FAMILIES,
  }
}

export function requestShoeFamilies() {
  return {
    type: actionTypes.REQUEST_SHOE_FAMILIES
  }
}

export function receiveShoeFamilies(shoeFamily) {
  return {
    shoeFamily,
    type: actionTypes.RECEIVE_SHOE_FAMILIES,
  }
}

export function fetchBikeFamilies(type) {
  return dispatch => {
    dispatch(requestBikeFamilies());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveBikeFamilies,
          url: bikeFamilyUrl(type),
        }
      )
    )
  }
}

export function requestBikeModels() {
  return {
    type: actionTypes.REQUEST_BIKE_MODELS
  }
}

export function receiveBikeModels(bikeModels) {
  return {
    bikeModels,
    type: actionTypes.RECEIVE_BIKE_MODELS,
  }
}

export function fetchBikeModels(model_id) {
  return dispatch => {
    dispatch(requestBikeModels());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveBikeModels,
          url: bikeModelsUrl(model_id),
        }
      )
    )
  }
}

export function fetchSaddleFamilies(type) {
  return dispatch => {
    dispatch(requestSaddleFamilies());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveSaddleFamilies,
          url: saddleFamilyUrl(type),
        }
      )
    )
  }
}

export function fetchShoeFamilies(type) {
  return dispatch => {
    dispatch(requestShoeFamilies());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveShoeFamilies,
          url: shoeFamilyUrl(type),
        }
      )
    )
  }
}

//GET PRODUCT MODELS
export function fetchSaddleModels(family) {
  return dispatch => {
    dispatch(requestSaddleModels());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveSaddleModels,
          url: saddleModelUrl(family),
        }
      )
    )
  }
}

export function fetchShoeModels(family) {
  return dispatch => {
    dispatch(requestShoeModels());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveShoeModels,
          url: shoeModelUrl(family),
        }
      )
    )
  }
}

export function fetchFootbedModels() {
  return dispatch => {
    dispatch(requestShoeModels());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveFootbedModels,
          url: footbedModelsUrl(),
        }
      )
    )
  }
}

export function requestSaddleModels() {
  return {
    type: actionTypes.REQUEST_SADDLE_MODELS
  }
}

export function receiveSaddleModels(saddleModel) {
  return {
    saddleModel,
    type: actionTypes.RECEIVE_SADDLE_MODELS,
  }
}

export function requestShoeModels() {
  return {
    type: actionTypes.REQUEST_SHOE_MODELS
  }
}

export function receiveShoeModels(shoeModel) {
  return {
    shoeModel,
    type: actionTypes.RECEIVE_SHOE_MODELS,
  }
}

export function requestFootbedModels() {
  return {
    type: actionTypes.REQUEST_FOOTBED_MODELS,
  }
}

export function receiveFootbedModels(footbedModels) {
  return {
    footbedModels,
    type: actionTypes.RECEIVE_FOOTBED_MODELS,
  }
}

//GET PRODUCT SIZES
export function fetchBikeSizes(family) {
  return dispatch => {
    dispatch(requestBikeSizes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveBikeSizes,
          url: bikeSizeUrl(family),
        }
      )
    )
  }
}

export function fetchSaddleSizes(model) {
  return dispatch => {
    dispatch(requestSaddleSizes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveSaddleSizes,
          url: saddleSizeUrl(model),
        }
      )
    )
  }
}

export function fetchShoeSizes(model) {
  return dispatch => {
    dispatch(requestShoeSizes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveShoeSizes,
          url: shoeSizeUrl(model),
        }
      )
    )
  }
}

export function requestBikeSizes() {
  return {
    type: actionTypes.REQUEST_BIKE_SIZES
  }
}

export function receiveBikeSizes(bikeSize) {
  return {
    bikeSize,
    type: actionTypes.RECEIVE_BIKE_SIZES,
  }
}

export function requestSaddleSizes() {
  return {
    type: actionTypes.REQUEST_SADDLE_SIZES
  }
}

export function receiveSaddleSizes(saddleSizes) {
  return {
    saddleSizes,
    type: actionTypes.RECEIVE_SADDLE_SIZES,
  }
}

export function requestShoeSizes() {
  return {
    type: actionTypes.REQUEST_SHOE_SIZES
  }
}

export function receiveShoeSizes(shoeSizes) {
  return {
    shoeSizes,
    type: actionTypes.RECEIVE_SHOE_SIZES,
  }
}
