import React, {Component} from 'react';
import './Navigation.css';
import Logo from '../../components/Logo/Logo';
import NavigationLink from '../../components/NavigationLink/NavigationLink';
import NavigationDropDownContainer from '../../containers/NavigationDropDownContainer';
import {isAuthenticated} from '../../api/tokens';
import {injectIntl} from 'react-intl';
import messages from './messages';

class Navigation extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'sessions': intl.formatMessage(messages.sessions),
      'analytics': intl.formatMessage(messages.analytics)
    };
  }

  renderNavigationLinkGroupSection() {
    return (
      <nav className="Navigation-center">
        <ul className="Navigation-list">
          <li>
            <NavigationLink to='/analytics'>
              {this.page.analytics}
            </NavigationLink>
          </li>
          <li>
            <NavigationLink to='/sessions'>
              {this.page.sessions}
            </NavigationLink>
          </li>
        </ul>
      </nav>
    )
  }

  renderNavigationDropDownSection() {
    return (
      <div className="Navigation-right">
        <NavigationDropDownContainer/>
      </div>
    )
  }

  render() {
    return (
      <header className="Navigation">
        <div className="Navigation-container">
          <div className="Navigation-left">
            <Logo/>
          </div>

          {isAuthenticated() && this.renderNavigationLinkGroupSection()}

          {isAuthenticated() && this.renderNavigationDropDownSection()}
        </div>
      </header>
    )

  }
}

export default injectIntl(Navigation);
