import React, { Component } from 'react';
import { connect } from 'react-redux';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import * as actionCreators from '../actions/verify';
import Verify from '../components/Verify/Verify';

export class VerifyContainer extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(data) {
        const { verify } = this.props;

        verify(data);
    }

    render() {
        const { verifyData } = this.props;

        return componentWithErrorBoundary(
            <Verify
                handleSubmit={this.handleSubmit}
                data={verifyData.data}
                errors={verifyData.errors}
                fetchingStatus={verifyData.fetchingStatus}
            />
        );
    }
}

function mapStateToProps(state) {
    return state.verify;
}

export default connect(mapStateToProps, actionCreators)(VerifyContainer);
