import React, {Component} from 'react';
import './TableHeadingSort.css';

const sortItems = [
  {name: 'descending', orderModifier: ''},
  {name: 'ascending', orderModifier: '-'},
];

export default class TableHeadingSort extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ascending: {
        active: false
      },
      descending: {
        active: false
      }
    };

    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount() {
    const {headingName, order} = this.props;
    const {attribute, sort} = order;
    if (headingName === attribute) {
      this.setState((prevState) => {
        return {
          [sort]: {
            active: !prevState[sort].active
          }
        }
      })
    }
  }

  handleClick() {
    const {headingName, handleClick} = this.props;
    const sort = ['ascending', 'descending'].find(sortDirection => !this.state[sortDirection].active);
    handleClick('order', {attribute: headingName, sort})
  }

  activeClass(name) {
    return this.state[name].active ? 'active' : 'inactive';
  }

  renderSortItems() {
    return sortItems.map((sortItem, index) => {
      const {name} = sortItem;
      return (
        <div key={index}
             className={`sort ${name} ${this.activeClass(name)}`}
        />
      )
    });
  }

  render() {
    return (
      <a
        className="TableHeadingSort"
        onClick={() => this.handleClick()}
      >
        {this.renderSortItems()}
      </a>
    )
  }
}