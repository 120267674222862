import { defineMessages } from 'react-intl';

export default defineMessages({
  'all_sessions_link': {
    'id': 'session_details.all_sessions_link',
    'defaultMessage': 'All Sessions',
    'description': 'Link back to the Sessions page'
  },
  'created_on': {
    'id': 'session_details.created_on',
    'defaultMessage': 'Created on: {data}',
    'description': 'Displays the created on date'
  },
  'client': {
    'id': 'session_details.client',
    'defaultMessage': 'Client',
    'description': 'Label for displaying the client name.(Client: My Shop)'
  },
  'client_notes': {
    'id': 'session_details.client_notes',
    'defaultMessage': 'Client Notes',
    'description': 'Label for button to see client notes.'
  },
  'shop': {
    'id': 'session_details.shop',
    'defaultMessage': 'Shop: {data}',
    'description': 'Label for displaying the shop name'
  },
  'employee': {
    'id': 'session_details.employee',
    'defaultMessage': 'Employee: {data}',
    'description': 'Label for displaying the employee name'
  },
  'status': {
    'id': 'session_details.status_label',
    'defaultMessage': 'Status',
    'description': 'Label for the status of the session'
  },
  'incomplete_status': {
    'id': 'session_details.incomplete_status',
    'defaultMessage': 'Open',
    'description': 'Open status of the session'
  },
  'complete_status': {
    'id': 'session_details.complete_status',
    'defaultMessage': 'Closed',
    'description': 'Closed status of the session'
  },
  'transactions_heading': {
    'id': 'session_details.transactions_heading',
    'defaultMessage': 'Session Transactions',
    'description': 'Heading for the table of the Session\'s Transactions '
  },
  'no_transactions_message': {
    'id': 'session_details.no_transactions_message',
    'defaultMessage': 'No transactions have been added.',
    'description': 'Message displayed when no transactions have been added to the session'
  },
  'add_transaction': {
    'id': 'session_details.add_transaction_cta_label',
    'defaultMessage': 'Add Transaction',
    'description': 'CTA Label for adding a transaction to the session'
  },
  'complete_session_message': {
    'id': 'session_details.complete_session_message',
    'defaultMessage': 'Close this session by adding any transactions made by the client, or',
    'description': 'Message prompting user to close the sessions'
  },
  'complete_session_cta_label': {
    'id': 'session_details.complete_session_cta_label',
    'defaultMessage': 'Mark as closed',
    'description': 'Label for CTA to close the sessions'
  },
  'product_label': {
    'id': 'session_details.product_label',
    'defaultMessage': 'Product',
    'description': 'Label for product column on the transactions table'
  },
  'family_label': {
    'id': 'session_details.family_label',
    'defaultMessage': 'Family',
    'description': 'Label for family column on the transactions table'
  },
  'model_label': {
    'id': 'session_details.model_label',
    'defaultMessage': 'Model',
    'description': 'Label for model column on the transactions table'
  },
  'size_label': {
    'id': 'session_details.size_label',
    'defaultMessage': 'Size',
    'description': 'Label for size column on the transactions table'
  },
  'client_review_label': {
    'id': 'session_details.client_review_label',
    'defaultMessage': 'Client Review',
    'description': 'Label for client review column on the transactions table'
  },
  'delete_link': {
    'id': 'session_details.delete_link',
    'defaultMessage': 'Delete',
    'description': 'Link for the user to delete the transaction'
  },
  'Saddle': {
    'id': 'session_details.saddle_product',
    'defaultMessage': 'Saddle',
    'description': 'Type of product (Saddle)'
  },
  'Bike': {
    'id': 'session_details.bike_product',
    'defaultMessage': 'Bike',
    'description': 'Type of product (Bike)'
  },
  'Shoe': {
    'id': 'session_details.shoe_product',
    'defaultMessage': 'Shoe',
    'description': 'Type of product (Shoe)'
  },
  'Footbed': {
    'id': 'session_details.footbed_product',
    'defaultMessage': 'Footbed',
    'description': 'Type of product (Footbed)'
  },
});
