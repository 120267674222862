import {COMPLETED, RECEIVED} from '../types/statuses';
import {getLocationsList} from '../selectors/locations';

export function getEmployeeShopIds(employee) {
  const {shops} = employee;
  return shops.map(shop => shop.id)
}

function filterLocationsList(locations) {

  const locationsList = getLocationsList(locations);

  if (locationsList) {
    const {data, fetchingStatus} = locationsList;
    if (data && fetchingStatus) {
      const filteredLocationData = data.map(location => {
        return {id: location.id, name: location.name}
      });

      return {
        ...locations,
        locationsList: {
          fetchingStatus,
          data: filteredLocationData
        }
      }
    }
  }
}

export function getEmployees(state) {
  const locations = filterLocationsList(state.locations);
  return {...state.employees, ...locations};
}

export function getEmployeesListFetchingStatus(data) {
  const {employeesList} = data;
  const {fetchingStatus} = employeesList;
  return fetchingStatus
}

export function getEmployeeFetchingStatus(data) {
  const {employee} = data;
  const {fetchingStatus} = employee;
  return fetchingStatus
}

export function getCreateStatus(data) {
  const {employee} = data;
  if (employee) {
    const {createStatus} = employee;
    return createStatus
  }
}

export function getUpdateStatus(data) {
  const {employee} = data;
  if (employee) {
    const {updateStatus} = employee;
    return updateStatus
  }
}

export function getDeleteStatus(data) {
  const {employee} = data;
  if (employee) {
    const {deleteStatus} = employee;
    return deleteStatus
  }
}

export function isEmployeesFetched(data) {
  return getEmployeesListFetchingStatus(data) === RECEIVED;
}

export function isUpdated(prevProps, newProps) {
  if (getUpdateStatus(newProps) !== getUpdateStatus(prevProps)) {
    return getUpdateStatus(newProps) === COMPLETED
  }
}

export function isDeleted(prevProps, newProps) {
  if (getDeleteStatus(newProps) !== getDeleteStatus(prevProps)) {
    return getDeleteStatus(newProps) === COMPLETED
  }
}

export function isFetchNeeded(prevProps, newProps) {
  return [isUpdated, isDeleted, isCreated].some(fn => fn(prevProps, newProps) === true);
}

export function isCreated(prevProps, newProps) {
  if (getCreateStatus(newProps) !== getCreateStatus(prevProps)) {
    return getCreateStatus(newProps) === COMPLETED
  }
}