import React, { Component } from 'react';
import { scaleBand, scaleLinear } from 'd3-scale';
import Bars from './Bars/Bars';
import Axis from './Axis/Axis';
import { max } from "d3-array";
import { getDimensions, getMargin } from "../../../utils/chartDimensions";
import { generateTickValues } from '../../../utils/chartHelper';
import componentWithErrorBoundary from '../../../componentsHighOrder/componentWithErrorBoundary';

export default class HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: 400,
        height: 500,
        margin: {
          top: 10,
          bottom: 30,
          right: 0,
          left: 35
        }
      }
    };
  }

  componentDidUpdate() {
    const { parentWidth, parentHeight } = this.props;
    if (this.isWidthAdjustmentNeeded() || this.isHeightAdjustmentNeeded()) {
      this.setState(this.updateDimensions(this.state, parentWidth, parentHeight));
    }
  }

   updateDimensions(stateObject, newWidth, newHeight) {
    return {
      dimensions:
        { ...getDimensions(stateObject), width: newWidth, height: newHeight }
    }
  }

  isWidthAdjustmentNeeded(){
    const { parentWidth } = this.props;
    const { width } = getDimensions(this.state);
    return parentWidth && parentWidth !== width;
  }

  isHeightAdjustmentNeeded(){
    const { parentHeight } = this.props;
    const { height } = getDimensions(this.state);
    return parentHeight && parentHeight !== height;
  }

  adjustedHeight() {
    const { height, margin } = getDimensions(this.state);
    return height - margin.top - margin.bottom
  }

  adjustedWidth() {
    const { width, margin } = getDimensions(this.state);
    return width - margin.left - margin.right;
  }

  xRange() {
    return [ 0, this.adjustedWidth() ]
  }

  xDomain() {
    return [ 0, max(this.props.getQuantities()) ]
  }

  yRange() {
    return [ this.adjustedHeight(), 0 ]
  }

  yDomain() {
    return this.props.getItems()
  }

  calculateYScale() {
    return scaleBand()
      .domain(this.yDomain())
      .range(this.yRange())
      .padding(0.5)
  }

  calculateXScale() {
    return scaleLinear()
      .domain(this.xDomain())
      .range(this.xRange())
  }

  renderXAxis(scale) {
    return (
      componentWithErrorBoundary(
        <Axis
          orientation="Bottom"
          scale={ scale }
          tickValues={ generateTickValues(this.props.getQuantities(), 50) }
          translate={ `translate(${10}, ${this.adjustedHeight()})` }
          tickSize={-this.adjustedHeight()}
        />
      )
    )
  }

  renderYAxis(scale) {
    return (
      componentWithErrorBoundary(
        <Axis
          orientation="Left"
          scale={ scale }
          tickSize={0}
        />
      )
    )
  }

  renderBars(scales) {
    return (
      componentWithErrorBoundary(
        <Bars
          scales={ scales }
          data={ this.props.data }
          margin={ getMargin(this.state) }
          height={ scales.yScale.bandwidth() / 2 }
        />
      )
    )
  }

  render() {
    const { height, width, margin } = getDimensions(this.state);
    const scales = {
      xScale: this.calculateXScale(),
      yScale: this.calculateYScale()
    };

    return (
      <svg
        className='small-bar-chart'
        height={ height }
        width={ width }
      >
        <g transform={ `translate(${margin.left}, ${margin.top})` }>
          { this.renderBars(scales) }
          { this.renderXAxis(scales.xScale) }
          { this.renderYAxis(scales.yScale) }
        </g>
      </svg>
    )
  }
}
