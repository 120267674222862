import React, {Component} from 'react';
import LocationsTable from '../../components/LocationsTable/LocationsTable';
import {getFields} from '../../utils/formHelper'
import {renderResourceModal} from '../../utils/modalHelper';
import {LOCATION_FORM_FIELDS} from '../../types/constants';
import {injectIntl} from 'react-intl';
import messages from './messages';

class ManageLocations extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.state = {
      openModal: false
    };

    this.page = {
      'addLocation': intl.formatMessage(messages.add_cta_label)
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({openModal: true});
  }

  renderModalTrigger() {
    return (
      <div className="open-modal-link-container">
        <button
          className="modal-link"
          onClick={this.openModal}
        >
          {this.page.addLocation}
        </button>
      </div>
    )
  }

  renderAddResourceModal() {
    const {createLocation} = this.props;
    const fields = getFields({
      'fieldsArray': LOCATION_FORM_FIELDS,
      'values': {
        'country': 'US'
      }
    });

    return renderResourceModal(
      {
        displayedText: this.page.addLocation,
        fields,
        submitText: this.page.addLocation,
        submitFunction: createLocation,
        openModal: this.state.openModal,
        topModalClass: true
      }
    )
  }

  renderTableData() {
    const {data} = this.props.locationsList;
    const {updateLocation, deleteLocation} = this.props;
    return data.length < 1 ? '' : (
      <React.Fragment>
        <div className="TableContainer">
          <LocationsTable
            data={data}
            updateResource={updateLocation}
            deleteResource={deleteLocation}
          />
        </div>
        {this.renderModalTrigger()}
      </React.Fragment>
    )
  }

  render() {
    return (
      <section className="bottom">
        <div className="container">
          {this.renderModalTrigger()}
          {this.renderTableData()}
          {this.renderAddResourceModal()}
        </div>
      </section>
    )
  }
}

export default injectIntl(ManageLocations);
