import { defineMessages } from 'react-intl';

export default defineMessages({
  'heading': {
    'id': 'sign_in.heading',
    'defaultMessage': 'Sign In',
    'description': 'Sign In Page title welcome message'
  },
  'intro': {
    'id': 'sign_in.intro',
    'defaultMessage': 'Enter your email and password to get started, or choose "Register Account" to make a new one.',
    'description': 'Sign In Page description message'
  }
});
