export function validatePresence(value) {
    return value.trim() !== '';
}

export function validateText(text) {
    return text.trim().length > 0;
}

export function validateEmail(email) {
    // eslint-disable-next-line
    const pattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return validateText(email) && pattern.test(email);
}

export function validateZip(zip) {
    const pattern = /(^\{5}$)|(^\d{5}$)/;

    return validateText(zip) && pattern.test(zip);
}

// eslint-disable-next-line
export const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export function validatePassword(password) {
    return passwordPattern.test(password);
}
