import React, {Component} from 'react';
import {connect} from 'react-redux';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import Sessions from '../components/Sessions/Sessions';
import * as actionCreators from '../actions/sessions';
import * as sessionsSelectors from '../selectors/sessions';
import {didSearchParamsUpdate} from '../selectors/sessions';
import {RECEIVED, REQUESTING} from '../types/statuses';
import {downloadSessionsCSV} from '../utils/csvExportHelper';
import Intro from '../components/Intro/Intro';
import Main from '../components/Main/Main';
import {injectIntl} from 'react-intl';
import messages from './messages';

class SessionsContainer extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;
    this.intl = props.intl;

    this.state = {
      'searchParams': {},
      'downloadCsv': false
    };

    this.page = {
      'heading': intl.formatMessage(messages.sessions_heading),
      'intro': intl.formatMessage(messages.sessions_intro)
    };

    this.fetchCSVExport = this.fetchCSVExport.bind(this);
    this.fetchSessionsData = this.fetchSessionsData.bind(this);
    this.isSessionDataFetched = this.isSessionDataFetched.bind(this);
    this.updateSearchParams = this.updateSearchParams.bind(this);
  }

  componentWillMount() {
    this.setState({
      'searchParams': this.combinedParams,
    }, () => {
      this.fetchSessionsData();
    });
  }

  componentDidUpdate() {
    if (this.state.downloadCsv && this.isSessionsCSVDataFetched()) {
      const data = JSON.parse(this.props.sessionsCSVData.data);

      downloadSessionsCSV(data);

      this.setState({
        'downloadCsv': false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (didSearchParamsUpdate(this.props, nextProps)) {
      const {fetchSessions} = nextProps;

      this.setState({
        'searchParams': sessionsSelectors.getSessionsSearchParamsData(nextProps),
      }, () => {
        this.updateURL();
        fetchSessions(this.state.searchParams);
      });
    }

    if (this.state.downloadCsv && this.isSessionsCSVDataFetched(nextProps)) {
      this.clearCsvInterval();
    }
  }

  updateURL() {
    let newParams = new URLSearchParams();
    const filterParams = Object.keys(this.state.searchParams).filter(param => {
      const nonFilterParams = ['order', 'page'];

      return !nonFilterParams.includes(param);
    });

    filterParams.forEach(param => {
      let value = this.state.searchParams[param];

      if (Array.isArray(value)) {
        value = value.join(',');
      }
      newParams.append(param, this.state.searchParams[param]);
    });

    window.history.replaceState({}, '', `${window.location.pathname}?${newParams}`);
  }

  fetchSessionsData() {
    const {fetchSessions} = this.props;

    fetchSessions(this.state.searchParams);
  }

  fetchCSVExport() {
    const {fetchSessionsCSVExport} = this.props;

    this.setState({
      'downloadCsv': true
    });

    this.csvInterval = setInterval(() => {
      if (!this.isSessionsCSVDataFetched()) {
        fetchSessionsCSVExport();
      }
    }, 1000);
  }

  renderLoadingMessage() {
    return (
      <div className="box box_alignCenter">
        <p className="txt txt_md text_loading_message text_white">
          {this.intl.formatMessage(messages.session_loading_message)}
        </p>
      </div>
    );
  }
  renderDownloadingMessage() {
    return (
      <div className="box box_alignCenter">
        <p className="txt txt_md text_loading_message text_white">
          {this.intl.formatMessage(messages.csv_downloading_message)}
        </p>
      </div>
    );
  }

  clearCsvInterval() {
    clearInterval(this.csvInterval);
  }

  isSessionsFiltersFetched() {
    return sessionsSelectors.getSessionsFiltersStatus(this.props) === RECEIVED;
  }

  isSessionsCSVDataFetched(props) {
    return sessionsSelectors.getSessionsCSVStatus(props || this.props) === RECEIVED;
  }

  isSessionsCSVDataFetching(props) {
    return sessionsSelectors.getSessionsCSVStatus(props || this.props) === REQUESTING;
  }

  isSessionDataFetched() {
    return sessionsSelectors.getSessionsFetchingStatus(this.props) === RECEIVED;
  }

  updateSearchParams(filterName, filterValue) {
    const {updateSearchParams} = this.props;
    updateSearchParams({[filterName]: filterValue})
  }

  renderSessions(isLoading, isFetchingCSV) {
    if (isLoading) return componentWithErrorBoundary(this.renderLoadingMessage());
    if (isFetchingCSV) return componentWithErrorBoundary(this.renderDownloadingMessage());
    return componentWithErrorBoundary(
      <Sessions
        pagination={sessionsSelectors.getSessionsPagination(this.props)}
        data={sessionsSelectors.getSessionsData(this.props)}
        fetchCSVExport={this.fetchCSVExport}
        fetchSessionsData={this.fetchSessionsData}
        isDataFetched={this.isSessionDataFetched}
        searchParams={this.state.searchParams}
        sessionsFilters={sessionsSelectors.getSessionsFiltersData(this.props)}
        updateSearchParams={this.updateSearchParams}
      />
    )
  }

  renderTopSection() {
    return (
      <section className="top">
        <div className="container">
          <Intro
            header={this.page.heading}
            summary={this.page.intro}
          />
        </div>
      </section>
    )
  }

  render() {
    const isLoading = !(this.isSessionDataFetched() && this.isSessionsFiltersFetched());
    const isFetchingCSV = this.isSessionsCSVDataFetching();

    return (
      <Main className="Sessions" isLoading={isLoading} isFetchingCSV={isFetchingCSV}>
        {this.renderTopSection()}
        {this.renderSessions(isLoading, isFetchingCSV)}
      </Main>
    );
  }

  get combinedParams() {
    const paramsFromProps = sessionsSelectors.getSessionsSearchParamsData(this.props);

    return {...paramsFromProps, ...this.urlParams};
  }

  get urlParams() {
    const filterParams = new URLSearchParams(window.location.search);
    const entries = {};

    for (let pair of filterParams.entries()) {
      const key = pair[0];
      let value = pair[1];
      const isArrayValue = ['ratings', 'employees', 'locations'];

      if (isArrayValue.includes(key)) {
        value = value.split(',');
      }
      entries[key] = value;
    }

    return entries;
  }
}

function mapStateToProps(state) {
  return state.sessions;
}

export default connect(mapStateToProps, actionCreators)(injectIntl(SessionsContainer));
