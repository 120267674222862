/**
 * Fires a function at the end of a burst of events
 *
 * @param  {Function} fn    [Function to be fired]
 * @param  {Number}   delay [The delay in ms until function is fired]
 * @return {Function}       [debounce function]
 */
export default function (fn, delay = 200) {
  let timer = null;

  return function () {
    let context = this,
      args = arguments;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
