import React, {Component} from 'react';
import './SessionsTable.css';
import {getHeadingOrderAttribute, isOrderable} from '../../utils/tableHelper';
import {renderAddTransactionModal} from '../../utils/modalHelper';
import {ORDABLE_TABLE_HEADINGS, SESSION_TABLE_HEADINGS} from '../../types/sessions';
import TableHeadingSort from '../../components/TableHeadingSort/TableHeadingSort'
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import messages from './messages';
import IconNotes from '../../components/Icons/IconNotes';

class SessionsTable extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;

    this.page = {
      'addTransaction': this.intl.formatMessage(messages.add_transaction_cta_label)
    };

    this.state = {
      openModal: false,
      modalData: null,
    };
  }

  openModal(sessionData) {
    this.setState({
      openModal: true,
      modalData: sessionData,
    });
  }

  renderTableHeaders() {
    return SESSION_TABLE_HEADINGS.map((heading, index) => {
      return (
        <th
          key={index}
        >
          <div className='heading-container'>
            <div className='heading'>
              {this.intl.formatMessage(messages[heading])}
            </div>
            {isOrderable(ORDABLE_TABLE_HEADINGS, heading) ? this.renderHeadingSort(heading) : null}
          </div>
        </th>
      )
    })
  }

  renderHeadingSort(heading) {
    const {searchParams, updatePage} = this.props;
    const {order} = searchParams;
    return (
      <TableHeadingSort
        heading={heading}
        handleClick={updatePage}
        headingName={getHeadingOrderAttribute(ORDABLE_TABLE_HEADINGS, heading)}
        order={order}
      />
    )
  }

  renderClient(client, client_id, client_notes) {
    return client_id === null ? (
      <span>
        {this.intl.formatMessage(messages.anonymous)}
      </span>
    ) : (
      <Link className="SessionsTable-client_name" to={`/clients/${client_id}`}>
        {this.renderNotesIcon(client_notes)}
        <span>
          {client}
        </span>
      </Link>
    )
  }

  renderRating(rating) {
    if (isNaN(rating)) {
      return this.intl.formatMessage(messages.no_rating);
    } else {
      return Array(rating).fill().map((r, i) => {
        return (<i key={i} className="rating-star"/>)
      })
    }
  }

  renderNotesIcon(notes) {
    if (notes) {
      return (
        <IconNotes
          className="SessionsTable-icon_notes"
        />
      );
    }
  }

  renderTableBody() {
    const {data} = this.props;

    return data && data.map((d, i) => {
        const {client, date, employee, id, client_id, client_notes, location, rating, sessionId, status} = d;

        return (
          <tr key={i}>
            <td className="session-id">
              <Link to={`/sessions/${id}`}>
                {sessionId}
              </Link>
            </td>
            <td>
              {this.intl.formatDate(date, {'month': '2-digit', 'day': '2-digit', 'year': '2-digit'})}
            </td>
            <td className="Table-cell Table-cell_constrained">
              {this.renderClient(client, client_id, client_notes)}
            </td>
            <td>
              {this.renderRating(rating)}
            </td>
            <td>{employee}</td>
            <td>{location}</td>
            <td className={`status ${status}`}>
              {this.intl.formatMessage(messages[status])}
            </td>
            <td>
              <div className="modify-resource-options">
                <button
                  className="modal-link"
                  onClick={() => {
                    this.openModal(d);
                  }}
                >
                  {this.page.addTransaction}
                </button>
              </div>
            </td>
          </tr>
        )
      }
    )
  }

  render() {
    return (
      <div className="SessionsTable">
        <div className="TableContainer">
          <table className="Table">
            <thead>
            <tr>
              {this.renderTableHeaders()}
            </tr>
            </thead>
            <tbody>
            {this.renderTableBody()}
            </tbody>
          </table>
        </div>
        {renderAddTransactionModal({
          openModal: this.state.openModal,
          data: this.state.modalData,
        })}
      </div>
    )
  }
}

export default injectIntl(SessionsTable);
