import { defineMessages } from 'react-intl';

export default defineMessages({
    // common
    submit_label: {
        id: 'submit_label',
        defaultMessage: 'Submit',
        description: 'Submit button label',
    },
    sign_in_label: {
        id: 'sign_in_label',
        defaultMessage: 'Sign In',
        description: 'Sign in button label',
    },
    cancel_label: {
        id: 'cancel_label',
        defaultMessage: 'Cancel',
        description: 'Cancel button label',
    },
    email_label: {
        id: 'email_label',
        defaultMessage: 'Email',
        description: 'Email label for forms',
    },
    password_label: {
        id: 'password_label',
        defaultMessage: 'Password',
        description: 'Password label for forms',
    },
    email_format_error: {
        id: 'email_format_error',
        defaultMessage: 'Invalid email format.',
        description: 'Invalid email format error message',
    },
    password_invalid_error: {
        id: 'password_invalid_error',
        defaultMessage: 'Invalid password.',
        description: 'Invalid password error message',
    },

    email_required_label: {
        id: 'email_required_label',
        defaultMessage: 'Email is required.',
        description: 'Email required label message',
    },

    first_name_required_label: {
        id: 'first_name_required_label',
        defaultMessage: 'First name is required.',
        description: 'First name required label message',
    },

    last_name_required_label: {
        id: 'last_name_required_label',
        defaultMessage: 'Last name is required.',
        description: 'Last name required label message',
    },

    verify_account_header: {
        id: 'verify_account.heading',
        defaultMessage: 'Account Verification',
        description: 'Account verification heading message',
    },

    // create account
    create_account_heading: {
        id: 'create_account.heading',
        defaultMessage: 'Create Account',
        description: 'Create account page heading and title',
    },
    create_account_intro: {
        id: 'create_account.intro',
        defaultMessage:
            'Enter an email and password to create a Ret\u00FCl Passport account.',
        description: 'Create account page heading intro',
    },
    first_name_label: {
        id: 'create_account.first_name_label',
        defaultMessage: 'First Name',
        description: 'Create account page first name field label',
    },
    last_name_label: {
        id: 'create_account.last_name_label',
        defaultMessage: 'Last Name',
        description: 'Create account page last name field label',
    },
    accept_tac_label: {
        id: 'create_account.accept_tac_label',
        defaultMessage: ' I have read and agree to the terms and conditions',
        description:
            'Create account page accept terms and conditions checkbox label',
    },
    accept_tou_label: {
        id: 'create_account.accept_tou_label',
        defaultMessage: 'I have read and agree to the Terms of Use',
        description: 'Create account page accept terms of use checkbox label',
    },
    marketing_optin_label: {
        id: 'create_account.marketing_optin_label',
        defaultMessage: 'Sign me up to receive email offers and updates',
        description: 'Create account page optin to marketing checkbox label',
    },

    // forgot password
    forgot_password_heading: {
        id: 'forgot_password.heading',
        defaultMessage: 'Forgot Password',
        description: 'Forgot Password page heading and title',
    },
    forgot_password_intro: {
        id: 'forgot_password.intro',
        defaultMessage:
            'Forgot your password? Enter your email and we’ll send you a link to reset.',
        description: 'Forgot password page heading intro',
    },

    reset_email_sent: {
        id: 'forgot_password.reset_email_sent',
        defaultMessage: 'A password reset email has been sent to {email}',
        description: 'Forgot password page reset email sent text',
    },

    // reset password
    reset_password_heading: {
        id: 'reset_password.heading',
        defaultMessage: 'Reset Password',
        description: 'Reset Password Page title welcome message',
    },
    reset_password_intro: {
        id: 'reset_password.intro',
        defaultMessage: 'Fill out the form to reset your password.',
        description: 'Reset Password Page description message',
    },
    reset_password_request: {
        id: 'reset_password.request_reset_password',
        defaultMessage: 'Please request a new password reset',
        description: 'Request a new password reset email text',
    },

    reset_password_request_success: {
        id: 'reset_password.request_reset_password_success',
        defaultMessage: 'Your password has been set. Please sign in.',
        description: 'Requesting a new password reset success text',
    },

    reset_password_request_failed: {
        id: 'reset_password.request_reset_password_failed',
        defaultMessage:
            'Something went wrong. Please request a new password reset.',
        description: 'Requesting a new password reset failed text',
    },

    // sign in
    sign_in_heading: {
        id: 'sign_in.heading',
        defaultMessage: 'Sign In',
        description: 'Sign in page heading and title',
    },
    sign_in_intro: {
        id: 'sign_in.intro',
        defaultMessage:
            'Welcome back to Ret\u00FCl Passport. Enter your email and password and to get started.',
        description: 'Sign in page heading intro',
    },
    sign_in_error: {
        id: 'sign_in.sign_in_error',
        defaultMessage:
            'We were unable to retrieve your User Data. Please contact Ret\u00FCl Support for help.',
        description: 'Login error message',
    },
    sign_in_button_label: {
        id: 'sign_in.sign_in_label',
        defaultMessage: 'Sign In',
        description: 'Sign in page link text',
    },
    forgot_password_label: {
        id: 'sign_in.forgot_password_label',
        defaultMessage: 'Forgot Password',
        description: 'Forgot password link text',
    },
    create_account_label: {
        id: 'sign_in.create_account_label',
        defaultMessage: 'Create Account',
        description: 'Create Account link text',
    },
    contact_label: {
        id: 'sign_in.contact_label',
        defaultMessage: 'Contact Ret\u00FCl',
        description: 'Contact link text',
    },

    EMAIL_VERIFIED: {
        id: 'verify.email_verified',
        defaultMessage: 'Your account has been verified! Please go sign in.',
        description: 'Account verified text',
    },

    verify: {
        id: 'verify.run_verify',
        defaultMessage: 'Verify',
        description: 'Account verification action text',
    },

    // errors
    // Sign in errors
    EMAIL_NOT_VERIFIED: {
        id: 'sign_in.email_not_verified_error',
        defaultMessage:
            'Email has not been verified and is required for login. A confirmation email has been resent to this user.',
        description: 'Email not verified error text',
    },

    USER_NOT_ACCEPTED_TERMS: {
        id: 'sign_in.user_not_accepted_terms',
        defaultMessage:
            'Terms and conditions have not been accepted and are required for login. Please read and accept terms, thank you.',
        description: 'Terms and conditions error text',
    },

    INVALID_USERNAME_OR_PASSWORD: {
        id: 'sign_in.invalid_username_or_password',
        defaultMessage: 'Invalid username or password was submitted.',
        description: 'Invalid username or password error text',
    },

    // Create errors
    USER_ALREADY_EXISTS: {
        id: 'create.user_already_exists',
        defaultMessage: 'There was an issue creating the account.',
        description: 'User already exists error text',
    },

    REQUIRED_FIELD: {
        id: 'create.required_field',
        defaultMessage: '{field} is required.',
        description: 'Missing field error text',
    },

    PASSWORD_FAILED_STATIC_COMPLEXITY: {
        id: 'create.password_too_simple',
        defaultMessage:
            'Passwords must be at least 8 characters long, include upper- and lower-case letters, and include a number',
        description: 'Password too simple error text',
    },

    // Create success
    USER_CREATED: {
        id: 'create.user_created',
        defaultMessage: 'Account created.',
        description: 'User account created text',
    },
    USER_CREATED_VERIFY_INSTRUCTIONS: {
        id: 'create.user_created_verify_instructions',
        defaultMessage:
            'Please follow the instructions in the email we just sent you.',
        description: 'User account created verify instructions text',
    },
});
