import {TOKEN_REFRESH_SUCCESSFUL, UNAUTHORIZED_STATUS} from '../types/apiClient';
import {fetchTokenRefresh, isAuthenticated, isTokenExpired} from '../api/tokens';

async function dispatchOriginalAction(store, next, action) {
  let result;
  result = await next(action);

  if (result === UNAUTHORIZED_STATUS) {
    const tokenRefreshResult = await fetchTokenRefresh();
    if (tokenRefreshResult === TOKEN_REFRESH_SUCCESSFUL) {
      result = await next(action);
    }
  }
  return result;
}

export function tokenRefresh(store) {
  return function tokenRefreshWrapper(next) {
    return async function refreshIfNeeded(action) {
      if (isAuthenticated() && isTokenExpired()) {
        try {
          const result = await fetchTokenRefresh();

          if (result === TOKEN_REFRESH_SUCCESSFUL) {
            return await dispatchOriginalAction(store, next, action)
          }
        } catch (error) {
          // console.error(error)
        }
      } else {
        return await dispatchOriginalAction(store, next, action)
      }
    }
  }
}
