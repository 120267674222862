import * as apiTypes from '../types/apiClient';
import { buildUrl } from '../utils/urlHelper';
import {
    isAccepted,
    isBadStatus,
    isUnauthorizedStatus,
    isValidStatus,
} from '../api/responses';

export function retryDelay() {
    const FETCH_TIMEOUT = 30000;

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('resolved');
        }, FETCH_TIMEOUT);
    });
}

export async function apiGet(requestData) {
    let response = await fetchRequest(requestData, apiTypes.GET);

    if (response === apiTypes.ACCEPTED) {
        do {
            response = await fetchRequest(requestData, apiTypes.GET);
        } while (response === apiTypes.ACCEPTED);
    }

    return response;
}

export async function apiPut(requestData) {
    return fetchRequest(requestData, apiTypes.PUT);
}

export async function apiOptions(requestData) {
    return fetchRequest(requestData, apiTypes.OPTIONS);
}

export async function apiCreate(requestData) {
    return fetchRequest(requestData, apiTypes.POST);
}

export async function apiDelete(requestData) {
    return fetchRequest(requestData, apiTypes.DELETE);
}

export function apiFetchConfig(config) {
    const { method, customConfig } = config;
    const { customHeaders } = customConfig || {};
    const defaultHeaders = {
        [apiTypes.ACCEPT]: apiTypes.APPLICATION_JSON,
        [apiTypes.AUTHORIZATION]: `Bearer ${localStorage.getItem(
            apiTypes.ACCESS_TOKEN
        )}`,
    };
    const headers = { ...defaultHeaders, ...customHeaders };

    return {
        headers,
        method,
        ...customConfig,
    };
}

function fetchSetup(requestData, method) {
    const { customConfig, searchParams } = requestData;
    const { url } = customConfig;
    const getUrl = buildUrl(url, searchParams);
    const config = { customConfig, method };
    const requestConfig = apiFetchConfig(config);

    return [getUrl, requestConfig];
}

export async function sendApiRequest(requestData, method) {
    const [url, requestConfig] = fetchSetup(requestData, method);

    try {
        const response = await fetch(url, requestConfig);
        return await handleFetchResponse(response);
    } catch (err) {
        console.error(err);
        return err;
    }
}

export async function fetchRequest(requestData, method) {
    const { customConfig } = requestData;
    const { dispatch, receiveCallback, parser, failCallback } = customConfig;

    try {
        const result = await sendApiRequest(requestData, method);
        if (result.status === apiTypes.ACCEPTED) {
            return result.response;
        }

        // CDJ: used to be that 401 would return the response... what about dispatching fail?
        // if (result.status === apiTypes.UNAUTHORIZED_STATUS) {
        //     return result.response;
        // }

        if (
            result.status === apiTypes.BAD_STATUS ||
            result.status === apiTypes.UNAUTHORIZED_STATUS
        ) {
            const data = await result.response.json();
            return dispatch(failCallback(data));
        } else {
            return dispatchResult(result, dispatch, receiveCallback, parser);
        }
    } catch (err) {
        console.error('ERROR', err);
        return err;
    }
}

export async function handleFetchResponse(response) {
    try {
        switch (true) {
            case isAccepted(response):
                return {
                    status: apiTypes.ACCEPTED,
                    response,
                };
            case isValidStatus(response):
                return await Promise.resolve(response);
            case isUnauthorizedStatus(response):
                return {
                    status: apiTypes.UNAUTHORIZED_STATUS,
                    response,
                };
            case isBadStatus(response):
                return {
                    status: apiTypes.BAD_STATUS,
                    response,
                };
            default:
                return response;
        }
    } catch (error) {
        return error;
    }
}

async function dispatchResult(result, dispatch, receiveCallback, parser) {
    if (result) {
        try {
            if (parser === apiTypes.JSON_PARSER) {
                const data = await result.json();
                return dispatch(receiveCallback(data));
            }

            if (parser === apiTypes.TEXT_PARSER) {
                const data = await result.text();
                return dispatch(receiveCallback(data));
            }

            return await dispatch(receiveCallback(result));
        } catch (error) {
            console.error('dispatchResult Error', error);
            return error;
        }
    }
}
