import { Component } from 'react';

export default class Form extends Component {
  constructor() {
    super();
    this.fields = {};

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [ event.target.name ]: { value: event.target.value } });
  }

  hasErrors() {
    let errors = [];

    for (var key in this.fields) {
      errors.push(this.fields[key]);
    }

    errors = errors.filter(field => {
      return !field.state.isValid || (field.state.value.trim() === '' && field.props.required);
    });

    return errors.length > 0;
  }
}
