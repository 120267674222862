import { defineMessages } from 'react-intl';

export default defineMessages({
  'heading': {
    'id': 'landing.heading',
    'defaultMessage': 'Welcome to Retailer Portal',
    'description': 'Landing Page title welcome message'
  },
  'intro': {
    'id': 'landing.intro',
    'defaultMessage': 'Access data, sessions and client information captured by Ret\u00FCl technology.',
    'description': 'Landing Page description message'
  },
  "sign_in_or_create_account_label": {
    "id": "landing.sign_in_or_create_account_label",
    "defaultMessage": "Sign In / Create Account",
    "decription": "Sign in or Create Account button label"
  }
});
