/* eslint-disable */
export const localeStrings = {
    pl: {
        'add_transaction.back_to_sessions_label': 'Powrót do sesji',
        'add_transaction.bike_label': 'Rower',
        'add_transaction.commute_comfort_type': 'Dojazdy-komfort',
        'add_transaction.commute_sport_type': 'Dojazdy-sport',
        'add_transaction.complete_transaction_cta_label': 'Zakończ transakcję',
        'add_transaction.confirm_label': 'Potwierdź',
        'add_transaction.cyclocross_type': 'Przełaj',
        'add_transaction.family_label': 'Rodzina',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'Wkładka',
        'add_transaction.heading': 'Dodaj transakcję do {data}',
        'add_transaction.model_label': 'Model',
        'add_transaction.mountain_type': 'Górskie',
        'add_transaction.mtb_trail_type': 'MTB (trail)',
        'add_transaction.mtb_xc_type': 'MTB (cross-country)',
        'add_transaction.product_label': 'Produkt',
        'add_transaction.ratings_message':
            'Po zakończeniu tej transakcji {data} otrzyma wiadomość e-mail, aby ocenić ten produkt. Będziesz mógł zobaczyć tę ocenę na stronie z listą sesji i w szczegółach sesji.',
        'add_transaction.road_type': 'Szosowe',
        'add_transaction.saddle_label': 'Siodło',
        'add_transaction.shoe_label': 'But',
        'add_transaction.size_label': 'Rozmiar',
        'add_transaction.transaction_complete_message':
            'Transakcja zakończona.',
        'add_transaction.triathlon_type': 'Triathlon',
        'add_transaction.tt_tri_type': 'TT/Tri',
        'add_transaction.type_label': 'rodzaj',
        'analytics.loading_message':
            'Kompilujemy Twoje dane, może to potrwać kilka minut',
        'analytics_filters.analytics_by': 'ANALITYKA PRZEZ',
        'analytics_filters.filter_by': 'FILTRUJ WEDŁUG',
        'analytics_filters.products': 'PRODUKTY',
        'analytics_filters.sessions': 'SESJE',
        'charts.bike_label_products': 'Rower',
        'charts.bike_label_sessions': 'Rower',
        'charts.commute_comfort_label': 'Dojazdy-komfort',
        'charts.commute_sport_label': 'Dojazdy-sport',
        'charts.cyclocross_label': 'Przełaj',
        'charts.female_label': 'Kobieta',
        'charts.friday_label': 'piątek',
        'charts.high_arch_label': 'wysoki',
        'charts.low_arch_label': 'niski',
        'charts.male_label': 'Mężczyzna',
        'charts.medium_arch_label': 'Średni',
        'charts.monday_label': 'poniedziałek',
        'charts.mtb_trail_label': 'mtb-trail',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'Szosowe',
        'charts.saddle_label_products': 'Siodło',
        'charts.saddle_label_sessions': 'Siodło',
        'charts.saturday_label': 'sobota',
        'charts.sessions_by_bike_type_label': 'Sesje według typu rowerów',
        'charts.sessions_by_day_of_week_label': 'Sesje według dnia tygodnia',
        'charts.sessions_by_employee_label': 'Sesje według pracownika',
        'charts.sessions_by_foot_arch_label': 'Sesje według łuku stopy',
        'charts.sessions_by_gender_label': 'Sesje według płci',
        'charts.sessions_by_product_label': 'Sesje według produktu',
        'charts.sessions_by_saddle_height_label':
            'Sesje według wysokości siodła',
        'charts.sessions_by_saddle_size_label': 'Sesje według rozmiaru siodła',
        'charts.sessions_by_shirt_bike_size_label':
            'Sesje według rozmiaru roweru (T-Shirt)',
        'charts.sessions_by_shoe_size_label': 'Sesje według rozmiaru obuwia',
        'charts.sessions_by_sitbone_width_label':
            'Sesje według szerokości kości kulszowych',
        'charts.sessions_by_standard_bike_size_label':
            'Sesje według rozmiaru roweru (standard)',
        'charts.sessions_over_time_label': 'Sesje w czasie',
        'charts.shoe_label_products': 'But',
        'charts.shoe_label_sessions': 'But',
        'charts.sunday_label': 'niedziela',
        'charts.thursday_label': 'czwartek',
        'charts.top_ten_session_label': 'Dziesięć najlepszych dat sesji',
        'charts.top_transactions_label': 'Daty najlepszych transakcji',
        'charts.triathlon_label': 'Triathlon',
        'charts.tuesday_label': 'wtorek',
        'charts.wednesday_label': 'środa',
        'client.all_sessions_link': 'Wszystkie sesje',
        'client.bike_progress': 'Rower',
        'client.client_progress_heading': 'Postęp klienta',
        'client.client_sessions_heading': 'Ostatnia sesja klienta',
        'client.email': 'Adres e-mail klienta',
        'client.name': 'Imię klienta',
        'client.not_applicable': 'Nie dotyczy',
        'client.notes_label': 'Uwagi',
        'client.progress_types_complete': 'Zakończono',
        'client.saddle_progress': 'Siodło',
        'client.save_cta': 'Zapisz',
        'client.shoe_progress': 'But',
        'commute-comfort': 'Dojazdy-komfort',
        'commute-sport': 'Dojazdy-sport',
        'country.ad': 'Andora',
        'country.ae': 'Zjednoczone Emiraty Arabskie',
        'country.af': 'Afganistan',
        'country.ag': 'Antigua i Barbuda',
        'country.ai': 'Anguilla',
        'country.al': 'Albania',
        'country.am': 'Armenia',
        'country.ao': 'Angola',
        'country.aq': 'Antarktyda',
        'country.ar': 'Argentyna',
        'country.as': 'Samoa Amerykańskie',
        'country.at': 'Austria',
        'country.au': 'Australia',
        'country.aw': 'Aruba',
        'country.ax': 'Wyspy Alandzkie',
        'country.az': 'Azerbejdżan',
        'country.ba': 'Bośnia i Hercegowina',
        'country.bb': 'Barbados',
        'country.bd': 'Bangladesz',
        'country.be': 'Belgia',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bułgaria',
        'country.bh': 'Bahrajn',
        'country.bi': 'Burundi',
        'country.bj': 'Benin',
        'country.bl': 'Święty Bartłomiej',
        'country.bm': 'Bermudy',
        'country.bn': 'Brunei',
        'country.bo': 'Boliwia',
        'country.bq': 'Bonaire, Sint Eustatius i Saba',
        'country.br': 'Brazylia',
        'country.bs': 'Bahamy',
        'country.bt': 'Bhutan',
        'country.bv': 'Wyspa Bouveta',
        'country.bw': 'Botswana',
        'country.by': 'Białoruś',
        'country.bz': 'Belize',
        'country.ca': 'Kanada',
        'country.cc': 'Wyspy Kokosowe (Keelinga)',
        'country.cd': 'Kongo (Demokratyczna Republika Konga)',
        'country.cf': 'Republika Środkowej Afryki',
        'country.cg': 'Kongo',
        'country.ch': 'Szwajcaria',
        'country.ci': 'Wybrzeże Kości Słoniowej',
        'country.ck': 'Wyspy Cooka',
        'country.cl': 'Chile',
        'country.cm': 'Kamerun',
        'country.cn': 'Chiny',
        'country.co': 'Kolumbia',
        'country.cr': 'Kostaryka',
        'country.cu': 'Kuba',
        'country.cv': 'Cabo Verde',
        'country.cw': 'Curacao',
        'country.cx': 'Wyspa Bożego Narodzenia',
        'country.cy': 'Cypr',
        'country.cz': 'Czechy',
        'country.de': 'Niemcy',
        'country.dj': 'Dżibuti',
        'country.dk': 'Dania',
        'country.dm': 'Dominika',
        'country.do': 'Republika Dominikany',
        'country.dz': 'Algieria',
        'country.ec': 'Ekwador',
        'country.ee': 'Estonia',
        'country.eg': 'Egipt',
        'country.eh': 'Sahara Zachodnia',
        'country.er': 'Erytrea',
        'country.es': 'Hiszpania',
        'country.et': 'Etiopia',
        'country.fi': 'Finlandia',
        'country.fj': 'Fidżi',
        'country.fk': 'Falklandy [Malwiny]',
        'country.fm': 'Mikronezja (Federalne Stany Mikronezji)',
        'country.fo': 'Wyspy Owcze',
        'country.fr': 'Francja',
        'country.ga': 'Gabon',
        'country.gb': 'Wielka Brytania',
        'country.gd': 'Grenada',
        'country.ge': 'Gruzja',
        'country.gf': 'Gujana Francuska',
        'country.gg': 'Guernsey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibraltar',
        'country.gl': 'Grenlandia',
        'country.gm': 'Gambia',
        'country.gn': 'Gwinea',
        'country.gp': 'Gwadelupa',
        'country.gq': 'Gwinea Równikowa',
        'country.gr': 'Grecja',
        'country.gs': 'Południowa Gruzja i Południowe Wyspy Sandwich ',
        'country.gt': 'Gwatemala',
        'country.gu': 'Guam',
        'country.gw': 'Gwinea Bissau',
        'country.gy': 'Gujana',
        'country.hk': 'Hongkong',
        'country.hm': 'Wyspa Heard i Wyspy McDonalda',
        'country.hn': 'Honduras',
        'country.hr': 'Chorwacja',
        'country.ht': 'Haiti',
        'country.hu': 'Węgry',
        'country.id': 'Indonezja',
        'country.ie': 'Irlandia',
        'country.il': 'Izrael',
        'country.im': 'Wyspa Man',
        'country.in': 'Indie',
        'country.io': 'Brytyjskie Terytorium Oceanu Indyjskiego',
        'country.iq': 'Irak',
        'country.ir': 'Iran',
        'country.is': 'Islandia',
        'country.it': 'Włochy',
        'country.je': 'Jersey',
        'country.jm': 'Jamajka',
        'country.jo': 'Jordania',
        'country.jp': 'Japonia',
        'country.ke': 'Kenia',
        'country.kg': 'Kirgistan',
        'country.kh': 'Kambodża',
        'country.ki': 'Kiribati',
        'country.km': 'Komory',
        'country.kn': 'Saint Kitts i Nevis',
        'country.kp': 'Korea Północna',
        'country.kr': 'Korea Południowa',
        'country.kw': 'Kuwejt',
        'country.ky': 'Kajmany',
        'country.kz': 'Kazachstan',
        'country.la': 'Laos',
        'country.lb': 'Liban',
        'country.lc': 'święta Lucia',
        'country.li': 'Liechtenstein',
        'country.lk': 'Sri Lanka',
        'country.lr': 'Liberia',
        'country.ls': 'Lesotho',
        'country.lt': 'Litwa',
        'country.lu': 'Luksemburg',
        'country.lv': 'Łotwa',
        'country.ly': 'Libia',
        'country.ma': 'Maroko',
        'country.mc': 'Monaco',
        'country.md': 'Mołdawia',
        'country.me': 'Czarnogóra',
        'country.mf': 'Saint Martin (część francuska)',
        'country.mg': 'Madagaskar',
        'country.mh': 'Wyspy Marshalla',
        'country.mk': 'Macedonia',
        'country.ml': 'Mali',
        'country.mm': 'Myanmar',
        'country.mn': 'Mongolia',
        'country.mo': 'Makao',
        'country.mp': 'Mariany Północne',
        'country.mq': 'Martynika',
        'country.mr': 'Mauretania',
        'country.ms': 'Montserrat',
        'country.mt': 'Malta',
        'country.mu': 'Mauritius',
        'country.mv': 'Malediwy',
        'country.mw': 'Malawi',
        'country.mx': 'Meksyk',
        'country.my': 'Malezja',
        'country.mz': 'Mozambik',
        'country.na': 'Namibia',
        'country.nc': 'Nowa Kaledonia',
        'country.ne': 'Niger',
        'country.nf': 'Wyspa Norfolk',
        'country.ng': 'Nigeria',
        'country.ni': 'Nikaragua',
        'country.nl': 'Holandia',
        'country.no': 'Norwegia',
        'country.np': 'Nepal',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'Nowa Zelandia',
        'country.om': 'Oman',
        'country.pa': 'Panama',
        'country.pe': 'Peru',
        'country.pf': 'Polinezja Francuska',
        'country.pg': 'Papua Nowa Gwinea',
        'country.ph': 'Filipiny',
        'country.pk': 'Pakistan',
        'country.pl': 'Polska',
        'country.pm': 'Saint Pierre i Miquelon',
        'country.pn': 'Pitcairn',
        'country.pr': 'Puerto Rico',
        'country.ps': 'Palestyna, państwo',
        'country.pt': 'Portugalia',
        'country.pw': 'Palau',
        'country.py': 'Paragwaj',
        'country.qa': 'Katar',
        'country.re': 'Reunion',
        'country.ro': 'Rumunia',
        'country.rs': 'Serbia',
        'country.ru': 'Rosja',
        'country.rw': 'Rwanda',
        'country.sa': 'Arabia Saudyjska',
        'country.sb': 'Wyspy Salomona',
        'country.sc': 'Seszele',
        'country.sd': 'Sudan',
        'country.se': 'Szwecja',
        'country.sg': 'Singapur',
        'country.sh':
            'Wyspy Świętej Heleny, wyspa Wniebowstąpienia i Tristan da Cunha',
        'country.si': 'Słowenia',
        'country.sj': 'Svalbard i Jan Mayen',
        'country.sk': 'Słowacja',
        'country.sl': 'Sierra Leone',
        'country.sm': 'San Marino',
        'country.sn': 'Senegal',
        'country.so': 'Somalia',
        'country.sr': 'Surinam',
        'country.ss': 'Południowy Sudan',
        'country.st': 'Wyspy Świętego Tomasza i Książęca',
        'country.sv': 'Salwador',
        'country.sx': 'Sint Maarten (część holenderska)',
        'country.sy': 'Syria',
        'country.sz': 'Suazi',
        'country.tc': 'Wyspy Turks i Caicos',
        'country.td': 'Czad',
        'country.tf': 'Francuskie Terytoria Południowe',
        'country.tg': 'Togo',
        'country.th': 'Tajlandia',
        'country.tj': 'Tadżykistan',
        'country.tk': 'Tokelau',
        'country.tl': 'Timor-Leste',
        'country.tm': 'Turkmenistan',
        'country.tn': 'Tunezja',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trynidad i Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Tajwan',
        'country.tz': 'Tanzania',
        'country.ua': 'Ukraina',
        'country.ug': 'Uganda',
        'country.um':
            'Stany Zjednoczone Ameryki Północnej niewielkie wyspy zewnętrzne',
        'country.us': 'Stany Zjednoczone Ameryki',
        'country.uy': 'Urugwaj',
        'country.uz': 'Uzbekistan',
        'country.va': 'Stolica Apostolska',
        'country.vc': 'Saint Vincent i Grenadyny',
        'country.ve': 'Wenezuela',
        'country.vg': 'Wyspy Dziewicze (brytyjskie)',
        'country.vi': 'Wyspy Dziewicze (USA)',
        'country.vn': 'Wietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis i Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Jemen',
        'country.yt': 'Majotta',
        'country.za': 'Republika Południowej Afryki',
        'country.zm': 'Zambia',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Przełaj',
        'date.all_time': 'DATA: CAŁY CZAS',
        'date.last_month': 'DATA: ZESZŁY MIESIĄC',
        'date.last_week': 'DATA: ZESZŁY TYDZIEŃ',
        'date.last_year': 'DATA: ZESZŁY ROK',
        'employees.add_cta_label': 'Dodaj pracownika',
        'employees.delete_cta_label': 'Usuń pracownika',
        'employees.employee_id_label': 'Identyfikator pracownika',
        'employees.heading': 'Pracownicy',
        'employees.intro':
            'Harmonogram wszystkich ostatnich pracowników  technologi Retül.',
        'employees.update_cta_label': 'Zaktualizuj pracownika',
        'filters.all_label': 'WSZYSTKIE',
        'filters.client_all': 'Wszystkie',
        'filters.client_anonymous': 'Anonimowy',
        'filters.client_identified': 'Zidentyfikowany',
        'filters.client_label': 'Rodzaj klienta',
        'filters.employees_label': 'Pracownicy',
        'filters.filters_heading': 'Filtry',
        'filters.hide_filters': 'Pokaż filtry',
        'filters.locations_label': 'Lokalizacje',
        'filters.rating_all': 'WSZYSTKIE',
        'filters.rating_label': 'Ocena',
        'filters.rating_none': 'ŻADEN',
        'filters.session_all': 'Wszystkie',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Rodzaj sesji',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Pokaż filtry',
        'filters.status_all': 'Wszystko',
        'filters.status_closed': 'Zamknięty',
        'filters.status_label': 'Status',
        'filters.status_open': 'Otwarty',
        'footer.privacy_link_label': 'Polityka prywatności',
        'footer.terms_link_label': 'Warunki użytkowania',
        'form.address_1_field_error': 'Adres jest wymagany.',
        'form.address_1_field_label': 'Adres',
        'form.city_field_error': 'Miasto jest wymagane.',
        'form.city_field_label': 'Miasto',
        'form.country_field_error': 'Kraj jest wymagany.',
        'form.country_field_label': 'Kraj',
        'form.delete_field_confirm_message':
            'Czy na pewno chcesz usunąć {data} ?',
        'form.delete_field_label': 'Usuń',
        'form.edit_field_label': 'Edytuj',
        'form.email_field_error':
            'Adres e-mail jest wymagany i musi być w prawidłowym formacie.',
        'form.email_field_label': 'Adres e-mail',
        'form.name_field_error': 'Nazwa jest wymagana.',
        'form.name_field_label': 'Nazwa',
        'form.shop_field_label': 'Sklep',
        'form.shops_field_label': 'Sklepy',
        'form.state_field_error': 'Stan/Prowincja jest wymagana.',
        'form.state_field_label': 'Stan/Prowincja',
        'form.zip_field_error': 'Wymagany jest kod pocztowy',
        'form.zip_field_label': 'Kod pocztowy',
        'gender.all': 'PŁEĆ: WSZYSTKIE',
        'gender.female': 'PŁEĆ: KOBIETA',
        'gender.male': 'PŁEĆ: MĘŻCZYZNA',
        'landing.heading': 'Witamy w Retailer Portal',
        'landing.intro':
            'Dostęp do danych sesji i klienta informacji zebranych dzięki technologii Retül.',
        'landing.sign_in_or_create_account_label': 'Zaloguj się / Utwórz konto',
        'locale.label_cs': 'Czeski',
        'locale.label_de': 'Niemiecki',
        'locale.label_en': 'Angielski',
        'locale.label_en-us': 'Angielski (USA)',
        'locale.label_es': 'Hiszpański',
        'locale.label_fr': 'Francuski',
        'locale.label_it': 'Włoski',
        'locale.label_ja': 'Japoński',
        'locale.label_ko': 'Koreański',
        'locale.label_nl': 'Niderlandzki',
        'locale.label_pl': 'Polski',
        'locale.label_pt': 'Portugalski',
        'locale.label_pt-br': 'Portugalski (Brazylia)',
        'locale.label_zh-cn': 'Chiński uproszczony',
        'locale.label_zh-tw': 'Chiński tradycyjny',
        'locale.locale_picker_cancel_label': 'Anuluj',
        'locale.locale_picker_heading': 'Wybierz swój język.',
        'locale.locale_picker_save_label': 'Zapisz',
        'locations.add_cta_label': 'Dodaj lokalizację',
        'locations.delete_cta_label': 'Usuń lokalizację',
        'locations.empty':
            'Nie dodano żadnych lokalizacji, aby użyć Retül Apps proszę dodać lokalizację.',
        'locations.empty_cta_label': 'Zarządzaj lokalizacjami',
        'locations.heading': 'Zarządzaj lokalizacjami',
        'locations.intro':
            'Dodawaj, edytuj i usuwaj lokalizacje za pomocą technologii Retül.',
        'locations.location_id_label': 'Identyfikator lokalizacji',
        'locations.update_cta_label': 'Zaktualizuj lokalizację',
        'metrics.closed_session_tooltip':
            'Sesje zamknięte oznaczają sesję podczas których zarejestrowano transakcję. Jeśli klient dokonał lub nie dokonał zakupu, {closingSessions} pomaga śledzić sprzedaż dzięki technologii Retül.',
        'metrics.closed_sessions': 'Zamknięte sesje',
        'metrics.closing_session_link': 'zamknięcie sesji',
        'metrics.total_sessions': 'Wszystkie sesje',
        'metrics.unique_client_emails': 'Unikatowy email klienta',
        'metrics.unique_emails_tooltip':
            'Liczba ta przedstawia sesje, które zostały przeprowadzone z poszczególnymi klientami. Unikatowi klienci są określani przez swój adres e-mail.',
        'mtb-trail': 'MTB (trail)',
        'mtb-xc': 'MTB (cross-country)',
        'nav.analytics': 'Analityka',
        'nav.greeting_label': 'Cześć, {data}',
        'nav.manage_employees_link_label': 'Zarządzaj pracownikami',
        'nav.manage_locations_link_label': 'Zarządzaj lokalizacjami',
        'nav.sessions': 'Sesje',
        'nav.sign_out_link_label': 'Wyloguj się',
        'pagination.next': 'Dalej',
        'pagination.page': 'Strona',
        'pagination.previous': 'Poprzedna',
        road: 'Szosowe',
        'session_details.add_transaction_cta_label': 'Dodaj transakcję',
        'session_details.all_sessions_link': 'Wszystkie sesje',
        'session_details.bike_product': 'Rower',
        'session_details.client': 'Klient',
        'session_details.client_review_label': 'Komentarz Klienta ',
        'session_details.complete_session_cta_label': 'Oznacz jako zamknięty',
        'session_details.complete_session_message':
            'Zamknij tę sesję, dodając wszystkie transakcje wykonane przez klienta, lub',
        'session_details.complete_status': 'Zamknięte',
        'session_details.created_on': 'Data utworzenia: {data}',
        'session_details.delete_link': 'Usuń',
        'session_details.employee': 'Pracownik: {data}',
        'session_details.family_label': 'Rodzina',
        'session_details.incomplete_status': 'Otwarte',
        'session_details.model_label': 'Model',
        'session_details.no_transactions_message':
            'Nie dodano żadnych transakcji.',
        'session_details.product_label': 'Produkt',
        'session_details.saddle_product': 'Siodło',
        'session_details.shoe_product': 'But',
        'session_details.shop': 'Sklep: {data}',
        'session_details.size_label': 'Rozmiar',
        'session_details.status_label': 'Status',
        'session_details.transactions_heading': 'Transakcje sesji',
        'session_products.arch_height_label': 'Wysokość łuku: {data}',
        'session_products.bike_size_label': 'Rozmiar roweru: {data}',
        'session_products.saddle_height_label': 'Wysokość siodełka: {data}mm',
        'session_products.saddle_width_label': 'Szerokość siodełka: {data}mm',
        'session_products.session_product_label': 'Dane sesji',
        'session_products.shoe_size_label': 'Rozmiar buta: {data}',
        'sessions.add_transaction_cta_label': 'Dodaj transakcję',
        'sessions.client_anonymous': 'Anonimowy',
        'sessions.client_label': 'Klient',
        'sessions.closed_status': 'ZAMKNIĘTE',
        'sessions.csv_downloading_message':
            'Zbieramy wszystkie sesje do CSV, to może potrwać kilka minut.',
        'sessions.date_label': 'Data',
        'sessions.employee_label': 'Pracownik',
        'sessions.export_csv_link': 'Lista eksportu (.CSV)',
        'sessions.filter_empty':
            'Niestety, nie ma sesji spełniających kryteria filtru. Zresetuj wybór filtra.',
        'sessions.filter_empty_cta_label': 'Resetuj filtry',
        'sessions.heading': 'Sesje',
        'sessions.intro': 'Harmonogram wszystkich ostatnich sesji Retül.',
        'sessions.location_label': 'Lokalizacja',
        'sessions.no_rating': 'Brak oceny',
        'sessions.open_status': 'OTWARTE',
        'sessions.rating_label': 'Ocena',
        'sessions.session_id_label': 'Identyfikator sesji',
        'sessions.session_loading_message':
            'Zbieramy wszystkie twoje sesje, może to chwilę potrwać.',
        'sessions.status_label': 'Status',
        'sign_in.heading': 'Zaloguj się ›',
        'sign_in.intro':
            'Wprowadź adres e-mail i hasło, aby rozpocząć, lub wybierz "Zarejestruj konto", aby utworzyć nowe.',
        'status.all': 'STATUS: WSZYSTKIE',
        'status.complete': 'STATUS: ZAMKNIĘTE',
        'status.incomplete': 'STATUS: OTWARTE',
        triathlon: 'Triathlon',
        'POWER MIMIC DESCRIPTION':
            'To siodło sprawia, że drętwienie i ból zanikają dzięki designowi, który naśladuje reakcję organizmu na różne rodzaje nacisku, aby zapewnić równowagę w tkankach miękkich.',
        'date_picker.all_time': 'Wszystkie sesje',
        'date_picker.date_range': 'Zakres dat',
        'date_picker.last_month': 'Zeszły miesiąc',
        'date_picker.last_week': 'Zeszły tydzień',
        'date_picker.last_year': 'Zeszły rok',
        'date_picker.this_month': 'Ten miesiąc',
        'date_picker.this_week': 'Ten tydzień',
        'date_picker.this_year': 'Ten rok',
        'session_details.client_notes': 'Uwagi klienta',
        'session_details.footbed_product': 'Wkładka',
        'session_products.it_width_label': 'Szerokość: {data}mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match to sklepowa usługa dokładnego pomiaru długości nóg rowerzysty, dzięki której można określić optymalny rozmiar roweru i zapewnić dobór produktów jak najlepiej dopasowanych do klienta.',
        'body_data.about_saddle':
            'Retül Match to wirtualna usługa sklepowa wykorzystująca cyfrową kość miednicy – Retül Digital Sitbone Device, dzięki której szerokość kości miednicy rowerzysty mierzona jest cyfrowo w technologii mapowania nacisku, przez co można dokładnie określić rozmiar i typ siodełka jak najlepiej dopasowanego do klienta.',
        'body_data.about_shoe':
            'Retül Match to wirtualna usługa sklepowa, dzięki której można zarejestrować szczegóły stopy, aby dokładnie określić zalecane wsparcie podbicia, rozmiar buta i rodzaj obuwia jak najlepiej dopasowanego do klienta.',
        'body_data.arch_height_label': 'wysokość podbicia',
        'body_data.bike_label': 'Rower',
        'body_data.bike_size_label': 'rozmiar roweru',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'obwód',
        'body_data.find_retul_technology': 'Znajdź Technologię Retül',
        'body_data.heading': 'Dane budowy ciała',
        'body_data.intro':
            'Dane budowy ciała rowerzysty i preferowany styl jazdy rejestrowane są w technologii Retül Vantage, przez co można podać dokładne rozmiary i dobrać produkty jak najlepiej dopasowane do klienta.',
        'body_data.length_label': 'długość',
        'body_data.load_error':
            'Obecnie nie możemy pobrać Twoich danych budowy ciała, spróbuj ponownie później.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'nie pobrano',
        'body_data.recommended_label': 'Zalecane',
        'body_data.saddle_height_label': 'wysokość siodełka',
        'body_data.saddle_label': 'Siodełko',
        'body_data.saddle_size_label': 'rozmiar siodełka',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'goleń',
        'body_data.shoe_label': 'But',
        'body_data.shoe_size_label': 'rozmiar buta',
        'body_data.sit_bone_label': 'kość miednicy',
        'body_data.starting_label': 'Rozpoczynam',
        'body_data.thigh_label': 'udo',
        'body_data.view_bike_matches_label': 'Zobacz dopasowane rowery',
        'body_data.view_saddle_matches_label': 'Zobacz dopasowane siodełka',
        'body_data.view_shoe_matches_label': 'Zobacz dopasowane buty',
        'body_data.your_progress_completion_label': 'Ukończono {numComplete}/3',
        'body_data.your_progress_label': 'Twoje postępy',
        cancel_label: 'Anuluj',
        'create_account.heading': 'Utwórz konto',
        'create_account.intro':
            'Podaj e-mail i hasło, aby utworzyć konto Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Anuluj',
        'edit_form.edit_form_save_label': 'Zapisz',
        email_format_error: 'Nieprawidłowy format e-mail.',
        email_label: 'Adres e-mail',
        'forgot_password.heading': 'Nie pamiętam hasła',
        'forgot_password.intro':
            'Nie pamiętasz swojego hasła? Podaj swój adres e-mail, a wyślemy Ci link do zresetowania hasła.',
        'forgot_password.reset_email_sent':
            'Wiadomość e-mail umożliwiającą zresetowanie hasła wysłano na adres {email}',
        'landing.create_account_label': 'Utwórz konto',
        'landing.sign_in_label': 'Rejestracja',
        'nav.account_profiles_link_label': 'Konto i profile rowerzysty',
        'nav.home_page_link_label': 'Strona główna',
        'nav.logout_link_label': 'Wyloguj się',
        'not_found.go_home_label': 'Wróć do Strony głównej',
        'not_found.heading': '404',
        'not_found.intro':
            'Strona, której szukasz, została prawdopodobnie przeniesiona, usunięta lub nie istnieje.',
        password_invalid_error: 'Nieprawidłowe hasło.',
        password_label: 'Hasło',
        'product_matches.bike_size_label': 'rozmiar roweru',
        'product_matches.category_filter_commute_comfort_label':
            'Dojazdy – wygoda',
        'product_matches.category_filter_commute_sport_label':
            'Dojazdy – sport',
        'product_matches.category_filter_cyclocross_label': 'Cyclocross',
        'product_matches.category_filter_label': 'Kategoria',
        'product_matches.category_filter_label_road': 'Szosowy',
        'product_matches.category_filter_mountain_trail_label':
            'Górski (trailowy)',
        'product_matches.category_filter_mountain_xc_label': 'Górski (krosowy)',
        'product_matches.category_filter_triathlon_label': 'Triatlonowy',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Nie mamy danych budowy ciała potrzebnych nam do podania dopasowanych wyników dla produktu w tej kategorii. Użyj powyższego filtra, aby zobaczyć inne rodzaje produktów, lub skorzystaj ze strony z lokalizacjami, aby odnaleźć lokalizację umożliwiającą ukończenie tego modułu dopasowania.',
        'product_matches.find_retul_technology_label':
            'Znajdź technologię Retül',
        'product_matches.gender_filter_all_label':
            'Wyświetl wszystkie produkty',
        'product_matches.gender_filter_female_label':
            'Wyświetl produkty dla kobiet',
        'product_matches.gender_filter_male_label':
            'Wyświetl produkty dla mężczyzn',
        'product_matches.heading': 'Dopasowane produkty',
        'product_matches.intro':
            'Technologia Retül wykorzystuje dane dotyczące budowy ciała rowerzysty i preferowanego stylu jazdy, aby wybrać specjalistyczne produkty w opcji Specialized jak najlepiej dopasowane do klienta.',
        'product_matches.last_session_match_label':
            'Ostatnia sesja dopasowania',
        'product_matches.load_error':
            'Obecnie nie możemy odzyskać Twoich dopasowanych produktów, spróbuj ponownie później.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'Powyższy rozmiar roweru zalecamy na podstawie danych biometrycznych zarejestrowanych podczas sesji Retül Match. Chociaż to dobry punkt wyjścia, zalecamy konsultację z lokalnym sprzedawcą w celu uzyskania rekomendacji na podstawie poziomu doświadczenia i budowy ciała rowerzysty.',
        'product_matches.overlay_bike_size_label': 'rozmiar roweru',
        'product_matches.overlay_close_label': 'Zamknij',
        'product_matches.overlay_indicator_label': 'Ty',
        'product_matches.overlay_saddle_height_details':
            'Powyższą wysokość siodełka zalecamy na podstawie danych biometrycznych zarejestrowanych podczas sesji Retül Match. Chociaż to dobry punkt wyjścia, zalecamy konsultację z lokalnym sprzedawcą w celu uzyskania rekomendacji na podstawie poziomu doświadczenia i budowy ciała rowerzysty.',
        'product_matches.overlay_saddle_height_label': 'wysokość siodełka',
        'product_matches.overlay_saddle_size_details':
            'Powyższy rozmiar siodełka zalecamy na podstawie danych biometrycznych zarejestrowanych podczas sesji Retül Match. Chociaż to dobry punkt wyjścia, zalecamy konsultację z lokalnym sprzedawcą w celu uzyskania rekomendacji na podstawie danych budowy ciała rowerzysty.',
        'product_matches.overlay_saddle_size_label': 'rozmiar siodełka',
        'product_matches.overlay_shoe_size_details':
            'Powyższy rozmiar buta zalecamy na podstawie danych biometrycznych zarejestrowanych podczas sesji Retül Match. Chociaż to dobry punkt wyjścia, zalecamy konsultację z lokalnym sprzedawcą w celu uzyskania rekomendacji na podstawie poziomu doświadczenia rowerzysty.',
        'product_matches.overlay_shoe_size_label': 'rozmiar buta',
        'product_matches.padding_level_label': 'Grubość wyściółki',
        'product_matches.product_type_filter_bikes_label': 'Rowery',
        'product_matches.product_type_filter_label': 'Rodzaj produktu',
        'product_matches.product_type_filter_saddles_label': 'Siodełka',
        'product_matches.product_type_filter_shoes_label': 'Buty',
        'product_matches.recommended_label': 'Zalecane',
        'product_matches.saddle_detail_size_label': 'Rozmiar siodełka:',
        'product_matches.saddle_height_label': 'wysokość siodełka',
        'product_matches.saddle_models_label': 'Modele',
        'product_matches.saddle_size_label': 'rozmiar siodełka',
        'product_matches.shoe_size_label': 'rozmiar buta',
        'product_matches.starting_label': 'Rozpoczynam',
        'product_matches.view_on_specialized_label':
            'Wyświetl w opcji Specialized',
        'profile.account_heading': 'Konto',
        'profile.bike_type_city_label': 'Miasto',
        'profile.bike_type_label': 'Rodzaj roweru',
        'profile.bike_type_mountain_label': 'Górski',
        'profile.bike_type_road_label': 'Szosowy',
        'profile.email_error': 'Podaj poprawny adres e-mail',
        'profile.email_label': 'Adres e-mail',
        'profile.gender_female_label': 'kobieta',
        'profile.gender_label': 'Płeć',
        'profile.gender_male_label': 'mężczyzna',
        'profile.heading': 'Konto i profile rowerzysty',
        'profile.intro':
            'Wkrótce... Edytuj informacje o koncie i profile rowerzysty.',
        'profile.name_error': 'Podaj imię',
        'profile.name_label': 'Imię',
        'profile.password_error': 'Podaj hasło',
        'profile.password_label': 'Hasło',
        'profile.position_high_label': 'Wysoka',
        'profile.position_label': 'Pozycja',
        'profile.position_low_label': 'Niska',
        'profile.position_medium_label': 'Średnia',
        'profile.ride_duration_extended_label': '90 lub więcej minut',
        'profile.ride_duration_label': 'Czas trwania',
        'profile.ride_duration_long_label': '60–90 minut',
        'profile.ride_duration_medium_label': '30–60 minut',
        'profile.ride_duration_short_label': '0–30 minut',
        'profile.ride_frequency_label': 'Częstotliwość',
        'profile.ride_frequency_once_month_label': 'Raz na miesiąc',
        'profile.ride_frequency_once_week_label': 'Raz na tydzień',
        'profile.ride_frequency_six_week_label': '6 razy w tygodniu',
        'profile.ride_frequency_three_week_label': '3 razy w tygodniu',
        'routes.account_profile_route_label': 'Konto i profile rowerzysty',
        'routes.body_data_route_label': 'Dane budowy ciała',
        'routes.create_account_route_label': 'Utwórz konto',
        'routes.forgot_password_route_label': 'Nie pamiętam hasła',
        'routes.locations_route_label': 'Lokalizacje',
        'routes.not_found_route_label': 'Nie znaleziono',
        'routes.privacy_policy_route_label': 'Polityka prywatności',
        'routes.product_matches_route_label': 'Dopasowane produkty',
        'routes.reset_password_route_label': 'Resetuj hasło',
        'routes.set_password_route_label': 'Resetuj hasło',
        'routes.sessions_route_label': 'Sesje',
        'routes.sign_in_route_label': 'Rejestracja',
        'routes.terms_of_service_route_label': 'Warunki korzystania',
        'sessions.download_pdf_cta_label': 'Pobierz plik PDF',
        'sessions.empty': 'Brak dotychczasowych sesji.',
        'sessions.empty_cta_label': 'Znajdź lokalizacje',
        'sessions.loading_error':
            'Obecnie nie możemy pobrać Twoich sesji, spróbuj ponownie później.',
        'sessions.loading_error_cta_label': 'Spróbuj ponownie',
        'sessions.session_type_filter_label': 'Rodzaj sesji',
        'session_type.dfd_session_name':
            'Retül Digital Foot Device – cyfrowa stopa',
        'session_type.dsd_session_name':
            'Retül Digital Sitbone Device – cyfrowa kość miednicy',
        'session_type.foot_session_name': 'Retül Foot – stopa',
        'session_type.legs_session_name': 'Retül Legs – nogi',
        'session_type.match_session_name': 'Wynik dopasowania Retül',
        'session_type.saddle_session_name': 'Retül Saddle – siodełko',
        'session_type.unknown_session_name': 'Sesja Retül',
        'session_type.vantage_session_name': 'Dopasowanie Retül',
        'sign_in.sign_in_label': 'Rejestracja',
        'sign_in.contact_label': 'Skontaktuj się z obsługą Retül',
        'sign_in.forgot_password_label': 'Zapomniałeś hasła?',
        'sign_in.sign_in_error':
            'Nie mogliśmy pobrać Twoich danych użytkownika. Skontaktuj się z obsługą Retül, aby uzyskać pomoc.',
        submit_label: 'Wyślij',
    },
};
