import React, {Component} from 'react';
import NavigationDropDownIcon from '../../components/NavigationDropDownIcon/NavigationDropDownIcon';
import './DropDown.css';
import {injectIntl} from 'react-intl';
import messages from '../../types/constantsMessages';

class DropDown extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.state = {
      'listVisible': false,
      'displayedValue': this.intl.formatMessage(messages[props.displayedValue]),
      'selectedValue': props.selectedValue
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.openClass = this.openClass.bind(this);
  }

  openClass() {
    return this.state.listVisible ? 'open' : '';
  }

  dropDownClassName() {
    const visibleStatus = this.state.listVisible ? 'show' : '';
    const halfWidth = this.props.halfWidth ? 'half-width-dropdown' : '';
    return `dropdown ${this.props.classExtensions} ${visibleStatus} ${halfWidth}`
  }

  show() {
    this.setState({listVisible: true});
    document.addEventListener("click", this.hide);
  }

  componentWillUnmount() {
    this.setState({listVisible: false});
    document.removeEventListener("click", this.hide);
  }

  hide() {
    this.setState({listVisible: false});
    document.removeEventListener("click", this.hide);
  }

  handleListItemClick(e, value) {
    this.updateInternalValues(value);

    if (typeof this.props.handleItemClick === 'function') {
      this.props.handleItemClick(value);
    }
  }

  updateInternalValues(value) {
    const foundItem = this.props.listItems.find(item => {
      return item.value === value;
    });

    this.setState({
      'displayedValue': this.intl.formatMessage(messages[foundItem.label]),
      'selectedValue': value
    });
  }

  renderListItems() {
    const {listItems} = this.props;
    return listItems.map((listItem, i) => {
      const {value, label} = listItem;

      return (
        <li key={i}>
          <button
            type="button"
            className='dropdown-option'
            onClick={(e) => this.handleListItemClick(e, value)}
          >
            {this.intl.formatMessage(messages[label])}
          </button>
        </li>
      )
    })
  }

  render() {
    const {displayedValue, selectedValue} = this.state;
    const {fieldName} = this.props;

    return (
      <div className={this.dropDownClassName()}>
        <button
          type="button"
          className="dropdown-trigger"
          onClick={this.show}
        >
          <span className="dropdown-trigger-text">
            {displayedValue}
          </span>
          <NavigationDropDownIcon
            openClass={this.openClass}
          />
        </button>
        <ul className="dropdown-list">
          {this.renderListItems()}
        </ul>
        <input
          type="hidden"
          name={fieldName}
          value={selectedValue}
        />
      </div>
    )
  }
}

export default injectIntl(DropDown);
