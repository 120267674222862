import React, {Component} from 'react';
import {connect} from "react-redux";
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import ManageEmployees from '../components/ManageEmployees/ManageEmployes';
import * as actionCreators from '../actions/employees';
import {isLocationsListFetched} from '../selectors/locations';
import {getEmployees, isEmployeesFetched, isFetchNeeded} from '../selectors/employees';
import Intro from '../components/Intro/Intro';
import Main from '../components/Main/Main';
import {injectIntl} from 'react-intl';
import messages from './messages';

class ManageEmployeesContainer extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'heading': intl.formatMessage(messages.employees_heading),
      'intro': intl.formatMessage(messages.employees_intro)
    };

    this.createEmployee = this.createEmployee.bind(this);
    this.deleteEmployee = this.deleteEmployee.bind(this);
    this.updateEmployee = this.updateEmployee.bind(this);
    this.fetchEmployees = this.fetchEmployees.bind(this);
  }

  componentDidMount() {
    this.fetchEmployees()
  }

  componentWillUpdate(nextProps, nextState) {
    isFetchNeeded(this.props, nextProps) && this.fetchEmployees()
  }

  fetchEmployees() {
    const {fetchEmployees} = this.props;
    fetchEmployees()
  }

  createEmployee(data) {
    const {createEmployee} = this.props;
    createEmployee(data)
  }

  deleteEmployee(data) {
    const {deleteEmployee} = this.props;
    deleteEmployee(data)
  }

  updateEmployee(data) {
    const {updateEmployee} = this.props;
    updateEmployee(data)
  }

  isDataFetched() {
    return isEmployeesFetched(this.props) && isLocationsListFetched(this.props)
  }

  renderTopSection() {
    return (
      <section className="top">
        <div className="container">
          <Intro
            header={this.page.heading}
            summary={this.page.intro}
          />
        </div>
      </section>
    )
  }

  renderManageEmployees(isLoading) {
    const {employee, employeesList, locationsList} = this.props;

    return isLoading ? null : (
      componentWithErrorBoundary(
        <ManageEmployees
          createEmployee={this.createEmployee}
          updateEmployee={this.updateEmployee}
          fetchEmployees={this.fetchEmployees}
          deleteEmployee={this.deleteEmployee}
          employee={employee}
          employeesList={employeesList}
          locationsList={locationsList}
        />
      )
    );
  }

  render() {
    const isLoading = !(this.isDataFetched());

    return (
      <Main isLoading={isLoading}>
        {this.renderTopSection()}
        {this.renderManageEmployees(isLoading)}
      </Main>
    );
  }
}

function mapStateToProps(state) {
  return getEmployees(state);
}

export default connect(mapStateToProps, actionCreators)(injectIntl(ManageEmployeesContainer));
