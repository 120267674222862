import React, {Component} from 'react';
import './ResourceCheckboxInput.css';

export default class ResourceCheckboxInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.checked || false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.setState({
        isChecked: nextProps.checked,
      });
    }
  }

  handleChange(event, checkboxGroupName, value) {
    const {handleCheck} = this.props;

    handleCheck(checkboxGroupName, value)
  }

  render() {
    const {
      checkboxGroupName,
      labelText,
      name,
      value,
    } = this.props;

    return (
      <label className="control control--checkbox">
        <div className="filter-label-content">
          {labelText}
        </div>
        <input
          checked={this.state.isChecked}
          type="checkbox"
          name={`${name}[]`}
          onChange={e => this.handleChange(e, checkboxGroupName, value)}
        />
        <div
          className="control__indicator"
        />
      </label>
    )
  }
}
