import {COMPLETED, RECEIVED} from '../types/statuses';
import {LOCATION_FORM_FIELDS} from '../types/constants';
import {getFields} from '../utils/formHelper'

export function getLocationFields(values = {}) {
  return getFields({fieldsArray: LOCATION_FORM_FIELDS, values})
}

export function getLocationsList(data) {
  const {locationsList} = data;
  return locationsList;
}

export function getLocationsListFetchingStatus(data) {
  const {locationsList} = data;
  if (locationsList) {
    const {fetchingStatus} = locationsList;
    return fetchingStatus
  }
}

export function getLocationFetchingStatus(data) {
  const {location} = data;
  const {fetchingStatus} = location;
  return fetchingStatus
}


export function getCreateStatus(data) {
  const {location} = data;
  if (location) {
    const {createStatus} = location;
    return createStatus
  }
}

export function getUpdateStatus(data) {
  const {location} = data;
  if (location) {
    const {updateStatus} = location;
    return updateStatus
  }
}

export function getDeleteStatus(data) {
  const {location} = data;
  if (location) {
    const {deleteStatus} = location;
    return deleteStatus
  }
}

export function isLocationsListFetched(data) {
  return getLocationsListFetchingStatus(data) === RECEIVED;
}

export function isUpdated(prevProps, newProps) {
  if (getUpdateStatus(newProps) !== getUpdateStatus(prevProps)) {
    return getUpdateStatus(newProps) === COMPLETED
  }
}

export function isDeleted(prevProps, newProps) {
  if (getDeleteStatus(newProps) !== getDeleteStatus(prevProps)) {
    return getDeleteStatus(newProps) === COMPLETED
  }
}

export function isFetchNeeded(prevProps, newProps) {
  return [isUpdated, isDeleted, isCreated].some(fn => fn(prevProps, newProps) === true);
}

export function isCreated(prevProps, newProps) {
  if (getCreateStatus(newProps) !== getCreateStatus(prevProps)) {
    return getCreateStatus(newProps) === COMPLETED
  }
}