export function normalizeClient(data) {
  const {email, name, notes} = data;
  const sessions = getSessions(data);

  return (
    {
      email,
      name,
      notes,
      sessions,
      'completed_types': numComplete(sessions),
    }
  )
}

export function getBikeTypeFetchingStatus(data) {
  const {bikeTypeData} = data;
  const {fetchingStatus} = bikeTypeData;
  return fetchingStatus
}

export function getClientProductFetchingStatus(data) {
  const {clientProductData} = data;
  const {fetchingStatus} = clientProductData;
  return fetchingStatus
}

export function getClientProduct(data) {
  const {latest_shoe_session, latest_bike_session, latest_saddle_session} = data;
  return (
    {
      latest_shoe_session,
      latest_bike_session,
      latest_saddle_session
    }
  )
}

function getSessions(data) {
  return Object.keys(data).filter(key => {
    return key.indexOf('latest_') > -1;
  }).map(key => {
    const id = data[key];
    const name = key.replace('latest_', '').replace('_session', '');

    return {
      name,
      id,
    }
  }).sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}

function numComplete(sessions) {
  return sessions.filter(step => {
    return step.id;
  }).length;
}
