import { defineMessages } from 'react-intl';

export default defineMessages({
  'sessions.session_id_label': {
    'id': 'sessions.session_id_label',
    'defaultMessage': 'Session ID',
    'description': 'Table column label for Session ID'
  },
  'sessions.date_label': {
    'id': 'sessions.date_label',
    'defaultMessage': 'Date',
    'description': 'Table column label for Date'
  },
  'sessions.client_label': {
    'id': 'sessions.client_label',
    'defaultMessage': 'Client',
    'description': 'Table column label for Client'
  },
  'sessions.rating_label': {
    'id': 'sessions.rating_label',
    'defaultMessage': 'Rating',
    'description': 'Table column label for Rating'
  },
  'sessions.employee_label': {
    'id': 'sessions.employee_label',
    'defaultMessage': 'Employee',
    'description': 'Table column label for Employee'
  },
  'sessions.location_label': {
    'id': 'sessions.location_label',
    'defaultMessage': 'Location',
    'description': 'Table column label for Location'
  },
  'sessions.status_label': {
    'id': 'sessions.status_label',
    'defaultMessage': 'Status',
    'description': 'Table column label for Status'
  },
  'add_transaction_cta_label': {
    'id': 'sessions.add_transaction_cta_label',
    'defaultMessage': 'Add Transaction',
    'description': 'Label for CTA to direct user to add a transaction to a session'
  },
  'OPEN': {
    'id': 'sessions.open_status',
    'defaultMessage': 'OPEN',
    'description': 'OPEN Status for the session'
  },
  'CLOSED': {
    'id': 'sessions.closed_status',
    'defaultMessage': 'CLOSED',
    'description': 'CLOSED Status for the session'
  },
  'no_rating': {
    'id': 'sessions.no_rating',
    'defaultMessage': 'No Rating',
    'description': 'Value for when the session was not rated on a scale from 1-5'
  },
  'anonymous': {
    'id': 'sessions.client_anonymous',
    'defaultMessage': 'Anonymous',
    'description': 'Value for when the session did not have a client'
  },
});
