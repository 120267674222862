import { defineMessages } from 'react-intl';

export default defineMessages({
  'locale_picker_heading': {
    'id': 'locale.locale_picker_heading',
    'defaultMessage': 'Choose your language.',
    'description': 'Locale picker modal heading text'
  },
  'locale_picker_save_label': {
    'id': 'locale.locale_picker_save_label',
    'defaultMessage': 'Save',
    'description': 'Locale picker save button text'
  },
  'locale_picker_cancel_label': {
    'id': 'locale.locale_picker_cancel_label',
    'defaultMessage': 'Cancel',
    'description': 'Locale picker cancel button text'
  },
  'label_en': {
    'id': 'locale.label_en',
    'defaultMessage': 'English',
    'description': 'English locale drop down option text'
  },
  'label_en-us': {
    'id': 'locale.label_en-us',
    'defaultMessage': 'English (US)',
    'description': 'English (US) locale drop down option text'
  },
  'label_es': {
    'id': 'locale.label_es',
    'defaultMessage': 'Espanol',
    'description': 'Spanish locale drop down option text'
  },
  'label_de': {
    'id': 'locale.label_de',
    'defaultMessage': 'Deutsch',
    'description': 'German locale drop down option text'
  },
  'label_fr': {
    'id': 'locale.label_fr',
    'defaultMessage': 'Français',
    'description': 'French locale drop down option text'
  },
  'label_it': {
    'id': 'locale.label_it',
    'defaultMessage': 'Italiano',
    'description': 'Italian locale drop down option text'
  },
  'label_cs': {
    'id': 'locale.label_cs',
    'defaultMessage': 'Česky',
    'description': 'Czech locale drop down option text'
  },
  'label_ja': {
    'id': 'locale.label_ja',
    'defaultMessage': '日本語',
    'description': 'Japanese locale drop down option text'
  },
  'label_ko': {
    'id': 'locale.label_ko',
    'defaultMessage': '한국어',
    'description': 'Korean locale drop down option text'
  },
  'label_nl': {
    'id': 'locale.label_nl',
    'defaultMessage': 'Nederlands',
    'description': 'Dutch locale drop down option text'
  },
  'label_pl': {
    'id': 'locale.label_pl',
    'defaultMessage': 'Polskie',
    'description': 'Polish locale drop down option text'
  },
  'label_pt': {
    'id': 'locale.label_pt',
    'defaultMessage': 'Português',
    'description': 'Portuguese locale drop down option text'
  },
  'label_pt-br': {
    'id': 'locale.label_pt-br',
    'defaultMessage': 'Português do Brasil',
    'description': 'Brazilian Portuguese locale drop down option text'
  },
  'label_zh-cn': {
    'id': 'locale.label_zh-cn',
    'defaultMessage': '中国',
    'description': 'Chinese (Simplified) locale drop down option text'
  },
  'label_zh-tw': {
    'id': 'locale.label_zh-tw',
    'defaultMessage': '台灣',
    'description': 'Chinese (Traditional) locale drop down option text'
  },
});
