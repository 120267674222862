export const RECEIVE_ANALYTICS_BY_SESSION_DATA = 'RECEIVE_ANALYTICS_BY_SESSION_DATA';
export const REQUEST_ANALYTICS_BY_SESSION_DATA = 'REQUEST_ANALYTICS_BY_SESSION_DATA';

export const RECEIVE_ANALYTICS_BY_PRODUCT_DATA = 'RECEIVE_ANALYTICS_BY_PRODUCT_DATA';
export const REQUEST_ANALYTICS_BY_PRODUCT_DATA = 'REQUEST_ANALYTICS_BY_PRODUCT_DATA';

export const REQUEST_JWT = 'REQUEST_JWT';
export const RECEIVE_JWT_SUCCESS = 'RECEIVE_JWT_SUCCESS';
export const RECEIVE_JWT_ERROR = 'RECEIVE_JWT_ERROR';
export const SIGN_OUT = 'SIGN_OUT';

export const UPDATE_ANALYTICS_FILTERS = 'UPDATE_ANALYTICS_FILTERS';

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER_FAILURE = 'RECEIVE_CURRENT_USER_FAILURE';

export const RECEIVE_NEW_ACCOUNT = 'RECEIVE_NEW_ACCOUNT';
export const REQUEST_NEW_ACCOUNT = 'REQUEST_NEW_ACCOUNT';
export const RECEIVE_NEW_ACCOUNT_FAILURE = 'RECEIVE_NEW_ACCOUNT_FAILURE';
