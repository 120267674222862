import React, { Component } from 'react';
import {parentContainerHeight,parentContainerWidth} from '../../../utils/chartDimensions';

export default function ResponsiveDoughnutChart(ChartComponent, containerClassName = "chart") {
  class ResponsiveDoughnutChart extends Component {
    constructor(props) {
      super(props);
      this.state = {
        containerHeight: null,
        containerWidth: null
      };
      this.fitParentContainer = this.fitParentContainer.bind(this);
    }

    componentDidMount() {
      this.fitParentContainer();
      window.addEventListener('resize', this.fitParentContainer)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer)
    }

    getItems() {
      return this.data.map(d => d.item);
    }

    getQuantities() {
      return this.data.map(d => d.quantity)
    }

    fitParentContainer() {
      const { containerWidth, containerHeight } = this.state;
      const currentContainerWidth = parentContainerWidth(this);
      const currentContainerHeight = parentContainerHeight(this);

      if (containerWidth !== currentContainerWidth || containerHeight !== currentContainerHeight) {
        this.setState({
          containerWidth: currentContainerWidth,
          containerHeight: currentContainerHeight
        })
      }
    }

    renderChart() {
      const parentWidth = this.state.containerWidth;
      const parentHeight = this.state.containerHeight;
      return (
        <ChartComponent
          { ...this.props }
          parentWidth={ parentWidth }
          parentHeight={parentHeight}
          getItems={ this.getItems }
          getQuantities={ this.getQuantities }
        />
      )
    }

    shouldRenderChart() {
      const { containerWidth } = this.state;
      return containerWidth !== null;
    }

    render() {
      return (
        <div
          ref={ (el) => {
            this.chartContainer = el
          } }
          className={ containerClassName }
        >
          { this.shouldRenderChart() && this.renderChart() }
        </div>
      )
    }
  }

  function componentName(childComponent) {
    const componentName =
      childComponent.displayName
      || childComponent.name
      || 'Chart';
    return `ResponsiveDoughnutChart${componentName}`;
  }

  ResponsiveDoughnutChart.displayName = componentName(ChartComponent);
  return ResponsiveDoughnutChart
}


