import React, {Component} from 'react';
import classNames from 'classnames';
import makeHash from '../../utils/makeHash';
import InformationIcon from '../../components/InformationIcon/InformationIcon';
import './InformationTooltip.css';


export default class InformationToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  render() {
    const {data, name} = this.props;
    const hash = makeHash(name);

    return (
      <div className="tooltip">
        <button
          type="button"
          className="tooltip-trigger"
          onClick={this.handleClick}
          aria-controls={hash}
          aria-expanded={this.state.visible}
          aria-label={`Toggle ${name} Tooltip`}
        >
          <InformationIcon/>
        </button>
        <div className={this.bodyClasses} id={hash}>
          {data}
        </div>
      </div>
    )
  }

  get bodyClasses() {
    return classNames(
      'tooltip-body',
      {
        'tooltip-body_visible': this.state.visible,
        'tooltip-body_bottomLeft': this.props.bottomLeft
      }
    );
  }
}
