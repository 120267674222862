export const SESSIONS_PAGE_SIZE = 20;

export const SESSION_TABLE_HEADINGS = [
  'sessions.session_id_label',
  'sessions.date_label',
  'sessions.client_label',
  'sessions.rating_label',
  'sessions.employee_label',
  'sessions.location_label',
  'sessions.status_label'
];

export const ORDABLE_TABLE_HEADINGS = [
  { name: 'Date', orderAttribute: 'created_at' },
  { name: 'Rating', orderAttribute: 'rating' }
];
