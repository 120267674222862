import React, {Component} from 'react';
import SearchFiltersGroupContainer from '../../containers/AnalyticsSearchFiltersGroupContainer'
import './AnalyticsFilters.css';
import {ANALYTICS_DATA_TYPES} from '../../types/constants';
import {injectIntl} from 'react-intl';
import messages from './messages';

class AnalyticsFilters extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.page = {
      'filterBy': this.intl.formatMessage(messages.filter_by),
      'analyticsBy': this.intl.formatMessage(messages.analytics_by),
    };
  }

  setClassName(name) {
    const {activeFilter} = this.props;
    const className = "filter-item";

    if (name === activeFilter) {
      return `${className} active`
    }
    else {
      return className
    }
  }

  renderPageFilterItems() {
    const {activeFilter} = this.props;

    return ANALYTICS_DATA_TYPES.map((name, i) => {
      let path = '';

      if (name !== activeFilter) {
        path = `/analytics/${name.toLowerCase()}-analytics`;
      }

      return (
        <a
          href={path}
          key={i}
          className={this.setClassName(name)}
        >
          {this.intl.formatMessage(messages[name])}
        </a>
      )
    })
  }

  render() {
    return (
      <div className="analytics-filters">
        <div className="page-filters-container">
          <div className="filter-label">
            <span className="filter-label-text">
              {this.page.analyticsBy}:
            </span>
          </div>
          <div className="page-filter-button-group">
            {this.renderPageFilterItems()}
          </div>
        </div>

        <div className="search-filters-container">
          <div className="filter-label">
            <span className="filter-label-text">
              {this.page.filterBy}:
            </span>
          </div>
          <SearchFiltersGroupContainer
            updateAnalyticsFilters={this.props.updateAnalyticsFilters}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(AnalyticsFilters);
