import React, { Component } from 'react';
import './SessionDetails.css';
import makeHash from '../../utils/makeHash';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import PageLink from '../../components/PageLink/PageLink';
import NavigationLinkIcon from '../../components/NavigationLinkIcon/NavigationLinkIcon';
import { renderAddTransactionModal } from '../../utils/modalHelper';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import messages from './messages';
import SessionProductContainer from '../../containers/SessionProductContainer';

class SessionDetails extends Component {
    constructor(props) {
        super(props);

        this.intl = props.intl;
        this.page = {
            allSessions: this.intl.formatMessage(messages.all_sessions_link),
            client: this.intl.formatMessage(messages.client),
            status: this.intl.formatMessage(messages.status),
            completeStatus: this.intl.formatMessage(messages.complete_status),
            incompleteStatus: this.intl.formatMessage(
                messages.incomplete_status
            ),
            transactionsHeading: this.intl.formatMessage(
                messages.transactions_heading
            ),
            addTransaction: this.intl.formatMessage(messages.add_transaction),
            noTransactionsMessage: this.intl.formatMessage(
                messages.no_transactions_message
            ),
            completeSessionMessage: this.intl.formatMessage(
                messages.complete_session_message
            ),
            completeSession: this.intl.formatMessage(
                messages.complete_session_cta_label
            ),
            productLabel: this.intl.formatMessage(messages.product_label),
            familyLabel: this.intl.formatMessage(messages.family_label),
            modelLabel: this.intl.formatMessage(messages.model_label),
            sizeLabel: this.intl.formatMessage(messages.size_label),
            clientReviewLabel: this.intl.formatMessage(
                messages.client_review_label
            ),
            deleteLink: this.intl.formatMessage(messages.delete_link),
        };

        this.state = {
            openModal: false,
        };

        this.openModal = this.openModal.bind(this);
        this.deleteTransaction = this.deleteTransaction.bind(this);
    }

    openModal() {
        this.setState({
            openModal: true,
        });
    }

    deleteTransaction(id, type) {
        this.props.deleteTransaction(id, type);
    }

    renderCompleteSection() {
        const isClosed =
            this.props.status === 'complete' || this.props.status === 'CLOSED';

        return isClosed ? null : (
            <div className="session-details_upper-bottom spaceAboveBelow">
                <p className="txt txt_mdLg">
                    {this.page.completeSessionMessage}:
                </p>
                <div className="box box_top1">
                    <Checkbox
                        className="checkbox"
                        labelText={this.page.completeSession}
                        onChange={this.props.completeTransaction}
                    />
                </div>
            </div>
        );
    }

    renderSessionTransactions() {
        console.log(this.props);
        return this.props.transactionData.map(
            ({
                family,
                model,
                size,
                product,
                id,
                session,
                product_display,
                rating_display,
                transaction_type,
                footbed_type,
                footbed_size,
            }) => {
                let prodModel = null;
                if (transaction_type === 'bike') {
                    prodModel = model;
                } else if (transaction_type === 'footbed') {
                    product = 'Footbed';
                    family = 'Footbed';
                    prodModel = footbed_type;
                    size = footbed_size;
                    // prodModel = model.replace(product_display, "").trim();
                } else {
                    prodModel = model;
                }
                if (prodModel === '') {
                    prodModel = 'N/A';
                }

                console.log('renderSessionTransactions', product);

                return (
                    <tr
                        key={makeHash(`${id}-${product}`)}
                        className="tableData"
                    >
                        <td>{this.intl.formatMessage(messages[product])}</td>
                        <td>{family}</td>
                        <td>{prodModel}</td>
                        <td>{size}</td>
                        <td>{rating_display}</td>
                        <td>
                            <button
                                className="modal-link"
                                onClick={() => {
                                    this.deleteTransaction(id, product);
                                }}
                            >
                                {this.page.deleteLink}
                            </button>
                        </td>
                    </tr>
                );
            }
        );
    }

    renderTransactionSection() {
        return this.props.transactionData.length ? (
            <div className="TableContainer">
                <table className="transactionDataTable">
                    <thead className="singleTransaction">
                        <tr className="headings">
                            <th>{this.page.productLabel}</th>
                            <th>{this.page.familyLabel}</th>
                            <th>{this.page.modelLabel}</th>
                            <th>{this.page.sizeLabel}</th>
                            <th>{this.page.clientReviewLabel}</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderSessionTransactions()}</tbody>
                </table>
            </div>
        ) : (
            <p className="txt txt_light">{this.page.noTransactionsMessage}</p>
        );
    }

    renderClient() {
        const { client_id, client } = this.props;
        return client_id === null ? (
            <span>{client}</span>
        ) : (
            <Link className="txt txt_link" to={`/clients/${client_id}`}>
                {client}
            </Link>
        );
    }

    renderClientNoteButton() {
        const { client_id } = this.props;
        return client_id !== null ? (
            <Link className="txt txt_link" to={`/clients/${client_id}`}>
                <Button light>
                    {this.intl.formatMessage(messages.client_notes)}
                </Button>
            </Link>
        ) : null;
    }

    renderSessionProducts() {
        return <SessionProductContainer />;
    }

    render() {
        const { date, location, employee, status, sessionId } = this.props;
        const currentStatus =
            status === 'OPEN'
                ? this.page.incompleteStatus
                : this.page.completeStatus;

        return (
            <section className="session-details">
                <div className="container">
                    <div className="session-details_breadcrumb">
                        <NavigationLinkIcon to="/sessions" />
                        <PageLink to="/sessions" heavy>
                            {this.page.allSessions}
                        </PageLink>
                    </div>
                    <div className="session-details_top">
                        <div className="session-details_top-left">
                            <h2 className="txt txt_mdLg h2_padded">
                                {sessionId}
                            </h2>
                            <div className="box box_top1">
                                <ul className="vList">
                                    <li className="txt">
                                        {this.intl.formatMessage(
                                            messages.created_on,
                                            {
                                                data: this.intl.formatDate(
                                                    date,
                                                    {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: '2-digit',
                                                    }
                                                ),
                                            }
                                        )}
                                    </li>
                                    <li className="txt">
                                        {this.page.client}:{' '}
                                        {this.renderClient()}
                                    </li>
                                    <li className="txt">
                                        {this.intl.formatMessage(
                                            messages.shop,
                                            { data: location }
                                        )}
                                    </li>
                                    <li className="txt">
                                        {this.intl.formatMessage(
                                            messages.employee,
                                            { data: employee }
                                        )}
                                    </li>
                                </ul>
                                {this.renderClientNoteButton()}
                            </div>
                            {this.renderSessionProducts()}
                        </div>
                        <div className="session-details_top-right">
                            <h3 className="txt txt_md mix-txt_uppercase">
                                {this.page.status}
                            </h3>
                            <span className="txt txt_lgPlus txt_light mix-txt_uppercase">
                                {currentStatus}
                            </span>
                        </div>
                    </div>
                    <div className="box box_top5 box_top7Md">
                        {this.renderCompleteSection()}
                        <div className="box box_top5 box_top10Lg">
                            <h4 className="txt txt_md mix-txt_uppercase">
                                {this.page.transactionsHeading}
                            </h4>
                            <div className="box box_top1">
                                {this.renderTransactionSection()}
                            </div>
                            <div className="box box_top1">
                                <Button light wide onClick={this.openModal}>
                                    {this.page.addTransaction}
                                </Button>
                            </div>
                            {renderAddTransactionModal({
                                openModal: this.state.openModal,
                                data: this.props,
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default injectIntl(SessionDetails);
