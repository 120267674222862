import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/clientProfile';
import ClientProfile from '../components/ClientProfile/ClientProfile';
import {RECEIVED} from '../types/statuses';
import Main from '../components/Main/Main';
import {normalizeClient} from '../selectors/client';


export class ClientProfileContainer extends Component {
  constructor() {
    super();

    this.onClientUpdateSubmit = this.onClientUpdateSubmit.bind(this);
  }

  componentWillMount() {
    this.fetchClientData();
  }

  fetchClientData() {
    const {fetchClient} = this.props;

    fetchClient(this.clientId);
  }

  onClientUpdateSubmit(data) {
    const {updateClient} = this.props;

    updateClient(this.clientId, data);
  }

  renderPage() {
    if (!this.dataReceived) return null;

    const {data, errors} = this.props.clientProfileData;
    const normalizedData = normalizeClient(data);

    return (
      <ClientProfile
        data={normalizedData}
        clientId={this.clientId}
        serverErrors={errors}
        onClientUpdateSubmit={this.onClientUpdateSubmit}
      />
    )
  }

  render() {
    return (
      <Main isLoading={!this.dataReceived}>
        {this.renderPage()}
      </Main>
    )
  }

  get dataReceived() {
    const {fetchingStatus} = this.props.clientProfileData;

    return fetchingStatus === RECEIVED;
  }

  get clientId() {
    return this.props.match.params.id;
  }
}

function mapStateToProps(state) {
  return state.clientProfile
}

export default connect(mapStateToProps, actionCreators)(ClientProfileContainer);
