import React, { Component } from 'react';
import './SignInForm.css';
import { injectIntl } from 'react-intl';
import TextField from '../../components/TextField/text-field';
import Text from '../../components/Text/Text';
import messages from '../../utils/messages';
import classNames from 'classnames';

class SignInForm extends Component {
    constructor(props) {
        super(props);

        this.showGigyaScreenset = this.showGigyaScreenset.bind(this);

        this.state = {
            serverError: false,
            formMessages: [],
            loading: false,
            acceptTOU: false,
            acceptTAC: false,
            showTermsCheckboxes: false,
        };
        this.fields = [];
        this.setUpHandlers();
    }

    componentDidMount() {
        this.fields = [this.email, this.password]; //🤢
        this.createScrollScript();

        if (process.env.REACT_APP_AUTH_BACKEND === 'gigya') {
            console.log('hi jason');
            this.createGigyaScript(
                `${process.env.REACT_APP_GIGYA_KEY}`,
                document.body
            );
        }
    }

    setUpHandlers() {
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    createScrollScript() {
        return new Promise(function (resolve, reject) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = `function scrollIt(element) {
        window.scrollTo({
          'behavior': 'smooth',
          'left': 0,
          'top': element.offsetTop
        });
        element.focus();
      }
      
      function enableScrollTo() {
        if (window.scrollToSet !== true) {
          for (let i = 0; i < document.querySelectorAll('input').length; i++) {
            document.querySelectorAll('input')[i].addEventListener('click', () => scrollIt(document.querySelectorAll('input')[i]));
            window.scrollToSet = true;
          }
        }
      }`;

            document.body.appendChild(s);
            resolve();
        });
    }

    createGigyaScript(gigyaApi, element) {
        console.log('heyy!', window.gigya);
        const scriptTag = document.createElement('script');
        scriptTag.src = `https://cdns.gigya.com/js/gigya.js?apikey=${gigyaApi}&lang=${this.locale}`;
        console.log('heyy2!', scriptTag.src);
        scriptTag.onload = this.showGigyaScreenset;
        scriptTag.onreadystatechange = this.showGigyaScreenset;
        console.log('heyy3!', window.gigya);

        element.appendChild(scriptTag);
    }

    showGigyaScreenset() {
        const params = {
            screenSet: 'Passport-RegistrationLogin',
            startScreen: 'gigya-login-screen',
            containerID: 'userScreensDiv',
            lang: this.props.intl.locale,
            onAfterSubmit: (evt) => {
                console.log(evt.form);
                if (
                    (evt.form === 'gigya-login-form' ||
                        evt.form === 'gigya-profile-form') &&
                    evt.response.errorCode === 0
                ) {
                    window.gigya.accounts.getJWT({
                        fields: 'firstName,lastName,email',
                        expiration: 604800,
                        callback: (resp) => {
                            this.submitForm(
                                evt.response.profile.email,
                                resp.id_token
                            );
                        },
                    });
                }
            },
        };
        window.gigya.accounts.showScreenSet(params);
        window.scrollToSet = false;

        setInterval(function () {
            window.enableScrollTo();
        }, 1000);
    }
    submitForm(email, password) {
        const data = { email, password, client: 'retailer' };
        if (this.state.acceptTAC) {
            data.tac = true;
        }

        if (this.state.acceptTOU) {
            data.tou = true;
        }

        this.props.handleSubmit(data);
    }

    handleSubmit(event) {
        if (event) event.preventDefault();

        const email = this.email.input.value;
        const password = this.password.input.value;

        this.submitForm(email, password);
    }

    renderFormMessages() {
        const formErrorsClasses = classNames('form-messages', {
            'form-messages_hasMessage':
                this.props.serverErrors && this.props.serverErrors.length > 0,
        });

        const color = this.props.serverErrors ? 'DefaultOrange' : 'DefaultBlue';

        if (this.props.serverErrors && this.props.serverErrors.length > 0) {
            //{field: "email", code: "403201", message: "EMAIL_NOT_VERIFIED"}
            const firstError = this.props.serverErrors[0];
            const message = this.props.intl.formatMessage(
                messages[firstError.message]
            );

            return (
                <ul className={formErrorsClasses}>
                    <li>
                        <Text color={color} weight="light">
                            {message.endsWith('.') ? message : message + '.'}
                        </Text>
                    </li>
                </ul>
            );
        }
    }

    render() {
        const { intl } = this.props;

        // const formMessages = null;
        const formMessages = this.renderFormMessages();
        console.log('form messages', formMessages); //CDJ

        // TODO: remove userScreensDiv along with gigya when the switch happens
        const className =
            process.env.REACT_APP_AUTH_BACKEND === 'gigya' ? 'hidden' : '';

        const checkboxClasses = this.props.showTerms
            ? 'field field_floatingLabel'
            : 'field field_floatingLabel hidden';

        return (
            <div className="SignInForm form-container">
                <div className="gigya-screen-set" id="userScreensDiv"></div>
                <form
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                    noValidate
                    id="signin-form"
                    className={className}
                >
                    {formMessages}
                    <div className="set-hd">
                        <TextField
                            id="email"
                            name="email"
                            type="email"
                            label={intl.formatMessage({
                                id: 'email_label',
                                defaultMessage: 'Email',
                                description: 'Email label for forms',
                            })}
                            errorMsg={intl.formatMessage({
                                id: 'email_format_error',
                                defaultMessage: 'Invalid email format.',
                                description:
                                    'Invalid email format error message',
                            })}
                            ref={(field) => {
                                this.email = field;
                            }}
                        />
                    </div>
                    <div className="set-bd set-bd_super">
                        <TextField
                            id="password"
                            name="password"
                            type="password"
                            label={intl.formatMessage({
                                id: 'password_label',
                                defaultMessage: 'Password',
                                description: 'Password label for forms',
                            })}
                            errorMsg={intl.formatMessage({
                                id: 'password_invalid_error',
                                defaultMessage: 'Invalid password.',
                                description: 'Invalid password error message',
                            })}
                            ref={(field) => {
                                this.password = field;
                            }}
                        />
                    </div>
                    <div className={checkboxClasses}>
                        <input
                            id="accept-tac"
                            name="accept terms and conditions"
                            type="checkbox"
                            value={this.state.acceptTAC}
                            onChange={(e) => {
                                this.setState({
                                    acceptTAC: !this.state.acceptTAC,
                                });
                            }}
                        />
                        <label htmlFor="accept-tac">
                            {intl.formatMessage({
                                id: 'create_account.accept_tac_label',
                                defaultMessage:
                                    'I have read and agree to the terms and conditions',
                                description:
                                    'Create account page accept terms and conditions checkbox label',
                            })}
                        </label>
                    </div>
                    <div className={checkboxClasses}>
                        <input
                            id="accept-tou"
                            name="accept terms of use"
                            type="checkbox"
                            value={this.state.acceptTOU}
                            onChange={(e) => {
                                this.setState({
                                    acceptTOU: !this.state.acceptTOU,
                                });
                            }}
                        />
                        <label htmlFor="accept-tou">
                            {intl.formatMessage({
                                id: 'create_account.accept_tou_label',
                                defaultMessage:
                                    'I have read and agree to the Terms of Use',
                                description:
                                    'Create account page accept terms of use checkbox label',
                            })}
                        </label>
                    </div>
                    <div className="set-bd set-bd_super">
                        <div className="set mix-set_alignCenter">
                            <div className="set-hd">
                                <button
                                    type="submit"
                                    id="signin-submit"
                                    className="btn btn_fixedWidth"
                                >
                                    {intl.formatMessage(
                                        messages.sign_in_button_label
                                    )}
                                </button>
                            </div>

                            <div className="set-bd set-bd_separated">
                                <a
                                    className="txt txt_reg mix-txt_colorDefaultBlue"
                                    href="/create-account"
                                >
                                    {intl.formatMessage(
                                        messages.create_account_label
                                    )}
                                </a>
                            </div>

                            <div className="set-bd set-bd_separated">
                                <a
                                    className="txt txt_reg mix-txt_colorDefaultBlue"
                                    href="/forgot-password"
                                >
                                    {intl.formatMessage(
                                        messages.forgot_password_label
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            // </Page>
        );
    }
}

export default injectIntl(SignInForm);
