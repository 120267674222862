import React from 'react';

const ICON_ID = 'icon-check';

export default function (props) {
  return (
    <svg viewBox="0.47 0.47 21.096 16.591" aria-labelledby={ICON_ID}>
      <title id={ICON_ID}>Check Icon</title>
      <polyline fill="none" strokeWidth="1.5" points="1,10.967 6.033,16 21.035,1"/>
    </svg>
  );
}
