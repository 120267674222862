/* eslint-disable */
export const localeStrings = {
    en: {
        'add_transaction.back_to_sessions_label': 'Back to Sessions',
        'add_transaction.bike_label': 'bike',
        'add_transaction.commute_comfort_type': 'Commute-comfort',
        'add_transaction.commute_sport_type': 'Commute-sport',
        'add_transaction.complete_transaction_cta_label':
            'Complete Transaction',
        'add_transaction.confirm_label': 'confirm',
        'add_transaction.cyclocross_type': 'Cyclocross',
        'add_transaction.family_label': 'family',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'footbed',
        'add_transaction.heading': 'Add Transaction to {data}',
        'add_transaction.model_label': 'model',
        'add_transaction.mountain_type': 'Mountain',
        'add_transaction.mtb_trail_type': 'Mountain (trail)',
        'add_transaction.mtb_xc_type': 'Mountain (cross-country)',
        'add_transaction.product_label': 'product',
        'add_transaction.ratings_message':
            'When you complete this transaction, {data} will receive an email to rate this product. You will be able to see this rating on the sessions list and session detail page.',
        'add_transaction.road_type': 'Road',
        'add_transaction.saddle_label': 'saddle',
        'add_transaction.shoe_label': 'shoe',
        'add_transaction.size_label': 'size',
        'add_transaction.transaction_complete_message':
            'Transaction Completed.',
        'add_transaction.triathlon_type': 'Triathlon',
        'add_transaction.tt_tri_type': 'TT/Tri',
        'add_transaction.type_label': 'type',
        'analytics.loading_message':
            'We are compiling your data, this may take a couple minutes',
        'analytics_filters.analytics_by': 'ANALYTICS BY',
        'analytics_filters.filter_by': 'FILTER BY',
        'analytics_filters.products': 'PRODUCTS',
        'analytics_filters.sessions': 'SESSIONS',
        'charts.bike_label_products': 'Bike',
        'charts.bike_label_sessions': 'Bike',
        'charts.commute_comfort_label': 'commute-comfort',
        'charts.commute_sport_label': 'commute-sport',
        'charts.cyclocross_label': 'cyclocross',
        'charts.female_label': 'female',
        'charts.friday_label': 'Friday',
        'charts.high_arch_label': 'high',
        'charts.low_arch_label': 'low',
        'charts.male_label': 'male',
        'charts.medium_arch_label': 'medium',
        'charts.monday_label': 'Monday',
        'charts.mtb_trail_label': 'mtb-trail',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'road',
        'charts.saddle_label_products': 'Saddle',
        'charts.saddle_label_sessions': 'Saddle',
        'charts.saturday_label': 'Saturday',
        'charts.sessions_by_bike_type_label': 'Sessions By Bike Type',
        'charts.sessions_by_day_of_week_label': 'Sessions By Day of Week',
        'charts.sessions_by_employee_label': 'Sessions By Employee',
        'charts.sessions_by_foot_arch_label': 'Sessions By Foot Arch',
        'charts.sessions_by_gender_label': 'Sessions By Gender',
        'charts.sessions_by_product_label': 'Sessions By Product',
        'charts.sessions_by_saddle_height_label': 'Sessions By Saddle Height',
        'charts.sessions_by_saddle_size_label': 'Sessions By Saddle Size',
        'charts.sessions_by_shirt_bike_size_label':
            'Sessions By Bike Size (T-Shirt)',
        'charts.sessions_by_shoe_size_label': 'Sessions By Shoe Size',
        'charts.sessions_by_sitbone_width_label': 'Sessions By Sitbone Width',
        'charts.sessions_by_standard_bike_size_label':
            'Sessions By Bike Size (Standard)',
        'charts.sessions_over_time_label': 'Sessions Over Time',
        'charts.shoe_label_products': 'Shoe',
        'charts.shoe_label_sessions': 'Shoe',
        'charts.sunday_label': 'Sunday',
        'charts.thursday_label': 'Thursday',
        'charts.top_ten_session_label': 'Top Ten Session Dates',
        'charts.top_transactions_label': 'Top Transactions Dates',
        'charts.triathlon_label': 'triathlon',
        'charts.tuesday_label': 'Tuesday',
        'charts.wednesday_label': 'Wednesday',
        'client.all_sessions_link': 'All Sessions',
        'client.bike_progress': 'Bike',
        'client.client_progress_heading': 'Client Progress',
        'client.client_sessions_heading': 'Latest Client Session',
        'client.email': 'Client Email',
        'client.name': 'Client Name',
        'client.not_applicable': 'N/A',
        'client.notes_label': 'Notes',
        'client.progress_types_complete': 'Complete',
        'client.saddle_progress': 'Saddle',
        'client.save_cta': 'Save',
        'client.shoe_progress': 'Shoe',
        'commute-comfort': 'Commute-comfort',
        'commute-sport': 'Commute-sport',
        'country.ad': 'Andorra',
        'country.ae': 'United Arab Emirates',
        'country.af': 'Afghanistan',
        'country.ag': 'Antigua and Barbuda',
        'country.ai': 'Anguilla',
        'country.al': 'Albania',
        'country.am': 'Armenia',
        'country.ao': 'Angola',
        'country.aq': 'Antarctica',
        'country.ar': 'Argentina',
        'country.as': 'American Samoa',
        'country.at': 'Austria',
        'country.au': 'Australia',
        'country.aw': 'Aruba',
        'country.ax': 'Åland Islands',
        'country.az': 'Azerbaijan',
        'country.ba': 'Bosnia and Herzegovina',
        'country.bb': 'Barbados',
        'country.bd': 'Bangladesh',
        'country.be': 'Belgium',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bulgaria',
        'country.bh': 'Bahrain',
        'country.bi': 'Burundi',
        'country.bj': 'Benin',
        'country.bl': 'Saint Barthélemy',
        'country.bm': 'Bermuda',
        'country.bn': 'Brunei',
        'country.bo': 'Bolivia',
        'country.bq': 'Bonaire, Sint Eustatius and Saba',
        'country.br': 'Brazil',
        'country.bs': 'Bahamas',
        'country.bt': 'Bhutan',
        'country.bv': 'Bouvet Island',
        'country.bw': 'Botswana',
        'country.by': 'Belarus',
        'country.bz': 'Belize',
        'country.ca': 'Canada',
        'country.cc': 'Cocos (Keeling) Islands',
        'country.cd': 'Congo (the Democratic Republic of the)',
        'country.cf': 'Central African Republic',
        'country.cg': 'Congo',
        'country.ch': 'Switzerland',
        'country.ci': "Côte d'Ivoire",
        'country.ck': 'Cook Islands',
        'country.cl': 'Chile',
        'country.cm': 'Cameroon',
        'country.cn': 'China',
        'country.co': 'Colombia',
        'country.cr': 'Costa Rica',
        'country.cu': 'Cuba',
        'country.cv': 'Cabo Verde',
        'country.cw': 'Curaçao',
        'country.cx': 'Christmas Island',
        'country.cy': 'Cyprus',
        'country.cz': 'Czechia',
        'country.de': 'Germany',
        'country.dj': 'Djibouti',
        'country.dk': 'Denmark',
        'country.dm': 'Dominica',
        'country.do': 'Dominican Republic',
        'country.dz': 'Algeria',
        'country.ec': 'Ecuador',
        'country.ee': 'Estonia',
        'country.eg': 'Egypt',
        'country.eh': 'Western Sahara',
        'country.er': 'Eritrea',
        'country.es': 'Spain',
        'country.et': 'Ethiopia',
        'country.fi': 'Finland',
        'country.fj': 'Fiji',
        'country.fk': 'Falkland Islands  [Malvinas]',
        'country.fm': 'Micronesia (Federated States of)',
        'country.fo': 'Faroe Islands',
        'country.fr': 'France',
        'country.ga': 'Gabon',
        'country.gb': 'United Kingdom',
        'country.gd': 'Grenada',
        'country.ge': 'Georgia',
        'country.gf': 'French Guiana',
        'country.gg': 'Guernsey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibraltar',
        'country.gl': 'Greenland',
        'country.gm': 'Gambia',
        'country.gn': 'Guinea',
        'country.gp': 'Guadeloupe',
        'country.gq': 'Equatorial Guinea',
        'country.gr': 'Greece',
        'country.gs': 'South Georgia and the South Sandwich Islands',
        'country.gt': 'Guatemala',
        'country.gu': 'Guam',
        'country.gw': 'Guinea-Bissau',
        'country.gy': 'Guyana',
        'country.hk': 'Hong Kong',
        'country.hm': 'Heard Island and McDonald Islands',
        'country.hn': 'Honduras',
        'country.hr': 'Croatia',
        'country.ht': 'Haiti',
        'country.hu': 'Hungary',
        'country.id': 'Indonesia',
        'country.ie': 'Ireland',
        'country.il': 'Israel',
        'country.im': 'Isle of Man',
        'country.in': 'India',
        'country.io': 'British Indian Ocean Territory',
        'country.iq': 'Iraq',
        'country.ir': 'Iran',
        'country.is': 'Iceland',
        'country.it': 'Italy',
        'country.je': 'Jersey',
        'country.jm': 'Jamaica',
        'country.jo': 'Jordan',
        'country.jp': 'Japan',
        'country.ke': 'Kenya',
        'country.kg': 'Kyrgyzstan',
        'country.kh': 'Cambodia',
        'country.ki': 'Kiribati',
        'country.km': 'Comoros',
        'country.kn': 'Saint Kitts and Nevis',
        'country.kp': 'North Korea',
        'country.kr': 'South Korea',
        'country.kw': 'Kuwait',
        'country.ky': 'Cayman Islands',
        'country.kz': 'Kazakhstan',
        'country.la': 'Laos',
        'country.lb': 'Lebanon',
        'country.lc': 'Saint Lucia',
        'country.li': 'Liechtenstein',
        'country.lk': 'Sri Lanka',
        'country.lr': 'Liberia',
        'country.ls': 'Lesotho',
        'country.lt': 'Lithuania',
        'country.lu': 'Luxembourg',
        'country.lv': 'Latvia',
        'country.ly': 'Libya',
        'country.ma': 'Morocco',
        'country.mc': 'Monaco',
        'country.md': 'Moldova',
        'country.me': 'Montenegro',
        'country.mf': 'Saint Martin (French part)',
        'country.mg': 'Madagascar',
        'country.mh': 'Marshall Islands',
        'country.mk': 'Macedonia',
        'country.ml': 'Mali',
        'country.mm': 'Myanmar',
        'country.mn': 'Mongolia',
        'country.mo': 'Macao',
        'country.mp': 'Northern Mariana Islands',
        'country.mq': 'Martinique',
        'country.mr': 'Mauritania',
        'country.ms': 'Montserrat',
        'country.mt': 'Malta',
        'country.mu': 'Mauritius',
        'country.mv': 'Maldives',
        'country.mw': 'Malawi',
        'country.mx': 'Mexico',
        'country.my': 'Malaysia',
        'country.mz': 'Mozambique',
        'country.na': 'Namibia',
        'country.nc': 'New Caledonia',
        'country.ne': 'Niger',
        'country.nf': 'Norfolk Island',
        'country.ng': 'Nigeria',
        'country.ni': 'Nicaragua',
        'country.nl': 'Netherlands',
        'country.no': 'Norway',
        'country.np': 'Nepal',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'New Zealand',
        'country.om': 'Oman',
        'country.pa': 'Panama',
        'country.pe': 'Peru',
        'country.pf': 'French Polynesia',
        'country.pg': 'Papua New Guinea',
        'country.ph': 'Philippines',
        'country.pk': 'Pakistan',
        'country.pl': 'Poland',
        'country.pm': 'Saint Pierre and Miquelon',
        'country.pn': 'Pitcairn',
        'country.pr': 'Puerto Rico',
        'country.ps': 'Palestine, State of',
        'country.pt': 'Portugal',
        'country.pw': 'Palau',
        'country.py': 'Paraguay',
        'country.qa': 'Qatar',
        'country.re': 'Réunion',
        'country.ro': 'Romania',
        'country.rs': 'Serbia',
        'country.ru': 'Russia',
        'country.rw': 'Rwanda',
        'country.sa': 'Saudi Arabia',
        'country.sb': 'Solomon Islands',
        'country.sc': 'Seychelles',
        'country.sd': 'Sudan',
        'country.se': 'Sweden',
        'country.sg': 'Singapore',
        'country.sh': 'Saint Helena, Ascension and Tristan da Cunha',
        'country.si': 'Slovenia',
        'country.sj': 'Svalbard and Jan Mayen',
        'country.sk': 'Slovakia',
        'country.sl': 'Sierra Leone',
        'country.sm': 'San Marino',
        'country.sn': 'Senegal',
        'country.so': 'Somalia',
        'country.sr': 'Suriname',
        'country.ss': 'South Sudan',
        'country.st': 'Sao Tome and Principe',
        'country.sv': 'El Salvador',
        'country.sx': 'Sint Maarten (Dutch part)',
        'country.sy': 'Syria',
        'country.sz': 'Swaziland',
        'country.tc': 'Turks and Caicos Islands',
        'country.td': 'Chad',
        'country.tf': 'French Southern Territories',
        'country.tg': 'Togo',
        'country.th': 'Thailand',
        'country.tj': 'Tajikistan',
        'country.tk': 'Tokelau',
        'country.tl': 'Timor-Leste',
        'country.tm': 'Turkmenistan',
        'country.tn': 'Tunisia',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trinidad and Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Taiwan',
        'country.tz': 'Tanzania',
        'country.ua': 'Ukraine',
        'country.ug': 'Uganda',
        'country.um': 'United States Minor Outlying Islands',
        'country.us': 'United States of America',
        'country.uy': 'Uruguay',
        'country.uz': 'Uzbekistan',
        'country.va': 'Holy See',
        'country.vc': 'Saint Vincent and the Grenadines',
        'country.ve': 'Venezuela',
        'country.vg': 'Virgin Islands (British)',
        'country.vi': 'Virgin Islands (U.S.)',
        'country.vn': 'Vietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis and Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Yemen',
        'country.yt': 'Mayotte',
        'country.za': 'South Africa',
        'country.zm': 'Zambia',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Cyclocross',
        'date.all_time': 'DATE: ALL TIME',
        'date.last_month': 'DATE: LAST MONTH',
        'date.last_week': 'DATE: LAST WEEK',
        'date.last_year': 'DATE: LAST YEAR',
        'employees.add_cta_label': 'Add Employee',
        'employees.delete_cta_label': 'Delete Employee',
        'employees.employee_id_label': 'Employee ID',
        'employees.heading': 'Employees',
        'employees.intro':
            'A timeline of all your latest employees with Retül Technology.',
        'employees.update_cta_label': 'Update Employee',
        'filters.all_label': 'ALL',
        'filters.client_all': 'All',
        'filters.client_anonymous': 'Anonymous',
        'filters.client_identified': 'Identified',
        'filters.client_label': 'Client Type',
        'filters.employees_label': 'Employees',
        'filters.filters_heading': 'Filters',
        'filters.hide_filters': 'Show Filters',
        'filters.locations_label': 'Locations',
        'filters.rating_all': 'ALL',
        'filters.rating_label': 'Rating',
        'filters.rating_none': 'NONE',
        'filters.session_all': 'All',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Session Type',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Show Filters',
        'filters.status_all': 'All',
        'filters.status_closed': 'Closed',
        'filters.status_label': 'Status',
        'filters.status_open': 'Open',
        'footer.privacy_link_label': 'Privacy Policy',
        'footer.terms_link_label': 'Terms of Use',
        'form.address_1_field_error': 'Address is required.',
        'form.address_1_field_label': 'Address',
        'form.city_field_error': 'City is required.',
        'form.city_field_label': 'City',
        'form.country_field_error': 'Country is required.',
        'form.country_field_label': 'Country',
        'form.delete_field_confirm_message':
            'Are you sure you want to delete {data}?',
        'form.delete_field_label': 'Delete',
        'form.edit_field_label': 'Edit',
        'form.email_field_error':
            'Email is required and must be a valid email format.',
        'form.email_field_label': 'email',
        'form.name_field_error': 'Name is required.',
        'form.name_field_label': 'Name',
        'form.shop_field_label': 'shop',
        'form.shops_field_label': 'Shops',
        'form.state_field_error': 'State is required.',
        'form.state_field_label': 'State',
        'form.zip_field_error': 'Zip is required.',
        'form.zip_field_label': 'Zip',
        'gender.all': 'GENDER: ALL',
        'gender.female': 'GENDER: FEMALE',
        'gender.male': 'GENDER: MALE',
        'landing.heading': 'Welcome to Retailer Portal',
        'landing.intro':
            'Access data, sessions and client information captured by Retül technology.',
        'landing.sign_in_or_create_account_label': 'Sign In / Create Account',
        'locale.label_cs': 'Česky',
        'locale.label_de': 'Deutsch',
        'locale.label_en': 'English',
        'locale.label_en-us': 'English (US)',
        'locale.label_es': 'Espanol',
        'locale.label_fr': 'Français',
        'locale.label_it': 'Italiano',
        'locale.label_ja': '日本語',
        'locale.label_ko': '한국어',
        'locale.label_nl': 'Nederlands',
        'locale.label_pl': 'Polski',
        'locale.label_pt': 'Português',
        'locale.label_pt-br': 'Português do Brasil',
        'locale.label_zh-cn': '简体中文',
        'locale.label_zh-tw': '繁體中文',
        'locale.locale_picker_cancel_label': 'Cancel',
        'locale.locale_picker_heading': 'Choose your language.',
        'locale.locale_picker_save_label': 'Save',
        'locations.add_cta_label': 'Add Location',
        'locations.delete_cta_label': 'Delete Location',
        'locations.empty':
            'No locations have been added, to use Retül Apps please add a location.',
        'locations.empty_cta_label': 'Manage Locations',
        'locations.heading': 'Manage Locations',
        'locations.intro':
            'Add, edit and remove locations using Retül Technology.',
        'locations.location_id_label': 'Location ID',
        'locations.update_cta_label': 'Update Location',
        'metrics.closed_session_tooltip':
            'Closed sessions indicate sessions that have a transaction recorded. If a client made or did not make a purchase, {closingSessions} helps you track sales from Retül technology.',
        'metrics.closed_sessions': 'Closed Sessions',
        'metrics.closing_session_link': 'closing session',
        'metrics.total_sessions': 'Total Sessions',
        'metrics.unique_client_emails': 'Unique Client Emails',
        'metrics.unique_emails_tooltip':
            'This number represents sessions that have been conducted with unique clients. Unique clients are determined by their email address.',
        'mtb-trail': 'Mountain (trail)',
        'mtb-xc': 'Mountain (cross-country)',
        'nav.analytics': 'Analytics',
        'nav.greeting_label': 'Hello, {data}',
        'nav.manage_employees_link_label': 'Manage Employees',
        'nav.manage_locations_link_label': 'Manage Locations',
        'nav.sessions': 'Sessions',
        'nav.sign_out_link_label': 'Sign Out',
        'pagination.next': 'Next',
        'pagination.page': 'Page',
        'pagination.previous': 'Previous',
        road: 'Road',
        'session_details.add_transaction_cta_label': 'Add Transaction',
        'session_details.all_sessions_link': 'All Sessions',
        'session_details.bike_product': 'Bike',
        'session_details.client': 'Client',
        'session_details.client_review_label': 'Client Review',
        'session_details.complete_session_cta_label': 'Mark as closed',
        'session_details.complete_session_message':
            'Close this session by adding any transactions made by the client, or',
        'session_details.complete_status': 'Closed',
        'session_details.created_on': 'Created on: {data}',
        'session_details.delete_link': 'Delete',
        'session_details.employee': 'Employee: {data}',
        'session_details.family_label': 'Family',
        'session_details.incomplete_status': 'Open',
        'session_details.model_label': 'Model',
        'session_details.no_transactions_message':
            'No transactions have been added.',
        'session_details.product_label': 'Product',
        'session_details.saddle_product': 'Saddle',
        'session_details.shoe_product': 'Shoe',
        'session_details.shop': 'Shop: {data}',
        'session_details.size_label': 'Size',
        'session_details.status_label': 'Status',
        'session_details.transactions_heading': 'Session Transactions',
        'session_products.arch_height_label': 'Arch Height: {data}',
        'session_products.bike_size_label': 'Bike Size: {data}',
        'session_products.saddle_height_label': 'Saddle Height: {data}mm',
        'session_products.saddle_width_label': 'Saddle Width: {data}mm',
        'session_products.session_product_label': 'Session Data',
        'session_products.shoe_size_label': 'Shoe Size: {data}',
        'sessions.add_transaction_cta_label': 'Add Transaction',
        'sessions.client_anonymous': 'Anonymous',
        'sessions.client_label': 'Client',
        'sessions.closed_status': 'CLOSED',
        'sessions.csv_downloading_message':
            'We are gathering all of your sessions for your CSV, this may take a couple minutes.',
        'sessions.date_label': 'Date',
        'sessions.employee_label': 'Employee',
        'sessions.export_csv_link': 'Export List (.CSV)',
        'sessions.filter_empty':
            'Sorry, there are no Sessions that match your filter criteria. Please reset the filter selection.',
        'sessions.filter_empty_cta_label': 'Reset Filters',
        'sessions.heading': 'Sessions',
        'sessions.intro':
            'A timeline of all your latest sessions with Retül Technology.',
        'sessions.location_label': 'Location',
        'sessions.no_rating': 'No Rating',
        'sessions.open_status': 'OPEN',
        'sessions.rating_label': 'Rating',
        'sessions.session_id_label': 'Session ID',
        'sessions.session_loading_message':
            'We are gathering all of your sessions, this may take a moment.',
        'sessions.status_label': 'Status',
        'sign_in.heading': 'Sign In',
        'sign_in.intro':
            'Enter your email and password to get started, or choose "Register Account" to make a new one.',
        'status.all': 'STATUS: ALL',
        'status.complete': 'STATUS: CLOSED',
        'status.incomplete': 'STATUS: OPEN',
        triathlon: 'Triathlon',
        'POWER MIMIC DESCRIPTION':
            'This saddle makes numbness and pain disappear with a design that mimics the body’s response to different types of pressure to create equilibrium within soft tissue.',
        'date_picker.all_time': 'All Sessions',
        'date_picker.date_range': 'Date Range',
        'date_picker.last_month': 'Last Month',
        'date_picker.last_week': 'Last Week',
        'date_picker.last_year': 'Last Year',
        'date_picker.this_month': 'This Month',
        'date_picker.this_week': 'This Week',
        'date_picker.this_year': 'This Year',
        'session_details.client_notes': 'Client Notes',
        'session_details.footbed_product': 'Footbed',
        'session_products.it_width_label': 'IT Width: {data}mm',
    },
};
