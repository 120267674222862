import React, {Component} from 'react';
import classNames from 'classnames';
import './Intro.css';

export default class Intro extends Component {
  render() {
    const {header} = this.props;

    return (
      <div className="Intro">
        <h1 className="header">
          {header}
        </h1>
        {this.props.summary ? (
          <p className={this.summaryClasses}>
            {this.props.summary}
          </p>
        ) : null}
      </div>
    )
  }

  get summaryClasses() {
    return classNames(
      'summary',
      {
        'summary_wide': this.props.wide
      }
    );
  }
}
