function getHeadingByName(headings, headingName) {
  return headings.find(heading => heading.name === headingName)
}

export function getHeadingOrderAttribute(headings, headingName) {
  const heading = getHeadingByName(headings, headingName);
  const {orderAttribute} = heading;
  return orderAttribute;
}

export function isOrderable(headings, headingName) {
  return !!getHeadingByName(headings, headingName)
}