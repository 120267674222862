import { defineMessages } from 'react-intl';

export default defineMessages({
  'heading': {
    'id': 'add_transaction.heading',
    'defaultMessage': 'Add Transaction to {data}',
    'description': 'Sessions Page title welcome message'
  },
  'complete_transaction': {
    'id': 'add_transaction.complete_transaction_cta_label',
    'defaultMessage': 'Complete Transaction',
    'description': 'Label for CTA to complete the transaction'
  },
  'transaction_completed': {
    'id': 'add_transaction.transaction_complete_message',
    'defaultMessage': 'Transaction Completed.',
    'description': 'Message displayed when transaction has been completed'
  },
  'back_to_sessions': {
    'id': 'add_transaction.back_to_sessions_label',
    'defaultMessage': 'Back to Sessions',
    'description': 'Label for link to direct user back to Sessions'
  },
  'ratings_message': {
    'id': 'add_transaction.ratings_message',
    'defaultMessage': 'When you complete this transaction, {data} will receive an email to rate this product. You will be able to see this rating on the sessions list and session detail page.',
    'description': 'Label for link to direct user back to Sessions'
  },
  'add_transaction.product_label': {
    'id': 'add_transaction.product_label',
    'defaultMessage': 'product',
    'description': 'Product label for the Add Transactiond flow'
  },
  'add_transaction.type_label': {
    'id': 'add_transaction.type_label',
    'defaultMessage': 'type',
    'description': 'Type label for the Add Transactiond flow'
  },
  'add_transaction.family_label': {
    'id': 'add_transaction.family_label',
    'defaultMessage': 'family',
    'description': 'Family label for the Add Transactiond flow'
  },
  'add_transaction.size_label': {
    'id': 'add_transaction.size_label',
    'defaultMessage': 'size',
    'description': 'Size label for the Add Transactiond flow'
  },
  'add_transaction.confirm_label': {
    'id': 'add_transaction.confirm_label',
    'defaultMessage': 'confirm',
    'description': 'Confirm label for the Add Transactiond flow'
  },
  'add_transaction.model_label': {
    'id': 'add_transaction.model_label',
    'defaultMessage': 'model',
    'description': 'Model label for the Add Transaction flow'
  },
  'bike': {
    'id': 'add_transaction.bike_label',
    'defaultMessage': 'bike',
    'description': 'Bike label for the Add Transaction flow'
  },
  'shoe': {
    'id': 'add_transaction.shoe_label',
    'defaultMessage': 'shoe',
    'description': 'Shoe label for the Add Transaction flow'
  },
  'saddle': {
    'id': 'add_transaction.saddle_label',
    'defaultMessage': 'saddle',
    'description': 'Saddle label for the Add Transaction flow'
  },
  'footbed': {
    'id': 'add_transaction.footbed_label',
    'defaultMessage': 'footbed',
    'description': 'Footbed label for the Add Transaction flow'
  },
  'commute-comfort': {
    'id': 'add_transaction.commute_comfort_type',
    'defaultMessage': 'Commute-comfort',
    'description': 'Label for Commute Comfort product types'
  },
  'commute-sport': {
    'id': 'add_transaction.commute_sport_type',
    'defaultMessage': 'Commute-sport',
    'description': 'Label for Commute Sport product types'
  },
  'cyclocross': {
    'id': 'add_transaction.cyclocross_type',
    'defaultMessage': 'Cyclocross',
    'description': 'Label for Cyclocross product types'
  },
  'fitness': {
    'id': 'add_transaction.fitness_type',
    'defaultMessage': 'Fitness',
    'description': 'Label for Fitness product types'
  },
  'mountain': {
    'id': 'add_transaction.mountain_type',
    'defaultMessage': 'Mountain',
    'description': 'Label for Mountain product types'
  },
  'mtb-trail': {
    'id': 'add_transaction.mtb_trail_type',
    'defaultMessage': 'Mountain (trail)',
    'description': 'Label for Mountain (trail) product types'
  },
  'mtb-xc': {
    'id': 'add_transaction.mtb_xc_type',
    'defaultMessage': 'Mountain (cross-country)',
    'description': 'Label for Mountain (cross-country) product types'
  },
  'road': {
    'id': 'add_transaction.road_type',
    'defaultMessage': 'Road',
    'description': 'Label for Road product types'
  },
  'triathlon': {
    'id': 'add_transaction.triathlon_type',
    'defaultMessage': 'Triathlon',
    'description': 'Label for Triathlon product types'
  },
  'tt/tri': {
    'id': 'add_transaction.tt_tri_type',
    'defaultMessage': 'TT/Tri',
    'description': 'Label for TT/Tri product types'
  },
});
