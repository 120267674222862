export function getFields(obj) {
  return assignFields(obj);
}

export function assignFields(obj) {
  const {fieldsArray, values} = obj;
  return fieldsArray.map((field) => {
    const {fieldName, fieldLabel, fieldType, errorMessage, required, editable, listItems} = field;
    const fieldValue = values && values[fieldName];
    return {
      fieldName,
      fieldLabel,
      fieldType,
      errorMessage,
      required,
      editable,
      listItems,
      value: fieldValue || ''
    }
  });
}

export function initializeFieldStateData(fieldArray) {
  const {fields, checkboxGroups} = fieldArray;
  const fieldsData = fields.reduce((fieldObject, nextField) => {
    const {fieldName, value} = nextField;
    return {...fieldObject, [fieldName]: {value: value || ''}}
  }, {});

  return {
    fields: fieldsData,
    checkboxGroups
  };
}

function getCheckedIds(checkboxes) {
  return checkboxes.reduce((checkboxIds, checkbox) => {

    if (checkbox.checked) {
      return [...checkboxIds, checkbox.value]
    } else {
      return checkboxIds
    }
  }, [])
}

export function getCheckboxStateData(stateObj) {
  const {checkboxGroups} = stateObj;
  if (checkboxGroups) {
    return checkboxGroups.reduce((checkboxGroupObj, checkboxGroup) => {
      return {
        ...checkboxGroupObj,
        [checkboxGroup.checkboxGroupName]: getCheckedIds(checkboxGroup.checkboxes)
      }
    }, {});
  }
}

export function getFieldStateData(stateObj) {
  const {fields} = stateObj;
  return Object.keys(fields).reduce((fieldsObject, nextKey) => {
    return {...fieldsObject, [nextKey]: fields[nextKey].value}
  }, {})
}

export function generateFieldLabelText(name) {
  return fieldLabelMap[name] || name.toUpperCase();
}

export function getFieldNames(fieldsArrayObject) {
  return fieldsArrayObject.map((fieldObject) => {
    const {field} = fieldObject;

    return field;
  })
}

export function updateFieldValue(data) {
  const {state, fieldName, value} = data;
  const fields = {...state.fields, [fieldName]: {value}};
  return {fields};
}

export function updateCheckboxValue(data) {
  return {
    checkboxGroups: [
      {...getCheckboxGroup(data), checkboxes: updateCheckboxes(data)}]
  };
}

function getCheckboxGroup(data) {
  const {state, checkboxGroupName} = data;
  return state.checkboxGroups.find((checkboxGroup => checkboxGroup.checkboxGroupName === checkboxGroupName));
}

function getCheckboxes(data) {
  return getCheckboxGroup(data).checkboxes;
}

function updateCheckboxes(data) {
  const {value} = data;
  return getCheckboxes(data).map(checkbox => {
    if (checkbox.value === value) {
      return {...checkbox, checked: !checkbox.checked};
    } else {
      return checkbox;
    }
  });
}

const fieldLabelMap = {
  'address_1': 'ADDRESS',
  'state': 'STATE/PROVINCE',
  'zip_code': 'ZIP'
};

export function isActiveCheckbox(value, activeCheckboxValues) {
  return activeCheckboxValues.find(activeCheckboxValue => {
    return activeCheckboxValue === value;
  });
}
