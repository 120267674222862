export function sortObjects({data, sortBy, direction}) {
  return data.sort((first, second) => {
    const a = typeof first[sortBy] === 'string' ? first[sortBy].toLowerCase() : first[sortBy];
    const b = typeof second[sortBy] === 'string' ? second[sortBy].toLowerCase() : second[sortBy];

    if (a > b) return 1 * direction;
    if (b > a) return -1 * direction;
    return 0;
  });
}
