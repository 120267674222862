import React, {Component} from 'react';
import {renderConfirmationModal, renderResourceModal} from '../../utils/modalHelper';
import {getLocationFields} from '../../selectors/locations';
import {injectIntl} from 'react-intl';
import messages from '../../types/constantsMessages';
import locationMessages from './messages';
import {sortObjects} from '../../utils/sortHelper';

class LocationsTable extends Component {
  constructor(props) {
    super();

    this.intl = props.intl;
    this.page = {
      'editLink': this.intl.formatMessage(messages.form_edit_field_label),
      'deleteLink': this.intl.formatMessage(messages.form_delete_field_label),
      'name': this.intl.formatMessage(messages['form.name_field_label']),
      'address': this.intl.formatMessage(messages['form.address_1_field_label']),
      'city': this.intl.formatMessage(messages['form.city_field_label']),
      'state': this.intl.formatMessage(messages['form.state_field_label']),
      'country': this.intl.formatMessage(messages['form.country_field_label']),
      'locationID': this.intl.formatMessage(locationMessages.location_id),
      'updateLocation': this.intl.formatMessage(locationMessages.update_location),
      'deleteLocation': this.intl.formatMessage(locationMessages.delete_location),
    };

    this.state = {
      openEditModal: false,
      openDeleteModal: false,
      modalData: props.data[0],
      sortBy: 'name',
      sortAscending: true
    };

    this.openEditModal = this.openEditModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.editModalClosed = this.editModalClosed.bind(this);
    this.deleteModalClosed = this.deleteModalClosed.bind(this);
  }

  openEditModal(data) {
    this.setState({
      openEditModal: true,
      modalData: data
    });
  }

  openDeleteModal(data) {
    this.setState({
      openDeleteModal: true,
      modalData: data
    });
  }

  editModalClosed() {
    this.setState({
      openEditModal: false,
    });
  }

  deleteModalClosed() {
    this.setState({
      openDeleteModal: false,
    });
  }

  handleSortClick(e) {
    const {value} = e.target;

    this.setState({
      'sortBy': value,
      'sortAscending': this.state.sortBy === value ? !this.state.sortAscending : true
    });
  }

  sortLocations(data) {
    const sortBy = this.state.sortBy;
    const direction = this.state.sortAscending ? 1 : -1;

    return sortObjects({data, sortBy, direction});
  }

  renderModalTrigger(text, onClick) {
    return (
      <div className="open-modal-link-container">
        <button
          className="modal-link"
          onClick={onClick}
        >
          {text}
        </button>
      </div>
    )
  }

  renderUpdateResourceModal(displayedText) {
    const {updateResource} = this.props;
    const {id} = this.state.modalData;

    return renderResourceModal(
      {
        displayedText: this.page.editLink,
        fields: getLocationFields(this.state.modalData),
        id,
        submitText: this.page.updateLocation,
        submitFunction: updateResource,
        openModal: this.state.openEditModal,
        modalClosed: this.editModalClosed,
      }
    )
  }

  renderDeleteResourceModal(displayedText) {
    const {deleteResource} = this.props;
    const {name, id} = this.state.modalData;

    return renderConfirmationModal(
      {
        displayedText: this.page.deleteLink,
        id,
        submitText: this.page.deleteLocation,
        confirmMessage: this.intl.formatMessage(messages.form_delete_field_confirm_message, {data: name}),
        confirmFunction: deleteResource,
        openModal: this.state.openDeleteModal,
        modalClosed: this.deleteModalClosed,
      }
    )
  }

  renderTableBody() {
    const {data} = this.props;
    const locations = this.sortLocations(data);

    return locations.map((d, i) => {
        const {id, name, address_1, city, state, country} = d;
        return (
          <tr key={i}>
            <td className="id">
              {id}
            </td>
            <td>
              {name}
            </td>
            <td>
              {address_1}
            </td>
            <td>
              {city}
            </td>
            <td>{state}</td>
            <td>{country}</td>
            <td>
              <div className="modify-resource-options">
                <div>
                  {this.renderModalTrigger(this.page.editLink, () => this.openEditModal(d))}

                </div>
                <div>
                  |
                </div>
                <div>
                  {this.renderModalTrigger(this.page.deleteLink, () => this.openDeleteModal(d))}
                </div>
              </div>
            </td>
          </tr>
        )
      }
    )
  }

  render() {
    return (
      <table className="Table Table_spaced">
        <thead>
        <tr>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="id"
            >
              {this.page.locationID}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="name"
            >
              {this.page.name}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="address_1"
            >
              {this.page.address}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="city"
            >
              {this.page.city}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="state"
            >
              {this.page.state}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="country"
            >
              {this.page.country}
            </button>
          </th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {this.renderTableBody()}
        </tbody>
        {this.renderUpdateResourceModal()}
        {this.renderDeleteResourceModal()}
      </table>
    )
  }
}

export default injectIntl(LocationsTable);
