export const donutColorValues = {
  1: [ '#008BCD' ],
  2: [ '#8DB954', '#2D6FA7' ],
  3: [ '#8DB954', '#2D6FA7', '#0F304A' ],
  4: [ '#BFD588', '#8DB954', '#2D6FA7', '#0F304A' ],
  5: [ '#BFD588', '#8DB954', '#49A8D9', '#2D6FA7', '#0F304A' ],
  6: [ '#BFD588', '#8DB954', '#49A8D9', '#398AC7', '#2D6FA7', '#0F304A' ],
  7: [ '#BFD588', '#8DB954', '#60B18B', '#49A8D9', '#398AC7', '#2D6FA7', '#0F304A' ],
  8: [ '#DFEAC3', '#BFD588', '#8DB954', '#60B18B', '#49A8D9', '#398AC7', '#2D6FA7', '#0F304A' ],
  9: [ '#DFEAC3', '#BFD588', '#8DB954', '#60B18B', '#49A8D9', '#398AC7', '#2D6FA7', '#1F527C', '#0F304A' ],
  10: [ '#DFEAC3', '#BFD588', '#8DB954', '#60B18B', '#49A8D9', '#398AC7', '#2D6FA7', '#1F527C', '#0F304A', '#051824' ]
};