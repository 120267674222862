import React, { Component } from 'react';
import Main from '../../components/Main/Main';
import SignInForm from '../../components/SignInForm/SignInForm';
import Intro from '../../components/Intro/Intro';
import Loader from '../../components/Loader/Loader';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';
import { injectIntl } from 'react-intl';
import messages from './messages';

class SignIn extends Component {
    constructor(props) {
        super(props);

        const { intl } = props;

        this.page = {
            heading: intl.formatMessage(messages.heading),
            intro: intl.formatMessage(messages.intro),
        };
    }

    renderSignInForm() {
        let { handleSubmit, serverErrors } = this.props;
        let showTerms = false;
        if (serverErrors && serverErrors.length > 0) {
            showTerms = serverErrors[0].message === 'USER_NOT_ACCEPTED_TERMS';
        }

        return componentWithErrorBoundary(
            <SignInForm
                handleSubmit={handleSubmit}
                serverErrors={serverErrors}
                showTerms={showTerms}
                fetchingStatus={this.props.fetchingStatus}
            />
        );
    }

    render(props) {
        return (
            <Main className="SignIn">
                <Loader
                    isLoading={this.props.fetchingStatus === 'REQUESTING'}
                />
                <section className="top top_medium">
                    <div className="container">
                        <Intro
                            header={this.page.heading}
                            summary={this.page.intro}
                        />
                    </div>
                </section>
                <section className="bottom bottom_extraPad bottom_dark">
                    <div className="container">{this.renderSignInForm()}</div>
                </section>
            </Main>
        );
    }
}

export default injectIntl(SignIn);
