import React, { Component } from 'react';
import { scaleBand, scaleLinear } from 'd3-scale';
import { min, max } from 'd3-array';
import Bars from '../Bars/Bars';
import Axis from '../Axis/Axis';
import { getDimensions } from "../../../../utils/chartDimensions";
import { updatedWidthState } from '../../../../utils/chartHelper';
import componentWithErrorBoundary from '../../../../componentsHighOrder/componentWithErrorBoundary';

export default class LargeVerticalBarChart extends Component {
  constructor(props) {
    super(props);
    this.state =
      {
        dimensions: {
          width: 800,
          height: 250,
          margin: {
            top: 30,
            bottom: 30,
            right: 40,
            left: 40
          }
        }
      }
  }

  componentDidMount() {
    const { parentWidth } = this.props;
    const { width } = getDimensions(this.state);
    if (parentWidth && parentWidth !== width) {
      this.setState(updatedWidthState(this.state, parentWidth));
    }
  }

  componentDidUpdate() {
    const { parentWidth } = this.props;
    const { width } = getDimensions(this.state);
    if (parentWidth && parentWidth !== width) {
      this.setState(updatedWidthState(this.state, parentWidth));
    }
  }

  xDomain() {
    return this.props.getItems();
  }

  yDomain() {
    const quantities = this.props.data.map(d => d.quantity);
    return [ 0, max(quantities) ];
  }

  yRange() {
    return [ this.adjustedHeight(), 0 ];
  }

  xRange() {
    return [ 0, this.adjustedWidth() ]
  }

  calculateYScale() {
    return scaleLinear()
      .domain(this.yDomain())
      .range(this.yRange())
  }

  calculateXScale() {
    return scaleBand()
      .rangeRound(this.xRange(), 0)
      .domain(this.xDomain())
  }


  renderXAxisLabels(xScale) {
    const items = this.props.getItems();
    const middleIndex = Math.round(items.length / 2);

    return (
      componentWithErrorBoundary(
        <Axis
          axisType={ 'XAxis-Labels' }
          orientation="Bottom"
          scale={ xScale }
          tickValues={ [ min(items), items[ middleIndex ], max(items) ] }
        />
      )

    )
  }

  renderXAxisTicks(xScale) {
    return (
      componentWithErrorBoundary(
        <Axis
          axisType={ 'XAxis-Ticks' }
          orientation="Bottom"
          scale={ xScale }
          tickSize={ 10 }
        />
      )

    )
  }


  renderYAxisLabels(yScale) {
    return (
      componentWithErrorBoundary(
        <Axis
          axisType={ 'YAxis-Labels' }
          orientation="Left"
          scale={ yScale }
          ticks={ [ 5 ] }
        />
      )
    )
  }

  renderYAxisTicks(yScale) {
    return (
      componentWithErrorBoundary(
        <Axis
          axisType={ 'YAxis-Ticks' }
          orientation="Left"
          scale={ yScale }
          ticks={ [ 5 ] }
          tickSize={ -this.adjustedWidth() + 10 }
        />
      )
    )
  }

  renderBars(scales) {
    return (
      <Bars
        scales={ scales }
        data={ this.props.data }
        upperLimit={ this.adjustedHeight() }
        width={this.adjustedWidth()}
      />
    )
  }

  adjustedHeight() {
    const { height, margin } = getDimensions(this.state);
    return height - margin.top - margin.bottom;
  }

  adjustedWidth() {
    const { width, margin } = getDimensions(this.state);
    return width - margin.left - margin.right;
  }

  render() {
    const { height, margin, width } = getDimensions(this.state);

    const scales = {
      xScale: this.calculateXScale(),
      yScale: this.calculateYScale()
    };

    return (
      <svg
        className='Large-Vertical-Bar-Chart'
        height={ height }
        width={ width }
      >
        <g
          className="chart-items"
          transform={ `translate(${margin.left}, ${margin.top})` }
        >
          <g className="y-axis-group labels">
            { this.renderYAxisLabels(scales.yScale) }
          </g>
          <g className="y-axis-group ticks"
             transform={ `translate(${10}, ${0})` }
          >
            { this.renderYAxisTicks(scales.yScale) }
          </g>
          <g className="bars-group"
             transform={ `translate(${0}, ${0})` }
             width={ this.adjustedWidth() - 20}
          >
            { this.renderBars(scales) }
          </g>
          <g className="x-axis-group ticks"
             transform={ `translate(${0}, ${this.adjustedHeight()})` }
          >
            { this.renderXAxisTicks(scales.xScale) }
          </g>
          <g className="x-axis-group labels"
             transform={ `translate(${0}, ${this.adjustedHeight() + 5})` }
          >
            { this.renderXAxisLabels(scales.xScale) }
          </g>
        </g>
      </svg>
    )
  }
}
