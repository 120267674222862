import React, { Component } from 'react';
import { connect } from 'react-redux';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import * as authenticationActionCreators from '../actions/authentication';
import * as createAccountActionCreators from '../actions/createAccount';
import Main from '../components/Main/Main';
import Text from '../components/Text/Text';
import Intro from '../components/Intro/Intro';
import messages from '../utils/messages';
import CreateAccount from '../components/CreateAccount/CreateAccount';
import { injectIntl } from 'react-intl';

export class CreateAccountContainer extends Component {
    constructor(props) {
        super(props);

        const { intl } = props;

        this.page = {
            heading: intl.formatMessage(messages.create_account_heading),
            intro: intl.formatMessage(messages.create_account_intro),
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.createAccount = this.createAccount.bind(this);
    }

    handleSubmit(data) {
        const { fetchJWT } = this.props;
        fetchJWT(data, this.handleServerError);
    }

    // Do passport-cognito account creation
    createAccount(data) {
        const { createAccount } = this.props;
        createAccount(data, this.handleServerError);
    }

    render() {
        if (this.props.userCreated) {
            return (
                <Main className="SignIn">
                    <section className="top top_medium">
                        <div className="container">
                            <Intro
                                header={this.page.heading}
                                summary={this.page.intro}
                            />
                        </div>
                    </section>
                    <section className="bottom bottom_extraPad bottom_dark">
                        <div className="container mix-set_alignCenter">
                            <ul>
                                <li>
                                    <Text color="DefaultBlue" weight="light">
                                        {this.props.intl.formatMessage(
                                            messages.USER_CREATED
                                        )}
                                    </Text>
                                </li>
                                <li>
                                    <Text color="DefaultBlue" weight="light">
                                        {this.props.intl.formatMessage(
                                            messages.USER_CREATED_VERIFY_INSTRUCTIONS
                                        )}
                                    </Text>
                                </li>
                            </ul>
                        </div>
                    </section>
                </Main>
            );
        } else {
            return componentWithErrorBoundary(
                <CreateAccount
                    handleSubmit={this.handleSubmit}
                    createAccount={this.createAccount}
                    serverErrors={this.props.error}
                    fetchingStatus={this.props.fetchingStatus}
                />
            );
        }
    }
}

function mapStateToProps(state) {
    return { ...state.authentication, ...state.createAccount };
}

export default injectIntl(
    connect(mapStateToProps, {
        ...authenticationActionCreators,
        ...createAccountActionCreators,
    })(CreateAccountContainer)
);
