/* eslint-disable */
export const localeStrings = {
    es: {
        'add_transaction.back_to_sessions_label': 'Volver a las sesiones',
        'add_transaction.bike_label': 'Bicicleta',
        'add_transaction.commute_comfort_type': 'Transporte-comodidad',
        'add_transaction.commute_sport_type': 'Transporte-deporte',
        'add_transaction.complete_transaction_cta_label': 'Completar',
        'add_transaction.confirm_label': 'Confirmar',
        'add_transaction.cyclocross_type': 'Ciclocross',
        'add_transaction.family_label': 'Familia',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'Plantilla',
        'add_transaction.heading': 'Agregar Transacción a {data}',
        'add_transaction.model_label': 'Modelo',
        'add_transaction.mountain_type': 'Montaña',
        'add_transaction.mtb_trail_type': 'Montaña (trail)',
        'add_transaction.mtb_xc_type': 'Montaña (cross-country)',
        'add_transaction.product_label': 'Producto',
        'add_transaction.ratings_message':
            'Cuando complete esta transacción, {data} recibirás un email para calificar este producto. Podrás ver esta clasificación en la lista de sesiones y en la página de detalles de la sesión.',
        'add_transaction.road_type': 'Carretera',
        'add_transaction.saddle_label': 'Sillín',
        'add_transaction.shoe_label': 'Zapatilla',
        'add_transaction.size_label': 'Talla',
        'add_transaction.transaction_complete_message': 'Completar',
        'add_transaction.triathlon_type': 'Triatlón',
        'add_transaction.tt_tri_type': 'TT / Tri',
        'add_transaction.type_label': 'Tipo',
        'analytics.loading_message':
            'Estamos recopilando tus datos, puede que tarde un par de minutos.',
        'analytics_filters.analytics_by': 'ANÁLISIS POR',
        'analytics_filters.filter_by': 'FILTRAR POR',
        'analytics_filters.products': 'PRODUCTOS',
        'analytics_filters.sessions': 'SESIONES',
        'charts.bike_label_products': 'Bicicleta',
        'charts.bike_label_sessions': 'Bicicleta',
        'charts.commute_comfort_label': 'Transporte-comodidad',
        'charts.commute_sport_label': 'Transporte-deporte',
        'charts.cyclocross_label': 'Ciclocross',
        'charts.female_label': 'Mujer',
        'charts.friday_label': 'viernes',
        'charts.high_arch_label': 'alto',
        'charts.low_arch_label': 'bajo',
        'charts.male_label': 'Hombre',
        'charts.medium_arch_label': 'Medio',
        'charts.monday_label': 'lunes',
        'charts.mtb_trail_label': 'MTB-trail',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'Carretera',
        'charts.saddle_label_products': 'Sillín',
        'charts.saddle_label_sessions': 'Sillín',
        'charts.saturday_label': 'sábado',
        'charts.sessions_by_bike_type_label': 'Sesiones por tipo de bicicleta',
        'charts.sessions_by_day_of_week_label': 'Sesiones por día de la semana',
        'charts.sessions_by_employee_label': 'Sesiones por empleado',
        'charts.sessions_by_foot_arch_label': 'Sesiones por arco de pie',
        'charts.sessions_by_gender_label': 'Sesiones por género',
        'charts.sessions_by_product_label': 'Sesiones por producto',
        'charts.sessions_by_saddle_height_label':
            'Sesiones por altura de sillín',
        'charts.sessions_by_saddle_size_label': 'Sesiones por ancho de sillín',
        'charts.sessions_by_shirt_bike_size_label':
            'Sesiones por tamaño de bicicleta (T-Shirt)',
        'charts.sessions_by_shoe_size_label': 'Sesiones por talla de zapatilla',
        'charts.sessions_by_sitbone_width_label':
            'Sesiones por ancho de isquiones',
        'charts.sessions_by_standard_bike_size_label':
            'Sesiones por tamaño de bicicleta (estándar)',
        'charts.sessions_over_time_label': 'Todas las Sesiones',
        'charts.shoe_label_products': 'Zapatilla',
        'charts.shoe_label_sessions': 'Zapatilla',
        'charts.sunday_label': 'domingo',
        'charts.thursday_label': 'jueves',
        'charts.top_ten_session_label':
            'Fechas de las Diez Sesiones Principales',
        'charts.top_transactions_label': 'Fechas principales de transacciones',
        'charts.triathlon_label': 'Triatlón',
        'charts.tuesday_label': 'martes',
        'charts.wednesday_label': 'Miércoles',
        'client.all_sessions_link': 'Todas las Sesiones',
        'client.bike_progress': 'Bicicleta',
        'client.client_progress_heading': 'Progreso del Cliente',
        'client.client_sessions_heading': 'Última Sesión Del Cliente',
        'client.email': 'Email del Cliente',
        'client.name': 'Nombre del Cliente',
        'client.not_applicable': 'N / A',
        'client.notes_label': 'Notas',
        'client.progress_types_complete': 'Completar',
        'client.saddle_progress': 'Sillín',
        'client.save_cta': 'Guardar',
        'client.shoe_progress': 'Zapatilla',
        'commute-comfort': 'Transporte-comodidad',
        'commute-sport': 'Transporte-deporte',
        'country.ad': 'Andorra',
        'country.ae': 'Emiratos Árabes Unidos',
        'country.af': 'Afganistán',
        'country.ag': 'Antigua y Barbuda',
        'country.ai': 'Anguila',
        'country.al': 'Albania',
        'country.am': 'Armenia',
        'country.ao': 'Angola',
        'country.aq': 'Antártida',
        'country.ar': 'Argentina',
        'country.as': 'Samoa Americana',
        'country.at': 'Austria',
        'country.au': 'Australia',
        'country.aw': 'Aruba',
        'country.ax': 'Islas Aland',
        'country.az': 'Azerbaiyán',
        'country.ba': 'Bosnia y Herzegovina',
        'country.bb': 'Barbados',
        'country.bd': 'Bangladesh',
        'country.be': 'Bélgica',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bulgaria',
        'country.bh': 'Bahrein',
        'country.bi': 'Burundi',
        'country.bj': 'Benin',
        'country.bl': 'San Bartolomé',
        'country.bm': 'Bermudas',
        'country.bn': 'Brunei',
        'country.bo': 'Bolivia',
        'country.bq': 'Bonaire, San Eustaquio y Saba',
        'country.br': 'Brasil',
        'country.bs': 'Bahamas',
        'country.bt': 'Bután',
        'country.bv': 'Isla Bouvet',
        'country.bw': 'Botswana',
        'country.by': 'Bielorrusia',
        'country.bz': 'Belice',
        'country.ca': 'Canadá',
        'country.cc': 'Islas Cocos (Keeling)',
        'country.cd': 'Congo (República Democrática del)',
        'country.cf': 'República Centroafricana',
        'country.cg': 'Congo',
        'country.ch': 'Suiza',
        'country.ci': "Côte d'Ivoire",
        'country.ck': 'Islas Salomón',
        'country.cl': 'Chile',
        'country.cm': 'Camerún',
        'country.cn': 'China',
        'country.co': 'Colombia',
        'country.cr': 'Costa Rica',
        'country.cu': 'Cuba',
        'country.cv': 'Cabo Verde',
        'country.cw': 'Curazao',
        'country.cx': 'Isla de Navidad',
        'country.cy': 'Chipre',
        'country.cz': 'Chequia',
        'country.de': 'Alemania',
        'country.dj': 'Djibouti',
        'country.dk': 'Dinamarca',
        'country.dm': 'Dominica',
        'country.do': 'República Dominicana',
        'country.dz': 'Argelia',
        'country.ec': 'Ecuador',
        'country.ee': 'Estonia',
        'country.eg': 'Egipto',
        'country.eh': 'Sahara Occidental',
        'country.er': 'Eritrea',
        'country.es': 'España',
        'country.et': 'Etiopía',
        'country.fi': 'Finlandia',
        'country.fj': 'Fiji',
        'country.fk': 'Islas Malvinas',
        'country.fm': 'Micronesia (Estados federados de)',
        'country.fo': 'Islas Faroe',
        'country.fr': 'Francia',
        'country.ga': 'Gabón',
        'country.gb': 'Reino Unido',
        'country.gd': 'Granada',
        'country.ge': 'Georgia',
        'country.gf': 'Guayana Francesa',
        'country.gg': 'Guernsey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibraltar',
        'country.gl': 'Groenlandia',
        'country.gm': 'Gambia',
        'country.gn': 'Guinea',
        'country.gp': 'Guadalupe',
        'country.gq': 'Guinea Ecuatorial',
        'country.gr': 'Grecia',
        'country.gs': 'Georgia del Sur y las Islas Sandwich del Sur',
        'country.gt': 'Guatemala',
        'country.gu': 'Guam',
        'country.gw': 'Guinea-Bissau',
        'country.gy': 'Guayana',
        'country.hk': 'Hong Kong',
        'country.hm': 'Islas Heard y McDonald',
        'country.hn': 'Honduras',
        'country.hr': 'Croacia',
        'country.ht': 'Haití',
        'country.hu': 'Hungría',
        'country.id': 'Indonesia',
        'country.ie': 'Irlanda',
        'country.il': 'Israel',
        'country.im': 'Isla de Man',
        'country.in': 'India',
        'country.io': 'Territorio Británico del Océano Índico',
        'country.iq': 'Irak',
        'country.ir': 'Irán',
        'country.is': 'Islandia',
        'country.it': 'Italia',
        'country.je': 'Jersey',
        'country.jm': 'Jamaica',
        'country.jo': 'Jordán',
        'country.jp': 'Japón',
        'country.ke': 'Kenia',
        'country.kg': 'Kirguizstán',
        'country.kh': 'Camboya',
        'country.ki': 'Kiribati',
        'country.km': 'Comoras',
        'country.kn': 'San Cristóbal y Nieves',
        'country.kp': 'Corea del Norte',
        'country.kr': 'Corea del Sur',
        'country.kw': 'Kuwait',
        'country.ky': 'Islas Caimán',
        'country.kz': 'Kazajstán',
        'country.la': 'Laos',
        'country.lb': 'Líbano',
        'country.lc': 'Santa Lucía',
        'country.li': 'Liechtenstein',
        'country.lk': 'Sri Lanka',
        'country.lr': 'Liberia',
        'country.ls': 'Lesoto',
        'country.lt': 'Lituania',
        'country.lu': 'Luxemburgo',
        'country.lv': 'Letonia',
        'country.ly': 'Libia',
        'country.ma': 'Marruecos',
        'country.mc': 'Mónaco',
        'country.md': 'Moldavia',
        'country.me': 'Montenegro',
        'country.mf': 'San Martín (parte francesa)',
        'country.mg': 'Madagascar',
        'country.mh': 'Islas Marshall',
        'country.mk': 'Macedonia',
        'country.ml': 'Mali',
        'country.mm': 'Myanmar',
        'country.mn': 'Mongolia',
        'country.mo': 'Macao',
        'country.mp': 'Islas Marianas del Norte',
        'country.mq': 'Martinica',
        'country.mr': 'Mauritania',
        'country.ms': 'Montserrat',
        'country.mt': 'Malta',
        'country.mu': 'Mauricio',
        'country.mv': 'Maldivas',
        'country.mw': 'Malawi',
        'country.mx': 'México',
        'country.my': 'Malasia',
        'country.mz': 'Mozambique',
        'country.na': 'Namibia',
        'country.nc': 'Nueva Caledonia',
        'country.ne': 'Níger',
        'country.nf': 'Isla Norfolk',
        'country.ng': 'Nigeria',
        'country.ni': 'Nicaragua',
        'country.nl': 'Países Bajos',
        'country.no': 'Noruega',
        'country.np': 'Nepal',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'Nueva Zelanda',
        'country.om': 'Omán',
        'country.pa': 'Panamá',
        'country.pe': 'Perú',
        'country.pf': 'Polinesia Francesa',
        'country.pg': 'Papúa Nueva Guinea',
        'country.ph': 'Filipinas',
        'country.pk': 'Pakistán',
        'country.pl': 'Polonia',
        'country.pm': 'San Pedro y Miquelón',
        'country.pn': 'Pitcairn',
        'country.pr': 'Puerto Rico',
        'country.ps': 'Palestina, Estado de',
        'country.pt': 'Portugal',
        'country.pw': 'Palau',
        'country.py': 'Paraguay',
        'country.qa': 'Katar',
        'country.re': 'Reunión',
        'country.ro': 'Rumania',
        'country.rs': 'Serbia',
        'country.ru': 'Rusia',
        'country.rw': 'Ruanda',
        'country.sa': 'Arabia Saudita',
        'country.sb': 'Islas Salomón',
        'country.sc': 'Seychelles',
        'country.sd': 'Sudán',
        'country.se': 'Suecia',
        'country.sg': 'Singapur',
        'country.sh': 'Santa Helena, Ascensión y Tristán da Cunha',
        'country.si': 'Eslovenia',
        'country.sj': 'Svalbard y Jan Mayen',
        'country.sk': 'Eslovaquia',
        'country.sl': 'Sierra Leona',
        'country.sm': 'San Marino',
        'country.sn': 'Senegal',
        'country.so': 'Somalia',
        'country.sr': 'Surinam',
        'country.ss': 'Sudán del Sur',
        'country.st': 'Santo Tomé y Príncipe',
        'country.sv': 'El Salvador',
        'country.sx': 'Sint Maarten (parte holandesa)',
        'country.sy': 'Siria',
        'country.sz': 'Suazilandia',
        'country.tc': 'Islas Turcas y Caicos',
        'country.td': 'Chad',
        'country.tf': 'Territorios Australes Franceses',
        'country.tg': 'Togo',
        'country.th': 'Tailandia',
        'country.tj': 'Tayikistan',
        'country.tk': 'Tokelau',
        'country.tl': 'Timor-Leste',
        'country.tm': 'Turkmenistan',
        'country.tn': 'Túnez',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trinidad y Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Taiwán',
        'country.tz': 'Tanzania',
        'country.ua': 'Ucrania',
        'country.ug': 'Uganda',
        'country.um': 'Islas Ultramarinas Menores de Estados Unidos',
        'country.us': 'Estados Unidos de America',
        'country.uy': 'Uruguay',
        'country.uz': 'Uzbekistán',
        'country.va': 'Santa Sede',
        'country.vc': 'San Vicente y las Granadinas',
        'country.ve': 'Venezuela',
        'country.vg': 'Islas Vírgenes (británicas)',
        'country.vi': 'Islas Vírgenes (EE.UU.)',
        'country.vn': 'Vietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis and Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Yemen',
        'country.yt': 'Mayotte',
        'country.za': 'Sudáfrica',
        'country.zm': 'Zambia',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Ciclocross',
        'date.all_time': 'FECHA: TODO EL TIEMPO',
        'date.last_month': 'FECHA: ÚLTIMO MES',
        'date.last_week': 'FECHA: SEMANA PASADA',
        'date.last_year': 'FECHA: AÑO PASADO',
        'employees.add_cta_label': 'Añadir empleado',
        'employees.delete_cta_label': 'Eliminar empleado',
        'employees.employee_id_label': 'ID de empleado',
        'employees.heading': 'Empleados',
        'employees.intro':
            'La cronología más reciente de todos tus empleados con la tecnología de Retül.',
        'employees.update_cta_label': 'Actualizar empleado',
        'filters.all_label': 'TODOS',
        'filters.client_all': 'Todos',
        'filters.client_anonymous': 'Anónimo',
        'filters.client_identified': 'Identificado',
        'filters.client_label': 'Tipo de cliente',
        'filters.employees_label': 'Empleados',
        'filters.filters_heading': 'Filtros',
        'filters.hide_filters': 'Mostrar filtros',
        'filters.locations_label': 'Ubicaciones',
        'filters.rating_all': 'TODOS',
        'filters.rating_label': 'Clasificación',
        'filters.rating_none': 'NINGUNO',
        'filters.session_all': 'Todos',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Tipo de sesion',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Mostrar filtros',
        'filters.status_all': 'Todos',
        'filters.status_closed': 'Cerrado',
        'filters.status_label': 'Estado',
        'filters.status_open': 'Abierto',
        'footer.privacy_link_label': 'Política de privacidad',
        'footer.terms_link_label': 'Términos de Uso',
        'form.address_1_field_error': 'La dirección es necesaria.',
        'form.address_1_field_label': 'Dirección',
        'form.city_field_error': 'Ciudad es necesaria.',
        'form.city_field_label': 'Ciudad',
        'form.country_field_error': 'El país es necesario.',
        'form.country_field_label': 'País',
        'form.delete_field_confirm_message':
            '¿Estás seguro de que quieres eliminar {data} ?',
        'form.delete_field_label': 'Eliminar',
        'form.edit_field_label': 'Editar',
        'form.email_field_error':
            'El email es necesario y debe ser un email con formato válido.',
        'form.email_field_label': 'email',
        'form.name_field_error': 'Nombre es necesario.',
        'form.name_field_label': 'Nombre',
        'form.shop_field_label': 'tienda',
        'form.shops_field_label': 'Tiendas',
        'form.state_field_error': 'El estado es necesario.',
        'form.state_field_label': 'Estado',
        'form.zip_field_error': 'El Código Postal es necesario',
        'form.zip_field_label': 'Código Postal',
        'gender.all': 'GÉNERO: TODOS',
        'gender.female': 'GÉNERO: MUJER',
        'gender.male': 'GÉNERO: HOMBRE',
        'landing.heading': 'Bienvenido al portal Retailler',
        'landing.intro':
            'Accede a los datos, sesiones e información del cliente captados por la tecnología Retül.',
        'landing.sign_in_or_create_account_label':
            'Iniciar sesión / Crear cuenta',
        'locale.label_cs': 'Česky',
        'locale.label_de': 'Deutsch',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': 'Español',
        'locale.label_fr': 'Français',
        'locale.label_it': 'Italiano',
        'locale.label_ja': '日本語',
        'locale.label_ko': '한국어',
        'locale.label_nl': 'Nederlands',
        'locale.label_pl': 'Polskie',
        'locale.label_pt': 'Português',
        'locale.label_pt-br': 'Português do Brasil',
        'locale.label_zh-cn': '中国',
        'locale.label_zh-tw': '台灣',
        'locale.locale_picker_cancel_label': 'Cancelar',
        'locale.locale_picker_heading': 'Elige tu idioma.',
        'locale.locale_picker_save_label': 'Guardar',
        'locations.add_cta_label': 'Añade una ubicación',
        'locations.delete_cta_label': 'Eliminar ubicación',
        'locations.empty':
            'No se han añadido ubicaciones, para poder usar Retül Apps, añade una ubicación.',
        'locations.empty_cta_label': 'Administrar ubicaciones',
        'locations.heading': 'Administrar ubicaciones',
        'locations.intro':
            'Añade, edita y elimina ubicaciones utilizando la tecnología Retül.',
        'locations.location_id_label': 'ID de ubicación',
        'locations.update_cta_label': 'Actualizar ubicación',
        'metrics.closed_session_tooltip':
            'Las sesiones cerradas indican sesiones que han registrado una sesión. Si un cliente realizó o no realizó una compra, {closingSessions} ayuda a realizar un seguimiento de las ventas de la tecnología Retül.',
        'metrics.closed_sessions': 'Cerrando Sesiones',
        'metrics.closing_session_link': 'cerrando sesión',
        'metrics.total_sessions': 'Total de Sesiones',
        'metrics.unique_client_emails': 'Emails de clientes únicos',
        'metrics.unique_emails_tooltip':
            'Este número representa las sesiones que se han llevado a cabo por clientes únicos. Los clientes únicos están determinados por su dirección de correo electrónico.',
        'mtb-trail': 'Montaña (trail)',
        'mtb-xc': 'Montaña (cross-country)',
        'nav.analytics': 'Analíticas',
        'nav.greeting_label': 'Hola, {data}',
        'nav.manage_employees_link_label': 'Administrar empleados',
        'nav.manage_locations_link_label': 'Administrar ubicaciones',
        'nav.sessions': 'Sesiones',
        'nav.sign_out_link_label': 'Cerrar sesión',
        'pagination.next': 'Siguiente',
        'pagination.page': 'Página',
        'pagination.previous': 'Anterior',
        road: 'Carretera',
        'session_details.add_transaction_cta_label': 'Añadir Transacción',
        'session_details.all_sessions_link': 'Todas las Sesiones',
        'session_details.bike_product': 'Bici',
        'session_details.client': 'Cliente',
        'session_details.client_review_label': 'Revisión del cliente',
        'session_details.complete_session_cta_label': 'Marcar como cerrado',
        'session_details.complete_session_message':
            'Cierra esta sesión agregando cualquier transacción realizada por el cliente, o',
        'session_details.complete_status': 'Cerrado',
        'session_details.created_on': 'Creado en: {data}',
        'session_details.delete_link': 'Borrar',
        'session_details.employee': 'Empleado: {data}',
        'session_details.family_label': 'Familia',
        'session_details.incomplete_status': 'Abierto',
        'session_details.model_label': 'Modelo',
        'session_details.no_transactions_message':
            'No se han añadido transacciones.',
        'session_details.product_label': 'Producto',
        'session_details.saddle_product': 'Sillín',
        'session_details.shoe_product': 'Zapatilla',
        'session_details.shop': 'Tienda: {data}',
        'session_details.size_label': 'Talla',
        'session_details.status_label': 'Estado',
        'session_details.transactions_heading': 'Transacciones de sesión',
        'session_products.arch_height_label': 'Altura del arco: {data}',
        'session_products.bike_size_label': 'Talla de la bici: {data}',
        'session_products.saddle_height_label': 'Altura del sillín: {data} mm',
        'session_products.saddle_width_label': 'Ancho del sillín: {data} mm',
        'session_products.session_product_label': 'Datos de la sesión',
        'session_products.shoe_size_label': 'Talla de zapatilla: {data}',
        'sessions.add_transaction_cta_label': 'Añadir transacción',
        'sessions.client_anonymous': 'Anónimo',
        'sessions.client_label': 'Cliente',
        'sessions.closed_status': 'CERRADO',
        'sessions.csv_downloading_message':
            'Estamos recopilando todas tus sesiones para el fichero CSV, esto puede tardar un par de minutos.',
        'sessions.date_label': 'Fecha',
        'sessions.employee_label': 'Empleado',
        'sessions.export_csv_link': 'Exportar lista (.CSV)',
        'sessions.filter_empty':
            'Lo sentimos, no hay Sesiones que coincidan con tus criterios de búsqueda. Por favor restablece los filtros.',
        'sessions.filter_empty_cta_label': 'Restablecer filtros',
        'sessions.heading': 'Sesiones',
        'sessions.intro':
            'La cronología de tus últimas sesiones con la tecnología Retül.',
        'sessions.location_label': 'Ubicación',
        'sessions.no_rating': 'Sin calificación',
        'sessions.open_status': 'ABIERTO',
        'sessions.rating_label': 'Clasificación',
        'sessions.session_id_label': 'ID de sesión',
        'sessions.session_loading_message':
            'Estamos recopilando todas tus sesiones, puede tardar un rato.',
        'sessions.status_label': 'Estado',
        'sign_in.heading': 'Registrarse',
        'sign_in.intro':
            'Introduce tu email y contraseña para comenzar, o selecciona "Registrar Cuenta" para crear una nueva.',
        'status.all': 'ESTADO: TODOS',
        'status.complete': 'ESTADO: CERRADO',
        'status.incomplete': 'ESTADO: ABIERTO',
        triathlon: 'Triatlón',
        'POWER MIMIC DESCRIPTION':
            'Esta sillín hace que el adormecimiento y el dolor desaparezcan gracias a un diseño que imita la respuesta del cuerpo a diferentes tipos de presión para crear un equilibrio en los tejidos blandos.',
        'date_picker.all_time': 'Todas las sesiones',
        'date_picker.date_range': 'Rango de fechas',
        'date_picker.last_month': 'El mes pasado',
        'date_picker.last_week': 'La semana pasada',
        'date_picker.last_year': 'El año pasado',
        'date_picker.this_month': 'Este mes',
        'date_picker.this_week': 'Esta semana',
        'date_picker.this_year': 'Este año',
        'session_details.client_notes': 'Notas del cliente',
        'session_details.footbed_product': 'Plantilla',
        'session_products.it_width_label': 'Ancho IT: {data} mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match es una experiencia de evaluación en tienda que mide con precisión la longitud de sus piernas con el fin de proporcionar el tamaño de bici óptimo y los productos que mejor se adapten a usted.',
        'body_data.about_saddle':
            'Retül Match es una experiencia en tienda inmersiva que aprovecha el Retül Digital Sitbone Device para capturar el ancho de su promontorio del isquion con una tecnología digital de mapa de presión con el fin de proporcionar el tamaño de sillín preciso y los sillines que mejor se adapten a usted.',
        'body_data.about_shoe':
            'Retül Match es una experiencia en tienda inmersiva que captura los datos de los pies con el fin de satisfacer necesidades de plantillas precisas y proporcionar talla de botas precisas y las botas que mejor se adapten a usted.',
        'body_data.arch_height_label': 'altura del puente',
        'body_data.bike_label': 'Bici',
        'body_data.bike_size_label': 'tamaño de la bici',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'circunferencia',
        'body_data.find_retul_technology': 'Buscar tecnología Retül',
        'body_data.heading': 'Datos del cuerpo',
        'body_data.intro':
            'Se capturan los datos del cuerpo y las preferencias de ciclismo mediante la tecnología Retül Vantage con el fin de proporcionar información de tamaño precisa y los productos que mejor se adapten a usted.',
        'body_data.length_label': 'longitud',
        'body_data.load_error':
            'No podemos recuperar sus datos corporales en este momento, vuelva a intentarlo más tarde.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'no recopilados',
        'body_data.recommended_label': 'Recomendado',
        'body_data.saddle_height_label': 'altura del sillín',
        'body_data.saddle_label': 'Sillín',
        'body_data.saddle_size_label': 'tamaño del sillín',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'espinilla',
        'body_data.shoe_label': 'bota',
        'body_data.shoe_size_label': 'talla de botas',
        'body_data.sit_bone_label': 'promontorio del isquion',
        'body_data.starting_label': 'Iniciando',
        'body_data.thigh_label': 'muslo',
        'body_data.view_bike_matches_label': 'Ver coincidencias de bici',
        'body_data.view_saddle_matches_label': 'Ver coincidencias de sillín',
        'body_data.view_shoe_matches_label': 'Ver coincidencias de botas',
        'body_data.your_progress_completion_label':
            '{numComplete}/3 completado',
        'body_data.your_progress_label': 'Su progreso',
        cancel_label: 'Cancelar',
        'create_account.heading': 'Crear cuenta',
        'create_account.intro':
            'Introduzca un correo electrónico y una contraseña para crear una cuenta Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Cancelar',
        'edit_form.edit_form_save_label': 'Guardar',
        email_format_error: 'Formato de correo electrónico no válido.',
        email_label: 'Correo electrónico',
        'forgot_password.heading': 'Contraseña olvidada',
        'forgot_password.intro':
            '¿Ha olvidado su contraseña? Introduzca su correo electrónico y le enviaremos un enlace para restablecerla.',
        'forgot_password.reset_email_sent':
            'Se ha enviado un correo electrónico de restablecimiento de contraseña a {email}',
        'landing.create_account_label': 'Crear cuenta',
        'landing.sign_in_label': 'Registrarse',
        'nav.account_profiles_link_label': 'Cuenta y perfiles de ciclista',
        'nav.home_page_link_label': 'Página de inicio',
        'nav.logout_link_label': 'Cerrar sesión',
        'not_found.go_home_label': 'Ir al inicio',
        'not_found.heading': '404',
        'not_found.intro':
            'La página que está buscando parece haberse movido, eliminado o no existe.',
        password_invalid_error: 'Contraseña no válida.',
        password_label: 'Contraseña',
        'product_matches.bike_size_label': 'tamaño de la bici',
        'product_matches.category_filter_commute_comfort_label':
            'Comodidad de viaje',
        'product_matches.category_filter_commute_sport_label':
            'Deporte de viaje',
        'product_matches.category_filter_cyclocross_label': 'Ciclocross',
        'product_matches.category_filter_label': 'Categoría',
        'product_matches.category_filter_label_road': 'Carretera',
        'product_matches.category_filter_mountain_trail_label':
            'Montaña (trail)',
        'product_matches.category_filter_mountain_xc_label':
            'Montaña (cross-country)',
        'product_matches.category_filter_triathlon_label': 'Triatlón',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'No tenemos datos corporales para proporcionarle resultados de Match para este tipo de producto. Utilice el filtro anterior para ver otros tipos de productos o utilice la página de ubicaciones con el fin de buscar una ubicación para completar este módulo Match.',
        'product_matches.find_retul_technology_label':
            'Buscar tecnología Retül',
        'product_matches.gender_filter_all_label':
            'Mostrar todos los productos',
        'product_matches.gender_filter_female_label':
            'Mostrar productos para mujer',
        'product_matches.gender_filter_male_label':
            'Mostrar productos para hombre',
        'product_matches.heading': 'Coincidencias de productos',
        'product_matches.intro':
            'La tecnología Retül aprovecha sus datos corporales y preferencias de ciclismo para encontrar los productos Specialized que mejor se adaptan a usted.',
        'product_matches.last_session_match_label':
            'Última sesión de coincidencia',
        'product_matches.load_error':
            'No podemos recuperar sus coincidencias de productos en este momento, vuelva a intentarlo más tarde.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'Conforme a los datos biométricos capturados durante la sesión Retül Match, recomendamos el anterior tamaño de bici. Si bien este es un buen punto de partida, le recomendamos, aun así, que consulte con su experto local para obtener recomendaciones en función de su nivel de experiencia y constitución física.',
        'product_matches.overlay_bike_size_label': 'tamaño de la bici',
        'product_matches.overlay_close_label': 'Cerrar',
        'product_matches.overlay_indicator_label': 'Usted',
        'product_matches.overlay_saddle_height_details':
            'Conforme a los datos biométricos capturados durante la sesión Retül Match, recomendamos la anterior altura de sillín. Si bien este es un buen punto de partida, le recomendamos, aun así, que consulte con su experto local para obtener recomendaciones en función de su nivel de experiencia y constitución física.',
        'product_matches.overlay_saddle_height_label': 'altura del sillín',
        'product_matches.overlay_saddle_size_details':
            'Conforme a los datos biométricos capturados durante la sesión Retül Match, recomendamos el anterior tamaño de sillín. Si bien este es un buen punto de partida, le recomendamos, aun así, que consulte a su experto local para obtener recomendaciones en función de sus datos específicos corporales.',
        'product_matches.overlay_saddle_size_label': 'tamaño del sillín',
        'product_matches.overlay_shoe_size_details':
            'Conforme a los datos biométricos capturados durante la sesión Retül Match, recomendamos la anterior talla de botas. Si bien este es un buen punto de partida, le recomendamos, aun así, que consulte a su experto local para obtener recomendaciones en función de su nivel de experiencia.',
        'product_matches.overlay_shoe_size_label': 'talla de botas',
        'product_matches.padding_level_label': 'Nivel de acolchado',
        'product_matches.product_type_filter_bikes_label': 'Bicis',
        'product_matches.product_type_filter_label': 'Tipo de producto',
        'product_matches.product_type_filter_saddles_label': 'Sillines',
        'product_matches.product_type_filter_shoes_label': 'Botas',
        'product_matches.recommended_label': 'Recomendado',
        'product_matches.saddle_detail_size_label': 'Tamaño del sillín:',
        'product_matches.saddle_height_label': 'altura del sillín',
        'product_matches.saddle_models_label': 'Modelos',
        'product_matches.saddle_size_label': 'tamaño del sillín',
        'product_matches.shoe_size_label': 'talla de botas',
        'product_matches.starting_label': 'Iniciando',
        'product_matches.view_on_specialized_label': 'Ver en Specialized',
        'profile.account_heading': 'Cuenta',
        'profile.bike_type_city_label': 'Ciudad',
        'profile.bike_type_label': 'Tipo de bici',
        'profile.bike_type_mountain_label': 'Montaña',
        'profile.bike_type_road_label': 'Carretera',
        'profile.email_error': 'Introduzca un correo electrónico válido',
        'profile.email_label': 'Correo electrónico',
        'profile.gender_female_label': 'mujer',
        'profile.gender_label': 'Sexo',
        'profile.gender_male_label': 'hombre',
        'profile.heading': 'Cuenta y perfiles de ciclista',
        'profile.intro':
            'Próximamente... Edite su información de cuenta y perfiles de ciclista.',
        'profile.name_error': 'Introduzca un nombre',
        'profile.name_label': 'Nombre',
        'profile.password_error': 'Introduzca una contraseña',
        'profile.password_label': 'Contraseña',
        'profile.position_high_label': 'Alto',
        'profile.position_label': 'Posición',
        'profile.position_low_label': 'Bajo',
        'profile.position_medium_label': 'Mediano',
        'profile.ride_duration_extended_label': 'Más de 90 minutos',
        'profile.ride_duration_label': 'Duración',
        'profile.ride_duration_long_label': 'De 60 a 90 minutos',
        'profile.ride_duration_medium_label': 'De 30 a 60 minutos',
        'profile.ride_duration_short_label': 'De 0 a 30 minutos',
        'profile.ride_frequency_label': 'Frecuencia',
        'profile.ride_frequency_once_month_label': 'Una vez al mes',
        'profile.ride_frequency_once_week_label': 'Una vez a la semana',
        'profile.ride_frequency_six_week_label': '6 veces a la semana',
        'profile.ride_frequency_three_week_label': '3 veces a la semana',
        'routes.account_profile_route_label': 'Cuenta y perfiles de ciclista',
        'routes.body_data_route_label': 'Datos del cuerpo',
        'routes.create_account_route_label': 'Crear cuenta',
        'routes.forgot_password_route_label': 'Contraseña olvidada',
        'routes.locations_route_label': 'Ubicaciones',
        'routes.not_found_route_label': 'No encontrado',
        'routes.privacy_policy_route_label': 'Política de privacidad',
        'routes.product_matches_route_label': 'Coincidencias de productos',
        'routes.reset_password_route_label': 'Restablecer contraseña',
        'routes.set_password_route_label': 'Restablecer contraseña',
        'routes.sessions_route_label': 'Sesiones',
        'routes.sign_in_route_label': 'Registrarse',
        'routes.terms_of_service_route_label': 'Condiciones de servicio',
        'sessions.download_pdf_cta_label': 'Descargar PDF',
        'sessions.empty': 'No ha tenido ninguna sesión.',
        'sessions.empty_cta_label': 'Buscar ubicaciones',
        'sessions.loading_error':
            'No podemos recuperar sus sesiones en este momento, vuelva a intentarlo más tarde.',
        'sessions.loading_error_cta_label': 'Inténtelo de nuevo',
        'sessions.session_type_filter_label': 'Tipo de sesión',
        'session_type.dfd_session_name': 'Retül Digital Foot Device',
        'session_type.dsd_session_name': 'Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül Foot',
        'session_type.legs_session_name': 'Retül Legs',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül Saddle',
        'session_type.unknown_session_name': 'Retül Session',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': 'Registrarse',
        'sign_in.contact_label': 'Ponerse en contacto con Retül',
        'sign_in.forgot_password_label': '¿Contraseña olvidada?',
        'sign_in.sign_in_error':
            'No hemos podido recuperar sus datos de usuario. Póngase en contacto con el Servicio de Atención al Cliente de Retül para obtener ayuda.',
        submit_label: 'Enviar',
    },
};
