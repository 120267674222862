import React, { Component } from 'react';
import Main from '../../components/Main/Main';
import VerifyForm from '../../components/Verify/VerifyForm';
import Loader from '../../components/Loader/Loader';
import Intro from '../../components/Intro/Intro';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';

export default class Verify extends Component {
    renderForm() {
        const { handleSubmit, data, errors, fetchingStatus } = this.props;

        return componentWithErrorBoundary(
            <VerifyForm
                handleSubmit={handleSubmit}
                data={data}
                errors={errors}
                fetchingStatus={fetchingStatus}
            />
        );
    }

    render() {
        return (
            <Main>
                <Loader
                    isLoading={this.props.fetchingStatus === 'REQUESTING'}
                />
                <section className="top top_medium">
                    <div className="container">
                        <Intro header="Account Verification" />
                    </div>
                </section>
                <section className="bottom bottom_extraPad bottom_dark">
                    <div className="container">{this.renderForm()}</div>
                </section>
            </Main>
        );
    }
}
