import { defineMessages } from 'react-intl';

export default defineMessages({
  'sessions': {
    'id': 'nav.sessions',
    'defaultMessage': 'Sessions',
    'description': 'Sessions Page navigation link'
  },
  'analytics': {
    'id': 'nav.analytics',
    'defaultMessage': 'Analytics',
    'description': 'Analytics Page navigation link'
  },
});
