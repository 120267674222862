/* eslint-disable */
export const localeStrings = {
    pt: {
        'add_transaction.back_to_sessions_label': 'Voltar a Sessões',
        'add_transaction.bike_label': 'Bicicleta',
        'add_transaction.commute_comfort_type': 'Comutar-conforto',
        'add_transaction.commute_sport_type': 'Comutar-esporte',
        'add_transaction.complete_transaction_cta_label':
            'Concluir a transação',
        'add_transaction.confirm_label': 'confirme',
        'add_transaction.cyclocross_type': 'Ciclocrosse',
        'add_transaction.family_label': 'família',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'palmilha',
        'add_transaction.heading': 'Adicionar transação a {data}',
        'add_transaction.model_label': 'Modelo',
        'add_transaction.mountain_type': 'Montanha',
        'add_transaction.mtb_trail_type': 'Montanha (trilha)',
        'add_transaction.mtb_xc_type': 'Montanha (Cross-Country)',
        'add_transaction.product_label': 'produto',
        'add_transaction.ratings_message':
            'Quando concluir esta transação, {data} receberá um email para avaliar este produto. Você poderá ver essa classificação na lista de sessões e na página de detalhes da sessão.',
        'add_transaction.road_type': 'Estrada',
        'add_transaction.saddle_label': 'selim',
        'add_transaction.shoe_label': 'sapato',
        'add_transaction.size_label': 'Tamanho',
        'add_transaction.transaction_complete_message': 'Transação Concluída.',
        'add_transaction.triathlon_type': 'Triatlo',
        'add_transaction.tt_tri_type': 'TT / Tri',
        'add_transaction.type_label': 'tipo',
        'analytics.loading_message':
            'Estamos compilando seus dados, isso pode levar alguns minutos',
        'analytics_filters.analytics_by': 'ANÁLISE POR',
        'analytics_filters.filter_by': 'FILTRAR POR',
        'analytics_filters.products': 'PRODUTOS',
        'analytics_filters.sessions': 'SESSÕES',
        'charts.bike_label_products': 'Bicicleta',
        'charts.bike_label_sessions': 'Bicicleta',
        'charts.commute_comfort_label': 'Comutar-conforto',
        'charts.commute_sport_label': 'Comutar-esporte',
        'charts.cyclocross_label': 'Ciclocrosse',
        'charts.female_label': 'Mulher',
        'charts.friday_label': 'Sexta-feira',
        'charts.high_arch_label': 'alto',
        'charts.low_arch_label': 'baixo',
        'charts.male_label': 'Homem',
        'charts.medium_arch_label': 'Médio',
        'charts.monday_label': 'Segunda-feira',
        'charts.mtb_trail_label': 'mtb-trilha',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'Estrada',
        'charts.saddle_label_products': 'selim',
        'charts.saddle_label_sessions': 'selim',
        'charts.saturday_label': 'Sábado',
        'charts.sessions_by_bike_type_label': 'Sessões por tipo de bicicleta',
        'charts.sessions_by_day_of_week_label': 'Sessões por dia da semana',
        'charts.sessions_by_employee_label': 'Sessões por empregado',
        'charts.sessions_by_foot_arch_label': 'Sessões por arco do pé',
        'charts.sessions_by_gender_label': 'Sessões por sexo',
        'charts.sessions_by_product_label': 'Sessões Por Produto',
        'charts.sessions_by_saddle_height_label': 'Sessões por altura do selim',
        'charts.sessions_by_saddle_size_label': 'Sessões por tamanho de sela',
        'charts.sessions_by_shirt_bike_size_label':
            'Sessões de bicicleta tamanho (t-shirt)',
        'charts.sessions_by_shoe_size_label': 'Sessões por tamanho de sapato',
        'charts.sessions_by_sitbone_width_label':
            'Sessões por Largura do Sitbone',
        'charts.sessions_by_standard_bike_size_label':
            'Sessões de bicicleta tamanho (padrão)',
        'charts.sessions_over_time_label': 'Sessões ao longo do tempo',
        'charts.shoe_label_products': 'Sapato',
        'charts.shoe_label_sessions': 'sapato',
        'charts.sunday_label': 'Domingo',
        'charts.thursday_label': 'Quinta-feira',
        'charts.top_ten_session_label': 'Dez melhores datas de sessão',
        'charts.top_transactions_label': 'Principais datas das transações',
        'charts.triathlon_label': 'Triatlo',
        'charts.tuesday_label': 'Terça-feira',
        'charts.wednesday_label': 'Quarta-feira',
        'client.all_sessions_link': 'Todas as Sessões',
        'client.bike_progress': 'Bicicleta',
        'client.client_progress_heading': 'Progresso do Cliente',
        'client.client_sessions_heading': 'Última sessão de cliente',
        'client.email': 'Email do cliente',
        'client.name': 'Nome do cliente',
        'client.not_applicable': '',
        'client.notes_label': 'Notas',
        'client.progress_types_complete': 'Completo',
        'client.saddle_progress': 'selim',
        'client.save_cta': 'Salvar',
        'client.shoe_progress': 'sapato',
        'commute-comfort': 'Comutar-conforto',
        'commute-sport': 'Comutar-esporte',
        'country.ad': '',
        'country.ae': '',
        'country.af': '',
        'country.ag': '',
        'country.ai': '',
        'country.al': '',
        'country.am': '',
        'country.ao': '',
        'country.aq': '',
        'country.ar': '',
        'country.as': '',
        'country.at': '',
        'country.au': '',
        'country.aw': '',
        'country.ax': '',
        'country.az': '',
        'country.ba': '',
        'country.bb': '',
        'country.bd': '',
        'country.be': '',
        'country.bf': '',
        'country.bg': '',
        'country.bh': '',
        'country.bi': '',
        'country.bj': '',
        'country.bl': '',
        'country.bm': '',
        'country.bn': '',
        'country.bo': '',
        'country.bq': '',
        'country.br': '',
        'country.bs': '',
        'country.bt': '',
        'country.bv': '',
        'country.bw': '',
        'country.by': '',
        'country.bz': '',
        'country.ca': '',
        'country.cc': '',
        'country.cd': '',
        'country.cf': '',
        'country.cg': '',
        'country.ch': '',
        'country.ci': '',
        'country.ck': '',
        'country.cl': '',
        'country.cm': '',
        'country.cn': '',
        'country.co': '',
        'country.cr': '',
        'country.cu': '',
        'country.cv': '',
        'country.cw': '',
        'country.cx': '',
        'country.cy': '',
        'country.cz': '',
        'country.de': '',
        'country.dj': '',
        'country.dk': '',
        'country.dm': '',
        'country.do': '',
        'country.dz': '',
        'country.ec': '',
        'country.ee': '',
        'country.eg': '',
        'country.eh': '',
        'country.er': '',
        'country.es': '',
        'country.et': '',
        'country.fi': '',
        'country.fj': '',
        'country.fk': '',
        'country.fm': '',
        'country.fo': '',
        'country.fr': '',
        'country.ga': '',
        'country.gb': '',
        'country.gd': '',
        'country.ge': '',
        'country.gf': '',
        'country.gg': '',
        'country.gh': '',
        'country.gi': '',
        'country.gl': '',
        'country.gm': '',
        'country.gn': '',
        'country.gp': '',
        'country.gq': '',
        'country.gr': '',
        'country.gs': '',
        'country.gt': '',
        'country.gu': '',
        'country.gw': '',
        'country.gy': '',
        'country.hk': '',
        'country.hm': '',
        'country.hn': '',
        'country.hr': '',
        'country.ht': '',
        'country.hu': '',
        'country.id': '',
        'country.ie': '',
        'country.il': '',
        'country.im': '',
        'country.in': '',
        'country.io': '',
        'country.iq': '',
        'country.ir': '',
        'country.is': '',
        'country.it': '',
        'country.je': '',
        'country.jm': '',
        'country.jo': '',
        'country.jp': '',
        'country.ke': '',
        'country.kg': '',
        'country.kh': '',
        'country.ki': '',
        'country.km': '',
        'country.kn': '',
        'country.kp': '',
        'country.kr': '',
        'country.kw': '',
        'country.ky': '',
        'country.kz': '',
        'country.la': '',
        'country.lb': '',
        'country.lc': '',
        'country.li': '',
        'country.lk': '',
        'country.lr': '',
        'country.ls': '',
        'country.lt': '',
        'country.lu': '',
        'country.lv': '',
        'country.ly': '',
        'country.ma': '',
        'country.mc': '',
        'country.md': '',
        'country.me': '',
        'country.mf': '',
        'country.mg': '',
        'country.mh': '',
        'country.mk': '',
        'country.ml': '',
        'country.mm': '',
        'country.mn': '',
        'country.mo': '',
        'country.mp': '',
        'country.mq': '',
        'country.mr': '',
        'country.ms': '',
        'country.mt': '',
        'country.mu': '',
        'country.mv': '',
        'country.mw': '',
        'country.mx': '',
        'country.my': '',
        'country.mz': '',
        'country.na': '',
        'country.nc': '',
        'country.ne': '',
        'country.nf': '',
        'country.ng': '',
        'country.ni': '',
        'country.nl': '',
        'country.no': '',
        'country.np': '',
        'country.nr': '',
        'country.nu': '',
        'country.nz': '',
        'country.om': '',
        'country.pa': '',
        'country.pe': '',
        'country.pf': '',
        'country.pg': '',
        'country.ph': '',
        'country.pk': '',
        'country.pl': '',
        'country.pm': '',
        'country.pn': '',
        'country.pr': '',
        'country.ps': '',
        'country.pt': '',
        'country.pw': '',
        'country.py': '',
        'country.qa': '',
        'country.re': '',
        'country.ro': '',
        'country.rs': '',
        'country.ru': '',
        'country.rw': '',
        'country.sa': '',
        'country.sb': '',
        'country.sc': '',
        'country.sd': '',
        'country.se': '',
        'country.sg': '',
        'country.sh': '',
        'country.si': '',
        'country.sj': '',
        'country.sk': '',
        'country.sl': '',
        'country.sm': '',
        'country.sn': '',
        'country.so': '',
        'country.sr': '',
        'country.ss': '',
        'country.st': '',
        'country.sv': '',
        'country.sx': '',
        'country.sy': '',
        'country.sz': '',
        'country.tc': '',
        'country.td': '',
        'country.tf': '',
        'country.tg': '',
        'country.th': '',
        'country.tj': '',
        'country.tk': '',
        'country.tl': '',
        'country.tm': '',
        'country.tn': '',
        'country.to': '',
        'country.tr': '',
        'country.tt': '',
        'country.tv': '',
        'country.tw': '',
        'country.tz': '',
        'country.ua': '',
        'country.ug': '',
        'country.um': '',
        'country.us': '',
        'country.uy': '',
        'country.uz': '',
        'country.va': '',
        'country.vc': '',
        'country.ve': '',
        'country.vg': '',
        'country.vi': '',
        'country.vn': '',
        'country.vu': '',
        'country.wf': '',
        'country.ws': '',
        'country.ye': '',
        'country.yt': '',
        'country.za': '',
        'country.zm': '',
        'country.zw': '',
        cyclocross: 'Ciclocrosse',
        'date.all_time': 'DATA: TODOS OS TEMPOS',
        'date.last_month': 'DATA: MÊS PASSADO',
        'date.last_week': 'DATA: SEMANA PASSADO',
        'date.last_year': 'DATA: NO ANO PASSADO',
        'employees.add_cta_label': 'Adicionar funcionário',
        'employees.delete_cta_label': 'Excluir funcionário',
        'employees.employee_id_label': 'ID do Empregado',
        'employees.heading': 'Funcionários',
        'employees.intro':
            'Uma linha do tempo de todos os seus funcionários mais recentes com a Tecnologia Retül.',
        'employees.update_cta_label': 'Atualizar funcionário',
        'filters.all_label': 'TODOS',
        'filters.client_all': 'Todos',
        'filters.client_anonymous': 'Anônimo',
        'filters.client_identified': 'Identificado',
        'filters.client_label': 'Tipo de cliente',
        'filters.employees_label': 'Funcionários',
        'filters.filters_heading': 'Filtros',
        'filters.hide_filters': 'Mostrar filtros',
        'filters.locations_label': 'Locais',
        'filters.rating_all': 'TODOS',
        'filters.rating_label': 'Classificação',
        'filters.rating_none': 'NENHUM',
        'filters.session_all': 'Todos',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Tipo de sessão',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Mostrar filtros',
        'filters.status_all': 'Todos',
        'filters.status_closed': 'Fechado',
        'filters.status_label': 'Estado',
        'filters.status_open': 'Aberto',
        'footer.privacy_link_label': 'Politica de Privacidade',
        'footer.terms_link_label': 'Termos de Uso',
        'form.address_1_field_error': 'Endereço é necessário.',
        'form.address_1_field_label': 'Endereço',
        'form.city_field_error': 'Cidade é necessária.',
        'form.city_field_label': 'Cidade',
        'form.country_field_error': 'País é necessária.',
        'form.country_field_label': 'País',
        'form.delete_field_confirm_message':
            'Tem certeza de que deseja excluir {data} ?',
        'form.delete_field_label': 'Apagar',
        'form.edit_field_label': 'Editar',
        'form.email_field_error':
            'E-mail é necessária e deve ser um formato de e-mail válido.',
        'form.email_field_label': 'Email',
        'form.name_field_error': 'Nome é necessário.',
        'form.name_field_label': 'Nome',
        'form.shop_field_label': 'loja',
        'form.shops_field_label': 'Lojas',
        'form.state_field_error': 'Estado é necessário.',
        'form.state_field_label': 'Estado',
        'form.zip_field_error': 'Zip é necessário.',
        'form.zip_field_label': '',
        'gender.all': 'GÊNERO: TODOS',
        'gender.female': 'GÊNERO: FEMININO',
        'gender.male': 'GÉNERO: MASCULINO',
        'landing.heading': 'Bem-vindo ao Portal do Retalhista',
        'landing.intro':
            'Acesso dados, sessões e cliente informações capturadas pela tecnologia Retül.',
        'landing.sign_in_or_create_account_label': 'Entrar / Criar conta',
        'locale.label_cs': '',
        'locale.label_de': '',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': '',
        'locale.label_fr': '',
        'locale.label_it': '',
        'locale.label_ja': '',
        'locale.label_ko': '',
        'locale.label_nl': '',
        'locale.label_pl': '',
        'locale.label_pt': '',
        'locale.label_pt-br': '',
        'locale.label_zh-cn': '',
        'locale.label_zh-tw': '',
        'locale.locale_picker_cancel_label': 'Cancelar',
        'locale.locale_picker_heading': 'Escolha o seu idioma.',
        'locale.locale_picker_save_label': 'Salvar',
        'locations.add_cta_label': 'Adicionar localização',
        'locations.delete_cta_label': 'Excluir localização',
        'locations.empty':
            'Não há locais foram adicionados, para usar Apps Retül por favor adicionar um local.',
        'locations.empty_cta_label': 'Gerenciar locais',
        'locations.heading': 'Gerenciar locais',
        'locations.intro':
            'Adicionar, editar e remover locais usando a tecnologia Retül.',
        'locations.location_id_label': 'ID de localização',
        'locations.update_cta_label': 'Atualizar localização',
        'metrics.closed_session_tooltip':
            'Sessões fechadas indicam sessões que possuem uma transação registrada. Se um cliente fez ou não uma compra, {closingSessions} ajuda você a acompanhar as vendas da tecnologia Retül.',
        'metrics.closed_sessions': 'Sessões fechadas',
        'metrics.closing_session_link': 'sessão de encerramento',
        'metrics.total_sessions': 'Total de sessões',
        'metrics.unique_client_emails': 'E-mails de clientes exclusivos',
        'metrics.unique_emails_tooltip':
            'Esse número representa as sessões que foram realizadas com clientes exclusivos. Clientes únicos são determinados pelo seu endereço de e-mail.',
        'mtb-trail': 'Montanha (trilha)',
        'mtb-xc': 'Montanha (Cross-Country)',
        'nav.analytics': 'Analytics',
        'nav.greeting_label': 'Olá, {data}',
        'nav.manage_employees_link_label': 'Gerenciar funcionários',
        'nav.manage_locations_link_label': 'Gerenciar locais',
        'nav.sessions': 'Sessões',
        'nav.sign_out_link_label': 'Sair',
        'pagination.next': 'Seguinte',
        'pagination.page': 'Página',
        'pagination.previous': 'Anterior',
        road: 'Estrada',
        'session_details.add_transaction_cta_label': 'Adicionar transação',
        'session_details.all_sessions_link': 'Todas as Sessões',
        'session_details.bike_product': 'Bicicleta',
        'session_details.client': 'Cliente',
        'session_details.client_review_label': 'Revisão do cliente',
        'session_details.complete_session_cta_label': 'Marcar como fechado',
        'session_details.complete_session_message':
            'Fechar esta sessão adicionando quaisquer transacções feitas pelo cliente, ou',
        'session_details.complete_status': 'Fechado',
        'session_details.created_on': 'Criado em: {data}',
        'session_details.delete_link': 'Apagar',
        'session_details.employee': 'Empregado: {data}',
        'session_details.family_label': 'Família',
        'session_details.incomplete_status': 'Aberto',
        'session_details.model_label': 'Modelo',
        'session_details.no_transactions_message':
            'Nenhuma transação foi adicionada.',
        'session_details.product_label': 'produto',
        'session_details.saddle_product': 'selim',
        'session_details.shoe_product': 'sapato',
        'session_details.shop': 'Loja: {data}',
        'session_details.size_label': 'Tamanho',
        'session_details.status_label': 'Estado',
        'session_details.transactions_heading': 'Transações de Sessão',
        'session_products.arch_height_label': 'Altura do arco: {data}',
        'session_products.bike_size_label': 'Tamanho da bicicleta: {data}',
        'session_products.saddle_height_label': 'Altura do selim: {data}mm',
        'session_products.saddle_width_label': 'Largura da sela: {data}mm',
        'session_products.session_product_label': 'Dados da Sessão',
        'session_products.shoe_size_label': 'Sapato tamanho: {data}',
        'sessions.add_transaction_cta_label': 'Adicionar transação',
        'sessions.client_anonymous': 'Anônimo',
        'sessions.client_label': 'Cliente',
        'sessions.closed_status': 'FECHADO',
        'sessions.csv_downloading_message':
            'Estamos reunindo todas as suas sessões para o seu CSV. Isso pode levar alguns minutos.',
        'sessions.date_label': 'Data',
        'sessions.employee_label': 'Empregado',
        'sessions.export_csv_link': 'Exportar lista (.CSV)',
        'sessions.filter_empty':
            'Desculpa, não há nenhum sessões que correspondem aos seus critérios de filtro. Por favor, redefina a seleção de filtro.',
        'sessions.filter_empty_cta_label': 'Redefinir Filtros',
        'sessions.heading': 'Sessões',
        'sessions.intro':
            'Um cronograma de todas as suas sessões mais recentes com tecnologia Retül.',
        'sessions.location_label': 'Localização',
        'sessions.no_rating': 'Sem classificação',
        'sessions.open_status': 'ABERTO',
        'sessions.rating_label': 'Classificação',
        'sessions.session_id_label': 'ID de sessão',
        'sessions.session_loading_message':
            'Estamos aqui reunidos todas as sessões, isso pode levar um momento.',
        'sessions.status_label': 'Estado',
        'sign_in.heading': 'Assinar em',
        'sign_in.intro':
            'Digite seu e-mail e senha para começar, ou escolha "Registar conta" para fazer um novo.',
        'status.all': 'ESTADO: TODOS',
        'status.complete': 'ESTADO: FECHADO',
        'status.incomplete': 'ESTADO: ABERTO',
        triathlon: 'Triatlo',
        'POWER MIMIC DESCRIPTION':
            'Esta sela faz com que a dormência e a dor desapareçam com um design que imita a resposta do corpo a diferentes tipos de pressão para criar equilíbrio dentro do tecido mole.',
        'date_picker.all_time': 'Todas as Sessões',
        'date_picker.date_range': 'Intervalo de datas',
        'date_picker.last_month': 'Mês passado',
        'date_picker.last_week': 'Semana passada',
        'date_picker.last_year': 'Ano passado',
        'date_picker.this_month': 'Este mês',
        'date_picker.this_week': 'Esta semana',
        'date_picker.this_year': 'Este ano',
        'session_details.client_notes': 'Notas do cliente',
        'session_details.footbed_product': 'palmilha',
        'session_products.it_width_label': 'Largura de IT: {data}mm',
        'bike_size.large': 'L',
        'bike_size.medium': 'M',
        'bike_size.small': 'S',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match é uma experiência de avaliação na loja que mede com rigor o seu comprimento de perna de modo a proporcionar-lhe o dimensionamento otimizado para a sua bicicleta, assim como os produtos que melhor correspondem às suas características.',
        'body_data.about_saddle':
            'O Retül Match é uma experiência imersiva na loja que tira partido do Retül Digital Sitbone Device para registar a largura do seu ísquio através de tecnologia digital de mapa de pressão de modo a proporcionar um dimensionamento rigoroso do selim, bem como os selins que melhor correspondem às suas características.',
        'body_data.about_shoe':
            'O Retül Match é uma experiência imersiva na loja que tira partido do Retül Digital Sitbone Device para registar os dados relativos ao pé de modo a proporcionar um suporte de arco correto, tamanho dos sapatos, assim como os sapatos que melhor correspondem às suas características.',
        'body_data.arch_height_label': 'altura de arco',
        'body_data.bike_label': 'bicicleta',
        'body_data.bike_size_label': 'tamanho de bicicleta',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'circunferência',
        'body_data.find_retul_technology': 'Localizar Tecnologia Retül',
        'body_data.heading': 'Dados sobre o Corpo',
        'body_data.intro':
            'Os dados corporais e as preferências na condução são registados através da tecnologia Retül Vantage de modo a obter informação rigorosa sobre os tamanhos e produtos que melhor correspondem às suas características.',
        'body_data.length_label': 'comprimento',
        'body_data.load_error':
            'Não nos é possível recuperar os seus Dados Corporais de momento, por favor tente mais tarde.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'não recolhidos',
        'body_data.recommended_label': 'Recomendado',
        'body_data.saddle_height_label': 'altura do selim',
        'body_data.saddle_label': 'Selim',
        'body_data.saddle_size_label': 'tamanho do selim',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'canela',
        'body_data.shoe_label': 'sapato',
        'body_data.shoe_size_label': 'tamanho do sapato',
        'body_data.sit_bone_label': 'ísquio',
        'body_data.starting_label': 'A começar',
        'body_data.thigh_label': 'coxa',
        'body_data.view_bike_matches_label':
            'Visualizar Correspondências para Bicicleta',
        'body_data.view_saddle_matches_label':
            'Visualizar Correspondências para Selim',
        'body_data.view_shoe_matches_label':
            'Visualizar Correspondências para Sapato',
        'body_data.your_progress_completion_label':
            '{numComplete}/3 Completado',
        'body_data.your_progress_label': 'O Seu Progresso',
        cancel_label: 'Cancelar',
        'create_account.heading': 'Criar Uma Conta',
        'create_account.intro':
            'Introduzir um e-mail e uma palavra-passe para criar uma conta Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Cancelar',
        'edit_form.edit_form_save_label': 'Guardar',
        email_format_error: 'Formato de e-mail inválido.',
        email_label: 'E-mail',
        'forgot_password.heading': 'Esqueceu-se da Palavra-passe',
        'forgot_password.intro':
            'Esqueceu-se da sua palavra-passe? Introduza o seu e-mail e enviar-lhe-emos um link para recuperá-la.',
        'forgot_password.reset_email_sent':
            'Foi enviado um e-mail de recuperação da palavra-passe para {email}',
        'landing.create_account_label': 'Criar uma Conta',
        'landing.sign_in_label': 'Iniciar Sessão',
        'nav.account_profiles_link_label': 'Conta e Perfis do Ciclista',
        'nav.home_page_link_label': 'Página Inicial',
        'nav.logout_link_label': 'Terminar Sessão',
        'not_found.go_home_label': 'Voltar à Página Inicial',
        'not_found.heading': '404',
        'not_found.intro':
            'A página que procura parece ter mudado de endereço, ter sido eliminada ou não existir.',
        password_invalid_error: 'Palavra-passe inválida.',
        password_label: 'Palavra-passe',
        'product_matches.bike_size_label': 'tamanho de bicicleta',
        'product_matches.category_filter_commute_comfort_label':
            'Trajeto-conforto',
        'product_matches.category_filter_commute_sport_label':
            'Trajeto-desporto',
        'product_matches.category_filter_cyclocross_label': 'Ciclocross',
        'product_matches.category_filter_label': 'Categoria',
        'product_matches.category_filter_label_road': 'Estrada',
        'product_matches.category_filter_mountain_trail_label':
            'Montanha (trail)',
        'product_matches.category_filter_mountain_xc_label':
            'Montanha (cross-country)',
        'product_matches.category_filter_triathlon_label': 'Triatlo',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Não temos dados corporais para lhe fornecer resultados de Correspondência para este tipo de produto. Utilize o filtro acima para ver outros tipos de produto ou utilize a página relativa a localizações para encontrar uma localização para completar este módulo de Correspondência.',
        'product_matches.find_retul_technology_label':
            'Encontrar Tecnologia Retül',
        'product_matches.gender_filter_all_label': 'Mostrar todos os produtos',
        'product_matches.gender_filter_female_label':
            'Mostrar produtos para senhora',
        'product_matches.gender_filter_male_label':
            'Mostrar produtos para homem',
        'product_matches.heading': 'Produtos Correspondentes',
        'product_matches.intro':
            'A tecnologia Retül utiliza os seus dados corporais e preferências de condução para os combinar com produtos Especializados que correspondem melhor às suas características.',
        'product_matches.last_session_match_label':
            'Última Sessão de Correspondência',
        'product_matches.load_error':
            'Não nos é possível recuperar os seus Produtos Correspondentes de momento, por favor tente mais tarde.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'A partir da biometria capturada durante a sua sessão Retül Match, recomendamos o tamanho de bicicleta acima. Mas, embora este seja um bom ponto de partida, recomendamos mesmo assim que consulte o seu revendedor local para obter recomendações com base no seu nível de experiência e tipo de corpo.',
        'product_matches.overlay_bike_size_label': 'tamanho de bicicleta',
        'product_matches.overlay_close_label': 'Fechar',
        'product_matches.overlay_indicator_label': 'Você',
        'product_matches.overlay_saddle_height_details':
            'A partir da biometria capturada durante a sua sessão Retül Match, recomendamos a altura de selim acima indicada. Mas, embora este seja um bom ponto de partida, recomendamos mesmo assim que consulte o seu revendedor local para obter recomendações com base no seu nível de experiência e tipo de corpo.',
        'product_matches.overlay_saddle_height_label': 'altura do selim',
        'product_matches.overlay_saddle_size_details':
            'A partir da biometria capturada durante a sua sessão do Retül Match, recomendamos o tamanho de selim acima indicado. Mas, embora este seja um bom ponto de partida, recomendamos mesmo assim que consulte o seu revendedor local para obter recomendações com base no seu nível de experiência.',
        'product_matches.overlay_saddle_size_label': 'tamanho do selim',
        'product_matches.overlay_shoe_size_details':
            'A partir da biometria capturada durante a sua sessão do Retül Match, recomendamos o tamanho do sapato acima indicado. Mas, embora este seja um bom ponto de partida, recomendamos mesmo assim que consulte o seu revendedor local para obter recomendações com base no seu nível de experiência.',
        'product_matches.overlay_shoe_size_label': 'tamanho do sapato',
        'product_matches.padding_level_label': 'Nível de Enchimento',
        'product_matches.product_type_filter_bikes_label': 'Bicicletas',
        'product_matches.product_type_filter_label': 'Tipo de Produto',
        'product_matches.product_type_filter_saddles_label': 'Selins',
        'product_matches.product_type_filter_shoes_label': 'Sapatos',
        'product_matches.recommended_label': 'Recomendado',
        'product_matches.saddle_detail_size_label': 'Tamanho do Selim:',
        'product_matches.saddle_height_label': 'altura do selim',
        'product_matches.saddle_models_label': 'Modelos',
        'product_matches.saddle_size_label': 'tamanho do selim',
        'product_matches.shoe_size_label': 'tamanho do sapato',
        'product_matches.starting_label': 'A começar',
        'product_matches.view_on_specialized_label': 'Ver em Specialized',
        'profile.account_heading': 'Conta',
        'profile.bike_type_city_label': 'Cidade',
        'profile.bike_type_label': 'Tipo de Bicicleta',
        'profile.bike_type_mountain_label': 'Montanha',
        'profile.bike_type_road_label': 'Estrada',
        'profile.email_error': 'Por favor introduza um e-mail válido',
        'profile.email_label': 'E-mail',
        'profile.gender_female_label': 'feminino',
        'profile.gender_label': 'Sexo',
        'profile.gender_male_label': 'masculino',
        'profile.heading': 'Conta e Perfis do Ciclista',
        'profile.intro':
            'Dentro em breve... edite a informação relativa à sua conta e os perfis de ciclista.',
        'profile.name_error': 'Por favor introduza um nome',
        'profile.name_label': 'Nome',
        'profile.password_error': 'Por favor introduza uma palavra-passe',
        'profile.password_label': 'Palavra-passe',
        'profile.position_high_label': 'Alta',
        'profile.position_label': 'Posição',
        'profile.position_low_label': 'Baixa',
        'profile.position_medium_label': 'Média',
        'profile.ride_duration_extended_label': '+90 minutos',
        'profile.ride_duration_label': 'Duração',
        'profile.ride_duration_long_label': '60-90 minutos',
        'profile.ride_duration_medium_label': '30-60 minutos',
        'profile.ride_duration_short_label': '0-30 minutos',
        'profile.ride_frequency_label': 'Frequência',
        'profile.ride_frequency_once_month_label': 'Uma vez por mês',
        'profile.ride_frequency_once_week_label': 'Uma vez por semana',
        'profile.ride_frequency_six_week_label': '6 Vezes por semana',
        'profile.ride_frequency_three_week_label': '3 Vezes por semana',
        'routes.account_profile_route_label': 'Conta e Perfis do Ciclista',
        'routes.body_data_route_label': 'Dados Corporais',
        'routes.create_account_route_label': 'Criar Conta',
        'routes.forgot_password_route_label': 'Esqueceu-se da Palavra-passe',
        'routes.locations_route_label': 'Localizações',
        'routes.not_found_route_label': 'Não Encontrado',
        'routes.privacy_policy_route_label': 'Política de Privacidade',
        'routes.product_matches_route_label': 'Produtos Correspondentes',
        'routes.reset_password_route_label': 'Recuperar Palavra-passe',
        'routes.set_password_route_label': 'Recuperar Palavra-passe',
        'routes.sessions_route_label': 'Sessões',
        'routes.sign_in_route_label': 'Iniciar Sessão',
        'routes.terms_of_service_route_label': 'Termos de Serviço',
        'sessions.download_pdf_cta_label': 'Transferir PDF',
        'sessions.empty': 'Não teve nenhuma sessão.',
        'sessions.empty_cta_label': 'Encontrar Localizações',
        'sessions.loading_error':
            'Não nos é possível recuperar as suas Sessões de momento, por favor tente mais tarde.',
        'sessions.loading_error_cta_label': 'Tentar Novamente',
        'sessions.session_type_filter_label': 'Tipo de Sessão',
        'session_type.dfd_session_name': 'Retül Digital Foot Device',
        'session_type.dsd_session_name': 'Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül Pé',
        'session_type.legs_session_name': 'Retül Pernas',
        'session_type.match_session_name': 'Retül Correspondência',
        'session_type.saddle_session_name': 'Retül Selim',
        'session_type.unknown_session_name': 'Retül Sessão',
        'session_type.vantage_session_name': 'Retül Ajuste',
        'sign_in.sign_in_label': 'Iniciar Sessão',
        'sign_in.contact_label': 'Contacte a Retül',
        'sign_in.forgot_password_label': 'Esqueceu-se da Palavra-passe?',
        'sign_in.sign_in_error':
            'Não nos é possível recuperar os seus Dados de Utilizador. Por favor contacte o Apoio Retül para ajuda.',
        submit_label: 'Enviar',
    },
};
