import { REQUESTING, RECEIVED } from '../types/statuses';
import {
    POST_VERIFY,
    RECEIVE_VERIFY,
    RECEIVE_VERIFY_ERROR,
} from '../types/actions/verify';

export const initialState = {
    verifyData: {
        data: null,
        fetchingStatus: null,
        errors: null,
    },
};

function postVerify(state) {
    const verifyData = {
        ...state.verifyData,
        fetchingStatus: REQUESTING,
    };

    return { ...state, verifyData };
}

function receiveVerify(state, action) {
    const verifyData = {
        ...state.verifyData,
        fetchingStatus: RECEIVED,
        data: action.data,
    };

    return { ...state, verifyData };
}

function receiveVerifyError(state, action) {
    const verifyData = {
        ...state.verifyData,
        fetchingStatus: RECEIVED,
        errors: action.errors,
    };

    return { ...state, verifyData };
}

export default function clientProfile(state = initialState, action) {
    switch (action.type) {
        case POST_VERIFY:
            return postVerify(state);
        case RECEIVE_VERIFY:
            return receiveVerify(state, action);
        case RECEIVE_VERIFY_ERROR:
            return receiveVerifyError(state, action);
        default:
            return state;
    }
}
