import React, { Component } from 'react';
import Main from '../../components/Main/Main';
import CreateAccountForm from '../../components/CreateAccountForm/CreateAccountForm';
// import CreateAccountForm from '../../components/CreateAccountForm/CreateAccountFormOld';
import Loader from '../../components/Loader/Loader';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';
import Intro from '../../components/Intro/Intro';
import messages from '../../utils/messages';
import { injectIntl } from 'react-intl';

class CreateAccount extends Component {
    constructor(props) {
        super(props);

        const { intl } = props;

        this.page = {
            heading: intl.formatMessage(messages.create_account_heading),
            intro: intl.formatMessage(messages.create_account_intro),
        };
    }

    renderCreateAccountForm() {
        const { handleSubmit, serverErrors, createAccount } = this.props;
        return componentWithErrorBoundary(
            <CreateAccountForm
                handleSubmit={handleSubmit}
                createAccount={createAccount}
                serverErrors={serverErrors}
            />
        );
    }

    render(props) {
        return (
            <Main className="SignIn">
                <Loader
                    isLoading={this.props.fetchingStatus === 'REQUESTING'}
                />
                <section className="top top_medium">
                    <div className="container">
                        <Intro
                            header={this.page.heading}
                            summary={this.page.intro}
                        />
                    </div>
                </section>
                <section className="bottom bottom_extraPad bottom_dark">
                    <div className="container">
                        {this.renderCreateAccountForm()}
                    </div>
                </section>
            </Main>
        );
    }
}

export default injectIntl(CreateAccount);
