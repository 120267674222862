import React, {Component} from 'react';
import './Bars.css';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'

export default class Bars extends Component {
  renderRect(i, data, scale) {
    const height = this.props.height || 7;

    return (
      <Tippy content={data.quantity.toString()} followCursor={true}>
        <rect
          className="Bar"
          key={`bar-${i}`}
          y={scale(data.item)}
          height={height}
          width={data.quantity}
        />
      </Tippy>
    )
  }

  renderText(i, data, scale) {
    const {top} = this.props.margin;
    const y = scale(data.item) + scale.bandwidth() * 2 - top;

    return (
      <text
        className="Bar-Text"
        key={`bar-text-${i}`}
        y={y}
        x={3}
      >
        {data.item}
      </text>
    )
  }

  renderBars() {
    const {data} = this.props;
    const {yScale} = this.props.scales;

    return (data.map((d, i) => {
        return (
          <g
            className="Bar-Group"
            key={`g-${i}`}
          >
            {this.renderRect(i, d, yScale)}
            {this.renderText(i, d, yScale)}
          </g>
        )
      })
    )
  }

  render() {
    const {margin} = this.props;

    return (
      <g
        className="Horizontal-Bar-Chart"
        transform={`translate(${10}, ${-margin.top})`}>
        {this.renderBars()}
      </g>
    )
  }
}
