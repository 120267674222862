import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../components/Button/Button';
import messages from '../../utils/messages';
import Text from '../../components/Text/Text';
import makeHash from '../../utils/makeHash';
import './Verify.css';

class VerifyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverError: false,
            error: false, // may represent client-side error or server-side error
            formMessages: [],
            loading: false,
        };

        this.fields = {};
        this.setUpHandlers();
    }

    setUpHandlers() {
        this.goToSignIn = this.goToSignIn.bind(this);
        this.verifyAccount = this.verifyAccount.bind(this);
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');
        const verificationCode = params.get('verification_code');
        const serverErrors = this.props.errors;
        const errorMessages = [];

        if (!email) errorMessages.push('Email is missing');
        if (!verificationCode)
            errorMessages.push('Verification code is missing');

        // Check client-side errors
        if (errorMessages.length > 0) {
            this.setState({
                loading: false,
                error: true,
                formMessages: [
                    ...errorMessages,

                    <Button
                        animateOnClick
                        onClick={this.goToSignIn}
                        className="btn_fixedWidth"
                    >
                        {this.props.intl.formatMessage(messages.sign_in_label)}
                    </Button>,
                ],
            });
        } else if (serverErrors) {
            // Check server-side errors
            this.setState({
                loading: false,
                error: true,
                formMessages: [
                    serverErrors.reason,
                    <Button
                        animateOnClick
                        onClick={this.goToSignIn}
                        className="btn_fixedWidth"
                    >
                        {this.props.intl.formatMessage(messages.sign_in_label)}
                    </Button>,
                ],
            });
        }

        // Verify run successfully
        if (this.props.data && !this.props.errors) {
            const message = messages[this.props.data.reason];
            this.setState({
                loading: false,
                error: false,
                formMessages: [
                    this.props.intl.formatMessage(message),
                    <Button
                        animateOnClick
                        onClick={this.goToSignIn}
                        className="btn_fixedWidth"
                    >
                        {this.props.intl.formatMessage(messages.sign_in_label)}
                    </Button>,
                ],
            });
        }

        // Run verification
        if (!this.props.fetchingStatus) {
            this.verifyAccount(email, verificationCode);
        }
    }

    verifyAccount(email, verificationCode) {
        this.props.handleSubmit({
            email,
            verificationCode,
            client: 'retailer',
        });
    }

    _renderFormMessages() {
        let errorMessage = null;
        const hasErrors =
            this.props.serverErrors &&
            this.props.serverErrors.errors &&
            this.props.serverErrors.errors.length > 0;

        if (hasErrors) {
            const firstError = this.props.serverErrors.errors[0];
            const message = messages[firstError.message];

            let missingField = '';
            if (firstError.message === 'REQUIRED_FIELD') {
                missingField = firstError.field;
            }
            errorMessage = this.props.intl.formatMessage(message, {
                field: missingField,
            });
        }

        const color = this.state.error ? 'DefaultOrange' : 'DefaultBlue';

        return (
            <ul className="form-messages_hasMessage">
                {this.state.formMessages.map((msg, i) => {
                    if (typeof msg === 'string') {
                        return (
                            <li key={makeHash(`${msg}-${i}`)}>
                                <Text color={color}>
                                    {msg.endsWith('.') ? msg : msg + '.'}
                                </Text>
                            </li>
                        );
                    } else {
                        return <li key={makeHash(`${i}`)}>{msg}</li>;
                    }
                })}
                {hasErrors && (
                    <li>
                        <Text color={color}>{errorMessage}</Text>
                    </li>
                )}
            </ul>
        );
    }

    goToSignIn() {
        window.location = '/sign-in';
    }

    render() {
        const formMessages = this._renderFormMessages();

        return (
            <div className="form-container">
                <div className="container container_constrainedMd">
                    <div className="gigya-screen-set" id="userScreensDiv" />

                    <div className="set mix-set_alignCenter">
                        {formMessages}
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(VerifyForm);
