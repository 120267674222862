import React from 'react';
import {NavLink} from 'react-router-dom';
import './NavigationDropDown.css';
import {NAVIGATION_DROPDOWN_ITEMS} from '../../types/urls';
import NavigationDropDownIcon from '../../components/NavigationDropDownIcon/NavigationDropDownIcon';
import {injectIntl} from 'react-intl';
import messages from './messages';

class NavigationDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'listVisible': false,
    };

    this.intl = props.intl;

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.openClass = this.openClass.bind(this);
  }

  dropDownClassName() {
    const visibleStatus = this.state.listVisible ? 'show' : '';
    return `dropdown ${this.props.classExtensions} ${visibleStatus}`
  }

  openClass() {
    return this.state.listVisible ? 'open' : '';
  }

  componentWillUnmount() {
    this.setState({listVisible: false});
    document.removeEventListener("click", this.hide);
  }

  show() {
    this.setState({listVisible: true});
    document.addEventListener("click", this.hide);
  }

  hide() {
    this.setState({listVisible: false});
    document.removeEventListener("click", this.hide);
  }

  renderNavLink(key, navigationItem, clickHandler) {
    return (
      <li key={key}>
        <NavLink
          to={navigationItem.path}
          activeClassName="active"
          className="dropdown-option"
          onClick={clickHandler}
        >
          {this.intl.formatMessage(messages[navigationItem.displayedText])}
        </NavLink>
      </li>
    )
  }

  renderListItems() {
    return NAVIGATION_DROPDOWN_ITEMS.map((navigationItem, index) => {
      if (navigationItem.isSignOut) {
        const {signOutHandler} = this.props;
        return (this.renderNavLink(index, navigationItem, signOutHandler))
      }
      return (this.renderNavLink(index, navigationItem))
    })
  }

  render() {
    const {data} = this.props;

    return (
      <div className="NavigationDropDown">
        <div className={`${this.dropDownClassName()}`}>
          <button
            className="dropdown-trigger"
            onClick={this.show}
          >
            <span className="dropdown-trigger-text dropdown-trigger-text_ellipse">
              {this.intl.formatMessage(messages.greeting_label, {data})}
            </span>
            <NavigationDropDownIcon
              openClass={this.openClass}
            />
          </button>
          <ul className="dropdown-list">
            {this.renderListItems()}
          </ul>
        </div>
      </div>
    )
  }
}

export default injectIntl(NavigationDropDown);
