import React, { Component } from 'react';
import Arc from '../Arc/Arc';
import * as d3 from 'd3';

export default class PieChart extends Component {
  renderArc(data, index, arc) {
    const { colorRange } = this.props;

    return (
      <Arc
        key={ index }
        data={ data }
        colorRange={ colorRange }
        arc={ arc }
      />
    )
  }


  intializeArc() {
    const radius = this.calculateRadius();

    return d3.arc()
      .innerRadius(radius * .6)
      .outerRadius(radius);
  }

  initializePie() {
    return d3.pie().value((d) => d.quantity);
  }

  calculateRadius(){
    const { width } = this.props;
    return  Math.min(width) / 5;
  }



  render() {
    const { data } = this.props;
    const pie = this.initializePie();
    const arc = this.intializeArc();

    return (
      <g
        className="pie-chart"
      >
        { pie(data).map((d, i) => { return this.renderArc(d, i, arc) }) }
      </g>
    )
  }
}