import * as actionTypes from '../types/actions/client'
import {apiGet, apiPut} from '../api/apiClient';
import {JSON_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';
import {fetchClientUrl, fetchSessionUrl} from '../utils/urlHelper';
import {receiveSession, requestSession} from "./sessionDetails";

export function fetchClient(id) {
  return dispatch => {
    dispatch(requestClient());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchClientUrl(id),
          receiveCallback: receiveClient,
        }
      )
    )
  }
}


export function fetchSession(sessionId) {
  return dispatch => {
    dispatch(requestSession());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchSessionUrl(sessionId),
          receiveCallback: receiveSession,
        }
      )
    )
  }
}



export function updateClient(id, data) {
  return dispatch => {
    dispatch(requestClientUpdate());

    return apiPut(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          parser: JSON_PARSER,
          url: fetchClientUrl(id),
          failCallback: receiveClientUpdateError,
          receiveCallback: receiveClientUpdate,
        }
      )
    )
  }
}



export function updateSession(sessionId, data, receiveCallback) {
  return dispatch => {
    dispatch(requestSession());

    return apiPut(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback,
          url: fetchSessionUrl(sessionId),
          body: JSON.stringify(data)
        }
      )
    )
  }
}

export function requestClient() {
  return {
    type: actionTypes.REQUEST_CLIENT_DATA
  }
}

export function receiveClient(data) {
  return {
    type: actionTypes.RECEIVE_CLIENT_DATA,
    data
  }
}

export function requestClientUpdate() {
  return {
    type: actionTypes.REQUEST_CLIENT_UPDATE
  }
}

export function receiveClientUpdate(data) {
  return {
    type: actionTypes.RECEIVE_CLIENT_UPDATE,
    data
  }
}

export function receiveClientUpdateError(errors) {
  return {
    type: actionTypes.RECEIVE_CLIENT_UPDATE_ERROR,
    errors
  }
}
