/* eslint-disable */
export const localeStrings = {
    nl: {
        'add_transaction.back_to_sessions_label': 'Terug naar Sessies',
        'add_transaction.bike_label': 'Fiets',
        'add_transaction.commute_comfort_type': 'woon-werkverkeer-comfort',
        'add_transaction.commute_sport_type': 'woon-werkverkeer-sport',
        'add_transaction.complete_transaction_cta_label': 'Voltooi transactie',
        'add_transaction.confirm_label': 'bevestigen',
        'add_transaction.cyclocross_type': 'Cyclocross',
        'add_transaction.family_label': 'familie',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'voetbed',
        'add_transaction.heading': 'Transactie toevoegen aan {data}',
        'add_transaction.model_label': 'model',
        'add_transaction.mountain_type': 'Mountain',
        'add_transaction.mtb_trail_type': 'Mountain (trail)',
        'add_transaction.mtb_xc_type': 'Mountain (cross-country)',
        'add_transaction.product_label': 'product',
        'add_transaction.ratings_message':
            'Wanneer deze transactie wordt voltooit, ontvangt {data} een e-mail om dit product te beoordelen. Deze beoordelig is zichtbaar op de sessielijst en sessiedetailpagina.',
        'add_transaction.road_type': 'Road',
        'add_transaction.saddle_label': 'Zadel',
        'add_transaction.shoe_label': 'Schoen',
        'add_transaction.size_label': 'maat',
        'add_transaction.transaction_complete_message': 'Transactie voltooid.',
        'add_transaction.triathlon_type': 'Triatlon',
        'add_transaction.tt_tri_type': 'TT / Tri',
        'add_transaction.type_label': 'type',
        'analytics.loading_message':
            'We zijn uw gegevens aan het samenstellen, dit kan een paar minuten duren',
        'analytics_filters.analytics_by': 'ANALYTICS DOOR',
        'analytics_filters.filter_by': 'FILTEREN OP',
        'analytics_filters.products': 'PRODUCTEN',
        'analytics_filters.sessions': 'SESSIES',
        'charts.bike_label_products': 'Fiets',
        'charts.bike_label_sessions': 'Fiets',
        'charts.commute_comfort_label': 'woon-werkverkeer-comfort',
        'charts.commute_sport_label': 'woon-werkverkeer-sport',
        'charts.cyclocross_label': 'cyclocross',
        'charts.female_label': 'vrouw',
        'charts.friday_label': 'Vrijdag',
        'charts.high_arch_label': 'hoog',
        'charts.low_arch_label': 'laag',
        'charts.male_label': 'man',
        'charts.medium_arch_label': 'medium',
        'charts.monday_label': 'Maandag',
        'charts.mtb_trail_label': 'mtb-trail',
        'charts.mtb_xc_label': 'mtb-XC',
        'charts.road_label': 'Road',
        'charts.saddle_label_products': 'Zadel',
        'charts.saddle_label_sessions': 'Zadel',
        'charts.saturday_label': 'Zaterdag',
        'charts.sessions_by_bike_type_label': 'Sessies per fietstype',
        'charts.sessions_by_day_of_week_label': 'Sessies per dag van de week',
        'charts.sessions_by_employee_label': 'Sessies per medewerker',
        'charts.sessions_by_foot_arch_label': 'Sessies per voetboog',
        'charts.sessions_by_gender_label': 'Sessies per geslacht',
        'charts.sessions_by_product_label': 'Sessies per product',
        'charts.sessions_by_saddle_height_label': 'Sessies op zadelhoogte',
        'charts.sessions_by_saddle_size_label': 'Sessies op zadelbreedte',
        'charts.sessions_by_shirt_bike_size_label':
            'Sessies op fietsmaat (T-shirt)',
        'charts.sessions_by_shoe_size_label': 'Sessies op schoenmaat',
        'charts.sessions_by_sitbone_width_label': 'Sessies op zitbot breedte',
        'charts.sessions_by_standard_bike_size_label':
            'Sessies op fietsmaat (standaard)',
        'charts.sessions_over_time_label': 'Sessies in de loop van de tijd',
        'charts.shoe_label_products': 'Schoen',
        'charts.shoe_label_sessions': 'Schoen',
        'charts.sunday_label': 'Zondag',
        'charts.thursday_label': 'Donderdag',
        'charts.top_ten_session_label': 'Top tien sessiedata',
        'charts.top_transactions_label': 'Top Transacties Data',
        'charts.triathlon_label': 'Triatlon',
        'charts.tuesday_label': 'Dinsdag',
        'charts.wednesday_label': 'Woensdag',
        'client.all_sessions_link': 'Alle sessies',
        'client.bike_progress': 'Fiets',
        'client.client_progress_heading': 'Vooruitgang van de klant',
        'client.client_sessions_heading': 'Laatste klantensessie',
        'client.email': 'Klant e-mail',
        'client.name': 'Klant Naam',
        'client.not_applicable': 'N / A',
        'client.notes_label': 'Opmerkingen',
        'client.progress_types_complete': 'Compleet',
        'client.saddle_progress': 'Zadel',
        'client.save_cta': 'Opslaan',
        'client.shoe_progress': 'Schoen',
        'commute-comfort': 'Woon-werkverkeer-comfort',
        'commute-sport': 'Woon-werkverkeer-sport',
        'country.ad': 'Andorra',
        'country.ae': 'Verenigde Arabische Emiraten',
        'country.af': 'Afghanistan',
        'country.ag': 'Antigua en Barbuda',
        'country.ai': 'Anguilla',
        'country.al': 'Albanië',
        'country.am': 'Armenië',
        'country.ao': 'Angola',
        'country.aq': 'Antarctica',
        'country.ar': 'Argentinië',
        'country.as': 'Amerikaans Samoa',
        'country.at': 'Oostenrijk',
        'country.au': 'Australië',
        'country.aw': 'Aruba',
        'country.ax': 'Ålandeilanden',
        'country.az': 'Azerbeidzjan',
        'country.ba': 'Bosnië-Herzegovina',
        'country.bb': 'Barbados',
        'country.bd': 'Bangladesh',
        'country.be': 'België',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bulgarije',
        'country.bh': 'Bahrein',
        'country.bi': 'Burundi',
        'country.bj': 'Benin',
        'country.bl': 'Saint Barthélemy',
        'country.bm': 'Bermuda',
        'country.bn': 'Brunei',
        'country.bo': 'Bolivia',
        'country.bq': 'Bonaire, Sint Eustatius en Saba',
        'country.br': 'Brazilië',
        'country.bs': 'Bahamas',
        'country.bt': 'Bhutan',
        'country.bv': 'Bouvet eiland',
        'country.bw': 'Botswana',
        'country.by': 'Wit-Rusland',
        'country.bz': 'Belize',
        'country.ca': 'Canada',
        'country.cc': 'Cocos (Keeling) eilanden',
        'country.cd': 'Democratische Republiek Congo',
        'country.cf': 'Centraal Afrikaanse Republiek',
        'country.cg': 'Congo',
        'country.ch': 'Zwitserland',
        'country.ci': 'Ivoorkust',
        'country.ck': 'Cook Eilanden',
        'country.cl': 'Chili',
        'country.cm': 'Kameroen',
        'country.cn': 'China',
        'country.co': 'Colombia',
        'country.cr': 'Costa Rica',
        'country.cu': 'Cuba',
        'country.cv': 'Cabo Verde',
        'country.cw': 'Curacao',
        'country.cx': 'Kersteiland',
        'country.cy': 'Cyprus',
        'country.cz': 'Tsjechië',
        'country.de': 'Duitsland',
        'country.dj': 'Djibouti',
        'country.dk': 'Denemarken',
        'country.dm': 'Dominica',
        'country.do': 'Dominicaanse Republiek',
        'country.dz': 'Algerije',
        'country.ec': 'Ecuador',
        'country.ee': 'Estland',
        'country.eg': 'Egypte',
        'country.eh': 'Westelijke Sahara',
        'country.er': 'Eritrea',
        'country.es': 'Spanje',
        'country.et': 'Ethiopië',
        'country.fi': 'Finland',
        'country.fj': 'Fiji',
        'country.fk': 'Falkland Eilanden',
        'country.fm': 'Micronesië',
        'country.fo': 'Faeröer Eilanden',
        'country.fr': 'Frankrijk',
        'country.ga': 'Gabon',
        'country.gb': 'Verenigd Koninkrijk',
        'country.gd': 'Grenada',
        'country.ge': 'Georgië',
        'country.gf': 'Frans-Polynesië',
        'country.gg': 'Guernsey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibraltar',
        'country.gl': 'Groenland',
        'country.gm': 'Gambia',
        'country.gn': 'Guinea',
        'country.gp': 'Guadeloupe',
        'country.gq': 'Equatoriaal-Guinea',
        'country.gr': 'Griekenland',
        'country.gs': 'Zuid-Georgië en de Zuidelijke Sandwicheilanden',
        'country.gt': 'Guatemala',
        'country.gu': 'Guam',
        'country.gw': 'Guinea-Bissau',
        'country.gy': 'Guyana',
        'country.hk': 'Hong Kong',
        'country.hm': 'Heard Eiland en McDonald Eilanden',
        'country.hn': 'Honduras',
        'country.hr': 'Kroatië',
        'country.ht': 'Haïti',
        'country.hu': 'Hongarije',
        'country.id': 'Indonesië',
        'country.ie': 'Ierland',
        'country.il': 'Israël',
        'country.im': 'Eiland Man',
        'country.in': 'India',
        'country.io': 'Brits-Indisch oceaan gebied',
        'country.iq': 'Irak',
        'country.ir': 'Iran',
        'country.is': 'IJsland',
        'country.it': 'Italië',
        'country.je': 'Jersey',
        'country.jm': 'Jamaica',
        'country.jo': 'Jordanië',
        'country.jp': 'Japan',
        'country.ke': 'Kenia',
        'country.kg': 'Kirgizië',
        'country.kh': 'Cambodja',
        'country.ki': 'Kiribati',
        'country.km': 'Comoren',
        'country.kn': 'Saint Kitts en Nevis',
        'country.kp': 'Noord Korea',
        'country.kr': 'Zuid Korea',
        'country.kw': 'Koeweit',
        'country.ky': 'Kaaiman Eilanden',
        'country.kz': 'Kazachstan',
        'country.la': 'Laos',
        'country.lb': 'Libanon',
        'country.lc': 'Saint Lucia',
        'country.li': 'Liechtenstein',
        'country.lk': 'Sri Lanka',
        'country.lr': 'Liberia',
        'country.ls': 'Lesotho',
        'country.lt': 'Litouwen',
        'country.lu': 'Luxemburg',
        'country.lv': 'Letland',
        'country.ly': 'Libië',
        'country.ma': 'Marokko',
        'country.mc': 'Monaco',
        'country.md': 'Moldavië',
        'country.me': 'Montenegro',
        'country.mf': 'Saint Martin (Frans gedeelte)',
        'country.mg': 'Madagascar',
        'country.mh': 'Marshall eilanden',
        'country.mk': 'Macedonië',
        'country.ml': 'Mali',
        'country.mm': 'Myanmar',
        'country.mn': 'Mongolië',
        'country.mo': 'Macao',
        'country.mp': 'Noordelijke Marianen',
        'country.mq': 'Martinique',
        'country.mr': 'Mauritanië',
        'country.ms': 'Montserrat',
        'country.mt': 'Malta',
        'country.mu': 'Mauritius',
        'country.mv': 'Malediven',
        'country.mw': 'Malawi',
        'country.mx': 'Mexico',
        'country.my': 'Maleisië',
        'country.mz': 'Mozambique',
        'country.na': 'Namibië',
        'country.nc': 'Nieuw Caledonië',
        'country.ne': 'Niger',
        'country.nf': 'Norfolk eiland',
        'country.ng': 'Nigeria',
        'country.ni': 'Nicaragua',
        'country.nl': 'Nederland',
        'country.no': 'Noorwegen',
        'country.np': 'Nepal',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'Nieuw Zeeland',
        'country.om': 'Oman',
        'country.pa': 'Panama',
        'country.pe': 'Peru',
        'country.pf': 'Frans-Polynesië',
        'country.pg': 'Papoea Nieuw Guinea',
        'country.ph': 'Filippijnen',
        'country.pk': 'Pakistan',
        'country.pl': 'Polen',
        'country.pm': 'Saint Pierre en Miquelon',
        'country.pn': 'Pitcairn',
        'country.pr': 'Puerto Rico',
        'country.ps': 'Palestina',
        'country.pt': 'Portugal',
        'country.pw': 'Palau',
        'country.py': 'Paraguay',
        'country.qa': 'Qatar',
        'country.re': 'Réunion',
        'country.ro': 'Roemenië',
        'country.rs': 'Servië',
        'country.ru': 'Rusland',
        'country.rw': 'Rwanda',
        'country.sa': 'Saoedi-Arabië',
        'country.sb': 'Solomon eilanden',
        'country.sc': 'Seychellen',
        'country.sd': 'Soedan',
        'country.se': 'Zweden',
        'country.sg': 'Singapore',
        'country.sh': 'Sint-Helena, Ascension en Tristan da Cunha',
        'country.si': 'Slovenië',
        'country.sj': 'Spitsbergen en Jan Mayen',
        'country.sk': 'Slowakije',
        'country.sl': 'Sierra Leone',
        'country.sm': 'San Marino',
        'country.sn': 'Senegal',
        'country.so': 'Somalië',
        'country.sr': 'Suriname',
        'country.ss': 'Zuid Soedan',
        'country.st': 'Sao Tomé en Principe',
        'country.sv': 'El Salvador',
        'country.sx': 'Sint Maarten (Nederlands deel)',
        'country.sy': 'Syrië',
        'country.sz': 'Swaziland',
        'country.tc': 'Turks- en Caicoseilanden',
        'country.td': 'Tsjaad',
        'country.tf': 'Franse Zuidelijke Gebieden',
        'country.tg': 'Togo',
        'country.th': 'Thailand',
        'country.tj': 'Tadzjikistan',
        'country.tk': 'Tokelau',
        'country.tl': 'Oost-Timor',
        'country.tm': 'Turkmenistan',
        'country.tn': 'Tunesië',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trinidad en Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Taiwan',
        'country.tz': 'Tanzania',
        'country.ua': 'Oekraïne',
        'country.ug': 'Oeganda',
        'country.um': 'Kleine afgelegen eilanden van de Verenigde Staten',
        'country.us': 'Verenigde Staten van Amerika',
        'country.uy': 'Uruguay',
        'country.uz': 'Oezbekistan',
        'country.va': 'Vaticaan',
        'country.vc': 'Saint Vincent en de Grenadines',
        'country.ve': 'Venezuela',
        'country.vg': 'Maagdeneilanden (Brits)',
        'country.vi': 'Maagdeneilanden (VS)',
        'country.vn': 'Vietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis en Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Jemen',
        'country.yt': 'Mayotte',
        'country.za': 'Zuid-Afrika',
        'country.zm': 'Zambia',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Cyclocross',
        'date.all_time': 'DATUM: ALLES',
        'date.last_month': 'DATUM: VORIGE MAAND',
        'date.last_week': 'DATUM: VORIGE WEEK',
        'date.last_year': 'DATUM: VORIG JAAR',
        'employees.add_cta_label': 'Werknemer toevoegen',
        'employees.delete_cta_label': 'Werknemer verwijderen',
        'employees.employee_id_label': 'Werknemers-ID',
        'employees.heading': 'Medewerkers',
        'employees.intro':
            'Een tijdlijn van al uw nieuwste werknemers met Retül Technology.',
        'employees.update_cta_label': 'Werknemer bijwerken',
        'filters.all_label': 'ALLE',
        'filters.client_all': 'Alle',
        'filters.client_anonymous': 'Anoniem',
        'filters.client_identified': 'Geïdentificeerd',
        'filters.client_label': 'Type klant',
        'filters.employees_label': 'Medewerkers',
        'filters.filters_heading': 'Filters',
        'filters.hide_filters': 'Toon Filters',
        'filters.locations_label': 'Locaties',
        'filters.rating_all': 'ALLE',
        'filters.rating_label': 'Beoordeling',
        'filters.rating_none': 'GEEN',
        'filters.session_all': 'Alle',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Sessie Type',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Toon Filters',
        'filters.status_all': 'Alle',
        'filters.status_closed': 'Gesloten',
        'filters.status_label': 'Status',
        'filters.status_open': 'Open',
        'footer.privacy_link_label': 'Privacybeleid',
        'footer.terms_link_label': 'Algemene voorwaarden',
        'form.address_1_field_error': 'Adres is verplicht.',
        'form.address_1_field_label': 'Adres',
        'form.city_field_error': 'Woonplaats is verplicht',
        'form.city_field_label': 'Woonplaats',
        'form.country_field_error': 'Land is verplicht.',
        'form.country_field_label': 'Land',
        'form.delete_field_confirm_message':
            'Weet je zeker dat je {data} wilt verwijderen?',
        'form.delete_field_label': 'Verwijderen',
        'form.edit_field_label': 'Bewerk',
        'form.email_field_error':
            'E-mail is verplicht en moet een geldig e-mailformaat zijn.',
        'form.email_field_label': 'E-mail',
        'form.name_field_error': 'Naam is verplicht.',
        'form.name_field_label': 'Naam',
        'form.shop_field_label': 'Winkel',
        'form.shops_field_label': 'Winkels',
        'form.state_field_error': 'Staat/Provincie is verplicht.',
        'form.state_field_label': 'Staat/Provincie',
        'form.zip_field_error': 'Postcode is verplicht',
        'form.zip_field_label': 'Postcode',
        'gender.all': 'GENDER: ALLES',
        'gender.female': 'GESLACHT: VROUW',
        'gender.male': 'GESLACHT: MAN',
        'landing.heading': 'Welkom bij het Retailer Portal',
        'landing.intro':
            'Toegang tot gegevens, sessies en klantinformatie vastgelegd door Retül-technologie.',
        'landing.sign_in_or_create_account_label': 'Inloggen / Account maken',
        'locale.label_cs': 'Tjechisch ',
        'locale.label_de': 'Duits',
        'locale.label_en': 'Engels',
        'locale.label_en-us': 'Engels (VS)',
        'locale.label_es': 'Spaans',
        'locale.label_fr': 'Frans',
        'locale.label_it': 'Italiaans',
        'locale.label_ja': '日本語',
        'locale.label_ko': '한국어',
        'locale.label_nl': 'Nederlands',
        'locale.label_pl': 'Pools',
        'locale.label_pt': 'Portugees',
        'locale.label_pt-br': 'Portugees (Brazilie) ',
        'locale.label_zh-cn': '简体 中文',
        'locale.label_zh-tw': '繁體 中文',
        'locale.locale_picker_cancel_label': 'Annuleer',
        'locale.locale_picker_heading': 'Taal kiezen',
        'locale.locale_picker_save_label': 'Opslaan',
        'locations.add_cta_label': 'Locatie toevoegen',
        'locations.delete_cta_label': 'Locatie verwijderen',
        'locations.empty':
            'Er zijn geen locaties toegevoegd, om Retül Apps te gebruiken, voeg een locatie toe.',
        'locations.empty_cta_label': 'Locaties beheren',
        'locations.heading': 'Locaties beheren',
        'locations.intro':
            'Locaties toevoegen, bewerken en verwijderen met Retül Technology.',
        'locations.location_id_label': 'Locatie-ID',
        'locations.update_cta_label': 'Locatie bijwerken',
        'metrics.closed_session_tooltip':
            'Gesloten sessies duiden sessies aan die een transactie hebben geregistreerd. Wanneer een klant wel of geen aankoop heeft gedaan, is het mogelijk om via {closingSessions} het aantal verkopen bij te houden..',
        'metrics.closed_sessions': 'Gesloten sessies',
        'metrics.closing_session_link': 'sessie afsluiten',
        'metrics.total_sessions': 'Totaal aantal sessies',
        'metrics.unique_client_emails': 'Unieke e-mailadressen van klanten',
        'metrics.unique_emails_tooltip':
            'Dit getal geeft het aantal sessies weer, die zijn uitgevoerd met unieke cliënten. Unieke cliënten worden bepaald door hun e-mailadres.',
        'mtb-trail': 'Mountain (trail)',
        'mtb-xc': 'Mountain (cross-country)',
        'nav.analytics': 'Analytics',
        'nav.greeting_label': 'Hallo, {data}',
        'nav.manage_employees_link_label': 'Medewerkers beheren',
        'nav.manage_locations_link_label': 'Locaties beheren',
        'nav.sessions': 'Sessies',
        'nav.sign_out_link_label': 'Afmelden',
        'pagination.next': 'Volgende',
        'pagination.page': 'Pagina',
        'pagination.previous': 'Vorige',
        road: 'Road',
        'session_details.add_transaction_cta_label': 'Transactie toevoegen',
        'session_details.all_sessions_link': 'Alle sessies',
        'session_details.bike_product': 'Fiets',
        'session_details.client': 'Klant',
        'session_details.client_review_label': 'Klantbeoordeling',
        'session_details.complete_session_cta_label': 'Markeren als gesloten',
        'session_details.complete_session_message':
            'Sluit deze sessie door transacties van de client toe te voegen, of',
        'session_details.complete_status': 'Gesloten',
        'session_details.created_on': 'Gemaakt op: {data}',
        'session_details.delete_link': 'Verwijderen',
        'session_details.employee': 'Werknemer: {data}',
        'session_details.family_label': 'Familie',
        'session_details.incomplete_status': 'Open',
        'session_details.model_label': 'Model',
        'session_details.no_transactions_message':
            'Er zijn geen transacties toegevoegd.',
        'session_details.product_label': 'Product',
        'session_details.saddle_product': 'Zadel',
        'session_details.shoe_product': 'Schoen',
        'session_details.shop': 'Winkel: {data}',
        'session_details.size_label': 'Maat',
        'session_details.status_label': 'Status',
        'session_details.transactions_heading': 'Sessie-transacties',
        'session_products.arch_height_label': 'Wreef hoogte: {data}',
        'session_products.bike_size_label': 'Frame maat: {data}',
        'session_products.saddle_height_label': 'Zadelhoogte: {data} mm',
        'session_products.saddle_width_label': 'Zadelbreedte: {data} mm',
        'session_products.session_product_label': 'Sessiegegevens',
        'session_products.shoe_size_label': 'Schoenmaat: {data}',
        'sessions.add_transaction_cta_label': 'Transactie toevoegen',
        'sessions.client_anonymous': 'Anoniem',
        'sessions.client_label': 'Klant',
        'sessions.closed_status': 'GESLOTEN',
        'sessions.csv_downloading_message':
            'We verzamelen alle sessies voor de CSV, dit kan enkele minuten duren.',
        'sessions.date_label': 'Datum',
        'sessions.employee_label': 'Werknemer',
        'sessions.export_csv_link': 'Lijst Exporteren (.CSV)',
        'sessions.filter_empty':
            'Sorry, er zijn geen sessies die overeenkomen met uw filtercriteria. Stel de filterselectie opnieuw in.',
        'sessions.filter_empty_cta_label': 'Reset instellingen',
        'sessions.heading': 'Sessies',
        'sessions.intro':
            'Een tijdlijn van alle laatste sessies met Retül Technologie.',
        'sessions.location_label': 'Locatie',
        'sessions.no_rating': 'Geen beoordeling',
        'sessions.open_status': 'OPEN',
        'sessions.rating_label': 'Beoordeling',
        'sessions.session_id_label': 'Sessie-ID',
        'sessions.session_loading_message':
            'We verzamelen al je sessies, dit kan even duren.',
        'sessions.status_label': 'Status',
        'sign_in.heading': 'Aanmelden',
        'sign_in.intro':
            "Voer uw e-mailadres en wachtwoord in om aan de slag te gaan of kies 'Account registreren' om een nieuwe te maken.",
        'status.all': 'STATUS: ALLES',
        'status.complete': 'STATUS: GESLOTEN',
        'status.incomplete': 'STATUS: OPEN',
        triathlon: 'Triatlon',
        'POWER MIMIC DESCRIPTION':
            'Dit zadel maakt gevoelloosheid en pijn verdwijnen met een ontwerp dat de reactie van het lichaam op verschillende soorten druk nabootst om een evenwicht te creëren in zacht weefsel.',
        'date_picker.all_time': 'Alle sessies',
        'date_picker.date_range': 'Datumbereik',
        'date_picker.last_month': 'Vorige maand',
        'date_picker.last_week': 'Vorige week',
        'date_picker.last_year': 'Vorig jaar',
        'date_picker.this_month': 'Deze maand',
        'date_picker.this_week': 'Deze week',
        'date_picker.this_year': 'Dit jaar',
        'session_details.client_notes': 'Opmerkingen van klanten',
        'session_details.footbed_product': 'Voetbed',
        'session_products.it_width_label': 'IT-breedte: {data} mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match meet in de winkel exact de lengte van uw been voor een optimale fietsmeting en het kiezen van producten die het best bij u passen.',
        'body_data.about_saddle':
            'Retül Match is een allesomvattende ervaring in de winkel, die de Digital Sitbone Device van Retül gebruikt om de breedte van uw zitbeen vast te leggen met digitale drukmaptechnologie voor een accurate zadelpasvorm en om te bepalen welk zadel het best bij u past.',
        'body_data.about_shoe':
            'Retül Match is een allesomvattende ervaring in de winkel, die uw voetgegevens vastlegt voor het leveren van accurate ondersteuning van de voetboog, schoenmaat en schoenen die het best bij u passen.',
        'body_data.arch_height_label': 'hoogte voetboog',
        'body_data.bike_label': 'Fiets',
        'body_data.bike_size_label': 'Fietsmaat',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'omtrek',
        'body_data.find_retul_technology': 'Vind Retül-technologie',
        'body_data.heading': 'Lichaamsdata',
        'body_data.intro':
            'Lichaamsdata en voorkeuren voor het rijden worden met behulp van de Retül Vantage-technologie vastgelegd, voor het leveren van de juiste pasvorminformatie en producten die het best bij u passen.',
        'body_data.length_label': 'lengte',
        'body_data.load_error':
            'Wij kunnen uw lichaamsdata op dit moment niet ophalen, probeer het later opnieuw.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'niet verzameld',
        'body_data.recommended_label': 'Aanbevolen',
        'body_data.saddle_height_label': 'zadelhoogte',
        'body_data.saddle_label': 'Zadel',
        'body_data.saddle_size_label': 'zadelgrootte',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'Scheen',
        'body_data.shoe_label': 'Schoen',
        'body_data.shoe_size_label': 'schoenmaat',
        'body_data.sit_bone_label': 'zitbeen',
        'body_data.starting_label': 'Starten',
        'body_data.thigh_label': 'dij',
        'body_data.view_bike_matches_label': 'Fietsmatches bekijken',
        'body_data.view_saddle_matches_label': 'Zadelmatches bekijken',
        'body_data.view_shoe_matches_label': 'Schoenmatches bekijken',
        'body_data.your_progress_completion_label': '{numComplete}/3 voltooid',
        'body_data.your_progress_label': 'Uw voortgang',
        cancel_label: 'Annuleren',
        'create_account.heading': 'Account aanmaken',
        'create_account.intro':
            'Voer een e-mailadres en wachtwoord in om een Retül Passport-account aan te maken.',
        'edit_form.edit_form_cancel_label': 'Annuleren',
        'edit_form.edit_form_save_label': 'Opslaan',
        email_format_error: 'Ongeldige e-mailindeling.',
        email_label: 'E-mailadres',
        'forgot_password.heading': 'Wachtwoord vergeten',
        'forgot_password.intro':
            'Uw wachtwoord vergeten? Voer uw e-mailadres in en wij sturen u een link om het te resetten.',
        'forgot_password.reset_email_sent':
            'Een e-mail met een wachtwoordreset is gestuurd naar {email}',
        'landing.create_account_label': 'Een account aanmaken',
        'landing.sign_in_label': 'Inloggen',
        'nav.account_profiles_link_label': 'Account- en rijderprofielen',
        'nav.home_page_link_label': 'Startpagina',
        'nav.logout_link_label': 'Uitloggen',
        'not_found.go_home_label': 'Naar startpagina',
        'not_found.heading': '404',
        'not_found.intro':
            'De pagina die u wilt bekijken lijkt te zijn verplaatst of verwijderd, of deze pagina bestaat niet.',
        password_invalid_error: 'Ongeldig wachtwoord.',
        password_label: 'Wachtwoord',
        'product_matches.bike_size_label': 'Fietsmaat',
        'product_matches.category_filter_commute_comfort_label':
            'Pendelen-comfortabel',
        'product_matches.category_filter_commute_sport_label':
            'Pendelen-sportief',
        'product_matches.category_filter_cyclocross_label': 'Veldrijden',
        'product_matches.category_filter_label': 'Categorie',
        'product_matches.category_filter_label_road': 'Race',
        'product_matches.category_filter_mountain_trail_label': 'MTB (trail)',
        'product_matches.category_filter_mountain_xc_label':
            'MTB (cross-country)',
        'product_matches.category_filter_triathlon_label': 'Triatlon',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Wij hebben geen lichaamsdata om Match-resultaten aan u te tonen voor dit producttype. Gebruik de filter hierboven om andere producttypen te zien, of gebruik de pagina met locaties om een winkel te vinden om deze Match-module te voltooien.',
        'product_matches.find_retul_technology_label': 'Vind Retül-technologie',
        'product_matches.gender_filter_all_label': 'Alle producten weergeven',
        'product_matches.gender_filter_female_label':
            'Damesproducten weergeven',
        'product_matches.gender_filter_male_label': 'Herenproducten weergeven',
        'product_matches.heading': 'Product-Matches',
        'product_matches.intro':
            'Retül-technologie gebruikt uw lichaamsdata en voorkeuren voor het rijden om gespecialiseerde producten te vinden die het best bij u passen.',
        'product_matches.last_session_match_label':
            'Meest recente Match-sessie',
        'product_matches.load_error':
            'Wij kunnen uw product-Matches op dit moment niet ophalen, probeer het later opnieuw.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'Op basis van de biometrische gegevens die zijn vastgelegd tijdens uw Retül Match-sessie, wordt de hiervoor vermelde fietsmaat door ons aangeraden. Hoewel dit een goed uitgangspunt is, raden we aan om te overleggen met uw plaatselijke detailhandelaar voor aanbevelingen gebaseerd op uw ervaringsniveau en lichaamsbouw.',
        'product_matches.overlay_bike_size_label': 'fietsmaat',
        'product_matches.overlay_close_label': 'Sluiten',
        'product_matches.overlay_indicator_label': 'U',
        'product_matches.overlay_saddle_height_details':
            'Op basis van de biometrische gegevens die zijn vastgelegd tijdens uw Retül Match-sessie, wordt de hiervoor vermelde zadelhoogte aangeraden. Hoewel dit een goed uitgangspunt is, raden we aan om te overleggen met uw plaatselijke detailhandelaar voor aanbevelingen gebaseerd op uw ervaringsniveau en lichaamsbouw.',
        'product_matches.overlay_saddle_height_label': 'zadelhoogte',
        'product_matches.overlay_saddle_size_details':
            'Op basis van de biometrische gegevens die zijn vastgelegd tijdens uw Retül Match-sessie, wordt de hiervoor vermelde zadelgrootte aangeraden. Hoewel dit een goed uitgangspunt is, raden we aan om te overleggen met uw plaatselijke detailhandelaar voor aanbevelingen gebaseerd op uw lichaamseigenschappen.',
        'product_matches.overlay_saddle_size_label': 'zadelgrootte',
        'product_matches.overlay_shoe_size_details':
            'Op basis van de biometrische gegevens die zijn vastgelegd tijdens uw Retül Match-sessie, wordt de hiervoor vermelde schoenmaat aangeraden. Hoewel dit een goed uitgangspunt is, raden we aan om te overleggen met uw plaatselijke detailhandelaar voor aanbevelingen gebaseerd op uw ervaringsniveau.',
        'product_matches.overlay_shoe_size_label': 'schoenmaat',
        'product_matches.padding_level_label': 'Vullingniveau',
        'product_matches.product_type_filter_bikes_label': 'Fietsen',
        'product_matches.product_type_filter_label': 'Productsoort',
        'product_matches.product_type_filter_saddles_label': 'Zadels',
        'product_matches.product_type_filter_shoes_label': 'Schoenen',
        'product_matches.recommended_label': 'Aanbevolen',
        'product_matches.saddle_detail_size_label': 'Zadelgrootte:',
        'product_matches.saddle_height_label': 'zadelhoogte',
        'product_matches.saddle_models_label': 'Modellen',
        'product_matches.saddle_size_label': 'zadelgrootte',
        'product_matches.shoe_size_label': 'schoenmaat',
        'product_matches.starting_label': 'Starten',
        'product_matches.view_on_specialized_label':
            'Bekijken bij Gespecialiseerd',
        'profile.account_heading': 'Account',
        'profile.bike_type_city_label': 'Stad',
        'profile.bike_type_label': 'Fietstype',
        'profile.bike_type_mountain_label': 'Berg',
        'profile.bike_type_road_label': 'Race',
        'profile.email_error': 'Voer een geldig e-mailadres in',
        'profile.email_label': 'E-mailadres',
        'profile.gender_female_label': 'vrouw',
        'profile.gender_label': 'Geslacht',
        'profile.gender_male_label': 'man',
        'profile.heading': 'Account- en rijderprofielen',
        'profile.intro':
            'Binnenkort... bewerk uw accountinformatie en rijderprofielen.',
        'profile.name_error': 'Voer een naam in',
        'profile.name_label': 'Naam',
        'profile.password_error': 'Voer een wachtwoord in',
        'profile.password_label': 'Wachtwoord',
        'profile.position_high_label': 'Hoog',
        'profile.position_label': 'Positie',
        'profile.position_low_label': 'Laag',
        'profile.position_medium_label': 'Medium',
        'profile.ride_duration_extended_label': 'Meer dan 90 minuten',
        'profile.ride_duration_label': 'Duur',
        'profile.ride_duration_long_label': '60-90 minuten',
        'profile.ride_duration_medium_label': '30-60 minuten',
        'profile.ride_duration_short_label': '0-30 minuten',
        'profile.ride_frequency_label': 'Frequentie',
        'profile.ride_frequency_once_month_label': 'Eenmaal per maand',
        'profile.ride_frequency_once_week_label': 'Eenmaal per week',
        'profile.ride_frequency_six_week_label': '6 keer per week',
        'profile.ride_frequency_three_week_label': '3 keer per week',
        'routes.account_profile_route_label': 'Account- en rijderprofielen',
        'routes.body_data_route_label': 'Lichaamsdata',
        'routes.create_account_route_label': 'Account aanmaken',
        'routes.forgot_password_route_label': 'Wachtwoord vergeten',
        'routes.locations_route_label': 'Locaties',
        'routes.not_found_route_label': 'Niet gevonden',
        'routes.privacy_policy_route_label': 'Privacybeleid',
        'routes.product_matches_route_label': 'Product-Matches',
        'routes.reset_password_route_label': 'Wachtwoord resetten',
        'routes.set_password_route_label': 'Wachtwoord resetten',
        'routes.sessions_route_label': 'Sessies',
        'routes.sign_in_route_label': 'Inloggen',
        'routes.terms_of_service_route_label': 'Servicevoorwaarden',
        'sessions.download_pdf_cta_label': 'PDF downloaden',
        'sessions.empty': 'U hebt nog geen sessie gehad.',
        'sessions.empty_cta_label': 'Vind locaties',
        'sessions.loading_error':
            'Wij kunnen uw Sessies op dit moment niet ophalen, probeer het later opnieuw.',
        'sessions.loading_error_cta_label': 'Opnieuw proberen',
        'sessions.session_type_filter_label': 'Type sessie',
        'session_type.dfd_session_name': 'Retül Digitaal Voetapparaat',
        'session_type.dsd_session_name': 'Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül-voet',
        'session_type.legs_session_name': 'Retül-benen',
        'session_type.match_session_name': 'Retül-Match',
        'session_type.saddle_session_name': 'Retül-zadel',
        'session_type.unknown_session_name': 'Retül-sessie',
        'session_type.vantage_session_name': 'Retül-pasvorm',
        'sign_in.sign_in_label': 'Inloggen',
        'sign_in.contact_label': 'Met Retül contact opnemen',
        'sign_in.forgot_password_label': 'Wachtwoord vergeten?',
        'sign_in.sign_in_error':
            'Wij kunnen uw gebruikersgegevens niet ophalen. Neem voor hulp contact op met Retül Support.',
        submit_label: 'Indienen',
    },
};
