import {TweenMax, Expo} from 'gsap';

const ANIMATION = {
  'DURATION': 0.3,
  'EASE_OUT': Expo.easeOut
};

export function open(element) {
  const elementHeight = element.scrollHeight;

  TweenMax.to(element, ANIMATION.DURATION,
    {
      'height': elementHeight,
      'ease': ANIMATION.EASE_OUT
    }
  );
}

export function close(element) {
  TweenMax.to(element, ANIMATION.DURATION,
    {
      'height': 0,
      'ease': ANIMATION.EASE_OUT
    }
  );
}

export function onWindowResize(element) {
  if (window.innerWidth >= 1024) {
    element.style.height = 'auto';
  }
}
