import React, { Component } from 'react';
import Main from '../../components/Main/Main';
import Intro from '../../components/Intro/Intro';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';
import ResetPasswordForm from './ResetPasswordForm';
import messages from '../../utils/messages';
import { injectIntl } from 'react-intl';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        const { intl } = props;

        this.page = {
            heading: intl.formatMessage(messages.reset_password_heading),
            intro: intl.formatMessage(messages.reset_password_intro),
        };
    }

    renderForm() {
        const { handleSubmit, serverErrors } = this.props;

        return componentWithErrorBoundary(
            <ResetPasswordForm
                handleSubmit={handleSubmit}
                serverErrors={serverErrors}
            />
        );
    }

    render() {
        return (
            <Main className="SignIn">
                <section className="top top_medium">
                    <div className="container">
                        <Intro
                            header={this.page.heading}
                            summary={this.page.intro}
                        />
                    </div>
                </section>
                <section className="bottom bottom_extraPad bottom_dark">
                    <div className="container">{this.renderForm()}</div>
                </section>
            </Main>
        );
    }
}

export default injectIntl(ResetPassword);
