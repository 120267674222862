import React, {Component} from 'react';
import './Sessions.css';
import componentWithErrorBoundary from '../../componentsHighOrder/componentWithErrorBoundary';
import SessionsTable from '../../components/SessionsTable/SessionsTable';
import Pagination from '../../components/Pagination/Pagination';
import SessionsSearchFiltersGroup from '../../components/SessionsSearchFiltersGroup/SessionsSearchFiltersGroup';
import Button from '../../components/Button/Button';
import LocationsEmpty from '../../components/LocationsEmpty/LocationsEmpty';
import {injectIntl} from 'react-intl';
import messages from './messages';

class Sessions extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'filterEmpty': intl.formatMessage(messages.filter_empty),
      'filterEmptyCtaLabel': intl.formatMessage(messages.filter_empty_cta_label),
      'exportCsvLink': intl.formatMessage(messages.export_csv_link)
    };

    this.handleExportLink = this.handleExportLink.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  handleExportLink(e) {
    e.preventDefault();
    const {fetchCSVExport} = this.props;
    fetchCSVExport()
  }

  resetFilters() {
    window.history.replaceState({}, '', window.location.pathname);
    window.location.reload();
  }

  renderExportLink() {
    return (
      <div className="export-list-link-container">
        <a
          className="export-list-link"
          href={'/'}
          onClick={this.handleExportLink}
        >
          {this.page.exportCsvLink}
        </a>
      </div>
    )
  }

  renderPagination() {
    const {pagination, updateSearchParams, isDataFetched} = this.props;

    return (
      pagination &&
      <Pagination
        data={pagination}
        updatePage={updateSearchParams}
        isDataFetched={isDataFetched}
      />
    );
  }

  renderTable() {
    const {data, searchParams, updateSearchParams} = this.props;

    return (
      componentWithErrorBoundary(
        <SessionsTable
          data={data}
          updatePage={updateSearchParams}
          searchParams={searchParams}
        />
      )
    )
  }

  renderSessionsList() {
    return (
      <div className="column column_12 column_10Lg">
        <div className="sessions-table-container">
          <div className="sessions-table-header">
            {this.renderExportLink()}
          </div>

          {this.renderTable()}

          <div className="sessions-table-footer">
            {this.renderPagination()}
            {this.renderExportLink()}
          </div>
        </div>
      </div>
    )
  }

  renderSessionsSearchFiltersGroup() {
    const {searchParams, sessionsFilters, updateSearchParams} = this.props;
    return (
      <div className="column column_12 column_2Lg column_dividerRightLg">
        <SessionsSearchFiltersGroup
          searchParams={searchParams}
          sessionsFilters={sessionsFilters}
          updateSearchParams={updateSearchParams}
        />
      </div>
    )
  }

  render() {
    const {data, sessionsFilters} = this.props;
    const noSessions = data.length <= 0;
    const noShops = sessionsFilters.shop <= 0;

    if (noShops) {
      return (
        <LocationsEmpty/>
      )
    }
    else if (noSessions) {
      return (
        <section className="bottom">
          <div className="container">
            <div className="empty-message">
              <p>{this.page.filterEmpty}</p>
              <div className="box box_top3">
                <Button
                  type="button"
                  onClick={this.resetFilters}
                  light
                >
                  {this.page.filterEmptyCtaLabel}
                </Button>
              </div>
            </div>
          </div>
        </section>
      );
    }
    else {
      return (
        <section className="bottom">
          <div className="container">
            <div className="grid">
              {this.renderSessionsSearchFiltersGroup()}
              {this.renderSessionsList()}
            </div>
          </div>
        </section>
      );
    }
  }
}

export default injectIntl(Sessions);
