import * as actionTypes from '../types/actions';
import { JSON_PARSER } from '../types/constants';
import { generateRequestConfigs } from '../utils/fetchHelper';
import { apiCreate } from '../api/apiClient';
import { fetchNewAccountUrl, createAccountUrl } from '../utils/urlHelper';

export function postNewAccount(data) {
    return (dispatch) => {
        dispatch(requestNewAccount());

        return apiCreate(
            generateRequestConfigs({
                dispatch,
                body: JSON.stringify(data),
                parser: JSON_PARSER,
                url: fetchNewAccountUrl(),
                receiveCallback: receiveNewAccount,
                failCallback: receiveNewAccountFailure,
                auth: 'Basic',
            })
        );
    };
}

// Cognito-style
export function createAccount(data) {
    return (dispatch) => {
        dispatch(requestNewAccount());

        return apiCreate(
            generateRequestConfigs({
                dispatch,
                body: JSON.stringify(data),
                parser: JSON_PARSER,
                url: createAccountUrl(),
                receiveCallback: receiveNewAccount,
                failCallback: receiveNewAccountFailure,
                auth: 'Basic',
            })
        );
    };
}

// pre-Gigya (old)
export function requestNewAccount() {
    return {
        type: actionTypes.REQUEST_NEW_ACCOUNT,
    };
}

export function receiveNewAccount(data) {
    // On success passport sends {reason: "USER_CREATED"}

    return {
        type: actionTypes.RECEIVE_NEW_ACCOUNT,
    };
}

export function receiveNewAccountFailure(error) {
    return {
        type: actionTypes.RECEIVE_NEW_ACCOUNT_FAILURE,
        error,
    };
}
