import { defineMessages } from 'react-intl';

export default defineMessages({
  'filter_empty': {
    'id': 'sessions.filter_empty',
    'defaultMessage': 'Sorry, there are no Sessions that match your filter criteria. Please reset the filter selection.',
    'description': 'Sessions Page loading error message'
  },
  'filter_empty_cta_label': {
    'id': 'sessions.filter_empty_cta_label',
    'defaultMessage': 'Reset Filters',
    'description': 'Sessions Page loading error try again cta label'
  },
  'export_csv_link': {
    'id': 'sessions.export_csv_link',
    'defaultMessage': 'Export List (.CSV)',
    'description': 'Link to export list of items into a .csv file'
  }
});
