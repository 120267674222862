import React, {Component} from 'react';
import {connect} from 'react-redux';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import ManageLocations from '../components/ManageLocations/ManageLocations';
import * as actionCreators from '../actions/locations';
import {isFetchNeeded, isLocationsListFetched} from '../selectors/locations';
import Intro from '../components/Intro/Intro';
import Main from '../components/Main/Main';
import {injectIntl} from 'react-intl';
import messages from './messages';

class ManageLocationsContainer extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'heading': intl.formatMessage(messages.locations_heading),
      'intro': intl.formatMessage(messages.locations_intro)
    };

    this.createLocation = this.createLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
  }

  componentDidMount() {
    this.fetchLocations()
  }

  componentWillUpdate(nextProps, nextState) {
    isFetchNeeded(this.props, nextProps) && this.fetchLocations()
  }

  fetchLocations() {
    const {fetchLocations} = this.props;
    fetchLocations()
  }

  createLocation(data) {
    const {createLocation} = this.props;
    createLocation(data)
  }

  deleteLocation(data) {
    const {deleteLocation} = this.props;
    deleteLocation(data)
  }

  updateLocation(data) {
    const {updateLocation} = this.props;
    updateLocation(data)
  }

  renderTopSection() {
    return (
      <section className="top">
        <div className="container">
          <Intro
            header={this.page.heading}
            summary={this.page.intro}
          />
        </div>
      </section>
    )
  }

  renderManageLocations(isLoading) {
    const {location, locationsList} = this.props;

    return isLoading ? null : (
      componentWithErrorBoundary(
        <ManageLocations
          createLocation={this.createLocation}
          updateLocation={this.updateLocation}
          fetchLocations={this.fetchLocations}
          deleteLocation={this.deleteLocation}
          location={location}
          locationsList={locationsList}
        />
      )
    )
  }

  render() {
    const isLoading = !(isLocationsListFetched(this.props));

    return (
      <Main isLoading={isLoading}>
        {this.renderTopSection()}
        {this.renderManageLocations(isLoading)}
      </Main>
    )
  }
}

function mapStateToProps(state) {
  return state.locations;
}

export default connect(mapStateToProps, actionCreators)(injectIntl(ManageLocationsContainer));
