import React, { Component } from 'react';
import { connect } from 'react-redux';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import * as actionCreators from '../actions/forgotPassword';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';

export class ForgotPasswordContainer extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(data) {
        const { resetPassword } = this.props;

        resetPassword(data);
    }

    render() {
        const { forgotPasswordData } = this.props;

        return componentWithErrorBoundary(
            <ForgotPassword
                handleSubmit={this.handleSubmit}
                data={forgotPasswordData.data}
                fetchingStatus={forgotPasswordData.fetchingStatus}
            />
        );
    }
}

function mapStateToProps(state) {
    return state.forgotPassword;
}

export default connect(
    mapStateToProps,
    actionCreators
)(ForgotPasswordContainer);
