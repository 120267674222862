import React from 'react';
import { IntlProvider } from 'react-intl';
import i18n, { IntlCapture } from './i18n';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import RootReducer from './reducers';
import { tokenRefresh } from './middleWares/tokenRefresh';
import App from './components/App/App';

export default class LocaleWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = { locale: i18n.locale };

        this.setUpHandlers();
    }

    /**
     * Binds any handlers to the correct context
     *
     * @method  setUpHandlers
     */

    setUpHandlers() {
        this.onLocaleChange = this.handleLocaleChange.bind(this);
    }

    /**
     * Handles updating the locale based on user selection
     * @method handleLocaleChange
     * @param locale - The new locale value to store
     */
    handleLocaleChange(locale) {
        i18n.setActiveLocale(locale);
        this.setState({ locale: locale });
    }

    render() {
        const composeEnhancers =
            typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
                  })
                : compose;

        const enhancer = composeEnhancers(
            applyMiddleware(tokenRefresh, thunkMiddleware)
            // other store enhancers if any
        );

        const store = createStore(RootReducer, enhancer);
        // let store = createStore(
        //     RootReducer,
        //     applyMiddleware(tokenRefresh, thunkMiddleware)
        // );

        return (
            <IntlProvider
                locale={i18n.locale}
                key={i18n.locale}
                messages={i18n.getLocaleMessages()}
            >
                <IntlCapture>
                    <Provider store={store}>
                        <App onLocaleChange={this.onLocaleChange} />
                    </Provider>
                </IntlCapture>
            </IntlProvider>
        );
    }
}
