import * as actionTypes from '../types/actions/verify';
import { apiCreate } from '../api/apiClient';
import { JSON_PARSER } from '../types/constants';
import { generateRequestConfigs } from '../utils/fetchHelper';
import { createVerifyUrl } from '../utils/urlHelper';

export function verify(data) {
    return (dispatch) => {
        dispatch(postVerify());

        return apiCreate(
            generateRequestConfigs({
                body: JSON.stringify(data),
                dispatch,
                parser: JSON_PARSER,
                url: createVerifyUrl(),
                failCallback: receiveVerifyError,
                receiveCallback: receiveVerify,
                auth: 'Basic',
            })
        );
    };
}

export function postVerify() {
    return {
        type: actionTypes.POST_VERIFY,
    };
}

export function receiveVerify(data) {
    return {
        type: actionTypes.RECEIVE_VERIFY,
        data,
    };
}

export function receiveVerifyError(errors) {
    return {
        type: actionTypes.RECEIVE_VERIFY_ERROR,
        errors,
    };
}
