import React, {Component} from 'react';
import './NavigationDropDownIcon.css';

export default class NavigationDropDownIcon extends Component {
  render() {
    const {openClass} = this.props;
    return (
      <span
        className={`NavigationDropDownIcon ${openClass()}`}
      >
        <i>
          <svg viewBox="0 0 14 8" aria-labelledby="icon-chevron">
            <title id="icon-chevron">Chevron Icon</title>
            <path d="M1.456 8l5.09-5.091L11.636 8h.002l1.453-1.454L7.273.728 6.546 0 0 6.546 1.454 8z"
                  fillRule="evenodd"
            />
          </svg>
        </i>
      </span>
    )
  }
}