import React from 'react';
import classNames from 'classnames';
import './Badge.css';

export default function Badge(props) {
  const badgeIconClasses = classNames(
    'badge-icon',
    {
      [`mix-badge-icon_color${props.color}`]: props.color
    }
  );
  const label = !props.label ? '' : (
    <div className="badge-label">
      <span className="txt txt_sm mix-txt_uppercase">{ props.label }</span>
    </div>
  );

  return (
    <div className="badge">
      <div className={badgeIconClasses}>
        { props.icon }
      </div>
      {label}
    </div>
  )
}
