import moment from 'moment';

export function formatDate(date, format) {
    return moment(date).format(format);
}

// function timeRange(numOfDays) {
//   const currentDate = Date.now();
//   const endDate = moment(currentDate);
//   const beginningDate = endDate.clone().subtract(numOfDays, 'days');
//   const [date_start, date_end] = [beginningDate, endDate].map((date) => date.format('YYYY-MM-DD'));

//   return [
//     {date_start},
//     {date_end}
//   ]
// }

export function parseDateRange(dateRange) {
    console.log('dateRange', dateRange);
    const [date_start, date_end] = dateRange.split(':').map((date) => date);
    console.log([{ date_start }, { date_end }]);
    return [{ date_start }, { date_end }];
    // switch (dateRange) {
    //   case(LAST_WEEK):
    //     console.log(timeRange(6));
    //     return timeRange(6);
    //   case(LAST_MONTH):
    //     console.log(timeRange(29));
    //     return timeRange(29);
    //   case(LAST_YEAR):
    //     console.log(timeRange(364));
    //     return timeRange(364);
    //   case(ALL_TIME):
    //     console.log(timeRange(36500));
    //     return timeRange(36500);
    //   default:
    //     console.log(timeRange(30));
    //     return timeRange(30);
    // }
}
