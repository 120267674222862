export function getSessionsSearchParamsData(data) {
  const {searchParams} = data;
  if (searchParams && searchParams.data) {
    return searchParams.data
  }
}

export function getSessionsFiltersData(data) {
  const {sessionsFilters} = data;
  if (sessionsFilters && sessionsFilters.data) {
    return sessionsFilters.data;
  }
}

export function getSessionsFetchingStatus(data) {
  const {sessionsData} = data;
  const {fetchingStatus} = sessionsData;
  return fetchingStatus
}

export function getSessionFetchingStatus(data) {
  const {sessionDetailsData} = data;
  const {fetchingStatus} = sessionDetailsData;
  return fetchingStatus
}

export function getSessionProductFetchingStatus(data) {
  const {sessionProductData} = data;
  const {fetchingStatus} = sessionProductData;
  return fetchingStatus
}

export function getBikeTypeFetchingStatus(data) {
  const {bikeTypeData} = data;
  const {fetchingStatus} = bikeTypeData;
  return fetchingStatus
}

export function getSessionFetchingStatusBike(data) {
  const {bikeTransactionData} = data;
  const {fetchingStatus} = bikeTransactionData;
  return fetchingStatus
}

export function getSessionFetchingStatusSaddle(data) {
  const {saddleTransactionData} = data;
  const {fetchingStatus} = saddleTransactionData;
  return fetchingStatus
}

export function getSessionFetchingStatusShoe(data) {
  const {shoeTransactionData} = data;
  const {fetchingStatus} = shoeTransactionData;
  return fetchingStatus
}

export function getSessionFetchingStatusFootbed(data) {
  const {footbedTransactionData} = data;
  const {fetchingStatus} = footbedTransactionData;
  return fetchingStatus
}

export function getSessionsCSVStatus(data) {
  const {sessionsCSVData} = data;
  const {fetchingStatus} = sessionsCSVData;
  return fetchingStatus
}

export function getSessionsFiltersGroup(sessionsFilters, groupName) {
  if (sessionsFilters && sessionsFilters[groupName]) {
    return sessionsFilters[groupName]
  }
}

export function getSessionsFiltersStatus(data) {
  const {sessionsFilters} = data;
  const {fetchingStatus} = sessionsFilters;
  return fetchingStatus
}

export function getSessionsCSVData(data) {
  const {sessionsCSVData} = data;
  if (sessionsCSVData && sessionsCSVData.data) {
    return sessionsCSVData.data;
  }
}

export function getSessionsData(data) {
  const {sessionsData} = data;
  if (sessionsData && sessionsData.data) {
    return sessionsData.data;
  }
}

export function getSessionData(data) {
  const {sessionDetailsData} = data;
  if (sessionDetailsData && sessionDetailsData.data) {
    return sessionDetailsData.data;
  }
}

export function getSessionsPagination(data) {
  const {sessionsData} = data;
  if (sessionsData && sessionsData.pagination) {
    const {pagination} = sessionsData;
    if (pagination && pagination.count) {
      const {count} = pagination;
      if (count > 0) {
        return pagination
      }
    }
  }
}

export function getSession(data) {
  const {employee_name, shop_name, id, client_id, client_notes, created_at} = data;

  return (
    {
      id,
      client_id,
      client_notes,
      sessionId: getSessionId(data),
      date: created_at,
      client: getClientName(data),
      rating: getRating(data),
      employee: employee_name,
      location: shop_name,
      status: getStatus(data)
    }
  )
}

export function getSessionProduct(data) {
  const {latest_shoe_session, latest_bike_session, latest_saddle_session} = data;

  return (
    {
      latest_shoe_session,
      latest_bike_session,
      latest_saddle_session
    }
  )
}

function getRating(data) {
  const {rating} = data;
  if (rating) {
    if (isNaN(rating)) {
      switch (rating) {
        case 'none_submitted':
          return 'None Submitted';
        default:
          return 'N/A'
      }
    } else {
      return rating && rating;
    }
  }
}

function getSessionId(data) {
  const {id, app_type} = data;
  if (app_type) {
    return `${app_type.toUpperCase()} #${id}`
  } else {
    return id
  }
}

function getClientName(data) {
  const {client_name, client_email} = data;
  if (client_email) {
    return client_email
  }
  else if (client_name) {
    return client_name
  }
  else {
    return 'Anonymous';
  }
}

function getStatus(data) {
  const {status} = data;
  if (status && status.toString().toLowerCase() === 'complete') {
    return 'CLOSED'
  } else {
    return 'OPEN'
  }
}

export function didSearchParamsUpdate(oldProps, nextProps) {
  const oldSearchParams = getSessionsSearchParamsData(oldProps);
  const newSearchParams = getSessionsSearchParamsData(nextProps);
  return JSON.stringify(oldSearchParams) !== JSON.stringify(newSearchParams);
}
