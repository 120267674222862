import React from 'react';
import Form from '../../components/Form/Form';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import makeHash from '../../utils/makeHash';
import {injectIntl} from 'react-intl';
import messages from './messages';

class ClientProfileForm extends Form {
  constructor(props) {
    super(props);
    const {notes} = props.data;
    const {intl} = props;

    this.page = {
      'notesLabel': intl.formatMessage(messages.notes_label),
      'save': intl.formatMessage(messages.save_cta)
    };

    this.state = {
      notes: {value: notes || ''},
      count: notes ? notes.length : 0,
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit(event) {
    const data = {
      notes: this.state.notes.value,
    };

    event.preventDefault();

    if (this.hasErrors()) {
      return;
    }

    this.props.onClientUpdateSubmit(data);
  }

  handleChange(event) {
    this.setState({
      'notes': {value: event.target.value},
      'count': event.target.value.length
    });
  }

  renderServerMessage(message, index) {
    return (
      <li key={makeHash(`${message}-${index}`)}>
        <p className="error">{message}</p>
      </li>
    )
  }

  renderServerError() {
    if (!this.props.serverErrors) return null;
    let errors = [];

    for (let field in this.props.serverErrors) {
      const serverErrors = this.props.serverErrors[field];
      const fieldErrors = serverErrors.map(error => {
        return `${field.toUpperCase()}: ${error}`;
      });

      errors = [...errors, ...fieldErrors];
    }

    return (
      <div className="form-row">
        <ul className="form-control">
          {errors.map((message, index) => {
            return this.renderServerMessage(message, index);
          })}
        </ul>
      </div>
    );
  }

  render() {
    const {count} = this.state;

    return (
      <form
        autoComplete="off"
        className="form-container form-container_left"
        onSubmit={this.handleOnSubmit}
        noValidate
      >
        {this.renderServerError()}
        <FormInput
          light
          defaultValue={this.state.notes.value}
          inputType="textarea"
          label={this.page.notesLabel}
          name="notes"
          handleChange={this.handleChange}
          ref={field => {
            this.fields.notes = field;
          }}
        />
        <div className="box box_top2 box_alignRight">
          <span className="text">{count} / 1000</span>
        </div>
        <div className="form-row form-row_lgTop">
          <Button
            type="submit"
          >
            {this.page.save}
          </Button>
        </div>
      </form>
    )
  }
}

export default injectIntl(ClientProfileForm);
