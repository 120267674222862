import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/sessionProducts';
import * as sessionsSelectors from '../selectors/sessions';
import {RECEIVED} from '../types/statuses';
import SessionProducts from "../components/SessionProduct/SessionProduct";


export class SessionProductContainer extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;

    this.state = {
      'bikeType': props.bikeType
    };
  }

  componentWillReceiveProps(nextProps) {
    const {bikeType} = nextProps;

    if (this.bikeTypeChanged(bikeType)) {
      this.setState({
        'bikeType': bikeType
      }, () => {
        this.getNewProduct();
      })
    }
  }

  getNewProduct() {
    const {fetchSessionProduct, bikeType} = this.props;
    fetchSessionProduct(this.props.sessionDetailsData.data.id, bikeType);
  }


  bikeTypeChanged(bikeType) {
    return bikeType !== this.props.bikeType;
  }

  componentWillMount() {
    this.fetchSessionProductData();
    this.fetchBikeTypes();
  }

  fetchSessionProductData() {
    const {fetchSessionProduct} = this.props;
    fetchSessionProduct(this.id, this.props.bikeType);
  }

  fetchBikeTypes() {
    const {fetchBikeTypes} = this.props;

    fetchBikeTypes();
  }

  renderSessionProducts() {
    return !this.dataReceived ? null : (
      <SessionProducts
        {...this.props.sessionProductData.data}
        bikeTypeData={this.props.bikeTypeData.data}
        bikeType={this.props.bikeType}
        updateBikeType={this.props.updateBikeType}
      />
    )
  }

  render() {
    return (
      this.renderSessionProducts()
    )
  }

  get dataReceived() {
    return (
      sessionsSelectors.getSessionFetchingStatus(this.props) === RECEIVED &&
      sessionsSelectors.getSessionProductFetchingStatus(this.props) === RECEIVED &&
      sessionsSelectors.getBikeTypeFetchingStatus(this.props) === RECEIVED
    )
  }

  get id() {
    return this.props.sessionDetailsData.data.id;
  }
}

function mapStateToProps(state) {
  return state.sessionProducts
}

export default connect(mapStateToProps, actionCreators)(SessionProductContainer);
