import React, { Component } from 'react';

export default function ResponsiveChart(ChartComponent, containerClassName = "chart") {
  class ResponsiveChart extends Component {
    constructor(props) {
      super(props);
      this.state = {
        containerHeight: null,
        containerWidth: null
      };
      this.fitParentContainer = this.fitParentContainer.bind(this);
    }

    componentDidMount() {
      this.fitParentContainer();
      window.addEventListener('resize', this.fitParentContainer)
    }

    getItems() {
      return this.data.map(d => d.item);
    }

    getQuantities() {
      return this.data.map(d => d.quantity)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer)
    }

    parentContainerWidth() {
      return this
        .chartContainer
        .getBoundingClientRect()
        .width
    }

    parentContainerHeight(){
      return this
        .chartContainer
        .getBoundingClientRect()
        .height
    }

    getContainerWidth(){
      return this.state.containerWidth;
    }

    getContainerHeight(){
      return this.state.containerHeight
    }

    isNotSameWidth(){
      return this.getContainerWidth() !== this.parentContainerWidth()
    }

    isNotSameHeight(){
      return this.getContainerHeight() !== this.parentContainerHeight()
    }

    fitParentContainer() {
      if (this.isNotSameWidth() || this.isNotSameHeight()) {

        this.setState({
           containerWidth: this.parentContainerWidth(),
           containerHeight:  this.parentContainerHeight()
        });
      }
    }

    renderChart() {
      return (
        <ChartComponent
          { ...this.props }
          parentWidth={ this.getContainerWidth() }
          parentHeight={ this.getContainerHeight() }
          getItems={ this.getItems }
          getQuantities={ this.getQuantities }
        />
      )
    }

    shouldRenderChart() {
      return this.getContainerWidth() !== null;
    }

    render() {
      return (
        <div
          ref={ (el) => {this.chartContainer = el} }
          className={ containerClassName }
        >
          { this.shouldRenderChart() && this.renderChart() }
        </div>
      )
    }
  }

  function componentName(childComponent) {
    const componentName =
      childComponent.displayName
      || childComponent.name
      || 'Chart';
    return `ResponsiveChart${componentName}`;
  }

  ResponsiveChart.displayName = componentName(ChartComponent);
  return ResponsiveChart
}
