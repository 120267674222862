import { defineMessages } from 'react-intl';

export default defineMessages({
  'next': {
    'id': 'pagination.next',
    'defaultMessage': 'Next',
    'description': 'Next link for pagination'
  },
  'page': {
    'id': 'pagination.page',
    'defaultMessage': 'Page',
    'description': 'Page link for pagination'
  },
  'previous': {
    'id': 'pagination.previous',
    'defaultMessage': 'Previous',
    'description': 'Previous link for pagination'
  },
});
