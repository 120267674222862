/* eslint-disable */
export const localeStrings = {
    ko: {
        'add_transaction.back_to_sessions_label': '세션으로 돌아가기',
        'add_transaction.bike_label': '자전거',
        'add_transaction.commute_comfort_type': '출퇴근-편안한',
        'add_transaction.commute_sport_type': '출퇴근-스포츠',
        'add_transaction.complete_transaction_cta_label': '거래 완료',
        'add_transaction.confirm_label': '확인',
        'add_transaction.cyclocross_type': '사이클로크로스',
        'add_transaction.family_label': '패밀리',
        'add_transaction.fitness_type': '피트니스',
        'add_transaction.footbed_label': '풋베드',
        'add_transaction.heading': '{data} 에 거래 추가',
        'add_transaction.model_label': '모델',
        'add_transaction.mountain_type': '산악',
        'add_transaction.mtb_trail_type': '산악(트레일)',
        'add_transaction.mtb_xc_type': '산악(XC)',
        'add_transaction.product_label': '제품',
        'add_transaction.ratings_message':
            '이 거래를 완료하면 {data} 는 제품을 평가하는 이메일을 받게됩니다. 세션 목록 및 세션 세부 정보 페이지에서 평가 결과를 확인할 수 있습니다.',
        'add_transaction.road_type': '로드',
        'add_transaction.saddle_label': '안장',
        'add_transaction.shoe_label': '슈즈',
        'add_transaction.size_label': '사이즈',
        'add_transaction.transaction_complete_message': '거래 완료',
        'add_transaction.triathlon_type': '트라이애슬론',
        'add_transaction.tt_tri_type': 'TT/트라이',
        'add_transaction.type_label': '타입',
        'analytics.loading_message':
            '당신의 데이터를 모으고 있습니다. 몇 분 소요될 수 있습니다.',
        'analytics_filters.analytics_by': '분석 기준',
        'analytics_filters.filter_by': '필터링 기준',
        'analytics_filters.products': '제품들',
        'analytics_filters.sessions': '세션',
        'charts.bike_label_products': '자전거',
        'charts.bike_label_sessions': '자전거',
        'charts.commute_comfort_label': '출퇴근-편안한',
        'charts.commute_sport_label': '출퇴근-스포츠',
        'charts.cyclocross_label': '사이클로크로스',
        'charts.female_label': '여성',
        'charts.friday_label': '금요일',
        'charts.high_arch_label': '높은',
        'charts.low_arch_label': '낮은',
        'charts.male_label': '남성',
        'charts.medium_arch_label': '중간',
        'charts.monday_label': '월요일',
        'charts.mtb_trail_label': 'MTB-트레일',
        'charts.mtb_xc_label': 'MTB-XC',
        'charts.road_label': '로드',
        'charts.saddle_label_products': '안장',
        'charts.saddle_label_sessions': '안장',
        'charts.saturday_label': '토요일',
        'charts.sessions_by_bike_type_label': '자전거 유형별 세션',
        'charts.sessions_by_day_of_week_label': '요일별 세션',
        'charts.sessions_by_employee_label': '직원 별 세션',
        'charts.sessions_by_foot_arch_label': '아치 유형별 세션',
        'charts.sessions_by_gender_label': '성별 세션',
        'charts.sessions_by_product_label': '제품별 세션',
        'charts.sessions_by_saddle_height_label': '안장 높이별 세션',
        'charts.sessions_by_saddle_size_label': '안장 사이즈별 세션',
        'charts.sessions_by_shirt_bike_size_label':
            '자전거 사이즈별 세션(T셔츠)',
        'charts.sessions_by_shoe_size_label': '슈즈 사이즈별 세션',
        'charts.sessions_by_sitbone_width_label': '좌골 너비별 세션',
        'charts.sessions_by_standard_bike_size_label':
            '자전거 사이즈별 세션(스탠다드)',
        'charts.sessions_over_time_label': '시간 경과에 따른 세션',
        'charts.shoe_label_products': '슈즈',
        'charts.shoe_label_sessions': '슈즈',
        'charts.sunday_label': '일요일',
        'charts.thursday_label': '목요일',
        'charts.top_ten_session_label': '상위 10 개 세션 날짜',
        'charts.top_transactions_label': '최고 거래 날짜',
        'charts.triathlon_label': '트라이애슬론',
        'charts.tuesday_label': '화요일',
        'charts.wednesday_label': '수요일',
        'client.all_sessions_link': '모든 세션',
        'client.bike_progress': '자전거',
        'client.client_progress_heading': '클라이언트 진행',
        'client.client_sessions_heading': '최근 고객 세션',
        'client.email': '고객 이메일',
        'client.name': '고객명',
        'client.not_applicable': '없음',
        'client.notes_label': '노트',
        'client.progress_types_complete': '완료',
        'client.saddle_progress': '안장',
        'client.save_cta': '저장',
        'client.shoe_progress': '슈즈',
        'commute-comfort': '출퇴근-편안한',
        'commute-sport': '출퇴근-스포츠',
        'country.ad': '안도라',
        'country.ae': '아랍 에미리트',
        'country.af': '아프가니스탄',
        'country.ag': '앤티가 바부다',
        'country.ai': '앙귈라',
        'country.al': '알바니아',
        'country.am': '아르메니아',
        'country.ao': '앙골라',
        'country.aq': '남극',
        'country.ar': '아르헨티나',
        'country.as': '사모아 (미국령)',
        'country.at': '오스트리아',
        'country.au': '오스트레일리아',
        'country.aw': '아루바',
        'country.ax': '올란드 제도',
        'country.az': '아제르바이잔',
        'country.ba': '보스니아 헤르체고비나',
        'country.bb': '바베이도스',
        'country.bd': '방글라데시',
        'country.be': '벨기에',
        'country.bf': '부르키나파소',
        'country.bg': '불가리아',
        'country.bh': '바레인',
        'country.bi': '부룬디',
        'country.bj': '베냉',
        'country.bl': '성 바르텔레미',
        'country.bm': '버뮤다',
        'country.bn': '브루나이',
        'country.bo': '볼리비아',
        'country.bq': '보네르, 신트 유스타티우스 및 사바',
        'country.br': '브라질',
        'country.bs': '바하마',
        'country.bt': '부탄',
        'country.bv': '부베 섬',
        'country.bw': '보츠와나',
        'country.by': '벨라루스',
        'country.bz': '벨리즈',
        'country.ca': '캐나다',
        'country.cc': '코코스 (킬링) 제도',
        'country.cd': '콩고 (콩고 민주 공화국)',
        'country.cf': '중앙 아프리카 공화국',
        'country.cg': '콩고',
        'country.ch': '스위스',
        'country.ci': '코트 디부아르',
        'country.ck': '쿡 제도',
        'country.cl': '칠레',
        'country.cm': '카메룬',
        'country.cn': '중국',
        'country.co': '콜롬비아',
        'country.cr': '코스타리카',
        'country.cu': '쿠바',
        'country.cv': '카보 베르데',
        'country.cw': '쿠라사우 섬',
        'country.cx': '크리스마스 섬',
        'country.cy': '키프로스',
        'country.cz': '체코',
        'country.de': '독일',
        'country.dj': '지부티',
        'country.dk': '덴마크',
        'country.dm': '도미니카',
        'country.do': '도미니카 공화국',
        'country.dz': '알제리',
        'country.ec': '에콰도르',
        'country.ee': '에스토니아',
        'country.eg': '이집트',
        'country.eh': '서부 사하라',
        'country.er': '에리트레아',
        'country.es': '스페인',
        'country.et': '에티오피아',
        'country.fi': '핀란드',
        'country.fj': '피지',
        'country.fk': '포클랜드 제도 [말비나스]',
        'country.fm': '미크로네시아 (연방)',
        'country.fo': '페로 제도',
        'country.fr': '프랑스',
        'country.ga': '가봉',
        'country.gb': '영국',
        'country.gd': '그레나다',
        'country.ge': '그루지야',
        'country.gf': '기아나 (프랑스령)',
        'country.gg': '건지 섬',
        'country.gh': '가나',
        'country.gi': '지브롤터',
        'country.gl': '그린란드',
        'country.gm': '감비아',
        'country.gn': '기니',
        'country.gp': '과들루프',
        'country.gq': '적도 기니',
        'country.gr': '그리스',
        'country.gs': '사우스 조지아 및 사우스 샌드위치 제도',
        'country.gt': '과테말라',
        'country.gu': '괌',
        'country.gw': '기니 비사우',
        'country.gy': '가이아나',
        'country.hk': '홍콩',
        'country.hm': '허드 섬 및 맥도날드 제도',
        'country.hn': '온두라스',
        'country.hr': '크로아티아',
        'country.ht': '아이티',
        'country.hu': '헝가리',
        'country.id': '인도네시아',
        'country.ie': '아일랜드',
        'country.il': '이스라엘',
        'country.im': '아일 오브 맨',
        'country.in': '인도',
        'country.io': '인도양 지역 (영국령)',
        'country.iq': '이라크',
        'country.ir': '이란',
        'country.is': '아이슬란드',
        'country.it': '이탈리아',
        'country.je': '저지',
        'country.jm': '자메이카',
        'country.jo': '요르단',
        'country.jp': '일본',
        'country.ke': '케냐',
        'country.kg': '키르기스스탄',
        'country.kh': '캄보디아',
        'country.ki': '키리바시',
        'country.km': '코모로',
        'country.kn': '세인트 키츠 네비스',
        'country.kp': '북한',
        'country.kr': '대한민국',
        'country.kw': '쿠웨이트',
        'country.ky': '케이맨 제도',
        'country.kz': '카자흐스탄',
        'country.la': '라오스',
        'country.lb': '레바논',
        'country.lc': '세인트 루시아',
        'country.li': '리히텐슈타인',
        'country.lk': '스리랑카',
        'country.lr': '라이베리아',
        'country.ls': '레소토',
        'country.lt': '리투아니아',
        'country.lu': '룩셈부르크',
        'country.lv': '라트비아',
        'country.ly': '리비아',
        'country.ma': '모로코',
        'country.mc': '모나코',
        'country.md': '몰도바',
        'country.me': '몬테네그로',
        'country.mf': '세인트 마틴 (프랑스 부분)',
        'country.mg': '마다가스카르',
        'country.mh': '마샬 군도',
        'country.mk': '마케도니아',
        'country.ml': '말리',
        'country.mm': '미얀마',
        'country.mn': '몽골',
        'country.mo': '마카오',
        'country.mp': '북 마샬 군도',
        'country.mq': '마르티니크',
        'country.mr': '모리타니',
        'country.ms': '몬세라트',
        'country.mt': '몰타',
        'country.mu': '모리셔스',
        'country.mv': '몰디브',
        'country.mw': '말라위',
        'country.mx': '멕시코',
        'country.my': '말레이시아',
        'country.mz': '모잠비크',
        'country.na': '나미비아',
        'country.nc': '뉴 칼레도니아',
        'country.ne': '니제르',
        'country.nf': '노퍽 섬',
        'country.ng': '나이지리아',
        'country.ni': '니카라과',
        'country.nl': '네덜란드',
        'country.no': '노르웨이',
        'country.np': '네팔',
        'country.nr': '나우루',
        'country.nu': '니우에',
        'country.nz': '뉴질랜드',
        'country.om': '오만',
        'country.pa': '파나마',
        'country.pe': '페루',
        'country.pf': '기아나 (프랑스령)',
        'country.pg': '파푸아뉴기니',
        'country.ph': '필리핀',
        'country.pk': '파키스탄',
        'country.pl': '폴란드',
        'country.pm': '생피에르 미클롱',
        'country.pn': '핏케언 제도',
        'country.pr': '푸에르토리코',
        'country.ps': '미크로네시아 (연방)',
        'country.pt': '포르투갈',
        'country.pw': '팔라우',
        'country.py': '파라과이',
        'country.qa': '카타르',
        'country.re': '레위니옹',
        'country.ro': '루마니아',
        'country.rs': '세르비아',
        'country.ru': '러시아',
        'country.rw': '르완다',
        'country.sa': '사우디아라비아',
        'country.sb': '솔로몬 제도',
        'country.sc': '세이셸',
        'country.sd': '수단',
        'country.se': '스웨덴',
        'country.sg': '싱가포르',
        'country.sh': '세인트헬레나 어센션 트리스탄다쿠냐',
        'country.si': '슬로베니아',
        'country.sj': '스발바르 얀마옌 제도',
        'country.sk': '슬로바키아',
        'country.sl': '시에라리온',
        'country.sm': '산마리노',
        'country.sn': '세네갈',
        'country.so': '소말리아',
        'country.sr': '수리남',
        'country.ss': '남수단',
        'country.st': '상투메 프린시페',
        'country.sv': '엘살바도르',
        'country.sx': '신트 마틴 (네덜란드 부분)',
        'country.sy': '시리아',
        'country.sz': '스와질란드',
        'country.tc': '터크스카이코스 제도',
        'country.td': '차드',
        'country.tf': '프랑스령 남부 지역',
        'country.tg': '토고',
        'country.th': '태국',
        'country.tj': '타지키스탄',
        'country.tk': '토켈라우',
        'country.tl': '동티모르',
        'country.tm': '투르크메니스탄',
        'country.tn': '튀니지',
        'country.to': '통가',
        'country.tr': 'Turvalue',
        'country.tt': '트리니다드 토바고',
        'country.tv': '투발루',
        'country.tw': '대만',
        'country.tz': '탄자니아',
        'country.ua': '우크라이나',
        'country.ug': '우간다',
        'country.um': '미국령 군소 제도',
        'country.us': '미국',
        'country.uy': '우루과이',
        'country.uz': '우즈베키스탄',
        'country.va': 'Holy See',
        'country.vc': '세인트빈센트 그레나딘 ',
        'country.ve': '베네수엘라',
        'country.vg': '버진 제도 (영국령)',
        'country.vi': '버진 제도 (미국령)',
        'country.vn': '베트남',
        'country.vu': '바누아투',
        'country.wf': '월리스푸투나제도',
        'country.ws': '사모아',
        'country.ye': '예멘',
        'country.yt': '마요트',
        'country.za': '남아프리카 공화국',
        'country.zm': '잠비아',
        'country.zw': '짐바브웨',
        cyclocross: '사이클로크로스',
        'date.all_time': '날짜: 모든 시간',
        'date.last_month': '날짜: 지난 달',
        'date.last_week': '날짜: 지난 주',
        'date.last_year': '날짜: 지난 1 년',
        'employees.add_cta_label': '직원 추가',
        'employees.delete_cta_label': '직원 삭제',
        'employees.employee_id_label': '직원 ID',
        'employees.heading': '직원',
        'employees.intro': 'Retül 테크놀로지와의 최신 세션 일정표.',
        'employees.update_cta_label': '직원 업데이트',
        'filters.all_label': '모두',
        'filters.client_all': '모두',
        'filters.client_anonymous': '익명',
        'filters.client_identified': '확인',
        'filters.client_label': '클라이언트 유형',
        'filters.employees_label': '직원',
        'filters.filters_heading': '필터',
        'filters.hide_filters': '필터 보기',
        'filters.locations_label': '위치',
        'filters.rating_all': '모두',
        'filters.rating_label': '평가',
        'filters.rating_none': '없음',
        'filters.session_all': '모두',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': '세션 유형',
        'filters.session_match': 'Match',
        'filters.show_filters': '필터 보기',
        'filters.status_all': '모두',
        'filters.status_closed': '닫기',
        'filters.status_label': '상태',
        'filters.status_open': '열기',
        'footer.privacy_link_label': '개인 정보 보호 정책',
        'footer.terms_link_label': '이용 약관',
        'form.address_1_field_error': '주소는 필수 항목입니다.',
        'form.address_1_field_label': '주소',
        'form.city_field_error': '시는 필수 항목입니다.',
        'form.city_field_label': '시',
        'form.country_field_error': '국가는 필수 항목입니다.',
        'form.country_field_label': '국가',
        'form.delete_field_confirm_message': ' {data} 삭제 하시겠습니까?',
        'form.delete_field_label': '삭제',
        'form.edit_field_label': '편집',
        'form.email_field_error':
            '이메일은 필수 항목이며 올바른 이메일 형식이어야 합니다.',
        'form.email_field_label': '이메일',
        'form.name_field_error': '이름은 필수 항목입니다.',
        'form.name_field_label': '이름',
        'form.shop_field_label': '쇼핑하기',
        'form.shops_field_label': '쇼핑하기',
        'form.state_field_error': '상태는 필수 항목입니다.',
        'form.state_field_label': '상태',
        'form.zip_field_error': '우편 번호는 필수 항목입니다.',
        'form.zip_field_label': '우편 번호',
        'gender.all': '성별: 전체',
        'gender.female': '성별: 여성',
        'gender.male': '성별: 남성',
        'landing.heading': '리테일러 포털에 오신 것을 환영합니다.',
        'landing.intro':
            'Retül 테크놀러지로 캡처 한 데이터, 세션 및 클라이언트 정보에 접속하세요.',
        'landing.sign_in_or_create_account_label': '로그인 / 계정 만들기',
        'locale.label_cs': 'Česky',
        'locale.label_de': 'Deutsch',
        'locale.label_en': 'English',
        'locale.label_en-us': 'English (US)',
        'locale.label_es': 'Espanol',
        'locale.label_fr': 'Français',
        'locale.label_it': 'Italiano',
        'locale.label_ja': '日本語',
        'locale.label_ko': '한국어',
        'locale.label_nl': 'Nederlands',
        'locale.label_pl': 'Polski',
        'locale.label_pt': 'Português',
        'locale.label_pt-br': 'Português do Brasil',
        'locale.label_zh-cn': '简体中文',
        'locale.label_zh-tw': '繁體中文',
        'locale.locale_picker_cancel_label': '취소',
        'locale.locale_picker_heading': '언어를 선택하세요.',
        'locale.locale_picker_save_label': '저장',
        'locations.add_cta_label': '위치 추가',
        'locations.delete_cta_label': '위치 삭제',
        'locations.empty':
            '위치가 추가되지 않았습니다. Retül 앱을 사용하려면 위치를 추가하세요.',
        'locations.empty_cta_label': '위치 관리',
        'locations.heading': '위치 관리',
        'locations.intro':
            'Retül 테크놀러지를 이용하여 위치 추가, 편집, 제거하기',
        'locations.location_id_label': '위치 아이디',
        'locations.update_cta_label': '위치 업데이트',
        'metrics.closed_session_tooltip':
            '닫힌 세션은 트랜잭션이 기록 된 세션을 나타냅니다. 고객이 구매 {closingSessions} 혹은 구매하지 않은 경우 {closingSessions} 통해 Retül 테크놀러지 매출을 추적 할 수 있습니다.',
        'metrics.closed_sessions': '닫힌 세션',
        'metrics.closing_session_link': '세션 닫기',
        'metrics.total_sessions': '총 세션',
        'metrics.unique_client_emails': '클라이언트 고유 이메일',
        'metrics.unique_emails_tooltip':
            '이 숫자는 고유 클라이언트로 수행 된 세션을 나타냅니다. 클라이언트는 이메일 주소로 식별됩니다.',
        'mtb-trail': '산악 (트레일)',
        'mtb-xc': '산악 (크로스컨트리)',
        'nav.analytics': '분석',
        'nav.greeting_label': '안녕하세요, {data}',
        'nav.manage_employees_link_label': '직원 관리',
        'nav.manage_locations_link_label': '위치 관리',
        'nav.sessions': '세션',
        'nav.sign_out_link_label': '로그아웃',
        'pagination.next': '다음',
        'pagination.page': '페이지',
        'pagination.previous': '이전',
        road: '로드',
        'session_details.add_transaction_cta_label': '트랜잭션 추가',
        'session_details.all_sessions_link': '모든 세션',
        'session_details.bike_product': '자전거',
        'session_details.client': '클라이언트',
        'session_details.client_review_label': '클라이언트 리뷰',
        'session_details.complete_session_cta_label': '종료로 표시',
        'session_details.complete_session_message':
            '클라이언트가 만든 트랜잭션을 추가하여 이 세션을 닫습니다.',
        'session_details.complete_status': '종료',
        'session_details.created_on': '생성일: {data}',
        'session_details.delete_link': '삭제',
        'session_details.employee': '직원: {data}',
        'session_details.family_label': 'Family',
        'session_details.incomplete_status': '오픈',
        'session_details.model_label': '모델',
        'session_details.no_transactions_message':
            '트랜잭션이 추가되지 않았습니다.',
        'session_details.product_label': '제품',
        'session_details.saddle_product': '안장',
        'session_details.shoe_product': '신발',
        'session_details.shop': '구매: {data}',
        'session_details.size_label': '사이즈',
        'session_details.status_label': '상태',
        'session_details.transactions_heading': '세션 트랜잭션',
        'session_products.arch_height_label': '아치 높이: {data}',
        'session_products.bike_size_label': '자전거 사이즈: {data}',
        'session_products.saddle_height_label': '안장 높이: {data} mm',
        'session_products.saddle_width_label': '안장 너비: {data} mm',
        'session_products.session_product_label': '세션 데이터',
        'session_products.shoe_size_label': '신발 사이즈 : {data}',
        'sessions.add_transaction_cta_label': '트랜잭션 추가',
        'sessions.client_anonymous': '익명',
        'sessions.client_label': '클라이언트',
        'sessions.closed_status': '종료',
        'sessions.csv_downloading_message':
            '우리는 CSV를 위해 모든 세션을 모으고 있습니다. 잠시 시간이 걸릴 수 있습니다.',
        'sessions.date_label': '날짜',
        'sessions.employee_label': '직원',
        'sessions.export_csv_link': '리스트 내보내기 (.CSV)',
        'sessions.filter_empty':
            '죄송합니다. 필터 기준과 일치하는 세션이 없습니다. 필터를 다시 선택하세요.',
        'sessions.filter_empty_cta_label': '필터 재설정',
        'sessions.heading': '세션',
        'sessions.intro': 'Retül 테크놀러지와의 모든 최신 세션 일정표.',
        'sessions.location_label': '위치',
        'sessions.no_rating': '평가 없음',
        'sessions.open_status': '오픈',
        'sessions.rating_label': '평가',
        'sessions.session_id_label': '세션 아이디',
        'sessions.session_loading_message':
            '우리는 모든 세션을 모으고 있습니다. 잠시 시간이 걸릴 수 있습니다.',
        'sessions.status_label': '상태',
        'sign_in.heading': '로그인',
        'sign_in.intro':
            '시작하려면 이메일과 비밀번호를 입력하거나 "계정 등록"을 클릭하여 새 계정을 만드세요.',
        'status.all': '상태 : 모두',
        'status.complete': '상태: 종료',
        'status.incomplete': '상태: 오픈',
        triathlon: '트라이애슬론',
        'POWER MIMIC DESCRIPTION':
            '이 안장은 연조직에서 균형을 유지하기 위해 여러 유형의 압박에 대한 신체 반응을 모방한 디자인으로 저림현상과 고통을 사라지게합니다.',
        'date_picker.all_time': '모든 세션',
        'date_picker.date_range': '날짜 범위',
        'date_picker.last_month': '지난 달',
        'date_picker.last_week': '지난 주',
        'date_picker.last_year': '작년',
        'date_picker.this_month': '이번 달',
        'date_picker.this_week': '이번 주',
        'date_picker.this_year': '올해',
        'session_details.client_notes': '클라이언트 노트',
        'session_details.footbed_product': '풋베드',
        'session_products.it_width_label': 'IT 너비 : {data} mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match는 매장 내 고객 측정 시스템으로서, 다리 길이를 정확하게 측정하여 최적의 자전거 크기를 파악하고 고객과 가장 일치하는 제품을 찾을 수 있게 해 줍니다. ',
        'body_data.about_saddle':
            'Retül Match는 몰입형 매장 시스템으로서 Retül Digital Sitbone Device의 디지털 압력 맵 기술로 좌골 폭을 캡처하고 가장 정확한 안장 크기를 찾아 고객과 가장 일치하는 안장을 찾아줍니다. ',
        'body_data.about_shoe':
            'Retül Match는 몰입형 매장 시스템으로서 발 데이터를 캡처하고 가장 정확한 아치 받침 요구 사항 및 신발 크기를 찾아 고객과 가장 일치하는 신발을 찾아줍니다. ',
        'body_data.arch_height_label': '아치 높이',
        'body_data.bike_label': '자전거',
        'body_data.bike_size_label': '자전거 크기',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': '둘레',
        'body_data.find_retul_technology': 'Retül 기술 찾기',
        'body_data.heading': '신체 데이터',
        'body_data.intro':
            'Retül Vantage 기술은 고객의 신체 데이터와 선호하는 라이딩 포지션을 위한 측정을 통해 정확한 사이징 정보를 제공하고 고객과 가장 일치하는 제품을 찾아줍니다. ',
        'body_data.length_label': '길이',
        'body_data.load_error':
            '현재는 신체 데이터를 검색할 수 없습니다. 나중에 다시 시도하십시오. ',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': '수집되지 않음',
        'body_data.recommended_label': '권장',
        'body_data.saddle_height_label': '안장 높이',
        'body_data.saddle_label': '안장',
        'body_data.saddle_size_label': '안장 크기',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': '정강이',
        'body_data.shoe_label': '신발',
        'body_data.shoe_size_label': '신발 크기',
        'body_data.sit_bone_label': '좌골',
        'body_data.starting_label': '시작하는 중',
        'body_data.thigh_label': '넓적다리',
        'body_data.view_bike_matches_label': '일치하는 자전거 보기',
        'body_data.view_saddle_matches_label': '일치하는 안장 보기',
        'body_data.view_shoe_matches_label': '일치하는 신발 보기',
        'body_data.your_progress_completion_label': '{numComplete}/3 완료',
        'body_data.your_progress_label': '진행률',
        cancel_label: '취소',
        'create_account.heading': '계정 생성',
        'create_account.intro':
            '이메일과 비밀번호를 입력하여 Retül Passport 계정을 생성하십시오. ',
        'edit_form.edit_form_cancel_label': '취소',
        'edit_form.edit_form_save_label': '저장',
        email_format_error: '잘못된 이메일 형식. ',
        email_label: '이메일',
        'forgot_password.heading': '비밀번호 찾기',
        'forgot_password.intro':
            '비밀번호를 잊으셨습니까? 이메일을 입력하시면 재설정 링크를 보내드립니다. ',
        'forgot_password.reset_email_sent':
            '{email} 주소로 비밀번호 재설정 이메일이 전송되었습니다',
        'landing.create_account_label': '계정 생성',
        'landing.sign_in_label': '로그인',
        'nav.account_profiles_link_label': '계정 및 라이더 프로필',
        'nav.home_page_link_label': '홈 페이지',
        'nav.logout_link_label': '로그아웃',
        'not_found.go_home_label': '홈으로 이동',
        'not_found.heading': '404',
        'not_found.intro':
            '찾고 있는 페이지가 이동 또는 삭제되었거나 존재하지 않습니다. ',
        password_invalid_error: '잘못된 비밀번호. ',
        password_label: '비밀번호',
        'product_matches.bike_size_label': '자전거 크기',
        'product_matches.category_filter_commute_comfort_label': '출퇴근용',
        'product_matches.category_filter_commute_sport_label':
            '출퇴근용 스포츠 자전거',
        'product_matches.category_filter_cyclocross_label': '사이클로크로스',
        'product_matches.category_filter_label': '카테고리',
        'product_matches.category_filter_label_road': '도로용',
        'product_matches.category_filter_mountain_trail_label':
            '산악용(트레일)',
        'product_matches.category_filter_mountain_xc_label':
            '산악용(크로스컨트리)',
        'product_matches.category_filter_triathlon_label': '철인 3종 경기',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            '이 제품 유형에 대해 일치하는 결과를 제공할 신체 데이터가 없습니다. 위의 필터를 사용하여 다른 제품 유형을 보거나 위치 페이지를 사용하여 이 일치 모듈을 완료할 위치를 찾으십시오. ',
        'product_matches.find_retul_technology_label': 'Retul 기술 찾기',
        'product_matches.gender_filter_all_label': '모든 제품 표시',
        'product_matches.gender_filter_female_label': '여성 제품 표시',
        'product_matches.gender_filter_male_label': '남성 제품 표시',
        'product_matches.heading': '일치하는 제품',
        'product_matches.intro':
            'Retül 기술은 고객의 신체 데이터와 선호하는 라이딩 포지션을 활용하여 고객과 가장 일치하는 Specialized 제품을 찾아줍니다. ',
        'product_matches.last_session_match_label': '마지막 Match 세션',
        'product_matches.load_error':
            '현재는 일치하는 제품을 검색할 수 없습니다. 나중에 다시 시도하십시오. ',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'Retül Match 세션 중 캡처한 생체측정 정보로부터 위 자전거 크기를 추천합니다. 이 추천이 좋은 출발점이지만, 현지 판매점과 상담하여 귀하의 경험 수준과 신체 유형에 맞게 추천을 받으실 것을 권장합니다. ',
        'product_matches.overlay_bike_size_label': '자전거 크기',
        'product_matches.overlay_close_label': '닫기',
        'product_matches.overlay_indicator_label': '귀하',
        'product_matches.overlay_saddle_height_details':
            'Retül Match 세션 중 캡처한 생체측정 정보로부터 위 안장 높이를 추천합니다. 이 추천이 좋은 출발점이지만, 현지 판매점과 상담하여 귀하의 경험 수준과 신체 유형에 맞게 추천을 받으실 것을 권장합니다. ',
        'product_matches.overlay_saddle_height_label': '안장 높이',
        'product_matches.overlay_saddle_size_details':
            'Retül Match 세션 중 캡처한 생체측정 정보로부터 위 안장 크기를 추천합니다. 이 추천이 좋은 출발점이지만, 현지 판매점과 상담하여 귀하의 구체적인 신체 조건에 맞게 추천을 받으실 것을 권장합니다. ',
        'product_matches.overlay_saddle_size_label': '안장 크기',
        'product_matches.overlay_shoe_size_details':
            'Retül Match 세션 중 캡처한 생체측정 정보로부터 위 신발 크기를 추천합니다. 이 추천이 좋은 출발점이지만, 현지 판매점과 상담하여 귀하의 경험 수준에 맞게 추천을 받으실 것을 권장합니다. ',
        'product_matches.overlay_shoe_size_label': '신발 크기',
        'product_matches.padding_level_label': '패딩 높이',
        'product_matches.product_type_filter_bikes_label': '자전거',
        'product_matches.product_type_filter_label': '제품 유형',
        'product_matches.product_type_filter_saddles_label': '안장',
        'product_matches.product_type_filter_shoes_label': '신발',
        'product_matches.recommended_label': '권장',
        'product_matches.saddle_detail_size_label': '안장 크기:',
        'product_matches.saddle_height_label': '안장 높이',
        'product_matches.saddle_models_label': '모델',
        'product_matches.saddle_size_label': '안장 크기',
        'product_matches.shoe_size_label': '신발 크기',
        'product_matches.starting_label': '시작하는 중',
        'product_matches.view_on_specialized_label': 'Specialized 보기',
        'profile.account_heading': '계정',
        'profile.bike_type_city_label': '도시용',
        'profile.bike_type_label': '자전거 유형',
        'profile.bike_type_mountain_label': '산악용',
        'profile.bike_type_road_label': '도로용',
        'profile.email_error': '유효한 이메일을 입력하십시오',
        'profile.email_label': '이메일',
        'profile.gender_female_label': '여성',
        'profile.gender_label': '성별',
        'profile.gender_male_label': '남성',
        'profile.heading': '계정 및 라이더 프로필',
        'profile.intro':
            '곧 출시 예정... 계정 정보와 라이어 프로필을 편집하십시오. ',
        'profile.name_error': '이름을 입력하십시오',
        'profile.name_label': '이름',
        'profile.password_error': '비밀번호를 입력하십시오',
        'profile.password_label': '비밀번호',
        'profile.position_high_label': '높음',
        'profile.position_label': '포지션',
        'profile.position_low_label': '낮음',
        'profile.position_medium_label': '중간',
        'profile.ride_duration_extended_label': '90분 이상',
        'profile.ride_duration_label': '기간',
        'profile.ride_duration_long_label': '60-90분',
        'profile.ride_duration_medium_label': '30-60분',
        'profile.ride_duration_short_label': '0-30분',
        'profile.ride_frequency_label': '빈도',
        'profile.ride_frequency_once_month_label': '한 달에 1회',
        'profile.ride_frequency_once_week_label': '한 주에 1회',
        'profile.ride_frequency_six_week_label': '한 주에 6회',
        'profile.ride_frequency_three_week_label': '한 주에 3회',
        'routes.account_profile_route_label': '계정 및 라이더 프로필',
        'routes.body_data_route_label': '신체 데이터',
        'routes.create_account_route_label': '계정 생성',
        'routes.forgot_password_route_label': '비밀번호 찾기',
        'routes.locations_route_label': '위치',
        'routes.not_found_route_label': '찾지 못함',
        'routes.privacy_policy_route_label': '개인정보 보호 정책',
        'routes.product_matches_route_label': '일치하는 제품',
        'routes.reset_password_route_label': '비밀번호 재설정',
        'routes.set_password_route_label': '비밀번호 재설정',
        'routes.sessions_route_label': '세션',
        'routes.sign_in_route_label': '로그인',
        'routes.terms_of_service_route_label': '서비스 약관',
        'sessions.download_pdf_cta_label': 'PDF 다운로드',
        'sessions.empty': '아직 시작한 세션이 없습니다. ',
        'sessions.empty_cta_label': '위치 찾기',
        'sessions.loading_error':
            '현재는 세션을 검색할 수 없습니다. 나중에 다시 시도하십시오. ',
        'sessions.loading_error_cta_label': '다시 시도',
        'sessions.session_type_filter_label': '세션 유형',
        'session_type.dfd_session_name': 'Retül Digital Foot Device',
        'session_type.dsd_session_name': 'Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül Foot',
        'session_type.legs_session_name': 'Retül Legs',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül Saddle',
        'session_type.unknown_session_name': 'Retül Session',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': '로그인',
        'sign_in.contact_label': 'Retül에 문의하기',
        'sign_in.forgot_password_label': '비밀번호를 잊으셨나요? ',
        'sign_in.sign_in_error':
            '귀하의 사용자 데이터를 검색할 수 없습니다. Retül 지원부에 도움을 요청하십시오. ',
        submit_label: '제출',
    },
};
