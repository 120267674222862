import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import messages from '../../utils/messages';
import Text from '../../components/Text/Text';
import TextField from '../../components/TextField/text-field';
import classNames from 'classnames';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverError: false,
            formMessages: [],
            loading: false,
        };

        this.showGigyaScreenset = this.showGigyaScreenset.bind(this);

        this.fields = {};

        this.setUpHandlers();
    }

    componentDidMount() {
        if (process.env.REACT_APP_AUTH_BACKEND === 'gigya') {
            this.createGigyaScript(
                `https://cdns.gigya.com/js/gigya.js?apikey=${process.env.REACT_APP_GIGYA_KEY}`,
                document.body
            );
        }
    }

    /**
     * Binds any handlers to the correct context
     *
     * @method  setUpHandlers
     */
    setUpHandlers() {
        this.handleSubmit = this.handleSubmit.bind(this);
        this.trackField = this.trackField.bind(this);
    }

    trackField(field) {
        if (!field) return;

        this.fields[field.input.name] = field;
    }

    /**
     * Handles submission of login form
     * Will validate email and password fields
     * based on their latest values
     *
     * @method  handleSubmit
     */
    handleSubmit(event) {
        if (event) event.preventDefault();

        if (this.formHasErrors()) return;

        this.setState({
            serverError: false,
            formMessages: [],
        });
        this.resetPassword();
    }

    formHasErrors() {
        const fields = Object.values(this.fields);

        const errors = fields.filter((f) => {
            return !f.isValid();
        });

        return errors.length > 0;
    }

    /**
     * Send email link to user to reset password.
     *
     * @method  resetPassword
     */
    resetPassword() {
        const email = this.fields.email.input.value;

        this.setState({ loading: true });

        this.props.handleSubmit({
            email,
        });
    }

    createGigyaScript() {
        const scriptTag = document.createElement('script');
        scriptTag.src = `https://cdns.gigya.com/js/gigya.js?apikey=${process.env.REACT_APP_GIGYA_KEY}`;

        scriptTag.onload = this.showGigyaScreenset;
        scriptTag.onreadystatechange = this.showGigyaScreenset;

        document.body.appendChild(scriptTag);
    }

    showGigyaScreenset() {
        window.gigya.accounts.showScreenSet({
            screenSet: 'Passport-RegistrationLogin',
            startScreen: 'gigya-forgot-password-screen',
            containerID: 'passwordScreensDiv',
        });
    }

    _renderFormMessages() {
        const ulClasses = classNames('form-messages', {
            'form-messages_hasMessage': this.state.formMessages.length > 0,
        });
        const color = this.state.serverError ? 'DefaultOrange' : 'DefaultBlue';

        if (this.props.data && this.props.data.reason) {
            return (
                <ul className={ulClasses}>
                    <li>
                        <Text color={color} weight="light">
                            {this.props.data.reason}
                        </Text>
                    </li>
                </ul>
            );
        }
    }

    render() {
        const { intl } = this.props;
        const formMessages = this._renderFormMessages();
        const className =
            process.env.REACT_APP_AUTH_BACKEND === 'gigya' ? 'hidden' : '';

        return (
            <div className="form-container">
                <div className="gigya-screen-set" id="passwordScreensDiv" />
                <form
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                    noValidate
                    className={className}
                >
                    {formMessages}
                    <div className="form-fields">
                        <div className="set">
                            <div className="set-hd">
                                <TextField
                                    type="email"
                                    name="email"
                                    label={intl.formatMessage(
                                        messages.email_label
                                    )}
                                    errorMsg={intl.formatMessage(
                                        messages.email_format_error
                                    )}
                                    ref={this.trackField}
                                />
                            </div>
                            <div className="set-bd set-bd_super">
                                <div className="set mix-set_alignCenter">
                                    <div className="set-hd">
                                        <Button
                                            className="btn_fixedWidth"
                                            theme="dark"
                                            onClick={this.handleSubmit}
                                            type="submit"
                                        >
                                            {intl.formatMessage(
                                                messages.submit_label
                                            )}
                                        </Button>
                                    </div>
                                    <div className="set-bd set-bd_separated">
                                        <Link to={`/`} className="txt txt_link">
                                            {intl.formatMessage(
                                                messages.cancel_label
                                            )}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default injectIntl(ForgotPasswordForm);
