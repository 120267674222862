import * as actionTypes from '../types/actions/locations'
import {apiCreate, apiDelete, apiGet, apiPut} from '../api/apiClient';
import {locationsUrl, locationUrl} from '../utils/urlHelper';
import {JSON_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';

export function createLocationRequested() {
  return {
    type: actionTypes.CREATE_LOCATION_REQUESTED
  }
}

export function createLocationComplete() {
  return {
    type: actionTypes.CREATE_LOCATION_COMPLETE
  }
}

export function createLocationFailed() {
  return {
    type: actionTypes.CREATE_LOCATION_FAILED
  }
}

export function createLocation(data) {
  return dispatch => {
    dispatch(createLocationRequested());
    return apiCreate(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          receiveCallback: createLocationComplete,
          url: locationsUrl()
        }
      )
    )
  }
}

export function deleteLocation(locationId) {
  return dispatch => {
    dispatch(deleteLocationRequested());

    return apiDelete(
      generateRequestConfigs({
          dispatch,
          receiveCallback: deleteLocationComplete,
          failCallback: deleteLocationFailed,
          url: locationUrl(locationId)
        }
      ))
  }
}

export function deleteLocationRequested() {
  return {
    type: actionTypes.DELETE_LOCATION_REQUESTED
  }
}

export function deleteLocationComplete() {
  return {
    type: actionTypes.DELETE_LOCATION_COMPLETE
  }
}

export function deleteLocationFailed() {
  return {
    type: actionTypes.DELETE_LOCATION_FAILED
  }
}

export function fetchLocation(locationId) {
  return dispatch => {
    dispatch(requestLocation());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveLocation,
          url: locationUrl(locationId),
        }
      )
    )
  }
}

export function fetchLocations() {
  return dispatch => {
    dispatch(requestLocations());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveLocations,
          url: locationsUrl(),
        }
      )
    )
  }
}

export function receiveLocation(data) {
  return {
    data,
    type: actionTypes.RECEIVE_LOCATION
  }
}

export function receiveLocations(data) {
  return {
    data,
    type: actionTypes.RECEIVE_LOCATIONS_DATA
  }
}

export function requestLocation() {
  return {
    type: actionTypes.REQUEST_LOCATION
  }
}

export function requestLocations() {
  return {
    type: actionTypes.REQUEST_LOCATIONS_DATA
  }
}

export function updateLocation(data) {
  const {id} = data;
  return dispatch => {
    dispatch(updateLocationRequested());
    return apiPut(
      generateRequestConfigs({
        body: JSON.stringify(data),
        dispatch,
        parser: JSON_PARSER,
        failCallback: updateLocationFailed,
        receiveCallback: updateLocationComplete,
        url: locationUrl(id)
      })
    )
  }
}

export function updateLocationRequested() {
  return {
    type: actionTypes.UPDATE_LOCATION_REQUESTED
  }
}

export function updateLocationComplete(data) {
  return {
    data,
    type: actionTypes.UPDATE_LOCATION_COMPLETE
  }
}

export function updateLocationFailed() {
  return {
    type: actionTypes.UPDATE_LOCATION_FAILED
  }
}


