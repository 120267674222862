import React, { Component } from 'react';
import { connect } from 'react-redux';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import * as actionCreators from '../actions/authentication';
import { RECEIVED } from '../types/statuses';
import ResetPassword from '../components/ResetPassword/ResetPassword';

export class ResetPasswordContainer extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUpdate(nextProps) {
        const { fetchingStatus, history } = nextProps;

        if (fetchingStatus === RECEIVED) {
            history.replace('/');
        }
    }

    handleSubmit(data) {
        const { fetchJWT } = this.props;
        fetchJWT(data, this.handleServerError);
    }

    render() {
        return componentWithErrorBoundary(
            <ResetPassword
                handleSubmit={this.handleSubmit}
                serverErrors={this.props.error}
            />
        );
    }
}

function mapStateToProps(state) {
    return state.authentication;
}

export default connect(mapStateToProps, actionCreators)(ResetPasswordContainer);
