import React, {Component} from 'react';
import Modal from 'react-modal';
import ResourceForm from '../../components/ResourceForm/ResourceForm';
import ModalClose from '../../components/Modal/ModalClose';

export default class ResourceModal extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openModal && !this.state.modalIsOpen) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false}, this.props.modalClosed);
  }

  renderResourceForm() {
    const {id, submitFunction, displayedText, fields, checkboxGroups, submitText} = this.props;

    return (
      <ResourceForm
        checkboxGroups={checkboxGroups}
        displayedText={displayedText}
        fields={fields}
        id={id}
        submitFunction={submitFunction}
        submitText={submitText}
      />
    )
  }

  render() {
    const {displayedText, topModalClass} = this.props;
    let className = "modal-content";
    if (topModalClass === true) {className = "modal-content-top"}
    return (
      <Modal
        className={{
          base: 'modal',
          afterOpen: '',
          beforeClose: ''
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: '',
          beforeClose: ''
        }}

        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel={`${displayedText} Modal`}
      >
        <ModalClose closeModal={this.closeModal}/>
        <div className={className}>
          {this.renderResourceForm()}
        </div>
      </Modal>
    )
  }
}
