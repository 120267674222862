import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Landing from '../../components/Landing/Landing';
import SessionsContainer from '../../containers/SessionsContainer';
import AnalyticsBySession from '../../components/AnalyticsBySession/AnalyticsBySession';
import AnalyticsByProduct from '../../components/AnalyticsByProduct/AnalyticsByProduct';
import ManageEmployeesContainer from '../../containers/ManageEmployeesContainer';
import ManageLocationsContainer from '../../containers/ManageLocationsContainer';
import SignInContainer from '../../containers/SignInContainer';
import CreateAccountContainer from '../../containers/CreateAccountContainer';
import SessionDetailsContainer from '../../containers/SessionDetailsContainer';
import ClientProfileContainer from '../../containers/ClientProfileContainer';
import ForgotPasswordContainer from '../../containers/ForgotPasswordContainer';
import VerifyContainer from '../../containers/VerifyContainer';
import ResetPasswordContainer from '../../containers/ResetPasswordContainer';
import Navigation from '../../components/Navigation/Navigation';
import NotFound from '../../components/NotFound/NotFound';
import Footer from '../../components/Footer/Footer';
import {
    renderRoute,
    renderRouteUnlessAuthenticated,
    renderSecureRedirect,
    renderSecureRoute,
} from '../../utils/routerHelper';
import '../../assets/stylesheets/base/Reset.css';
import '../../assets/stylesheets/box.css';
import '../../assets/stylesheets/grid.css';
import '../../assets/stylesheets/modal.css';
import '../../assets/stylesheets/Tables.css';
import '../../assets/stylesheets/Text.css';
import '../../assets/stylesheets/vList.css';
import '../../assets/stylesheets/set.css';
import './App.css';
import { Route } from 'react-router';
import { initializeFirebase } from '../../firebase';

export default class App extends Component {
    constructor(props) {
        super(props);
        initializeFirebase();
    }
    render() {
        return (
            <Router>
                <div className="site">
                    <Navigation />
                    <div className="site-main">
                        <Switch>
                            {renderRouteUnlessAuthenticated(
                                '/',
                                '/sessions',
                                Landing
                            )}

                            {renderSecureRedirect(
                                '/analytics',
                                '/analytics/products-analytics',
                                '/'
                            )}

                            {renderSecureRoute(
                                '/analytics/products-analytics',
                                AnalyticsByProduct
                            )}

                            {renderSecureRoute(
                                '/analytics/sessions-analytics',
                                AnalyticsBySession
                            )}

                            {renderSecureRoute(
                                '/manage-employees',
                                ManageEmployeesContainer
                            )}

                            {renderSecureRoute(
                                '/manage-locations',
                                ManageLocationsContainer
                            )}

                            {renderRoute(
                                '/forgot-password',
                                ForgotPasswordContainer
                            )}

                            {renderSecureRoute('/sessions', SessionsContainer)}

                            {renderSecureRoute(
                                '/sessions/:id',
                                SessionDetailsContainer
                            )}

                            {renderSecureRoute(
                                '/clients/:id',
                                ClientProfileContainer
                            )}

                            {renderRouteUnlessAuthenticated(
                                '/sign-in',
                                '/',
                                SignInContainer
                            )}

                            {renderRouteUnlessAuthenticated(
                                '/create-account',
                                '/',
                                CreateAccountContainer
                            )}

                            {renderRouteUnlessAuthenticated(
                                '/forgot-password',
                                '/',
                                ForgotPasswordContainer
                            )}

                            {renderRouteUnlessAuthenticated(
                                '/verify',
                                '/',
                                VerifyContainer
                            )}

                            {renderRouteUnlessAuthenticated(
                                '/reset-password',
                                '/',
                                ResetPasswordContainer
                            )}

                            {/*{renderRoute('/terms', Terms)}*/}
                            <Route
                                path="/terms"
                                component={() =>
                                    (window.location =
                                        'https://www.specialized.com/terms-of-use')
                                }
                            />

                            {/*{renderRoute('/privacy-policy', PrivacyPolicy)}*/}
                            <Route
                                path="/privacy-policy"
                                component={() =>
                                    (window.location =
                                        'https://www.specialized.com/privacy-policy')
                                }
                            />

                            {renderRoute('*', NotFound)}
                        </Switch>
                    </div>
                    <Footer onLocaleChange={this.props.onLocaleChange} />
                </div>
            </Router>
        );
    }
}
