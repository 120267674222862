import React from 'react';
import {ADD_EMPLOYEE, EMPLOYEE_FORM_FIELDS, LOCATION_FORM_FIELDS} from '../types/constants';
import {renderConfirmationModal, renderResourceModal} from '../utils/modalHelper';
import {getFields} from '../utils/formHelper';
import {getEmployeeShopIds} from '../selectors/employees';
import messages from '../components/EmployeesTable/messages';
import formMessages from '../types/constantsMessages';

function generateUpdateEmployeeFields(employeeData) {
  return getFields(
    {
      fieldsArray: EMPLOYEE_FORM_FIELDS,
      checkboxGroups: LOCATION_FORM_FIELDS,
      values: employeeData
    }
  )
}

export function renderAddEmployeeModal(openModal, props) {
  const {createEmployee, locationsList, intl} = props;

  return renderResourceModal(
    {
      displayedText: ADD_EMPLOYEE,
      fields: getFields({fieldsArray: EMPLOYEE_FORM_FIELDS}),
      checkboxGroups: generateLocationCheckboxFields(locationsList, [], props),
      submitText: intl.formatMessage(messages.add_employee),
      submitFunction: createEmployee,
      openModal,
      topModalClass: true
    }
  )
}

export function renderDeleteEmployeeModal(openModal, modalClosed, employeeData, props) {
  const {deleteEmployee, intl} = props;
  const {id, name} = employeeData;

  return renderConfirmationModal(
    {
      displayedText: 'DELETE',
      id,
      submitText: intl.formatMessage(messages.delete_employee_label),
      confirmMessage: intl.formatMessage(formMessages.form_delete_field_confirm_message, {data: name}),
      confirmFunction: deleteEmployee,
      employeeData,
      openModal,
      modalClosed,
    }
  )
}

export function renderUpdateEmployeeModal(openModal, modalClosed, employeeData, props) {
  const {id} = employeeData;
  const employeeShopIds = getEmployeeShopIds(employeeData);
  const {locationsList, updateEmployee, intl} = props;

  return renderResourceModal(
    {
      displayedText: 'EDIT',
      id,
      fields: generateUpdateEmployeeFields(employeeData),
      checkboxGroups: generateLocationCheckboxFields(locationsList, employeeShopIds, props),
      submitText: intl.formatMessage(messages.update_employee),
      submitFunction: updateEmployee,
      openModal,
      modalClosed,
    }
  )
}

export function renderShopsList(shops) {
  return shops.map((shop, index) => {
    const {name} = shop;
    return (
      <div
        className="shop"
        key={index}
      >
        {name}
      </div>)
  })
}

function isEmployeeLocation(locationId, employeeShopIds) {
  return !!employeeShopIds.find(employeeShopId => employeeShopId === locationId)
}

export function generateLocationCheckbox(location, employeeShopIds) {

  const {name, id} = location;
  return {
    labelText: name,
    fieldType: 'checkbox',
    value: id,
    checked: employeeShopIds && isEmployeeLocation(id, employeeShopIds)
  };
}

export function generateLocationCheckboxFields(locationsList, employeeShopIds, props) {
  const {intl} = props;
  const {data} = locationsList;

  const checkboxes = data.map((location) => {
    return generateLocationCheckbox(location, employeeShopIds)
  });

  return [
    {
      displayedGroupName: intl.formatMessage(messages.shops_label),
      checkboxGroupName: 'shops',
      checkboxes
    }
  ]
}
