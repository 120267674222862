import React, {Component} from 'react';
import './Legend.css';
import {setFill} from "../../../../utils/chartHelper";

export default class Legend extends Component {
  calculateGYposition(i) {
    if (i <= 1) {
      return this.yBasePosition(i);
    }

    if (i % 2 === 0) {
      const multiplier = i * 30;
      return this.yBasePosition(i) + multiplier;
    }

    else {
      const multiplier = (i - 1) * 30;
      return this.yBasePosition(i) + multiplier;
    }
  }

  yBasePosition(i) {
    const {height} = this.props;
    return 0 - (.2 * height);
  }

  xBasePosition(i) {
    const {width} = this.props;
    const multiplier = width * .25;
    return multiplier + (100 * i)
  }

  calculateGXposition(i) {
    if (i % 2 === 0 || i === 0) {
      return this.xBasePosition(0)
    }
    else {
      return this.xBasePosition(1);
    }
  }

  render() {
    const {data, colorRange} = this.props;

    return (
      <g className="Dougnut-Legend"
      >
        {
          data.map((d, i) => {
            return (
              <g
                key={i}
                transform={`translate(${this.calculateGXposition(i)}, ${this.calculateGYposition(i)})`}
              >
                <rect
                  x={0}
                  y={10}
                  width={10}
                  height={10}
                  style={setFill(colorRange, d.item)}
                />

                <text
                  className="Item"
                  key={`${i}-legend-item`}
                  x={15}
                  y={20}
                  style={setFill(colorRange, d.item)}
                >
                  {d.item}
                </text>

                <text
                  className="Quantity"
                  key={`${i}-legend-quantity`}
                  x={15}
                  y={50}
                  style={setFill(colorRange, d.item)}
                >
                  {d.quantity}
                </text>
              </g>
            )
          })
        }
      </g>
    )
  }
}
