import { defineMessages } from 'react-intl';

export default defineMessages({
  'empty': {
    'id': 'locations.empty',
    'defaultMessage': 'No locations have been added, to use Retül Apps please add a location.',
    'description': 'Message when no locations have been setup on the account'
  },
  'empty_cta_label': {
    'id': 'locations.empty_cta_label',
    'defaultMessage': 'Manage Locations',
    'description': 'CTA label when no locations have been setup on the account'
  }
});
