import {RECEIVED, REQUESTING} from '../types/statuses';
import {
  RECEIVE_CLIENT_PRODUCT_DATA,
  RECEIVE_SESSION_DATA,
  RECEIVE_SESSION_PRODUCT_DATA, REQUEST_CLIENT_PRODUCT_DATA,
  REQUEST_SESSION_DATA,
  REQUEST_SESSION_PRODUCT_DATA,
} from '../types/actions/sessions';
import {getSession, getSessionProduct} from '../selectors/sessions';
import {RECEIVE_BIKE_TYPES, REQUEST_BIKE_TYPES, UPDATE_BIKE_TYPE} from "../types/actions/transactions";

export const initialState = {
  sessionProductData: {
    data: null,
    fetchingStatus: null,
  },
  bikeTypeData: {
    data: null,
    fetchingStatus: null
  },
  bikeType: null
};

function receiveSessionData(state, action) {
  const normalizedData = getSession(action.data);
  const sessionDetailsData = {...state.sessionDetailsData, fetchingStatus: RECEIVED, data: normalizedData};

  return {...state, sessionDetailsData}
}

function requestSessionData(state) {
  const sessionDetailsData = {...state.sessionDetailsData, fetchingStatus: REQUESTING};

  return {...state, sessionDetailsData}
}

function receiveSessionProductData(state, action) {
  const normalizedData = getSessionProduct(action.data);
  const sessionProductData = {...state.sessionProductData, fetchingStatus: RECEIVED, data: normalizedData};

  return {...state, sessionProductData}
}

function requestSessionProductData(state) {
  const sessionProductData = {...state.sessionProductData, fetchingStatus: REQUESTING};

  return {...state, sessionProductData}
}

function receiveClientProductData(state, action) {
  const normalizedData = getSessionProduct(action.data);
  const sessionProductData = {...state.sessionProductData, fetchingStatus: RECEIVED, data: normalizedData};

  return {...state, sessionProductData}
}

function requestClientProductData(state) {
  const sessionProductData = {...state.sessionProductData, fetchingStatus: REQUESTING};

  return {...state, sessionProductData}
}

function requestBikeTypes(state) {
  const bikeTypeData = {...state.bikeTypeData, fetchingStatus: REQUESTING};
  return {...state, bikeTypeData}
}

function receiveBikeTypes(state, action) {

  const bikeTypeData = {...state.bikeTypeData, fetchingStatus: RECEIVED};
  bikeTypeData.data = [...action.bikeType];
  return {...state, bikeTypeData}
}


function updateBikeType(state, action) {
  const bikeType = {"bikeType": action.data};

  return {...state, ...bikeType}
}


export default function sessionProducts(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SESSION_DATA:
      return receiveSessionData(state, action);
    case REQUEST_SESSION_DATA:
      return requestSessionData(state);
    case RECEIVE_SESSION_PRODUCT_DATA:
      return receiveSessionProductData(state, action);
    case REQUEST_SESSION_PRODUCT_DATA:
      return requestSessionProductData(state);
    case RECEIVE_CLIENT_PRODUCT_DATA:
      return receiveClientProductData(state, action);
    case REQUEST_CLIENT_PRODUCT_DATA:
      return requestClientProductData(state);
    case REQUEST_BIKE_TYPES:
      return requestBikeTypes(state);
    case RECEIVE_BIKE_TYPES:
      return receiveBikeTypes(state, action);
    case UPDATE_BIKE_TYPE:
      return updateBikeType(state, action);
    default:
      return state
  }
}
