import {APPLICATION_JSON, CONTENT_TYPE} from '../types/constants';
import {AUTHORIZATION} from '../types/apiClient';

export function generateRequestConfigs(params = {}) {
  const {body, contentType, dispatch, parser, failCallback, receiveCallback, url, auth} = params;
  let customHeaders = {
    [CONTENT_TYPE]: contentType || APPLICATION_JSON
  };

  if (auth) {
    customHeaders[AUTHORIZATION] = auth;
  }

  return {
    customConfig: {
      body,
      customHeaders,
      dispatch,
      failCallback,
      parser,
      receiveCallback,
      url
    }
  }
}
