import {addLocaleData, intlShape} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import itLocaleData from 'react-intl/locale-data/it';
import csLocaleData from 'react-intl/locale-data/cs';
import jaLocaleData from 'react-intl/locale-data/ja';
import koLocaleData from 'react-intl/locale-data/ko';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import zhLocaleData from 'react-intl/locale-data/zh';
import {
  esLocaleStrings,
  enLocaleStrings,
  zh_CNLocaleStrings,
  zh_TWLocaleStrings,
  csLocaleStrings,
  nlLocaleStrings,
  frLocaleStrings,
  deLocaleStrings,
  itLocaleStrings,
  jaLocaleStrings,
  koLocaleStrings,
  plLocaleStrings,
  ptLocaleStrings,
  pt_BRLocaleStrings,
} from './locales';

addLocaleData([
  ...enLocaleData,
  ...esLocaleData,
  ...deLocaleData,
  ...frLocaleData,
  ...itLocaleData,
  ...csLocaleData,
  ...jaLocaleData,
  ...koLocaleData,
  ...nlLocaleData,
  ...plLocaleData,
  ...ptLocaleData,
  ...zhLocaleData
]);

const localeStorageKey = 'locale';

// TODO: Add locale specific messages here, for now will drop back to default locale

// merge en (default) with other locales to fallback on en if other locale is missing a string
const localeStrings = Object.assign(
  {},
  esLocaleStrings,
  frLocaleStrings,
  enLocaleStrings,
  zh_CNLocaleStrings,
  zh_TWLocaleStrings,
  csLocaleStrings,
  nlLocaleStrings,
  deLocaleStrings,
  itLocaleStrings,
  jaLocaleStrings,
  koLocaleStrings,
  plLocaleStrings,
  ptLocaleStrings,
  pt_BRLocaleStrings,
);
// merge languages with default to allow fallback to default lang

// Internationalization formatter instance that is set using the IntlCapture
// component which pulls the existing instance from the React component context.
// This is exposed in the I18n class below and allows for use outside of a
// React component.
let intl;

export const IntlCapture = ({children}, context) => {
  // store intl formatter for later use
  intl = context.intl;

  return children;
};

IntlCapture.contextTypes = {
  intl: intlShape.isRequired
};

class I18n {
  constructor() {
    this.availableLocales = ['en', 'es', 'de', 'cs', 'fr', 'it', 'ja', 'ko', 'nl', 'pl', 'pt', 'pt-br', 'zh-cn', 'zh-tw'];
    this.defaultLocale = 'en';
    this.locale = this.getLocalePreference();
    this.messages = localeStrings;
  }

  /**
   * Provides internationalization formatting using the react-intl library
   * without needing to be used within a React component.
   *
   * @param  {Object} key - the message structure which must contain an id field
   * @param  {Object} values - an object containing any substitution values for the message
   * @return {String} - A formatted string based on the current locale
   */
  formatMessage(key, values) {
    return intl.formatMessage(key, values);
  }

  /**
   * Retrieve preferred locale for user
   *
   * Order of priority:
   * 1. Locale stored in local storage
   * 2. Browser locale preference
   * 3. Default Application locale
   *
   * Note: Attempts to use base locale when preferred locale is not available
   * before dropping back to the default locale.
   * (eg. 'en-US' will also search for 'en' in available locales)
   *
   * Note: Standardizes all returned locale codes to lowercase as browsers can
   * be inconsistent with casing.
   * (eg. 'en-US' becomes 'en-us')
   *
   * @return {String} Locale code (ie. 'en', 'es')
   */
  getLocalePreference() {
    let locale =
      localStorage.getItem(localeStorageKey)
      || this.getBrowserLocale();

    if (this.availableLocales.includes(locale)) {
      return locale.toLowerCase();
    }

    const baseLocale = locale.split('-')[0];
    if (this.availableLocales.includes(baseLocale)) {
      return baseLocale.toLowerCase();
    }

    return this.defaultLocale.toLowerCase();
  }

  /**
   * Pull locale preference from browser (only the top locale when multiple exist)
   *
   * @method getBrowserLocale
   * @return {String} [The preferred locale based on browser settings]
   */
  getBrowserLocale() {
    return (
      (navigator.languages && navigator.languages[0])
      || navigator.language
      || navigator.userLanguage
    );
  }

  /**
   * Updates the current locale and stores the value in local storage
   * for later use
   *
   * @param {String} locale - Locale code (ie. 'en', 'es')
   */
  setActiveLocale(locale) {
    this.locale = locale;
    localStorage.setItem(localeStorageKey, locale);
  }

  /**
   * Retrieves the messages for the current locale
   *
   * @return {Object} Contains a key/value pair for
   * each localized string in the current locale
   */
  getLocaleMessages() {
    return i18n.messages[i18n.locale];
  }
}

const i18n = new I18n();
export default i18n;
