import { defineMessages } from 'react-intl';

export default defineMessages({
  'filter_by': {
    'id': 'analytics_filters.filter_by',
    'defaultMessage': 'FILTER BY',
    'description': 'Label for all filter options'
  },
  'analytics_by': {
    'id': 'analytics_filters.analytics_by',
    'defaultMessage': 'ANALYTICS BY',
    'description': 'Label to show analytics by products or sessions'
  },
  'SESSIONS': {
    'id': 'analytics_filters.sessions',
    'defaultMessage': 'SESSIONS',
    'description': 'Option to show analytics by sessions'
  },
  'PRODUCTS': {
    'id': 'analytics_filters.products',
    'defaultMessage': 'PRODUCTS',
    'description': 'Option to show analytics by products'
  },
});
