import {
    RECEIVE_JWT_ERROR,
    RECEIVE_JWT_SUCCESS,
    REQUEST_JWT,
} from '../types/actions';
import * as statusTypes from '../types/statuses';
import { isAuthenticated } from '../api/tokens';
import { SIGN_OUT } from '../types/actions';

export const initialState = {
    fetchingStatus: null,
    isAuthenticated: isAuthenticated(),
};

function requestLogin(state) {
    return {
        ...state,
        fetchingStatus: statusTypes.REQUESTING,
    };
}

function receiveJWTSuccess(state) {
    return {
        ...state,
        fetchingStatus: statusTypes.RECEIVED,
        isAuthenticated: isAuthenticated(),
    };
}

function receiveJWTError(state, action) {
    return {
        ...state,
        fetchingStatus: statusTypes.FAILED,
        errors: action.error.non_field_errors || action.error.errors,
    };
}

function signOut(state) {
    return {
        ...state,
        isAuthenticated: isAuthenticated(),
    };
}

export default function login(state = initialState, action) {
    switch (action.type) {
        case REQUEST_JWT:
            return requestLogin(state);
        case RECEIVE_JWT_SUCCESS:
            return receiveJWTSuccess(state);
        case RECEIVE_JWT_ERROR:
            return receiveJWTError(state, action);
        case SIGN_OUT:
            return signOut(state);
        default:
            return state;
    }
}
