import React, { Component } from 'react';
import { scaleBand, scaleLinear } from 'd3-scale';
import Bars from './Bars/Bars';
import Axis from './Axis/Axis';
import { max } from "d3-array";
import { getDimensions } from "../../../utils/chartDimensions";
import { updatedWidthState } from '../../../utils/chartHelper';
import componentWithErrorBoundary from '../../../componentsHighOrder/componentWithErrorBoundary';

export default class VerticalBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: 400,
        height: 240,
        margin: {
          top: 20,
          bottom: 90,
          right: 10,
          left: 35
        }
      }
    };
  }

  componentDidMount() {
    const { parentWidth } = this.props;
    const { width } = getDimensions(this.state);
    if (parentWidth && parentWidth !== width) {
      this.setState(updatedWidthState(this.state, parentWidth));
    }
  }

  componentDidUpdate() {
    const { parentWidth } = this.props;
    const { width } = getDimensions(this.state);
    if (parentWidth && parentWidth !== width) {
      this.setState(updatedWidthState(this.state, parentWidth));
    }
  }


  adjustedHeight() {
    const { height, margin } = getDimensions(this.state);
    return height - margin.top - margin.bottom
  }

  adjustedWidth() {
    const { width, margin } = getDimensions(this.state);
    return width - margin.left - margin.right;
  }

  yRange() {
    return [ this.adjustedHeight(), 0 ];
  }

  xRange() {
    return [ 0, this.adjustedWidth() ]
  }

  yDomain() {
    return [ 0, max(this.props.getQuantities()) ]
  }

  xDomain() {
    return this.props.getItems();
  }

  isDateCollection(data){
    return !!data.find(item =>  Object.prototype.toString.call(item) === '[object Date]')
  }

  timeFormat(data){
    return this.isDateCollection(data) ? "%b %d" : null;
  }

  renderXAxis(xScale) {
    const translateX = xScale.bandwidth() / 5 * -2;
    return (
      <Axis
        orientation="Bottom"
        scale={ xScale }
        ticks={ this.props.getItems().length }
        tickSize={ 5 }
        translate={ `translate(${translateX}, ${this.adjustedHeight()})` }
        timeFormatType={this.timeFormat(this.props.getItems())}
      />
    )
  }


  renderYAxis(yScale) {
    const { width } = getDimensions(this.state);
    return (
      componentWithErrorBoundary(
      <Axis
        orientation="Left"
        scale={ yScale }
        ticks={ 5 }
        tickSize={ -width }
      />
    ))
  }

  renderBars(scales) {
    return (
      <Bars
        scales={ scales }
        data={ this.props.data }
        upperLimit={ this.adjustedHeight() }
        width={ scales.xScale.bandwidth() }
      />
    )
  }

  calculateYScale() {
    return scaleLinear()
      .domain(this.yDomain())
      .range(this.yRange())
  }

  calculateXScale() {
    return scaleBand()
      .padding(0.7)
      .domain(this.xDomain())
      .range(this.xRange())
  }

  render() {
    const scales = {
      xScale: this.calculateXScale(),
      yScale: this.calculateYScale()
    };

    return (
      <svg
        className='small-bar-chart'
      >
        <g transform={ `translate(10, 0)` }>
          <g
            transform={ `translate(${20}, ${0})` }
          >
          { this.renderYAxis(scales.yScale) }
          </g>
          <g
            transform={ `translate(${0}, ${0})` }
          >
          { this.renderBars(scales) }
          </g>
          <g
            transform={ `translate(${0}, ${0})` }
          >
          { this.renderXAxis(scales.xScale) }
          </g>
        </g>
      </svg>
    )
  }
}
