import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './Button.css';

export default class Button extends Component {
    renderLinkElement() {
        const { to, onClick, children } = this.props;

        return (
            <Link className={this.classNames} onClick={onClick} to={to}>
                {children}
            </Link>
        );
    }

    renderButtonElement() {
        const {
            onClick,
            type = 'button',
            children,
            disabled = false,
        } = this.props;

        return (
            <button
                onClick={onClick}
                type={type}
                className={this.classNames}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }

    render() {
        return this.props.to
            ? this.renderLinkElement()
            : this.renderButtonElement();
    }

    get classNames() {
        const { light, wide } = this.props;

        return classNames('Button', {
            Button_light: light,
            'Button_md-wide': wide,
        });
    }
}
