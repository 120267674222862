import React from 'react';
import Button from '../../components/Button/Button';
import messages from './messages';

const Confirm = ({intl, labels, selections, isConfirmed, handleConfirmation, sessionId, product, client}) => {

  const FAMILY = product === 'footbed'
    ?
    null
    :
    <li>
      {intl.formatMessage(messages[labels[2]])}
      <span className="subtitles">
              {selections[2]}
              </span>
    </li>;

  const MODEL = product === 'bike'
    ?
    <li>
      {intl.formatMessage(messages[labels[3]])}
      <span className="subtitles">
      {selections[3]}
      </span>
    </li>
    :
    product === 'footbed'
      ?
      <li>
        {intl.formatMessage(messages[labels[1]])}
        <span className="subtitles">
      {selections[1]}
      </span>
      </li>
      :
      <li>
        {intl.formatMessage(messages[labels[3]])}
        <span className="subtitles">
      {selections[3]}
      </span>
      </li>;

  const SIZE = product === 'bike'
    ?
    <li>
      {intl.formatMessage(messages[labels[4]])}
      <span className="subtitles">
      {selections[4]}
      </span>
    </li>
    :
    product === 'footbed'
      ?
      <li>
        {intl.formatMessage(messages[labels[2]])}
        <span className="subtitles">
      {selections[2]}
      </span>
      </li>
      :
      <li>
        {intl.formatMessage(messages[labels[4]])}
        <span className="subtitles">
      {selections[4]}
      </span>
      </li>;

  const confirmTransactionSummary = client !== 'Anonymous'
    ?
    <div className="confirm-transaction_summary">
      <p className="txt_light">
        {intl.formatMessage(messages.ratings_message, {data: client})}
      </p>
    </div>
    :
    null;

  const completeTransaction = () => {
    return (
      <div className="confirm-transaction">
        <div className="confirm-transaction_left">
          <ul className="confirm-transaction_labels">
            {FAMILY}
            {MODEL}
            {SIZE}
          </ul>
        </div>
        <div className="confirm-transaction_right">
          {confirmTransactionSummary}
          <Button
            onClick={handleConfirmation}>{intl.formatMessage(messages.complete_transaction)}</Button>
        </div>
      </div>
    )
  };

  const transactionCompleted = () => {
    return (
      <div className="transaction-completed_add-another">
        <div className="transaction-completed_confirmation">
          <div className="transaction-completed_icon"></div>
          <span className="transaction-completed_txt">{intl.formatMessage(messages.transaction_completed)}</span>
        </div>
        <Button
          to={`/sessions/${sessionId}`}
          className="back-to-session_link"
        >
          {intl.formatMessage(messages.back_to_sessions)}
        </Button>
      </div>
    )
  };

  return (
    <div className="transaction-completed">
      {isConfirmed ? transactionCompleted() : completeTransaction()}
    </div>
  )
};

export default Confirm;
