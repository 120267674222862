import { RECEIVE_CURRENT_USER, REQUEST_CURRENT_USER } from '../types/actions';
import * as statusTypes from '../types/statuses';
export const initialState = {
  fetchingStatus: null
};

function requestCurrentUser(state) {
  return { ...state, fetchingStatus: statusTypes.REQUESTING }
}

function receiveCurrentUser(state) {
  return {
    ...state,
    fetchingStatus: statusTypes.RECEIVED
  }
}

export default function currentUser(state = initialState, action) {
  switch (action.type) {
    case(REQUEST_CURRENT_USER):
      return requestCurrentUser(state);
    case(RECEIVE_CURRENT_USER):
      return receiveCurrentUser(state);
    default:
      return state;
  }
}