import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../actions/sessionDetails';
import * as sessionsSelectors from '../selectors/sessions';
import SessionDetails from '../components/SessionDetails/SessionDetails';
import Main from '../components/Main/Main';
import {RECEIVED} from '../types/statuses';


export class SessionDetailsContainer extends Component {
  componentWillMount() {
    this.fetchSessionData();
    this.fetchBikeTransactions();
    this.fetchSaddleTransactions();
    this.fetchShoeTransactions();
    this.fetchFootbedTransactions();
  }

  fetchSessionData() {
    const {fetchSession} = this.props;

    fetchSession(this.id);
  }

  fetchBikeTransactions() {
    const {fetchBikeTransactions} = this.props;

    fetchBikeTransactions(this.id);
  }

  fetchSaddleTransactions() {
    const {fetchSaddleTransactions} = this.props;

    fetchSaddleTransactions(this.id);
  }

  fetchShoeTransactions() {
    const {fetchShoeTransactions} = this.props;

    fetchShoeTransactions(this.id);
  }

  fetchFootbedTransactions() {
    const {fetchFootbedTransactions} = this.props;

    fetchFootbedTransactions(this.id);
  }

  getSessionTransactions(radix) {
    const allTransactionData = [
      ...this.props.saddleTransactionData.data,
      ...this.props.bikeTransactionData.data,
      ...this.props.shoeTransactionData.data,
      ...this.props.footbedTransactionData.data,
    ];

    return allTransactionData.filter(transaction => {
      return transaction.session === parseInt(this.id, radix)
    });
  }

  completeTransaction() {
    const {updateSession, fetchSession} = this.props;

    updateSession(this.id, {
      'manually_completed': true,
    }, data => {
      fetchSession(this.id);

      return {
        type: 'RECEIVE_SESSION_DATA',
        data
      };
    });
  }

  deleteTransaction(id, type) {
    const {deleteTransaction} = this.props;

    deleteTransaction(id, type, data => {
      window.location.reload();

      return {
        type: 'RECEIVE_SESSION_DATA',
        data
      };
    });
  }

  renderSession() {
    return !this.dataReceived ? null : (
      <SessionDetails
        {...this.props.sessionDetailsData.data}
        transactionData={this.getSessionTransactions()}
        completeTransaction={this.completeTransaction.bind(this)}
        deleteTransaction={this.deleteTransaction.bind(this)}
      />
    )
  }

  render() {
    return (
      <Main isLoading={!this.dataReceived}>
        {this.renderSession()}
      </Main>
    )
  }

  get dataReceived() {
    return (
      sessionsSelectors.getSessionFetchingStatus(this.props) === RECEIVED &&
      sessionsSelectors.getSessionFetchingStatusBike(this.props) === RECEIVED &&
      sessionsSelectors.getSessionFetchingStatusSaddle(this.props) === RECEIVED &&
      sessionsSelectors.getSessionFetchingStatusShoe(this.props) === RECEIVED &&
      sessionsSelectors.getSessionFetchingStatusFootbed(this.props) === RECEIVED
    )
  }

  get id() {
    return this.props.match.params.id;
  }
}

function mapStateToProps(state) {
  return state.sessionDetails
}

export default connect(mapStateToProps, actionCreators)(SessionDetailsContainer);
