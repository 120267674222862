import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import './NavigationLink.css'

export default class NavigationLink extends Component {
  render() {

    return (
      <NavLink
        {...this.props}
        activeClassName="active"
        className="NavigationLink"
      >
        {this.props.children}
      </NavLink>
    )
  }
}
