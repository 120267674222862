import React from 'react';

const ICON_ID = 'icon-x';

export default function (props) {
  return (
    <svg viewBox="0.604 0.604 17.812 17.812" aria-labelledby={ICON_ID}>
      <title id={ICON_ID}>X Icon</title>
      <g transform="translate(1.000000, 1.000000)">
        <path fill="none" strokeWidth="1.5" d="M0.135,16.887L16.887,0.135"/>
        <path fill="none" strokeWidth="1.5" d="M0.135,0.135l16.752,16.752"/>
      </g>
    </svg>
  );
}
