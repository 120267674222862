import React, {Component} from 'react';
import {
  renderDonutOrVerticalChart,
  renderHorizontalBarChart,
  renderLargeVerticalBarChart,
  renderVerticalBarChart,
  renderVerticalOrHorizontalBarChart
} from '../../utils/chartHelper';
import WrappedAnalyticsContainer from '../../containers/WrappedAnalyticsContainer';

import {SESSIONS} from '../../types/constants';
import * as selectors from '../../selectors/analyticsBySession';
import {injectIntl} from 'react-intl';
import messages from './messages';

class AnalyticsBySession extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.page = {
      'overTime': this.intl.formatMessage(messages.over_time),
      'byEmployee': this.intl.formatMessage(messages.by_employee),
      'byGender': this.intl.formatMessage(messages.by_gender),
      'dayOfWeek': this.intl.formatMessage(messages.day_of_week),
      'byProduct': this.intl.formatMessage(messages.by_product),
      'topTen': this.intl.formatMessage(messages.top_ten),
      'topTransactions': this.intl.formatMessage(messages.top_transactions),
    };
  }

  translateData(data) {
    return data.map(obj => {
      obj.item = this.intl.formatMessage(messages[obj.item]);

      return obj;
    });
  }

  renderSessionsOverTime() {
    const {data} = this.props;

    const sessionsOverTime = selectors.getSessionsOverTime(data).map(obj => {
      return {
        'item': this.intl.formatDate(obj.item, {'month': 'short', 'day': '2-digit'}),
        'quantity': obj.quantity
      };
    });

    return sessionsOverTime.length > 0 && renderLargeVerticalBarChart(this.page.overTime, sessionsOverTime);
  }

  renderSessionsByEmployee() {
    const {data} = this.props;
    const sessionsByEmployee = selectors.getSessionsByEmployee(data);

    return sessionsByEmployee.length > 0 && renderVerticalBarChart(this.page.byEmployee, sessionsByEmployee);
  }

  renderSessionsByGender() {
    const {data} = this.props;
    const sessionsByGender = selectors.getSessionsByGender(data);
    const translatedData = this.translateData(sessionsByGender);

    return sessionsByGender.length > 0 && renderDonutOrVerticalChart(this.page.byGender, translatedData);
  }

  renderSessionsByDayOfWeek() {
    const {data} = this.props;
    const sessionsByDayOfWeek = selectors.getSessionsByDayOfWeek(data);
    const translatedData = this.translateData(sessionsByDayOfWeek);

    return sessionsByDayOfWeek.length > 0 && renderVerticalBarChart(this.page.dayOfWeek, translatedData);
  }

  renderSessionsByProduct() {
    const {data} = this.props;
    const sessionsByProduct = selectors.getSessionsByProduct(data);
    const translatedData = this.translateData(sessionsByProduct);

    return sessionsByProduct.length > 0 && renderDonutOrVerticalChart(this.page.byProduct, translatedData);
  }

  renderTopTenSessionDates() {
    const {data} = this.props;
    const topTenSessionDates = selectors.getTopTenSessionDates(data).map(obj => {
      return {
        'item': this.intl.formatDate(obj.item, {'month': '2-digit', 'day': '2-digit', 'year': 'numeric'}),
        'quantity': obj.quantity
      };
    });

    return topTenSessionDates.length > 0 && renderHorizontalBarChart(this.page.topTen, topTenSessionDates, 'chart chart_horizontalBar');
  }

  renderTopTenSalesDates() {
    const {data} = this.props;
    const renderTopTenSalesDates = selectors.getTopTenSalesDates(data).map(obj => {
      return {
        'item': this.intl.formatDate(obj.item, {'month': 'short', 'day': '2-digit'}),
        'quantity': obj.quantity
      };
    });

    return renderTopTenSalesDates.length > 0 && renderVerticalOrHorizontalBarChart(this.page.topTransactions, renderTopTenSalesDates)
  }

  render() {
    return (
      <div className="Analytics">
        <div className="chart-group-container full">
          {this.renderSessionsOverTime()}
        </div>
        <div className="chart-group-container">
          {this.renderSessionsByEmployee()}

          {this.renderSessionsByGender()}
        </div>
        <div className="chart-group-container">
          {this.renderSessionsByDayOfWeek()}
          {this.renderSessionsByProduct()}
        </div>
        <div className="chart-group-container">
          {this.renderTopTenSessionDates()}

          {this.renderTopTenSalesDates()}
        </div>
      </div>
    )
  }
}

export default WrappedAnalyticsContainer(injectIntl(AnalyticsBySession), SESSIONS);
