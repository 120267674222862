import { REQUESTING, RECEIVED } from '../types/statuses';
import {
  POST_PASSWORD_RESET,
  RECEIVE_PASSWORD_RESET,
  RECEIVE_PASSWORD_RESET_ERROR,
} from '../types/actions/forgotPassword';

export const initialState = {
  forgotPasswordData: {
    data: null,
    fetchingStatus: null,
  }
};

function postPasswordReset(state) {
  const forgotPasswordData = {
    ...state.forgotPasswordData,
    fetchingStatus: REQUESTING
  };

  return { ...state, forgotPasswordData };
}

function receivePasswordReset(state, action) {
  const forgotPasswordData = {
    ...state.forgotPasswordData,
    fetchingStatus: RECEIVED,
    data: action.data
  };

  return { ...state, forgotPasswordData };
}

function receivePasswordResetError(state, action) {
  const forgotPasswordData = {
    data: state.forgotPasswordData.data,
    fetchingStatus: RECEIVED,
    errors: action.errors,
  };

  return { ...state, forgotPasswordData };
}

export default function clientProfile(state = initialState, action) {
  switch (action.type) {
    case POST_PASSWORD_RESET:
      return postPasswordReset(state);
    case RECEIVE_PASSWORD_RESET:
      return receivePasswordReset(state, action);
    case RECEIVE_PASSWORD_RESET_ERROR:
      return receivePasswordResetError(state, action);
    default:
      return state
  }
}
