import React, {Component} from 'react';
import ResourceCheckboxInput from '../../components/ResourceCheckboxInput/ResourceCheckboxInput';
import '../../assets/stylesheets/base/ControlGroups.css';
import './ResourceCheckboxGroup.css';

export default class ResourceCheckboxGroup extends Component {
  renderCheckboxes() {
    const {handleCheck, checkboxGroupName, checkboxes} = this.props;

    return checkboxes.map((checkbox, index) => {
        const {checked, labelText, value} = checkbox;

        return (
          <ResourceCheckboxInput
            checkboxGroupName={checkboxGroupName}
            checked={checked}
            labelText={labelText}
            handleCheck={handleCheck}
            key={index}
            value={value}
          />
        )
      }
    )
  }

  render() {
    const {displayedGroupName} = this.props;
    return (
      <div className="form-row">
        <div className="form-control form-control_light">
          <legend className="label">
            {displayedGroupName}
          </legend>
        </div>
        <div className="control-group">
          {this.renderCheckboxes()}
        </div>
      </div>
    )
  }
}
