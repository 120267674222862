import * as actionTypes from '../types/actions/employees'
import {fetchLocations} from '../actions/locations';
import {apiCreate, apiDelete, apiGet, apiPut} from '../api/apiClient';
import {employeesUrl, employeeUrl} from '../utils/urlHelper';
import {JSON_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';

export function createEmployeeRequested() {
  return {
    type: actionTypes.CREATE_EMPLOYEE_REQUESTED
  }
}

export function createEmployeeComplete() {
  return {
    type: actionTypes.CREATE_EMPLOYEE_COMPLETE
  }
}

export function createEmployeeFailed() {
  return {
    type: actionTypes.CREATE_EMPLOYEE_FAILED
  }
}

export function createEmployee(data) {
  return dispatch => {
    dispatch(createEmployeeRequested());
    return apiCreate(
      generateRequestConfigs(
        {
          body: JSON.stringify(data),
          dispatch,
          receiveCallback: createEmployeeComplete,
          url: employeesUrl()
        }
      )
    )
  }
}

export function deleteEmployee(employeeId) {
  return dispatch => {
    dispatch(deleteEmployeeRequested());

    return apiDelete(
      generateRequestConfigs({
          dispatch,
          receiveCallback: deleteEmployeeComplete,
          failCallback: deleteEmployeeFailed,
          url: employeeUrl(employeeId)
        }
      ))
  }
}

export function deleteEmployeeRequested() {
  return {
    type: actionTypes.DELETE_EMPLOYEE_REQUESTED
  }
}

export function deleteEmployeeComplete() {
  return {
    type: actionTypes.DELETE_EMPLOYEE_COMPLETE
  }
}

export function deleteEmployeeFailed() {
  return {
    type: actionTypes.DELETE_EMPLOYEE_FAILED
  }
}

export function fetchEmployee(employeeId) {
  return dispatch => {
    dispatch(requestEmployee());
    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: employeeUrl(employeeId),
          receiveCallback: receiveEmployee,
        }
      )
    )
  }
}

export function fetchEmployees() {
  return dispatch => {
    dispatch(fetchLocations());
    dispatch(requestEmployees());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: employeesUrl(),
          receiveCallback: receiveEmployees,
        }
      )
    )
  }
}

export function receiveEmployee(data) {
  return {
    data,
    type: actionTypes.RECEIVE_EMPLOYEE
  }
}

export function receiveEmployees(data) {
  return {
    data,
    type: actionTypes.RECEIVE_EMPLOYEES_DATA
  }
}

export function requestEmployee() {
  return {
    type: actionTypes.REQUEST_EMPLOYEE
  }
}

export function requestEmployees() {
  return {
    type: actionTypes.REQUEST_EMPLOYEES_DATA
  }
}

export function updateEmployee(data) {
  const {id} = data;
  return dispatch => {
    dispatch(updateEmployeeRequested());
    return apiPut(
      generateRequestConfigs({
        body: JSON.stringify(data),
        dispatch,
        parser: JSON_PARSER,
        failCallback: updateEmployeeFailed,
        receiveCallback: updateEmployeeComplete,
        url: employeeUrl(id)
      })
    )
  }
}

export function updateEmployeeRequested() {
  return {
    type: actionTypes.UPDATE_EMPLOYEE_REQUESTED
  }
}

export function updateEmployeeComplete(data) {
  return {
    data,
    type: actionTypes.UPDATE_EMPLOYEE_COMPLETE
  }
}

export function updateEmployeeFailed() {
  return {
    type: actionTypes.UPDATE_EMPLOYEE_FAILED
  }
}
