import React from 'react';
import classNames from "classnames";

export default function (props) {
  const {className, weight, height, align, tag, type, color, children} = props;
  const CustomTextTag = tag ? tag : 'span';
  const txtClasses = classNames(
    'txt',
    {
      [`txt_${type}`]: type,
      [`txt_${weight}`]: weight,
      [`mix-txt_${height}Height`]: height,
      [`mix-txt_${align}Align`]: align,
      [`mix-txt_color${color}`]: color
    },
    className
  );

  return (
    <CustomTextTag className={txtClasses}>
      {children}
    </CustomTextTag>
  );
}
