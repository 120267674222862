export const CREATE_LOCATION_COMPLETE = 'CREATE_LOCATION_COMPLETE';
export const CREATE_LOCATION_FAILED = 'CREATE_LOCATION_FAILED';
export const CREATE_LOCATION_REQUESTED = 'CREATE_LOCATION_REQUESTED';
export const DELETE_LOCATION_COMPLETE = 'DELETE_LOCATION_COMPLETE';
export const DELETE_LOCATION_FAILED = 'DELETE_LOCATION_FAILED';
export const DELETE_LOCATION_REQUESTED = 'DELETE_LOCATION_REQUESTED';
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION';
export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const RECEIVE_LOCATIONS_DATA = 'RECEIVE_LOCATIONS_DATA';
export const REQUEST_LOCATIONS_DATA = 'REQUEST_LOCATIONS_DATA';
export const UPDATE_LOCATION_COMPLETE = 'UPDATE_LOCATION_COMPLETE';
export const UPDATE_LOCATION_FAILED = 'UPDATE_LOCATION_FAILED';
export const UPDATE_LOCATION_REQUESTED = 'UPDATE_LOCATION_REQUESTED';