import React, {Component} from 'react';
import './Checkbox.css';

export default class Checkbox extends Component {
  render() {
    const {name, labelText, checked, onChange, value} = this.props;
    return (
      <label className="control control--checkbox">
        <div className="filter-label-content">
          {labelText}
        </div>
        <input
          checked={checked}
          type="checkbox"
          name={`${name}[]`}
          onChange={() => onChange(value)}
          value={value}
        />
        <div className="control__indicator"/>
      </label>
    )
  }
}
