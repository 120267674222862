import React from 'react';
import {getSessionsFiltersGroup} from '../selectors/sessions';
import {
  ALL,
  CLIENT_FILTERS,
  EMPLOYEE,
  RATING_FILTERS,
  SESSION_FILTERS,
  SHOP,
  STATUS_FILTERS
} from '../types/searchFilters';
import messages from '../components/SessionsSearchFiltersGroup/messages';

export function generateFilterLabels(filterItems, intl) {
  if (intl) {
    return filterItems.map(obj => {
      return {
        value: obj.value,
        element: generateFilterLabelText(intl.formatMessage(messages[obj.label]))
      }
    });
  }
  else {
    return filterItems.map(text => {
      return {
        value: text,
        element: generateFilterLabelText(text)
      }
    });
  }
}

export function generateReceivedFilterLables(filterItems, intl) {
  const generatedLabels = filterItems.map(filterItem => {
    const [value, text] = filterItem;
    return {
      value,
      element: generateFilterLabelText(text)
    }
  });

  return [{
    value: ALL,
    element: generateFilterLabelText(intl.formatMessage(messages.all_label))
  }].concat(generatedLabels)
}

function generateRatingElement(key = null) {
  if (key) {
    return (
      <i key={key} className="rating-star"/>
    )
  } else {
    return (<i className="rating-star"/>)
  }
}

function generateRatingElementLabel(quantity) {
  const ratingElements = Array(quantity)
    .fill(quantity)
    .map((num, index) => {
      const key = `rating-${quantity}-${index}`;
      return generateRatingElement(key)
    });

  return (
    <React.Fragment>
      {ratingElements}
    </React.Fragment>
  )
}

function generateRatingElements(quantity) {
  return (
    {
      value: quantity,
      element: {
        ...generateRatingElementLabel(quantity)
      }
    }
  )
}

function generateFilterLabelText(text) {
  return (
    <span className="filter-label-text">
      {text}
    </span>
  )
}

export function generateRatingFilters(intl) {
  const textFilters = generateFilterLabels(RATING_FILTERS.textLabels, intl);
  const scoreFilters = RATING_FILTERS.scores.map(scoreFilter => generateRatingElements(scoreFilter));
  return [...textFilters, ...scoreFilters];
}

export function generateClientFilters(intl) {
  return generateFilterLabels(CLIENT_FILTERS, intl);
}

export function generateEmployeeFilters(sessionsFilters, intl) {
  const employeeFilters = getSessionsFiltersGroup(sessionsFilters, EMPLOYEE);

  // Default to email if name is not given
  const filters = employeeFilters.map(obj => {
    const val = obj.name || obj.email;

    return [obj.id, val];
  });

  return generateReceivedFilterLables(filters, intl);
}

export function generateLocationFilters(sessionsFilters, intl) {
  const locationFilters = getSessionsFiltersGroup(sessionsFilters, SHOP);
  return generateReceivedFilterLables(locationFilters, intl)
}

export function generateSessionFilters(intl) {
  return generateFilterLabels(SESSION_FILTERS, intl);
}

export function generateStatusFilters(intl) {
  return generateFilterLabels(STATUS_FILTERS, intl);
}
