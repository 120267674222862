export const CREATE_EMPLOYEE_COMPLETE = 'CREATE_EMPLOYEE_COMPLETE';
export const CREATE_EMPLOYEE_FAILED = 'CREATE_EMPLOYEE_FAILED';
export const CREATE_EMPLOYEE_REQUESTED = 'CREATE_EMPLOYEE_REQUESTED';
export const DELETE_EMPLOYEE_COMPLETE = 'DELETE_EMPLOYEE_COMPLETE';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';
export const DELETE_EMPLOYEE_REQUESTED = 'DELETE_EMPLOYEE_REQUESTED';
export const RECEIVE_EMPLOYEE = 'RECEIVE_EMPLOYEE';
export const REQUEST_EMPLOYEE = 'REQUEST_EMPLOYEE';
export const RECEIVE_EMPLOYEES_DATA = 'RECEIVE_EMPLOYEES_DATA';
export const REQUEST_EMPLOYEES_DATA = 'REQUEST_EMPLOYEES_DATA';
export const UPDATE_EMPLOYEE_COMPLETE = 'UPDATE_EMPLOYEE_COMPLETE';
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED';
export const UPDATE_EMPLOYEE_REQUESTED = 'UPDATE_EMPLOYEE_REQUESTED';