import React, {Component} from 'react';
import makeHash from '../../utils/makeHash';
import Badge from '../../components/Badge/Badge';
import IconCheck from '../../components/Icons/IconCheck';
import IconX from '../../components/Icons/IconX';
import {injectIntl} from 'react-intl';
import messages from './messages';

class ClientProgress extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.page = {
      'clientProgress': this.intl.formatMessage(messages.client_progress),
      'typesComplete': this.intl.formatMessage(messages.types_complete)
    };
  }

  renderSteps() {
    const {sessions} = this.props.data;

    return sessions.map(step => {
      const icon = step.id ? <IconCheck/> : <IconX/>;
      const color = step.id ? 'DefaultBlue' : 'DarkGray';

      return (
        <li key={makeHash(`${step.name}`)}>
          <Badge
            label={this.intl.formatMessage(messages[step.name])}
            color={color}
            icon={icon}
          />
        </li>
      );
    });
  }

  render() {
    const {completed_types} = this.props.data;

    return (
      <div className="box">
        <h2 className="txt mix-txt_uppercase">{this.page.clientProgress}</h2>
        <div className="box box_top1">
          <span className="txt txt_lg mix-txt_uppercase">{completed_types}/3 {this.page.typesComplete}</span>
        </div>
        <div className="box box_top2">
          <ul className="vList vList_2">
            {this.renderSteps()}
          </ul>
        </div>
      </div>
    )
  }
}

export default injectIntl(ClientProgress);
