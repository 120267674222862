import * as actionTypes from '../types/actions'
import {JSON_PARSER, SESSIONS} from '../types/constants';
import {apiGet} from '../api/apiClient';
import {generateRequestConfigs} from '../utils/fetchHelper';
import {fetchProductsAnalyticsUrl, fetchSessionsAnalyticsUrl} from '../utils/urlHelper';

export function requestProductsAnalytics() {
  return {
    type: actionTypes.REQUEST_ANALYTICS_BY_PRODUCT_DATA
  }
}

export function receiveProductsAnalytics(data) {
  return {
    type: actionTypes.RECEIVE_ANALYTICS_BY_PRODUCT_DATA,
    data
  }
}

export function fetchAnalytics(analyticsType, searchParams = {}) {
  console.log("FetchAnalytics", searchParams);
  if (analyticsType === SESSIONS) {
    return dispatch => {
      dispatch(fetchSessionsAnalytics(searchParams))
    }
  } else {
    return dispatch => {
      dispatch(fetchProductsAnalytics(searchParams))
    }
  }
}

export function fetchSessionsAnalytics(searchParams = {}) {
  return dispatch => {

    dispatch(requestSessionsAnalytics());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveSessionsAnalytics,
          url: fetchSessionsAnalyticsUrl(searchParams)
        }
      )
    )
  }
}

export function fetchProductsAnalytics(searchParams = {}) {
  return dispatch => {

    dispatch(requestProductsAnalytics());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveProductsAnalytics,
          url: fetchProductsAnalyticsUrl(searchParams)
        }
      )
    )
  }
}

export function requestSessionsAnalytics() {
  return {
    type: actionTypes.REQUEST_ANALYTICS_BY_SESSION_DATA
  }
}

export function receiveSessionsAnalytics(data) {
  return {
    type: actionTypes.RECEIVE_ANALYTICS_BY_SESSION_DATA,
    data
  }
}

export function updateAnalyticsFilters(data) {
  return {
    type: actionTypes.UPDATE_ANALYTICS_FILTERS,
    data: data
  }
}
