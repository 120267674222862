import {
  CREATE_TRANSACTION_COMPLETE,
  CREATE_TRANSACTION_FAILED,
  CREATE_TRANSACTION_REQUESTED,
  RECEIVE_BIKE_FAMILIES, RECEIVE_BIKE_MODELS,
  RECEIVE_BIKE_SIZES,
  RECEIVE_BIKE_TYPES, RECEIVE_FOOTBED_MODELS,
  RECEIVE_SADDLE_FAMILIES,
  RECEIVE_SADDLE_MODELS,
  RECEIVE_SADDLE_SIZES,
  RECEIVE_SADDLE_TYPES,
  RECEIVE_SHOE_FAMILIES,
  RECEIVE_SHOE_MODELS,
  RECEIVE_SHOE_SIZES,
  RECEIVE_SHOE_TYPES,
  REQUEST_BIKE_FAMILIES, REQUEST_BIKE_MODELS,
  REQUEST_BIKE_SIZES,
  REQUEST_BIKE_TYPES, REQUEST_FOOTBED_MODELS,
  REQUEST_SADDLE_FAMILIES,
  REQUEST_SADDLE_MODELS,
  REQUEST_SADDLE_SIZES,
  REQUEST_SADDLE_TYPES,
  REQUEST_SHOE_FAMILIES,
  REQUEST_SHOE_MODELS,
  REQUEST_SHOE_SIZES,
  REQUEST_SHOE_TYPES,
} from '../types/actions/transactions';

import {COMPLETED, FAILED, RECEIVED, REQUESTING} from '../types/statuses';

export const initialState = {
  productInfo: {
    data: null,
    fetchingStatus: null
  }
};

function requestBikeTypes(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveBikeTypes(state, action) {
  const bikeType = [...action.bikeType];
  return {...state, fetchingStatus: RECEIVED, bikeType}
}

function requestBikeFamilies(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveBikeFamilies(state, action) {
  const bikeFamily = [...action.bikeFamily];
  return {...state, fetchingStatus: RECEIVED, bikeFamily}
}


function requestBikeModels(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveBikeModels(state, action) {
  const bikeModels = {...action.bikeModels};
  return {...state, fetchingStatus: RECEIVED, bikeModels}
}

function requestSaddleTypes(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveSaddleTypes(state, action) {
  const saddleType = [...action.saddleType];
  return {...state, fetchingStatus: RECEIVED, saddleType}
}

function requestSaddleFamilies(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveSaddleFamilies(state, action) {
  const saddleFamily = [...action.saddleFamily];
  return {...state, fetchingStatus: RECEIVED, saddleFamily}
}

function requestSaddleModels(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveSaddleModels(state, action) {
  console.log(action.saddleModel);
  const saddleModel = [...action.saddleModel];
  return {...state, fetchingStatus: RECEIVED, saddleModel}
}

function requestSaddleSizes(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveSaddleSizes(state, action) {
  const saddleSizes = [...action.saddleSizes];
  return {...state, fetchingStatus: RECEIVED, saddleSizes}
}

function requestShoeTypes(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveShoeTypes(state, action) {
  const shoeType = [...action.shoeType];
  return {...state, fetchingStatus: RECEIVED, shoeType}
}

function requestShoeFamilies(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveShoeFamilies(state, action) {
  const shoeFamily = [...action.shoeFamily];
  return {...state, fetchingStatus: RECEIVED, shoeFamily}
}

function requestShoeModels(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveShoeModels(state, action) {
  const shoeModel = [...action.shoeModel];
  return {...state, fetchingStatus: RECEIVED, shoeModel}
}

function requestShoeSizes(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveShoeSizes(state, action) {
  const shoeSizes = [...action.shoeSizes];
  return {...state, fetchingStatus: RECEIVED, shoeSizes}
}


function requestFootbedModels(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveFootbedModels(state, action) {
  const footbedModels = [...action.footbedModels];
  return {...state,  fetchingStatus: RECEIVED, footbedModels}
}


function requestBikeSizes(state) {
  return {...state, fetchingStatus: REQUESTING}
}

function receiveBikeSizes(state, action) {
  const bikeSize = [...action.bikeSize];
  return {...state, fetchingStatus: RECEIVED, bikeSize}
}

function setCreateStatus(state, status) {
  const transaction = {...state.transaction, createStatus: status};
  return {...state, transaction}
}

export default function productInfo(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BIKE_TYPES:
      return requestBikeTypes(state);
    case RECEIVE_BIKE_TYPES:
      return receiveBikeTypes(state, action);
    case REQUEST_BIKE_FAMILIES:
      return requestBikeFamilies(state);
    case RECEIVE_BIKE_FAMILIES:
      return receiveBikeFamilies(state, action);
    case REQUEST_BIKE_MODELS:
      return requestBikeModels(state);
    case RECEIVE_BIKE_MODELS:
      return receiveBikeModels(state, action);
    case REQUEST_BIKE_SIZES:
      return requestBikeSizes(state);
    case RECEIVE_BIKE_SIZES:
      return receiveBikeSizes(state, action);
    case REQUEST_SADDLE_TYPES:
      return requestSaddleTypes(state);
    case RECEIVE_SADDLE_TYPES:
      return receiveSaddleTypes(state, action);
    case REQUEST_SADDLE_FAMILIES:
      return requestSaddleFamilies(state);
    case RECEIVE_SADDLE_FAMILIES:
      return receiveSaddleFamilies(state, action);
    case REQUEST_SADDLE_MODELS:
      return requestSaddleModels(state);
    case RECEIVE_SADDLE_MODELS:
      return receiveSaddleModels(state, action);
    case REQUEST_SADDLE_SIZES:
      return requestSaddleSizes(state);
    case RECEIVE_SADDLE_SIZES:
      return receiveSaddleSizes(state, action);
    case REQUEST_SHOE_TYPES:
      return requestShoeTypes(state);
    case RECEIVE_SHOE_TYPES:
      return receiveShoeTypes(state, action);
    case REQUEST_SHOE_FAMILIES:
      return requestShoeFamilies(state);
    case RECEIVE_SHOE_FAMILIES:
      return receiveShoeFamilies(state, action);
    case REQUEST_SHOE_MODELS:
      return requestShoeModels(state);
    case RECEIVE_SHOE_MODELS:
      return receiveShoeModels(state, action);
    case REQUEST_SHOE_SIZES:
      return requestShoeSizes(state);
    case RECEIVE_SHOE_SIZES:
      return receiveShoeSizes(state, action);
    case REQUEST_FOOTBED_MODELS:
      return requestFootbedModels(state);
    case RECEIVE_FOOTBED_MODELS:
      return receiveFootbedModels(state, action);
    case CREATE_TRANSACTION_REQUESTED:
      return setCreateStatus(state, REQUESTING);
    case CREATE_TRANSACTION_COMPLETE:
      return setCreateStatus(state, COMPLETED);
    case CREATE_TRANSACTION_FAILED:
      return setCreateStatus(state, FAILED);
    default:
      return state;
  }
}
