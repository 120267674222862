import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actionCreators from '../actions/transactions';
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import AddTransactionForm from '../components/AddTransactionForm/AddTransactionForm';

export class AddTransactionFormContainer extends Component {
  constructor(props) {
    super(props);

    this.createTransaction = this.createTransaction.bind(this);
  }

  createTransaction(data) {
    const {createTransaction} = this.props;
    createTransaction(data)
  }

  render() {
    const {data} = this.props;

    return (
      componentWithErrorBoundary(
        <AddTransactionForm
          data={data}
          createTransaction={this.createTransaction}
        />
      )
    )
  }
}

// const mapStateToProps = state => ({
//   authentication: state.authentication,
//   productInfo: state.productInfo
// });

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    productInfo: state.productInfo
  }
}

export default connect(mapStateToProps, actionCreators)(AddTransactionForm);
