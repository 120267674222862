import React, {Component} from 'react';
import makeHash from '../../utils/makeHash';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import messages from './messages';
import ClientProductContainer from "../../containers/ClientProductContainer";

class ClientSessions extends Component {
  constructor(props) {
    super(props);

    const {intl} = props;

    this.page = {
      'clientSessions': intl.formatMessage(messages.client_sessions)
    };
  }

  renderSessionList() {
    return this.props.sessions.map(session => {
      const {name, id} = session;

      return session.id ? (
        <li key={makeHash(`${ name }`)}>
          <Link to={`/sessions/${ id }`} className="txt txt_link">
            {`#${ id }`}
          </Link>
        </li>
      ) : null;
    });
  }

  render() {
    return (
      <div className="box box_top10">
        <h2 className="txt mix-txt_uppercase">{this.page.clientSessions}</h2>
        <div className="box box_top2">
          <ul className="vList vList_2">
            {this.renderSessionList()}
          </ul>
        </div>
        <ClientProductContainer
          clientId={this.props.clientId}
        />
      </div>
    );
  }
}

export default injectIntl(ClientSessions);
