import * as actionTypes from '../types/actions';
import * as statusTypes from '../types/statuses';
import { DATE_RANGE, PRODUCTS, SESSIONS } from '../types/constants';
import moment from 'moment';

export const initialState = {
    data: null,
    displayAnalytics: null,
    fetchingStatus: null,
    searchFilters: {
        [DATE_RANGE]: `${moment()
            .subtract(1, 'years')
            .format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`,
    },
};

function updateAnalyticsFilters(state, action) {
    const filterItems = {};

    Object.keys(action.data).forEach((key) => {
        filterItems[key] = action.data[key];
    });

    const searchFilters = { ...state.searchFilters, ...filterItems };

    return { ...state, searchFilters };
}

function requestAnalyticsData(state, analyticsType) {
    return {
        ...state,
        fetchingStatus: statusTypes.REQUESTING,
        displayAnalytics: analyticsType,
    };
}

function receiveAnalyticsData(state, action, analyticsType) {
    return {
        ...state,
        fetchingStatus: statusTypes.RECEIVED,
        data: action.data,
        displayAnalytics: analyticsType,
    };
}

export default function analytics(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_ANALYTICS_BY_SESSION_DATA:
            return requestAnalyticsData(state, SESSIONS);
        case actionTypes.RECEIVE_ANALYTICS_BY_SESSION_DATA:
            return receiveAnalyticsData(state, action, SESSIONS);
        case actionTypes.RECEIVE_ANALYTICS_BY_PRODUCT_DATA:
            return receiveAnalyticsData(state, action, PRODUCTS);
        case actionTypes.REQUEST_ANALYTICS_BY_PRODUCT_DATA:
            return requestAnalyticsData(state, PRODUCTS);
        case actionTypes.UPDATE_ANALYTICS_FILTERS:
            return updateAnalyticsFilters(state, action);
        default:
            return state;
    }
}
