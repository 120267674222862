import { combineReducers } from 'redux';
import analytics from './analytics';
import authentication from './authentication';
import employees from './employees';
import locations from './locations';
import currentUser from './currentUser';
import sessions from './sessions';
import sessionDetails from './sessionDetails';
import createAccount from './createAccount';
import clientProfile from './clientProfile';
import productInfo from './transactions';
import forgotPassword from './forgotPassword';
import sessionProducts from './sessionProducts';
import clientProducts from './clientProducts';
import verify from './verify';

const RootReducer = combineReducers({
    analytics,
    authentication,
    currentUser,
    employees,
    locations,
    sessions,
    sessionDetails,
    sessionProducts,
    createAccount,
    clientProfile,
    clientProducts,
    productInfo,
    forgotPassword,
    verify,
});

export default RootReducer;
