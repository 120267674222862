export const RECEIVE_SESSIONS_CSV_EXPORT = 'RECEIVE_SESSIONS_CSV_EXPORT';
export const RECEIVE_SESSIONS_DATA = 'RECEIVE_SESSIONS_DATA';
export const RECEIVE_SESSION_DATA = 'RECEIVE_SESSION_DATA';
export const RECEIVE_SESSION_PRODUCT_DATA = 'RECEIVE_SESSION_PRODUCT_DATA';
export const RECEIVE_CLIENT_PRODUCT_DATA = 'RECEIVE_CLIENT_PRODUCT_DATA';
export const REQUEST_SESSIONS_FILTERS = 'REQUEST_SESSIONS_FILTERS';
export const RECEIVE_SESSIONS_FILTERS = 'RECEIVE_SESSIONS_FILTERS';
export const REQUEST_SESSIONS_DATA = 'REQUEST_SESSIONS_DATA';
export const REQUEST_SESSION_DATA = 'REQUEST_SESSION_DATA';
export const REQUEST_SESSION_PRODUCT_DATA = 'REQUEST_SESSION_PRODUCT_DATA';
export const REQUEST_CLIENT_PRODUCT_DATA = 'REQUEST_CLIENT_PRODUCT_DATA';
export const REQUEST_SESSIONS_CSV_EXPORT = 'REQUEST_SESSIONS_CSV_EXPORT';
export const UPDATE_SESSIONS_SEARCH_PARAMS = 'UPDATE_SESSIONS_SEARCH_PARAMS';
export const REQUEST_BIKE_TRANSACTIONS = 'REQUEST_BIKE_TRANSACTIONS';
export const RECEIVE_BIKE_TRANSACTIONS = 'RECEIVE_BIKE_TRANSACTIONS';
export const REQUEST_SADDLE_TRANSACTIONS = 'REQUEST_SADDLE_TRANSACTIONS';
export const RECEIVE_SADDLE_TRANSACTIONS = 'RECEIVE_SADDLE_TRANSACTIONS';
export const REQUEST_SHOE_TRANSACTIONS = 'REQUEST_SHOE_TRANSACTIONS';
export const RECEIVE_SHOE_TRANSACTIONS = 'RECEIVE_SHOE_TRANSACTIONS';
export const REQUEST_FOOTBED_TRANSACTIONS = 'REQUEST_FOOTBED_TRANSACTIONS';
export const RECEIVE_FOOTBED_TRANSACTIONS = 'RECEIVE_FOOTBED_TRANSACTIONS';
export const REQUEST_BIKE_TYPES = 'REQUEST_BIKE_TYPES';
export const RECEIVE_BIKE_TYPES = 'RECEIVE_BIKE_TYPES';
export const UPDATE_BIKE_TYPE = 'UPDATE_BIKE_TYPE';