import React, {Component} from 'react';
import PieChart from './PieChart/PieChart';
import Legend from './Legend/Legend';
import {generateColorData, updatedDimensionsState} from '../../../utils/chartHelper';

export default class DonutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: 400,
        height: 240,
        margin: {
          top: 20,
          bottom: 90,
          right: 10,
          left: 35
        }
      }
    };
  }

  colorRange() {
    const {data} = this.props;
    return generateColorData(data);
  }

  updateIfNeeded() {
    const {parentWidth, parentHeight} = this.props;
    const {width, height} = this.state.dimensions;

    if (parentWidth !== width || parentHeight !== height) {
      this.setState((prevState) => {
        return updatedDimensionsState(this.state, parentWidth, parentHeight)
      });
    }
  }

  componentDidMount() {
    this.updateIfNeeded()
  }

  componentDidUpdate() {
    this.updateIfNeeded()
  }

  renderPieChart() {
    const {data} = this.props;
    const {width, height} = this.state.dimensions;

    return (
      <PieChart
        data={data}
        colorRange={this.colorRange()}
        width={width}
        height={height}
      />
    )
  }

  renderLegend() {
    const {data} = this.props;
    const {width, height} = this.state.dimensions;

    return (
      <Legend
        data={data}
        colorRange={this.colorRange()}
        height={height}
        width={width}
      />
    )
  }

  calculateTransformPieChartY() {
    const height = this.props.parentHeight;

    return height / 4;
  }

  calculateTransformLegendX() {
    const {width} = this.state.dimensions;

    const baseX = width * .3;
    return this.calculateTransformPieChartX() - baseX;
  }

  calculateTransformLegendY() {
    const {height} = this.state.dimensions;

    const baseY = .3 * height;
    return baseY - this.calculateTransformPieChartY();
  }

  render() {
    return (
      <svg
        className="donut-chart"
        viewBox={`0 0 250 250`}
      >

        <g
          transform={`translate(${10}, ${this.calculateTransformPieChartY()})`}
        >
          <g
          >
            {this.renderPieChart()}
          </g>

          <g>

            {this.renderLegend()}
          </g>
        </g>
      </svg>
    )
  }
}
