import {RECEIVED, REQUESTING} from '../types/statuses';
import {
  RECEIVE_CLIENT_DATA,
  RECEIVE_CLIENT_UPDATE,
  RECEIVE_CLIENT_UPDATE_ERROR,
  REQUEST_CLIENT_DATA,
  REQUEST_CLIENT_UPDATE,
} from '../types/actions/client';

export const initialState = {
  clientProfileData: {
    data: null,
    fetchingStatus: null,
  }
};

function requestClientData(state) {
  const clientProfileData = {
    ...state.clientProfileData,
    fetchingStatus: REQUESTING
  };

  return {...state, clientProfileData};
}

function receiveClientData(state, action) {
  const clientProfileData = {
    ...state.clientProfileData,
    fetchingStatus: RECEIVED,
    data: action.data
  };

  return {...state, clientProfileData};
}

function requestClientUpdate(state) {
  const clientProfileData = {
    ...state.clientProfileData,
    fetchingStatus: REQUESTING
  };

  return {...state, clientProfileData};
}

function receiveClientUpdate(state, action) {
  const clientProfileData = {
    data: Object.assign({}, state.clientProfileData.data, action.data),
    fetchingStatus: RECEIVED
  };

  return {...state, clientProfileData};
}

function receiveClientUpdateError(state, action) {
  const clientProfileData = {
    data: state.clientProfileData.data,
    fetchingStatus: RECEIVED,
    errors: action.errors,
  };

  return {...state, clientProfileData};
}

export default function clientProfile(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CLIENT_DATA:
      return requestClientData(state);
    case RECEIVE_CLIENT_DATA:
      return receiveClientData(state, action);
    case REQUEST_CLIENT_UPDATE:
      return requestClientUpdate(state);
    case RECEIVE_CLIENT_UPDATE:
      return receiveClientUpdate(state, action);
    case RECEIVE_CLIENT_UPDATE_ERROR:
      return receiveClientUpdateError(state, action);
    default:
      return state
  }
}
