import { defineMessages } from 'react-intl';

export default defineMessages({
    over_time: {
        id: 'charts.sessions_over_time_label',
        defaultMessage: 'Sessions Over Time',
        description: 'Lable for the Sessions Over Time chart',
    },
    by_employee: {
        id: 'charts.sessions_by_employee_label',
        defaultMessage: 'Sessions By Employee',
        description: 'Lable for the Sessions By Employee chart',
    },
    by_gender: {
        id: 'charts.sessions_by_gender_label',
        defaultMessage: 'Sessions By Gender',
        description: 'Lable for the Sessions By Gender chart',
    },
    day_of_week: {
        id: 'charts.sessions_by_day_of_week_label',
        defaultMessage: 'Sessions By Day of Week',
        description: 'Lable for the Sessions By Day of Week chart',
    },
    by_product: {
        id: 'charts.sessions_by_product_label',
        defaultMessage: 'Sessions By Product',
        description: 'Lable for the Sessions By Product chart',
    },
    top_ten: {
        id: 'charts.top_ten_session_label',
        defaultMessage: 'Top Ten Session Dates',
        description: 'Lable for the Top Ten Sessions chart',
    },
    top_transactions: {
        id: 'charts.top_transactions_label',
        defaultMessage: 'Top Transactions Dates',
        description: 'Lable for the Top Transactions chart',
    },
    female: {
        id: 'charts.female_label',
        defaultMessage: 'female',
        description: 'Lable for female data in a chart',
    },
    male: {
        id: 'charts.male_label',
        defaultMessage: 'male',
        description: 'Lable for male data in a chart',
    },
    Monday: {
        id: 'charts.monday_label',
        defaultMessage: 'Monday',
        description: 'Lable for Monday data in a chart',
    },
    Tuesday: {
        id: 'charts.tuesday_label',
        defaultMessage: 'Tuesday',
        description: 'Lable for Tuesday data in a chart',
    },
    Wednesday: {
        id: 'charts.wednesday_label',
        defaultMessage: 'Wednesday',
        description: 'Lable for Wednesday data in a chart',
    },
    Thursday: {
        id: 'charts.thursday_label',
        defaultMessage: 'Thursday',
        description: 'Lable for Thursday data in a chart',
    },
    Friday: {
        id: 'charts.friday_label',
        defaultMessage: 'Friday',
        description: 'Lable for Friday data in a chart',
    },
    Saturday: {
        id: 'charts.saturday_label',
        defaultMessage: 'Saturday',
        description: 'Lable for Saturday data in a chart',
    },
    Sunday: {
        id: 'charts.sunday_label',
        defaultMessage: 'Sunday',
        description: 'Lable for Sunday data in a chart',
    },
    Bike: {
        id: 'charts.bike_label_sessions',
        defaultMessage: 'Bike',
        description: 'Lable for Bike data in a chart',
    },
    Saddle: {
        id: 'charts.saddle_label_sessions',
        defaultMessage: 'Saddle',
        description: 'Lable for Saddle data in a chart',
    },
    Shoe: {
        id: 'charts.shoe_label_sessions',
        defaultMessage: 'Shoe',
        description: 'Lable for Shoe data in a chart',
    },
});
