import React, {Component} from 'react';
import './EmployeesTable.css';
import {renderDeleteEmployeeModal, renderShopsList, renderUpdateEmployeeModal} from '../../utils/employeesHelper';
import {injectIntl} from 'react-intl';
import messages from '../../types/constantsMessages';
import employeeMessages from './messages';
import {sortObjects} from '../../utils/sortHelper';

class EmployeesTable extends Component {
  constructor(props) {
    super();

    const {intl} = props;

    this.page = {
      'editLink': intl.formatMessage(messages.form_edit_field_label),
      'deleteLink': intl.formatMessage(messages.form_delete_field_label),
      'employeeId': intl.formatMessage(employeeMessages.employee_id),
      'name': intl.formatMessage(messages['form.name_field_label']),
      'email': intl.formatMessage(messages['form.email_field_label']),
      'shop': intl.formatMessage(messages['form.shop_field_label']),
    };

    this.state = {
      openEditModal: false,
      openDeleteModal: false,
      modalData: props.employeesList.data[0],
      sortBy: 'name',
      sortAscending: true
    };

    this.openEditModal = this.openEditModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.editModalClosed = this.editModalClosed.bind(this);
    this.deleteModalClosed = this.deleteModalClosed.bind(this);
  }

  openEditModal(data) {
    this.setState({
      openEditModal: true,
      modalData: data
    });
  }

  openDeleteModal(data) {
    this.setState({
      openDeleteModal: true,
      modalData: data
    });
  }

  editModalClosed() {
    this.setState({
      openEditModal: false,
    });
  }

  deleteModalClosed() {
    this.setState({
      openDeleteModal: false,
    });
  }

  handleSortClick(e) {
    const {value} = e.target;

    this.setState({
      'sortBy': value,
      'sortAscending': this.state.sortBy === value ? !this.state.sortAscending : true
    });
  }

  sortEmployees(data) {
    const sortBy = this.state.sortBy;
    const direction = this.state.sortAscending ? 1 : -1;

    return sortObjects({data, sortBy, direction});
  }

  renderModalTrigger(text, onClick) {
    return (
      <div className="open-modal-link-container">
        <button
          className="modal-link"
          onClick={onClick}
        >
          {text}
        </button>
      </div>
    )
  }

  renderTableBody() {
    const {employeesList} = this.props;
    const {data} = employeesList;
    const employees = this.sortEmployees(data);

    return employees.map((d, i) => {
        const {id, email, name, shops} = d;

        return (
          <tr key={i}>
            <td className="id">
              {id}
            </td>
            <td>
              {name}
            </td>
            <td>
              {email}
            </td>
            <td>
              <div className="shops-list-container">
                {renderShopsList(shops)}
              </div>
            </td>
            <td>
              <div className="modify-resource-options">
                <div>
                  {this.renderModalTrigger(this.page.editLink, () => this.openEditModal(d))}
                </div>
                <div>
                  |
                </div>
                <div>
                  {this.renderModalTrigger(this.page.deleteLink, () => this.openDeleteModal(d))}
                </div>
              </div>
            </td>
          </tr>
        )
      }
    )
  }

  render() {
    return (
      <table className="Table Table_spaced">
        <thead>
        <tr>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="id"
            >
              {this.page.employeeId}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="name"
            >
              {this.page.name}
            </button>
          </th>
          <th>
            <button
              className="th_sortable"
              onClick={e => this.handleSortClick(e)}
              value="email"
            >
              {this.page.email}
            </button>
          </th>
          <th>{this.page.shop}</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {this.renderTableBody()}
        </tbody>
        {renderUpdateEmployeeModal(this.state.openEditModal, this.editModalClosed, this.state.modalData, this.props)}
        {renderDeleteEmployeeModal(this.state.openDeleteModal, this.deleteModalClosed, this.state.modalData, this.props)}
      </table>
    )
  }
}

export default injectIntl(EmployeesTable);
