import React, { Component } from 'react';
import {
    DATE_RANGE,
    GENDER,
    GENDER_SEARCH_FILTERS,
    STATUS,
    ALL,
    STATUS_SEARCH_FILTERS,
} from '../../types/constants';

import DropDown from '../../components/DropDown/DropDown';
import './AnalyticsSearchFiltersGroup.css';
import DatePicker from '../DatePicker/DatePicker';

export default class SearchFiltersGroup extends Component {
    updateSearchFilters(filterName, value) {
        this.props.updateSearchFilters(filterName, value);
    }

    renderGenderFilterItems() {
        const { searchFilters } = this.props;
        const defaultVal = searchFilters.GENDER || ALL;
        const displayedValue = GENDER_SEARCH_FILTERS.find((x) => {
            return x.value.includes(defaultVal);
        });

        return (
            <DropDown
                displayedValue={displayedValue.label}
                selectedValue={displayedValue.value}
                fieldName={GENDER}
                listItems={GENDER_SEARCH_FILTERS}
                handleItemClick={this.updateSearchFilters.bind(this, GENDER)}
            />
        );
    }

    renderStatusFilterItems() {
        const { searchFilters } = this.props;
        const defaultVal = searchFilters.STATUS || ALL;
        const displayedValue = STATUS_SEARCH_FILTERS.find((x) => {
            return x.value.includes(defaultVal);
        });

        return (
            <DropDown
                displayedValue={displayedValue.label}
                selectedValue={displayedValue.value}
                fieldName={STATUS}
                listItems={STATUS_SEARCH_FILTERS}
                handleItemClick={this.updateSearchFilters.bind(this, STATUS)}
            />
        );
    }

    renderCalendarFilter() {
        const { searchFilters } = this.props;

        return (
            <DatePicker
                // displayedValue={displayedValue.label}
                currentValues={searchFilters}
                handleItemClick={this.updateSearchFilters.bind(
                    this,
                    DATE_RANGE
                )}
            />
        );
    }

    render() {
        return (
            <div className="search-filter-button-group">
                <div className="search-filter dropdown-filter">
                    {this.renderGenderFilterItems()}
                </div>
                <div className="search-filter dropdown-filter">
                    {this.renderStatusFilterItems()}
                </div>
                <div className="search-filter datepicker-filter">
                    {this.renderCalendarFilter()}
                </div>
            </div>
        );
    }
}
