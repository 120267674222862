import React, {Component} from 'react';

export default class RadioButton extends Component {
  constructor(props) {
    super(props);
    this.toggleCheckedState = this.toggleCheckedState.bind(this);
    this.isChecked = this.isChecked.bind(this);
  }

  componentWillMount() {
    const {checked} = this.props;
    this.setState({
      checked: checked
    });
  }

  componentWillReceiveProps(nextProps) {
    const {checked} = nextProps;
    this.setState({
      checked: checked
    });
  }

  toggleCheckedState(value) {
    const {toggleCheckedState} = this.props;
    if (toggleCheckedState) {
      toggleCheckedState(value)
    } else {
      this.setState({
        checked: !this.state.checked
      });
    }
  }

  isChecked() {
    return this.state.checked;
  }

  render() {
    const {name, labelText, value} = this.props;
    return (
      <label className="control control--radio">
        <div className="filter-label-content">
          {labelText}
        </div>
        <input
          checked={this.isChecked()}
          type="radio"
          name={`${name}[]`}
          onChange={() => this.toggleCheckedState(value)}
        />
        <div
          className="control__indicator"
        />
      </label>
    )
  }
}