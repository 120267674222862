import React from 'react';
import Modal from 'react-modal';
import ModalClose from '../../components/Modal/ModalClose';
import LocalePicker from '../../components/LocalePicker/LocalePicker';

class LocalePickerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'modalIsOpen': false
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openModal && !this.state.modalIsOpen) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <Modal
        className={{
          base: 'modal',
          afterOpen: '',
          beforeClose: ''
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: '',
          beforeClose: ''
        }}
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
      >
        <ModalClose closeModal={() => this.closeModal()}/>
        <LocalePicker
          closeModal={() => this.closeModal()}
          onLocaleChange={this.props.onLocaleChange}
        />
      </Modal>
    )
  }
}

export default LocalePickerModal;
