import React from 'react';
import classNames from 'classnames';
import messages from './messages';

const TransactionLabel = ({ intl, translateStep, index, label, transactionStep, title, name, buttonSelected, step, handleChange }) => {
  const labelClassnames = classNames(
    'ProgressBarStepLabel',
    {
      ProgressBarStepLabel_active: name === label && buttonSelected,
      ProgressBarStepLabel_default: transactionStep === step,
      ProgressBarStepLabel_previous: transactionStep < step,
    }
  );
  const subtitleClass = classNames(
    'subtitle',
    {
      subtitle_previous: transactionStep < step,
    }
  );

  const subtitle = (translateStep && title) ? intl.formatMessage(messages[title]) : title;

  return (
    <div
      key={ `progressBarLabel-${index}` }
      className='parent-label'
    >
      <button
        onClick={ handleChange }
      >
        <span className={ labelClassnames }>
          { `${transactionStep}. ${label}` }
          <span className={ subtitleClass }>{ subtitle }</span>
        </span>
      </button>
    </div>
  )
};

export default TransactionLabel;
