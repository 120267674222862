import * as actionTypes from '../types/actions/sessions'
import {apiGet} from '../api/apiClient';
import {JSON_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';
import {bikeTypeUrl, fetchSessionProductUrl} from '../utils/urlHelper';
import {receiveBikeTypes, requestBikeTypes} from "./transactions";


export function fetchSessionProduct(sessionID, bikeType = 'road') {
  return dispatch => {
    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchSessionProductUrl(sessionID, bikeType),
          receiveCallback: receiveSessionProduct,
        }
      )
    )
  }
}

export function fetchBikeTypes() {
  return dispatch => {
    dispatch(requestBikeTypes());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          receiveCallback: receiveBikeTypes,
          url: bikeTypeUrl(),
        }
      )
    )
  }
}

export function receiveSessionProduct(data) {
  return {
    type: actionTypes.RECEIVE_SESSION_PRODUCT_DATA,
    data
  };
}

export function receiveUserProduct(data) {
  return {
    type: actionTypes.RECEIVE_SESSION_PRODUCT_DATA,
    data
  };
}


export function updateBikeType(data) {
  return {
    type: actionTypes.UPDATE_BIKE_TYPE,
    data: data
  }
}
