/* eslint-disable */
export const localeStrings = {
    it: {
        'add_transaction.back_to_sessions_label': 'Torna alle sessioni',
        'add_transaction.bike_label': 'bici',
        'add_transaction.commute_comfort_type': 'Pendolari-comfort',
        'add_transaction.commute_sport_type': 'Pendolari-sport',
        'add_transaction.complete_transaction_cta_label':
            'Completare la transazione',
        'add_transaction.confirm_label': 'confermare',
        'add_transaction.cyclocross_type': 'Ciclocross',
        'add_transaction.family_label': 'famiglia',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'soletta',
        'add_transaction.heading': 'Aggiungi transazione a {data}',
        'add_transaction.model_label': 'modello',
        'add_transaction.mountain_type': 'Mountain',
        'add_transaction.mtb_trail_type': 'Mountain (trail)',
        'add_transaction.mtb_xc_type': 'Mountain (cross-country)',
        'add_transaction.product_label': 'prodotto',
        'add_transaction.ratings_message':
            "Quando completi questa transazione, {data} riceverai un'email per valutare questo prodotto. Sarai in grado di vedere questa valutazione nell'elenco delle sessioni e nella pagina dei dettagli della sessione.",
        'add_transaction.road_type': 'Strada',
        'add_transaction.saddle_label': 'sella',
        'add_transaction.shoe_label': 'scarpa',
        'add_transaction.size_label': 'taglia',
        'add_transaction.transaction_complete_message':
            'Transazione completata.',
        'add_transaction.triathlon_type': 'Triathlon',
        'add_transaction.tt_tri_type': 'TT/Tri',
        'add_transaction.type_label': 'tipo',
        'analytics.loading_message':
            'Stiamo compilando i tuoi dati, questo potrebbe richiedere un paio di minuti',
        'analytics_filters.analytics_by': 'STATISTICHE PER',
        'analytics_filters.filter_by': 'FILTRA PER',
        'analytics_filters.products': 'PRODOTTI',
        'analytics_filters.sessions': 'SESSIONI',
        'charts.bike_label_products': 'Bici',
        'charts.bike_label_sessions': 'Bici',
        'charts.commute_comfort_label': 'Pendolari-comfort',
        'charts.commute_sport_label': 'Pendolari-sport',
        'charts.cyclocross_label': 'ciclocross',
        'charts.female_label': 'Donna',
        'charts.friday_label': 'Venerdì',
        'charts.high_arch_label': 'alto',
        'charts.low_arch_label': 'basso',
        'charts.male_label': 'Uomo',
        'charts.medium_arch_label': 'medio',
        'charts.monday_label': 'lunedì',
        'charts.mtb_trail_label': 'mtb-trail',
        'charts.mtb_xc_label': 'mtb-xc',
        'charts.road_label': 'strada',
        'charts.saddle_label_products': 'Sella',
        'charts.saddle_label_sessions': 'Sella',
        'charts.saturday_label': 'Sabato',
        'charts.sessions_by_bike_type_label': 'Sessioni per tipo di bici',
        'charts.sessions_by_day_of_week_label':
            'Sessioni per giorno della settimana',
        'charts.sessions_by_employee_label': 'Sessioni per dipendente',
        'charts.sessions_by_foot_arch_label': 'Sessioni per arco del piede',
        'charts.sessions_by_gender_label': 'Sessioni per genere',
        'charts.sessions_by_product_label': 'Sessioni per prodotto',
        'charts.sessions_by_saddle_height_label':
            'Sessioni per altezza della sella',
        'charts.sessions_by_saddle_size_label':
            'Sessioni per dimensioni della sella',
        'charts.sessions_by_shirt_bike_size_label':
            'Sessioni per taglia di bici (T-Shirt)',
        'charts.sessions_by_shoe_size_label': 'Sessioni per taglia di scarpe',
        'charts.sessions_by_sitbone_width_label':
            'Sessioni per larghezza ossa ischiatiche',
        'charts.sessions_by_standard_bike_size_label':
            'Sessioni per taglia di bici (Standard)',
        'charts.sessions_over_time_label': 'Sessioni nel tempo',
        'charts.shoe_label_products': 'Scarpa',
        'charts.shoe_label_sessions': 'Scarpa',
        'charts.sunday_label': 'Domenica',
        'charts.thursday_label': 'Giovedì',
        'charts.top_ten_session_label':
            'Date delle dieci sessioni più importanti',
        'charts.top_transactions_label':
            'Date delle transazioni più importanti',
        'charts.triathlon_label': 'Triathlon',
        'charts.tuesday_label': 'martedì',
        'charts.wednesday_label': 'mercoledì',
        'client.all_sessions_link': 'Tutte le sessioni',
        'client.bike_progress': 'Bici',
        'client.client_progress_heading': 'Progresso del cliente',
        'client.client_sessions_heading': 'Ultima sessione del cliente',
        'client.email': 'Email del cliente',
        'client.name': 'Nome del cliente',
        'client.not_applicable': 'Non Disp',
        'client.notes_label': 'Note',
        'client.progress_types_complete': 'Completa',
        'client.saddle_progress': 'Sella',
        'client.save_cta': 'Salva',
        'client.shoe_progress': 'Scarpa',
        'commute-comfort': 'Pendolari-comfort',
        'commute-sport': 'Pendolari-sport',
        'country.ad': 'Andorra',
        'country.ae': 'Emirati Arabi Uniti',
        'country.af': 'Afghanistan',
        'country.ag': 'Antigua e Barbuda',
        'country.ai': 'Anguilla',
        'country.al': 'Albania',
        'country.am': 'Armenia',
        'country.ao': 'Angola',
        'country.aq': 'Antartide',
        'country.ar': 'Argentina',
        'country.as': 'Samoa Americane',
        'country.at': 'Austria',
        'country.au': 'Australia',
        'country.aw': 'Aruba',
        'country.ax': 'Isole Åland',
        'country.az': 'Azerbaigian',
        'country.ba': 'Bosnia Erzegovina',
        'country.bb': 'Barbados',
        'country.bd': 'Bangladesh',
        'country.be': 'Belgio',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bulgaria',
        'country.bh': 'Bahrain',
        'country.bi': 'Burundi',
        'country.bj': 'Benin',
        'country.bl': 'San Bartolomeo',
        'country.bm': 'Bermuda',
        'country.bn': 'Brunei',
        'country.bo': 'Bolivia',
        'country.bq': 'Bonaire, Sint Eustatius e Saba',
        'country.br': 'Brasile',
        'country.bs': 'Bahamas',
        'country.bt': 'Bhutan',
        'country.bv': 'Isola Bouvet',
        'country.bw': 'Botswana',
        'country.by': 'Bielorussia',
        'country.bz': 'Belize',
        'country.ca': 'Canada',
        'country.cc': 'Isole Cocos (Keeling)',
        'country.cd': 'Congo (Repubblica Democratica del)',
        'country.cf': 'Repubblica Centrafricana',
        'country.cg': 'Congo',
        'country.ch': 'Svizzera',
        'country.ci': "Costa d'Avorio",
        'country.ck': 'Isole Cook',
        'country.cl': 'Cile',
        'country.cm': 'Camerun',
        'country.cn': 'Cina',
        'country.co': 'Colombia',
        'country.cr': 'Costa Rica',
        'country.cu': 'Cuba',
        'country.cv': 'Capo Verde',
        'country.cw': 'Curacao',
        'country.cx': 'Isola di Natale',
        'country.cy': 'Cipro',
        'country.cz': 'Repubblica Ceca',
        'country.de': 'Germania',
        'country.dj': 'Gibuti',
        'country.dk': 'Danimarca',
        'country.dm': 'Dominica',
        'country.do': 'Repubblica Dominicana',
        'country.dz': 'Algeria',
        'country.ec': 'Ecuador',
        'country.ee': 'Estonia',
        'country.eg': 'Egitto',
        'country.eh': 'Sahara Occidentale',
        'country.er': 'Eritrea',
        'country.es': 'Spagna',
        'country.et': 'Etiopia',
        'country.fi': 'Finlandia',
        'country.fj': 'Fiji',
        'country.fk': 'Isole Falkland [Malvinas]',
        'country.fm': 'Micronesia (Stati federati di)',
        'country.fo': 'Isole Faroe',
        'country.fr': 'Francia',
        'country.ga': 'Gabon',
        'country.gb': 'Regno Unito',
        'country.gd': 'Grenada',
        'country.ge': 'Georgia',
        'country.gf': 'Guiana francese',
        'country.gg': 'Guernsey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibilterra',
        'country.gl': 'Groenlandia',
        'country.gm': 'Gambia',
        'country.gn': 'Guinea',
        'country.gp': 'Guadeloupe',
        'country.gq': 'Guinea Equatoriale',
        'country.gr': 'Grecia',
        'country.gs': 'Georgia del sud e Isole Sandwich del Sud',
        'country.gt': 'Guatemala',
        'country.gu': 'Guam',
        'country.gw': 'Guinea-Bissau',
        'country.gy': 'Guyana',
        'country.hk': 'Hong Kong',
        'country.hm': 'Isole Heard e McDonald',
        'country.hn': 'Honduras',
        'country.hr': 'Croazia',
        'country.ht': 'Haiti',
        'country.hu': 'Ungheria',
        'country.id': 'Indonesia',
        'country.ie': 'Irlanda',
        'country.il': 'Israele',
        'country.im': 'Isola di Man',
        'country.in': 'India',
        'country.io': "Territorio Britannico Dell'Oceano Indiano",
        'country.iq': 'Iraq',
        'country.ir': 'Iran',
        'country.is': 'Islanda',
        'country.it': 'Italia',
        'country.je': 'Jersey',
        'country.jm': 'Giamaica',
        'country.jo': 'Giordania',
        'country.jp': 'Giappone',
        'country.ke': 'Kenia',
        'country.kg': 'Kirghizistan',
        'country.kh': 'Cambogia',
        'country.ki': 'Kiribati',
        'country.km': 'Comore',
        'country.kn': 'Saint Kitts e Nevis',
        'country.kp': 'Corea del Nord',
        'country.kr': 'Corea del Sud',
        'country.kw': 'Kuwait',
        'country.ky': 'Isole Cayman',
        'country.kz': 'Kazakistan',
        'country.la': 'Laos',
        'country.lb': 'Libano',
        'country.lc': 'Santa Lucia',
        'country.li': 'Liechtenstein',
        'country.lk': 'Sri Lanka',
        'country.lr': 'Liberia',
        'country.ls': 'Lesoto',
        'country.lt': 'Lituania',
        'country.lu': 'Lussemburgo',
        'country.lv': 'Lettonia',
        'country.ly': 'Libia',
        'country.ma': 'Marocco',
        'country.mc': 'Monaco',
        'country.md': 'Moldavia',
        'country.me': 'Montenegro',
        'country.mf': 'Saint Martin (parte francese)',
        'country.mg': 'Madagascar',
        'country.mh': 'Isole Marshall',
        'country.mk': 'Macedonia',
        'country.ml': 'Mali',
        'country.mm': 'Myanmar',
        'country.mn': 'Mongolia',
        'country.mo': 'Macao',
        'country.mp': 'Isole Marianne Settentrionali',
        'country.mq': 'Martinica',
        'country.mr': 'Mauritania',
        'country.ms': 'Montserrat',
        'country.mt': 'Malta',
        'country.mu': 'Mauritius',
        'country.mv': 'Maldive',
        'country.mw': 'Malawi',
        'country.mx': 'Messico',
        'country.my': 'Malesia',
        'country.mz': 'Mozambico',
        'country.na': 'Namibia',
        'country.nc': 'Nuova Caledonia',
        'country.ne': 'Niger',
        'country.nf': 'Isola di Norfolk',
        'country.ng': 'Nigeria',
        'country.ni': 'Nicaragua',
        'country.nl': 'Paesi Bassi',
        'country.no': 'Norvegia',
        'country.np': 'Nepal',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'Nuova Zelanda',
        'country.om': 'Oman',
        'country.pa': 'Panama',
        'country.pe': 'Perù',
        'country.pf': 'Polinesia Francese',
        'country.pg': 'Papua Nuova Guinea',
        'country.ph': 'Filippine',
        'country.pk': 'Pakistan',
        'country.pl': 'Polonia',
        'country.pm': 'Saint Pierre e Miquelon',
        'country.pn': 'Pitcairn',
        'country.pr': 'Puerto Rico',
        'country.ps': 'Palestina, Stato di',
        'country.pt': 'Portogallo',
        'country.pw': 'Palau',
        'country.py': 'Paraguay',
        'country.qa': 'Qatar',
        'country.re': 'Réunion',
        'country.ro': 'Romania',
        'country.rs': 'Serbia',
        'country.ru': 'Russia',
        'country.rw': 'Ruanda',
        'country.sa': 'Arabia Saudita',
        'country.sb': 'Isole Salomone',
        'country.sc': 'Seychelles',
        'country.sd': 'Sudan',
        'country.se': 'Svezia',
        'country.sg': 'Singapore',
        'country.sh': "Sant'Elena, Ascensione e Tristan da Cunha",
        'country.si': 'Slovenia',
        'country.sj': 'Svalbard e Jan Mayen',
        'country.sk': 'Slovacchia',
        'country.sl': 'Sierra Leone',
        'country.sm': 'San Marino',
        'country.sn': 'Senegal',
        'country.so': 'Somalia',
        'country.sr': 'Suriname',
        'country.ss': 'Sud Sudan',
        'country.st': 'Sao Tome e Principe',
        'country.sv': 'El Salvador',
        'country.sx': 'Sint Maarten (parte olandese)',
        'country.sy': 'Siria',
        'country.sz': 'Swaziland',
        'country.tc': 'Isole Turks e Caicos',
        'country.td': 'Chad',
        'country.tf': 'Territori Australi Francesi',
        'country.tg': 'Togo',
        'country.th': 'Tailandia',
        'country.tj': 'Tajikistan',
        'country.tk': 'Tokelau',
        'country.tl': 'Timor Est',
        'country.tm': 'Turkmenistan',
        'country.tn': 'Tunisia',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trinidad e Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Taiwan',
        'country.tz': 'Tanzania',
        'country.ua': 'Ucraina',
        'country.ug': 'Uganda',
        'country.um': 'Stati Uniti Isole Minori',
        'country.us': "Stati Uniti d'America",
        'country.uy': 'Uruguay',
        'country.uz': 'Uzbekistan',
        'country.va': 'Santa Sede',
        'country.vc': 'Saint Vincent e le Grenadine',
        'country.ve': 'Venezuela',
        'country.vg': 'Isole Vergini (Britanniche)',
        'country.vi': 'Isole Vergini (USA)',
        'country.vn': 'Vietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis e Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Yemen',
        'country.yt': 'Mayotte',
        'country.za': 'Sud Africa',
        'country.zm': 'Zambia',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Ciclocross',
        'date.all_time': 'DATA: TUTTI I PERIODI',
        'date.last_month': 'DATA: ULTIMO MESE',
        'date.last_week': 'DATA: ULTIMA SETTIMANA',
        'date.last_year': 'DATA: ULTIMO ANNO',
        'employees.add_cta_label': 'Aggiungi dipendente',
        'employees.delete_cta_label': 'Elimina dipendente',
        'employees.employee_id_label': 'ID dipendente',
        'employees.heading': 'Dipendenti',
        'employees.intro':
            'Una cronologia di tutti i tuoi ultimi dipendenti con tecnologia Retül.',
        'employees.update_cta_label': 'Aggiornare il dipendente',
        'filters.all_label': 'TUTTI',
        'filters.client_all': 'Tutti',
        'filters.client_anonymous': 'Anonimo',
        'filters.client_identified': 'Identificato',
        'filters.client_label': 'Tipo cliente',
        'filters.employees_label': 'Dipendenti',
        'filters.filters_heading': 'Filtri',
        'filters.hide_filters': 'Mostra filtri',
        'filters.locations_label': 'Posizioni',
        'filters.rating_all': 'TUTTI',
        'filters.rating_label': 'Valutazione',
        'filters.rating_none': 'NESSUNO',
        'filters.session_all': 'Tutti',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Tipo di sessione',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Mostra filtri',
        'filters.status_all': 'Tutti',
        'filters.status_closed': 'Chiuso',
        'filters.status_label': 'Stato',
        'filters.status_open': 'Aperto',
        'footer.privacy_link_label': 'Informativa sulla privacy',
        'footer.terms_link_label': 'Termini di utilizzo',
        'form.address_1_field_error': "L'indirizzo è richiesto.",
        'form.address_1_field_label': 'Indirizzo',
        'form.city_field_error': 'La città è richiesta.',
        'form.city_field_label': 'Città',
        'form.country_field_error': 'Il paese è richiesto.',
        'form.country_field_label': 'Paese',
        'form.delete_field_confirm_message':
            'Sei sicuro di voler eliminare {data}?',
        'form.delete_field_label': 'Elimina',
        'form.edit_field_label': 'Modifica',
        'form.email_field_error':
            "L'e-mail è richiesta e deve essere un formato email valido.",
        'form.email_field_label': 'email',
        'form.name_field_error': 'Il nome è richiesto.',
        'form.name_field_label': 'Nome',
        'form.shop_field_label': 'negozio',
        'form.shops_field_label': 'Negozi',
        'form.state_field_error': 'Lo stato è richiesto.',
        'form.state_field_label': 'Stato',
        'form.zip_field_error': "E' necessario indicare il C.A.P.",
        'form.zip_field_label': 'C.A.P.',
        'gender.all': 'SESSO: TUTTI',
        'gender.female': 'SESSO: DONNA',
        'gender.male': 'SESSO: UOMO',
        'landing.heading': 'Benvenuti sul Retailer Portal',
        'landing.intro':
            'Accesso a dati, sessioni e informazioni sui clienti rilevate con la tecnologia Retül.',
        'landing.sign_in_or_create_account_label': 'Accedi / Crea account',
        'locale.label_cs': '',
        'locale.label_de': '',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': '',
        'locale.label_fr': '',
        'locale.label_it': '',
        'locale.label_ja': '',
        'locale.label_ko': '',
        'locale.label_nl': '',
        'locale.label_pl': '',
        'locale.label_pt': '',
        'locale.label_pt-br': '',
        'locale.label_zh-cn': '',
        'locale.label_zh-tw': '',
        'locale.locale_picker_cancel_label': 'Cancella',
        'locale.locale_picker_heading': 'Scegli la tua lingua.',
        'locale.locale_picker_save_label': 'Salva',
        'locations.add_cta_label': 'Aggiungi posizione',
        'locations.delete_cta_label': 'Elimina posizione',
        'locations.empty':
            'Non è stata aggiunta alcuna posizione, per utilizzare le app Retül si prega di aggiungere una posizione.',
        'locations.empty_cta_label': 'Gestisci posizioni',
        'locations.heading': 'Gestisci posizioni',
        'locations.intro':
            'Aggiungi, modifica e rimuovi posizioni usando la tecnologia Retül.',
        'locations.location_id_label': 'ID posizione',
        'locations.update_cta_label': 'Aggiorna posizione',
        'metrics.closed_session_tooltip':
            'Le sessioni chiuse indicano sessioni in cui è stata registrata una transazione. Se un cliente ha effettuato o meno un acquisto, {closingSessions} ti aiuta a tenere traccia delle vendite grazie alla tecnologia Retül.',
        'metrics.closed_sessions': 'Sessioni chiuse',
        'metrics.closing_session_link': 'chiusura sessione',
        'metrics.total_sessions': 'Sessioni totali',
        'metrics.unique_client_emails': 'Indirizzi Email univoci',
        'metrics.unique_emails_tooltip':
            'Questo numero rappresenta le sessioni condotte con clienti univoci. I singoli clienti sono determinati dal loro indirizzo e-mail.',
        'mtb-trail': 'Mountain (trail)',
        'mtb-xc': 'Mountain (cross-country)',
        'nav.analytics': 'Analytics',
        'nav.greeting_label': 'Ciao, {data}',
        'nav.manage_employees_link_label': 'Gestisci dipendenti',
        'nav.manage_locations_link_label': 'Gestisci posizioni',
        'nav.sessions': 'Sessioni',
        'nav.sign_out_link_label': 'Esci',
        'pagination.next': 'Successivo',
        'pagination.page': 'Pagina',
        'pagination.previous': 'Precedente',
        road: 'Strada',
        'session_details.add_transaction_cta_label': 'Aggiungi transazione',
        'session_details.all_sessions_link': 'Tutte le sessioni',
        'session_details.bike_product': 'Bici',
        'session_details.client': 'Cliente',
        'session_details.client_review_label': 'Revisione del cliente',
        'session_details.complete_session_cta_label':
            'Contrassegna come chiuso',
        'session_details.complete_session_message':
            'Chiudere questa sessione aggiungendo eventuali transazioni effettuate dal cliente, oppure',
        'session_details.complete_status': 'Chiuso',
        'session_details.created_on': 'Creato il: {data}',
        'session_details.delete_link': 'Elimina',
        'session_details.employee': 'Dipendente: {data}',
        'session_details.family_label': 'Famiglia',
        'session_details.incomplete_status': 'Aperto',
        'session_details.model_label': 'Modello',
        'session_details.no_transactions_message':
            'Nessuna transazione è stata aggiunta.',
        'session_details.product_label': 'Prodotto',
        'session_details.saddle_product': 'Sella',
        'session_details.shoe_product': 'Scarpa',
        'session_details.shop': 'Negozio: {data}',
        'session_details.size_label': 'Taglia',
        'session_details.status_label': 'Stato',
        'session_details.transactions_heading': 'Transazioni di sessione',
        'session_products.arch_height_label': "Altezza dell'arco: {data}",
        'session_products.bike_size_label': 'Taglia della bici: {data}',
        'session_products.saddle_height_label': 'Altezza sella: {data}mm',
        'session_products.saddle_width_label': 'Larghezza sella: {data}mm',
        'session_products.session_product_label': 'Dati della sessione',
        'session_products.shoe_size_label': 'Taglia della scarpa: {data}',
        'sessions.add_transaction_cta_label': 'Aggiungi transazione',
        'sessions.client_anonymous': 'Anonimo',
        'sessions.client_label': 'Cliente',
        'sessions.closed_status': 'CHIUSO',
        'sessions.csv_downloading_message':
            'Stiamo raccogliendo tutte le sessioni per il tuo CSV, questo potrebbe richiedere un paio di minuti.',
        'sessions.date_label': 'Data',
        'sessions.employee_label': 'Dipendente',
        'sessions.export_csv_link': 'Esporta elenco (. CSV)',
        'sessions.filter_empty':
            'Spiacenti, non ci sono sessioni che soddisfano i criteri del tuo filtro. Si prega di reimpostare la selezione del filtro.',
        'sessions.filter_empty_cta_label': 'Reimposta filtri',
        'sessions.heading': 'Sessioni',
        'sessions.intro':
            'Una cronologia di tutte le tue ultime sessioni con la tecnologia Retül.',
        'sessions.location_label': 'Posizione',
        'sessions.no_rating': 'Nessuna valutazione',
        'sessions.open_status': 'APERTO',
        'sessions.rating_label': 'Valutazione',
        'sessions.session_id_label': 'ID sessione',
        'sessions.session_loading_message':
            'Stiamo raccogliendo tutte le sessioni, questo potrebbe richiedere qualche istante.',
        'sessions.status_label': 'Stato',
        'sign_in.heading': 'Accedi',
        'sign_in.intro':
            'Inserisci la tua email e la password per iniziare, oppure scegli "Registra account" per crearne uno nuovo.',
        'status.all': 'STATO: TUTTO',
        'status.complete': 'STATO: CHIUSO',
        'status.incomplete': 'STATO: APERTO',
        triathlon: 'Triathlon',
        'POWER MIMIC DESCRIPTION':
            "Questa sella fa scomparire intorpidimento e dolore con un design che imita la risposta del corpo ai diversi tipi di pressione per creare equilibrio all'interno dei tessuti molli.",
        'date_picker.all_time': 'Tutte le sessioni',
        'date_picker.date_range': 'Intervallo di date',
        'date_picker.last_month': 'Lo scorso mese',
        'date_picker.last_week': 'La settimana scorsa',
        'date_picker.last_year': "L'anno scorso",
        'date_picker.this_month': 'Questo mese',
        'date_picker.this_week': 'Questa settimana',
        'date_picker.this_year': "Quest'anno",
        'session_details.client_notes': 'Note del cliente',
        'session_details.footbed_product': 'Soletta',
        'session_products.it_width_label':
            'Larghezza ossa ischiatiche: {data} mm',
        'bike_size.large': 'L',
        'bike_size.medium': 'M',
        'bike_size.small': 'S',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'L’esperienza Retül Match consiste in una valutazione, eseguita nel punto vendita, che misura con precisione la lunghezza della tua gamba per fornirti una bicicletta dalla taglia ottimale e prodotti che rispondono meglio alle tue esigenze.',
        'body_data.about_saddle':
            'Retül Match è un’esperienza immersiva in punto vendita che sfrutta il Digital Sitbone Device Retül per catturare l’ampiezza della tuberosità ischiatica con una mappa digitale della pressione e fornire, in tal modo, una sella della taglia corretta e che risponda meglio alle tue esigenze.',
        'body_data.about_shoe':
            'Retül Match è un’esperienza immersiva in punto vendita che cattura i dati relativi ai piedi per fornire un corretto supporto per l’arco plantare e scarpe della giusta misura, che rispondano meglio alle tue esigenze.',
        'body_data.arch_height_label': 'altezza arco',
        'body_data.bike_label': 'Bicicletta',
        'body_data.bike_size_label': 'taglia bicicletta',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'circonferenza',
        'body_data.find_retul_technology': 'Trova la tecnologia Retül',
        'body_data.heading': 'Dati corporei',
        'body_data.intro':
            'La tecnologia Retül Vantage cattura i dati corporei e le preferenze ciclistiche per fornire misurazioni precise e prodotti che rispondano meglio alle tue esigenze.',
        'body_data.length_label': 'lunghezza',
        'body_data.load_error':
            'Non siamo in grado di recuperare i tuoi dati corporei al momento, ti preghiamo di riprovare più tardi.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'non raccolto',
        'body_data.recommended_label': 'Consigliato',
        'body_data.saddle_height_label': 'altezza sella',
        'body_data.saddle_label': 'Sella',
        'body_data.saddle_size_label': 'dimensione sella',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'tibia',
        'body_data.shoe_label': 'Scarpa',
        'body_data.shoe_size_label': 'misura scarpa',
        'body_data.sit_bone_label': 'tuberosità ischiatica',
        'body_data.starting_label': 'Inizio',
        'body_data.thigh_label': 'coscia',
        'body_data.view_bike_matches_label':
            'Visualizza le biciclette corrispondenti alla tua ricerca',
        'body_data.view_saddle_matches_label':
            'Visualizza le selle corrispondenti alla tua ricerca',
        'body_data.view_shoe_matches_label':
            'Visualizza le scarpe corrispondenti alla tua ricerca',
        'body_data.your_progress_completion_label':
            '{numComplete}/3 completato',
        'body_data.your_progress_label': 'I tuoi progressi',
        cancel_label: 'Annulla',
        'create_account.heading': 'Crea account',
        'create_account.intro':
            'Inserisci un indirizzo e-mail e una password per creare un account Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Annulla',
        'edit_form.edit_form_save_label': 'Salva',
        email_format_error: 'Formato e-mail non valido.',
        email_label: 'E-mail',
        'forgot_password.heading': 'Password dimenticata',
        'forgot_password.intro':
            'Hai dimenticato la password? Inserisci il tuo indirizzo e-mail e ti invieremo il link per reimpostarla.',
        'forgot_password.reset_email_sent':
            'Un’e-mail per la reimpostazione della password è stata inviata a {email}',
        'landing.create_account_label': 'Crea un account',
        'landing.sign_in_label': 'Accedi',
        'nav.account_profiles_link_label': 'Account e profili ciclista',
        'nav.home_page_link_label': 'Home page',
        'nav.logout_link_label': 'Esci',
        'not_found.go_home_label': 'Torna alla home page',
        'not_found.heading': '404',
        'not_found.intro':
            'La pagina che cerchi potrebbe essere stata spostata o eliminata, oppure è inesistente.',
        password_invalid_error: 'Password non valida.',
        password_label: 'Password',
        'product_matches.bike_size_label': 'taglia bicicletta',
        'product_matches.category_filter_commute_comfort_label':
            'Comfort negli spostamenti',
        'product_matches.category_filter_commute_sport_label':
            'Spostamenti come sport',
        'product_matches.category_filter_cyclocross_label': 'Ciclocross',
        'product_matches.category_filter_label': 'Categoria',
        'product_matches.category_filter_label_road': 'Strada',
        'product_matches.category_filter_mountain_trail_label':
            'Montagna (sentieri)',
        'product_matches.category_filter_mountain_xc_label':
            'Montagna (cross country)',
        'product_matches.category_filter_triathlon_label': 'Triathlon',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Non disponiamo dei dati corporei per fornirti risultati corrispondenti alle tue esigenze per questo tipo di prodotto. Utilizza il filtro qui sopra per selezionare altri tipi di prodotto o usa la pagina delle località e trovane una per completare questo modulo Match.',
        'product_matches.find_retul_technology_label':
            'Trova la tecnologia Retül',
        'product_matches.gender_filter_all_label': 'Mostra tutti i prodotti',
        'product_matches.gender_filter_female_label':
            'Mostra i prodotti da donna',
        'product_matches.gender_filter_male_label': 'Mostra i prodotti da uomo',
        'product_matches.heading': 'Corrispondenze prodotti',
        'product_matches.intro':
            'La tecnologia Retül sfrutta i tuoi dati corporei e le tue preferenze ciclistiche per rispondere alle tue esigenze con i prodotti Specialized più adatti a te.',
        'product_matches.last_session_match_label': 'Ultima sessione Match',
        'product_matches.load_error':
            'Non siamo in grado di recuperare i prodotti corrispondenti alle tue esigenze, ti preghiamo di riprovare più tardi.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'In base ai dati biometrici catturati durante la tua sessione Retül Match, ti consigliamo una bicicletta della taglia indicata sopra. Benché questo sia un buon punto di partenza, ti consigliamo comunque di consultare il tuo rivenditore per consigli basati sul tuo livello di esperienza e sulla tua corporatura.',
        'product_matches.overlay_bike_size_label': 'taglia bicicletta',
        'product_matches.overlay_close_label': 'Chiudi',
        'product_matches.overlay_indicator_label': 'Tu',
        'product_matches.overlay_saddle_height_details':
            'In base ai dati biometrici catturati durante la tua sessione Retül Match, ti consigliamo di regolare la sella all’altezza indicata sopra. Benché questo sia un buon punto di partenza, ti consigliamo comunque di consultare il tuo rivenditore per consigli basati sul tuo livello di esperienza e sulla tua corporatura.',
        'product_matches.overlay_saddle_height_label': 'altezza sella',
        'product_matches.overlay_saddle_size_details':
            'In base ai dati biometrici catturati durante la tua sessione Retül Match, ti consigliamo una sella della dimensione indicata sopra. Benché questo sia un buon punto di partenza, ti consigliamo comunque di consultare il tuo rivenditore per consigli basati sulle caratteristiche del tuo corpo.',
        'product_matches.overlay_saddle_size_label': 'dimensione sella',
        'product_matches.overlay_shoe_size_details':
            'In base ai dati biometrici catturati durante la tua sessione Retül Match, ti consigliamo una scarpa della taglia indicata sopra. Benché questo sia un buon punto di partenza, ti consigliamo comunque di consultare il tuo rivenditore per consigli basati sul tuo livello di esperienza.',
        'product_matches.overlay_shoe_size_label': 'misura scarpa',
        'product_matches.padding_level_label': 'Livello di imbottitura',
        'product_matches.product_type_filter_bikes_label': 'Biciclette',
        'product_matches.product_type_filter_label': 'Tipo di prodotto',
        'product_matches.product_type_filter_saddles_label': 'Selle',
        'product_matches.product_type_filter_shoes_label': 'Scarpe',
        'product_matches.recommended_label': 'Consigliato',
        'product_matches.saddle_detail_size_label': 'Dimensione sella:',
        'product_matches.saddle_height_label': 'altezza sella',
        'product_matches.saddle_models_label': 'Modelli',
        'product_matches.saddle_size_label': 'dimensione sella',
        'product_matches.shoe_size_label': 'misura scarpa',
        'product_matches.starting_label': 'Inizio',
        'product_matches.view_on_specialized_label':
            'Visualizza su Specialized',
        'profile.account_heading': 'Account',
        'profile.bike_type_city_label': 'Città',
        'profile.bike_type_label': 'Tipo di bicicletta',
        'profile.bike_type_mountain_label': 'Montagna',
        'profile.bike_type_road_label': 'Strada',
        'profile.email_error': 'Inserisci un indirizzo e-mail valido',
        'profile.email_label': 'E-mail',
        'profile.gender_female_label': 'femmina',
        'profile.gender_label': 'Genere',
        'profile.gender_male_label': 'maschio',
        'profile.heading': 'Account e profili ciclista',
        'profile.intro':
            'In arrivo... modifica i dati del tuo account e i profili ciclista.',
        'profile.name_error': 'Inserisci un nome',
        'profile.name_label': 'Nome',
        'profile.password_error': 'Inserisci una password',
        'profile.password_label': 'Password',
        'profile.position_high_label': 'Alto',
        'profile.position_label': 'Posizione',
        'profile.position_low_label': 'Basso',
        'profile.position_medium_label': 'Medio',
        'profile.ride_duration_extended_label': 'Oltre 90 minuti',
        'profile.ride_duration_label': 'Durata',
        'profile.ride_duration_long_label': '60-90 minuti',
        'profile.ride_duration_medium_label': '30-60 minuti',
        'profile.ride_duration_short_label': '0-30 minuti',
        'profile.ride_frequency_label': 'Frequenza',
        'profile.ride_frequency_once_month_label': 'Una volta al mese',
        'profile.ride_frequency_once_week_label': 'Una volta alla settimana',
        'profile.ride_frequency_six_week_label': 'Sei volte alla settimana',
        'profile.ride_frequency_three_week_label': 'Tre volte alla settimana',
        'routes.account_profile_route_label': 'Account e profili ciclista',
        'routes.body_data_route_label': 'Dati corporei',
        'routes.create_account_route_label': 'Crea account',
        'routes.forgot_password_route_label': 'Password dimenticata',
        'routes.locations_route_label': 'Località',
        'routes.not_found_route_label': 'Non trovato',
        'routes.privacy_policy_route_label': 'Informativa sulla privacy',
        'routes.product_matches_route_label': 'Corrispondenze prodotti',
        'routes.reset_password_route_label': 'Reimposta la password',
        'routes.set_password_route_label': 'Reimposta la password',
        'routes.sessions_route_label': 'Sessioni',
        'routes.sign_in_route_label': 'Accedi',
        'routes.terms_of_service_route_label': 'Termini di servizio',
        'sessions.download_pdf_cta_label': 'Scarica PDF',
        'sessions.empty': 'Non hai effettuato alcuna sessione.',
        'sessions.empty_cta_label': 'Trova località',
        'sessions.loading_error':
            'Non siamo in grado di recuperare le tue sessioni al momento, ti preghiamo di riprovare più tardi.',
        'sessions.loading_error_cta_label': 'Riprova',
        'sessions.session_type_filter_label': 'Tipo di sessione',
        'session_type.dfd_session_name': 'Digital Foot Device Retül',
        'session_type.dsd_session_name': 'Digital Sitbone Device Retül',
        'session_type.foot_session_name': 'Retül Piede',
        'session_type.legs_session_name': 'Retül Gambe',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül Sella',
        'session_type.unknown_session_name': 'Sessione Retül',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': 'Accedi',
        'sign_in.contact_label': 'Contatta Retül',
        'sign_in.forgot_password_label': 'Hai dimenticato la password?',
        'sign_in.sign_in_error':
            'Non è stato possibile recuperare i tuoi dati utente. Contatta l’assistenza Retül.',
        submit_label: 'Invia',
    },
};
