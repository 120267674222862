import React from 'react';
import AddTransactionModal from '../components/AddTransactionModal/AddTransactionModal';
import ResourceModal from '../components/ResourceModal/ResourceModal';
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal';
import LocalePickerModal from '../components/LocalePicker/LocalePickerModal';

export function renderResourceModal(params) {
  const {
    displayedText, fields, checkboxGroups, id, submitText, submitFunction, openModal, topModalClass, modalClosed = () => {
    }
  } = params;

  return (
    <ResourceModal
      displayedText={displayedText}
      fields={fields}
      checkboxGroups={checkboxGroups}
      id={id}
      submitText={submitText}
      submitFunction={submitFunction}
      openModal={openModal}
      modalClosed={modalClosed}
      topModalClass={topModalClass}
    />
  )
}

export function renderConfirmationModal(params) {
  const {displayedText, id, submitText, confirmFunction, confirmMessage, openModal, modalClosed} = params;
  return (
    <ConfirmationModal
      displayedText={displayedText}
      id={id}
      submitText={submitText}
      confirmMessage={confirmMessage}
      confirmFunction={confirmFunction}
      openModal={openModal}
      modalClosed={modalClosed}
    />
  )
}

export function renderAddTransactionModal(params) {
  const {data, id, openModal, modalClosed} = params;

  return (
    <AddTransactionModal
      id={id}
      data={data}
      openModal={openModal}
      modalClosed={modalClosed}
    />
  )
}

export function renderLocalePickerModal(params) {
  const {openModal, modalClosed, onLocaleChange} = params;

  return (
    <LocalePickerModal
      openModal={openModal}
      modalClosed={modalClosed}
      onLocaleChange={onLocaleChange}
    />
  );
}
