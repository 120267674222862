import React, {Component} from 'react';
import {
  renderDonutOrVerticalChart,
  renderVerticalBarChart,
  renderVerticalOrHorizontalBarChart
} from '../../utils/chartHelper';
import WrappedAnalyticsContainer from '../../containers/WrappedAnalyticsContainer';

import {BIKE, PRODUCTS, SADDLE, SHOE} from '../../types/constants';
import {
  getProduct,
  getProductItem,
  getProductsWithNumericSizes,
  getProductsWithTshirtSizes
} from '../../selectors/analyticsByProduct';

import {injectIntl} from 'react-intl';
import messages from './messages';


class AnalyticsByProduct extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.page = {
      'saddle': this.intl.formatMessage(messages.saddle),
      'saddleSize': this.intl.formatMessage(messages.saddle_size),
      'sitboneWidth': this.intl.formatMessage(messages.sitbone_width),
      'bikeType': this.intl.formatMessage(messages.bike_type),
      'bike': this.intl.formatMessage(messages.bike),
      'bikeSizeStandard': this.intl.formatMessage(messages.bike_size_standard),
      'bikeSizeShirt': this.intl.formatMessage(messages.bike_size_shirt),
      'saddleHeight': this.intl.formatMessage(messages.saddle_height),
      'shoe': this.intl.formatMessage(messages.shoe),
      'footArch': this.intl.formatMessage(messages.foot_arch),
      'shoeSize': this.intl.formatMessage(messages.shoe_size),
    };
  }

  translateData(data) {
    return data.map(obj => {
      obj.item = this.intl.formatMessage(messages[obj.item]);

      return obj;
    });
  }

  renderSaddleData() {
    const {products} = this.props.data;
    const saddle = getProduct(products, 'saddle');

    const sessionsBySaddleSize = getProductItem(saddle, 'by_saddle_size');
    const sessionsBySitboneWidth = getProductItem(saddle, 'by_sitbone_width');
    const sessionsByBikeType = getProductItem(saddle, 'by_bike_type');
    const translatedBikeTypes = this.translateData(sessionsByBikeType);

    return (
      <div className="product-group-container">
        <h2 className="product-name">
          {this.page.saddle}
        </h2>
        <div className="chart-group-container">
          {sessionsBySaddleSize && renderDonutOrVerticalChart(this.page.saddleSize, sessionsBySaddleSize)}
          {sessionsBySitboneWidth && renderVerticalBarChart(this.page.sitboneWidth, sessionsBySitboneWidth)}
        </div>
        <div className="chart-group-container">
          {sessionsByBikeType && renderVerticalBarChart(this.page.bikeType, translatedBikeTypes)}
        </div>
      </div>
    )
  }

  renderBikeData() {
    const {products} = this.props.data;
    const bike = getProduct(products, 'bike');
    const sessionsByBikeSize = getProductItem(bike, 'by_bike_size');
    const sessionsByBikeSizeNumeric = getProductsWithNumericSizes(sessionsByBikeSize);
    const sessionsByBikeSizeTShirt = getProductsWithTshirtSizes(sessionsByBikeSize);
    const sessionsBySaddleHeight = getProductItem(bike, 'by_saddle_height');
    const sessionsByBikeType = getProductItem(bike, 'by_bike_type');
    const translatedBikeTypes = this.translateData(sessionsByBikeType);

    return (
      <div className="product-group-container">
        <h2 className="product-name">
          {this.page.bike}
        </h2>
        <div className="chart-group-container">
          {
            sessionsByBikeSizeNumeric &&
            renderVerticalOrHorizontalBarChart(this.page.bikeSizeStandard, sessionsByBikeSizeNumeric)
          }
          {
            sessionsByBikeSizeTShirt &&
            renderVerticalOrHorizontalBarChart(this.page.bikeSizeShirt, sessionsByBikeSizeTShirt)
          }
        </div>
        <div className="chart-group-container">
          {sessionsByBikeType && renderVerticalOrHorizontalBarChart(this.page.bikeType, translatedBikeTypes)}
          {sessionsBySaddleHeight && renderDonutOrVerticalChart(this.page.saddleHeight, sessionsBySaddleHeight)}
        </div>
      </div>
    )
  }

  renderShoeData() {
    const {products} = this.props.data;
    const shoe = getProduct(products, 'shoe');
    const sessionsByShoeSize = getProductItem(shoe, 'by_shoe_size');
    const sessionsByFootArch = getProductItem(shoe, 'by_foot_arch');
    const translatedArchData = this.translateData(sessionsByFootArch);

    return (
      <div className="product-group-container">
        <h2 className="product-name">
          {this.page.shoe}
        </h2>
        <div className="chart-group-container">
          {sessionsByFootArch && renderDonutOrVerticalChart(this.page.footArch, translatedArchData)}
        </div>
        <div className="chart-group-container full left">
          {sessionsByShoeSize && renderVerticalBarChart(this.page.shoeSize, sessionsByShoeSize)}
        </div>
      </div>
    )
  }

  render() {
    const {products} = this.props.data;
    return (
      <div className="Analytics">
        {
          getProduct(products, SADDLE) && this.renderSaddleData()
        }
        {
          getProduct(products, BIKE) && this.renderBikeData()
        }
        {
          getProduct(products, SHOE) && this.renderShoeData()
        }
      </div>
    )
  }
}

export default WrappedAnalyticsContainer(injectIntl(AnalyticsByProduct), PRODUCTS);
