import { defineMessages } from 'react-intl';

export default defineMessages({
  'session_product_label': {
    'id': 'session_products.session_product_label',
    'defaultMessage': 'Latest Session Data',
    'description': 'Label for session product data.'
  },
  'bike_size_label': {
    'id': 'session_products.bike_size_label',
    'defaultMessage': 'Bike Size: {data}',
    'description': 'Label for saddle height.'
  },
  'saddle_height_label': {
    'id': 'session_products.saddle_height_label',
    'defaultMessage': 'Saddle Height: {data}mm',
    'description': 'Label for saddle height.'
  },
  'saddle_width_label': {
    'id': 'session_products.saddle_width_label',
    'defaultMessage': 'Saddle Width: {data}mm',
    'description': 'Label for saddle width.'
  },
  'it_width_label': {
    'id': 'session_products.it_width_label',
    'defaultMessage': 'IT Width: {data}mm',
    'description': 'Label for IT width.'
  },
  'shoe_size_label': {
    'id': 'session_products.shoe_size_label',
    'defaultMessage': 'Shoe Size: {data}',
    'description': 'Label for shoe size.'
  },
  'arch_height_label': {
    'id': 'session_products.arch_height_label',
    'defaultMessage': 'Arch Height: {data}',
    'description': 'Label for foot arch height.'
  },
});
