import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import './Footer.css';
import { injectIntl } from 'react-intl';
import { renderLocalePickerModal } from '../../utils/modalHelper';
import localeMessages from '../../components/LocalePicker/messages';
import messages from './messages';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
        };

        this.intl = props.intl;
    }

    handleLocaleClick() {
        this.setState({
            openModal: true,
        });
    }

    render() {
        return (
            <React.Fragment>
                <footer className="site-footer footer">
                    <div className="footer-inner">
                        <div className="footerLogo">
                            <Logo
                                fill="DarkGray"
                                size="small"
                                display="block"
                            />
                        </div>
                        <div className="footerInfo">
                            <button
                                className="footerLink"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.handleLocaleClick();
                                }}
                            >
                                <span>
                                    {this.intl.formatMessage(
                                        localeMessages[
                                            `label_${this.intl.locale}`
                                        ]
                                    )}
                                </span>
                            </button>

                            <Link className="footerLink" to="/terms">
                                <span>
                                    {this.intl.formatMessage(
                                        messages.terms_link_label
                                    )}
                                </span>
                            </Link>

                            <Link className="footerLink" to="/privacy-policy">
                                <span className="mix-txt_capitalize">
                                    {this.intl.formatMessage(
                                        messages.privacy_link_label
                                    )}
                                </span>
                            </Link>

                            <span className="txt txt_sm mix-txt_colorRegularGray">
                                {`\u00A92018 Retül`}
                            </span>
                        </div>
                    </div>
                </footer>
                {renderLocalePickerModal({
                    openModal: this.state.openModal,
                    onLocaleChange: this.props.onLocaleChange,
                })}
            </React.Fragment>
        );
    }
}

export default injectIntl(Footer);
