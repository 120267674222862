import React, { Component } from 'react';
import EmployeesTable from '../../components/EmployeesTable/EmployeesTable';
import { renderAddEmployeeModal } from '../../utils/employeesHelper';
import Button from '../../components/Button/Button';
import { injectIntl } from 'react-intl';
import messages from '../../components/EmployeesTable/messages';

class ManageEmployes extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    this.page = {
      'addEmployee': intl.formatMessage(messages.add_employee)
    };

    this.state = {
      openModal: false
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ openModal: true });
  }

  renderModalTrigger() {
    return (
      <div className="open-modal-link-container">
        <button
          className="modal-link"
          onClick={ this.openModal }
        >
          {this.page.addEmployee}
        </button>
      </div>
    )
  }

  renderEmployeeTable() {
    const { employeesList } = this.props;

    return employeesList.data.length < 1 ? '' : (
      <React.Fragment>
        <div className="TableContainer">
          <EmployeesTable {...this.props} />
        </div>
        { this.renderModalTrigger() }
      </React.Fragment>
    );
  }

  renderEmployeeManagement() {
    return (
      <section className="bottom">
        <div className="container">
          { this.renderModalTrigger() }
          { this.renderEmployeeTable() }
          { renderAddEmployeeModal(this.state.openModal, this.props) }
        </div>
      </section>
    )
  }

  renderAddLocations() {
    return (
      <section className="bottom">
        <div className="container">
          <p className="text text_lg">
            You must add a location before adding an employee.
          </p>
          <div className="box box_top3">
            <Button
              type="button"
              to="/manage-locations"
              light
            >
              Add a Location
            </Button>
          </div>
        </div>
      </section>
    )
  }

  render() {
    const { locationsList } = this.props;

    return locationsList.data.length > 0 ? this.renderEmployeeManagement() : this.renderAddLocations();
  }
}

export default injectIntl(ManageEmployes);
