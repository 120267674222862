import * as statusTypes from '../types/statuses';
import * as actionTypes from '../types/actions/locations'

export const initialState = {
  locationsList: {
    data: null,
    fetchingStatus: null
  },
  location: {
    data: null,
    createStatus: null,
    deleteStatus: null,
    fetchingStatus: null,
    updateStatus: null,
  }
};


function setCreateStatus(state, status) {
  const location = {...state.location, createStatus: status};
  return {...state, location}
}

function setDeleteStatus(state, status) {
  const location = {...state.location, deleteStatus: status};

  return {...state, location};
}

function setLocationListFetchingStatus(state, status) {
  const locationsList = {...state.locationsList, fetchingStatus: status};

  return {...state, locationsList}
}

function setLocationFetchingStatus(state, status) {
  const location = {...state.location, fetchingStatus: status};

  return {...state, location}
}

function receiveLocationData(state, action) {
  const locationsList = {...state.locationsList, fetchingStatus: statusTypes.RECEIVED, data: action.data};

  return {...state, locationsList}
}

function receiveLocation(state, action) {
  const location = {...state.location, fetchingStatus: statusTypes.RECEIVED, data: action.data};

  return {...state, location}
}

function setUpdateStatus(state, status) {
  const location = {...state.location, updateStatus: status};
  return {...state, location}
}

export default function sessions(state = initialState, action) {
  switch (action.type) {
    case(actionTypes.CREATE_LOCATION_REQUESTED):
      return setCreateStatus(state, statusTypes.REQUESTING);
    case(actionTypes.CREATE_LOCATION_COMPLETE):
      return setCreateStatus(state, statusTypes.COMPLETED);
    case(actionTypes.CREATE_LOCATION_FAILED):
      return setCreateStatus(state, statusTypes.FAILED);
    case(actionTypes.DELETE_LOCATION_COMPLETE):
      return setDeleteStatus(state, statusTypes.COMPLETED);
    case(actionTypes.DELETE_LOCATION_FAILED):
      return setDeleteStatus(state, statusTypes.FAILED);
    case(actionTypes.DELETE_LOCATION_REQUESTED):
      return setDeleteStatus(state, statusTypes.REQUESTING);
    case(actionTypes.RECEIVE_LOCATIONS_DATA):
      return receiveLocationData(state, action);
    case(actionTypes.REQUEST_LOCATIONS_DATA):
      return setLocationListFetchingStatus(state, statusTypes.REQUESTING);
    case(actionTypes.REQUEST_LOCATION):
      return setLocationFetchingStatus(state, statusTypes.REQUESTING);
    case(actionTypes.RECEIVE_LOCATION):
      return receiveLocation(state, action);
    case(actionTypes.UPDATE_LOCATION_REQUESTED):
      return setUpdateStatus(state, statusTypes.REQUESTING);
    case(actionTypes.UPDATE_LOCATION_COMPLETE):
      return setUpdateStatus(state, statusTypes.COMPLETED);
    case(actionTypes.UPDATE_LOCATION_FAILED):
      return setUpdateStatus(state, statusTypes.FAILED);
    default:
      return state
  }
}