import React, {Component} from 'react';
import RadioButton from '../../components/RadioButton/RadioButton';
import '../../assets/stylesheets/base/ControlGroups.css';
import './RadioButtonGroup.css';

export default class RadioButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.toggleCheckedState = this.toggleCheckedState.bind(this);
  }

  initializeRadioButtons() {
    const {filterLabels} = this.props;
    const radioButtons = filterLabels.map(radioButtonLabel => {
      const {element, value} = radioButtonLabel;

      return {labelText: element, value, checked: this.isSelectedByProp(value)}
    });

    return radioButtons;
  }

  toggleCheckedState(radioButtonValue) {
    const {name, onChange} = this.props;

    onChange(name, radioButtonValue);
  }

  isSelectedByProp(value) {
    const {activeRadioButtonValue} = this.props;

    return value === activeRadioButtonValue;
  }

  renderRadioButtons() {
    const {name} = this.props;
    const radioButtons = this.initializeRadioButtons();

    return radioButtons.map((radioButton, index) => {
        const {labelText, checked, value} = radioButton;

        return (
          <RadioButton
            checked={checked}
            labelText={labelText}
            name={name}
            key={index}
            toggleCheckedState={this.toggleCheckedState}
            value={value}
          />
        )
      }
    )
  }

  render() {
    const {heading} = this.props;
    return (
      <div className="RadioButtonGroup">
        <div className="control-group">
          <div className="filter-heading">
            {heading}
          </div>
          {this.renderRadioButtons()}
        </div>
      </div>
    )
  }
}
