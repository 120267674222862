import * as actionTypes from '../types/actions/sessions'
import {apiGet, apiOptions} from '../api/apiClient';
import {JSON_PARSER, TEXT_PARSER} from '../types/constants';
import {generateRequestConfigs} from '../utils/fetchHelper';
import {fetchSessionsCSVUrl, fetchSessionsUrl, fetchSessionUrl} from '../utils/urlHelper';

export function fetchSessions(searchParams) {
  return dispatch => {
    dispatch(fetchSessionsFilters());
    dispatch(requestSessions());

    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchSessionsUrl(searchParams),
          receiveCallback: receiveSessions,
        }
      )
    )
  }
}

export function fetchSession(sessionId) {
  return dispatch => {
    dispatch(requestSession());
    return apiGet(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchSessionUrl(sessionId),
          receiveCallback: receiveSessions,
        }
      )
    )
  }
}

export function fetchSessionsFilters() {
  return dispatch => {
    dispatch(requestSessionsFilters());

    return apiOptions(
      generateRequestConfigs(
        {
          dispatch,
          parser: JSON_PARSER,
          url: fetchSessionsUrl(),
          receiveCallback: receiveSessionsFilters,
        }
      )
    )
  }
}

export function fetchSessionsCSVExport() {
  return dispatch => {
    dispatch(requestSessionsCSVExport());

    return apiGet(
      generateRequestConfigs(
        {
          customHeaders: {
            "Accept": "text/html"
          },
          dispatch,
          parser: TEXT_PARSER,
          url: fetchSessionsCSVUrl(),
          receiveCallback: receiveSessionsCSVExport
        }
      )
    )
  }
}

export function receiveSessions(data) {
  return {
    type: actionTypes.RECEIVE_SESSIONS_DATA,
    data
  }
}

export function receiveSessionProduct(data) {
  return {
    type: actionTypes.RECEIVE_SESSION_PRODUCT_DATA,
    data
  }
}

export function receiveSessionsCSVExport(data) {
  return {
    type: actionTypes.RECEIVE_SESSIONS_CSV_EXPORT,
    data
  }
}

export function receiveSessionsFilters(data) {
  return {
    data,
    type: actionTypes.RECEIVE_SESSIONS_FILTERS,
  }
}

export function requestSessionsCSVExport() {
  return {
    type: actionTypes.REQUEST_SESSIONS_CSV_EXPORT
  }
}

export function requestSessionsFilters() {
  return {
    type: actionTypes.REQUEST_SESSIONS_FILTERS
  }
}

export function requestSessions() {
  return {
    type: actionTypes.REQUEST_SESSIONS_DATA
  }
}

export function requestSession() {
  return {
    type: actionTypes.REQUEST_SESSION_DATA
  }
}

export function requestSessionProduct() {
  return {
    type: actionTypes.REQUEST_SESSION_PRODUCT_DATA
  }
}

export function updateSearchParams(searchParams) {
  return {
    type: actionTypes.UPDATE_SESSIONS_SEARCH_PARAMS,
    data: searchParams
  }
}
