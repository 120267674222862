import { defineMessages } from 'react-intl';

export default defineMessages({
  'employee_id': {
    'id': 'employees.employee_id_label',
    'defaultMessage': 'Employee ID',
    'description': 'Label for Employee ID on a table'
  },
  'add_employee': {
    'id': 'employees.add_cta_label',
    'defaultMessage': 'Add Employee',
    'description': 'CTA text for adding an employee'
  },
  'update_employee': {
    'id': 'employees.update_cta_label',
    'defaultMessage': 'Update Employee',
    'description': 'CTA text for updating an employee'
  },
  'delete_employee_label': {
    'id': 'employees.delete_cta_label',
    'defaultMessage': 'Delete Employee',
    'description': 'CTA text for deleting an employee'
  },
  'shops_label': {
    'id': 'form.shops_field_label',
    'defaultMessage': 'Shops',
    'description': 'Label for seleting a shop from the list of shops'
  }
});
