export function getSessionsOverTime(data) {
  const {over_time} = data;

  return over_time && over_time.map(session => {
    return {
      quantity: session.quantity,
      item: new Date(session.item)
    }
  });
}

export function getSessionsByEmployee(data) {
  return data.by_employee.map(employeeData => {
    const item = employeeData.item || 'Unknown';
    return {
      quantity: employeeData.quantity,
      item
    }
  });
}

export function getSessionsByDuration(data) {
  return data.by_duration;
}

export function getSessionsByDayOfWeek(data) {
  return data.by_day_of_week;
}

export function getSessionsByGender(data) {
  return data.by_gender;
}

export function getSessionsByProduct(data) {
  return data.by_product;
}

export function getTopTenSessionDates(data) {
  const {top_ten_session_dates} = data;
  return top_ten_session_dates && top_ten_session_dates.map(session => {
    return {
      quantity: session.quantity,
      item: new Date(session.item)
    }
  });
}

export function getTopTenSalesDates(data) {
  const {top_ten_sale_dates} = data;

  return top_ten_sale_dates && top_ten_sale_dates.map(session => {
    return {
      quantity: session.quantity,
      item: new Date(session.item)
    }
  });
}