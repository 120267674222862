import React from 'react';
import Button from '../../components/Button/Button';
import {injectIntl} from 'react-intl';
import messages from './messages';

export default injectIntl(function (props) {
  const {intl} = props;

  return (
    <section className="bottom">
      <div className="container">
        <div className="box box_alignCenter">
          <p>{intl.formatMessage(messages.empty)}</p>
          <div className="box box_top3">
            <Button
              to="/manage-locations"
            >
              {intl.formatMessage(messages.empty_cta_label)}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
});
