import {RECEIVED, REQUESTING} from '../types/statuses';
import {RECEIVE_CLIENT_PRODUCT_DATA, REQUEST_CLIENT_PRODUCT_DATA,} from '../types/actions/sessions';
import {RECEIVE_BIKE_TYPES, REQUEST_BIKE_TYPES, UPDATE_BIKE_TYPE} from "../types/actions/transactions";
import {getClientProduct} from "../selectors/client";

export const initialState = {
  clientProductData: {
    data: null,
    fetchingStatus: null,
  },
  bikeTypeData: {
    data: null,
    fetchingStatus: null
  },
  bikeType: null
};

function receiveClientProductData(state, action) {
  const normalizedData = getClientProduct(action.data);
  const clientProductData = {...state.clientProductData, fetchingStatus: RECEIVED, data: normalizedData};

  return {...state, clientProductData}
}

function requestClientProductData(state) {
  const clientProductData = {...state.clientProductData, fetchingStatus: REQUESTING};

  return {...state, clientProductData}
}

function requestBikeTypes(state) {
  const bikeTypeData = {...state.bikeTypeData, fetchingStatus: REQUESTING};
  return {...state, bikeTypeData}
}

function receiveBikeTypes(state, action) {

  const bikeTypeData = {...state.bikeTypeData, fetchingStatus: RECEIVED};
  bikeTypeData.data = [...action.bikeType];
  return {...state, bikeTypeData}
}

function updateBikeType(state, action) {
  const bikeType = {"bikeType": action.data};

  return {...state, ...bikeType}
}

export default function clientProducts(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CLIENT_PRODUCT_DATA:
      return receiveClientProductData(state, action);
    case REQUEST_CLIENT_PRODUCT_DATA:
      return requestClientProductData(state);
    case REQUEST_BIKE_TYPES:
      return requestBikeTypes(state);
    case RECEIVE_BIKE_TYPES:
      return receiveBikeTypes(state, action);
    case UPDATE_BIKE_TYPE:
      return updateBikeType(state, action);
    default:
      return state
  }
}
