import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import classNames from "classnames";
import './PageLink.css';

export default class PageLink extends Component {

  render() {
    const {to} = this.props;
    return (
      <Link
        to={to}
        className={this.classNames}>
        {this.props.children}
      </Link>
    )
  }

  get classNames() {
    const {heavy} = this.props;

    return classNames(
      'PageLink',
      {
        'PageLink_heavy': heavy
      }
    );
  }
}
