import * as actionTypes from '../types/actions/forgotPassword';
import { apiCreate } from '../api/apiClient';
import { JSON_PARSER } from '../types/constants';
import { generateRequestConfigs } from '../utils/fetchHelper';
import { createPasswordResetUrl } from '../utils/urlHelper';

export function resetPassword(data) {
    return (dispatch) => {
        dispatch(postPasswordReset());

        console.log('forgot stuff', data); //CDJ
        console.log(
            //CDJ
            generateRequestConfigs({
                body: JSON.stringify(data),
                dispatch,
                parser: JSON_PARSER,
                url: createPasswordResetUrl(),
                failCallback: receivePasswordResetError,
                receiveCallback: receivePasswordReset,
                auth: 'Basic',
            })
        );

        return apiCreate(
            generateRequestConfigs({
                body: JSON.stringify(data),
                dispatch,
                parser: JSON_PARSER,
                url: createPasswordResetUrl(),
                failCallback: receivePasswordResetError,
                receiveCallback: receivePasswordReset,
                auth: 'Basic',
            })
        );
    };
}

export function postPasswordReset() {
    return {
        type: actionTypes.POST_PASSWORD_RESET,
    };
}

export function receivePasswordReset(data) {
    return {
        type: actionTypes.RECEIVE_PASSWORD_RESET,
        data,
    };
}

export function receivePasswordResetError(errors) {
    return {
        type: actionTypes.RECEIVE_PASSWORD_RESET_ERROR,
        errors,
    };
}
