import { defineMessages } from 'react-intl';

export default defineMessages({
  'filter_heading': {
    'id': 'filters.filters_heading',
    'defaultMessage': 'Filters',
    'description': 'Heading displayed above all the filters'
  },
  'show_filters': {
    'id': 'filters.show_filters',
    'defaultMessage': 'Show Filters',
    'description': 'Label to show the filter options that are hidden'
  },
  'hide_filters': {
    'id': 'filters.hide_filters',
    'defaultMessage': 'Show Filters',
    'description': 'Label to hide the filter options that are shown'
  },
  'all_label': {
    'id': 'filters.all_label',
    'defaultMessage': 'ALL',
    'description': 'Label for generic All filter'
  },
  'status_filter_label': {
    'id': 'filters.status_label',
    'defaultMessage': 'Status',
    'description': 'Label for different status filter options'
  },
  'session_filter_label': {
    'id': 'filters.session_label',
    'defaultMessage': 'Session Type',
    'description': 'Label for different session types filter options'
  },
  'client_filter_label': {
    'id': 'filters.client_label',
    'defaultMessage': 'Client Type',
    'description': 'Label for different client types filter options'
  },
  'rating_filter_label': {
    'id': 'filters.rating_label',
    'defaultMessage': 'Rating',
    'description': 'Label for different rating filter options'
  },
  'employees_filter_label': {
    'id': 'filters.employees_label',
    'defaultMessage': 'Employees',
    'description': 'Label for different employees filter options'
  },
  'locations_filter_label': {
    'id': 'filters.locations_label',
    'defaultMessage': 'Locations',
    'description': 'Label for different locations filter options'
  },
  'filters.status_all': {
    'id': 'filters.status_all',
    'defaultMessage': 'All',
    'description': 'Label for all selecting all statuses'
  },
  'filters.status_open': {
    'id': 'filters.status_open',
    'defaultMessage': 'Open',
    'description': 'Label for selecting open statuses'
  },
  'filters.status_closed': {
    'id': 'filters.status_closed',
    'defaultMessage': 'Closed',
    'description': 'Label for selecting closed statuses'
  },
  'filters.session_all': {
    'id': 'filters.session_all',
    'defaultMessage': 'All',
    'description': 'Label for selecting all sessions'
  },
  'filters.session_match': {
    'id': 'filters.session_match',
    'defaultMessage': 'Match',
    'description': 'Label for selecting Match sessions'
  },
  'filters.session_dsd': {
    'id': 'filters.session_dsd',
    'defaultMessage': 'DSD/DFD',
    'description': 'Label for selecting iPad sessions'
  },
  'filters.client_all': {
    'id': 'filters.client_all',
    'defaultMessage': 'All',
    'description': 'Label for selecting All clients'
  },
  'filters.client_identified': {
    'id': 'filters.client_identified',
    'defaultMessage': 'Identified',
    'description': 'Label for selecting Identified clients'
  },
  'filters.client_anonymous': {
    'id': 'filters.client_anonymous',
    'defaultMessage': 'Anonymous',
    'description': 'Label for selecting Anonymous clients'
  },
  'filters.rating_all': {
    'id': 'filters.rating_all',
    'defaultMessage': 'ALL',
    'description': 'Label for selecting All ratings'
  },
  'filters.rating_none': {
    'id': 'filters.rating_none',
    'defaultMessage': 'NONE',
    'description': 'Label for selecting sessions without ratings'
  },
});
