import { defineMessages } from 'react-intl';

export default defineMessages({
  'greeting_label': {
    'id': 'nav.greeting_label',
    'defaultMessage': 'Hello, { data }',
    'description': 'Greeting label for the Navigation dropdown'
  },
  'nav.manage_employees_link_label': {
    'id': 'nav.manage_employees_link_label',
    'defaultMessage': 'Manage Employees',
    'description': 'Navigation label for the Manage Employees link'
  },
  'nav.manage_locations_link_label': {
    'id': 'nav.manage_locations_link_label',
    'defaultMessage': 'Manage Locations',
    'description': 'Navigation label for the Manage Locations link'
  },
  'nav.sign_out_link_label': {
    'id': 'nav.sign_out_link_label',
    'defaultMessage': 'Sign Out',
    'description': 'Navigation label for the Sign Out link'
  },
});
