import React, {Component} from 'react';
import './ClientProducts.css';
import {injectIntl} from 'react-intl';
import messages from './messages';
import DropDown from "../DropDown/DropDown";

class ClientProducts extends Component {
  updateBikeType(value) {
    this.props.updateBikeType(value);
  }

  constructor(props) {
    super(props);

    this.intl = props.intl;
    this.page = {
      'sessionProductLabel': this.intl.formatMessage(messages.session_product_label),
    };

    this.state = {
      openModal: false,
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({
      openModal: true,
    });
  }

  renderBikeSize(bs) {
    if (bs !== null && typeof bs !== 'undefined') {
      return (
        <li className="txt">{this.intl.formatMessage(messages.bike_size_label, {data: bs})}</li>
      );
    } else {
      return null;
    }
  }

  renderSaddleHeight(sh) {
    if (sh !== null && typeof sh !== 'undefined') {
      return (
        <li className="txt">{this.intl.formatMessage(messages.saddle_height_label, {data: sh})}</li>
      );
    } else {
      return null;
    }
  }

  renderSaddleWidth(sw) {
    if (sw !== null && typeof sw !== 'undefined') {
      return (
        <li className="txt">{this.intl.formatMessage(messages.saddle_width_label, {data: sw})}</li>
      );
    } else {
      return null;
    }
  }

  renderITWidth(it) {
    if (it !== null && typeof it !== 'undefined') {
      return (
        <li className="txt">{this.intl.formatMessage(messages.it_width_label, {data: it})}</li>
      );
    } else {
      return null;
    }
  }

  renderShoeSize(ss) {
    console.log(ss);
    if (ss !== null && typeof ss !== 'undefined') {
      return (
        <li className="txt">{this.intl.formatMessage(messages.shoe_size_label, {data: ss})}</li>
      );
    } else {
      return null;
    }
  }

  renderFootbed(ah) {
    if (ah !== null && typeof ah !== 'undefined') {
      return (
        <li className="txt">{this.intl.formatMessage(messages.arch_height_label, {data: ah})}</li>
      );
    } else {
      return null;
    }
  }

  renderBikeCategoryDropdown(bikeTypeFormat) {
    const defaultVal = this.props.latest_bike_session.category;
    const displayedValue = bikeTypeFormat.find(x => {
      return x.value.includes(defaultVal);
    });

    return (
      <DropDown
        displayedValue={displayedValue.label}
        selectedValue={displayedValue.value}
        fieldName='Bike Type'
        listItems={bikeTypeFormat}
        halfWidth={true}
        handleItemClick={this.updateBikeType.bind(this)}
      />
    );
  }

  render() {
    const {latest_bike_session, latest_shoe_session, latest_saddle_session, bikeTypeData} = this.props;

    let bikeTypeFormat = [];
    for (var i = 0; i < bikeTypeData.length; i++) {
      bikeTypeFormat.push({
        'value': bikeTypeData[i].name,
        'label': bikeTypeData[i].name
      })
    }

    let renderDropDown = false;
    let bikeSize, saddleHeight, saddleSize, itWidth, shoeSize, archHeight = null;
    if (typeof latest_bike_session !== 'undefined') {
      bikeSize = latest_bike_session.bike_size;
      saddleHeight = latest_bike_session.saddle_height;
      if (bikeSize !== null && typeof bikeSize !== 'undefined') {
        renderDropDown = true;
      }
    }
    if (typeof latest_saddle_session !== 'undefined') {
      saddleSize = latest_saddle_session.saddle_size;
      itWidth = latest_saddle_session.it_width;
    }
    if (typeof latest_shoe_session !== 'undefined') {
      shoeSize = latest_shoe_session.shoe_size;
      archHeight = latest_shoe_session.arch_height;
    }

    return (
      <div className="session-products">
        <h2 className="txt txt_mdLg h2_padded">{this.page.sessionProductLabel}</h2>
        <div className="box box_top1">
          {renderDropDown ? this.renderBikeCategoryDropdown(bikeTypeFormat) : null}
          <ul className="vList productList">
            {this.renderBikeSize(bikeSize)}
            {this.renderSaddleHeight(saddleHeight)}
            {this.renderSaddleWidth(saddleSize)}
            {this.renderITWidth(itWidth)}
            {this.renderShoeSize(shoeSize)}
            {this.renderFootbed(archHeight)}
          </ul>
        </div>
      </div>
    )
  }
}

export default injectIntl(ClientProducts);
