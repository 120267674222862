import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'
import componentWithErrorBoundary from '../componentsHighOrder/componentWithErrorBoundary';
import NavigationDropDown from '../components/NavigationDropDown/NavigationDropDown';
import * as currentUserActionCreators from '../actions/currentUser';
import {signOut} from '../actions/authentication';
import {getCurrentUserName, isAuthenticated, isCurrentUserNameSet} from '../api/tokens';


const actionCreators = {
  ...currentUserActionCreators, signOut
};

export class NavigationDropDownContainer extends Component {
  constructor(props) {
    super(props);
    this.signOutHandler = this.signOutHandler.bind(this);
  }

  componentDidMount() {
    if (!isCurrentUserNameSet()) {
      const {fetchCurrentUser} = this.props;
      fetchCurrentUser();
    }
  }

  signOutHandler(e) {
    e.preventDefault();
    const {signOut} = this.props;
    signOut()

  }

  renderNavDropDown() {
    return (
      isCurrentUserNameSet() && componentWithErrorBoundary(
        <NavigationDropDown
          data={getCurrentUserName()}
          signOutHandler={this.signOutHandler}
          classExtensions="dropdown_dark dropdown_simple"
        />
      )
    )
  }

  render() {
    return (
      isAuthenticated() ? this.renderNavDropDown() : <Redirect to="/" push={true}/>
    )
  }
}

function mapStateToProps(state) {
  return {currentUser: state.currentUser, authentication: state.authentication};
}

export default connect(mapStateToProps, actionCreators)(NavigationDropDownContainer);
