export function getProduct(data, productName) {
  return data.find(product => product.name === productName);
}

export function getProductItem(data, itemType) {
  const dataItemType = data.items.find(item => item[itemType]);
  if (dataItemType) {
    return dataItemType[itemType]
  }
}

export function getProductsWithTshirtSizes(data) {
  const ORDER = ['XS', 'SM', 'MD', 'LG', 'XL'];
  const sizes = [];

  for (const d of data) {
    if (!d.item.match(/\d/)) {
      d.order = ORDER.indexOf(d.item);

      sizes.push(d);
    }
  }

  return sizes.sort((a, b) => {
    return a.order < b.order ? -1 : 1;
  });
}

export function getProductsWithNumericSizes(data) {
  return data.filter(d => d.item.match(/\d/))
}
