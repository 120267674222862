/* eslint-disable */
export const localeStrings = {
    cs: {
        'add_transaction.back_to_sessions_label': 'Zpět na relace',
        'add_transaction.bike_label': 'Kolo',
        'add_transaction.commute_comfort_type': 'Dojíždění-komfort',
        'add_transaction.commute_sport_type': 'Dojíždění-sport',
        'add_transaction.complete_transaction_cta_label': 'Dokončení transakce',
        'add_transaction.confirm_label': 'Potvrdit',
        'add_transaction.cyclocross_type': 'Cyklokros',
        'add_transaction.family_label': 'Rodina',
        'add_transaction.fitness_type': 'Fitness',
        'add_transaction.footbed_label': 'Stélka',
        'add_transaction.heading': 'Přidání transakce do {data}',
        'add_transaction.model_label': 'Model',
        'add_transaction.mountain_type': 'MTB ',
        'add_transaction.mtb_trail_type': 'MTB (trail)',
        'add_transaction.mtb_xc_type': 'MTB (cross country)',
        'add_transaction.product_label': 'Produkt',
        'add_transaction.ratings_message':
            'Po dokončení této transakce obdržíte  {data} e-mail, kde lze hodnotit tento produkt. Toto hodnocení bude možné vidět na stránce relace a na stránce s podrobnostmi o relacích.',
        'add_transaction.road_type': 'Silniční',
        'add_transaction.saddle_label': 'Sedlo',
        'add_transaction.shoe_label': 'Boty',
        'add_transaction.size_label': 'Velikost',
        'add_transaction.transaction_complete_message': 'Transakce dokončena.',
        'add_transaction.triathlon_type': 'Triatlon',
        'add_transaction.tt_tri_type': 'TT / Tri',
        'add_transaction.type_label': 'typ',
        'analytics.loading_message':
            'Sestavujeme data, může to trvat několik minut',
        'analytics_filters.analytics_by': 'ANALYTICS PODLE',
        'analytics_filters.filter_by': 'FILTROVAT PODLE',
        'analytics_filters.products': 'PRODUKTY',
        'analytics_filters.sessions': 'RELACE',
        'charts.bike_label_products': 'Kolo',
        'charts.bike_label_sessions': 'Kolo',
        'charts.commute_comfort_label': 'Dojíždění-komfort',
        'charts.commute_sport_label': 'Dojíždění-sport',
        'charts.cyclocross_label': 'Cyklokros',
        'charts.female_label': 'Žena',
        'charts.friday_label': 'pátek',
        'charts.high_arch_label': 'vysoký',
        'charts.low_arch_label': 'nízký',
        'charts.male_label': 'Muž',
        'charts.medium_arch_label': 'Střední',
        'charts.monday_label': 'pondělí',
        'charts.mtb_trail_label': 'MTB-trail',
        'charts.mtb_xc_label': 'MTB-xc',
        'charts.road_label': 'Silniční',
        'charts.saddle_label_products': 'Sedlo',
        'charts.saddle_label_sessions': 'Sedlo',
        'charts.saturday_label': 'sobota',
        'charts.sessions_by_bike_type_label': 'Relace podle typu kola',
        'charts.sessions_by_day_of_week_label': 'Relace podle dne v týdnu',
        'charts.sessions_by_employee_label': 'Relace podle zaměstnance',
        'charts.sessions_by_foot_arch_label': 'Relace dle klenby nohy',
        'charts.sessions_by_gender_label': 'Relace podle pohlaví',
        'charts.sessions_by_product_label': 'Relace dle produktu',
        'charts.sessions_by_saddle_height_label': 'Relace podle výšky sedla',
        'charts.sessions_by_saddle_size_label': 'Relace podle velikosti sedla',
        'charts.sessions_by_shirt_bike_size_label':
            'Relace dle velikosti kola (triko)',
        'charts.sessions_by_shoe_size_label': 'Relace podle velikosti boty',
        'charts.sessions_by_sitbone_width_label':
            'Relace podle šířky sedacích kostí',
        'charts.sessions_by_standard_bike_size_label':
            'Relace podle velikosti kola (standardní)',
        'charts.sessions_over_time_label': 'Relace v průběhu času',
        'charts.shoe_label_products': 'Boty',
        'charts.shoe_label_sessions': 'Boty',
        'charts.sunday_label': 'Neděle',
        'charts.thursday_label': 'Čtvrtek',
        'charts.top_ten_session_label': 'Deset nejlepších relací',
        'charts.top_transactions_label': 'Nejlepší data transakcí',
        'charts.triathlon_label': 'Triatlon',
        'charts.tuesday_label': 'úterý',
        'charts.wednesday_label': 'středa',
        'client.all_sessions_link': 'Všechny relace',
        'client.bike_progress': 'Kolo',
        'client.client_progress_heading': 'Pokrok klienta',
        'client.client_sessions_heading': 'Poslední klientská relace',
        'client.email': 'Klientův e-mail',
        'client.name': 'Jméno klienta',
        'client.not_applicable': 'NENÍ K DISPOZICI',
        'client.notes_label': 'Poznámky',
        'client.progress_types_complete': 'Hotovo',
        'client.saddle_progress': 'Sedlo',
        'client.save_cta': 'Uložit',
        'client.shoe_progress': 'Boty',
        'commute-comfort': 'Dojíždění-komfort',
        'commute-sport': 'Dojíždění-sport',
        'country.ad': 'Andorra',
        'country.ae': 'Spojené arabské emiráty',
        'country.af': 'Afghánistán',
        'country.ag': 'Antigua a Barbuda',
        'country.ai': 'Anguilla',
        'country.al': 'Albánie',
        'country.am': 'Arménie',
        'country.ao': 'Angola',
        'country.aq': 'Antarktida',
        'country.ar': 'Argentina',
        'country.as': 'Americká Samoa',
        'country.at': 'Rakousko',
        'country.au': 'Austrálie',
        'country.aw': 'Aruba',
        'country.ax': 'Ålandské ostrovy',
        'country.az': 'Ázerbajdžán',
        'country.ba': 'Bosna a Hercegovina',
        'country.bb': 'Barbados',
        'country.bd': 'Bangladéš',
        'country.be': 'Belgie',
        'country.bf': 'Burkina Faso',
        'country.bg': 'Bulharsko',
        'country.bh': 'Bahrajn',
        'country.bi': 'Burundi',
        'country.bj': 'Benin',
        'country.bl': 'Svatý Bartoloměj',
        'country.bm': 'Bermudy',
        'country.bn': 'Brunej',
        'country.bo': 'Bolívie',
        'country.bq': 'Bonaire, Sint Eustatius a Saba',
        'country.br': 'Brazílie',
        'country.bs': 'Bahamy',
        'country.bt': 'Bhútán',
        'country.bv': 'Bouvetův Ostrov',
        'country.bw': 'Botswana',
        'country.by': 'Bělorusko',
        'country.bz': 'Belize',
        'country.ca': 'Kanada',
        'country.cc': 'Kokosové (Keelingské) ostrovy',
        'country.cd': 'Kongo (Demokratická republika)',
        'country.cf': 'Středoafrická republika',
        'country.cg': 'Kongo',
        'country.ch': 'Švýcarsko',
        'country.ci': 'Pobřeží slonoviny',
        'country.ck': 'Cookovy Ostrovy',
        'country.cl': 'Chile',
        'country.cm': 'Kamerun',
        'country.cn': 'Čína',
        'country.co': 'Kolumbie',
        'country.cr': 'Kostarika',
        'country.cu': 'Kuba',
        'country.cv': 'Cabo Verde',
        'country.cw': 'Curaçao',
        'country.cx': 'Vánoční Ostrov',
        'country.cy': 'Kypr',
        'country.cz': 'Česká republika',
        'country.de': 'Německo',
        'country.dj': 'Džibuti',
        'country.dk': 'Dánsko',
        'country.dm': 'Dominika',
        'country.do': 'Dominikánská republika',
        'country.dz': 'Alžírsko',
        'country.ec': 'Ekvádor',
        'country.ee': 'Estonsko',
        'country.eg': 'Egypt',
        'country.eh': 'Západní Sahara',
        'country.er': 'Eritrea',
        'country.es': 'Španělsko',
        'country.et': 'Etiopie',
        'country.fi': 'Finsko',
        'country.fj': 'Fidži',
        'country.fk': 'Falklandské Ostrovy [Malvíny]',
        'country.fm': 'Mikronésie (Federativní Státy)',
        'country.fo': 'Faerské ostrovy',
        'country.fr': 'Francie',
        'country.ga': 'Gabon',
        'country.gb': 'Spojené království',
        'country.gd': 'Grenada',
        'country.ge': 'Gruzie',
        'country.gf': 'Francouzská Guyana',
        'country.gg': 'Guernsey',
        'country.gh': 'Ghana',
        'country.gi': 'Gibraltar',
        'country.gl': 'Grónsko',
        'country.gm': 'Gambie',
        'country.gn': 'Guinea',
        'country.gp': 'Guadeloupe',
        'country.gq': 'Rovníková Guinea',
        'country.gr': 'Řecko',
        'country.gs': 'Jižní Georgie a Jižní Sandwichovy ostrovy',
        'country.gt': 'Guatemala',
        'country.gu': 'Guam',
        'country.gw': 'Guinea-Bissau',
        'country.gy': 'Guyana',
        'country.hk': 'Hongkong',
        'country.hm': 'Heardův ostrov a MacDonaldovy ostrovy',
        'country.hn': 'Honduras',
        'country.hr': 'Chorvatsko',
        'country.ht': 'Haiti',
        'country.hu': 'Maďarsko',
        'country.id': 'Indonésie',
        'country.ie': 'Irsko',
        'country.il': 'Izrael',
        'country.im': 'Ostrov Man',
        'country.in': 'Indie',
        'country.io': 'Britské Území V Indickém Oceánu',
        'country.iq': 'Irák',
        'country.ir': 'Írán',
        'country.is': 'Island',
        'country.it': 'Itálie',
        'country.je': 'Jersey',
        'country.jm': 'Jamaica',
        'country.jo': 'Jordánsko',
        'country.jp': 'Japonsko',
        'country.ke': 'Keňa',
        'country.kg': 'Kyrgyzstán',
        'country.kh': 'Kambodža',
        'country.ki': 'Kiribati',
        'country.km': 'Komory',
        'country.kn': 'Svatý Kryštof a Nevis',
        'country.kp': 'Severní Korea',
        'country.kr': 'Jižní Korea',
        'country.kw': 'Kuvajt',
        'country.ky': 'Kajmanské ostrovy',
        'country.kz': 'Kazachstán',
        'country.la': 'Laos',
        'country.lb': 'Libanon',
        'country.lc': 'Svatá Lucie',
        'country.li': 'Lichtenštejnsko',
        'country.lk': 'Srí Lanka',
        'country.lr': 'Libérie',
        'country.ls': 'Lesotho',
        'country.lt': 'Litva',
        'country.lu': 'Lucembursko',
        'country.lv': 'Lotyšsko',
        'country.ly': 'Libye',
        'country.ma': 'Maroko',
        'country.mc': 'Monako',
        'country.md': 'Moldavsko',
        'country.me': 'Černá Hora',
        'country.mf': 'Saint Martin (francouzská část)',
        'country.mg': 'Madagaskar',
        'country.mh': 'Marshallovy ostrovy',
        'country.mk': 'Makedonie',
        'country.ml': 'Mali',
        'country.mm': 'Myanmaru',
        'country.mn': 'Mongolsko',
        'country.mo': 'Macao',
        'country.mp': 'Severní Marianské ostrovy',
        'country.mq': 'Martinik',
        'country.mr': 'Mauritánie',
        'country.ms': 'Montserrat',
        'country.mt': 'Malta',
        'country.mu': 'Mauricius',
        'country.mv': 'Maledivy',
        'country.mw': 'Malawi',
        'country.mx': 'Mexiko',
        'country.my': 'Malajsie',
        'country.mz': 'Mozambik',
        'country.na': 'Namibie',
        'country.nc': 'Nová Kaledonie',
        'country.ne': 'Niger',
        'country.nf': 'Norfolk Island',
        'country.ng': 'Nigérie',
        'country.ni': 'Nikaragua',
        'country.nl': 'Nizozemsko',
        'country.no': 'Norsko',
        'country.np': 'Nepál',
        'country.nr': 'Nauru',
        'country.nu': 'Niue',
        'country.nz': 'Nový Zéland',
        'country.om': 'Omán',
        'country.pa': 'Panama',
        'country.pe': 'Peru',
        'country.pf': 'Francouzská Polynésie',
        'country.pg': 'Papua-Nová Guinea',
        'country.ph': 'Filipíny',
        'country.pk': 'Pákistán',
        'country.pl': 'Polsko',
        'country.pm': 'Saint Pierre a Miquelon',
        'country.pn': 'Pitcairn',
        'country.pr': 'Portoriko',
        'country.ps': 'Palestina, stát',
        'country.pt': 'Portugalsko',
        'country.pw': 'Palau',
        'country.py': 'Paraguay',
        'country.qa': 'Katar',
        'country.re': 'Réunion',
        'country.ro': 'Rumunsko',
        'country.rs': 'Srbsko',
        'country.ru': 'Rusko',
        'country.rw': 'Rwanda',
        'country.sa': 'Saúdská Arábie',
        'country.sb': 'Šalamounovy Ostrovy',
        'country.sc': 'Seychely',
        'country.sd': 'Súdán',
        'country.se': 'Švédsko',
        'country.sg': 'Singapur',
        'country.sh': 'Svatá Helena, Ascension a Tristan da Cunha',
        'country.si': 'Slovinsko',
        'country.sj': 'Svalbard a Jan Mayen',
        'country.sk': 'Slovensko',
        'country.sl': 'Sierra Leone',
        'country.sm': 'San Marino',
        'country.sn': 'Senegal',
        'country.so': 'Somálsko',
        'country.sr': 'Surinam',
        'country.ss': 'jižní Súdán',
        'country.st': 'Svatý Tomáš a Princův ostrov',
        'country.sv': 'El Salvador',
        'country.sx': 'Sint Maarten (holandská část)',
        'country.sy': 'Sýrie',
        'country.sz': 'Svazijsko',
        'country.tc': 'Ostrovy Turks a Caicos',
        'country.td': 'Čad',
        'country.tf': 'Francouzská Jižní Teritoria',
        'country.tg': 'Togo',
        'country.th': 'Thajsko',
        'country.tj': 'Tádžikistán',
        'country.tk': 'Tokelau',
        'country.tl': 'Timor-Leste',
        'country.tm': 'Turkmenistán',
        'country.tn': 'Tunisko',
        'country.to': 'Tonga',
        'country.tr': 'Turvalue',
        'country.tt': 'Trinidad a Tobago',
        'country.tv': 'Tuvalu',
        'country.tw': 'Taiwan',
        'country.tz': 'Tanzanie',
        'country.ua': 'Ukrajina',
        'country.ug': 'Uganda',
        'country.um': 'Malé odlehlé ostrovy Spojených států',
        'country.us': 'Spojené státy americké',
        'country.uy': 'Uruguay',
        'country.uz': 'Uzbekistán',
        'country.va': 'Svatý Stolec',
        'country.vc': 'Svatý Vincent a Grenadiny',
        'country.ve': 'Venezuela',
        'country.vg': 'Panenské Ostrovy (Britské)',
        'country.vi': 'Panenské ostrovy (USA)',
        'country.vn': 'Vietnam',
        'country.vu': 'Vanuatu',
        'country.wf': 'Wallis a Futuna',
        'country.ws': 'Samoa',
        'country.ye': 'Jemen',
        'country.yt': 'Mayotte',
        'country.za': 'Jižní Afrika',
        'country.zm': 'Zambie',
        'country.zw': 'Zimbabwe',
        cyclocross: 'Cyklokros',
        'date.all_time': 'DATUM: VŠECHNY ČASY',
        'date.last_month': 'DATUM: MINULÝ MĚSÍC',
        'date.last_week': 'DATUM: MINULÝ TÝDEN',
        'date.last_year': 'DATUM: MINULÝ ROK',
        'employees.add_cta_label': 'Přidat zaměstnance',
        'employees.delete_cta_label': 'Smazat zaměstnance',
        'employees.employee_id_label': 'ID zaměstnance',
        'employees.heading': 'Zaměstnanci',
        'employees.intro':
            'Časová osa všech vašich nejnovějších zaměstnanců s technologií Retül.',
        'employees.update_cta_label': 'Aktualizovat zaměstnance',
        'filters.all_label': 'VŠECHNO',
        'filters.client_all': 'Vše',
        'filters.client_anonymous': 'Anonymní',
        'filters.client_identified': 'Identifikován',
        'filters.client_label': 'Typ klienta',
        'filters.employees_label': 'Zaměstnanci',
        'filters.filters_heading': 'Filtry',
        'filters.hide_filters': 'Zobrazit filtry',
        'filters.locations_label': 'Místa',
        'filters.rating_all': 'VŠECHNO',
        'filters.rating_label': 'Hodnocení',
        'filters.rating_none': 'ŽÁDNÝ',
        'filters.session_all': 'Vše',
        'filters.session_dsd': 'DSD/DFD',
        'filters.session_label': 'Typ relace',
        'filters.session_match': 'Match',
        'filters.show_filters': 'Zobrazit filtry',
        'filters.status_all': 'Vše',
        'filters.status_closed': 'Zavřena',
        'filters.status_label': 'Stav',
        'filters.status_open': 'Otevřená',
        'footer.privacy_link_label': 'Zásady ochrany osobních údajů',
        'footer.terms_link_label': 'Podmínky užití',
        'form.address_1_field_error': 'Adresa je povinná.',
        'form.address_1_field_label': 'Adresa',
        'form.city_field_error': 'Město je povinné.',
        'form.city_field_label': 'Město',
        'form.country_field_error': 'Země je povinná.',
        'form.country_field_label': 'Země',
        'form.delete_field_confirm_message': 'Opravdu chcete smazat {data} ?',
        'form.delete_field_label': 'Odstranit',
        'form.edit_field_label': 'Upravit',
        'form.email_field_error':
            'Je vyžadován e-mail a musí být platný formát e-mailu.',
        'form.email_field_label': 'E-mail',
        'form.name_field_error': 'Jméno je povinné.',
        'form.name_field_label': 'Jméno',
        'form.shop_field_label': 'prodejna',
        'form.shops_field_label': 'Obchody',
        'form.state_field_error': 'Stát je povinný.',
        'form.state_field_label': 'Stát',
        'form.zip_field_error': 'PSČ je povinné',
        'form.zip_field_label': 'PSČ',
        'gender.all': 'POHLAVÍ: VŠECHNY',
        'gender.female': 'POHLAVÍ: ŽENA',
        'gender.male': 'POHLAVÍ: MUŽ',
        'landing.heading': 'Vítejte na portálu prodejců',
        'landing.intro':
            'Přístup k datům, relace a informace o klientovi zachycené technologie Retül.',
        'landing.sign_in_or_create_account_label':
            'Přihlaste se / Vytvořte účet',
        'locale.label_cs': '',
        'locale.label_de': '',
        'locale.label_en': '',
        'locale.label_en-us': '',
        'locale.label_es': '',
        'locale.label_fr': '',
        'locale.label_it': '',
        'locale.label_ja': '',
        'locale.label_ko': '',
        'locale.label_nl': '',
        'locale.label_pl': '',
        'locale.label_pt': '',
        'locale.label_pt-br': '',
        'locale.label_zh-cn': '',
        'locale.label_zh-tw': '',
        'locale.locale_picker_cancel_label': 'Zrušit',
        'locale.locale_picker_heading': 'Zvolte svůj jazyk.',
        'locale.locale_picker_save_label': 'Uložit',
        'locations.add_cta_label': 'Přidat místo',
        'locations.delete_cta_label': 'Smazat místo',
        'locations.empty':
            'Nebyla přidána žádná místa. Chcete-li použít Retül Apps, přidejte prosím místo.',
        'locations.empty_cta_label': 'Správa míst',
        'locations.heading': 'Správa míst',
        'locations.intro':
            'Přidejte, upravujte a odstraňte místa pomocí technologie Retül.',
        'locations.location_id_label': 'ID místa',
        'locations.update_cta_label': 'Aktualizovat místo',
        'metrics.closed_session_tooltip':
            'Uzavřené relace označují relace, které zaznamenaly transakci. Pokud klient provedl nebo neprovedl nákup, {closingSessions} vám pomůže sledovat prodej z technologie Retül.',
        'metrics.closed_sessions': 'Uzavřené relace',
        'metrics.closing_session_link': 'ukončení relace',
        'metrics.total_sessions': 'Všechny relace',
        'metrics.unique_client_emails': 'Unikátní e-maily klientů',
        'metrics.unique_emails_tooltip':
            'Toto číslo představuje relace, které byly provedeny s unikátními klienty. Unikátní klienti jsou určeni jejich e-mailovou adresou.',
        'mtb-trail': 'Horské (trail)',
        'mtb-xc': 'MTB (cross country)',
        'nav.analytics': 'Analytics',
        'nav.greeting_label': 'Dobrý den, {data}',
        'nav.manage_employees_link_label': 'Správa zaměstnanců',
        'nav.manage_locations_link_label': 'Správa míst',
        'nav.sessions': 'Relace',
        'nav.sign_out_link_label': 'Odhlásit se',
        'pagination.next': 'další',
        'pagination.page': 'Stránka',
        'pagination.previous': 'Předchozí',
        road: 'Silniční',
        'session_details.add_transaction_cta_label': 'Přidat transakci',
        'session_details.all_sessions_link': 'Všechny relace',
        'session_details.bike_product': 'Kolo',
        'session_details.client': 'Klient',
        'session_details.client_review_label': 'Přehled klientů',
        'session_details.complete_session_cta_label': 'Označit jako uzavřený',
        'session_details.complete_session_message':
            'Zavřete tuto relaci přidáním jakékoli transakce provedené klientem nebo',
        'session_details.complete_status': 'Zavřeno',
        'session_details.created_on': 'Vytvořeno dne: {data}',
        'session_details.delete_link': 'Odstranit',
        'session_details.employee': 'Zaměstnanec: {data}',
        'session_details.family_label': 'Rodina',
        'session_details.incomplete_status': 'Otevřená',
        'session_details.model_label': 'Model',
        'session_details.no_transactions_message':
            'Nebyly přidány žádné transakce.',
        'session_details.product_label': 'Produkt',
        'session_details.saddle_product': 'Sedlo',
        'session_details.shoe_product': 'Boty',
        'session_details.shop': 'Obchod: {data}',
        'session_details.size_label': 'Velikost',
        'session_details.status_label': 'Stav',
        'session_details.transactions_heading': 'Transakce relace',
        'session_products.arch_height_label': 'Výška klenby: {data}',
        'session_products.bike_size_label': 'Velikost kola: {data}',
        'session_products.saddle_height_label': 'Výška sedla: {data} mm',
        'session_products.saddle_width_label': 'Šířka sedla: {data} mm',
        'session_products.session_product_label': 'Data relace',
        'session_products.shoe_size_label': 'Velikost bot: {data}',
        'sessions.add_transaction_cta_label': 'Přidat transakci',
        'sessions.client_anonymous': 'Anonymní',
        'sessions.client_label': 'Klient',
        'sessions.closed_status': 'ZAVŘENO',
        'sessions.csv_downloading_message':
            'Zhromažďujeme všechny vaše relace pro vaše CSV, může to trvat několik minut.',
        'sessions.date_label': 'Datum',
        'sessions.employee_label': 'Zaměstnanec',
        'sessions.export_csv_link': 'Exportní seznam (.CSV)',
        'sessions.filter_empty':
            'Omlouváme se, neexistují žádné relace, které odpovídají kritériím filtru. Vymažte výběr filtru.',
        'sessions.filter_empty_cta_label': 'Resetovat filtry',
        'sessions.heading': 'Relace',
        'sessions.intro':
            'Časová osa všech vašich nejnovějších relací s technologií Retül.',
        'sessions.location_label': 'Umístění',
        'sessions.no_rating': 'Žádné hodnocení',
        'sessions.open_status': 'OTEVŘENO',
        'sessions.rating_label': 'Hodnocení',
        'sessions.session_id_label': 'Číslo relace',
        'sessions.session_loading_message':
            'Shromažďujeme všechny vaše relace, může to chvíli trvat.',
        'sessions.status_label': 'Stav',
        'sign_in.heading': 'Přihlásit se',
        'sign_in.intro':
            'Chcete-li začít, zadejte svůj e-mail a heslo nebo zvolte "Zaregistrovat účet" a vytvořte nový.',
        'status.all': 'STATUS: VŠECHNY',
        'status.complete': 'STATUS: ZAVŘENO',
        'status.incomplete': 'STATUS: OTEVŘENO',
        triathlon: 'Triatlon',
        'POWER MIMIC DESCRIPTION':
            'Toto sedlo eliminuje necitlivost a bolest díky konstrukcí, která ""zrcadlí" reakci těla na různé druhy tlaku, aby vytvořila rovnováhu v měkké tkáni.',
        'date_picker.all_time': 'Všechny relace',
        'date_picker.date_range': 'Časové období',
        'date_picker.last_month': 'Minulý měsíc',
        'date_picker.last_week': 'Minulý týden',
        'date_picker.last_year': 'Minulý rok',
        'date_picker.this_month': 'Tento měsíc',
        'date_picker.this_week': 'Tento týden',
        'date_picker.this_year': 'Tento rok',
        'session_details.client_notes': 'Poznámky klienta',
        'session_details.footbed_product': 'Stélka',
        'session_products.it_width_label': 'Šířka sedacích hrbolů: {data} mm',
        'bike_size.large': 'LG',
        'bike_size.medium': 'MD',
        'bike_size.small': 'SM',
        'bike_size.xlarge': 'XL',
        'bike_size.xsmall': 'XS',
        'bike_size.xxlarge': 'XXL',
        'body_data.about_bike':
            'Retül Match je postup zjištění rozměrů v obchodě, který přesně změří délku nohou s cílem zajistit optimální velikost jízdního kola a produktů, které jsou pro vás nejvhodnější.',
        'body_data.about_saddle':
            'Retül Match je postup zjištění rozměrů v obchodě, který využívá zařízení Retül Digital Sitbone Device k změření šířky vašich sedacích kostí pomocí technologie zaznamenání digitální mapy tlaku s cílem poskytnout přesnou velikost sedla, které je pro vás nejvhodnější.',
        'body_data.about_shoe':
            'Retül Match je postup zjištění rozměrů v obchodě, který zaznamená údaje o chodidlech a poskytne vám přesné pomůcky pro podporu klenby, velikost bot a typ bot, které jsou pro vás nejvhodnější.',
        'body_data.arch_height_label': 'výška klenby',
        'body_data.bike_label': 'Jízdní kolo',
        'body_data.bike_size_label': 'velikost jízdního kola',
        'body_data.centimeter_symbol': 'cm',
        'body_data.circumference_label': 'obvod',
        'body_data.find_retul_technology': 'Objevte technologii Retül',
        'body_data.heading': 'Rozměry těla',
        'body_data.intro':
            'Rozměry těla a vámi upřednostňovaný způsob jízdy jsou zaznamenány za pomoci technologie Retül Vantage. Poskytne vám přesné údaje o velikosti a o produktech, které jsou pro vás nejvhodnější.',
        'body_data.length_label': 'délka',
        'body_data.load_error':
            'Nyní nejsme schopni získat zpět vaše rozměry těla. Zkuste to prosím později.',
        'body_data.millimeter_symbol': 'mm',
        'body_data.not_collected_label': 'nezjištěno',
        'body_data.recommended_label': 'Doporučeno',
        'body_data.saddle_height_label': 'výška sedla',
        'body_data.saddle_label': 'Sedlo',
        'body_data.saddle_size_label': 'velikost sedla',
        'body_data.session_type': 'Retül {sessionType}',
        'body_data.shin_label': 'holeň',
        'body_data.shoe_label': 'Bota',
        'body_data.shoe_size_label': 'velikost obuvi',
        'body_data.sit_bone_label': 'sedací kosti',
        'body_data.starting_label': 'Počínaje',
        'body_data.thigh_label': 'stehno',
        'body_data.view_bike_matches_label': 'Zobrazit vhodná kola',
        'body_data.view_saddle_matches_label': 'Zobrazit vhodná sedla',
        'body_data.view_shoe_matches_label': 'Zobrazit vhodnou obuv',
        'body_data.your_progress_completion_label': 'Dokončeno {numComplete}/3',
        'body_data.your_progress_label': 'Váš postup',
        cancel_label: 'Zrušit',
        'create_account.heading': 'Vytvořit účet',
        'create_account.intro':
            'Vložte svůj email a heslo a vytvořte účet Retül Passport.',
        'edit_form.edit_form_cancel_label': 'Zrušit',
        'edit_form.edit_form_save_label': 'Uložit',
        email_format_error: 'Neplatný formát emailové adresy.',
        email_label: 'E-mailová adresa',
        'forgot_password.heading': 'Zapomenuté heslo',
        'forgot_password.intro':
            'Zapomněli jste heslo? Vložte svou emailovou adresu a zašleme vám odkaz k obnově hesla.',
        'forgot_password.reset_email_sent':
            'Email s odkazem na obnovu hesla byl odeslán na {email}',
        'landing.create_account_label': 'Vytvořit účet',
        'landing.sign_in_label': 'Přihlášení',
        'nav.account_profiles_link_label': 'Účet a profily cyklisty',
        'nav.home_page_link_label': 'Domovská stránka',
        'nav.logout_link_label': 'Odhlásit',
        'not_found.go_home_label': 'Jít domů',
        'not_found.heading': '404',
        'not_found.intro':
            'Strana, kterou hledáte, se zdá být přesunutá, smazaná či neexistuje.',
        password_invalid_error: 'Neplatné heslo.',
        password_label: 'Heslo',
        'product_matches.bike_size_label': 'velikost jízdního kola',
        'product_matches.category_filter_commute_comfort_label': 'Městské',
        'product_matches.category_filter_commute_sport_label': 'Fitness',
        'product_matches.category_filter_cyclocross_label': 'Cyklokrosové',
        'product_matches.category_filter_label': 'Kategorie',
        'product_matches.category_filter_label_road': 'Silniční',
        'product_matches.category_filter_mountain_trail_label': 'MTB (trail)',
        'product_matches.category_filter_mountain_xc_label':
            'MTB (cross-country)',
        'product_matches.category_filter_triathlon_label': 'Triatlonové',
        'product_matches.centimeter_symbol': 'cm',
        'product_matches.empty_session':
            'Nemáme vaše rozměry těla, abychom vám mohli poskytnout to nejvhodnější z tohoto druhu produktů. Použijte výše uvedený filtr a podívejte se na jiné druhy produktů nebo použijte stránku s prodejními místy a najděte obchod, kde budete moci projít modulem shod.',
        'product_matches.find_retul_technology_label':
            'Najít technologii Retul',
        'product_matches.gender_filter_all_label': 'Zobrazit všechny produkty',
        'product_matches.gender_filter_female_label':
            'Zobrazit produkty pro ženy',
        'product_matches.gender_filter_male_label':
            'Zobrazit produkty pro muže',
        'product_matches.heading': 'Vhodné produkty',
        'product_matches.intro':
            'Technologie Retül využije vaše rozměry těla a jakým způsobem jezdíte a poskytne vám shody se specializovanými produkty, které jsou pro vás nejvhodnější.',
        'product_matches.last_session_match_label': 'Poslední hledání shody',
        'product_matches.load_error':
            'Nyní nejsme schopni získat zpět vaše údaje o poslední produktové shodě. Zkuste to prosím později.',
        'product_matches.millimeter_symbol': 'mm',
        'product_matches.overlay_bike_size_details':
            'Na základě biometrických údajů zaznamenaných během relace v systému Retül Match vám doporučujeme výše uvedenou velikost jízdního kola. Máte teď dobrou výchozí pozici, ale i tak se poraďte s místním prodejcem, který vám doporučí produkty na základě vašich zkušeností a tělesného typu.',
        'product_matches.overlay_bike_size_label': 'velikost jízdního kola',
        'product_matches.overlay_close_label': 'Zavřít',
        'product_matches.overlay_indicator_label': 'Vy',
        'product_matches.overlay_saddle_height_details':
            'Na základě biometrických údajů zaznamenaných během relace v systému Retül Match vám doporučujeme výše uvedenou výšku sedla. Máte teď dobrou výchozí pozici, ale i tak se poraďte s místním prodejcem, který vám doporučí produkty na základě vašich zkušeností a tělesného typu.',
        'product_matches.overlay_saddle_height_label': 'výška sedla',
        'product_matches.overlay_saddle_size_details':
            'Na základě biometrických údajů zaznamenaných během relace v systému Retül Match vám doporučujeme výše uvedenou velikost sedla. Máte teď dobrou výchozí pozici, ale i tak se poraďte s místním prodejcem, který vám doporučí produkty na základě vašich konkrétních tělesných údajů.',
        'product_matches.overlay_saddle_size_label': 'velikost sedla',
        'product_matches.overlay_shoe_size_details':
            'Na základě biometrických údajů zaznamenaných během relace v systému Retül Match vám doporučujeme výše uvedenou velikost obuvi. Máte teď dobrou výchozí pozici, ale i tak se poraďte s místním prodejcem, který vám doporučí produkty na základě vašich zkušeností.',
        'product_matches.overlay_shoe_size_label': 'velikost obuvi',
        'product_matches.padding_level_label': 'Úroveň tlumení',
        'product_matches.product_type_filter_bikes_label': 'Jízdní kola',
        'product_matches.product_type_filter_label': 'Typ produktu',
        'product_matches.product_type_filter_saddles_label': 'Sedla',
        'product_matches.product_type_filter_shoes_label': 'Obuv',
        'product_matches.recommended_label': 'Doporučeno',
        'product_matches.saddle_detail_size_label': 'Velikost sedla:',
        'product_matches.saddle_height_label': 'výška sedla',
        'product_matches.saddle_models_label': 'Modely',
        'product_matches.saddle_size_label': 'velikost sedla',
        'product_matches.shoe_size_label': 'velikost obuvi',
        'product_matches.starting_label': 'Počínaje',
        'product_matches.view_on_specialized_label':
            'Zobrazit na webu Specialized',
        'profile.account_heading': 'Účet',
        'profile.bike_type_city_label': 'Město',
        'profile.bike_type_label': 'Druh jízdního kola',
        'profile.bike_type_mountain_label': 'Horské',
        'profile.bike_type_road_label': 'Silniční',
        'profile.email_error': 'Vložte prosím platnou emailovou adresu',
        'profile.email_label': 'E-mailová adresa',
        'profile.gender_female_label': 'žena',
        'profile.gender_label': 'Pohlaví',
        'profile.gender_male_label': 'muž',
        'profile.heading': 'Účet a profily cyklisty',
        'profile.intro':
            'Již brzy... upravte své údaje na účtě a profil cyklisty.',
        'profile.name_error': 'Vložte prosím své jméno',
        'profile.name_label': 'Jméno',
        'profile.password_error': 'Vložte prosím heslo',
        'profile.password_label': 'Heslo',
        'profile.position_high_label': 'Vysoký',
        'profile.position_label': 'Posed',
        'profile.position_low_label': 'Nízký',
        'profile.position_medium_label': 'Střední',
        'profile.ride_duration_extended_label': '90+ minut',
        'profile.ride_duration_label': 'Výdrž',
        'profile.ride_duration_long_label': '60–90 minut',
        'profile.ride_duration_medium_label': '30–60 minut',
        'profile.ride_duration_short_label': '0–30 minut',
        'profile.ride_frequency_label': 'Frekvence',
        'profile.ride_frequency_once_month_label': 'Jednou měsíčně',
        'profile.ride_frequency_once_week_label': 'Jednou týdně',
        'profile.ride_frequency_six_week_label': '6× týdně',
        'profile.ride_frequency_three_week_label': '3× týdně',
        'routes.account_profile_route_label': 'Účet a profily cyklisty',
        'routes.body_data_route_label': 'Rozměry těla',
        'routes.create_account_route_label': 'Vytvořit účet',
        'routes.forgot_password_route_label': 'Zapomenuté heslo',
        'routes.locations_route_label': 'Místa',
        'routes.not_found_route_label': 'Nenalezeno',
        'routes.privacy_policy_route_label': 'Zásady ochrany soukromí',
        'routes.product_matches_route_label': 'Vhodné produkty',
        'routes.reset_password_route_label': 'Obnovit heslo',
        'routes.set_password_route_label': 'Obnovit heslo',
        'routes.sessions_route_label': 'Relace',
        'routes.sign_in_route_label': 'Přihlášení',
        'routes.terms_of_service_route_label': 'Podmínky využívání služeb',
        'sessions.download_pdf_cta_label': 'Stáhnout PDF',
        'sessions.empty': 'Neotevřeli jste žádné relace.',
        'sessions.empty_cta_label': 'Najít prodejní místo',
        'sessions.loading_error':
            'Nyní nejsme schopni získat zpět vaše relace. Zkuste to prosím později.',
        'sessions.loading_error_cta_label': 'Opakovat',
        'sessions.session_type_filter_label': 'Typ relace',
        'session_type.dfd_session_name': 'Zařízení Retül Digital Foot Device',
        'session_type.dsd_session_name':
            'Zařízení Retül Digital Sitbone Device',
        'session_type.foot_session_name': 'Retül – chodidla',
        'session_type.legs_session_name': 'Retül – nohy',
        'session_type.match_session_name': 'Retül Match',
        'session_type.saddle_session_name': 'Retül – sedlo',
        'session_type.unknown_session_name': 'Retül – relace',
        'session_type.vantage_session_name': 'Retül Fit',
        'sign_in.sign_in_label': 'Přihlášení',
        'sign_in.contact_label': 'Kontaktovat Retül',
        'sign_in.forgot_password_label': 'Zapomenuté heslo?',
        'sign_in.sign_in_error':
            'Nyní nejsme schopni získat zpět vaše uživatelská data. Kontaktujte prosím podporu Retül s žádostí o pomoc.',
        submit_label: 'Odeslat',
    },
};
